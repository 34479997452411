import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { ContractOption, Option, Select } from "ui-library/atoms";

import router from "core/router";
import userContracts from "core/userContracts";
import i18n from "core/i18n";

import { Pages, Params, Tabs } from "routeConstants";
import { getContractId } from "../selectors";

export const ContractSelect = () => {
    const { t } = i18n.useTranslation();
    const contracts = userContracts.useGetActiveContracts();
    const selectedContractId = useSelector(getContractId);
    const dispatch = useDispatch();

    // TODO fix args order after rebase
    const doTransition = (contractId) =>
        router.navigate(Pages.ONLINE_PAYMENT, Tabs.PAYMENTS_PAY, {}, { [Params.PAYMENT_CONTRACT_ID]: contractId }, true);

    return (
        <Select
            value={selectedContractId || ""}
            onChange={(event) => dispatch(doTransition(event.target.value))}
            placeholder={t("payments.contractPlaceholder")}
            label={t("common.contract")}
            id="selectOther"
            fullWidth
        >
            {contracts &&
                contracts.map((contract) => (
                    <Option value={contract.idObject} key={contract.idObject}>
                        <ContractOption contractName={contract.contractName} contractNumber={`${contract.contractNumber}`} />
                    </Option>
                ))}
        </Select>
    );
};
