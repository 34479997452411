export default (addresses, typeCode, addressesIdentical, contactPersonType, showPersonInContactAddressForm) => {
    const addressItem = addresses && addresses.find((item) => item.addressTypeCode === typeCode);
    if (!addressItem) {
        return {};
    }
    return {
        addressesIdentical,
        contactSubject: {
            contactPerson: addressItem.contactPerson,
            contactCompanyName: addressItem.contactCompanyName,
            contactPersonType,
            showPersonInContactAddressForm,
        },
        street: addressItem.street,
        orientationNumber: addressItem.orientationNumber,
        descriptionNumber: addressItem.descriptionNumber,
        city: addressItem.city,
        zip: addressItem.postalCode,
        state: addressItem.countryCode,
    };
};
