import { GoogleMap, LoadScript, OverlayView } from "@react-google-maps/api";
import { Image } from "ui-library/atoms";
import uniqaMarker from "ui-library/assets/images/Uniqa_Marker.svg";
import { mapTheme } from "./mapTheme";
import { Box } from "ui-library/layouts";
import PropTypes from "prop-types";

export const Map = ({ width, height, lat, lng, zoom, ...props }) => {
    const containerStyle = {
        width: "100%",
        height: "100%",
    };

    const center = {
        lat: lat,
        lng: lng,
    };

    const options = {
        styles: mapTheme,
    };

    return (
        <Box maxWidth={width} height={height}>
            <GoogleMap options={options} mapContainerStyle={containerStyle} center={center} zoom={zoom} {...props}>
                <OverlayView position={center} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
                    <Image shadow={false} src={uniqaMarker} alt="marker" />
                </OverlayView>
            </GoogleMap>
        </Box>
    );
};

Map.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    lat: PropTypes.number,
    lng: PropTypes.number,
    zoom: PropTypes.number,
};

Map.defaultProps = {
    zoom: 13,
    width: 900,
    height: 450,
};
