import { model } from "core/util";

import { AmountLegacy } from "./AmountLegacy";
import { CodeValueLegacy } from "./CodeValueLegacy";

export const PaymentsSchedulerLegacy = model.createModelLegacy("PaymentsScheduler", (json) => ({
    periodicity: CodeValueLegacy.fromServer(json.Periodicity),
    modalAmount: AmountLegacy.fromServer(json.ModalAmount),
    paymentMode: CodeValueLegacy.fromServer(json.PaymentMode),
    paymentType: json.PaymentType,
    paymentStartDate: json.PaymentStartDate,
    paymentEndDate: json.PaymentEndDate,
}));
