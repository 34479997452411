import { model } from "core/util";
import { PensionPpTypes as PensionPpTypesType, PensionPpTypesDto } from "types";

export const PensionPpTypes = model.createModel<PensionPpTypesDto, PensionPpTypesType>((json) => ({
    pensionPlan: json.PensionPlan,
    pensionPlanCode: json.PensionPlanCode,
    pensionCode: json.PensionCode,
    pensionDescription: json.PensionDescription,
    pensionDate: json.PensionDate,
    retirementPension: json.RetirementPension,
    servicePension: json.ServicePension,
    disabilityPension: json.DisabilityPension,
    survivorsPension: json.SurvivorsPension,
}));
