import React from "react";
import { useDispatch, useSelector } from "react-redux";

import i18n from "core/i18n";
import pageContext from "core/pageContext";
import userInfo from "core/userInfo";
import { ContentBox, Flexbox } from "ui-library/layouts";
import { Button } from "ui-library/atoms";

import { accept, setDisclaimerStep } from "../actions";
import { STEP_DISCLAIMER_DECLINED } from "../constants";

export const Consent = () => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();

    const text = useSelector(pageContext.getDisclaimerText);
    const accepted = useSelector(userInfo.getCurrentDisclaimerAccepted);

    return (
        <>
            <ContentBox pb={{ xs: 4, md: 4 }}>
                <div dangerouslySetInnerHTML={{ __html: text }} />
            </ContentBox>

            {!accepted && (
                <Flexbox gap={2} justifyContent="space-between">
                    <Button onClick={() => dispatch(setDisclaimerStep(STEP_DISCLAIMER_DECLINED))} variant="outlined" color="blue">
                        {t("common.disagree")}
                    </Button>
                    <Button onClick={() => dispatch(accept())} variant="contained" color="blue">
                        {t("common.agree")}
                    </Button>
                </Flexbox>
            )}
        </>
    );
};
