import { combineReducers as immutableCombineReducers } from "redux-immutable";
import { combineReducers, createReducer } from "@reduxjs/toolkit";

import { PensionDps } from "model/pension/dps";
import { PensionDpsContract } from "types";

import { ActualStatements, ExtraStatements, FinancialOperationsTableFilter, TaxStatements, YearStatements } from "./modules";
import { clear, setPensionDpsContractDetail } from "./actions";

const pensionDpsContractDetail = createReducer<PensionDpsContract>(PensionDps.fromServer(), (builder) => {
    builder.addCase(setPensionDpsContractDetail, (_, { payload }) => payload);
    builder.addCase(clear, () => PensionDps.fromServer());
});

export default immutableCombineReducers({
    data: combineReducers({
        pensionDpsContractDetail,
    }),
    appState: immutableCombineReducers({
        yearStatements: YearStatements.reducer,
        extraStatements: ExtraStatements.reducer,
        taxStatements: TaxStatements.reducer,
        actualStatements: ActualStatements.reducer,
        financialOperationsFilter: FinancialOperationsTableFilter.reducer,
    }),
});
