import { withTheme } from "@mui/styles";
import styled from "styled-components";
import { Box } from "ui-library/layouts/Box/Box";
import { Flexbox } from "ui-library/layouts/Flexbox/Flexbox";

export const StyledSidemenu = withTheme(styled("div")`
    flex-shrink: 0;
    transition: all ease 200ms;
    width: 300px;
    position: sticky;
    // header je fix height na 100
    top: 140px;
    align-self: baseline;
    margin-right: 20px;
    margin-bottom: 0;
    @media (max-width: ${(props) => props.theme.breakpoints.values.lg}px) {
        width: 220px;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        z-index: 2;
        margin-bottom: 48px;
        margin-right: 0;
        background: ${(props) => props.theme.palette.white.main};
        top: 70px;
        left: 0;
        box-shadow: 0px 20px 35px 0px #30313d1a;
        width: 100%;
    }
    .sidemenu-item {
        a {
            display: block;
            padding-bottom: 16px;
        }
    }
    &.sidenav-fixed {
        transition: all ease 200ms;
        z-index: 4;
        @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
            transition: all ease 200ms;
            top: ${(props) => (props.$scrollingUp ? "70px" : "0px")};
            margin-left: -24px;
            width: calc(100% + 48px);
        }
        @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
            margin-left: -16px;
            width: calc(100% + 32px);
        }
    }
`);

export const StyledSidemenuDropdownHeader = withTheme(styled(Flexbox)`
    padding: 20px 20px 20px;
    &:hover {
        cursor: pointer;
    }
    p {
        font-size: 21px;
        color: ${(props) => props.theme.palette.black.main};
        font-weight: 500;
        text-decoration-color: ${(props) => props.theme.palette.blue.main};
        text-decoration-thickness: 3px;
        text-underline-offset: 2px;
        position: relative;
        &:before {
            position: absolute;
            width: 100%;
            bottom: 1px;
            left: 0;
            right: 0;
            height: 3px;
            background: ${(props) => props.theme.palette.blue.main};
            content: "";
        }
    }
`);

export const StyledSidemenuDropdownContent = withTheme(styled(Box)`
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: none;
        padding: 19px 20px 0;
        &.opened-sidemenu {
            display: block;
        }
        .chevron-button {
            position: absolute;
            top: 25px;
            right: 20px;
        }
    }
`);
