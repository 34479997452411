import React, { FC } from "react";
import { Set } from "immutable";

import i18n from "core/i18n";
import userContracts from "core/userContracts";

import { Checkbox, CheckboxGroup } from "ui-library/atoms";
import { Box } from "ui-library/layouts";

export interface ContractsPickerProps {
    idObject: number;
    input: any;
}

const ContractsPicker: FC<ContractsPickerProps> = ({ input, idObject }) => {
    const { t } = i18n.useTranslation();
    const selectedContracts = input.value || Set();

    const activeContracts = userContracts.useGetActiveContracts();
    const otherContracts = activeContracts && idObject ? activeContracts.filter((contract) => contract.idObject !== idObject) : [];

    const onChange = (contractId: number) =>
        selectedContracts.includes(contractId)
            ? input.onChange(selectedContracts.delete(contractId))
            : input.onChange(selectedContracts.add(contractId));

    return (
        <Box>
            <CheckboxGroup label={t("form.bulkChange.contractList")} direction={"column"}>
                {otherContracts
                    .filter((contract) => contract.isActive)
                    .map((contract) => (
                        <Checkbox
                            key={contract.idObject}
                            id={`id_${contract.idObject}`}
                            tooltip=""
                            hasGroup
                            label={`${contract.productGroup}, ${contract.contractNumber}`}
                            onChange={() => onChange(contract.idObject)}
                            checked={selectedContracts.includes(contract.idObject)}
                        />
                    ))}
            </CheckboxGroup>
        </Box>
    );
};

export default ContractsPicker;
