import { v4 as uuid } from "uuid";

import createModel from "../createModel";

export const UniqaOutgoingDocument = createModel("UniqaOutgoingDocument", (json) => ({
    reactKey: uuid(),
    state: json.state,
    channel: json.channel,
    creationDate: json.creationDate,
    updatedDate: json.updatedDate,
    documentDescription: json.documentDescription,
    name: json.name,
    title1: json.title1,
    title2: json.title2,
    street: json.street,
    postalCode: json.postalCode,
    phone: json.phone,
    city: json.city,
    email: json.email,
}));
