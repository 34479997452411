import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";

import { DEFAULT_TABLE_PAGE_SIZE, DEFAULT_TABLE_ROWS_OPTIONS } from "app/constants";
import entity from "core/entity";
import fetching from "core/fetching";
import { localAmountWithCurrencyLegacy, localFullDate } from "core/formatters";
import i18n from "core/i18n";
import { PensionPpFinancialOperation } from "model/pension/pp";
import { amountOperators } from "modules/tableFilter";
import { Typography } from "ui-library/atoms";
import { ContentBox, GridItem } from "ui-library/layouts";
import { DataTable } from "ui-library/modules";

import { CATEGORY_NAME_COLUMN, GROSS_AMOUNT_COLUMN, VALUE_DATE_COLUMN } from "../constants";

const columns = (t) => [
    {
        field: VALUE_DATE_COLUMN,
        type: "date",
        headerName: t("common.date"),
        flex: 2,
        minWidth: 120,
        renderCell: (params) => localFullDate(params.value),
    },
    {
        field: CATEGORY_NAME_COLUMN,
        headerName: t("common.name"),
        flex: 2,
        minWidth: 200,
    },
    {
        field: GROSS_AMOUNT_COLUMN,
        filterOperators: amountOperators,
        headerName: t("common.amount"),
        flex: 1,
        align: "right",
        headerAlign: "right",
        renderCell: (params) => localAmountWithCurrencyLegacy(params.value),
        sortComparator: (v1, v2) => parseFloat(v1.value) > parseFloat(v2.value),
    },
];

export const PensionFinancialOperationsGridItem = ({ entityName, financialOperations, Filter, filterModelSelector }) => {
    const { t } = i18n.useTranslation();
    const isFinOperationsLoading = useSelector(fetching.isFetching(entityName, true));
    const isFinOperationsError = useSelector(entity.isError(entityName));
    const filterModel = useSelector(filterModelSelector);

    return (
        <GridItem xs={12}>
            <DataTable
                icon={"chart-bar"}
                title={t("payments.overview")}
                preContent={
                    <ContentBox>
                        <Typography fontSize={14}>{t("common.financialOperationsNotice")}</Typography>
                    </ContentBox>
                }
                disableColumnReorder
                pageSize={DEFAULT_TABLE_PAGE_SIZE}
                rowsPerPageOptions={DEFAULT_TABLE_ROWS_OPTIONS}
                getRowId={(row) => row.id}
                rows={financialOperations.toArray()}
                columns={columns(t)}
                emptyMsg={t("common.noData")}
                errorMsg={t("error.sectionUnavailable")}
                isLoading={isFinOperationsLoading}
                isError={isFinOperationsError}
                actions={<Filter />}
                filterModel={filterModel}
            />
        </GridItem>
    );
};

PensionFinancialOperationsGridItem.propTypes = {
    entityName: PropTypes.string.isRequired,
    financialOperations: ImmutablePropTypes.listOf(PensionPpFinancialOperation).isRequired,
};
