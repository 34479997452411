import { fetch } from "core/util";
import { API_PREFIX } from "app/constants";

const URL_PREFIX = `${API_PREFIX}/contract-data-change`;

const updateHolder = (body) => fetch.doPut(`${URL_PREFIX}/`, body);
const updateContractElectronicCommunication = (body) => fetch.doPut(`${URL_PREFIX}/electronic-communication`, body);
const updateContractName = (body) => fetch.doPut(`${URL_PREFIX}/contract-name`, body);
const updateContractOwnerPayments = (body) => fetch.doPut(`${URL_PREFIX}/contribution`, body);
const updateEmployerPayments = (body) => fetch.doPut(`${URL_PREFIX}/employer-contribution`, body);
const updateTaxOptimizationsSettings = (body) => fetch.doPut(`${URL_PREFIX}/tax-optimum`, body);
const updateLicencePlateNumber = (body) => fetch.doPut(`${URL_PREFIX}/licence-plate-number`, body);
const updateVehicleLicenceNumber = (body) => fetch.doPut(`${URL_PREFIX}/vehicle-licence-number`, body);
const updateActiveOperationsPhoneNumber = (body) => fetch.doPut(`${URL_PREFIX}/active-operations-phone-number-change`, body);
const updateInvestmentProfile = (body) => fetch.doPut(`${URL_PREFIX}/investment-profile`, body);
const updateStatementsPassword = (body) => fetch.doPut(`${URL_PREFIX}/statements-password`, body);

export default {
    updateHolder,
    updateContractElectronicCommunication,
    updateContractName,
    updateContractOwnerPayments,
    updateEmployerPayments,
    updateTaxOptimizationsSettings,
    updateLicencePlateNumber,
    updateVehicleLicenceNumber,
    updateActiveOperationsPhoneNumber,
    updateInvestmentProfile,
    updateStatementsPassword,
};
