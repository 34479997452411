import pensionRedeemSavings from "form/fullPage/pensionRedeemSavings";

import { FORM_REDEEM_SAVINGS } from "../constants";

import {
    getContractAdditionalInfo,
    getIdObject,
    getNotApplicableRedeems,
    getPolicyHolder,
    getRedeemAllowedPensionTypes,
    getRedeemAllowedRedeemTypes,
    getRedeemParameters,
} from "../selectors";

export const FullPageRedeemSavingsForm = pensionRedeemSavings.createContainerPensionDps(
    FORM_REDEEM_SAVINGS,
    getIdObject,
    getPolicyHolder,
    getContractAdditionalInfo,
    getRedeemAllowedPensionTypes,
    getRedeemAllowedRedeemTypes,
    getRedeemParameters,
    getNotApplicableRedeems,
);
