import { all, call, delay, fork, put } from "redux-saga/effects";
import { destroy, initialize as reduxFormInitialize, submit } from "redux-form/immutable";

import { RECAPTCHA_PUBLIC_KEY } from "app/constants";
import { fetch, fn, sentry } from "core/util";

import { ERROR_COMPONENT_CLASS } from "./constants";
import {
    endAsyncValidation,
    failSubmitWithCustomErrors,
    failSubmitWithException,
    initialize as initializeAction,
    successSubmit,
    takeLatestAsyncValidate,
    takeLatestOnSubmitFail,
    takeLatestReset,
    takeLatestSubmit,
} from "./saga";

export const getPathFromFieldName = (filedName) => filedName.split(".");

/**
 * @param formName
 * @param {object} config params
 * @param {function} config.save
 * @param {?function} config.initialize
 * @param {?function} config.success
 * @param {?function} config.error
 * @param {?function} config.asyncValidation
 * @param {?function} config.persistentEffects
 */
export const formWrapper = (
    formName,
    {
        save,
        initialize = fn.noop,
        success = fn.noop,
        error = fn.noop,
        asyncValidation = fn.noop,
        onSubmitFail = fn.noop,
        persistentEffects = fn.noop,
    },
) =>
    function* wrapperFn(...params) {
        try {
            const initialData = yield call(initialize, ...params);
            // when initialData will be {} - form will be initialized as empty
            // in case of undefined/null/'' form stays as is (it values could be filled in initialize method)
            if (!fn.isEmpty(initialData)) {
                yield call(initializeAction, formName, initialData);
            }

            yield fork(persistentEffects, ...params);

            yield all([
                takeLatestOnSubmitFail(formName, onSubmitFail, ...params),
                saveWrapper(formName, save, success, error, ...params),
                resetWrapper(formName),
                asyncValidatonWrapper(formName, asyncValidation, ...params),
            ]);
            yield call(fn.block);
        } finally {
            yield put(destroy(formName));
        }
    };

export function* scrollToFirstError() {
    yield delay(0);
    const errors = document.querySelectorAll(`.${ERROR_COMPONENT_CLASS}`);
    if (errors.length) {
        errors[0].parentElement.parentElement.parentElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    }
}

export const getErrorsAsString = (meta) => {
    if (meta.touched && meta.error) {
        return meta.error.reduce((accum, element) => (accum += `${element} `), "");
    }
    return "";
};

export function* validateWithRecaptcha(action = "login") {
    try {
        let recaptchaValue;
        if (window && window.grecaptcha && typeof window.grecaptcha.execute === "function") {
            recaptchaValue = yield call(window.grecaptcha.execute, RECAPTCHA_PUBLIC_KEY, { action });
        }
        return recaptchaValue;
    } catch (e) {
        sentry.captureException(e);
        return null;
    }
}

const saveWrapper = (formName, saga, onSuccess, onError, ...params) =>
    takeLatestSubmit(formName, saveForm, saga, onSuccess, onError, params);
const resetWrapper = (formName) => takeLatestReset(formName, resetForm, formName);
const asyncValidatonWrapper = (formName, saga) => takeLatestAsyncValidate(formName, asyncValidationSaga, saga);

function* resetForm(formName) {
    yield put(reduxFormInitialize(formName, {}));
    yield delay(0); // force end of event loop. Otherwise, submit is called with old form values, before reset
    yield put(submit(formName));
}

function* saveForm(saga, onSuccess, onError, params, { meta, payload }) {
    try {
        const result = yield call(saga, payload, ...params);
        yield call(onSuccess, result, ...params);
        yield call(successSubmit, meta);
    } catch (e) {
        if (e && (e.status === fetch.PRECONDITION_FAILED || e.status === fetch.TOO_MANY_REQUESTS)) {
            const errors = yield call(onError, e, payload, ...params);
            if (errors) {
                yield call(failSubmitWithCustomErrors, meta, errors);
            } else {
                yield call(failSubmitWithException, meta, e);
            }
        } else {
            throw e;
        }
    }
}

function* asyncValidationSaga(saga, { payload, meta }) {
    yield delay(500); // debounce
    const result = yield call(saga, payload, meta.field);
    yield call(endAsyncValidation, meta, result);
}
