import { combineReducers as immutableCombineReducers } from "redux-immutable";
import { combineReducers, createReducer } from "@reduxjs/toolkit";

import { ChangeResponse } from "model/form";
import { PensionDss } from "model/pension/dss";
import { ChangeResponseType, PensionDssContract } from "types";

import { clear, setFundsChangeFormChangeType, setPensionDssContractDetail, setPisFormChangeResult } from "./actions";
import {
    ActualStatements,
    ChangePropositionModule,
    ExtraStatements,
    FinancialOperationsTableFilter,
    OtpModule,
    YearStatements,
} from "./modules";

const pensionDssContractDetail = createReducer<PensionDssContract>(PensionDss.fromServer(), (builder) => {
    builder.addCase(setPensionDssContractDetail, (_, { payload }) => payload);
    builder.addCase(clear, () => PensionDss.fromServer());
});

const fundsChangeFormChangeType = createReducer<String>("", (builder) => {
    builder.addCase(setFundsChangeFormChangeType, (_, { payload }) => payload);
    builder.addCase(clear, () => "");
});

const pisFormChangeResult = createReducer<ChangeResponseType>(ChangeResponse.fromServer(), (builder) => {
    builder.addCase(setPisFormChangeResult, (_, { payload }) => payload);
    builder.addCase(clear, () => ChangeResponse.fromServer());
});

export default immutableCombineReducers({
    data: combineReducers({
        pensionDssContractDetail,
        fundsChangeFormChangeType,
        pisFormChangeResult,
    }),
    appState: immutableCombineReducers({
        yearStatements: YearStatements.reducer,
        extraStatements: ExtraStatements.reducer,
        actualStatements: ActualStatements.reducer,
        otp: OtpModule.reducer,
        changeProposition: ChangePropositionModule.reducer,
        financialOperationsFilter: FinancialOperationsTableFilter.reducer,
    }),
});
