export const ERROR_DATA_VALUE = "error_happened";

export const createDataReducer =
    (actionType, initialState = null, clearAction) =>
    (state = initialState, action) => {
        if (clearAction && action.type === clearAction) {
            return initialState;
        } else if (action.type === actionType) {
            return action.payload === null || action.payload === undefined ? initialState : action.payload;
        }
        return state;
    };

export const createDataReducerWithError =
    (actionType, initialState = null, clearAction) =>
    (state = initialState, action) => {
        if (clearAction && action.type === clearAction) {
            return initialState;
        } else if (action.type === actionType) {
            if (action.meta && action.meta.error === true) {
                return ERROR_DATA_VALUE;
            }
            return action.payload || initialState;
        }
        return state;
    };
