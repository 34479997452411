import { NAME } from "./constants";

export const SET_RESULT = `${NAME}/SET_RESULT`;
export const SET_ERROR = `${NAME}/SET_ERROR`;

export const setResult = (phoneNumberShort = null, resendEpinCountExhausted = null, invalidHash = null) => ({
    type: SET_RESULT,
    payload: { phoneNumberShort, resendEpinCountExhausted, invalidHash },
});

export const setError = () => ({
    type: SET_ERROR,
});
