import React from "react";
import PropTypes from "prop-types";

import { GridItem } from "ui-library/layouts";
import { Typography } from "ui-library/atoms";
import { ContractCard, InfoText } from "ui-library/modules";
import { ADAPTIVE } from "ui-library/modules/enums";

import i18n from "core/i18n";
import { dateTimeToLocalFullDate } from "core/formatters";
import { claims } from "core/util";
import userContracts from "core/userContracts";

export const WebclaimIncident = ({ incident, shouldDisplayContractNumber }) => {
    const { t } = i18n.useTranslation();
    const policy = userContracts.useGetContractByContractNumber(incident.get("policyNumber"));
    return (
        <GridItem xs={12}>
            <ContractCard
                variant={ADAPTIVE}
                title={incident.claimSubType}
                subTitleSection={<InfoText icon={"arrow-right"} color="orange" text={t("common.reported")} />}
                imageUrl={claims.getImageCategoryByProductGroupLvl2(policy?.idEnuProductGroupLevelTwo)}
                action={<Typography sx={{ width: "450px" }}>{t("incident.reportedOnlineInfo")}</Typography>}
            >
                {incident.claimNumber && <Typography component="p">{`${t("claim.incidentNumber")} ${incident.claimNumber}`}</Typography>}
                {shouldDisplayContractNumber && (
                    <Typography component="p">{`${t("common.contractNumber")} ${incident.policyNumber}`}</Typography>
                )}
                <Typography component="p">{`${t("common.creationDate")} ${dateTimeToLocalFullDate(incident.eventDate)}`}</Typography>
                <Typography component="p">{`${t("claim.incidentRegistrationDate")} ${dateTimeToLocalFullDate(
                    incident.registrationDate,
                )}`}</Typography>
            </ContractCard>
        </GridItem>
    );
};

WebclaimIncident.propTypes = {
    incident: PropTypes.object.isRequired,
    shouldDisplayContractNumber: PropTypes.bool,
};

WebclaimIncident.defaultProps = {
    shouldDisplayContractNumber: false,
};
