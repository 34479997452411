import React from "react";
import { useSelector } from "react-redux";

import { ContentBox, GridItem, GridLayout } from "ui-library/layouts";
import { Block, Loading, Typography } from "ui-library/atoms";

import fetching from "core/fetching";
import i18n from "core/i18n";
import { TMP_INDIVIDUAL_PROFILE_CONTRACTS } from "app/constants";
import { Field, getFormFieldValue, required, SegmentedControl, SingleSelect } from "core/form";
import { localAmountWithCurrencyLegacy } from "core/formatters";
import pageContext from "core/pageContext";

import { FeatureFlag } from "types";

import { INDIVIDUAL_PROFILE_CODE, PROFILE_CHANGE_FEE, PROFILE_SELECT, REALLOCATE_INVESTMENTS_FIELD } from "../constants";

import { IndividualProfileComponent } from "./IndividualProfileComponent";

export const ProfileChangeComponent = ({
    formName,
    contractNumber,
    availableProfiles,
    availableFundsProfileChangeOptionsSelector,
    isProductTempoCzSelector,
}) => {
    const { t } = i18n.useTranslation();
    const feeValue = useSelector(getFormFieldValue(formName, PROFILE_CHANGE_FEE));
    const selectedProfile = useSelector(getFormFieldValue(formName, PROFILE_SELECT));
    const isIndividualProfile = INDIVIDUAL_PROFILE_CODE === selectedProfile;

    const changeFeeText = feeValue ? t("investments.profileChangeFee", { changeFee: localAmountWithCurrencyLegacy(feeValue) }) : null;
    const fetchingFeeValue = useSelector(fetching.isFetching(fetching.FETCHING_PROFILE_CHANGE_FEE, true));

    const isTempoCz = useSelector(isProductTempoCzSelector);
    const featureEnabled = pageContext.useIsFeatureFlagEnabled(FeatureFlag.INDIVIDUAL_PROFILE_ENABLED);
    const isIndividualProfileEnabledOnContract = TMP_INDIVIDUAL_PROFILE_CONTRACTS.includes(contractNumber);

    const isReallocateToggleVisible = isIndividualProfileEnabledOnContract || (isTempoCz && featureEnabled);

    return (
        <>
            <ContentBox>
                <Loading loading={!availableProfiles}>
                    <Field
                        name={PROFILE_SELECT}
                        component={SingleSelect}
                        label={t("investments.newProfile")}
                        options={availableProfiles}
                        validateStatic={[required]}
                        placeholder={t("investments.chooseProfile")}
                    />
                </Loading>
            </ContentBox>
            {changeFeeText && (
                <Loading loading={fetchingFeeValue}>
                    <ContentBox>
                        <Typography color="blue" markDown={changeFeeText} />
                    </ContentBox>
                </Loading>
            )}

            {isIndividualProfile && (
                <IndividualProfileComponent
                    formName={formName}
                    availableFundsProfileChangeOptionsSelector={availableFundsProfileChangeOptionsSelector}
                />
            )}

            {isReallocateToggleVisible && (
                <ContentBox mb={3}>
                    <GridLayout defaultSpacing>
                        <GridItem xs={12}>
                            <Block variant="white">
                                <Field name={REALLOCATE_INVESTMENTS_FIELD} component={SegmentedControl} />
                                <Typography fontWeight={500} markDown={t("investments.profileChange.transferInvestedAmountText")} />
                            </Block>
                        </GridItem>
                    </GridLayout>
                </ContentBox>
            )}

            <ContentBox>
                <Typography
                    markDown={
                        isReallocateToggleVisible ? t("investments.profileChangeInfoText.tempoCz") : t("investments.profileChangeInfoText")
                    }
                />
            </ContentBox>
            <ContentBox>
                <Typography
                    markDown={isReallocateToggleVisible ? t("investments.profileChange.tempoCz") : t("investments.profileChange")}
                />
            </ContentBox>
        </>
    );
};
