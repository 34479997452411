import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchingState, initialState, NAME, SetFetchingInternalPayload, SetFetchingPayload } from "./constants";

const setFetching: CaseReducer<FetchingState, PayloadAction<SetFetchingPayload>> = (state, { payload }) => {
    // fetching in saga
};

const setFetchingInternal: CaseReducer<FetchingState, PayloadAction<SetFetchingInternalPayload>> = (state, { payload }) => {
    state[payload.entity] = payload.isFetching;
};

const clear: CaseReducer<FetchingState, PayloadAction<string>> = (state, { payload }) => {
    state[payload] = undefined;
};

export const slice = createSlice({
    name: NAME,
    initialState,
    reducers: {
        setFetching: {
            reducer: setFetching,
            prepare: (entity: string, isFetching: boolean, immediate: boolean = false) => ({
                payload: { entity, isFetching, immediate },
            }),
        },
        clear,
        setFetchingInternal: {
            reducer: setFetchingInternal,
            prepare: (entity: string, isFetching: boolean) => ({
                payload: { entity, isFetching },
            }),
        },
    },
});

export default slice;
