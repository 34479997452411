import React, { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { BannerFlexbox, Section } from "ui-library/layouts";
import { Button } from "ui-library/atoms";

import i18n from "core/i18n";
import router from "core/router";
import { PensionDssFundsChangeType, PensionInvestmentStrategy } from "enums";
import { Pages, Tabs } from "routeConstants";

import { getPensionAccountDetail } from "../selectors";

export const InvestmentsChangeButtonsSection: FC = () => {
    const { t } = i18n.useTranslation();
    const navigate = useNavigate();

    const { idObject } = useParams();
    const formLink = (changeType) => router.getStaticUrl(Pages.CONTRACT_DSS, Tabs.PENSION_DSS_FUNDS_CHANGE_FORM, { idObject, changeType });
    const pensionAccountDetail = useSelector(getPensionAccountDetail);
    const isCombinedStrategy = pensionAccountDetail?.investmentStrategy === PensionInvestmentStrategy.COMBINED.id;

    return (
        <>
            {isCombinedStrategy ? (
                <Section>
                    <Button color="green" variant="contained" onClick={() => navigate(formLink(PensionDssFundsChangeType.COMBINED.id))}>
                        {t("common.changeInvestments")}
                    </Button>
                </Section>
            ) : (
                <BannerFlexbox>
                    <Button color="green" variant="contained" onClick={() => navigate(formLink(PensionDssFundsChangeType.COMPULSORY.id))}>
                        {`${t("common.changeInvestments")} (${t("pension.compulsory")})`}
                    </Button>
                    <Button color="green" variant="contained" onClick={() => navigate(formLink(PensionDssFundsChangeType.VOLUNTARY.id))}>
                        {`${t("common.changeInvestments")} (${t("pension.voluntary")})`}
                    </Button>
                </BannerFlexbox>
            )}
        </>
    );
};
