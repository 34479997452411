import React, { FC } from "react";
import { useSelector } from "react-redux";

import { formatYesNo } from "core/formatters";
import i18n from "core/i18n";
import { InfoPanel, LabelTextItem } from "ui-library/modules";
import { ContentBox, GridItem } from "ui-library/layouts";

import { getPensionTypes } from "../selectors";

const OLD_PENSION_PLAN = "1";

type PensionTypesGridItemProps = {
    mdSize: number;
};

export const PensionTypesGridItem: FC<PensionTypesGridItemProps> = ({ mdSize }) => {
    const { t } = i18n.useTranslation();
    const pensionTypes = useSelector(getPensionTypes);
    const policyConditionsText =
        pensionTypes.pensionPlanCode === OLD_PENSION_PLAN
            ? t("pension.pds.policyConditionsFirst")
            : t("pension.pds.policyConditionsSecond");

    return (
        <GridItem display="flex" xs={12} md={mdSize}>
            <InfoPanel icon="document" title={t("pension.holderPensionTypes")}>
                <ContentBox>
                    <LabelTextItem label={t("common.policyConditions")} text={policyConditionsText} />
                    <LabelTextItem label={t("pension.retirement")} text={formatYesNo(t, pensionTypes.retirementPension)} />
                    <LabelTextItem label={t("pension.disability")} text={formatYesNo(t, pensionTypes.disabilityPension)} />
                </ContentBox>
            </InfoPanel>
        </GridItem>
    );
};
