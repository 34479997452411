import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { CodeValueLegacy } from "model/common";
import i18n from "core/i18n";
import localized from "core/localized";
import { MutualFundsPeriodicity } from "enums";

import { formatLocalizedValueMap } from "./productFormatter";
import { Typography } from "ui-library/atoms";

const FundFrequencyFormatter = ({ periodicityType, frequency, frequencyMap }) => {
    const { t } = i18n.useTranslation();

    const frequencyMsg =
        periodicityType === MutualFundsPeriodicity.ONETIME.id ? t("common.oneTime") : formatLocalizedValueMap(frequency.code, frequencyMap);
    return <Typography>{frequencyMsg}</Typography>;
};

FundFrequencyFormatter.propTypes = {
    frequencyMap: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
    periodicityType: PropTypes.string.isRequired,
    frequency: PropTypes.instanceOf(CodeValueLegacy).isRequired,
};

const mapStateToProps = (state) => ({
    frequencyMap: localized.getLocalizedFrequencyMap(state),
});

export default connect(mapStateToProps)(FundFrequencyFormatter);
