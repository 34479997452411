import React from "react";
import PropTypes from "prop-types";

import { Typography } from "ui-library/atoms";
import { ContentBox, Section } from "ui-library/layouts";

import i18n from "core/i18n";
import { FormWideDisabledProvider } from "core/form";
import { PensionDssFundsChangeType } from "enums";

import { TRANSFER_FUNDS_DATA } from "../constants";
import { PercentageSelectDss } from "../components";
import { TransferCombinedMandatoryDisclaimer, TransferExample, TransferVoluntaryDisclaimer } from "../components/textBlock";

const DssTransferSection = ({ formName, changeType, actualStrategies, isTransferFundsDataPossible, disabled = false }) => {
    const { t } = i18n.useTranslation();
    const isVoluntary = changeType === PensionDssFundsChangeType.VOLUNTARY.id;
    const isDisabled = !isTransferFundsDataPossible || disabled;

    return (
        <Section>
            <FormWideDisabledProvider value={isDisabled}>
                {/* Section title */}
                <ContentBox>
                    <Typography variant="h3">{`1. ${t("pension.fundsChange.transferPensionUnits.title")}`}</Typography>
                </ContentBox>

                {/* Section Disclaimer */}
                {!isVoluntary && <TransferCombinedMandatoryDisclaimer />}
                {isVoluntary && <TransferVoluntaryDisclaimer />}

                {/* Transfer example */}
                <TransferExample />

                {/* Form title */}
                <ContentBox>
                    <Typography fontWeight={600} component="p">
                        {t("pension.fundsChange.transferPensionUnits.choose")}
                    </Typography>
                </ContentBox>

                {/* Form or not allowed at the moment text. */}
                {isTransferFundsDataPossible ? (
                    <PercentageSelectDss formName={formName} sectionName={TRANSFER_FUNDS_DATA} actualStrategies={actualStrategies} />
                ) : (
                    <ContentBox>
                        <Typography color="red" component="p">
                            {t("pension.fundsChange.unprocessedTransfer")}
                        </Typography>
                    </ContentBox>
                )}
            </FormWideDisabledProvider>
        </Section>
    );
};

DssTransferSection.propTypes = {
    formName: PropTypes.string.isRequired,
    changeType: PropTypes.string.isRequired,
    isTransferFundsDataPossible: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    actualStrategies: PropTypes.object.isRequired,
};

export default DssTransferSection;
