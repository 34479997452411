import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ColorDot, Typography } from "ui-library/atoms";
import { Box } from "ui-library/layouts/Box/Box";
import { Flexbox } from "ui-library/layouts/Flexbox/Flexbox";
import PropTypes from "prop-types";
import React from "react";
import { Pie, ResponsiveContainer, PieChart as PieChartRecharts, Cell, Tooltip, Legend } from "recharts";
import { StyledLegend } from "ui-library/Styled";

const CustomLegend = ({ payload, legendTitle, legendValue }) => {
    return (
        <Box>
            <StyledLegend direction={{ xs: "row" }} justifyContent="space-between">
                <Typography variant={"b"}>{legendTitle}</Typography>
                <Typography variant={"b"}>{legendValue}</Typography>
            </StyledLegend>
            {payload.map((entry, index) => (
                <StyledLegend direction={{ xs: "row" }} key={index} justifyContent="space-between">
                    <Flexbox direction={{ xs: "row" }} gap={2} alignItems={"center"}>
                        <ColorDot color={entry.color} />
                        <Typography>{entry.payload.name}</Typography>
                    </Flexbox>

                    <Typography>{entry.payload.fullValue}</Typography>
                </StyledLegend>
            ))}
        </Box>
    );
};

export const PieChart = ({ data, legendTitle, legendValue, ...props }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("md"));

    const legendStyle = {
        width: matches ? "70%" : "100%",
        paddingLeft: matches ? "54px" : "0",
    };

    const calculateHeight = () => {
        if (matches) {
            return data.length * 50 < 180 ? 180 : data.length * 50;
        } else {
            return data.length * 70 + 200;
        }
    };

    return (
        <ResponsiveContainer height={calculateHeight()}>
            <PieChartRecharts width={300}>
                <Pie dataKey="value" data={data} cx="50%" cy="50%" outerRadius={80} innerRadius={50}>
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={theme.palette.chartColors[entry.color]} strokeWidth={1} />
                    ))}
                </Pie>

                <Tooltip cursor={false} />
                <Legend
                    wrapperStyle={legendStyle}
                    layout={matches ? "vertical" : "horizontal"}
                    align={matches ? "right" : "left"}
                    verticalAlign={matches ? "right" : "bottom"}
                    content={<CustomLegend legendTitle={legendTitle} legendValue={legendValue} />}
                />
            </PieChartRecharts>
        </ResponsiveContainer>
    );
};

PieChart.propTypes = {
    data: PropTypes.array,
    legendTitle: PropTypes.string,
    legendValue: PropTypes.string,
};

PieChart.defaultProps = {};
