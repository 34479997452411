import { nanoid } from "@reduxjs/toolkit";

import { model } from "core/util";
import {
    PensionDssDdsFundInvestmentStrategy as PensionDssDdsFundInvestmentStrategyType,
    PensionDssDdsFundInvestmentStrategyDto,
} from "types";

import { Amount, CodeValue } from "../common";

export const PensionDssDdsFundInvestmentStrategy = model.createModel<
    PensionDssDdsFundInvestmentStrategyDto,
    PensionDssDdsFundInvestmentStrategyType
>((json) => ({
    reactKey: nanoid(),
    investmentFund: CodeValue.fromServer(json.InvestmentFund),
    pricePerShare: Amount.fromServer(json.PricePerShare),
    investmentPercentage: json.InvestmentPercentage,
}));
