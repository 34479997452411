import React from "react";
import { useSelector } from "react-redux";
import { Flexbox } from "ui-library/layouts";

import { QrCodeBase64 } from "containers";

import { getSkQrCode } from "../selectors";
import payBySquareLogo from "../assets/pay_by_square_logo.png";

const SkQrCode = () => {
    const skQrCode = useSelector(getSkQrCode);
    return (
        skQrCode && (
            <Flexbox direction={{ xs: "column" }} gap={1}>
                <QrCodeBase64 base64Img={`data:image/png;base64, ${skQrCode}`} />
                <img style={{ width: "140px" }} src={payBySquareLogo} alt="PAY by square logo" />
            </Flexbox>
        )
    );
};

export default SkQrCode;
