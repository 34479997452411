import { StyledOptionGroup } from "ui-library/Styled";
import React from "react";
import PropTypes from "prop-types";
import { Flexbox } from "ui-library/layouts/Flexbox/Flexbox";

export const OptionGroup = ({ icon, children, ...props }) => (
    <StyledOptionGroup>
        <Flexbox sx={{ gap: "8px" }} alignItems={"center"} {...props}>
            {icon}
            {children}
        </Flexbox>
    </StyledOptionGroup>
);

OptionGroup.propTypes = {
    icon: PropTypes.node,
};
