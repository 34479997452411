import { model } from "core/util";

import { AmountLegacy, CodeValueLegacy } from "../common";

export const LifeFinancialOperation = model.createModelLegacy("LifeFinancialOperation", (json) => ({
    id: json.Id,
    type: CodeValueLegacy.fromServer(json.Type),
    grossAmount: AmountLegacy.fromServer(json.GrossAmount),
    netAmount: AmountLegacy.fromServer(json.NetAmount),
    valueDate: json.ValueDate,
    investmentDate: json.InvestmentDate,
    status: json.status,
}));
