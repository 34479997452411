import { Container } from "ui-library/atoms";
import PropTypes from "prop-types";
import { StyledPromoBlock } from "ui-library/Styled";

export const PromoBlock = ({ hasOffests, variant, children, ...props }) => {
    return (
        <StyledPromoBlock $hasOffsets={hasOffests} padding={false} variant={variant} {...props}>
            <Container>{children}</Container>
        </StyledPromoBlock>
    );
};

PromoBlock.propTypes = {
    variant: PropTypes.string,
    hasOffests: PropTypes.bool,
};

PromoBlock.defaultProps = {
    variant: "clean,",
};
