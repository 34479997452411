import { model } from "core/util";
import { LegalEntityLegacy } from "model/common/LegalEntityLegacy";
import { NonLifeVinculationAndLease } from "model/nonLife/NonLifeVinculationAndLease";

export const Vehicle = model.createModelLegacy("Vehicle", (json) => ({
    manufacturer: json.Manufacturer,
    model: json.Model,
    type: json.Type,
    vinNumber: json.VinNumber,
    licencePlateNumber: json.LicencePlateNumber,
    vehicleLicenceNumber: json.VehicleLicenceNumber,
    hasActiveMTPL: json.HasActiveMTPL,
    whiteCardNumber: json.WhiteCardNumber,
    greenCardNumber: json.GreenCardNumber,
    greenCardValidFrom: json.GreenCardValidFrom,
    greenCardValidTo: json.GreenCardValidTo,
    leasingInformations: NonLifeVinculationAndLease.fromServerList(json.LeasingInformation),
    vehicleOwners: LegalEntityLegacy.fromServerList(json.VehicleOwner),
    vehicleOperators: LegalEntityLegacy.fromServerList(json.VehicleOperator),
}));
