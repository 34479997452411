import React, { FC } from "react";
import { useSelector } from "react-redux";

import useBreakpoints from "ui-library/theme/breakpoints";
import userInfo from "core/userInfo";
import userContracts from "core/userContracts";

import {
    FinOperationsOverviewInfoPanel,
    MarketingCommunicationInfoPanel,
    QuickPaymentInfoPanel,
    SetElectronicCommunicationInfoPanel,
} from "./rightPanels";
import { UnverifiedUserPanel } from "./UnverifiedUserPanel";

export const SideInfoPanels: FC = () => {
    const breakpointMd = useBreakpoints("md");
    const isUserVerified = useSelector(userInfo.getIsVerifiedUser);
    const contractsWithoutElCom = useSelector(userContracts.getContractsWithoutElCom);

    return (
        <>
            {breakpointMd && !isUserVerified && <UnverifiedUserPanel />}

            {contractsWithoutElCom.length > 0 && <SetElectronicCommunicationInfoPanel />}

            <QuickPaymentInfoPanel />

            <FinOperationsOverviewInfoPanel />

            <MarketingCommunicationInfoPanel />
        </>
    );
};
