import React from "react";
import PropTypes from "prop-types";

import { ModalFormLayout } from "containers/modal";
import { Field, form, InputText, lettersNumbersBasicInterpunctionCharacters, max50Characters, required } from "core/form";
import i18n from "core/i18n";
import { ModalBody, ModalFooter } from "ui-library/modules";
import { Box, ContentBox } from "ui-library/layouts";
import { Button, Loading } from "ui-library/atoms";
import { ContractNameModalSuccess } from "./ContractNameModalSuccess";

const ContractNameForm = ({ canSubmit, handleSubmit, submitting }) => {
    const { t } = i18n.useTranslation();
    return (
        <>
            <ModalBody>
                <Loading loading={submitting}>
                    <Box>
                        <ContentBox>
                            <Field
                                name="contractName"
                                component={InputText}
                                label={t("common.customContractNameFull")}
                                validateStatic={[required]}
                                normalize={[lettersNumbersBasicInterpunctionCharacters, max50Characters]}
                                onEnter={handleSubmit}
                            />
                        </ContentBox>
                    </Box>
                </Loading>
            </ModalBody>
            <ModalFooter>
                <Button color="blue" variant="contained" onClick={handleSubmit} disabled={!canSubmit}>
                    {t("common.submitChange")}
                </Button>
            </ModalFooter>
        </>
    );
};

ContractNameForm.propTypes = {
    canSubmit: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
};

export const createContainer = (formName) =>
    form(formName)((props) => {
        const { t } = i18n.useTranslation();
        return (
            <ModalFormLayout
                formName={formName}
                formTitle={t("form.customContractName")}
                formIcon="contract"
                SuccessComponent={ContractNameModalSuccess}
            >
                <ContractNameForm {...props} />
            </ModalFormLayout>
        );
    });
