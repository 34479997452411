import { fetch } from "core/util";
import { API_PREFIX } from "app/constants";
import { PaymentInfo, PaymentLink, PaymentStatus } from "model/payment";

export default {
    get: (productGroup, idObject) => fetch.doGet(`${API_PREFIX}/payment/${productGroup}/${idObject}`, null, PaymentInfo.fromServer),
    getPaymentLink: (productGroup, idObject, amountType) =>
        fetch.doGet(`${API_PREFIX}/payment/${productGroup}/${idObject}/${amountType}/get-payment-link`, null, PaymentLink.fromServer),
    getPaymentStatus: (requestId) => fetch.doGet(`${API_PREFIX}/payment/${requestId}/get-payment-status`, null, PaymentStatus.fromServer),
};
