import React from "react";

import { Typography } from "ui-library/atoms";
import { fn } from "core/util";

import { localAmountInvestmentWithCurrencyEurLegacy, localAmountWithCurrencyLegacy } from "./localAmountFormatter";

/**
 *  Create twoLineAmoutWithCurrencyFormater used in table formaters
 *  prices - contans price text value to show in first line and perShare in second
 *  12254,21 Kc
 *  (4544,45 Kc)
 */
export const twoLineAmountWithCurrencyFormatter = (prices) => {
    if (!prices || fn.isEmpty(prices.get("price")) || fn.isEmpty(prices.getIn(["price", "value"]))) {
        return "";
    }
    if (fn.isEmpty(prices.get("perShare")) || fn.isEmpty(prices.getIn(["perShare", "value"]))) {
        return (
            <span>
                <Typography>{localAmountWithCurrencyLegacy(prices.get("price"))}</Typography>
            </span>
        );
    }
    return (
        <span>
            <Typography>{localAmountWithCurrencyLegacy(prices.get("price"))}</Typography>
            <Typography>{`(${localAmountInvestmentWithCurrencyEurLegacy(prices.get("perShare"))})`}</Typography>
        </span>
    );
};
