import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Typography } from "ui-library/atoms";
import { ContentBox } from "ui-library/layouts";
import { LabelTextItem } from "ui-library/modules";

import i18n from "core/i18n";
import modal from "core/modal";

import { FORM_EDIT_INVESTMENT_STRATEGY } from "../constants";
import { getPensionAccountDetail } from "../selectors";

export const ContractInvestmentParamsContentBox = () => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();

    const pensionAccountDetail = useSelector(getPensionAccountDetail);
    const investmentStrategy = pensionAccountDetail?.investmentStrategy || "";
    const openModalForm = () => dispatch(modal.openModalForm(FORM_EDIT_INVESTMENT_STRATEGY));

    return (
        <ContentBox mb={3}>
            <LabelTextItem
                label={t("pension.dss.investmentStrategy")}
                text={
                    <>
                        <Typography component="p">{t(`pension.dss.investmentStrategy.${investmentStrategy.toLowerCase()}`)}</Typography>
                        <Button onClick={openModalForm} color="blue" variant="text">
                            {t("common.change")}
                        </Button>
                    </>
                }
            />
            <Typography fontSize={14} markDown={t("pension.dss.investmentStrategy.description")} />
        </ContentBox>
    );
};
