import React, { FC, PropsWithChildren } from "react";
import { useSelector } from "react-redux";

import { isServiceInErrorState } from "./selectors";

export interface ModalErrorBoundaryProps extends PropsWithChildren {
    service: string;
    closeModal: any;
    ErrorComponent: React.ComponentType<{ closeModal: any }>;
}

export const ModalErrorBoundary: FC<ModalErrorBoundaryProps & any> = ({ children, service, closeModal, ErrorComponent, ...props }) => {
    const isServiceInError = useSelector(isServiceInErrorState(service));

    if (isServiceInError) {
        return <ErrorComponent closeModal={closeModal} {...props} />;
    } else {
        return <>{children}</>;
    }
};
