import { model } from "core/util";

import { AmountLegacy, SimpleCodeValue } from "../common";

export const UniqaLifeInvestmentFund = model.createModelLegacy("UniqaLifeInvestmentFund", (json) => ({
    investmentFund: SimpleCodeValue.fromServer(json.InvestmentFund),
    investmentPercentage: json.InvestmentPercentage,
    investmentFundType: SimpleCodeValue.fromServer(json.InvestmentFundType),
    investmentFundUnits: json.InvestmentFundUnits,
    investmentFundUnitValue: AmountLegacy.fromServer(json.InvestmentFundUnitValue),
    investmentFundValue: AmountLegacy.fromServer(json.InvestmentFundValue),
    investmentProgram: SimpleCodeValue.fromServer(json.InvestmentProgram),
}));
