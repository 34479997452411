import { call } from "redux-saga/effects";

import router from "core/router";
import campaignElectronicCommunicationForm from "form/inPage/campaignElectronicCommunication";
import { Tabs } from "routeConstants";
import { campaignApi } from "serverApi";

import { setElectronicCommunicationCampaignData } from "./actions";
import { FORM_CAMPAIGN_ELECTRONIC_COMMUNICATION } from "./constants";

export default router.routerWrapper({
    *getDataForPage({ guid, idObject }) {
        const elComCampaignData = yield call(campaignApi.getElectronicCommunicationData, guid, idObject);

        return [setElectronicCommunicationCampaignData(elComCampaignData)];
    },
    *onInnerRouteChange(name, params) {
        yield call(routeChangedSaga, name, params);
    },
    clearDataForPage() {
        return [setElectronicCommunicationCampaignData(null)];
    },
});

function* routeChangedSaga(name: string, params: any) {
    switch (name) {
        case Tabs.ELECTRONIC_COMMUNICATION: {
            yield call(campaignElectronicCommunicationFormSaga, params.guid, params.idObject);
            break;
        }
        default:
            break;
    }
}

const campaignElectronicCommunicationFormSaga = (guid: string, idObject: number) =>
    campaignElectronicCommunicationForm.createSaga(
        FORM_CAMPAIGN_ELECTRONIC_COMMUNICATION,
        setElectronicCommunicationCampaignData,
        guid,
        idObject,
    );
