import React from "react";
import PropTypes from "prop-types";

import { Typography } from "ui-library/atoms";
import { ContentBox } from "ui-library/layouts";

export const SectionTitle = ({ title }) => (
    <ContentBox>
        <Typography variant="h3">{title}</Typography>
    </ContentBox>
);

SectionTitle.propTypes = {
    title: PropTypes.string.isRequired,
};
