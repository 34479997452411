import React from "react";
import PropTypes from "prop-types";

/**
 * Form section; used for scrolling: scrollToFirstError should scroll to the start of this section.
 * not yet implemented correctly, see AXAMEDEA-1102
 */
const FormSection = ({ children }) => <div>{children}</div>;

FormSection.propTypes = {
    children: PropTypes.node.isRequired,
};

export default FormSection;
