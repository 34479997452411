import { createSaga } from "./createSaga";
import { createContainer } from "./createContainer";

/**
 * Saga and Container creator for active operations phone number form
 */
const activeOperationsPhoneNumberForm = {
    createSaga,
    createContainer,
};

export default activeOperationsPhoneNumberForm;
