import React, { FC } from "react";

import { Box, ContentBox, Flexbox } from "ui-library/layouts";
import { Typography } from "ui-library/atoms";
import { ContractPolicy } from "types";

export interface FinOperationsOverviewItemProps {
    contract: ContractPolicy;
    isThisMonth?: boolean;
}

export const FinOperationsOverviewItem: FC<FinOperationsOverviewItemProps> = ({ contract, isThisMonth }) => {
    const movements = isThisMonth
        ? contract.financialOperations?.thisMonthMovements || []
        : contract.financialOperations?.lastMountMovements || [];

    return (
        <ContentBox>
            <Box>
                <Typography color={"gray"} variant="caption">
                    {`${contract.contractName ? contract.contractName : contract.productCRM}, ${contract.contractNumber}`}
                </Typography>
            </Box>
            {movements.map((movement) => (
                <Flexbox key={movement.id} justifyContent={"space-between"}>
                    <Typography variant="p">{movement.description}</Typography>
                    <Typography variant="p">{movement.amount}</Typography>
                </Flexbox>
            ))}
        </ContentBox>
    );
};
