import React, { FC } from "react";
import { useSelector } from "react-redux";

import { ModalBody, ModalFooter } from "ui-library/modules";
import { Box } from "ui-library/layouts";
import { Button, ImportantAnnouncements } from "ui-library/atoms";

import i18n from "core/i18n";
import errorHandling from "core/errorHandling";

import { ErrorDateAndIdentifier } from "../ErrorDateAndIdentifier";

export interface ModalEditFormErrorProps {
    service: string;
    closeModal?: any;
    title?: string;
}

export const ModalEditFormError: FC<ModalEditFormErrorProps> = ({ closeModal, title, service }) => {
    const { t } = i18n.useTranslation();
    const errorIdentifiers = useSelector(errorHandling.getErrorsForService(service));

    return (
        <>
            <ModalBody>
                <Box>
                    {errorIdentifiers.map((errorIdentifier, i) => (
                        <ImportantAnnouncements
                            key={i}
                            variant={"ERROR"}
                            data={{
                                heading: title || t("error.editFormUnavailable.title"),
                                content: (
                                    <ErrorDateAndIdentifier
                                        errorMsgKey="error.editFormUnavailable.text"
                                        errorIdentifier={errorIdentifier}
                                    />
                                ),
                            }}
                        />
                    ))}
                </Box>
            </ModalBody>
            <ModalFooter>
                <Button onClick={closeModal} color="blue" variant="contained">
                    {t("common.close")}
                </Button>
            </ModalFooter>
        </>
    );
};
