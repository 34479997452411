import React, { FC } from "react";
import { useSelector } from "react-redux";

import i18n from "core/i18n";
import entity from "core/entity";
import fetching from "core/fetching";
import { documentsApi } from "serverApi";
import { localFullDate } from "core/formatters";
import { Box, GridItem } from "ui-library/layouts";
import { FileInfo, InfoPanel } from "ui-library/modules";
import { Loading, StandaloneLink, Typography } from "ui-library/atoms";

const prepareDocumentLine = (variant, idObject, document) => (
    <FileInfo
        fullWidth={variant === "LONG"}
        key={document.id}
        icon={"download"}
        fileName={document.typeLocalName}
        onClick={() => window.open(documentsApi.getContractDocumentDownloadUrl(idObject, document.guid))}
        fileDate={variant === "LONG" ? localFullDate(document.date) : null}
        fileSize={"PDF"}
    />
);

export interface ContractDocumentsOverviewGridItemProps {
    variant?: "SHORT" | "LONG";
    entityName: string;
    idObject: number;
    detailLink: string;
}

export const ContractDocumentsOverviewGridItem: FC<ContractDocumentsOverviewGridItemProps> = ({
    variant,
    entityName,
    idObject,
    detailLink,
}) => {
    const { t } = i18n.useTranslation();

    const isDocumentsLoading = useSelector(fetching.isFetching(entityName, true));
    const isDocumentsError = useSelector(entity.isError(entityName));
    const contractDocuments = useSelector(entity.getData(entityName));
    const mappedContractDocuments =
        contractDocuments &&
        contractDocuments
            .take(3)
            .map((document) => prepareDocumentLine(variant, idObject, document))
            .toArray();

    const documentsSection =
        mappedContractDocuments && !mappedContractDocuments.length ? (
            <Typography>{t("documents.noDocuments")}</Typography>
        ) : (
            mappedContractDocuments
        );

    return (
        <GridItem display={"flex"} md={variant === "LONG" ? 12 : 7} xs={12}>
            <InfoPanel
                icon={"document"}
                title={t("common.documents")}
                headerAction={
                    <StandaloneLink to={detailLink} color="blue">
                        {t("common.all")}
                    </StandaloneLink>
                }
            >
                <Box>
                    <Loading loading={isDocumentsLoading}>
                        {!isDocumentsError ? documentsSection : <Typography>{t("error.sectionUnavailable")}</Typography>}
                    </Loading>
                </Box>
            </InfoPanel>
        </GridItem>
    );
};
