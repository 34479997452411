import createReducer from "./reducer";
import createActions from "./actions";
import createSelectors from "./selectors";
import createContainer from "./Container";
import createSagaDataInitialization from "./sagaDataInitialization";
import createSaga from "./saga";

export default ({ reducerPath, actionPrefix, idObjectSelector, contractConstant, propositionsEntity }) => {
    const actions = createActions(actionPrefix);
    const selectors = createSelectors(reducerPath, propositionsEntity);
    const sagaDataInitialization = createSagaDataInitialization(actions, propositionsEntity);
    const saga = createSaga(actions, selectors, propositionsEntity);

    const Container = createContainer(propositionsEntity, actions, selectors, idObjectSelector, contractConstant);
    const reducer = createReducer(actions);

    return {
        sagaDataInitialization,
        saga,
        Container,
        selectors,
        reducer,
        getChangeProposition: selectors.getChangeProposition,
    };
};
