import React, { FC, PropsWithChildren } from "react";
import PropTypes from "prop-types";

import { Block, Icon, Typography } from "ui-library/atoms";
import { Box } from "ui-library/layouts";
import { StyledImportantAnnouncement } from "ui-library/Styled";
import { BoxProps } from "@mui/material";

export interface ImportantAnnouncementsProps extends PropsWithChildren {
    action?: any;
    data?: {
        heading: string;
        content?: string | React.ReactNode;
    };
    variant: "SUCCESS" | "ERROR"; //todo to enum
    shadow?: boolean;
}

export const ImportantAnnouncements: FC<ImportantAnnouncementsProps> = ({ children, action, data, variant, ...props }) => (
    <StyledImportantAnnouncement>
        <Block position="relative" variant={variant === "ERROR" ? "redGradient" : "greenGradient"} shadow={props.shadow}>
            <Box display="flex">
                <span>
                    <Icon
                        size={16}
                        color={variant === "ERROR" ? "red" : "green"}
                        icon={variant === "ERROR" ? "circle-error" : "circle-check"}
                    />
                </span>

                {data ? (
                    <div className={"content"}>
                        <Typography variant="h3">{data.heading}</Typography>
                        {data?.content &&
                            (React.isValidElement(data.content) ? (
                                <>{data.content}</>
                            ) : (
                                <Typography color="gray" variant="p" markDown={data.content} internalAppLink />
                            ))}
                    </div>
                ) : (
                    <div className={"content"}>{children}</div>
                )}
            </Box>
            {action}
        </Block>
    </StyledImportantAnnouncement>
);
