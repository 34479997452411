import slice from "./slice";
import * as selectors from "./selectors";
import * as hooks from "./hooks";

/**
 * Module for access to page context across the application.
 */
const pageContext = {
    NAME: slice.name,
    reducer: slice.reducer,
    ...slice.actions,
    ...selectors,
    ...hooks,
};

export default pageContext;
