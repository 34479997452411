import { Record } from "immutable";

class PaymentMethod extends Record({ id: null, tabId: null, msg: null }) {}

export default Object.freeze({
    TRANSFER: new PaymentMethod({
        id: "TRANSFER",
        tabId: 0,
        msg: "payments.method.transfer",
    }),
    ONLINE_PAYMENT: new PaymentMethod({
        id: "ONLINE_PAYMENT",
        tabId: 1,
        msg: "payments.method.onlinePayment",
    }),
});
