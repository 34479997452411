import { model } from "core/util";
import { PensionDpsContractDetail as PensionDpsContractDetailType, PensionDpsContractDetailDto } from "types";

import { Holder } from "../../holder";
import { ElectronicCommunication } from "../../electronicCommunication";
import { PensionDpsAccountDetail } from "./PensionDpsAccountDetail";
import { PensionBeneficiary } from "../PensionBeneficiary";
import { PensionStatementSettings } from "../PensionStatementSettings";

export const PensionDpsContractDetail = model.createModel<PensionDpsContractDetailDto, PensionDpsContractDetailType>((json) => ({
    holder: Holder.fromServer(json.Holder),
    pensionDpsAccountDetail: PensionDpsAccountDetail.fromServer(json.PensionAccountDetail),
    beneficiaries: PensionBeneficiary.fromServerList(json.Beneficiaries),
    electronicCommunication: ElectronicCommunication.fromServer(json.ElectronicCommunication),
    statementSettings: PensionStatementSettings.fromServer(json.ClientStatementSettings),
}));
