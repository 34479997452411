import React, { FC } from "react";

import { Typography } from "ui-library/atoms";

import { fn, model, nbsp } from "core/util";
import { MutualFunds } from "enums";
import i18n from "core/i18n";

const VS_SYMBOL = "VS";

export interface FormatMutualFundsAccountPaymentProps {
    value: any; // MutualFundsAccountPaymentDetailLegacy
}

export const FormatMutualFundsAccountPayment: FC<FormatMutualFundsAccountPaymentProps> = ({ value }) => {
    const { t } = i18n.useTranslation();
    const locale = i18n.getLanguageCode();

    if (!value) {
        return null;
    }

    // Transfer to fund.
    if (value.get("fund")?.code) {
        const fundToValue = model.getLocaleCodeName(value.get("fund"), locale);
        if (fundToValue) {
            return <Typography>{fundToValue}</Typography>;
        } else {
            const fundEnum = fn.getEnumItemByCode(MutualFunds, value.get("fund")?.code);
            return <Typography>{t(fundEnum?.msg)}</Typography>;
        }
    }

    // Transfer to bank account.
    const ibanValue = value.get("iban");
    if (ibanValue) {
        const variableSymbolValue = value.get("variableSymbol") ? value.get("variableSymbol") : "";
        const secondDisplay = variableSymbolValue ? `${VS_SYMBOL}:${nbsp}${variableSymbolValue}` : "";
        return (
            <span>
                <Typography>{ibanValue}</Typography>
                <Typography>{secondDisplay}</Typography>
            </span>
        );
    }

    const accountNumberValue = value.get("accountNumber");
    const bankCodeValue = value.getIn(["bankNumber", "code"]);
    if (accountNumberValue && bankCodeValue) {
        const accountPrefix = value.get("accountNumberPrefix") ? `${value.get("accountNumberPrefix")}-` : "";
        const variableSymbolValue = value.get("variableSymbol") ? value.get("variableSymbol") : "";
        const firstDisplay = `${accountPrefix}${accountNumberValue}/${bankCodeValue}`;
        const secondDisplay = `${VS_SYMBOL}:${nbsp}${variableSymbolValue}`;
        return (
            <span>
                <Typography>{firstDisplay}</Typography>
                <Typography>{secondDisplay}</Typography>
            </span>
        );
    }

    return <>-</>;
};
