import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { StandaloneLink } from "ui-library/atoms";
import { Box, GridItem } from "ui-library/layouts";
import { InfoPanel, LabelTextItem } from "ui-library/modules";

import i18n from "core/i18n";
import localized from "core/localized";
import { date, fn } from "core/util";

export const NonLifeContractOverviewGridItem = ({ policy, actualScheduler, detailLink }) => {
    const { t } = i18n.useTranslation();
    const formattedSchedulerPayment = useSelector(
        localized.getLocalizedSchedulerPaymentFullLegacy(fn.getItemOrFirstItemWhenIterable(actualScheduler)),
    );

    return (
        <GridItem display={"flex"} md={7} xs={12}>
            <InfoPanel
                icon={"contract"}
                title={t("common.contract")}
                headerAction={
                    <StandaloneLink to={detailLink} color="blue">
                        {t("common.detail")}
                    </StandaloneLink>
                }
            >
                <Box>
                    <LabelTextItem
                        label={t("insurance.insuranceTo")}
                        text={date.formatToDateWithUnlimited(policy?.endDate, t("common.unlimitedDate"))}
                    />
                    <LabelTextItem label={t("insurance.insuranceCost")} text={formattedSchedulerPayment} />
                </Box>
            </InfoPanel>
        </GridItem>
    );
};

NonLifeContractOverviewGridItem.propTypes = {
    policy: PropTypes.object.isRequired,
    actualScheduler: PropTypes.object.isRequired,
    detailLink: PropTypes.string.isRequired,
};
