import React from "react";
import { StyledUnorderedList } from "ui-library/Styled";
import { ListItem } from "./ListItem";
import PropTypes from "prop-types";
import { Typography } from "ui-library/atoms/Typography/Typography";

export const UnorderedListWithHeading = ({ children, ...props }) => (
    <StyledUnorderedList>
        {props.data
            ? props.data.map((item, index) => (
                  <ListItem variant={props.itemsVariant} key={index}>
                      <Typography variant="h3">{item.heading}</Typography>
                      {item.content}
                  </ListItem>
              ))
            : ""}

        {children
            ? React.Children.map(children, (item, index) => (
                  <ListItem variant={props.itemsVariant} key={index}>
                      {item}
                  </ListItem>
              ))
            : ""}
    </StyledUnorderedList>
);

UnorderedListWithHeading.propTypes = {
    data: PropTypes.array,
    itemsVariant: PropTypes.string,
};
