import createModel from "../createModel";

import { PersonalDataSection } from "./PersonalDataSection";

export const PersonalData = createModel("PersonalData", (json) => ({
    objectId: json.policyId,
    contractNumber: json.contractNumber,
    productGroup: json.productGroup,
    sections: PersonalDataSection.fromServerList(json.rightToAccess),
}));
