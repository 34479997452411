export default (actionPrefix) => {
    const CHANGE_DATA = `${actionPrefix}/CHANGE_DATA`;
    const STATEMENTS_SUBMIT = `${actionPrefix}/STATEMENTS_SUBMIT`;
    const ADD_MESSAGE = `${actionPrefix}/ADD_MESSAGE`;
    const SET_AVAILABLE_YEARS = `${actionPrefix}/SET_AVAILABLE_YEARS`;

    return {
        CHANGE_DATA,
        changeData: (selectedYear) => ({
            type: CHANGE_DATA,
            payload: selectedYear,
        }),

        STATEMENTS_SUBMIT,
        statementsSubmit: () => ({
            type: STATEMENTS_SUBMIT,
        }),

        SET_AVAILABLE_YEARS,
        setAvailableYears: (availableYears, error = false) => ({
            type: SET_AVAILABLE_YEARS,
            meta: { error },
            payload: availableYears,
        }),

        ADD_MESSAGE,
        addMessage: (message) => ({
            type: ADD_MESSAGE,
            payload: message,
        }),
    };
};
