import { model } from "core/util";

import { IdentityCardLegacy, AddressLegacy } from "../common";

export const HolderLegacy = model.createModelLegacy("Holder", (json) => ({
    idSubject: json.idSubject,
    countryCode: json.CountryCode,
    description: json.Description,
    firstName: json.FirstName,
    lastName: json.LastName,
    titleBefore: json.TitleBefore,
    titleAfter: json.TitleAfter,
    sexCode: json.SexCode,
    isPerson: !json.LegalPersonalityCode || json.LegalPersonalityCode === "FO",
    companyName: json.CompanyName,
    companyID: json.CompanyID,
    birthDate: json.BirthDate,
    birthPlace: json.BirthPlace,
    birthNumber: json.BirthNumber,
    citizenship: json.Citizenship,
    email: json.Email,
    phone: json.Phone,
    ids: IdentityCardLegacy.fromServerList(json.IDs),
    addresses: AddressLegacy.fromServerList(json.Address),
    addressesIdentical: json.AddressesIdentical,
    showPersonInContactAddressForm: json.ShowPersonInContactAddressForm,
}));
