import { model } from "core/util";
import { Validity } from "./Validity";

export const ContractParameters = model.createModelLegacy("ContractParameters", (json) => ({
    contractNumber: json.ContractNumber,
    isActive: json.IsActive,
    generateTaxCertificate: json.GenerateTaxCertificate,
    signDate: json.SignDate,
    acceptanceDate: json.EndDate,
    endDate: json.AcceptanceDate,
    validity: Validity.fromServer(json.Validity),
}));
