import { withTheme } from "@mui/styles";
import { Button } from "ui-library/atoms/Button/Button";
import styled from "styled-components";

const activeStyles = (props) => {
    if (props.$isActive) {
        return `
            border: 1px solid ${props.theme.palette.blue.main};
            background-color: ${props.theme.palette.blue.soft};
        
            svg {
                path {
                    fill : ${props.theme.palette.blue.main};
                }
            }
        `;
    }
};
export const StyledFilterButton = withTheme(styled(Button)`
    ${(props) => activeStyles(props)};
`);
