import i18n from "core/i18n";
import pageContext from "core/pageContext";
import router from "core/router";
import { Locales } from "enums";
import { Locale } from "types";

export const useChangeLanguage: (dispatch, navigate, location) => (locale: Locale) => void =
    (dispatch, navigate, location) => (locale: Locale) => {
        i18n.changeLanguage(locale.languageCode);
        dispatch(pageContext.setLocale(locale));

        // Make sure for en lang is in url as get param, for local language it is not.
        const searchParams = new URLSearchParams(location.search);
        if (locale === Locales.en_US && searchParams.get(router.LANG_PARAM) !== Locales.en_US.languageCode) {
            searchParams.set(router.LANG_PARAM, Locales.en_US.languageCode);
        }
        if (locale !== Locales.en_US && searchParams.get(router.LANG_PARAM)) {
            searchParams.delete(router.LANG_PARAM);
        }
        navigate({ search: searchParams.toString() });
    };
