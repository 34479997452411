import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Button, Image, OffsetImageWrapper, Typography } from "ui-library/atoms";
import { Box, ContentBox, GridItem, GridLayout } from "ui-library/layouts";
import { PromoBlock, YoutubeEmbed } from "ui-library/modules";
import useBreakpoints from "ui-library/theme/breakpoints";
import homePageImg from "ui-library/assets/images/home-client-portal.jpg";
import homePatalie from "ui-library/assets/images/home-patalie.jpg";

import i18n from "core/i18n";
import pageContext from "core/pageContext";
import { useGetSiteValue } from "core/hooks";
import { ExternalUrl } from "enums";
import { Pages } from "routeConstants";
import { pageRoutes } from "routeUrls";

export const PromoBlocks = () => {
    const isMdBreak = useBreakpoints("md");
    const { t } = i18n.useTranslation();
    const navigate = useNavigate();
    const youtubePromoId = useSelector(pageContext.getYoutubePromoId);
    const getSiteValue = useGetSiteValue();

    return (
        <>
            <PromoBlock hasOffests={true} variant={!isMdBreak ? "blueSoft" : "clean"}>
                <GridLayout alignItems={"center"} spacing={{ sm: 3, md: 5, lg: 8 }}>
                    <GridItem xs={12} md={6}>
                        <Box>
                            <Box mb={1}>
                                <Typography variant="h2">{t("page.login.theme.title")}</Typography>
                            </Box>

                            <ContentBox>
                                <Typography variant="p" color={"gray"}>
                                    {t("page.login.theme.content")}
                                </Typography>
                            </ContentBox>
                            <ContentBox>
                                <Typography listVariant="check" markDown={t("page.login.theme.bullets")} />
                            </ContentBox>
                            <ContentBox>
                                <Button variant="contained" color="blue" newTab externalLink={getSiteValue(ExternalUrl.UNIQA)}>
                                    {t("common.moreInformation")}
                                </Button>
                            </ContentBox>
                        </Box>
                    </GridItem>

                    <GridItem display={"flex"} justifyContent="center" xs={12} md={6}>
                        <YoutubeEmbed maxWidth={560} embedId={youtubePromoId} />
                    </GridItem>
                </GridLayout>
            </PromoBlock>

            <PromoBlock hasOffests={true} variant={isMdBreak ? "blueSoft" : "clean"}>
                <GridLayout alignItems={"center"} spacing={{ md: 5, lg: 8, sm: 3 }}>
                    <GridItem order={{ xs: 1, md: 0 }} display={"flex"} item md={6} xs={12}>
                        <OffsetImageWrapper>
                            <Image src={homePageImg} alt={"Moje UNIQA"} />
                        </OffsetImageWrapper>
                    </GridItem>
                    <GridItem md={6}>
                        <Box mb={1}>
                            <Typography variant="h2">{t("page.login.promo.first.title")}</Typography>
                        </Box>

                        <ContentBox>
                            <Typography variant="perex" color={"gray"} markDown={t("page.login.promo.first.content")} />
                        </ContentBox>

                        <ContentBox>
                            <Button variant="contained" color="blue" onClick={() => navigate(pageRoutes[Pages.REGISTRATION])}>
                                {t("common.register")}
                            </Button>
                        </ContentBox>
                    </GridItem>
                </GridLayout>
            </PromoBlock>

            <PromoBlock hasOffests={true} variant={!isMdBreak ? "blueSoft" : "clean"}>
                <GridLayout alignItems={"center"} spacing={{ md: 5, lg: 8, sm: 3 }}>
                    <GridItem xs={12} md={6}>
                        <Box mb={1}>
                            <Typography variant="h2">{t("page.login.promo.second.title")}</Typography>
                        </Box>

                        <ContentBox>
                            <Typography variant="perex" color={"gray"} markDown={t("page.login.promo.second.content")} />
                        </ContentBox>

                        <ContentBox>
                            <ContentBox>
                                <Button variant="contained" color="blue" newTab externalLink={getSiteValue(ExternalUrl.PATALIE)}>
                                    {t("common.readIn")}
                                </Button>
                            </ContentBox>
                        </ContentBox>
                    </GridItem>
                    <GridItem display={"flex"} justifyContent="center" item md={6} xs={12}>
                        <OffsetImageWrapper>
                            <Image src={homePatalie} alt={"Patalie"} />
                        </OffsetImageWrapper>
                    </GridItem>
                </GridLayout>
            </PromoBlock>
        </>
    );
};
