import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { List } from "immutable";

import entity from "core/entity";
import fetching from "core/fetching";
import i18n from "core/i18n";
import modal from "core/modal";
import { Box, GridItem } from "ui-library/layouts";
import { Accordion, AccordionTitle, Button, Icon, Typography } from "ui-library/atoms";
import { DataTable, FileInfo, InfoPanel } from "ui-library/modules";

import { ENTITY_CLAIM_OUTGOING_DOCUMENTS, ENTITY_CLAIM_REQUIRED_DOCUMENTS, FORM_UPLOAD_CLAIM_DOCUMENTS } from "../constants";
import { getCounterPartyObj, getDocumentStateObj } from "../util";
import { setDocumentToUploadReactKey } from "../actions";

const IRSU_FORM_PREFIX = "iRSU_Formular ";
const IRSU_PREFIX = "iRSU_";

const getDocumentName = (row) => {
    if (row.documentType?.name) {
        return row.documentType.name;
    } else if (row.otherDescription) {
        return row.otherDescription;
    } else if (row.documentType?.ecmSubDocumentType) {
        return row.documentType.ecmSubDocumentType.replace(IRSU_FORM_PREFIX, "").replace(IRSU_PREFIX, "");
    } else {
        return null;
    }
};

const requiredDocumentsColumns = (t, openUploadDocumentDialog) => [
    {
        field: "documentType",
        headerName: t("common.name"),
        flex: 6,
        renderCell: (params) => {
            const description = params.value?.name ? params.value?.name : params.row.otherDescription;
            return <Typography fontSize={14}>{getDocumentName(params.row)}</Typography>;
        },
        sortable: false,
    },
    {
        field: "counterParty",
        headerName: t("claim.requiredFrom"),
        flex: 2,
        renderCell: (params) => t(getCounterPartyObj(params.value).msg),
        sortable: false,
    },
    {
        field: "state",
        headerName: t("common.status"),
        flex: 2,
        renderCell: (params) => {
            const stateObj = getDocumentStateObj(params.value);
            return t(stateObj.msg);
        },
        sortable: false,
    },
    {
        field: "reactKey",
        headerName: t("common.insertDocument"),
        flex: 0.5,
        renderCell: (params) => {
            const stateObj = getDocumentStateObj(params.row.state);
            return (
                !stateObj.isCompleted && (
                    <Button
                        onClick={() => openUploadDocumentDialog(params.value)}
                        rounded
                        variant="text"
                        color="blue"
                        startIcon={"file-plus"}
                    />
                )
            );
        },
        sortable: false,
    },
];

export const outgoingDocumentsColumns = (t) => [
    {
        field: "documentDescription",
        headerName: t("common.name"),
        flex: 2.5,
        renderCell: (params) => {
            return <FileInfo disabled tableVariant={true} fileName={params.value} />;
        },
    },
    {
        field: "creationDate",
        headerName: t("common.date"),
        width: 110,
        flex: 1,
    },
];

export const ClaimDocuments = () => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();

    const openUploadDocumentDialog = (documentReactKey) => {
        dispatch(setDocumentToUploadReactKey(documentReactKey));
        dispatch(modal.openModalForm(FORM_UPLOAD_CLAIM_DOCUMENTS));
    };

    const isRequiredDocumentsLoading = useSelector(fetching.isFetching(ENTITY_CLAIM_REQUIRED_DOCUMENTS, true));
    const isRequiredDocumentsError = useSelector(entity.isError(ENTITY_CLAIM_REQUIRED_DOCUMENTS, new List()));
    const requiredDocuments = useSelector(entity.getData(ENTITY_CLAIM_REQUIRED_DOCUMENTS, new List()));
    const mappedRequiredDocuments = requiredDocuments && requiredDocuments.toArray();
    const numberOfUnprocessedRequiredDocs = requiredDocuments.filter((item) => item.state === "REQUIRED").size;

    const isOutgoingDocumentsLoading = useSelector(fetching.isFetching(ENTITY_CLAIM_OUTGOING_DOCUMENTS, true));
    const isOutgoingDocumentsError = useSelector(entity.isError(ENTITY_CLAIM_OUTGOING_DOCUMENTS));
    const outgoingDocuments = useSelector(entity.getData(ENTITY_CLAIM_OUTGOING_DOCUMENTS));
    const mappedOutgoingDocuments = outgoingDocuments && outgoingDocuments.toArray();

    return (
        <GridItem xs={12}>
            <InfoPanel icon="document" title={t("common.documents")}>
                <Accordion
                    data={[
                        {
                            title: <AccordionTitle count={numberOfUnprocessedRequiredDocs} text={t("claim.requiredDocuments")} />,
                            children: (
                                <DataTable
                                    disableColumnReorder
                                    rowHeight={120}
                                    hideFooter={true}
                                    withWrapper={false}
                                    getRowId={(row) => row.reactKey}
                                    rows={mappedRequiredDocuments}
                                    columns={requiredDocumentsColumns(t, openUploadDocumentDialog)}
                                    emptyMsg={t("common.noData")}
                                    errorMsg={t("error.sectionUnavailable")}
                                    isLoading={isRequiredDocumentsLoading}
                                    isError={isRequiredDocumentsError}
                                />
                            ),
                        },
                        {
                            title: <AccordionTitle count={mappedOutgoingDocuments?.length} text={t("claim.outgoingDocuments")} />,
                            children: (
                                <DataTable
                                    disableColumnReorder
                                    hideFooter={true}
                                    withWrapper={false}
                                    getRowId={(row) => row.reactKey}
                                    rows={mappedOutgoingDocuments}
                                    columns={outgoingDocumentsColumns(t)}
                                    emptyMsg={t("common.noData")}
                                    errorMsg={t("error.sectionUnavailable")}
                                    isLoading={isOutgoingDocumentsLoading}
                                    isError={isOutgoingDocumentsError}
                                />
                            ),
                        },
                        {
                            title: <AccordionTitle count={0} text={t("claim.otherDocuments")} />,
                            children: (
                                <>
                                    <Box mt={1}>
                                        <Button
                                            color="blue"
                                            variant="outlined"
                                            startIcon={"plus"}
                                            onClick={() => openUploadDocumentDialog(null)}
                                        >
                                            {t("common.add")}
                                        </Button>
                                    </Box>
                                </>
                            ),
                        },
                    ]}
                    id="accordionID"
                />
            </InfoPanel>
        </GridItem>
    );
};
