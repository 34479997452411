import * as React from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";

import { Icon } from "ui-library/atoms";
import { Flexbox } from "ui-library/layouts";
import { LabelTextItem } from "ui-library/modules/LabelTextItem/LabelTextItem";

import { localAmountWithCurrencyLegacy } from "core/formatters";
import i18n from "core/i18n";

import { getAccountNumber, getAmountObj, getAmountTextLabel, getSpecificSymbol, getVariableSymbol } from "../selectors";
import { LocalQrCode } from "./LocalQrCode";

export const TransferMethod = () => {
    const { t } = i18n.useTranslation();

    const accountNumber = useSelector(getAccountNumber);
    const variableSymbol = useSelector(getVariableSymbol);
    const specificSymbol = useSelector(getSpecificSymbol);
    const amountObj = useSelector(getAmountObj);
    const amountLabel = useSelector(getAmountTextLabel);
    const copyToClipboard = (value) => navigator.clipboard.writeText(value);

    return (
        <Box pt={6} pb={2} px={3}>
            <Flexbox display="flex" flexDirection={{ xs: "column", md: "row" }} justifyContent="space-between">
                <Box>
                    <Flexbox display={{ xs: "flex", md: "table" }} flexDirection="column">
                        <LabelTextItem
                            label={t("common.accountNumber")}
                            text={accountNumber}
                            iconAction={<Icon icon="copy" size={16} color="blue" onClick={() => copyToClipboard(accountNumber)} />}
                        />
                        <LabelTextItem
                            label={t("payments.variableSymbol")}
                            text={variableSymbol}
                            iconAction={<Icon icon="copy" size={16} color="blue" onClick={() => copyToClipboard(variableSymbol)} />}
                        />
                        {specificSymbol && (
                            <LabelTextItem
                                label={t("payments.specificSymbol")}
                                text={specificSymbol}
                                iconAction={<Icon icon="copy" size={16} color="blue" onClick={() => copyToClipboard(specificSymbol)} />}
                            />
                        )}
                        {amountObj && (
                            <LabelTextItem
                                label={amountLabel}
                                text={localAmountWithCurrencyLegacy(amountObj)}
                                iconAction={
                                    <Icon
                                        icon="copy"
                                        size={16}
                                        color="blue"
                                        onClick={() => copyToClipboard(localAmountWithCurrencyLegacy(amountObj))}
                                    />
                                }
                            />
                        )}
                    </Flexbox>
                </Box>
                <Box>
                    <LocalQrCode />
                </Box>
            </Flexbox>
        </Box>
    );
};
