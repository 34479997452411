import { fetch } from "core/util";
import { API_PREFIX } from "app/constants";
import { MutualFundsDirect, MutualFundsDirectFinancialStatement, MutualFundsDirectFinancialOperation } from "model/mutualFunds/direct";
import { MutualFundsFinancialRequest } from "model/mutualFunds";

const productUriPrefix = `${API_PREFIX}/product/mutual-funds-direct`;
const getMutualFundsDirectContractDetail = (idObject) =>
    fetch.doGet(`${productUriPrefix}/${idObject}/contract-detail`, null, MutualFundsDirect.fromServer);
const getMutualFundsDirectFinancialOperations = (idObject) =>
    fetch.doGet(`${productUriPrefix}/${idObject}/financial-operations`, null, MutualFundsDirectFinancialOperation.fromServerList);
const getMutualFundsDirectFinancialStatement = (idObject) =>
    fetch.doGet(`${productUriPrefix}/${idObject}/financial-statement`, null, MutualFundsDirectFinancialStatement.fromServer);
const getMutualFundsDirectFinancialRequests = (idObject) =>
    fetch.doGet(`${productUriPrefix}/${idObject}/financial-requests`, null, MutualFundsFinancialRequest.fromServerList);
const sellOrSwitchFund = (transaction) => fetch.doPut(`${productUriPrefix}/sell-or-switch-fund`, transaction);
export default {
    getMutualFundsDirectContractDetail,
    getMutualFundsDirectFinancialOperations,
    getMutualFundsDirectFinancialStatement,
    getMutualFundsDirectFinancialRequests,
    sellOrSwitchFund,
};
