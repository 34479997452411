import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import i18n from "core/i18n";
import { Field, form, InputText, max6Characters, numberCharacters } from "core/form";
import { Button, ErrorContent, Icon, Loading, Typography } from "ui-library/atoms";
import { BannerFlexbox, Box, Flexbox } from "ui-library/layouts";

export const VerificationEpinFormContainer = ({ handleSubmit, canSubmit, submitting, selectors, actions }) => {
    const { t } = i18n.useTranslation();
    const epinVerificationError = useSelector(selectors.getEpinVerificationError);
    const epinData = useSelector(selectors.getEpinData);
    const dispatch = useDispatch();

    return (
        <>
            <Loading fullPage loading={submitting} />

            <BannerFlexbox>
                <Box>
                    <Field
                        name="otpCode"
                        component={InputText}
                        size="large"
                        label={t("page.login.forgotPassword.smsCode")}
                        onEnter={handleSubmit}
                        normalize={[numberCharacters, max6Characters]}
                    />
                    <Button onClick={() => dispatch(actions.setResendPin())} variant="text" color="blue">
                        {t("form.otp.resend")}
                    </Button>
                </Box>

                <Box mt={{ xs: 0, lg: 3.625 }}>
                    <Button color="green" variant="contained" size={"large"} onClick={handleSubmit} disabled={!canSubmit}>
                        {t("page.login.forgotPassword.sendPassword")}
                    </Button>
                </Box>
            </BannerFlexbox>

            {epinVerificationError && epinVerificationError.map((error) => <ErrorContent key={error} content={error} />)}

            <Flexbox alignItems={"top"} gap={2} mt={2}>
                <Icon icon="siren" color={"orange"} />
                <Typography variant="p" markDown={t("page.login.forgotPassword.phonePinText", { phone: epinData.get("phone") })} />
            </Flexbox>
        </>
    );
};

VerificationEpinFormContainer.propTypes = {
    selectors: PropTypes.object.isRequired,
    canSubmit: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
};

export const createVerificationEpinForm = (formName, selectors, actions) =>
    form(formName)((props) => <VerificationEpinFormContainer selectors={selectors} actions={actions} {...props} />);
