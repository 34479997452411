import { call, getContext, put } from "redux-saga/effects";

import router from "core/router";
import { fn } from "core/util";
import { Pages } from "routeConstants";
import { impersonateApi } from "serverApi";

import { slice } from "./slice";

export const impersonateSaga = function* impersonateSaga() {
    const { href, pathname, search } = window.location;
    const pageInformation = router.parseLocation(pathname, search);

    if (pageInformation?.name === Pages.IMPERSONATE) {
        try {
            yield call(impersonateApi.checkAccess);

            // First step - we don't have code in query params = SSO call is needed.
            if (!pageInformation.query?.code) {
                yield put(router.navigateSsoLogin(true));
                yield call(fn.block);
            } else if (pageInformation.query?.code) {
                const redirectUri = href.split("?").shift();
                const response = yield call(impersonateApi.loginByCode, {
                    uuid: pageInformation.params?.uuid,
                    code: pageInformation.query?.code,
                    redirectUri,
                });

                if (response.isLoggedInSuccessfully) {
                    yield put(router.navigate(Pages.CONTRACTS));
                } else {
                    // Remove query params.
                    const history = yield getContext("history");
                    history.replace({ search: "" });
                    yield put(slice.actions.setUnsuccessfulReason(response.loginUnsuccessfulReason));
                }
            }
        } catch (e) {
            yield put(router.navigate(Pages.LOGIN));
        }
    }
};
