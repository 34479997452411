import createReducer from "./reducer";
import createActions from "./actions";
import createSelectors from "./selectors";
import createSaga from "./saga";
import createContainer from "./Container";

export { OTP_CODE_FIELD_NAME, OTP_ID_FIELD_NAME } from "./constants";

export default ({ reducerPath, actionPrefix }) => {
    const actions = createActions(actionPrefix);
    const selectors = createSelectors(reducerPath);
    const Container = createContainer(actions, selectors);
    const reducer = createReducer(actions);
    const saga = createSaga(actions);

    return {
        Container,
        setPhoneNumber: actions.setPhoneNumber,
        reducer,
        createIsVisibleSelector: selectors.createIsVisibleSelector,
        clear: actions.clear,
        saga,
    };
};
