import PropTypes from "prop-types";
import React from "react";

import { Box, Flexbox } from "ui-library/layouts";
import { Typography } from "ui-library/atoms";
import { StyledDropdownOption } from "ui-library/Styled";
import useBreakpoints from "ui-library/theme/breakpoints";

export const ContractOption = ({ leftText, middleText, rightText, noMiddleText, maxLeftTextLength }) => {
    const isFullWidth = useBreakpoints("md");
    const isSm = useBreakpoints("sm");
    const isXs = useBreakpoints("xs");
    const isMedium = isSm && !isFullWidth;
    const isMobile = isXs && !isSm;

    const getEllipsisMiddleTextLength = (maxLeftTextLength) => 70 - maxLeftTextLength;
    return (
        <>
            {(isFullWidth || (noMiddleText && isMedium)) && (
                <Flexbox>
                    <Box>
                        <Typography sx={{ display: "block", textAlign: "left" }} mr={1.75} variant="b">
                            {noMiddleText ? leftText : getEllipsisText(leftText, 50)}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="p">{getEllipsisText(middleText, getEllipsisMiddleTextLength(maxLeftTextLength))}</Typography>
                    </Box>
                    <Box sx={{ marginLeft: "auto" }}>
                        <Typography variant="c" markDown={rightText} />
                    </Box>
                </Flexbox>
            )}
            {isMedium && !noMiddleText && (
                <StyledDropdownOption complexOption={true} py={{ xs: 0.5, md: 0 }} direction={{ xs: "column" }}>
                    <>
                        <Box>
                            <Typography sx={{ width: "90px", textAlign: { xs: "none" } }} mr={1.75} component="span" variant="b">
                                {leftText}
                            </Typography>
                            {middleText && (
                                <Typography sx={{ width: "90px", textAlign: { xs: "none" } }} mr={1.75} component="span">
                                    {getEllipsisText(middleText, 40)}
                                </Typography>
                            )}
                        </Box>
                        <Box pr={2}>
                            <Typography variant="p" markDown={rightText} />
                        </Box>
                    </>
                </StyledDropdownOption>
            )}
            {isMobile && (
                <StyledDropdownOption complexOption={true} py={{ xs: 0.5, md: 0 }} direction={{ xs: "column" }}>
                    <>
                        <Box>
                            <Typography sx={{ width: "90px", textAlign: { xs: "none" } }} mr={1.75} component="span" variant="b">
                                {getEllipsisText(leftText, 40)}
                            </Typography>
                        </Box>
                        {middleText && (
                            <Box>
                                <Typography sx={{ width: "90px", textAlign: { xs: "none" } }} mr={1.75} component="span">
                                    {getEllipsisText(middleText, 40)}
                                </Typography>
                            </Box>
                        )}
                        <Box pr={2}>
                            <Typography variant="p" markDown={rightText} />
                        </Box>
                    </>
                </StyledDropdownOption>
            )}
        </>
    );
};

const getEllipsisText = (text, length) => {
    if (!text) {
        return "";
    }
    const trimmedWhiteSpaces = text.trim();
    let trimmed = trimmedWhiteSpaces.substring(0, length).trim();
    return trimmed.length + 3 > length && trimmed !== trimmedWhiteSpaces ? "".concat(trimmed, "...") : trimmed;
};

ContractOption.propTypes = {
    leftText: PropTypes.string.isRequired,
    middleText: PropTypes.string,
    rightText: PropTypes.string.isRequired,
};

ContractOption.defaultProps = {
    middleText: null,
};
