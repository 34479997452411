import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import i18n from "core/i18n";
import {
    acceptedFileSizeValidator,
    acceptedFileTypesValidator,
    Field,
    FileUpload,
    getFormFieldValue,
    personalDataConsentRequired,
    SegmentedControl,
} from "core/form";
import { Typography } from "ui-library/atoms";
import { ContentBox, Section } from "ui-library/layouts";

import { SectionDescription, SectionTitle } from "../components";
import { FILES_FIELD } from "../constants";

const AttachmentsFormBox = ({ formName, isCompany, isIdentificationDocShown }) => {
    const { t } = i18n.useTranslation();
    const files = useSelector(getFormFieldValue(formName, FILES_FIELD));
    const fileUploaded = typeof files !== "undefined" && files.size > 0;
    const personAttachmentsText = !isIdentificationDocShown
        ? t("form.editPolicyHolder.changeAttachmentsHiddenID")
        : t("form.editPolicyHolder.changeAttachments");

    return (
        <Section>
            <SectionTitle title={t("common.attachments")} />
            <SectionDescription content={isCompany ? t("form.editPolicyHolder.companyChangeAttachments") : personAttachmentsText} />

            <ContentBox>
                <Field name={FILES_FIELD} component={FileUpload} validateStatic={[acceptedFileTypesValidator, acceptedFileSizeValidator]} />
            </ContentBox>

            {fileUploaded && (
                <ContentBox>
                    <Field
                        checkboxLabel={t("common.personalDataConsentRequired")}
                        name="personalDataConsent"
                        component={SegmentedControl}
                        validateStatic={[personalDataConsentRequired]}
                    />
                    <Typography variant="caption" markDown={t("common.personalDataConsent")} />
                </ContentBox>
            )}
        </Section>
    );
};

AttachmentsFormBox.propTypes = {
    formName: PropTypes.string.isRequired,
    isCompany: PropTypes.bool.isRequired,
    isIdentificationDocHidden: PropTypes.bool.isRequired,
};

export default AttachmentsFormBox;
