import React, { FC } from "react";

import { GridLayout } from "ui-library/layouts";

import { ActualAccountBalanceGridItem, ActualInvestmentsStatusGridItem, ActualInvestmentValue } from "../containers";

export const FinancialStatementTab: FC = () => {
    return (
        <GridLayout defaultSpacing>
            <ActualInvestmentValue />
            <ActualInvestmentsStatusGridItem />
            <ActualAccountBalanceGridItem />
        </GridLayout>
    );
};
