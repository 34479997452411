import { Set } from "immutable";
import { createSelector } from "reselect";

import { fn } from "core/util";

export const createGetRedeemAllowedPensionTypes = (getContractAdditionalInfoCallback, RedeemTypesEnum) =>
    createSelector(getContractAdditionalInfoCallback, (contractAdditionalInfo) =>
        Set(
            contractAdditionalInfo.redeemInvestmentsOptions
                .filter((redeemOption) => redeemOption.isApplicable === 1)
                .map((redeemOption) => fn.getEnumItemByCode(RedeemTypesEnum, redeemOption.typeCode))
                .map((redeemTypeEnum) => redeemTypeEnum.pensionType),
        ),
    );

export const createGetRedeemAllowedRedeemTypes = (pensionType, getContractAdditionalInfoCallback, RedeemTypesEnum) =>
    createSelector(getContractAdditionalInfoCallback, (contractAdditionalInfo) => {
        const allowedRedeemTypes = contractAdditionalInfo.redeemInvestmentsOptions
            .filter((redeemOption) => redeemOption.isApplicable === 1)
            .map((redeemOption) => redeemOption.typeCode);

        return Set(
            Object.values(RedeemTypesEnum)
                .filter((redeemType) => redeemType.pensionType === pensionType)
                .filter((redeemType) => allowedRedeemTypes.includes(redeemType.code)),
        );
    });

export const createGetIsAnotherRedeemInProcess = (getContractAdditionalInfoCallback) =>
    createSelector(getContractAdditionalInfoCallback, (contractAdditionalInfo) => {
        const count = contractAdditionalInfo.redeemInvestmentsOptions
            .map((redeemOption) => redeemOption.beingProcessedCount)
            .reduce((acc, value) => acc + Number(value), 0);
        return 0 !== count;
    });

export const createGetIsRedeemPossible = (getRedeemAllowedPensionTypesCallback, getIsAnotherRedeemInProcessCallback) =>
    createSelector(
        getRedeemAllowedPensionTypesCallback,
        getIsAnotherRedeemInProcessCallback,
        (allowedPensionTypes, isAnotherRedeemInProcess) => !allowedPensionTypes.isEmpty() && !isAnotherRedeemInProcess,
    );

export const createGetRedeemParameters = (redeemType, getContractAdditionalInfoCallback) =>
    createSelector(getContractAdditionalInfoCallback, (contactAdditionalInfo) =>
        fn.getFirstFromArray(contactAdditionalInfo.redeemInvestmentsOptions.filter((item) => item.typeCode === redeemType?.code)),
    );
