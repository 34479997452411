import React from "react";
import PropTypes from "prop-types";

import { ModalBody, ModalFooter } from "ui-library/modules";
import { Box, ContentBox } from "ui-library/layouts";
import { Button, Loading, Typography } from "ui-library/atoms";

import { ModalFormLayout } from "containers/modal";
import { form } from "core/form";
import i18n from "core/i18n";
import { useSelector } from "react-redux";
import { GdprModalSuccess } from "./GdprModalSuccess";

const GdprConsentForm = ({ textSelector, hasConsentSelector, canSubmit, handleSubmit, submitting }) => {
    const { t } = i18n.useTranslation();
    const hasConsent = useSelector(hasConsentSelector);
    const text = useSelector(textSelector);
    return (
        <>
            <ModalBody>
                <Loading loading={submitting}>
                    <Box>
                        <ContentBox>
                            {hasConsent ? (
                                <Typography>{t("userSettings.personalDataConsent.confirmationQuestion")}</Typography>
                            ) : (
                                <div dangerouslySetInnerHTML={{ __html: text }} />
                            )}
                        </ContentBox>
                    </Box>
                </Loading>
            </ModalBody>
            <ModalFooter>
                <Button color="blue" variant="contained" onClick={handleSubmit} disabled={submitting}>
                    {hasConsent ? t("common.cancelAgreement") : t("common.iAgree")}
                </Button>
            </ModalFooter>
        </>
    );
};

GdprConsentForm.propTypes = {
    canSubmit: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    // text: PropTypes.string.isRequired,
};

export const createContainer = (formName, textSelector, hasConsentSelector) =>
    form(formName)((props) => {
        const { t } = i18n.useTranslation();
        return (
            <ModalFormLayout formName={formName} formTitle={t("form.gdprConsent")} formIcon="contract" SuccessComponent={GdprModalSuccess}>
                <GdprConsentForm {...props} textSelector={textSelector} hasConsentSelector={hasConsentSelector} />
            </ModalFormLayout>
        );
    });
