import { Country, Locale } from "types";

export const NAME = "core/page-context";

export type PageLocalization = {
    locale: Locale;
    site: Country;
    availableLocales: Locale[];
};

export type Disclaimer = {
    text: string;
    version: string;
};

export type InfoTexts = {
    enableLoginAlert: boolean;
    loginText: string;
    enableHomepageAlert: boolean;
    homepageText: string;
};

export type ConfigKeys = {
    recaptcha: string;
    vapid: string;
    youtube: string;
};

export type ConfigUrls = {
    authUri: string;
    logoutUri: string;
    claimReportingUrl: string;
    claimReportingUrls: {
        base: string;
        vehicle: string;
        home: string;
        travel: string;
        life: string;
    };
};

export type NotAllowed = {
    targetFundCodesCz: string[];
    targetFundCodesSk: string[];
};

export interface PageContextState {
    clientId: string;
    configUrls: ConfigUrls;
    disclaimer: Disclaimer;
    featureFlags: string[];
    infoTexts: InfoTexts;
    keys: ConfigKeys;
    notAllowed: NotAllowed;
    pageLocalization: PageLocalization;
}

export const initialState: PageContextState = {
    clientId: "",
    configUrls: {
        authUri: "",
        logoutUri: "",
        claimReportingUrl: "",
        claimReportingUrls: {
            base: "",
            vehicle: "",
            home: "",
            travel: "",
            life: "",
        },
    },
    disclaimer: {
        text: "",
        version: "",
    },
    featureFlags: [],
    infoTexts: {
        enableLoginAlert: false,
        loginText: "",
        enableHomepageAlert: false,
        homepageText: "",
    },
    keys: {
        recaptcha: "",
        vapid: "",
        youtube: "",
    },
    notAllowed: {
        targetFundCodesCz: [],
        targetFundCodesSk: [],
    },
    pageLocalization: {
        locale: null,
        site: null,
        availableLocales: [],
    },
};

export type SetContextPayload = PageContextState;
