import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Button } from "ui-library/atoms";
import { DataTable, InfoPanel } from "ui-library/modules";
import { Box, GridItem, GridLayout } from "ui-library/layouts";

import {
    formatPercentage,
    localAmountInvestmentWithCurrencyEurLegacy,
    localAmountNoDecimal,
    localAmountWithCurrencyLegacy,
    localFullDate,
} from "core/formatters";
import i18n, { TFunction } from "core/i18n";
import router from "core/router";
import { Pages, Tabs } from "routeConstants";
import fetching from "core/fetching";

import { getFinancialOperationDetailBuy, getFinancialOperationDetailBuyFunds } from "../selectors";
import { ENTITY_FINANCIAL_OPERATION_DETAIL } from "../constants";

const getBuyColumns = (t: TFunction) => [
    {
        headerName: t("investments.paymentDate"),
        field: "valueDate",
        renderCell: (params) => localFullDate(params.value),
        minWidth: 130,
    },
    {
        headerName: t("common.payment"),
        field: "payment",
        renderCell: (params) => localAmountWithCurrencyLegacy(params.value),
        align: "right",
        headerAlign: "right",
        minWidth: 130,
    },
    {
        headerName: t("investments.feePayment"),
        field: "feePayment",
        renderCell: (params) => localAmountWithCurrencyLegacy(params.value),
        align: "right",
        headerAlign: "right",
        minWidth: 150,
    },
    {
        headerName: t("investments.forInvestment"),
        field: "investedAmount",
        renderCell: (params) => localAmountWithCurrencyLegacy(params.value),
        align: "right",
        headerAlign: "right",
        minWidth: 130,
    },
    {
        headerName: t("investments.investmentProfile"),
        field: "investmentProfile",
        align: "right",
        headerAlign: "right",
        minWidth: 250,
    },
];

const getBuyFundsColumns = (t: TFunction) => [
    {
        headerName: t("investments.shareOfInvestmentInTheFund"),
        field: "shareOfInvestmentInTheFund",
        renderCell: (params) => formatPercentage(params.value),
        minWidth: 50,
    },
    {
        headerName: t("common.fund"),
        field: "fund",
        isKey: true,
        minWidth: 180,
    },
    {
        headerName: t("common.amount"),
        // headerElement: <span>{amount}&nbsp;<Fn id={1} /></span>,
        field: "amount",
        renderCell: (params) => localAmountWithCurrencyLegacy(params.value),
        align: "right",
    },
    {
        headerName: t("investments.instructionGenerationDate"),
        field: "dateOfInstructionGeneration",
        renderCell: (params) => localFullDate(params.value),
        // align: "right",
        minWidth: 110,
    },
    {
        headerName: t("investments.instructionRealizationDate"),
        field: "dateOfInstructionRealization",
        renderCell: (params) => localFullDate(params.value),
        // align: "right",
        minWidth: 110,
    },
    {
        headerName: t("investments.numberOfShares"),
        field: "numberOfPurchasedShares",
        renderCell: (params) => localAmountNoDecimal(params.value),
        align: "right",
        minWidth: 50,
    },
    {
        headerName: t("investments.shareValueAtOperationDateTime"),
        field: "priceOfOneShare",
        renderCell: (params) => localAmountInvestmentWithCurrencyEurLegacy(params.value),
        minWidth: 110,
        align: "right",
    },
];

export const FinancialOperationDetailBuy: FC = () => {
    const { t } = i18n.useTranslation();
    const isDetailLoading = useSelector(fetching.isFetching(ENTITY_FINANCIAL_OPERATION_DETAIL, true));
    const buyData = useSelector(getFinancialOperationDetailBuy);
    const buyFundsData = useSelector(getFinancialOperationDetailBuyFunds);
    const { idObject } = useParams();
    const navigate = useNavigate();
    return (
        <GridLayout defaultSpacing>
            <InfoPanel icon={"coins"} title={t("investments.investmentDetail")}>
                <GridItem xs={12}>
                    <DataTable
                        // icon={'coins'}
                        // title={t("investments.investmentDetail")}
                        getRowId={(row) => row.id}
                        pageSize={10}
                        rowsPerPageOptions={[5, 10, 15, 20]}
                        // @ts-ignore
                        rows={buyData ? buyData.toArray() : []}
                        columns={getBuyColumns(t)}
                        isLoading={isDetailLoading}
                        headerHeight={60}
                        hideFooter
                        withWrapper={false}
                    />
                </GridItem>
                <GridItem xs={12}>
                    <DataTable
                        // icon={'coins'}
                        getRowId={(row) => row.id}
                        pageSize={10}
                        rowsPerPageOptions={[5, 10, 15, 20]}
                        rows={buyFundsData ? buyFundsData.toArray() : []}
                        columns={getBuyFundsColumns(t)}
                        isLoading={isDetailLoading}
                        headerHeight={60}
                        rowHeight={70}
                        withWrapper={false}
                    />
                </GridItem>
            </InfoPanel>
            <Box mt={3}>
                <Button
                    onClick={() => navigate(router.getStaticUrl(Pages.CONTRACT_REGULAR, Tabs.FINANCIAL_STATEMENT, { idObject }))}
                    color="green"
                    variant="contained"
                    size="large"
                >
                    {t("common.backToStatements")}
                </Button>
            </Box>
        </GridLayout>
    );
};
