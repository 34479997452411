import React, { FC } from "react";

import { ImportantAnnouncements } from "ui-library/atoms";

import i18n from "core/i18n";
import { ErrorDateAndIdentifier } from "../ErrorDateAndIdentifier";

export interface DefaultPageErrorProps {
    errorMsgKey: string;
    errorIdentifier: string;
}

export const DefaultPageError: FC<DefaultPageErrorProps> = ({ errorMsgKey, errorIdentifier }) => {
    const { t } = i18n.useTranslation();

    return (
        <ImportantAnnouncements
            variant={"ERROR"}
            data={{
                heading: t("error.contractDetailUnavailable.title"),
                content: <ErrorDateAndIdentifier errorMsgKey={errorMsgKey} errorIdentifier={errorIdentifier} />,
            }}
        />
    );
};
