import React from "react";
import { useSelector } from "react-redux";

import entity from "core/entity";
import fetching from "core/fetching";
import { localAmountInvestment, localAmountInvestmentWithCurrency, localAmountWithCurrencyLegacy } from "core/formatters";
import i18n from "core/i18n";
import { DataTable } from "ui-library/modules";
import { ContentBox } from "ui-library/layouts";

import { ENTITY_FINANCIAL_STATEMENT } from "../../constants";
import { getInvestmentFundsDataAxa } from "../../selectors";

const getColumns = (t) => [
    {
        field: "fundName",
        headerName: t("investments.fundName"),
        sortable: false,
        flex: 2,
    },
    {
        field: "fundType",
        headerName: t("investments.fundType"),
        sortable: false,
        flex: 1,
    },
    {
        field: "numberOfShares",
        headerName: t("investments.numberOfShares"),
        sortable: false,
        flex: 1,
        align: "right",
        headerAlign: "right",
        renderCell: (params) => localAmountInvestment(params.value),
    },
    {
        field: "fundUnitValue",
        headerName: t("investments.fundUnitValue"),
        sortable: false,
        flex: 1,
        align: "right",
        headerAlign: "right",
        renderCell: (params) => localAmountInvestmentWithCurrency(params.value),
    },
    {
        field: "sharesValue",
        headerName: t("investments.sharesValue"),
        sortable: false,
        flex: 1,
        align: "right",
        headerAlign: "right",
        renderCell: (params) => localAmountWithCurrencyLegacy(params.value),
    },
];

export const AxaInvestmentsFundsTable = () => {
    const { t } = i18n.useTranslation();

    const isStatementLoading = useSelector(fetching.isFetching(ENTITY_FINANCIAL_STATEMENT, true));
    const isStatementError = useSelector(entity.isError(ENTITY_FINANCIAL_STATEMENT));

    const investmentsFunds = useSelector(getInvestmentFundsDataAxa);
    const mappedInvestmentsFunds = investmentsFunds && investmentsFunds.toArray();

    return (
        <ContentBox bigger>
            <DataTable
                disableColumnReorder
                hideFooter
                withWrapper={false}
                headerHeight={62}
                getRowId={(row) => row.reactKey}
                rows={mappedInvestmentsFunds}
                columns={getColumns(t)}
                isLoading={isStatementLoading}
                isError={isStatementError}
            />
        </ContentBox>
    );
};
