import { Record, List, Set } from "immutable";
import ClaimsLineOfBusinessClass from "./ClaimsLineOfBusinessClass";

class ClaimReportCategory extends Record({
    id: null,
    text: null,
    productGroupLvls2: null,
    compliantLineOfBusinessClasses: null,
}) {}

export default Object.freeze({
    VEHICLE: new ClaimReportCategory({
        id: "VEHICLE",
        text: "page.reportClaim.vehicle",
        productGroupLvls2: List([6]),
        compliantLineOfBusinessClasses: Set([
            ClaimsLineOfBusinessClass.MTPL.id,
            ClaimsLineOfBusinessClass.CASCO.id,
            ClaimsLineOfBusinessClass.GLASS.id,
            ClaimsLineOfBusinessClass.GLASS_CASCO.id,
        ]),
    }),
    HOME_AND_LIABILITY: new ClaimReportCategory({
        id: "HOME_AND_LIABILITY",
        text: "page.reportClaim.homeAndLiability",
        productGroupLvls2: List([7, 21, 22, 23, 24]),
        compliantLineOfBusinessClasses: Set([ClaimsLineOfBusinessClass.PROPERTY.id, ClaimsLineOfBusinessClass.LIABILITY.id]),
    }),
    TRAVEL: new ClaimReportCategory({
        id: "TRAVEL",
        text: "page.reportClaim.travel",
        productGroupLvls2: List([17]),
        compliantLineOfBusinessClasses: Set([ClaimsLineOfBusinessClass.TRAVEL.id, ClaimsLineOfBusinessClass.LIFE.id]),
    }),
    LIFE: new ClaimReportCategory({
        id: "LIFE",
        text: "page.reportClaim.life",
        productGroupLvls2: List([1, 2, 4, 14, 15, 16, 18, 19, 25, 26, 27, 28, 29]),
        compliantLineOfBusinessClasses: Set([ClaimsLineOfBusinessClass.TRAVEL.id, ClaimsLineOfBusinessClass.LIFE.id]),
    }),
});
