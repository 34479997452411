import { model } from "core/util";
import { Address as AddressType, AddressDto } from "types";

export const Address = model.createModel<AddressDto, AddressType>((json) => ({
    addressTypeCode: json.AddressTypeCode,
    street: json.Street,
    orientationNumber: json.OrientationNumber,
    descriptionNumber: json.DescriptionNumber,
    city: json.City,
    postalCode: json.PostalCode,
    countryCode: json.CountryCode,
}));
