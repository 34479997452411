import { withTheme } from "@mui/styles";
import { Box } from "ui-library/layouts/Box/Box";
import styled from "styled-components";

const contentStyle = (props) => {
    return `
        overflow: hidden;
        transition: max-height 1s;
        max-height: ${props.$more ? "auto" : "0px"};
        `;
};

export const StyledDroppableContractCard = withTheme(styled(Box)`
    padding: 12px 0 12px 12px;

    .wrapper {
        border-bottom: ${(props) =>
            props.$more ? "3px solid" + props.theme.palette.blue.main : "1px solid" + props.theme.palette.gray.line};
    }

    &:last-child {
        .wrapper {
            border-bottom: ${(props) => (props.$more ? "3px solid" + props.theme.palette.blue.main : "unset")};
        }
    }

    .content {
        ${(props) => contentStyle(props)};
    }

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    }
`);
