import React from "react";
import PropTypes from "prop-types";

import { ModalBody, ModalFooter } from "ui-library/modules";
import { Box, ContentBox } from "ui-library/layouts";
import { Button, Loading, Typography } from "ui-library/atoms";

import { ModalFormLayout } from "containers/modal";
import { Field, form, InputText, meetStatementsPasswordCriteria, required } from "core/form";
import i18n from "core/i18n";

const StatementPasswordForm = ({ canSubmit, handleSubmit, submitting }) => {
    const { t } = i18n.useTranslation();
    return (
        <>
            <ModalBody>
                <Loading loading={submitting}>
                    <Box>
                        <ContentBox>
                            <Typography variant="caption">{t("form.statementPassword.disclaimer")}</Typography>
                        </ContentBox>
                        <ContentBox>
                            <Field
                                name="password"
                                component={InputText}
                                label={t("holder.newPassword")}
                                validateStatic={[required, meetStatementsPasswordCriteria]}
                                onEnter={handleSubmit}
                            />
                        </ContentBox>
                    </Box>
                </Loading>
            </ModalBody>
            <ModalFooter>
                <Button color="blue" variant="contained" onClick={handleSubmit} disabled={!canSubmit}>
                    {t("common.submitChange")}
                </Button>
            </ModalFooter>
        </>
    );
};

StatementPasswordForm.propTypes = {
    canSubmit: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
};

export const createContainer = (formName) =>
    form(formName)((props) => {
        const { t } = i18n.useTranslation();
        return (
            <ModalFormLayout formName={formName} formTitle={t("form.statementPassword")} formIcon="contract">
                <StatementPasswordForm {...props} />
            </ModalFormLayout>
        );
    });
