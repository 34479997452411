import React from "react";

import { BubbleAccordion, Button, Loading, Typography } from "ui-library/atoms";
import { ButtonsLayout, ContentBox, GridItem } from "ui-library/layouts";
import { InfoPanel } from "ui-library/modules";

import i18n from "core/i18n";
import { useSelector } from "react-redux";
import userContracts from "core/userContracts";
import fetching from "core/fetching";
import pageContext from "core/pageContext";
import { useGetClaimReportingUrls, useGetSiteValue } from "core/hooks";
import { CallCenterContactData, ClaimReportCategory } from "enums";

import { ContractSelect } from "./ContractSelect";
import { ContractSummary } from "./ContractSummary";
import { createGetCompliantLineOfBusinessClasses, getSelectedContractId } from "../selectors";
import { ENTITY_CONTRACT_OVERVIEW, SECTION_TO_SCROLL_VIEW_CLASS } from "../constants";

const getFaqSkData = (t) => [
    {
        title: t("page.reportClaim.life.faq.titleOne"),
        children: t("page.reportClaim.life.faq.answerOne"),
    },
    {
        title: t("page.reportClaim.life.faq.titleTwo"),
        children: t("page.reportClaim.life.faq.answerTwo"),
    },
    {
        title: t("page.reportClaim.life.faq.titleThree"),
        children: t("page.reportClaim.life.faq.answerThree"),
    },
    {
        title: t("page.reportClaim.life.faq.titleFour"),
        children: t("page.reportClaim.life.faq.answerFour"),
    },
];

const getFaqCzData = (t) => [
    {
        title: t("page.reportClaim.life.faq.titleOne"),
        children: t("page.reportClaim.life.faq.answerOne"),
    },
    {
        title: t("page.reportClaim.life.faq.titleTwo"),
        children: t("page.reportClaim.life.faq.answerTwo"),
    },
    {
        title: t("page.reportClaim.life.faq.titleThree"),
        children: t("page.reportClaim.life.faq.answerThree"),
    },
    {
        title: t("page.reportClaim.life.faq.titleFour"),
        children: t("page.reportClaim.life.faq.answerFour"),
    },
];

export const ReportLifeSection = () => {
    const { t } = i18n.useTranslation();
    const { isSlovakSite } = pageContext.useGetPageLocalization();
    const selectedContractId = useSelector(getSelectedContractId);
    const compliantLifeClaimClasses = useSelector(createGetCompliantLineOfBusinessClasses(selectedContractId));
    const redirectUrlExternalSpecific = useSelector(
        userContracts.createGetFinalRedirectUrl(selectedContractId, compliantLifeClaimClasses.first()),
    );
    const isContractOverviewLoading = useSelector(fetching.isFetching(ENTITY_CONTRACT_OVERVIEW, false));
    const claimReportingUrls = useGetClaimReportingUrls();
    const getSiteValue = useGetSiteValue();

    return (
        <>
            <GridItem xs={12}>
                <div className={SECTION_TO_SCROLL_VIEW_CLASS}>
                    <InfoPanel icon={"bullhorn"} title={t("page.reportClaim.life")}>
                        <ContentBox>
                            <ContractSelect claimReportCategoryId={ClaimReportCategory.LIFE.id} />
                        </ContentBox>
                        {selectedContractId && <ContractSummary selectedContractId={selectedContractId} />}
                        <ContentBox bigger mb={4}>
                            <Loading loading={isContractOverviewLoading}>
                                <ButtonsLayout>
                                    <Button
                                        color="blue"
                                        variant="contained"
                                        endIcon={"computer"}
                                        externalLink={
                                            compliantLifeClaimClasses.first() ? redirectUrlExternalSpecific : claimReportingUrls.life
                                        }
                                        newTab
                                    >
                                        {t("common.reportOnline")}
                                    </Button>

                                    <Button
                                        color="blue"
                                        variant="outlined"
                                        endIcon={"phone"}
                                        externalLink={`tel:${getSiteValue(CallCenterContactData.PHONE_AS_LINK)}`}
                                    >
                                        {getSiteValue(CallCenterContactData.PHONE)}
                                    </Button>
                                </ButtonsLayout>
                            </Loading>
                        </ContentBox>

                        <ContentBox>
                            <Typography variant="h4" markDown={t("page.reportClaim.titleWhatToPrepare")} />
                        </ContentBox>

                        <ContentBox bigger>
                            <Typography listVariant="check" markDown={t("page.reportClaim.life.whatToPrepare")} />
                        </ContentBox>
                    </InfoPanel>
                </div>
            </GridItem>

            <GridItem xs={12}>
                <ContentBox>
                    <Typography variant={"h3"}>{t("page.reportClaim.faqTitle")}</Typography>
                </ContentBox>
                <BubbleAccordion data={isSlovakSite ? getFaqSkData(t) : getFaqCzData(t)} id="faqAccordion" />
            </GridItem>
        </>
    );
};
