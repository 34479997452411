import { combineReducers } from "redux-immutable";

import { app } from "core/util";
import { UniqaIncident } from "model/uniqaClaims";

import { CLEAR, SET_DISPLAYED_INCIDENT_STATE_TYPE, SET_INCIDENTS, SET_SELECTED_CONTRACT_ID } from "./actions";
import { IncidentDtoIncidentStateEnum } from "types/generated";

export default combineReducers({
    data: combineReducers({
        incidents: app.createDataReducer(SET_INCIDENTS, UniqaIncident.fromServerList(), CLEAR),
    }),
    appState: combineReducers({
        displayedIncidentState: app.createDataReducer(SET_DISPLAYED_INCIDENT_STATE_TYPE, IncidentDtoIncidentStateEnum.Opened, CLEAR),
        selectedContractId: app.createDataReducer(SET_SELECTED_CONTRACT_ID, null, CLEAR),
    }),
});
