import { Box } from "ui-library/layouts/Box/Box";
import { useState } from "react";
import { Button } from "ui-library/atoms";
import { StyledShowMore, StyledShowMoreContainer } from "ui-library/Styled";

export const ShowMoreLayout = ({ children, ...props }) => {
    const [more, setMore] = useState(false);

    const handleClick = () => {
        setMore(!more);
    };

    return (
        <StyledShowMore {...props}>
            <StyledShowMoreContainer $more={more}>
                <Box className="more-content">{children}</Box>
            </StyledShowMoreContainer>
            <Box my={1}>
                <Button onClick={handleClick} color="blue" variant="text" endIcon={more ? "chevron-up" : "chevron-down"}>
                    {more ? "Zobraziť menej" : "Zobraziť viac"}
                </Button>
            </Box>
        </StyledShowMore>
    );
};
