import { Record } from "immutable";

const RequiredDocumentParty = Record({ id: null, msg: null });

export default Object.freeze({
    AGGRIEVED: new RequiredDocumentParty({
        id: "AGGRIEVED",
        msg: "person.aggrieved",
    }),
    INSURED: new RequiredDocumentParty({
        id: "INSURED",
        msg: "person.insured",
    }),
    INSURANT: new RequiredDocumentParty({
        id: "INSURANT",
        msg: "person.insurant",
    }),
});
