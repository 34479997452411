import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { ContentBox } from "ui-library/layouts";
import {
    DatePicker,
    Field,
    getFormFieldValue,
    InputText,
    lettersNumbersBasicInterpunctionCharacters,
    max10Characters,
    max50Characters,
    numberCharacters,
    required,
    SegmentedControl,
} from "core/form";
import i18n from "core/i18n";
import { date } from "core/util";
import {
    ACCOUNT_OWNER_IDENTIFICATION_SECTION,
    BIRTH_NUMBER_FIELD,
    DATE_OF_BIRTH_FIELD,
    DONT_HAVE_BIRTH_NUMBER_FIELD,
    FULL_NAME_FIELD,
} from "../constants";

const PersonIdentification = ({ formName }) => {
    const { t } = i18n.useTranslation();

    const dontHaveBirthNumberSelector = getFormFieldValue(
        formName,
        `${ACCOUNT_OWNER_IDENTIFICATION_SECTION}.${DONT_HAVE_BIRTH_NUMBER_FIELD}`,
    );
    const dontHaveBirthNumberValue = useSelector(dontHaveBirthNumberSelector);

    return (
        <>
            <ContentBox fullWidth>
                <Field
                    name={`${ACCOUNT_OWNER_IDENTIFICATION_SECTION}.${FULL_NAME_FIELD}`}
                    label={t("holder.fullName")}
                    component={InputText}
                    normalize={[lettersNumbersBasicInterpunctionCharacters, max50Characters]}
                    validateStatic={[required]}
                    md={6}
                />
            </ContentBox>
            <ContentBox fullWidth>
                <Field
                    name={`${ACCOUNT_OWNER_IDENTIFICATION_SECTION}.${DONT_HAVE_BIRTH_NUMBER_FIELD}`}
                    component={SegmentedControl}
                    label={t("investments.sellSwitch.dontHaveBirthNumber")}
                />
            </ContentBox>
            {dontHaveBirthNumberValue ? (
                <ContentBox fullWidth>
                    <Field
                        name={`${ACCOUNT_OWNER_IDENTIFICATION_SECTION}.${DATE_OF_BIRTH_FIELD}`}
                        label={t("holder.birthDate")}
                        component={DatePicker}
                        maxDate={date.yesterday}
                        validateStatic={[required]}
                        md={6}
                    />
                </ContentBox>
            ) : (
                <ContentBox fullWidth>
                    <Field
                        name={`${ACCOUNT_OWNER_IDENTIFICATION_SECTION}.${BIRTH_NUMBER_FIELD}`}
                        label={t("holder.birthNumber")}
                        component={InputText}
                        normalize={[numberCharacters, max10Characters]}
                        validateStatic={[required]}
                        md={6}
                    />
                </ContentBox>
            )}
        </>
    );
};

PersonIdentification.propTypes = {
    formName: PropTypes.string.isRequired,
};

export default PersonIdentification;
