import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EntityState, initialState, NAME, SetDataIntoMapPayload, SetDataPayload } from "./constants";

export const setData: CaseReducer<EntityState, PayloadAction<SetDataPayload>> = (state, { payload }) => {
    state.data[payload.entity] = payload.data;
    state.errors.splice(state.errors.indexOf(payload.entity), 1);
};

export const setDataIntoMap: CaseReducer<EntityState, PayloadAction<SetDataIntoMapPayload>> = (state, { payload }) => {
    const values = state.data[payload.entity] || {};
    values[payload.id] = payload.data;
    state.data[payload.entity] = values;
};

export const setError: CaseReducer<EntityState, PayloadAction<string>> = (state, { payload }) => {
    if (!state.errors.includes(payload)) {
        state.errors.push(payload);
    }
};

export const clearData: CaseReducer<EntityState, PayloadAction<string[]>> = (state, { payload }) => {
    payload.forEach((value) => {
        if (!state.errors.includes(value)) {
            state.errors.splice(state.errors.indexOf(value), 1);
        }
    });
};

export const slice = createSlice({
    name: NAME,
    initialState,
    reducers: {
        setData: {
            reducer: setData,
            prepare: (entity: string, data: any) => ({
                payload: { entity, data },
            }),
        },
        setDataIntoMap: {
            reducer: setDataIntoMap,
            prepare: (entity: string, id: string, data: any) => ({
                payload: { entity, data, id },
            }),
        },
        setError,
        clearData: {
            reducer: clearData,
            prepare: (...entities: string[]) => ({
                payload: entities,
            }),
        },
    },
});

export default slice;
