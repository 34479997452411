import React, { FC } from "react";

import { Container, Image, Typography } from "ui-library/atoms";
import { Box, ContentBox, Flexbox, Section } from "ui-library/layouts";

import i18n from "core/i18n";
import womanImage from "assets/campaign/1440x490_Woman.png";

export const SuccessContent: FC = () => {
    const { t } = i18n.useTranslation();

    return (
        <>
            <Flexbox direction={{ lg: "row" }} justifyContent={"center"} mb={5}>
                <Box sx={{ width: 960 }}>
                    <Image borderRadius={0} alt={"table"} src={womanImage} shadow={false} />
                </Box>
            </Flexbox>

            <Container maxWidth="md">
                {/* Title */}
                <Section>
                    <Typography variant="h1">{t("campaign.electronicCommunication.successTitle")}</Typography>
                </Section>

                {/* Introduction text */}
                <ContentBox mb={10}>
                    <Typography component="p" markDown={t("campaign.electronicCommunication.successText")} />
                </ContentBox>
            </Container>
        </>
    );
};
