import React, { FC } from "react";
import { useSelector } from "react-redux";

import { ContentBox } from "ui-library/layouts";
import { LabelTextItem, PageLead } from "ui-library/modules";
import { Container } from "ui-library/atoms";

import i18n from "core/i18n";
import localized from "core/localized";
import { dateTimeToLocalFullDate } from "core/formatters";

import { getIncidentDetail } from "../selectors";
import { getImageCategory, getLocalizedInsuranceRisk, getLocalizedLineOfBusiness } from "../util";

export const IncidentHeaderOneTimeUser: FC = () => {
    const { t } = i18n.useTranslation();

    const localizedLineOfBusiness = useSelector(localized.getLocalizedUniqaLineOfBusinessMap);
    const localizedInsuranceRisk = useSelector(localized.getLocalizedUniqaInsuranceRiskMap);

    const incidentDetail = useSelector(getIncidentDetail);

    return (
        <Container>
            <PageLead
                leadImg={getImageCategory(incidentDetail)}
                title={`${getLocalizedLineOfBusiness(incidentDetail, localizedLineOfBusiness)}${getLocalizedInsuranceRisk(
                    incidentDetail,
                    localizedInsuranceRisk,
                )}`}
            >
                <ContentBox bigger>
                    <LabelTextItem fontVariant="perex" label={t("claim.incidentNumber")} text={incidentDetail.incidentNumber} />
                    <LabelTextItem fontVariant="perex" label={t("common.contractNumber")} text={incidentDetail.policyNumber} />
                    <LabelTextItem
                        fontVariant="perex"
                        label={t("common.creationDate")}
                        text={dateTimeToLocalFullDate(incidentDetail.incidentDate)}
                    />
                </ContentBox>
            </PageLead>
        </Container>
    );
};
