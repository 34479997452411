import React from "react";
import { useSelector } from "react-redux";

import { ContentBox } from "ui-library/layouts";
import { InfoPanel } from "ui-library/modules";

import i18n from "core/i18n";

import { PaymentAdvice, ContractSelect, AmountTypeSelect, PaymentFundSelect, PaymentBox } from "../containers";
import { getContractId, getIsAmountMultipleChoice } from "../selectors";

export const PayTab = () => {
    const { t } = i18n.useTranslation();
    const selectedContractId = useSelector(getContractId);
    const isMultipleChoice = useSelector(getIsAmountMultipleChoice);
    const showAmountTypeSelect = selectedContractId && isMultipleChoice;

    return (
        <>
            <InfoPanel bgVariant={"white"} icon={"credit-card"} title={t("payments.paymentPerContract")}>
                <ContentBox>
                    <ContractSelect />
                    {showAmountTypeSelect && <AmountTypeSelect />}
                    <PaymentFundSelect />
                </ContentBox>

                {selectedContractId ? <PaymentBox /> : <PaymentAdvice />}
            </InfoPanel>
        </>
    );
};
