import { model } from "core/util";
import { PaymentsSchedulerDto, PaymentsScheduler as PaymentsSchedulerType } from "types";

import { Amount } from "./Amount";
import { CodeValue } from "./CodeValue";

export const PaymentsScheduler = model.createModel<PaymentsSchedulerDto, PaymentsSchedulerType>((json) => ({
    periodicity: CodeValue.fromServer(json.Periodicity),
    modalAmount: Amount.fromServer(json.ModalAmount),
    paymentMode: CodeValue.fromServer(json.PaymentMode),
    paymentType: json.PaymentType,
    paymentStartDate: json.PaymentStartDate,
    paymentEndDate: json.PaymentEndDate,
}));
