import slice from "./slice";
import * as selectors from "./selectors";
import * as util from "./util";
import * as hooks from "./hooks";

export default {
    NAME: slice.name,
    reducer: slice.reducer,
    ...slice.actions,
    ...selectors,
    ...util,
    ...hooks,
};
