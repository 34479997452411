import { withTheme } from "@mui/styles";
import styled from "styled-components";
// import { Container } from 'atoms';
import { Box } from "ui-library/layouts/Box/Box";
import { StyledContainer } from "ui-library/Styled/Layouts/Blocks";

export const StyledSectionBanner = withTheme(styled(Box)`
    && {
        position: relative;
        min-height: 640px;
        background-color: #eef2fa;
        .banner-img {
            position: absolute;
            width: 100%;
            max-width: 1440px;
            height: 100%;
            object-fit: cover;
            left: calc(50% - 720px);
            top: 0;
            background-size: auto;
            background-position: right bottom;
            background-repeat: no-repeat;
            @media (max-width: ${(props) => props.theme.breakpoints.values.xl}px) {
                max-width: unset;
                left: unset;
            }
            @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
                top: unset;
                background-size: 500px 300px;
                background-position: right bottom;
                bottom: 40px;
                right: 15px;

                /* height: 160px;
                bottom: 140px;
                top: unset;
                object-fit: contain;
              
                /* width: 100%;
                max-width: 200px;
                min-height: 180px;
                bottom: 0;
                right: 0;
                height: auto;
                background-size: unset;
                background-position: unset;
                background-repeat: no-repeat;
                object-fit: contain;
                top: unset; */
            }
        }
    }
`);
export const StyledSectionContainer = withTheme(styled(StyledContainer)`
    && {
        position: relative;
        z-index: 0;
    }
`);
