import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { Typography } from "ui-library/atoms";
import { ContentBox } from "ui-library/layouts";

import i18n from "core/i18n";
import userInfo from "core/userInfo";
import { oneLineNameWithTitles } from "core/formatters";

import { ChangePropositionsGeneralTable } from "./ChangePropositionsGeneralTable";
import { ChangePropositionsTransactionTable } from "./ChangePropositionsTransactionTable";

export const ChangePropositionsTables = ({ idObjectSelector, selectors, actions, changePropositions }) => {
    const { t } = i18n.useTranslation();
    const idObject = useSelector(idObjectSelector);
    const agent = useSelector(userInfo.createGetAgentByObjectId(idObject));

    const isChangePropositionsEmpty = useSelector(changePropositions).isEmpty();
    const isGeneralPropositionsEmpty = useSelector(selectors.getChangePropositionsData).isEmpty();
    const isTransactionPropositionsEmpty = useSelector(selectors.getChangePropositionsTransactionData).isEmpty();

    if (isChangePropositionsEmpty) {
        return <Typography variant="p">{t("changeProposition.noProposition")}</Typography>;
    } else {
        return (
            <>
                <ContentBox>
                    <Typography variant="p">
                        {t("changeProposition.text", { agentName: oneLineNameWithTitles(agent?.get("legalEntity")) })}
                    </Typography>
                </ContentBox>

                {!isGeneralPropositionsEmpty && <ChangePropositionsGeneralTable selectors={selectors} actions={actions} />}

                {!isTransactionPropositionsEmpty && <ChangePropositionsTransactionTable selectors={selectors} actions={actions} />}
            </>
        );
    }
};

ChangePropositionsTables.propTypes = {
    idObjectSelector: PropTypes.func.isRequired,
    selectors: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    changePropositions: PropTypes.any.isRequired,
};
