import slice from "./slice";
import * as selectors from "./selectors";
import { loadRssContent } from "./saga";

/**
 * Module for loading marketing contents into forms (not limited to them).
 */
const cmsContent = {
    NAME: slice.name,
    reducer: slice.reducer,
    ...selectors,

    loadRssContent,
};
export default cmsContent;
