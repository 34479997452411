import { Record } from "immutable";

class ChangePropositionStatus extends Record({ id: null, msg: null }) {}

export default Object.freeze({
    AWAITING_ACCEPTATION: new ChangePropositionStatus({ id: "AWAITING_ACCEPTATION", msg: "changeProposition.stateAwaiting" }),
    ACCEPTED: new ChangePropositionStatus({ id: "ACCEPTED", msg: "changeProposition.stateAccepted" }),
    DECLINED: new ChangePropositionStatus({ id: "DECLINED", msg: "changeProposition.stateDeclined" }),
    CANCELLED: new ChangePropositionStatus({ id: "CANCELLED", msg: "changeProposition.stateCancelled" }),
});
