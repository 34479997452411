import { v4 as uuid } from "uuid";

import createModel from "../createModel";
import { UniqaDocumentType } from "./UniqaDocumentType";

export const UniqaRequiredDocument = createModel("UniqaRequiredDocument", (json) => ({
    reactKey: uuid(),
    documentType: UniqaDocumentType.fromServer(json.documentType),
    counterParty: json.counterParty,
    state: json.state,
    otherDescription: json.otherDescription,
}));
