import { createAction } from "@reduxjs/toolkit";

import { app } from "core/util";
import { MutualFundsRegularContract } from "types";

import { NAME } from "./constants";

export const SET_AVAILABLE_PROFILES = `${NAME}/SET_AVAILABLE_PROFILES`;
export const SEND_ENQUEUE_REQUEST = `${NAME}/SEND_ENQUEUE_REQUEST`;
export const RESTART_ENQUEUE_CHECKING = `${NAME}/RESTART_ENQUEUE_CHECKING`;
export const SET_SELECTED_FUND = `${NAME}/SET_SELECTED_FUND`;
export const CLEAR = `${NAME}/CLEAR`;

export const setMutualFundsRegularContractDetail = app.createActionWithPayload<MutualFundsRegularContract>(
    `${NAME}/SET_MUTUAL_FUNDS_REGULAR_CONTRACT_DETAIL`,
);

export const setAvailableProfiles = (availableProfiles) => ({
    type: SET_AVAILABLE_PROFILES,
    payload: availableProfiles,
});

export const sendEnqueueRequest = (idObject, idOperation) => ({
    type: SEND_ENQUEUE_REQUEST,
    payload: { idObject, idOperation },
});

export const restartEnqueueChecking = () => ({
    type: RESTART_ENQUEUE_CHECKING,
});

export const setSelectedFund = (fundIsin) => ({
    type: SET_SELECTED_FUND,
    payload: fundIsin,
});

export const clear = createAction(`${NAME}/CLEAR`);
