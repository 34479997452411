import React from "react";
import PropTypes from "prop-types";

import i18n from "core/i18n";
import { localFullDate } from "core/formatters";
import { Box } from "ui-library/layouts";
import { LabelTextItem } from "ui-library/modules";

export const HolderCompanySectionLegacy = ({ holder }) => {
    const { t } = i18n.useTranslation();
    return (
        <Box>
            <LabelTextItem label={t("holder.companyName")} text={holder.companyName} />
            <LabelTextItem label={t("holder.companyID")} text={holder.companyID} />
            <LabelTextItem label={t("holder.companyAddress")} text={localFullDate(holder.get("birthDate"))} />
        </Box>
    );
};

HolderCompanySectionLegacy.propTypes = {
    holder: PropTypes.object.isRequired,
};
