import React from "react";
import PropTypes from "prop-types";

import { ContentBox } from "ui-library/layouts";
import { Typography } from "ui-library/atoms";

import i18n from "core/i18n";

export const AllowedPensionTypesSection = ({ allowedPensionTypes }) => {
    const { t } = i18n.useTranslation();

    return (
        <>
            <ContentBox>
                <Typography markDown={t("pension.redeemSavings.pensionOptions")} />
            </ContentBox>

            <ContentBox>
                {allowedPensionTypes.map((pensionType) => (
                    <Typography key={pensionType.id} listVariant="check" markDown={t(pensionType.description)} />
                ))}
            </ContentBox>
        </>
    );
};

AllowedPensionTypesSection.propTypes = {
    allowedPensionTypes: PropTypes.object.isRequired,
};
