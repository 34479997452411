import { debounce, put, select, takeEvery } from "redux-saga/effects";
import { initialize } from "redux-form/immutable";

import { formChangeMatcher, getFormValues } from "core/form";

export const createSaga = (actions, { name }) => {
    function* tableFilterSaga() {
        yield debounce(500, formChangeMatcher(name), onSubmit);
        yield takeEvery(actions.RESET_FILTER, resetFilter);
    }

    function* onSubmit() {
        const formValues = yield select(getFormValues, name);
        yield put(actions.setFilter(formValues));
    }

    function* resetFilter() {
        yield put(initialize(name));
    }

    return {
        tableFilterSaga,
    };
};
