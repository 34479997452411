import React from "react";
import { useSelector } from "react-redux";

import entity from "core/entity";
import fetching from "core/fetching";
import { localFullDate } from "core/formatters";
import i18n from "core/i18n";
import { Box, ContentBox } from "ui-library/layouts";
import { DataTable, InfoPanel } from "ui-library/modules";

import { ENTITY_CLAIM_INSPECTION_ORDERS } from "../constants";
import { WriteClaimOrOrderNoteButton } from "app/pages/detailIncident/containers/button";

const columns = (t) => [
    {
        field: "supplier",
        headerName: t("claim.orderSupplier"),
        flex: 2,
    },
    {
        field: "tasks",
        headerName: t("claim.orderType"),
        flex: 3,
        renderCell: (params) => params.value.join(", "),
    },
    {
        field: "plannedDate",
        headerName: t("common.scheduledTime"),
        flex: 1,
        renderCell: (params) => localFullDate(params.value),
    },
];

export const ClaimOrders = () => {
    const { t } = i18n.useTranslation();

    const isOrdersLoading = useSelector(fetching.isFetching(ENTITY_CLAIM_INSPECTION_ORDERS, true));
    const isOrdersError = useSelector(entity.isError(ENTITY_CLAIM_INSPECTION_ORDERS));
    const orders = useSelector(entity.getData(ENTITY_CLAIM_INSPECTION_ORDERS));
    const mappedOrders = orders && orders.toArray();

    return (
        <Box>
            <InfoPanel
                icon="user-check"
                title={t("claim.orders")}
                actionButton={
                    mappedOrders && mappedOrders.length > 0 ? (
                        <WriteClaimOrOrderNoteButton type={WriteClaimOrOrderNoteButton.Type.ORDER} />
                    ) : null
                }
            >
                <ContentBox bigger>
                    <DataTable
                        disableColumnReorder
                        rowHeight={66}
                        hideFooter={true}
                        withWrapper={false}
                        getRowId={(row) => row.reactKey}
                        rows={mappedOrders}
                        columns={columns(t)}
                        emptyMsg={t("common.noData")}
                        errorMsg={t("error.sectionUnavailable")}
                        isLoading={isOrdersLoading}
                        isError={isOrdersError}
                    />
                </ContentBox>
            </InfoPanel>
        </Box>
    );
};
