import { call, put, fork, take, cancel, actionChannel, delay } from "redux-saga/effects";

import slice from "./slice";

export default function* () {
    const channel = yield actionChannel(slice.actions.setFetching.type);
    const fetchingTask = {};
    for (;;) {
        const {
            payload: { entity, isFetching, immediate },
        } = yield take(channel);
        if (isFetching) {
            if (!fetchingTask[entity]) {
                fetchingTask[entity] = yield fork(setFetchingSaga, entity, immediate);
            }
        } else {
            yield put(slice.actions.setFetchingInternal(entity, false));
            const task = fetchingTask[entity];
            if (task) {
                yield cancel(task);
                fetchingTask[entity] = null;
            }
        }
    }
}

export const withFetching = function* withFetching(fetchingName: string, fn, ...params) {
    yield put(slice.actions.setFetching(fetchingName, true));
    yield call(fn, ...params);
    yield put(slice.actions.setFetching(fetchingName, false));
};

const setFetchingSaga = function* startFetching(entity: string, immediate: boolean) {
    // wait some ms before setting fetching if not immediate. This will visually help fast loadings.
    if (!immediate) {
        yield delay(150);
    }
    yield put(slice.actions.setFetchingInternal(entity, true));
};
