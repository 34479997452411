import React, { FC } from "react";

import { localPhone, oneLineNameWithTitles } from "core/formatters";
import i18n, { TFunction } from "core/i18n";
import { Box, GridItem } from "ui-library/layouts";
import { InfoPanel, LabelTextItem } from "ui-library/modules";
import { StandaloneLink } from "ui-library/atoms";
import { Holder } from "types";

const getPersonNameLine = (t: TFunction, holder: Holder) => (
    <LabelTextItem label={t("holder.fullName")} text={oneLineNameWithTitles(holder)} />
);
const getCompanyNameLine = (t: TFunction, holder: Holder) => <LabelTextItem label={t("holder.companyName")} text={holder.companyName} />;

type HolderOverviewGridItemProps = {
    holder: Holder;
    // TODO: martin.rodin fix types after enums are converted
    individualPersonType: any;
    detailLink: string;
};

export const HolderOverviewGridItem: FC<HolderOverviewGridItemProps> = ({ holder, individualPersonType, detailLink }) => {
    const { t } = i18n.useTranslation();

    return (
        <GridItem display="flex" md={5} xs={12}>
            <InfoPanel
                icon="user"
                title={t(individualPersonType.msg)}
                headerAction={
                    <StandaloneLink to={detailLink} color="blue">
                        {t("common.detail")}
                    </StandaloneLink>
                }
            >
                <Box>
                    {holder.isPerson ? getPersonNameLine(t, holder) : getCompanyNameLine(t, holder)}
                    <LabelTextItem label={t("holder.phone")} text={localPhone(holder.phone)} />
                    <LabelTextItem label={t("holder.email")} text={holder.email} />
                </Box>
            </InfoPanel>
        </GridItem>
    );
};
