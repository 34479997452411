import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

import bannerImg from "ui-library/assets/images/banner-prihlasenie.svg";
import { Button } from "ui-library/atoms";
import { LandingPageLayout } from "ui-library/layouts";
import { SectionBanner } from "ui-library/modules";

import i18n from "core/i18n";
import { Pages } from "routeConstants";
import { pageRoutes } from "routeUrls";

export const ChangeUsernameSuccess: FC = () => {
    const { t } = i18n.useTranslation();
    const navigate = useNavigate();

    return (
        <LandingPageLayout>
            <SectionBanner title={t("success.username.title")} description={t("success.username.text")} imageUrl={bannerImg}>
                <Button variant="contained" color="blue" onClick={() => navigate(pageRoutes[Pages.LOGIN])}>
                    {t("general.text.proceedToLogin")}
                </Button>
            </SectionBanner>
        </LandingPageLayout>
    );
};
