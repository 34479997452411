import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { Container } from "ui-library/atoms";
import { FormBlock } from "ui-library/modules";

import { NotApplicableRedeemsSection } from "containers/contract";
import { FormErrorBox, getFormFieldValue } from "core/form";
import { fn } from "core/util";
import { RedeemPensionDpsPensionTypes, RedeemPensionDpsRedeemTypes } from "enums";

import {
    AllowedPensionTypesSection,
    AllowedRedeemTypesSection,
    PaymentSettingsSection,
    RedeemParametersPensionDpsBasedOnType,
    SelectPensionTypeSection,
    SelectRedeemTypeSection,
} from "../";

import { StepOneButtonsBox } from "../../components";
import { PENSION_TYPE_FIELD, REDEEM_TYPE_FIELD } from "../../constants";

export const PensionDpsStepOneForm = ({
    handleSubmit,
    canSubmit,
    formName,
    idObject,
    contractAdditionalInfo,
    allowedPensionTypes,
    redeemAllowedRedeemTypesSelector,
    redeemParametersSelector,
    notApplicableRedeemsSelector,
}) => {
    const selectedPensionTypeFormValue = useSelector(getFormFieldValue(formName, PENSION_TYPE_FIELD));
    const selectedPensionType = fn.getEnumItemById(RedeemPensionDpsPensionTypes, selectedPensionTypeFormValue);
    const allowedRedeemTypes = useSelector(redeemAllowedRedeemTypesSelector(selectedPensionType));

    const selectedRedeemTypeFormValue = useSelector(getFormFieldValue(formName, REDEEM_TYPE_FIELD));
    const selectedRedeemType = fn.getEnumItemById(RedeemPensionDpsRedeemTypes, selectedRedeemTypeFormValue);
    const selectedRedeemTypeParameters = useSelector(redeemParametersSelector(selectedRedeemType));

    return (
        <Container maxWidth="md">
            {/* Form Errors */}
            <FormErrorBox form={formName} />

            {/* Main Form Container */}
            <FormBlock mb={4}>
                {/* Pension type selection */}
                <AllowedPensionTypesSection allowedPensionTypes={allowedPensionTypes} />
                <NotApplicableRedeemsSection notApplicableRedeemsSelector={notApplicableRedeemsSelector} />
                <SelectPensionTypeSection allowedPensionTypes={allowedPensionTypes} />

                {/* Redeem type selection */}
                {selectedPensionType && (
                    <>
                        <AllowedRedeemTypesSection selectedPensionType={selectedPensionType} allowedRedeemTypes={allowedRedeemTypes} />
                        <SelectRedeemTypeSection allowedRedeemTypes={allowedRedeemTypes} />
                    </>
                )}

                {/* Redeem parameters selection */}
                {selectedRedeemType && (
                    <>
                        <RedeemParametersPensionDpsBasedOnType
                            formName={formName}
                            contractAdditionalInfo={contractAdditionalInfo}
                            selectedRedeemType={selectedRedeemType}
                            selectedRedeemTypeParameters={selectedRedeemTypeParameters}
                        />
                        <PaymentSettingsSection formName={formName} />
                    </>
                )}

                <StepOneButtonsBox idObject={idObject} canSubmit={canSubmit} handleSubmit={handleSubmit} />
            </FormBlock>
        </Container>
    );
};

PensionDpsStepOneForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    canSubmit: PropTypes.bool.isRequired,
    formName: PropTypes.string.isRequired,
    idObject: PropTypes.any.isRequired,
    contractAdditionalInfo: PropTypes.object.isRequired,
    allowedPensionTypes: PropTypes.object.isRequired,
    redeemAllowedRedeemTypesSelector: PropTypes.func.isRequired,
    redeemParametersSelector: PropTypes.func.isRequired,
    notApplicableRedeemsSelector: PropTypes.func.isRequired,
};
