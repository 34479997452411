import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialState, NAME, PageContextState, SetContextPayload } from "./constants";
import { Locale } from "types";

export const setLocale: CaseReducer<PageContextState, PayloadAction<Locale>> = (state, { payload }) => {
    state.pageLocalization.locale = payload;
};

export const setContext: CaseReducer<PageContextState, PayloadAction<SetContextPayload>> = (state, { payload }) => {
    // eslint-disable-next-line
    state.clientId = payload.clientId;
    state.configUrls = payload.configUrls;
    state.disclaimer = payload.disclaimer;
    state.featureFlags = payload.featureFlags;
    state.infoTexts = payload.infoTexts;
    state.keys = payload.keys;
    state.notAllowed = payload.notAllowed;
    state.pageLocalization = payload.pageLocalization;
};

export const slice = createSlice({
    name: NAME,
    initialState,
    reducers: {
        setLocale,
        setContext,
    },
});

export default slice;
