import "core-js/stable";
import "regenerator-runtime/runtime";

import React from "react";
import ReactDOM from "react-dom";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import activePages from "app/activePages";
import { ScriptLoader } from "containers";
import createRootStore from "store";
import router from "core/router";

import App from "./App";
import ScrollToTop from "app/layout/helpers/ScrollToTop";

const history = createBrowserHistory({ window });
router.registerRoutes(activePages);

const Root = createRootStore(history);

ReactDOM.render(
    <Root>
        <HistoryRouter history={history}>
            <ScrollToTop />
            <App />
        </HistoryRouter>
        <ScriptLoader />
    </Root>,

    document.getElementById("root"),
);
