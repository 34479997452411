import React from "react";
import { useSelector } from "react-redux";

import entity from "core/entity";
import fetching from "core/fetching";
import i18n from "core/i18n";
import { dateTimeToLocalFullDate } from "core/formatters";
import { DataTable } from "ui-library/modules";
import { GridItem, GridLayout } from "ui-library/layouts";

import { ENTITY_REQUESTS } from "../constants";
import { createGetTranslatedRequests } from "../selectors";

const columns = (t) => [
    {
        field: "date",
        headerName: t("requests.requestDate"),
        flex: 1.5,
        minWidth: 120,
        renderCell: (params) => dateTimeToLocalFullDate(params.value),
    },
    {
        field: "id",
        headerName: t("common.id"),
        flex: 0.5,
    },
    {
        field: "changeTypes",
        headerName: t("requests.changeType"),
        minWidth: 350,
        flex: 5,
    },
    {
        field: "contractNumber",
        headerName: t("common.contractNumber"),
        minWidth: 120,
        flex: 2,
    },
    {
        field: "status",
        headerName: t("requests.status"),
        minWidth: 200,
        flex: 3,
    },
    {
        field: "author",
        headerName: t("requests.author"),
        minWidth: 120,
        flex: 2,
    },
];

export const RequestsTab = () => {
    const { t } = i18n.useTranslation();

    const isRequestsLoading = useSelector(fetching.isFetching(ENTITY_REQUESTS, true));
    const isRequestsError = useSelector(entity.isError(ENTITY_REQUESTS));
    const requests = useSelector(createGetTranslatedRequests);

    return (
        <GridLayout defaultSpacing>
            <GridItem xs={12}>
                <DataTable
                    disableColumnReorder
                    icon="document"
                    title={t("requests.list")}
                    headerHeight={62}
                    pageSize={10}
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    getRowId={(row) => row.reactKey}
                    rows={requests.toArray()}
                    columns={columns(t)}
                    isLoading={isRequestsLoading}
                    isError={isRequestsError}
                />
            </GridItem>
        </GridLayout>
    );
};
