import { call, put, select, takeEvery } from "redux-saga/effects";
import { resetSection } from "redux-form/immutable";
import { Selector } from "@reduxjs/toolkit";

import errorHandling from "core/errorHandling";
import { fieldChangeMatcher, formWrapper } from "core/form";
import userInfo from "core/userInfo";
import { sentry } from "core/util";
import { pensionDpsApi } from "serverApi";
import { PensionStatementSettings, State } from "types";

import { RECEIVE_STATEMENT_FIELD, STATEMENT_PERIODICITY_FIELD } from "./constants";

export function* createSaga(formName: string, idObject: number, getAccountDetail: Selector<State, PensionStatementSettings>) {
    try {
        yield call(formSaga(formName, getAccountDetail), idObject);
    } catch (e) {
        sentry.captureException(e);
        yield put(errorHandling.addServiceError(formName, e.identifier));
    }
}

const formSaga = (formName: string, getStatementSettings: Selector<State, PensionStatementSettings>) =>
    // @ts-ignore we won't type sagas
    formWrapper(formName, {
        *persistentEffects() {
            yield takeEvery(fieldChangeMatcher(formName, RECEIVE_STATEMENT_FIELD), enabledChangedSaga, formName);
        },
        *initialize() {
            yield call(userInfo.checkUserVerified);
            yield put(errorHandling.removeServiceErrors(formName));

            const statementSettings: PensionStatementSettings = yield select(getStatementSettings);
            return {
                [RECEIVE_STATEMENT_FIELD]: statementSettings.receiveStatement,
                [STATEMENT_PERIODICITY_FIELD]: statementSettings.statementPeriodicity,
            };
        },
        *save(values, idObject) {
            const statementPeriodicity = values.get(STATEMENT_PERIODICITY_FIELD) ? values.get(STATEMENT_PERIODICITY_FIELD) : null;
            const requestBody = {
                idObject,
                receiveStatement: values.get(RECEIVE_STATEMENT_FIELD),
                statementPeriodicity,
            };
            yield call(pensionDpsApi.updateStatementSettings, requestBody);
        },
    });

function* enabledChangedSaga(formName) {
    yield put(resetSection(formName, STATEMENT_PERIODICITY_FIELD));
}
