import { model } from "core/util";
import { PensionPpContractDetail as PensionPpContractDetailType, PensionPpContractDetailDto } from "types";

import { Holder } from "../../holder";
import { ElectronicCommunication } from "../../electronicCommunication";
import { PensionBeneficiary } from "../PensionBeneficiary";
import { PensionPpAccountDetail } from "./PensionPpAccountDetail";
import { PensionStatementSettings } from "../PensionStatementSettings";

export const PensionPpContractDetail = model.createModel<PensionPpContractDetailDto, PensionPpContractDetailType>((json) => ({
    holder: Holder.fromServer(json.Holder),
    pensionPpAccountDetail: PensionPpAccountDetail.fromServer(json.PensionAccountDetail),
    beneficiaries: PensionBeneficiary.fromServerList(json.Beneficiaries),
    electronicCommunication: ElectronicCommunication.fromServer(json.ElectronicCommunication),
    statementSettings: PensionStatementSettings.fromServer(json.ClientStatementSettings),
}));
