import styled from "styled-components";
import { withTheme } from "@mui/styles";

const markerPosition = (props) => {
    if (props.variant === "circle") {
        return "left :-20px; top : -3px";
    } else if (props.variant === "check" || props.variant === "error") {
        return "left :-28px; top : 3px";
    }
};

export const StyledUnorderedList = withTheme(styled("ul")`
    color: ${(props) => props.theme.palette.black.main};
    list-style: none;
    font-family: ${(props) => props.theme.typography.fontFamily};
    padding: 0;
    margin: ${(props) => (props.$variant === "circle" ? "0 0 0 25px" : "0 0 0 31px")};
`);

export const StyledOrderedList = withTheme(styled("ol")`
    color: ${(props) => props.theme.palette.black.main};
    font-family: ${(props) => props.theme.typography.fontFamily};
    padding: 0;
    margin: 0 0 0 25px;
`);

export const StyledListItem = withTheme(styled("li")`
    color: ${(props) => props.theme.palette.black.main};
    line-height: 24px;
    position: relative;
    font-size: 16px;
    margin-bottom: ${(props) => (props.$compact ? "8px" : "16px")};
`);

export const StyledUnorderedListItem = withTheme(styled(StyledListItem)`
    .marker {
        position: absolute;
        ${(props) => markerPosition(props)};
    }
`);

export const StyledOrderedListItem = withTheme(styled(StyledListItem)`
    margin-left: -10px;
    padding-left: 10px;
`);
