import { Button } from "ui-library/atoms/Button/Button";
import { Image } from "ui-library/atoms/Image/Image";
import { Typography } from "ui-library/atoms/Typography/Typography";
import { Box } from "ui-library/layouts/Box/Box";
import PropTypes from "prop-types";
import { StyledBlogCardContent, StyledBlogCardImage, StyledBlogCardWrapper } from "ui-library/Styled";

export const BlogCard = ({
    id,
    thumbSrc,
    thumbSrcset,
    title,
    perex,
    buttonColor,
    buttonLabel,
    buttonOnClick,
    variant,
    shadow,
    padding,
    ...props
}) => {
    return (
        <StyledBlogCardWrapper variant={variant} shadow={shadow} padding={padding}>
            {thumbSrc && variant === "white" ? (
                <StyledBlogCardImage>
                    <Image borderRadius={0} src={thumbSrc} srcSet={thumbSrcset} alt={title} />
                </StyledBlogCardImage>
            ) : (
                <></>
            )}
            <StyledBlogCardContent>
                <Typography variant="h3" component="h2" color={variant === "cloud" ? "blue" : ""}>
                    {title}
                </Typography>
                <Typography variant="p">{perex}</Typography>
                {buttonLabel && (
                    <Box pt={3}>
                        <Button color={buttonColor} onClick={buttonOnClick} fullWidth>
                            {buttonLabel}
                        </Button>
                    </Box>
                )}
            </StyledBlogCardContent>
        </StyledBlogCardWrapper>
    );
};

BlogCard.propTypes = {
    id: PropTypes.string,
    thumbSrc: PropTypes.string,
    thumbSrcset: PropTypes.string,
    title: PropTypes.string,
    perex: PropTypes.string,
    buttonColor: PropTypes.string,
    buttonLabel: PropTypes.string,
    buttonOnClick: PropTypes.func,
    variant: PropTypes.string,
    shadow: PropTypes.bool,
    padding: PropTypes.bool,
};

BlogCard.defaultProps = {
    buttonColor: "green",
    variant: "white", //cloud, white
    shadow: true,
    padding: false,
};
