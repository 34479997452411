import { Map } from "immutable";

import { Countries, Locales } from "enums";

const getKey = (site, locale) => `${site?.id}-${locale?.id}`;

const loaders = Map()
    .set(getKey(Countries.CZ, Locales.cs_CZ), async () => {
        const csCommons = await import(/* webpackChunkName: "cs" */ "translations/cs_commons.yaml");
        const csSpecific = await import(/* webpackChunkName: "cs" */ "translations/cs_specific.yaml");
        return { ...csCommons.default, ...csSpecific.default };
    })
    .set(getKey(Countries.SK, Locales.sk_SK), async () => {
        const skCommons = await import(/* webpackChunkName: "sk" */ "translations/sk_commons.yaml");
        const skSpecific = await import(/* webpackChunkName: "sk" */ "translations/sk_specific.yaml");
        return { ...skCommons.default, ...skSpecific.default };
    })
    .set(getKey(Countries.CZ, Locales.en_US), async () => {
        const enCommons = await import(/* webpackChunkName: "en_cz" */ "translations/en_commons.yaml");
        const enCsSpecific = await import(/* webpackChunkName: "en_cz" */ "translations/en_cs_specific.yaml");
        return { ...enCommons.default, ...enCsSpecific.default };
    })
    .set(getKey(Countries.SK, Locales.en_US), async () => {
        const enCommons = await import(/* webpackChunkName: "en_sk" */ "translations/en_commons.yaml");
        const enSkSpecific = await import(/* webpackChunkName: "en_sk" */ "translations/en_sk_specific.yaml");
        return { ...enCommons.default, ...enSkSpecific.default };
    });

function getTranslations(site, locale) {
    const translationsKey = getKey(site, locale);

    if (!loaders.has(translationsKey)) {
        throw new Error(`No translations for site '${site}' locale '${locale}' `);
    }
    const loader = loaders.get(translationsKey);
    return loader();
}

export default {
    getTranslations,
};
