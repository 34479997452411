import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorHandlingState, initialState, NAME } from "./constants";
import { AddServiceErrorPayload } from "core/auth/constants";

const addServiceError: CaseReducer<ErrorHandlingState, PayloadAction<AddServiceErrorPayload>> = (state, { payload }) => {
    const errorsForService = state.serviceErrors[payload.service];
    if (errorsForService) {
        errorsForService.push(payload.errorIdentifier);
        state.serviceErrors[payload.service] = errorsForService;
    } else {
        state.serviceErrors[payload.service] = [payload.errorIdentifier];
    }
};

const removeServiceErrors: CaseReducer<ErrorHandlingState, PayloadAction<string>> = (state, { payload }) => {
    state.serviceErrors[payload] = undefined;
};

export const slice = createSlice({
    name: NAME,
    initialState,
    reducers: {
        addServiceError: {
            reducer: addServiceError,
            prepare: (service: string, errorIdentifier: string) => ({
                payload: { service, errorIdentifier },
            }),
        },
        removeServiceErrors,
    },
});

export default slice;
