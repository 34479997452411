import { Map } from "immutable";
import { v4 as uuid } from "uuid";

import { FUND_FIELD, PERCENTAGE_FIELD } from "./constants";

export const createEmptyFundPercentageRow = () =>
    Map({
        reactKey: uuid(),
        [FUND_FIELD]: "",
        [PERCENTAGE_FIELD]: "",
    });
