import { model } from "core/util";

import { AmountLegacy, CodeValueLegacy } from "../common";

export const LifePaymentRequests = model.createModelLegacy("LifePaymentRequests", (json) => ({
    paymentRequestType: CodeValueLegacy.fromServer(json.PaymentRequestType),
    paymentRequestAmountDue: AmountLegacy.fromServer(json.PaymentRequestAmountDue),
    paymentRequestDebtMonths: json.PaymentRequestDebtMonths,
    paymentRequestPrintDate: json.PaymentRequestPrintDate,
}));
