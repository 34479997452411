import React from "react";
import { useDispatch, useSelector } from "react-redux";

import carOption from "ui-library/assets/images/car-option.svg";
import houseOption from "ui-library/assets/images/house-option.svg";
import lifeInsuranceOption from "ui-library/assets/images/life-insurance-option.svg";
import travelInsuranceOption from "ui-library/assets/images/travel-insurance-option.svg";
import { Typography } from "ui-library/atoms";
import { ContentBox, GridItem, GridLayout } from "ui-library/layouts";
import { ImageLabelRadio, ImageLabelRadioGroup } from "ui-library/modules";

import i18n from "core/i18n";
import { ClaimReportCategory } from "enums";
import router from "core/router";
import { Pages, Params, Tabs } from "routeConstants";

import { ReportHomeSection, ReportLifeSection, ReportTravelSection, ReportVehicleSection } from "../containers";
import { getSelectedClaimCategoryId, hasContractOfGivenCategoryId } from "../selectors";

export const ReportClaimTab = () => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();
    const selectedClaimCategoryId = useSelector(getSelectedClaimCategoryId);

    const insuranceEventChange = (event) => {
        dispatch(
            router.navigate(
                Pages.CLAIMS,
                Tabs.REPORT_CLAIM,
                {},
                {
                    [Params.CLAIM_REPORT_CATEGORY]: event.target.value,
                    [Params.PAYMENT_CONTRACT_ID]: undefined,
                    [Params.CLAIM_LINE_OF_BUSINESS_CLASS]: undefined,
                },
                false,
            ),
        );
    };

    const hasVehicleContracts = useSelector(hasContractOfGivenCategoryId(ClaimReportCategory.VEHICLE.id));
    const hasHomeContracts = useSelector(hasContractOfGivenCategoryId(ClaimReportCategory.HOME_AND_LIABILITY.id));
    const hasTravelContracts = useSelector(hasContractOfGivenCategoryId(ClaimReportCategory.TRAVEL.id));
    const hasLifeContracts = useSelector(hasContractOfGivenCategoryId(ClaimReportCategory.LIFE.id));
    const hasAnyContractForClaimReporting = hasVehicleContracts || hasHomeContracts || hasTravelContracts || hasLifeContracts;

    return (
        <GridLayout defaultSpacing>
            <GridItem md={12}>
                <ContentBox bigger>
                    <Typography component="h2" variant="h2">
                        {t("page.reportClaim.title")}
                    </Typography>
                </ContentBox>

                {!hasAnyContractForClaimReporting && (
                    <ContentBox bigger mt={3}>
                        <Typography color={"red"}>{t("page.reportClaim.noContractsForReporting")}</Typography>
                    </ContentBox>
                )}
                <ImageLabelRadioGroup value={selectedClaimCategoryId} onChange={insuranceEventChange}>
                    <GridLayout spacing={{ xs: 1, md: 4 }}>
                        <GridItem lg={3} md={6} xs={12}>
                            <ImageLabelRadio
                                id={ClaimReportCategory.VEHICLE.id}
                                value={ClaimReportCategory.VEHICLE.id}
                                labelImgSrc={carOption}
                                labelTitle={t("page.reportClaim.vehicle")}
                                disabled={!hasVehicleContracts}
                            />
                        </GridItem>
                        <GridItem lg={3} md={6} xs={12}>
                            <ImageLabelRadio
                                id={ClaimReportCategory.HOME_AND_LIABILITY.id}
                                value={ClaimReportCategory.HOME_AND_LIABILITY.id}
                                labelImgSrc={houseOption}
                                labelTitle={t("page.reportClaim.home")}
                                labelSubtitle={t("page.reportClaim.homeIncludes")}
                                disabled={!hasHomeContracts}
                            />
                        </GridItem>
                        <GridItem lg={3} md={6} xs={12}>
                            <ImageLabelRadio
                                id={ClaimReportCategory.TRAVEL.id}
                                value={ClaimReportCategory.TRAVEL.id}
                                labelImgSrc={travelInsuranceOption}
                                labelTitle={t("page.reportClaim.travel")}
                                disabled={!hasTravelContracts}
                            />
                        </GridItem>
                        <GridItem lg={3} md={6} xs={12}>
                            <ImageLabelRadio
                                id={ClaimReportCategory.LIFE.id}
                                value={ClaimReportCategory.LIFE.id}
                                labelImgSrc={lifeInsuranceOption}
                                labelTitle={t("page.reportClaim.life")}
                                disabled={!hasLifeContracts}
                            />
                        </GridItem>
                    </GridLayout>
                </ImageLabelRadioGroup>
            </GridItem>

            {selectedClaimCategoryId === ClaimReportCategory.VEHICLE.id && <ReportVehicleSection />}
            {selectedClaimCategoryId === ClaimReportCategory.HOME_AND_LIABILITY.id && <ReportHomeSection />}
            {selectedClaimCategoryId === ClaimReportCategory.TRAVEL.id && <ReportTravelSection />}
            {selectedClaimCategoryId === ClaimReportCategory.LIFE.id && <ReportLifeSection />}
        </GridLayout>
    );
};
