import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Typography } from "ui-library/atoms";
import { InfoPanel, LabelTextItem } from "ui-library/modules";
import { Box, Flexbox, GridItem } from "ui-library/layouts";
import { Amount } from "types";

import { localAmountWithCurrency, localAmountWithCurrencyLegacy } from "core/formatters";
import i18n, { TFunction } from "core/i18n";
import { useHasEditContractPermission } from "core/hooks";
import { model, nbsp } from "core/util";
import router from "core/router";
import userContracts from "core/userContracts";
import { EditLinksDropdown } from "containers/EditLinksDropdown";
import { Pages, Tabs } from "routeConstants";

import { canChangeInvestmentProfile, getIsDipContract, getMutualFundsRegularAccountDetail, getPaidFee, getRequiredFee } from "../selectors";

const getYearsStringFromNumber = (yearInNumber: number, t: TFunction) => {
    if (yearInNumber === 1) {
        return `${yearInNumber}${nbsp}${t("common.year")}`;
    }
    if (yearInNumber > 1 && yearInNumber < 5) {
        return `${yearInNumber}${nbsp}${t("common.betweenTwoAndFiveYears")}`;
    }
    if (yearInNumber > 4) {
        return `${yearInNumber}${nbsp}${t("common.moreThanFourYears")}`;
    }
    return "";
};

const getEditLinks = (idObject: number) => [
    {
        link: router.getStaticUrl(Pages.CONTRACT_REGULAR, Tabs.INVESTMENT_PROFILE_FORM, { idObject }),
        name: "investments.investmentProfile",
    },
];

export const ContractParametersGridItem: FC = () => {
    const { t } = i18n.useTranslation();
    const mutualFundsRegularDetail = useSelector(getMutualFundsRegularAccountDetail);
    const locale = useSelector(i18n.getLanguageCode);
    const { idObject } = useParams();

    const isDipContract = useSelector(getIsDipContract);
    const contract = userContracts.useGetContractById(idObject);
    const { isInFutureOrTerminated } = userContracts.useGetContractDerivedData(contract);
    const hasEditPermission: boolean = useHasEditContractPermission();
    const canChangeProfile: boolean =
        hasEditPermission && !isDipContract && !isInFutureOrTerminated && useSelector(canChangeInvestmentProfile);

    const entryFee = useSelector(getRequiredFee);
    const paidFee = useSelector(getPaidFee);
    const toPay: Amount = { value: entryFee.value - paidFee.value, currencyCode: entryFee.currencyCode };

    return (
        <GridItem xs={12} display="flex">
            <InfoPanel
                bgVariant="white"
                icon="contract"
                title={t("investments.contractParameters")}
                headerAction={canChangeProfile && <EditLinksDropdown links={getEditLinks(Number(idObject))} />}
            >
                <Flexbox gap={4}>
                    <Box>
                        <LabelTextItem
                            label={t("investments.monthlyPayment")}
                            text={localAmountWithCurrency(mutualFundsRegularDetail.monthlyPayment)}
                        />
                        <LabelTextItem
                            label={t("investments.investmentProfile")}
                            text={model.getLocaleCodeName(mutualFundsRegularDetail.investmentProfile, locale)}
                        />
                        <LabelTextItem
                            label={t("investments.targetAmount")}
                            text={localAmountWithCurrency(mutualFundsRegularDetail.targetAmount)}
                        />
                        <LabelTextItem
                            label={t("investments.agreedPeriod")}
                            text={getYearsStringFromNumber(mutualFundsRegularDetail.agreedPeriod, t)}
                        />
                        <LabelTextItem
                            label={t("investments.wayOfPaymentOfTheFee")}
                            text={model.getLocaleCodeName(mutualFundsRegularDetail.wayOfPaymentOfTheFee, locale)}
                        />
                    </Box>
                    <Box>
                        <Box mb={1}>
                            <Typography component="p" variant="b">
                                {t("investments.entryFee")}
                            </Typography>
                        </Box>
                        <LabelTextItem label={t("investments.calculatedEntryFee")} text={`${nbsp}${localAmountWithCurrency(entryFee)}`} />
                        <LabelTextItem label={t("investments.paidEntryFee")} text={`${nbsp}${localAmountWithCurrencyLegacy(paidFee)}`} />
                        <LabelTextItem label={t("investments.toPayEntryFee")} text={`${nbsp}${localAmountWithCurrency(toPay)}`} />
                    </Box>
                </Flexbox>
            </InfoPanel>
        </GridItem>
    );
};
