import { call, put, select, takeEvery } from "redux-saga/effects";
import { resetSection } from "redux-form/immutable";

import errorHandling from "core/errorHandling";
import { fieldChangeMatcher, formWrapper } from "core/form";
import userInfo from "core/userInfo";
import { sentry } from "core/util";
import { mutualFundsApi } from "serverApi";
import { MutualFundsClientStatementSettings } from "types";

import {
    RECEIVE_BUY_CONFIRMATION_FIELD,
    RECEIVE_SELL_CONFIRMATION_FIELD,
    RECEIVE_STATEMENT_FIELD,
    STATEMENT_PERIODICITY_FIELD,
} from "./constants";

export function* createSaga(formName, idObject, getStatementSettings, includeBuySellConfirmation = false) {
    try {
        yield call(formSaga(formName, getStatementSettings, includeBuySellConfirmation), idObject);
    } catch (e) {
        sentry.captureException(e);
        yield put(errorHandling.addServiceError(formName, e.identifier));
    }
}

const formSaga = (formName, getStatementSettings, includeBuySellConfirmation) =>
    // @ts-ignore
    formWrapper(formName, {
        *persistentEffects() {
            yield takeEvery(fieldChangeMatcher(formName, RECEIVE_STATEMENT_FIELD), enabledChangedSaga, formName);
        },
        *initialize() {
            yield call(userInfo.checkUserVerified);
            yield put(errorHandling.removeServiceErrors(formName));

            const statementSettings: MutualFundsClientStatementSettings = yield select(getStatementSettings);
            return getInitialValues(statementSettings, includeBuySellConfirmation);
        },
        *save(values, idObject) {
            const requestBody = getRequestBody(values, idObject, includeBuySellConfirmation);
            yield call(mutualFundsApi.statementSettings, requestBody);
        },
    });

function* enabledChangedSaga(formName) {
    yield put(resetSection(formName, `${STATEMENT_PERIODICITY_FIELD}`));
}

function getInitialValues(statementSettings, includeBuySellConfirmation) {
    if (includeBuySellConfirmation) {
        return {
            [RECEIVE_STATEMENT_FIELD]: statementSettings[RECEIVE_STATEMENT_FIELD],
            [STATEMENT_PERIODICITY_FIELD]: statementSettings[STATEMENT_PERIODICITY_FIELD],
            [RECEIVE_BUY_CONFIRMATION_FIELD]: statementSettings[RECEIVE_BUY_CONFIRMATION_FIELD],
            [RECEIVE_SELL_CONFIRMATION_FIELD]: statementSettings[RECEIVE_SELL_CONFIRMATION_FIELD],
        };
    }

    return {
        [RECEIVE_STATEMENT_FIELD]: statementSettings[RECEIVE_STATEMENT_FIELD],
        [STATEMENT_PERIODICITY_FIELD]: statementSettings[STATEMENT_PERIODICITY_FIELD],
    };
}

function getRequestBody(values, idObject, includeBuySellConfirmation) {
    if (includeBuySellConfirmation) {
        return {
            idObject,
            receiveBuyConfirmation: values.get("receiveBuyConfirmation"),
            receiveSellConfirmation: values.get("receiveSellConfirmation"),
            receiveStatement: values.get("receiveStatement"),
            statementPeriodicity: values.get("statementPeriodicity"),
        };
    }

    return {
        idObject,
        receiveStatement: values.get("receiveStatement"),
        statementPeriodicity: values.get("statementPeriodicity"),
    };
}
