import React from "react";
import { useSelector } from "react-redux";

import entity from "core/entity";
import fetching from "core/fetching";
import i18n from "core/i18n";
import { dateTimeToLocalFullDate, formatPercentage, localAmountWithCurrencyLegacy } from "core/formatters";
import { PopoverTooltip, Typography } from "ui-library/atoms";
import { DataTable } from "ui-library/modules";
import { Flexbox, GridItem } from "ui-library/layouts";

import { ENTITY_FINANCIAL_STATEMENT } from "../constants";
import { getPensionDpsFinancialStatementEntity, getPensionDpsStatementData } from "../selectors";

const columns = (t) => [
    {
        field: "investmentsSumInvested",
        headerName: t("pension.statements.investmentsSumInvested"),
        sortable: false,
        flex: 2,
        renderCell: (params) => localAmountWithCurrencyLegacy(params.value),
    },
    {
        field: "investmentsSumNotInvested",
        headerName: t("pension.statements.investmentsSumNotInvested"),
        sortable: false,
        flex: 2,
        renderCell: (params) => localAmountWithCurrencyLegacy(params.value),
    },
    {
        field: "actualInvestmentsValue",
        headerName: t("investments.actualInvestmentsValue"),
        sortable: false,
        flex: 2,
        renderCell: (params) => localAmountWithCurrencyLegacy(params.value),
    },
    {
        field: "valuationPercentage",
        headerName: t("investments.statements.valuationCurrent"),
        sortable: false,
        flex: 2,
        renderCell: (params) => formatPercentage(params.value),
    },
];

export const ActualInvestmentsStatusGridItem = () => {
    const { t } = i18n.useTranslation();
    const isStatementLoading = useSelector(fetching.isFetching(ENTITY_FINANCIAL_STATEMENT, true));
    const isStatementError = useSelector(entity.isError(ENTITY_FINANCIAL_STATEMENT));

    const financialStatement = useSelector(getPensionDpsFinancialStatementEntity);
    const statementDate = dateTimeToLocalFullDate(financialStatement.dateTime);
    const statementData = useSelector(getPensionDpsStatementData);

    return (
        <GridItem xs={12}>
            <DataTable
                icon="document-dollar"
                title={
                    <Flexbox gap={1}>
                        {t("investments.investmentState")}
                        <PopoverTooltip title="" content={t("pension.statements.valuationPercentageFootnoteLong")} />
                    </Flexbox>
                }
                actions={<Typography color="gray">{t("statements.dateTime", { statementDate })}</Typography>}
                disableColumnReorder
                hideFooter={true}
                getRowId={(row) => row.reactKey}
                columns={columns(t)}
                rows={statementData}
                errorMsg={t("error.sectionUnavailable")}
                isLoading={isStatementLoading}
                isError={isStatementError}
            />
        </GridItem>
    );
};
