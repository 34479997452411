import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import { Button } from "ui-library/atoms";
import { Box, GridLayout } from "ui-library/layouts";
import { DataTable } from "ui-library/modules";

import { Tabs } from "routeConstants";
import { formatPercentage, localAmountNoDecimal, localFullDate, twoLineAmountWithCurrencyFormatter } from "core/formatters";
import i18n from "core/i18n";
import router from "core/router";

const getColumns = (t, isRegularContractType) => [
    {
        headerName: t("common.date"),
        field: "date",
        minWidth: 100,
        renderCell: (params) => localFullDate(params.value),
    },
    {
        headerName: t("common.fund"),
        field: "fund",
        minWidth: 190,
    },
    {
        headerName: t("investments.numberOfShares"),
        field: "numberOfShares",
        minWidth: 100,
        renderCell: (params) => localAmountNoDecimal(params.value),
        align: "right",
    },
    {
        headerName: t("investments.purchasePrice"),
        field: "purchasePrice",
        minWidth: 140,
        renderCell: (params) => twoLineAmountWithCurrencyFormatter(params.value),
        headerAlign: "right",
        align: "right",
    },
    {
        headerName: t("investments.actualPrice"),
        field: "actualPrice",
        minWidth: 140,
        renderCell: (params) => twoLineAmountWithCurrencyFormatter(params.value),
        headerAlign: "right",
        align: "right",
    },
    {
        headerName: t("common.profit"),
        field: isRegularContractType ? "nettoProfit" : "profit",
        minWidth: 100,
        renderCell: (params) => formatPercentage(params.value),
        headerAlign: "right",
        align: "right",
    },
];

export const ProfitDetailTable = ({ getProfitDetail, getIdObject, topPage, isRegularContractType }) => {
    const { t } = i18n.useTranslation();
    const data = useSelector(getProfitDetail);
    const idObject = useSelector(getIdObject);
    const navigate = useNavigate();
    return (
        <GridLayout defaultSpacing>
            <DataTable
                icon={"coins"}
                title={t("investments.profit")}
                getRowId={(row) => row.id}
                pageSize={10}
                rowsPerPageOptions={[5, 10, 15, 20]}
                rows={data ? data.toArray() : []}
                rowHeight={60}
                columns={getColumns(t, isRegularContractType)}
                headerHeight={60}
            />
            <Box mt={3}>
                <Button
                    onClick={() => navigate(router.getStaticUrl(topPage, Tabs.PORTFOLIO, { idObject }))}
                    color="green"
                    variant="contained"
                >
                    {t("common.backToPortfolio")}
                </Button>
            </Box>
        </GridLayout>
    );
};

ProfitDetailTable.propTypes = {
    getProfitDetail: PropTypes.func.isRequired,
    getIdObject: PropTypes.func.isRequired,
    topPage: PropTypes.string.isRequired,
    isRegularContractType: PropTypes.bool,
};

ProfitDetailTable.defaultProps = {
    isRegularContractType: false,
};
