export const NAME = "core/entity";

export interface EntityState {
    data: {
        [entityId: string]: any;
    };
    errors: string[];
}

export const initialState: EntityState = {
    data: {},
    errors: [],
};

export type SetDataPayload = {
    entity: string;
    data: any;
};

export type SetDataIntoMapPayload = {
    entity: string;
    id: any;
    data: any;
};
