import React from "react";
import PropTypes from "prop-types";

import { Typography } from "ui-library/atoms";
import { ContentBox } from "ui-library/layouts";

export const SectionDescription = ({ content }) => (
    <ContentBox>
        <Typography component="p" markDown={content} />
    </ContentBox>
);

SectionDescription.propTypes = {
    content: PropTypes.string.isRequired,
};
