import { TFunction } from "core/i18n";
import { IdentityCard } from "types";

const IDENTITY_CARD_ENUM = "OP";
const PASSPORT_ENUM = "CP";

export const getPersonId = (ids: IdentityCard[], t: TFunction) => {
    let label = t("holder.idPassNumber");
    let numberValue = "";
    if (ids) {
        const idCard = ids.filter((item) => item.idTypeCode === IDENTITY_CARD_ENUM)[0];
        const passport = ids.filter((item) => item.idTypeCode === PASSPORT_ENUM)[0];
        if (idCard) {
            label = t("holder.idNumber");
            numberValue = idCard.number;
        } else if (passport) {
            label = t("holder.passNumber");
            numberValue = passport.number;
        }
    }
    return { label, number: numberValue };
};
