import React from "react";

import { Image, Typography, UnorderedList } from "ui-library/atoms";
import { Box, Flexbox, ContentBox } from "ui-library/layouts";
import manImg from "ui-library/assets/images/manNDog.svg";

import i18n from "core/i18n";

export const PaymentAdvice = () => {
    const { t } = i18n.useTranslation();
    return (
        <Flexbox mt={2} direction={{ xs: "column", md: "row" }}>
            <Box>
                <ContentBox>
                    <Typography variant="h3">{t("payments.advice.title")}</Typography>
                </ContentBox>

                <UnorderedList itemsVariant="check">
                    <Typography variant="p">{t("payments.advice.payOnTime")}</Typography>
                    <Typography variant="p">{t("payments.advice.useStandingOrder")}</Typography>
                    <Typography variant="p">{t("payments.advice.useExtraDeposit")}</Typography>
                </UnorderedList>
            </Box>

            <Box>
                <Image src={manImg} alt="man and dog" className="img" shadow={false} />
            </Box>
        </Flexbox>
    );
};
