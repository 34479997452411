import { createSaga } from "./createSaga";
import { createContainer } from "./createContainer";

/**
 * Saga and Container creator for FileUpload Document form.
 */
const uploadClaimDocumentsForm = {
    createSaga,
    createContainer,
};
export default uploadClaimDocumentsForm;
