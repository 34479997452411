import { call, fork, put } from "redux-saga/effects";

import auth from "core/auth";
import router from "core/router";
import { userApi } from "serverApi";
import userInfo from "core/userInfo";
import { fn, sentry } from "core/util";
import { formWrapper, validateWithRecaptcha } from "core/form";

import usernameForm from "form/modal/username";
import passwordForm from "form/modal/passwordChange";
import gdprConsentForm from "form/modal/gdprConsent";
import userVerification from "form/fullPage/userVerification";
import { Tabs } from "routeConstants";

import { ENTITY_CONTRACTS_GDPR_DATA, USER_GDPR_CONSENT_FORM, USER_PASSWORD_FORM, USER_VERIFICATION_FORM, USERNAME_FORM } from "./constants";
import { clear } from "./actions";
import entity from "core/entity";

export default router.routerWrapper({
    *onPageEnter() {
        const authenticatedUser = yield call(userInfo.loadAuthenticatedUser);
        yield call(userInfo.loadGdpr, authenticatedUser);

        yield fork(entity.load, ENTITY_CONTRACTS_GDPR_DATA, () => userApi.getPersonalData());
    },
    *onModalOpen(modalName) {
        switch (modalName) {
            case USER_PASSWORD_FORM:
                yield call(passwordForm.createSaga, USER_PASSWORD_FORM);
                break;
            case USERNAME_FORM:
                yield call(usernameForm.createSaga, USERNAME_FORM);
                break;
            case USER_GDPR_CONSENT_FORM:
                yield call(gdprConsentForm.createSaga, USER_GDPR_CONSENT_FORM);
                break;
            default:
                break;
        }
    },
    *onInnerRouteChange(name) {
        switch (name) {
            case Tabs.USER_VERIFICATION_FORM:
                yield call(userVerification.createSaga, USER_VERIFICATION_FORM);
                break;
            default:
                break;
        }
    },
    clearDataForPage() {
        return [clear()];
    },
});
