import { Record } from "immutable";

import ChangeGroup from "./ChangeGroup";

class Change extends Record({ id: null, msg: null, group: null }) {}

export default Object.freeze({
    HOLDER_TITLE_BEFORE: new Change({
        id: "HOLDER_TITLE_BEFORE",
        msg: "changes.HOLDER_TITLE_BEFORE",
        group: ChangeGroup.HOLDER_GROUP.id,
    }),
    HOLDER_FIRST_NAME: new Change({
        id: "HOLDER_FIRST_NAME",
        msg: "changes.HOLDER_FIRST_NAME",
        group: ChangeGroup.HOLDER_GROUP.id,
    }),
    HOLDER_LAST_NAME: new Change({
        id: "HOLDER_LAST_NAME",
        msg: "changes.HOLDER_LAST_NAME",
        group: ChangeGroup.HOLDER_GROUP.id,
    }),
    HOLDER_TITLE_AFTER: new Change({
        id: "HOLDER_TITLE_AFTER",
        msg: "changes.HOLDER_TITLE_AFTER",
        group: ChangeGroup.HOLDER_GROUP.id,
    }),
    HOLDER_COMPANY_NAME: new Change({
        id: "HOLDER_COMPANY_NAME",
        msg: "changes.HOLDER_COMPANY_NAME",
        group: ChangeGroup.HOLDER_GROUP.id,
    }),
    HOLDER_PERMANENT_ADDRESS: new Change({
        id: "HOLDER_PERMANENT_ADDRESS",
        msg: "changes.HOLDER_PERMANENT_ADDRESS",
        group: ChangeGroup.HOLDER_GROUP.id,
    }),
    HOLDER_COMPANY_ADDRESS: new Change({
        id: "HOLDER_COMPANY_ADDRESS",
        msg: "changes.HOLDER_COMPANY_ADDRESS",
        group: ChangeGroup.HOLDER_GROUP.id,
    }),
    HOLDER_IDENTIFICATION_DOCUMENT: new Change({
        id: "HOLDER_IDENTIFICATION_DOCUMENT",
        msg: "changes.HOLDER_IDENTIFICATION_DOCUMENT",
        group: ChangeGroup.HOLDER_GROUP.id,
    }),
    HOLDER_CORRESPONDEND_ADDRESS: new Change({
        id: "HOLDER_CORRESPONDEND_ADDRESS",
        msg: "changes.HOLDER_CORRESPONDEND_ADDRESS",
    }),
    HOLDER_EMAIL: new Change({
        id: "HOLDER_EMAIL",
        msg: "changes.HOLDER_EMAIL",
    }),
    HOLDER_PHONE: new Change({
        id: "HOLDER_PHONE",
        msg: "changes.HOLDER_PHONE",
    }),
    ELECTRONIC_COMMUNICATION_SET: new Change({
        id: "ELECTRONIC_COMMUNICATION_SET",
        msg: "changes.ELECTRONIC_COMMUNICATION_SET",
    }),
    ELECTRONIC_COMMUNICATION_UNSET: new Change({
        id: "ELECTRONIC_COMMUNICATION_UNSET",
        msg: "changes.ELECTRONIC_COMMUNICATION_UNSET",
    }),

    HOLDER_PAYMENTS_AMOUNT: new Change({
        id: "HOLDER_PAYMENTS_AMOUNT",
        msg: "changes.HOLDER_PAYMENTS_AMOUNT",
        group: ChangeGroup.HOLDER_PAYMENTS_GROUP.id,
    }),
    HOLDER_PAYMENTS_FREQUENCY: new Change({
        id: "HOLDER_PAYMENTS_FREQUENCY",
        msg: "changes.HOLDER_PAYMENTS_FREQUENCY",
        group: ChangeGroup.HOLDER_PAYMENTS_GROUP.id,
    }),
    HOLDER_PAYMENTS_PAYMENT_TYPE: new Change({
        id: "HOLDER_PAYMENTS_PAYMENT_TYPE",
        msg: "changes.HOLDER_PAYMENTS_PAYMENT_TYPE",
        group: ChangeGroup.HOLDER_PAYMENTS_GROUP.id,
    }),

    INSURANCE_PAYMENTS_FREQUENCY: new Change({
        id: "INSURANCE_PAYMENTS_FREQUENCY",
        msg: "changes.INSURANCE_PAYMENTS_FREQUENCY",
        group: ChangeGroup.INSURANCE_PAYMENTS_GROUP.id,
    }),
    INSURANCE_PAYMENTS_PAYMENT_TYPE: new Change({
        id: "INSURANCE_PAYMENTS_PAYMENT_TYPE",
        msg: "changes.INSURANCE_PAYMENTS_PAYMENT_TYPE",
        group: ChangeGroup.INSURANCE_PAYMENTS_GROUP.id,
    }),
    EMPLOYER_PAYMENTS_SET: new Change({
        id: "EMPLOYER_PAYMENTS_SET",
        msg: "changes.EMPLOYER_PAYMENTS_SET",
    }),
    EMPLOYER_PAYMENTS_UNSET: new Change({
        id: "EMPLOYER_PAYMENTS_UNSET",
        msg: "changes.EMPLOYER_PAYMENTS_UNSET",
    }),
    TAX_OPTIMIZATION_SET: new Change({
        id: "TAX_OPTIMIZATION_SET",
        msg: "changes.TAX_OPTIMIZATION_SET",
    }),
    TAX_OPTIMIZATION_UNSET: new Change({
        id: "TAX_OPTIMIZATION_UNSET",
        msg: "changes.TAX_OPTIMIZATION_UNSET",
    }),

    CONTRACT_NAME: new Change({
        id: "CONTRACT_NAME",
        msg: "changes.CONTRACT_NAME",
    }),
    CREATE_ONE_TIME_TRANSACTION: new Change({
        id: "CREATE_ONE_TIME_TRANSACTION",
        msg: "changes.CREATE_ONE_TIME_TRANSACTION",
    }),
    CREATE_PERIODICAL_TRANSACTION: new Change({
        id: "CREATE_PERIODICAL_TRANSACTION",
        msg: "changes.CREATE_PERIODICAL_TRANSACTION",
    }),
    CANCEL_PERIODICAL_TRANSACTION: new Change({
        id: "CANCEL_PERIODICAL_TRANSACTION",
        msg: "changes.CANCEL_PERIODICAL_TRANSACTION",
    }),
    ACTIVE_OPERATIONS_PHONE: new Change({
        id: "ACTIVE_OPERATIONS_PHONE",
        msg: "changes.ACTIVE_OPERATIONS_PHONE",
    }),
    PROFILE: new Change({
        id: "PROFILE",
        msg: "changes.PROFILE",
    }),
    INVESTMENTS_ALLOCATION: new Change({
        id: "INVESTMENTS_ALLOCATION",
        msg: "changes.INVESTMENTS_ALLOCATION",
    }),
    SWITCH_INVESTMENTS: new Change({
        id: "SWITCH_INVESTMENTS",
        msg: "changes.SWITCH_INVESTMENTS",
    }),
    LICENCE_PLATE_NUMBER: new Change({
        id: "LICENCE_PLATE_NUMBER",
        msg: "changes.LICENCE_PLATE_NUMBER",
    }),
    VEHICLE_LICENCE_NUMBER: new Change({
        id: "VEHICLE_LICENCE_NUMBER",
        msg: "changes.VEHICLE_LICENCE_NUMBER",
    }),
    MKT_CONSENT_SET: new Change({
        id: "MKT_CONSENT_SET",
        msg: "changes.MKT_CONSENT_SET",
    }),
    MKT_CONSENT_UNSET: new Change({
        id: "MKT_CONSENT_UNSET",
        msg: "changes.MKT_CONSENT_UNSET",
    }),
    GUARANTEED_FUND_RATIO_REDUCTION_SET: new Change({
        id: "GUARANTEED_FUND_RATIO_REDUCTION_SET",
        msg: "changes.GUARANTEED_FUND_RATIO_REDUCTION_SET",
    }),
    GUARANTEED_FUND_RATIO_REDUCTION_UNSET: new Change({
        id: "GUARANTEED_FUND_RATIO_REDUCTION_UNSET",
        msg: "changes.GUARANTEED_FUND_RATIO_REDUCTION_UNSET",
    }),
    VERIFICATION_REQUEST: new Change({
        id: "VERIFICATION_REQUEST",
        msg: "changes.VERIFICATION_REQUEST",
    }),
    BENEFICIARIES_PP: new Change({
        id: "BENEFICIARIES_PP",
        msg: "changes.BENEFICIARIES_PP",
    }),
    BENEFICIARIES_DPS: new Change({
        id: "BENEFICIARIES_DPS",
        msg: "changes.BENEFICIARIES_DPS",
    }),
    BENEFICIARIES_DDS: new Change({
        id: "BENEFICIARIES_DDS",
        msg: "changes.BENEFICIARIES_DDS",
    }),
    BENEFICIARIES_DSS: new Change({
        id: "BENEFICIARIES_DSS",
        msg: "changes.BENEFICIARIES_DSS",
    }),
    AGENT_CHANGE: new Change({
        id: "AGENT_CHANGE",
        msg: "changes.AGENT_CHANGE",
    }),
    CLIENT_STATEMENT_SETTING: new Change({
        id: "CLIENT_STATEMENT_SETTING",
        msg: "changes.CLIENT_STATEMENT_SETTING",
    }),
    PENSION_STATEMENT_SETTINGS: new Change({
        id: "PENSION_STATEMENT_SETTINGS",
        msg: "changes.PENSION_STATEMENT_SETTINGS",
    }),
    STATEMENTS_PASSWORD: new Change({
        id: "STATEMENTS_PASSWORD",
        msg: "changes.STATEMENTS_PASSWORD",
    }),
    PENSION_INVESTMENT_STRATEGY_SETTING: new Change({
        id: "PENSION_INVESTMENT_STRATEGY_SETTING",
        msg: "changes.PENSION_INVESTMENT_STRATEGY_SETTING",
    }),
    PENSION_PIS_SET: new Change({
        id: "PENSION_PIS_SET",
        msg: "changes.PENSION_PIS_SET",
    }),
    PENSION_PIS_UNSET: new Change({
        id: "PENSION_PIS_UNSET",
        msg: "changes.PENSION_PIS_UNSET",
    }),
});
