import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { ContentBox, Section } from "ui-library/layouts";
import { Typography } from "ui-library/atoms";

import { DatePicker, Field, getFormFieldValue, required, SingleSelect } from "core/form";
import i18n from "core/i18n";
import { MutualFundsPeriodicity } from "enums";
import { date } from "core/util";
import localized from "core/localized";

export const TypeSectionDirect = ({ formName, getFundSellTypeOptions }) => {
    const { t } = i18n.useTranslation();
    const typeFieldSelector = getFormFieldValue(formName, "type");
    const typeFieldValue = useSelector(typeFieldSelector);

    const typeOptions = useSelector(getFundSellTypeOptions);

    const startDateSelector = getFormFieldValue(formName, "startDate");
    const startDateValue = useSelector(startDateSelector);

    const endDateSelector = getFormFieldValue(formName, "endDate");
    const endDateValue = useSelector(endDateSelector);

    const frequencyOptions = useSelector(localized.getLocalizedFrequencyOptions);

    return (
        <Section>
            <ContentBox>
                <Typography variant="h3">{t("investments.sellSwitch.type")}</Typography>
            </ContentBox>
            <ContentBox fullWidth>
                <Field
                    name="type"
                    component={SingleSelect}
                    label={t("investments.sellSwitch.typeLabel")}
                    options={typeOptions}
                    validateStatic={[required]}
                    placeholder={t("investments.sellSwitch.typePlaceholder")}
                />
            </ContentBox>
            {typeFieldValue === MutualFundsPeriodicity.PERIODICAL.id && (
                <>
                    <ContentBox fullWidth>
                        <Field
                            name="startDate"
                            labelMsg="investments.sellSwitch.startDate"
                            component={DatePicker}
                            validateStatic={[required]}
                            minDate={date.today}
                            maxDate={endDateValue ? date.getPreviousDay(endDateValue) : undefined}
                            md={4}
                        />
                    </ContentBox>
                    <ContentBox fullWidth>
                        <Field
                            name="endDate"
                            labelMsg="investments.sellSwitch.endDate"
                            component={DatePicker}
                            minDate={startDateValue ? date.getNextDay(startDateValue) : date.tomorrow}
                            md={4}
                        />
                    </ContentBox>
                    <ContentBox fullWidth>
                        <Field
                            name="frequency"
                            component={SingleSelect}
                            label={t("investments.sellSwitch.frequency")}
                            options={frequencyOptions}
                            validateStatic={[required]}
                            placeholder={t("common.choose")}
                        />
                    </ContentBox>
                </>
            )}
        </Section>
    );
};

TypeSectionDirect.propTypes = {
    formName: PropTypes.string.isRequired,
    getFundSellTypeOptions: PropTypes.func.isRequired,
};
