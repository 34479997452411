import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";

import { PopoverTooltip, Typography } from "ui-library/atoms";
import { ContentBox, Flexbox, GridItem } from "ui-library/layouts";
import { DataTable } from "ui-library/modules";

import entity from "core/entity";
import fetching from "core/fetching";
import { localFullDate, localAmountInvestmentWithCurrency, negativeFormatter, localAmountInvestment } from "core/formatters";
import i18n from "core/i18n";
import { DEFAULT_TABLE_PAGE_SIZE, DEFAULT_TABLE_ROWS_OPTIONS } from "app/constants";
import { PensionDpsFinancialOperation } from "model/pension/dps";
import { amountWithOperationTypeOperators } from "modules/tableFilter";

import { VALUE_DATE_COLUMN, INVESTMENT_FUND_NAME_COLUMN, GROSS_AMOUNT_COLUMN, TYPE_COLUMN } from "../constants";
import { FinancialOperationsTableFilter } from "../modules";

const columns = (t) => [
    {
        field: VALUE_DATE_COLUMN,
        type: "date",
        headerName: t("investments.investmentDate"),
        flex: 2,
        renderCell: (params) => localFullDate(params.value),
    },
    {
        field: INVESTMENT_FUND_NAME_COLUMN,
        headerName: t("common.fund"),
        flex: 2,
        minWidth: 150,
    },
    {
        field: GROSS_AMOUNT_COLUMN,
        filterOperators: amountWithOperationTypeOperators,
        align: "right",
        headerAlign: "right",
        headerName: t("investments.investmentBuyoutAmount"),
        sortable: false,
        renderCell: (params) => negativeFormatter(params.value),
        flex: 2,
        minWidth: 130,
    },
    {
        field: "pricePerShareAtOperationDateTime",
        align: "right",
        headerAlign: "right",
        headerName: t("investments.sellSwitch.buyoutSharePrice"),
        sortable: false,
        renderCell: (params) => localAmountInvestmentWithCurrency(params.value),
        flex: 2,
        minWidth: 110,
    },
    {
        field: "numberOfShares",
        headerName: t("pension.numberOfShares"),
        align: "right",
        headerAlign: "right",
        renderCell: (params) => localAmountInvestment(params.value),
        flex: 2,
        minWidth: 130,
    },
    {
        field: "actualPricePerShare",
        headerName: t("pension.actualPricePerShare"),
        align: "right",
        headerAlign: "right",
        sortable: false,
        renderCell: (params) => localAmountInvestmentWithCurrency(params.value),
        flex: 2,
        minWidth: 110,
    },
    {
        field: TYPE_COLUMN,
        headerName: t("common.actionType"),
        align: "right",
        headerAlign: "right",
        sortable: false,
        flex: 2,
    },
];

export const FinancialOperationsGridItem = ({ entityName, financialOperations }) => {
    const { t } = i18n.useTranslation();
    const isFinOperationsLoading = useSelector(fetching.isFetching(entityName, true));
    const isFinOperationsError = useSelector(entity.isError(entityName));
    const filterModel = useSelector(FinancialOperationsTableFilter.getFilterModel);

    return (
        <GridItem xs={12}>
            <DataTable
                icon={"chart-bar"}
                title={
                    <Flexbox gap={1}>
                        {t("payments.overview")}
                        <PopoverTooltip title="" content={t("pension.statements.dateTimeFootnote")} />
                    </Flexbox>
                }
                preContent={
                    <ContentBox>
                        <Typography fontSize={14}>{t("common.financialOperationsNotice")}</Typography>
                    </ContentBox>
                }
                actions={<FinancialOperationsTableFilter.Container />}
                headerHeight={82}
                disableColumnReorder
                pageSize={DEFAULT_TABLE_PAGE_SIZE}
                rowsPerPageOptions={DEFAULT_TABLE_ROWS_OPTIONS}
                getRowId={(row) => row.id}
                rows={financialOperations.toArray()}
                columns={columns(t)}
                emptyMsg={t("common.noData")}
                errorMsg={t("error.sectionUnavailable")}
                isLoading={isFinOperationsLoading}
                isError={isFinOperationsError}
                filterModel={filterModel}
            />
        </GridItem>
    );
};

FinancialOperationsGridItem.propTypes = {
    entityName: PropTypes.string.isRequired,
    financialOperations: ImmutablePropTypes.listOf(PensionDpsFinancialOperation).isRequired,
};
