import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";

import { Tab, TabPanel, Tabs, Typography } from "ui-library/atoms";
import { StyledPaymentMethodWrapper } from "ui-library/Styled";

import i18n from "core/i18n";
import { PaymentMethod } from "enums";

import { setPaymentMethod } from "../actions";
import { getAmountObj, getPaymentMethod, hasDataAndCanPayOnline } from "../selectors";
import { TransferMethod } from "./TransferMethod";
import OnlinePayment from "./OnlinePayment";

export const PaymentBox = () => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();
    const paymentMethodTabId = useSelector(getPaymentMethod);
    const canPayOnline = useSelector(hasDataAndCanPayOnline);
    const amountObj = useSelector(getAmountObj);
    const isAmountValueMoreThanOne = amountObj && amountObj.get("value") ? amountObj.get("value") > 1 : false;

    return (
        <Box mt={5}>
            <StyledPaymentMethodWrapper>
                <Box mb={3}>
                    <Typography component="h3" variant="h3">
                        {t("payments.method")}
                    </Typography>
                </Box>
                <Tabs value={paymentMethodTabId} onChange={(event, newValue) => dispatch(setPaymentMethod(newValue))}>
                    <Tab label={t("payments.meansGiro")} />
                    {canPayOnline && isAmountValueMoreThanOne && <Tab label={t("payments.meansOnline")} />}
                </Tabs>
                <TabPanel value={paymentMethodTabId} index={PaymentMethod.TRANSFER.tabId}>
                    <TransferMethod />
                </TabPanel>

                {canPayOnline && isAmountValueMoreThanOne && (
                    <TabPanel value={paymentMethodTabId} index={PaymentMethod.ONLINE_PAYMENT.tabId}>
                        <OnlinePayment />
                    </TabPanel>
                )}
            </StyledPaymentMethodWrapper>
        </Box>
    );
};
