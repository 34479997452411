import { model } from "core/util";

export const NonLifeVinculationAndLease = model.createModelLegacy("NonLifeVinculationAndLease", (json) => ({
    leasingNumber: json.LeasingNumber,
    status: json.Status,
    subjectName: json.SubjectName,
    subjectRegistrationNumber: json.SubjectRegistrationNumber,
    validTo: json.ValidTo,
    // only for uniqa contracts; 0 = no, 1 = yes
    isExists: json.IsExists,
}));
