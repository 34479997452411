import { createSelector } from "reselect";
import { List } from "immutable";

import i18n from "core/i18n";
import pageContext from "core/pageContext";
import { Countries } from "enums";

const isInvestingEnabled = (fundCode, siteId, notAllowedFundsCz, notAllowedFundsSk) =>
    (siteId === Countries.CZ.id && !notAllowedFundsCz.includes(fundCode)) ||
    (siteId === Countries.SK.id && !notAllowedFundsSk.includes(fundCode));

export const createGetFundsForInvestment = (listOfFundsForInvestment) =>
    createSelector(
        i18n.getLanguageCode,
        pageContext.getSiteId,
        pageContext.getNotAllowedTargetFundCodesCz,
        pageContext.getNotAllowedTargetFundCodesSk,
        (languageCode, siteId, notAllowedFundsCz, notAllowedFundsSk) =>
            listOfFundsForInvestment
                ? listOfFundsForInvestment.filter((fund) =>
                      isInvestingEnabled(fund.fund.code, siteId, notAllowedFundsCz, notAllowedFundsSk),
                  )
                : List(),
    );

export const createGetFundsOptionsForInvestment = (listOfFundsOptionsForInvestment, fundCodeAttributeName) =>
    createSelector(
        i18n.getLanguageCode,
        pageContext.getSiteId,
        pageContext.getNotAllowedTargetFundCodesCz,
        pageContext.getNotAllowedTargetFundCodesSk,
        (languageCode, siteId, notAllowedFundsCz, notAllowedFundsSk) =>
            listOfFundsOptionsForInvestment
                ? listOfFundsOptionsForInvestment.filter((option) =>
                      isInvestingEnabled(option.get(fundCodeAttributeName), siteId, notAllowedFundsCz, notAllowedFundsSk),
                  )
                : List(),
    );
