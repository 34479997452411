import { call, put } from "redux-saga/effects";

import { sentry } from "core/util";
import { userApi } from "serverApi";

import { setUserAccount } from "./actions";

export function* loadUserAccount() {
    yield call(loadUserAccountData);
}

function* loadUserAccountData() {
    try {
        const userAccount = yield call(userApi.getAccountInformation);
        yield put(setUserAccount(userAccount));
    } catch (e) {
        sentry.captureException(e);
    }
}
