import { model } from "core/util";
import { UseCaseChangeDataResponse as UseCaseChangeDataResponseType } from "types";

import { SubjectObject } from "../subject";
import { UniqaIncidentDetail } from "../uniqaClaims";

export const UseCaseChangeDataResponse = model.createModel<UseCaseChangeDataResponseType, UseCaseChangeDataResponseType>((json) => ({
    useCaseUserType: json.useCaseUserType,
    contractData: SubjectObject.fromServer(json.contractData),
    incident: UniqaIncidentDetail.fromServer(json.incident),
}));
