import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { ModalBody, ModalFooter } from "ui-library/modules";
import { Box } from "ui-library/layouts";
import { Button, Loading, Typography } from "ui-library/atoms";

import i18n from "core/i18n";
import { ModalFormLayout, ModalChangeResultSuccess } from "containers/modal";
import { form } from "core/form";
import { getPisFormChangeResult } from "app/pages/detailDss/selectors";

const PisConfirmationForm = ({ canEnable, handleSubmit, submitting }) => {
    const { t } = i18n.useTranslation();
    return (
        <>
            <ModalBody>
                <Loading loading={submitting}>
                    <Box>
                        <Typography>
                            {canEnable ? t("pension.pis.enableConfirmationQuestion") : t("pension.pis.disableConfirmationQuestion")}
                        </Typography>
                    </Box>
                </Loading>
            </ModalBody>
            <ModalFooter>
                <Button color="blue" variant="contained" onClick={handleSubmit} disabled={submitting}>
                    {t("common.confirm")}
                </Button>
            </ModalFooter>
        </>
    );
};

PisConfirmationForm.propTypes = {
    canSubmit: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    canEnable: PropTypes.bool.isRequired,
};

export const createContainer = (formName, getDisplayEnableButton, getPisFormChangeResult) =>
    form(formName)((props) => {
        const { t } = i18n.useTranslation();
        const canEnable = useSelector(getDisplayEnableButton);
        const changeResult = useSelector(getPisFormChangeResult);
        const SuccessComponent = ({ closeModal }) => <ModalChangeResultSuccess closeModal={closeModal} changeResult={changeResult} />;

        return (
            <ModalFormLayout
                formName={formName}
                formTitle={t("form.pisConfirmation")}
                formIcon="contract"
                SuccessComponent={SuccessComponent}
            >
                <PisConfirmationForm canEnable={canEnable} {...props} />
            </ModalFormLayout>
        );
    });
