import PropTypes from "prop-types";
import { BubbleAccordion, Container, Typography } from "ui-library/atoms";
import { RolldownLayout } from "ui-library/layouts";

export const FAQ = ({ title, data }) => {
    return (
        <>
            <Container sx={{ marginBottom: "32px" }}>
                <Typography variant={"h2"}>{title}</Typography>
            </Container>

            <RolldownLayout>
                <Container>
                    <BubbleAccordion data={data} id="faqAccordion" />
                </Container>
            </RolldownLayout>
        </>
    );
};

FAQ.propTypes = {
    data: PropTypes.array.isRequired,
};
