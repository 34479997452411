import { createSelector } from "reselect";

import i18n from "core/i18n";
import { MutualFundsStatementFrequencySetting } from "enums";

const frequencyComparator = (freq1, freq2) => parseInt(freq1.get("value")) - parseInt(freq2.get("value"));

export const getLocalizedStatementFrequencyOptions = createSelector(
    i18n.createGetLocalizedEnumOptions(MutualFundsStatementFrequencySetting),
    (options) => options.sort(frequencyComparator),
);
