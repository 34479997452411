import React, { FC } from "react";
import { useSelector } from "react-redux";

import { ContentBox, GridItem } from "ui-library/layouts";
import { DataTable, InfoPanel } from "ui-library/modules";

import i18n, { TFunction } from "core/i18n";
import { formatCodeValueFundLink, formatPercentageNoDecimal, localAmountInvestmentWithCurrencyEur } from "core/formatters";
import { DdsFunds } from "enums";

import { getFundsInvestmentStrategies } from "../selectors";

const columns = (t: TFunction, languageCode: string) => [
    {
        field: "investmentFund",
        headerName: t("common.fund"),
        flex: 1.5,
        sortable: false,
        renderCell: (params) => formatCodeValueFundLink(t, languageCode, DdsFunds, params.value),
    },
    {
        field: "pricePerShare",
        headerName: t("pension.dds.actualPricePerShare"),
        flex: 2,
        sortable: false,
        align: "right",
        headerAlign: "right",
        valueGetter: (params) => localAmountInvestmentWithCurrencyEur(params.value),
    },
    {
        field: "investmentPercentage",
        headerName: t("pension.investmentStrategies.percentage"),
        flex: 1,
        sortable: false,
        align: "right",
        headerAlign: "right",
        valueGetter: (params) => formatPercentageNoDecimal(params.value),
    },
];

export const PensionInvestmentStrategiesGridItem: FC = () => {
    const { t } = i18n.useTranslation();
    const languageCode = i18n.getLanguageCode();
    const fundsInvestmentStrategies = useSelector(getFundsInvestmentStrategies);

    return (
        <GridItem xs={12}>
            <InfoPanel icon="chart-column" title={t("pension.investmentStrategies.title")}>
                <ContentBox bigger>
                    <DataTable
                        disableColumnReorder
                        withWrapper={false}
                        hideFooter={true}
                        getRowId={(row) => row.reactKey}
                        columns={columns(t, languageCode)}
                        rows={fundsInvestmentStrategies}
                    />
                </ContentBox>
            </InfoPanel>
        </GridItem>
    );
};
