import React, { FC } from "react";

import { Icon, Typography } from "ui-library/atoms";
import { StyledFileInfo } from "ui-library/Styled";
import { Flexbox, FlexboxProps } from "ui-library/layouts/Flexbox/Flexbox";

export interface FileInfoProps extends FlexboxProps {
    icon?: string;
    fileName?: string;
    fileSize?: string;
    fileDate?: string;
    tableVariant?: boolean;
    fullWidth?: boolean;
    disabled?: boolean;
}

export const FileInfo: FC<FileInfoProps> = ({ icon, fileName, fileDate, fileSize, tableVariant, fullWidth, disabled, ...props }) => {
    return (
        <StyledFileInfo
            justifyContent={fullWidth ? "space-between" : "flex-start"}
            $tableVariant={tableVariant}
            $disabled={disabled}
            alignItems={{ xs: "flex-start", md: "center" }}
            gap={{ xs: 0.5, md: 2 }}
            flexDirection={{ xs: "column", md: "row" }}
            {...props}
        >
            <Flexbox gap={2} alignItems={"center"}>
                {icon && <Icon size={16} color={disabled ? "gray" : "blue"} icon={icon} />}
                {fileName && <Typography color={disabled ? "gray" : "blue"}>{fileName}</Typography>}
            </Flexbox>

            <Flexbox gap={{ xs: 1, md: 4 }} alignItems={"center"} pl={{ xs: 4, md: 0 }}>
                {fileDate && (
                    <Typography variant="caption" color={"gray"}>
                        {fileDate}
                    </Typography>
                )}
                {fileSize && (
                    <Typography variant="caption" color={"gray"}>
                        {fileSize}
                    </Typography>
                )}
            </Flexbox>
        </StyledFileInfo>
    );
};
