import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { List } from "immutable";

import { Container, Typography } from "ui-library/atoms";
import { ContentBox } from "ui-library/layouts";
import { FormBlock } from "ui-library/modules";

import i18n from "core/i18n";
import { FormErrorBox, getFormFieldValue } from "core/form";
import { localAmountNoDecimal } from "core/formatters";
import { fn } from "core/util";
import { RedeemPensionDpsRedeemTypes, RedeemPensionPpRedeemTypes } from "enums";

import { PAYMENT_AMOUNT_VIRTUAL_FIELD, PENSION_TYPE_FIELD, REDEEM_TYPE_FIELD } from "../../constants";
import { StepTwoButtonsBox } from "../../components";

import { ClientContractDataSection, SectionEarlyPaymentOrOneTimeSettlement, SectionPension } from "../recapitulation";

const earlyPaymentOrOneTimeSettlementTypes = List.of(
    RedeemPensionDpsRedeemTypes.DPS_RETIREMENT_PENSION_ONE_TIME_SETTLEMENT,
    RedeemPensionDpsRedeemTypes.DPS_EARLY_PAYMENT_ONE_TIME_SETTLEMENT,
    RedeemPensionPpRedeemTypes.PP_EARLY_PAYMENT_ONE_TIME_SETTLEMENT,
    RedeemPensionPpRedeemTypes.PP_SERVICE_PENSION_ONE_TIME_SETTLEMENT,
    RedeemPensionPpRedeemTypes.PP_RETIREMENT_PENSION_ONE_TIME_SETTLEMENT,
);

export const StepTwoForm = ({ formName, canSubmit, handleSubmit, idObject, policyHolder, pensionTypesEnum, redeemTypesEnum }) => {
    const { t } = i18n.useTranslation();

    // Form values
    const pensionTypeFormValue = useSelector(getFormFieldValue(formName, PENSION_TYPE_FIELD));
    const pensionType = fn.getEnumItemById(pensionTypesEnum, pensionTypeFormValue);
    const redeemTypeFormValue = useSelector(getFormFieldValue(formName, REDEEM_TYPE_FIELD));
    const redeemType = fn.getEnumItemById(redeemTypesEnum, redeemTypeFormValue);

    const isEarlyPaymentOrOneTimeSettlement = earlyPaymentOrOneTimeSettlementTypes.includes(redeemType);

    const paymentAmountValue = useSelector(getFormFieldValue(formName, PAYMENT_AMOUNT_VIRTUAL_FIELD));
    const paymentAmount = `${localAmountNoDecimal(paymentAmountValue)} Kč`;

    return (
        <Container maxWidth="md">
            {/* Form Errors */}
            <FormErrorBox form={formName} />

            {/* Main Form Container */}
            <FormBlock mb={4}>
                {/* Title */}
                <ContentBox>
                    <Typography variant="h3">{t("form.pensionRedeemSavings.recapitulation.title")}</Typography>
                </ContentBox>

                {/* Client and contract general data */}
                <ClientContractDataSection idObject={idObject} policyHolder={policyHolder} />

                {/* Redeem recapitulation - early payment or one time settlement */}
                {isEarlyPaymentOrOneTimeSettlement && (
                    <SectionEarlyPaymentOrOneTimeSettlement formName={formName} redeemType={redeemType} paymentAmount={paymentAmount} />
                )}

                {/* Redeem recapitulation - all others */}
                {!isEarlyPaymentOrOneTimeSettlement && (
                    <SectionPension formName={formName} pensionType={pensionType} redeemType={redeemType} paymentAmount={paymentAmount} />
                )}

                {/* Authorization Disclaimer */}
                <ContentBox mt={4}>
                    <Typography markDown={t("form.pensionRedeemSavings.recapitulation.authorizationDisclaimer")} />
                </ContentBox>

                <StepTwoButtonsBox formName={formName} canSubmit={canSubmit} handleSubmit={handleSubmit} />
            </FormBlock>
        </Container>
    );
};

StepTwoForm.propTypes = {
    formName: PropTypes.string.isRequired,
    canSubmit: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    idObject: PropTypes.any.isRequired,
    policyHolder: PropTypes.object.isRequired,
    pensionTypesEnum: PropTypes.object.isRequired,
    redeemTypesEnum: PropTypes.object.isRequired,
};
