import { useNavigate } from "react-router-dom";

import { Button } from "ui-library/atoms";

import { Pages, Tabs } from "routeConstants";
import i18n from "core/i18n";
import router from "core/router";

export const ReportClaimButtonOld = ({ isOutlined }) => {
    const { t } = i18n.useTranslation();
    const navigate = useNavigate();
    return (
        <Button
            color="blue"
            variant={isOutlined ? "outlined" : "contained"}
            onClick={() => navigate(router.getStaticUrl(Pages.CLAIMS, Tabs.REPORT_CLAIM_OLD))}
        >
            {t("claim.report")}
        </Button>
    );
};
