import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Button, Typography } from "ui-library/atoms";
import { ContentBox } from "ui-library/layouts";
import { InfoPanel } from "ui-library/modules";

import i18n from "core/i18n";
import router from "core/router";
import userInfo from "core/userInfo";
import { useHasEditContractPermission } from "core/hooks";
import { Pages, Tabs } from "routeConstants";

export const UnverifiedUserPanel: FC = () => {
    const { t } = i18n.useTranslation();
    const navigate = useNavigate();

    const hasEditPermission = useHasEditContractPermission();
    const requestDate = useSelector(userInfo.getVerificationRequestDate);
    const isVerificationInProgress = !!requestDate;

    return (
        <InfoPanel
            bgVariant={isVerificationInProgress ? "blueGradient" : "redGradient"}
            icon={"alert"}
            title={isVerificationInProgress ? t("holder.list.verificationInProgressTitle") : t("holder.unverifiedTitle")}
            actionButton={
                hasEditPermission &&
                !isVerificationInProgress && (
                    <Button variant={"outlined"} color={"red"} onClick={() => navigate(router.getStaticUrl(Pages.USER, Tabs.VERIFICATION))}>
                        {t("common.verifyMyself")}
                    </Button>
                )
            }
        >
            <ContentBox>
                <Typography color={"gray"} variant={"p"}>
                    {isVerificationInProgress ? t("holder.list.verificationInProgress") : t("holder.verificationRequired")}
                </Typography>
            </ContentBox>
        </InfoPanel>
    );
};
