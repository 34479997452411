import { withTheme } from "@mui/styles";
import styled from "styled-components";
import { Typography } from "@mui/material";

// ak nezadavam tak sa podedi z temy
export const StyledTypography = withTheme(styled(Typography)`
    font-size: ${(props) => (props.fontSize ? props.fontSize + "px" : "")};
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "")};
    line-height: ${(props) => (props.lineHeight ? props.lineHeight + "px" : "")};

    color: ${(props) => (props.color ? props.theme.palette.colors[props.color] : "inherit")};

    strong,
    b {
        font-weight: 700 !important;
    }
`);
