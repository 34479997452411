import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { Section } from "ui-library/layouts";
import { Container, Typography } from "ui-library/atoms";
import { Step, Stepper } from "ui-library/modules";
import useBreakpoints from "ui-library/theme/breakpoints";

import router from "core/router";
import { form, FormErrorBox, getFormFieldValue } from "core/form";
import errorHandling, { OTP_WRONG_MOBILE_NUMBER_FIN_OPERATIONS } from "core/errorHandling";
import i18n from "core/i18n";
import { FullPageOtpFormLayout } from "containers/fullPageForm";

import { CANCEL_FIELD_ID } from "./constants";
import { InnerContainerDirect } from "./containers";
import { BackToFinancialStatementButton } from "../fullPage/pensionFundsChange/components";

const MutualFundsSellSwitchDirectForm = ({
    handleSubmit,
    submitting,
    OtpModule,
    formName,
    canSubmit,
    getTargetFundCodes,
    getSourceFundCodes,
    createGetSourceFundOptions,
    createGetTargetFundOptions,
    getFundSellTypeOptions,
    getFundSellTargetOptions,
    getLocalizedFundSellMeansDirect,
    getShouldCheckIbanCountry,
}) => {
    const { t } = i18n.useTranslation();

    const isOtpVisibleSelector = OtpModule.createIsVisibleSelector(formName);
    const isOtpVisible = useSelector(isOtpVisibleSelector);

    const otpWrongNumberErrorExists = useSelector(errorHandling.isServiceInErrorState(OTP_WRONG_MOBILE_NUMBER_FIN_OPERATIONS));
    const isCancellingSelector = getFormFieldValue(formName, CANCEL_FIELD_ID);
    const isCancelling = !!useSelector(isCancellingSelector);

    const sellSwitchMeans = useSelector(getFormFieldValue(formName, "means"));

    const dispatch = useDispatch();
    const onOtpBack = () => dispatch(OtpModule.clear(formName));
    const onBack = () => dispatch(router.goBack());

    const isMDBreak = useBreakpoints("md");

    const InnerContainerDirectProps = {
        handleSubmit,
        submitting,
        isOtpVisible,
        otpWrongNumberErrorExists,
        onOtpBack,
        isCancelling,
        OtpModule,
        formName,
        canSubmit,
        onBack,
        getTargetFundCodes,
        getSourceFundCodes,
        createGetSourceFundOptions,
        createGetTargetFundOptions,
        getFundSellTypeOptions: getFundSellTypeOptions(sellSwitchMeans),
        getFundSellTargetOptions,
        getLocalizedFundSellMeansDirect,
        getShouldCheckIbanCountry,
    };
    return (
        <Container maxWidth="md">
            <Section>
                <Typography variant="lead">{t("investments.sellSwitch.description")}</Typography>
            </Section>

            <Section>
                <Stepper displayAsCircle={!isMDBreak} activeStep={isOtpVisible ? 1 : 0}>
                    <Step label={"Krok 1"} />
                    <Step label={"Krok 2"} />
                </Stepper>
            </Section>

            <Container maxWidth="md">
                <FormErrorBox form={formName} />

                <InnerContainerDirect {...InnerContainerDirectProps} />
            </Container>
        </Container>
    );
};

MutualFundsSellSwitchDirectForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    OtpModule: PropTypes.object.isRequired,
    formName: PropTypes.string.isRequired,
    canSubmit: PropTypes.bool.isRequired,
    getTargetFundCodes: PropTypes.func.isRequired,
    getSourceFundCodes: PropTypes.func.isRequired,
    createGetSourceFundOptions: PropTypes.func.isRequired,
    createGetTargetFundOptions: PropTypes.func.isRequired,
    getFundSellTypeOptions: PropTypes.func.isRequired,
    getFundSellTargetOptions: PropTypes.func.isRequired,
    getLocalizedFundSellMeansDirect: PropTypes.func.isRequired,
    getShouldCheckIbanCountry: PropTypes.func.isRequired,
};

export default (
    formName,
    OtpModule,
    getTargetFundCodes,
    getSourceFundCodes,
    createGetSourceFundOptions,
    createGetTargetFundOptions,
    getFundSellTypeOptions,
    getFundSellTargetOptions,
    getLocalizedFundSellMeansDirect,
    idObjectSelector,
    getShouldCheckIbanCountry,
    slowServices,
) =>
    form(
        formName,
        {},
    )((props) => {
        const { t } = i18n.useTranslation();
        const idObject = useSelector(idObjectSelector);
        return (
            <FullPageOtpFormLayout
                formName={formName}
                formTitle={t("investments.sellSwitch.formTitle")}
                BackButton={<BackToFinancialStatementButton idObject={idObject} />}
            >
                <MutualFundsSellSwitchDirectForm
                    {...props}
                    formName={formName}
                    OtpModule={OtpModule}
                    getTargetFundCodes={getTargetFundCodes}
                    getSourceFundCodes={getSourceFundCodes}
                    createGetSourceFundOptions={createGetSourceFundOptions}
                    createGetTargetFundOptions={createGetTargetFundOptions}
                    getFundSellTypeOptions={getFundSellTypeOptions}
                    getFundSellTargetOptions={getFundSellTargetOptions}
                    getLocalizedFundSellMeansDirect={getLocalizedFundSellMeansDirect}
                    getShouldCheckIbanCountry={getShouldCheckIbanCountry}
                    slowServices={slowServices}
                />
            </FullPageOtpFormLayout>
        );
    });
