import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
    AgentOverviewGridItem,
    ContractDocumentsOverviewGridItem,
    HolderOverviewGridItem,
    PensionFinancialOperationsOverviewGridItem,
} from "containers/contract";
import router from "core/router";
import userContracts from "core/userContracts";

import { IndividualPersonType, PensionTypeEnum } from "enums";
import { Pages, Tabs } from "routeConstants";
import { GridLayout } from "ui-library/layouts";

import { ContractOverviewGridItem, PensionTypesGridItem } from "../containers";
import { ENTITY_CONTRACT_DOCUMENTS, ENTITY_FINANCIAL_OPERATIONS, ENTITY_PAYMENT_INFORMATION } from "../constants";
import { getPolicyHolder } from "../selectors";

export const OverviewTab: FC = () => {
    const { idObject } = useParams();
    const contract = userContracts.useGetContractById(idObject);
    const { isInFutureOrTerminated } = userContracts.useGetContractDerivedData(contract);
    const holder = useSelector(getPolicyHolder);

    return (
        <GridLayout defaultSpacing>
            <PensionFinancialOperationsOverviewGridItem
                idObject={Number(idObject)}
                pensionType={PensionTypeEnum.PP}
                financialOperationsEntityName={ENTITY_FINANCIAL_OPERATIONS}
                paymentDataEntityName={ENTITY_PAYMENT_INFORMATION}
                detailLink={router.getStaticUrl(Pages.CONTRACT_PP, Tabs.FINANCIAL_OPERATIONS, { idObject })}
                hasQuickPayment={!isInFutureOrTerminated}
            />

            <ContractOverviewGridItem policy={contract} detailLink={router.getStaticUrl(Pages.CONTRACT_PP, Tabs.DETAIL, { idObject })} />

            <HolderOverviewGridItem
                holder={holder}
                individualPersonType={IndividualPersonType.PARTICIPANT}
                detailLink={router.getStaticUrl(Pages.CONTRACT_PP, Tabs.HOLDER, { idObject })}
            />

            <ContractDocumentsOverviewGridItem
                idObject={Number(idObject)}
                entityName={ENTITY_CONTRACT_DOCUMENTS}
                detailLink={router.getStaticUrl(Pages.CONTRACT_PP, Tabs.DOCUMENTS, { idObject })}
            />

            <PensionTypesGridItem mdSize={5} />

            <AgentOverviewGridItem
                idObject={Number(idObject)}
                detailLink={router.getStaticUrl(Pages.CONTRACT_PP, Tabs.AGENT, { idObject })}
            />
        </GridLayout>
    );
};
