import { fetch } from "core/util";
import { API_PREFIX } from "app/constants";
import { Message, Request } from "model/messages";

const getMessages = () => fetch.doGet(`${API_PREFIX}/messages/announcements`, null, Message.fromServerList);
const setMessageRead = (id) => fetch.doPut(`${API_PREFIX}/messages/announcement-read/${id}`);
const sendMessage = (values) =>
    fetch.doPost(`${API_PREFIX}/messages/send-message`, {
        message: values.get("message"),
        email: values.get("email"),
        files: values.get("files"),
    });

const getRequests = () => fetch.doGet(`${API_PREFIX}/requests`, null, Request.fromServerList);

export default {
    getMessages,
    setMessageRead,
    getRequests,
    sendMessage,
};
