import { useDispatch, useSelector } from "react-redux";

import { Option, Select } from "ui-library/atoms";

import i18n from "core/i18n";
import router from "core/router";
import { Pages, Params, Tabs } from "routeConstants";
import { ClaimsLineOfBusinessClass } from "enums";

import { getSelectedClaimCategoryId, getSelectedContractId, getSelectedLineOfBusinessClass } from "../selectors";

export const HomeLineOfBusinessSelect = ({ compliantHomeClaimClasses }) => {
    const { t } = i18n.useTranslation();
    const selectedClaimReportCategory = useSelector(getSelectedClaimCategoryId);
    const selectedContractId = useSelector(getSelectedContractId);
    const selectedProductGroupClass = useSelector(getSelectedLineOfBusinessClass);
    const dispatch = useDispatch();

    const doTransition = (lineOfBusinessClass) =>
        router.navigate(
            Pages.CLAIMS,
            Tabs.REPORT_CLAIM,
            {},
            {
                [Params.CLAIM_LINE_OF_BUSINESS_CLASS]: lineOfBusinessClass,
                [Params.CLAIM_REPORT_CATEGORY]: selectedClaimReportCategory,
                [Params.PAYMENT_CONTRACT_ID]: selectedContractId,
            },
            false,
        );

    return (
        <Select
            value={selectedProductGroupClass || ""}
            onChange={(event) => dispatch(doTransition(event.target.value))}
            placeholder={t("page.reportClaim.lineOfBusinessSelectPlaceholder")}
            label={t("common.insuranceType")}
            id="selectOther"
            fullWidth
        >
            {compliantHomeClaimClasses.map((category) => {
                return (
                    <Option value={category} key={category}>
                        {t(ClaimsLineOfBusinessClass[category].text)}
                    </Option>
                );
            })}
        </Select>
    );
};
