import { Record } from "immutable";

class ProductPaymentAmountName extends Record({ id: null, amountMsg: null, debtAmountMsg: null }) {}

export default Object.freeze({
    HOME_AMOUNT: new ProductPaymentAmountName({
        id: "HOME",
        amountMsg: "payments.home.advancedSchedulerAmount",
        debtAmountMsg: "payments.home.debtAmount",
    }),
    BUSINESS_AMOUNT: new ProductPaymentAmountName({
        id: "BUSINESS",
        amountMsg: "payments.home.advancedSchedulerAmount",
        debtAmountMsg: "payments.home.debtAmount",
    }),
    VEHICLE_AMOUNT: new ProductPaymentAmountName({
        id: "VEHICLE",
        amountMsg: "payments.vehicle.advancedSchedulerAmount",
        debtAmountMsg: "payments.vehicle.debtAmount",
    }),
    LIFE_AMOUNT: new ProductPaymentAmountName({
        id: "LIFE",
        amountMsg: "payments.life.advancedSchedulerAmount",
        debtAmountMsg: "payments.life.debtAmount",
    }),
    TRAVEL_AMOUNT: new ProductPaymentAmountName({
        id: "TRAVEL",
        amountMsg: "payments.life.advancedSchedulerAmount",
        debtAmountMsg: "payments.life.debtAmount",
    }),
    PENSION_AMOUNT: new ProductPaymentAmountName({
        id: "PENSION",
        amountMsg: "payments.pension.contribution",
    }),
    MUTUAL_FUNDS_AMOUNT: new ProductPaymentAmountName({
        id: "MUTUAL_FUNDS",
        amountMsg: "payments.mutualFunds.contribution",
    }),
});
