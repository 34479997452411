import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { List } from "immutable";

import auth from "core/auth";
import i18n from "core/i18n";
import fetching from "core/fetching";
import { formWrapper, validateWithRecaptcha } from "core/form";
import { fetch, fn, sentry } from "core/util";
import { authApi } from "serverApi";

import { EPIN_PHASE_ISSUE, EPIN_PHASE_PASSWORD, EPIN_PHASE_VERIFICATION } from "./constants";

const OTP_WRONG_MOBILE_ERROR_CODE = "SEC_SMS_004";

/**
 * @param formConstants
 * @param actions
 * @param selectors
 */
export default (formConstants, actions, selectors) => {
    const issueEpinFormSaga = formWrapper(formConstants.FORM_EPIN_ISSUE, {
        *initialize() {
            return {};
        },
        *save(values) {
            yield put(actions.setEpinIssueError(null));

            const recaptchaValue = yield call(validateWithRecaptcha, "resetpassword");
            if (fn.isEmpty(recaptchaValue) && process.env.RECAPTCHA_REQUIRED) {
                yield call(processRecaptchaFailedError);
            } else {
                const { requestId, phone } = yield call(authApi.resetPasswordStepOneGetOneTimeCode, {
                    contractNumber: values.get("contractNumber"),
                    recaptchaResponse: recaptchaValue,
                    birthNumberOrCompanyId: values.get("birthNumberOrCompanyId"),
                });

                return {
                    contract: values.get("contractNumber"),
                    phone,
                    requestId,
                };
            }
        },
        *success({ contract, phone, requestId }) {
            // to set the form to the initial state
            yield put(actions.setDuplicateContractNumber(null));
            yield put(actions.setEpinPhase(EPIN_PHASE_PASSWORD, { contract, phone, requestId }));
        },
        // Not sure how to handle this error
        /* eslint-disable-next-line consistent-return */
        *error(error, values) {
            if (error.status === fetch.PRECONDITION_FAILED) {
                if (error.response.errorCode) {
                    switch (error.response.errorCode) {
                        case "MED1": {
                            const errorText = i18n.translateDirectly("error.epin.contractDontExists");
                            yield put(actions.setEpinIssueError(errorText));
                            break;
                        }
                        case "WRONG_COMBINATION_OF_IDENTIFIERS": {
                            const errorText = i18n.translateDirectly("error.epin.wrongCombinationOfContractNumberAndClientId");
                            yield put(actions.setEpinIssueError(errorText));
                            break;
                        }
                        case "MED2": {
                            yield put(actions.setDuplicateContractNumber(values.get("contractNumber")));
                            break;
                        }
                        case "MED3": {
                            const errorText = i18n.translateDirectly("error.epin.phone.textNoNumber");
                            yield put(actions.setEpinIssueError(errorText));
                            break;
                        }
                        case "MED4":
                        default:
                            const errorText = i18n.translateDirectly("error.epin.phone.textUnableToSendPin");
                            yield put(actions.setEpinIssueError(errorText));
                            break;
                    }
                }
            } else if (error.status === fetch.TOO_MANY_REQUESTS) {
                const errorText = i18n.translateDirectly("error.tooManyRequests");
                yield put(actions.setEpinIssueError(errorText));
            }
        },
    });

    const epinPasswordFormSaga = formWrapper(formConstants.FORM_EPIN_PASSWORD, {
        *initialize() {
            yield put(actions.setEpinPasswordError(null));
            return {};
        },
        *save(values) {
            const data = yield select(selectors.getEpinData);
            const contract = data.get("contract");
            const requestId = data.get("requestId");
            const phone = data.get("phone");
            const newPassword = values.get("newPassword");
            const verificationPassword = values.get("verificationPassword");
            try {
                const recaptchaValue = yield call(validateWithRecaptcha, "resetpassword");
                if (fn.isEmpty(recaptchaValue) && process.env.RECAPTCHA_REQUIRED) {
                    yield call(processRecaptchaFailedError);
                } else {
                    yield call(authApi.resetPasswordStepTwoSetNewPassword, {
                        requestId,
                        newPassword,
                        verificationPassword,
                        recaptchaResponse: recaptchaValue,
                    });

                    return {
                        requestId,
                        phone,
                        contract,
                    };
                }
            } catch (error) {
                if (error.status === fetch.BAD_REQUEST && error.response.otpErrorCode === OTP_WRONG_MOBILE_ERROR_CODE) {
                    const errorText = i18n.translateDirectly("error.otp.wrongPhoneNumber");
                    yield put(actions.setEpinPasswordError(errorText));
                    throw new fetch.EmptyPreconditionFailedError();
                } else if (error.status === fetch.TOO_MANY_REQUESTS) {
                    const errorText = i18n.translateDirectly("error.tooManyRequests");
                    yield put(actions.setEpinPasswordError(errorText));
                } else {
                    throw error;
                }
            }
        },
        *success(data) {
            if (data) {
                yield put(actions.setEpinPhase(EPIN_PHASE_VERIFICATION, data));
            }
        },
    });

    const epinVerificationFormSaga = formWrapper(formConstants.FORM_EPIN_VERIFICATION, {
        *initialize() {
            yield put(actions.setEpinVerificationError(null));
            const data = yield select(selectors.getEpinData);
            const password = data.get("password");
            return {
                newPassword: password,
                verificationPassword: password,
            };
        },
        *save(values) {
            const recaptchaValue = yield call(validateWithRecaptcha, "resetpassword");
            if (fn.isEmpty(recaptchaValue) && process.env.RECAPTCHA_REQUIRED) {
                yield call(processRecaptchaFailedError);
            } else {
                const data = yield select(selectors.getEpinData);
                const requestId = data.get("requestId");
                const otpCode = values.get("otpCode");
                yield call(authApi.resetPasswordStepThreeSubmit, {
                    requestId,
                    otpCode,
                    recaptchaResponse: recaptchaValue,
                });
            }
        },
        *success() {
            yield put(auth.logIn());
            yield call(fn.block); // to avoind visual blink of page (loading will stop), we will block until redirected
        },
        *error(error) {
            if (error.status === fetch.PRECONDITION_FAILED) {
                if (error.response.errors && error.response.errors.length > 0) {
                    yield put(actions.setEpinVerificationError(error.response.errors));
                }
            } else if (error.status === fetch.TOO_MANY_REQUESTS) {
                const errorText = i18n.translateDirectly("error.tooManyRequests");
                yield put(actions.setEpinVerificationError(List.of(errorText)));
            }
        },
    });

    return function* () {
        yield takeEvery(actions.SET_EPIN_PHASE, phaseChangeSaga);
        yield takeLatest(actions.SET_RESEND_PIN, resendSaga);
    };

    function* phaseChangeSaga() {
        const phase = yield select(selectors.getEpinPhase);
        switch (phase) {
            case EPIN_PHASE_ISSUE:
                yield call(issueEpinFormSaga);
                break;
            case EPIN_PHASE_PASSWORD:
                yield call(epinPasswordFormSaga);
                break;
            case EPIN_PHASE_VERIFICATION:
                yield call(epinVerificationFormSaga);
                break;
            default:
                break;
        }
    }

    function* resendSaga() {
        try {
            const recaptchaValue = yield call(validateWithRecaptcha, "resetpassword");
            if (fn.isEmpty(recaptchaValue) && process.env.RECAPTCHA_REQUIRED) {
                yield call(processRecaptchaFailedError);
            } else {
                yield put(fetching.setFetching(actions.SET_RESEND_PIN, true));
                const data = yield select(selectors.getEpinData);
                const requestId = data.get("requestId");

                yield call(authApi.resetPasswordStepThreeNewPin, {
                    requestId,
                    recaptchaResponse: recaptchaValue,
                });
            }
        } catch (error) {
            if (error.status === fetch.TOO_MANY_REQUESTS) {
                const errorText = i18n.translateDirectly("error.tooManyRequests");
                yield put(actions.setEpinIssueError(errorText));
            }
            const data = yield select(selectors.getEpinData);
            const contract = data.get("contract");
            yield put(actions.setEpinPhase(EPIN_PHASE_ISSUE, { contract }));
        } finally {
            yield put(fetching.setFetching(actions.SET_RESEND_PIN, false));
        }
    }

    function* processRecaptchaFailedError() {
        yield put(actions.setEpinIssueError(auth.UnsuccessfulReason.ERROR_MISSING_RECAPTCHA));
        sentry.captureException(new Error("Google recaptcha missing"));
    }
};
