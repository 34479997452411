import React from "react";
import PropTypes from "prop-types";

import router from "core/router";
import i18n from "core/i18n";
import { Pages, Tabs } from "routeConstants";

import { ContentBox } from "ui-library/layouts";
import { Typography } from "ui-library/atoms";

export const OtpWrongNumberDds = ({ idObject }) => {
    const { t } = i18n.useTranslation();

    const editPolicyHolderLink = router.getStaticUrl(Pages.CONTRACT_DDS, Tabs.EDIT_POLICY_HOLDER_FORM, { idObject });
    const agentTabLink = router.getStaticUrl(Pages.CONTRACT_DDS, Tabs.AGENT, { idObject });

    return (
        <>
            <ContentBox>
                <Typography variant="p" color="red" markDown={t("error.otp.wrongPhoneNumberFinOperations.partOne")} />
            </ContentBox>

            <ContentBox mb={4}>
                <Typography
                    variant="p"
                    color="red"
                    markDown={t("error.otp.wrongPhoneNumberFinOperations.partTwo", { editPolicyHolderLink, agentTabLink })}
                    internalAppLink
                />
            </ContentBox>
        </>
    );
};

OtpWrongNumberDds.propTypes = {
    idObject: PropTypes.number.isRequired,
};
