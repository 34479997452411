import { Box } from "@mui/material";
import { useState } from "react";
import { Button } from "ui-library/atoms";
import { StyledRolldownContainer, StyledRolldownWrapper } from "ui-library/Styled";

export const RolldownLayout = ({ children, ...props }) => {
    const [roll, setRoll] = useState(false);

    const handleClick = () => {
        setRoll(!roll);
    };

    return (
        <StyledRolldownWrapper {...props}>
            <StyledRolldownContainer $roll={roll}>
                <Box className="rolldown-content">{children}</Box>
            </StyledRolldownContainer>
            <Box className="rolldown-icon">
                <Button onClick={handleClick} color="blue" startIcon={roll ? "chevron-up" : "chevron-down"} />
            </Box>
        </StyledRolldownWrapper>
    );
};
