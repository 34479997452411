import { combineReducers } from "redux-immutable";

import { app } from "core/util";

import { CLEAR, SET_DISCLAIMER_STEP } from "./actions";
import { STEP_DISCLAIMER_TEXT } from "./constants";

export default combineReducers({
    appState: combineReducers({
        disclaimerStep: app.createDataReducer(SET_DISCLAIMER_STEP, STEP_DISCLAIMER_TEXT, CLEAR),
    }),
});
