import { useSelector } from "react-redux";

import { Button, Image, Typography } from "ui-library/atoms";
import { Box, ContentBox, Flexbox, GridItem, GridLayout } from "ui-library/layouts";
import { PromoBlock, YoutubeEmbed } from "ui-library/modules";
import patalieImg from "ui-library/assets/images/pataliaImg.svg";

import i18n from "core/i18n";
import pageContext from "core/pageContext";
import { useGetSiteValue } from "core/hooks";
import { ExternalUrl } from "enums";

export const PromoBlocks = () => {
    const { t } = i18n.useTranslation();
    const youtubePromoId = useSelector(pageContext.getYoutubePromoId);
    const getSiteValue = useGetSiteValue();

    return (
        <>
            <PromoBlock>
                <GridLayout alignItems={"center"} spacing={{ sm: 3, md: 5, lg: 8 }}>
                    <GridItem xs={12} md={6}>
                        <Box mb={1}>
                            <Typography variant="h2">{t("page.contact.promoBlocks.one.title")}</Typography>
                        </Box>

                        <ContentBox>
                            <Typography variant="perex" color={"gray"}>
                                {t("page.contact.promoBlocks.one.description")}
                            </Typography>
                        </ContentBox>

                        <Flexbox direction={{ xs: "column", md: "row" }} mb={4} gap={2}>
                            <Button
                                variant="outlined"
                                color="blue"
                                startIcon={"social-youtube"}
                                newTab
                                externalLink={getSiteValue(ExternalUrl.YOUTUBE)}
                            >
                                Youtube
                            </Button>

                            <Button
                                variant="outlined"
                                color="blue"
                                startIcon={"social-facebook"}
                                newTab
                                externalLink={getSiteValue(ExternalUrl.FACEBOOK)}
                            >
                                Facebook
                            </Button>
                        </Flexbox>
                    </GridItem>

                    <GridItem display={"flex"} justifyContent="center" xs={12} sm={12} md={6}>
                        <YoutubeEmbed maxWidth={560} embedId={youtubePromoId} />
                    </GridItem>
                </GridLayout>
            </PromoBlock>

            <PromoBlock variant={"blueSoft"}>
                <GridLayout alignItems={"center"} spacing={{ sm: 3, md: 5, lg: 8 }}>
                    {/* We used order, to change order when using mobile version, picture is always on the bottom */}
                    <GridItem order={{ xs: 1, md: 0 }} display={"flex"} justifyContent="center" item md={6} xs={12}>
                        <Box>
                            <Image src={patalieImg} alt={"Patalia obrazok"} />
                        </Box>
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <Box mb={1}>
                            <Typography variant="h2">{t("page.contact.promoBlocks.two.title")}</Typography>
                        </Box>

                        <ContentBox>
                            <Typography variant="perex" color={"gray"}>
                                {t("page.contact.promoBlocks.two.description")}
                            </Typography>
                        </ContentBox>

                        <ContentBox>
                            <Button
                                color="blue"
                                startIcon={"link"}
                                variant="outlined"
                                newTab
                                externalLink={getSiteValue(ExternalUrl.PATALIE)}
                            >
                                {getSiteValue(ExternalUrl.PATALIE_TITLE)}
                            </Button>
                        </ContentBox>
                    </GridItem>
                </GridLayout>
            </PromoBlock>
        </>
    );
};
