import * as React from "react";

import { Box } from "@mui/material";
import { Image, Typography } from "ui-library/atoms";
import maestro from "ui-library/assets/images/payment-cards/maestro.svg";
import mastercardSC from "ui-library/assets/images/payment-cards/mastercard-securecode.svg";
import mastercard from "ui-library/assets/images/payment-cards/mastercard.svg";
import verifiedByVisa from "ui-library/assets/images/payment-cards/verified-by-visa.svg";
import visa2 from "ui-library/assets/images/payment-cards/visa-2.svg";
import visa from "ui-library/assets/images/payment-cards/visa.svg";

import unicreditbank from "ui-library/assets/images/banks/unicredit.svg";
import postovabanka from "ui-library/assets/images/banks/postovabanka.svg";
import slsp from "ui-library/assets/images/banks/slsp.svg";
import tatrabanka from "ui-library/assets/images/banks/tatrabanka.svg";
import vub from "ui-library/assets/images/banks/vubbanka.svg";

import p24 from "ui-library/assets/images/banks/p24.svg";
import raiff from "ui-library/assets/images/banks/raiff.svg";
import kb from "ui-library/assets/images/banks/kb.svg";
import csob from "ui-library/assets/images/banks/csob.svg";
import fio from "ui-library/assets/images/banks/fio.svg";
import mbank from "ui-library/assets/images/banks/mbank.svg";

import { StyledPaymentMethodCardsWrapper, StyledPaymentMethodBanksWrapper } from "ui-library/Styled";

import i18n from "core/i18n";
import { Locales } from "enums";

const paymentCards = [visa, visa2, mastercard, maestro, verifiedByVisa, mastercardSC];

const skBankImages = [unicreditbank, postovabanka, slsp, tatrabanka, vub];
const csBankImages = [p24, raiff, kb, csob, fio, mbank];

const internetBankingImages = {
    [Locales.cs_CZ.languageCodeUpperCase]: csBankImages,
    [Locales.sk_SK.languageCodeUpperCase]: skBankImages,
};

export const AvailableBanksAndCards = () => {
    const { t } = i18n.useTranslation();
    const languageCode = i18n.getLanguageCode();
    return (
        <>
            <Box pb={0.75}>
                <Typography variant="caption" color="gray" component="p">
                    {t("payments.meansCard")}
                </Typography>
            </Box>
            <StyledPaymentMethodCardsWrapper>
                {paymentCards.map((card) => (
                    <Image alt={card} src={card} shadow={false} />
                ))}
            </StyledPaymentMethodCardsWrapper>
            <Box pb={0.75} pt={2}>
                <Typography variant="caption" color="gray" component="p">
                    {t("payments.meansIB")}
                </Typography>
            </Box>
            <StyledPaymentMethodBanksWrapper>
                {internetBankingImages[languageCode].map((img) => (
                    <Image alt={img} src={img} shadow={false} />
                ))}
                <Box mt={1}>
                    <Typography variant="h3">{t("payments.andOther")}</Typography>
                </Box>
            </StyledPaymentMethodBanksWrapper>
        </>
    );
};
