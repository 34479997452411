import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { Block, Typography } from "ui-library/atoms";
import { StyledError } from "ui-library/Styled";
import { Icon } from "ui-library/atoms/Icons/Icon";

import { fn } from "core/util";

export const NotApplicableRedeemsSection = ({ notApplicableRedeemsSelector }) => {
    const notApplicableRedeems = useSelector(notApplicableRedeemsSelector);

    if (fn.isArrayEmpty(notApplicableRedeems)) {
        return null;
    }

    return (
        <Block mb={2} shadow={false} variant="grayWhite">
            {notApplicableRedeems.map((notApplicableRedeem) => (
                <StyledError key={notApplicableRedeem.typeCode}>
                    <Icon sx={{ width: "25px" }} size={16} icon={"circle-info"} color="blue" />
                    &nbsp;&nbsp;
                    <Typography component="i">{`${notApplicableRedeem.description} ${notApplicableRedeem.notApplicableReason}`}</Typography>
                </StyledError>
            ))}
        </Block>
    );
};

NotApplicableRedeemsSection.propTypes = {
    notApplicableRedeemsSelector: PropTypes.func.isRequired,
};
