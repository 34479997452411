import { FundSellTarget } from "enums";

const isFilterIncomplete = (filterItem) => !filterItem.columnField || !filterItem.value || !filterItem.operatorValue;
const isDebitOperation = (operationType) => operationType === FundSellTarget.SELL.id || operationType === FundSellTarget.SELL.type;
export const replaceCommaForDot = (value) => (value ? value.replace(/,/g, ".") : value);

// amount is a Map of currency type and value, so we need special operators for it
export const amountOperators = [
    {
        value: "amountLess",
        getApplyFilterFn: (filterItem) => {
            if (isFilterIncomplete(filterItem)) {
                return null;
            }

            return (params) => {
                const { value } = params;

                return Number(value.get("value")) < Number(replaceCommaForDot(filterItem.value));
            };
        },
    },
    {
        value: "amountMore",
        getApplyFilterFn: (filterItem) => {
            if (isFilterIncomplete(filterItem)) {
                return null;
            }

            return (params) => {
                const { value } = params;

                return Number(value.get("value")) > Number(replaceCommaForDot(filterItem.value));
            };
        },
    },
    {
        value: "amountEqual",
        getApplyFilterFn: (filterItem) => {
            if (isFilterIncomplete(filterItem)) {
                return null;
            }

            return (params) => {
                const { value } = params;

                return Number(value.get("value")) === Number(replaceCommaForDot(filterItem.value));
            };
        },
    },
];

export const amountWithOperationTypeOperators = [
    {
        value: "amountWithOperationTypeLess",
        getApplyFilterFn: (filterItem) => {
            if (isFilterIncomplete(filterItem)) {
                return null;
            }

            return (params) => {
                const { value } = params;
                const amount = value.get("amount");
                const operationType = value.get("operationType");

                if (isDebitOperation(operationType)) {
                    return -Math.abs(Number(amount.get("value"))) < Number(replaceCommaForDot(filterItem.value));
                } else {
                    return Number(amount.get("value")) < Number(replaceCommaForDot(filterItem.value));
                }
            };
        },
    },
    {
        value: "amountWithOperationTypeMore",
        getApplyFilterFn: (filterItem) => {
            if (isFilterIncomplete(filterItem)) {
                return null;
            }

            return (params) => {
                const { value } = params;
                const amount = value.get("amount");
                const operationType = value.get("operationType");

                if (isDebitOperation(operationType)) {
                    return -Math.abs(Number(amount.get("value"))) > Number(replaceCommaForDot(filterItem.value));
                } else {
                    return Number(amount.get("value")) > Number(replaceCommaForDot(filterItem.value));
                }
            };
        },
    },
    {
        value: "amountWithOperationTypeEqual",
        getApplyFilterFn: (filterItem) => {
            if (isFilterIncomplete(filterItem)) {
                return null;
            }

            return (params) => {
                const { value } = params;
                const amount = value.get("amount");
                const operationType = value.get("operationType");

                if (isDebitOperation(operationType)) {
                    return -Math.abs(Number(amount.get("value"))) === Number(replaceCommaForDot(filterItem.value));
                } else {
                    return Number(amount.get("value")) === Number(replaceCommaForDot(filterItem.value));
                }
            };
        },
    },
];

export const codeValueOperators = [
    {
        value: "codeValueEquals",
        getApplyFilterFn: (filterItem) => {
            if (isFilterIncomplete(filterItem)) {
                return null;
            }

            return (params) => {
                const { value } = params;

                return value.code === filterItem.value;
            };
        },
    },
];
