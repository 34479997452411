import createModel from "../createModel";
import { ChangeProposition } from "./ChangeProposition";

export const ContractChangeProposition = createModel("ContractChangeProposition", (json) => ({
    id: json.Id,
    contractNumber: json.ContractNumber,
    status: json.Status,
    agentId: json.AgentId,
    isActiveAdvisor: json.IsActiveAdvisor,
    creationDateTime: json.CreationDateTime,
    lastChangeDateTime: json.LastChangeDateTime,
    changeProposition: ChangeProposition.fromServer(json.ChangeProposition),
}));
