import { Record } from "immutable";
import RedeemPensionDpsPensionTypes from "./RedeemPensionDpsPensionTypes";

const RedeemPensionDpsRedeemTypes = Record({ id: null, msg: null, description: null, pensionType: null, type: null, code: null });

export default Object.freeze({
    // PRE_RETIREMENT
    DPS_PRE_RETIREMENT: new RedeemPensionDpsRedeemTypes({
        id: "DPS_PRE_RETIREMENT",
        msg: "pension.redeemType.preRetirement",
        description: "pension.description.preRetirement",
        pensionType: RedeemPensionDpsPensionTypes.PRE_RETIREMENT,
        type: "PENSION",
        code: "151P",
    }),

    // RETIREMENT_PENSION
    DPS_RETIREMENT_PENSION_ONE_TIME_SETTLEMENT: new RedeemPensionDpsRedeemTypes({
        id: "DPS_RETIREMENT_PENSION_ONE_TIME_SETTLEMENT",
        msg: "pension.redeemType.oneTimeSettlement",
        description: "pension.description.dps.retirementPension.oneTimeSettlement",
        pensionType: RedeemPensionDpsPensionTypes.RETIREMENT_PENSION,
        type: "ONE_TIME_PAYMENT",
        code: "511",
    }),
    DPS_RETIREMENT_PENSION_REGULAR_PENSION: new RedeemPensionDpsRedeemTypes({
        id: "DPS_RETIREMENT_PENSION_REGULAR_PENSION",
        msg: "pension.redeemType.regularPension",
        description: "pension.description.dps.retirementPension.regularPension",
        pensionType: RedeemPensionDpsPensionTypes.RETIREMENT_PENSION,
        type: "PENSION",
        code: "151",
    }),

    // EARLY_PAYMENT
    DPS_EARLY_PAYMENT_ONE_TIME_SETTLEMENT: new RedeemPensionDpsRedeemTypes({
        id: "DPS_EARLY_PAYMENT_ONE_TIME_SETTLEMENT",
        msg: "pension.redeemType.earlyPayment",
        description: "pension.description.earlyPayment.oneTimeSettlement",
        pensionType: RedeemPensionDpsPensionTypes.EARLY_PAYMENT,
        type: "TERMINATION",
        code: "1",
    }),
});
