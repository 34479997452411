import React from "react";
import PropTypes from "prop-types";

import { Box } from "ui-library/layouts";
import { Typography } from "ui-library/atoms";
import { LabelTextItem } from "ui-library/modules";

import i18n from "core/i18n";
import { MutualFundsProfileChange } from "model/changePropositions";

export const MutualFundsProfileChangeDetail = ({ mutualFundsProfileChange }) => {
    const { t } = i18n.useTranslation();
    return (
        <>
            <Box mb={1}>
                <Typography component="p" variant="b">
                    {t("changeProposition.transactionDetail")}
                </Typography>
            </Box>
            <Box>
                <LabelTextItem fontVariant="caption" label={t("common.newProfile")} text={mutualFundsProfileChange.targetProfile} />
            </Box>
        </>
    );
};

MutualFundsProfileChangeDetail.propTypes = {
    mutualFundsProfileChange: PropTypes.instanceOf(MutualFundsProfileChange).isRequired,
};
