import { API_PREFIX } from "app/constants";
import { fetch } from "core/util";
import { ClaimsEvents } from "model/claim";
import { HarmonizedContract, HarmonizedFinancialOperation } from "model/harmonized";

const productUriPrefix = `${API_PREFIX}/product/home`;
const getHomeContractDetail = (idObject) =>
    fetch.doGet(`${productUriPrefix}/${idObject}/contract-detail`, null, HarmonizedContract.fromServer);
const getHomeFinancialOperations = (idObject) =>
    fetch.doGet(`${productUriPrefix}/${idObject}/financial-operations`, null, HarmonizedFinancialOperation.fromServerList);
const getHomeIncidents = (idObject) => fetch.doGet(`${productUriPrefix}/${idObject}/claims`, null, ClaimsEvents.fromServer);

export default {
    getHomeContractDetail,
    getHomeFinancialOperations,
    getHomeIncidents,
};
