import { withTheme } from "@mui/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import styled from "styled-components";

export const StyledAccordion = withTheme(styled(Accordion)`
    && {
        background: transparent;
        margin-bottom: 0;
        border-bottom: 1px solid ${(props) => props.theme.palette.gray.line};
        border-radius: 0;
        &:before {
            display: none;
        }
        &.Mui-expanded {
            margin: 0;
        }
        &:hover {
            border-bottom: 1px solid ${(props) => props.theme.palette.blue.dark};
        }
        &:last-child {
            border-bottom: none;
        }
    }
`);

export const StyledAccordionSummary = withTheme(styled(AccordionSummary)`
    flex-direction: row-reverse;
    color: ${(props) => props.theme.palette.blue.main};

    padding: 0;
    transition: all ease 200ms;
    @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
        align-items: center;
    }
    &:hover,
    &:focus,
    &:active {
        color: ${(props) => props.theme.palette.blue.dark};
    }
    .MuiAccordionSummary-content {
        align-items: center;
        margin: 0;
        padding: 15px 0;

        @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
            padding: 8px 0;
        }
    }
    &.Mui-expanded {
        border-bottom: 3px solid ${(props) => props.theme.palette.blue.main};
        transition: all ease 200ms;
        min-height: unset;
        span,
        p {
            font-weight: 700;
        }
        font-weight: 700;
    }
    svg {
        margin: 0 18px;
        @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
            margin: 0 9px;
        }
    }
    &.title-with-action {
        justify-content: flex-end;
        .MuiAccordionSummary-content {
            max-width: calc(100% - 100px);
        }
    }
    &.header-compact {
        .MuiAccordionSummary-content {
            padding: 0;
        }
    }
`);

export const StyledAccordionDetails = withTheme(styled(AccordionDetails)`
    padding: 24px 24px 24px 48px;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        padding: 16px 16px 16px 20px;
    }
`);
