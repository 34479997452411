import React, { FC, PropsWithChildren } from "react";

import { StyledSectionBanner, StyledSectionContainer } from "ui-library/Styled";
import { Box } from "ui-library/layouts";
import { Typography } from "ui-library/atoms";
import useBreakpoints from "ui-library/theme/breakpoints";
import { BoxProps } from "ui-library/layouts/Box/Box";

export interface SectionBannerProps extends PropsWithChildren, BoxProps {
    title?: string;
    description?: string;
    imageUrl?: string;
}

export const SectionBanner: FC<SectionBannerProps> = ({ title, description, children, imageUrl, ...props }) => {
    const isMdBreak = useBreakpoints("md");

    return (
        <StyledSectionBanner {...props}>
            {imageUrl && <Box className="banner-img" sx={{ backgroundImage: "url(" + imageUrl + ")" }} />}
            <StyledSectionContainer>
                <Box width={{ xs: "100%", md: "60%" }}>
                    {title && (
                        <Box mb={1}>
                            <Typography variant="h1">{title}</Typography>
                        </Box>
                    )}
                    {description && (
                        <Box mb={4}>
                            <Typography sx={{ whiteSpace: "pre-line" }} variant={isMdBreak ? "lead" : "perex"} markDown={description} />
                        </Box>
                    )}
                    {children && <Box pb={!isMdBreak && imageUrl ? 40 : 0}>{children}</Box>}
                </Box>
            </StyledSectionContainer>
        </StyledSectionBanner>
    );
};
