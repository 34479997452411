import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { arrayRemove, unregisterField } from "redux-form/immutable";
import { nanoid, Selector } from "@reduxjs/toolkit";
import { fromJS, List, Map } from "immutable";

import userInfo from "core/userInfo";
import { FORM_STEPS_BACK } from "app/constants";
import { formWrapper, getFormFieldValue, scrollToFirstError } from "core/form";
import { model, sentry } from "core/util";
import { AddressType } from "enums";
import errorHandling from "core/errorHandling";
import { pensionDpsApi } from "serverApi";
import { PensionBeneficiary, State } from "types";

import { REMOVE_BENEFICIARY } from "./actions";
import { BENEFICIARIES_FIELD } from "./constants";
import { pensionBeneficiaryShareFormatter } from "./util";

const parseBeneficiary = (beneficiary) => ({
    reactKey: nanoid(),
    firstName: beneficiary.getIn(["beneficiaryEntity", "firstName"]),
    lastName: beneficiary.getIn(["beneficiaryEntity", "lastName"]),
    birthNumber: beneficiary.getIn(["beneficiaryEntity", "birthNumber"]),
    share: pensionBeneficiaryShareFormatter(beneficiary.get("beneficiaryShare")),
    address: model.getPlainObjAddressByTypeCode(beneficiary.getIn(["beneficiaryEntity", "addresses"]), AddressType.PER),
});

export function* createSagaDps(formName: string, getBeneficiaries: Selector<State, PensionBeneficiary[]>, idObject: number) {
    try {
        yield call(formSaga(formName, getBeneficiaries), idObject);
    } catch (e) {
        sentry.captureException(e);
        yield put(errorHandling.addServiceError(formName, e.identifier));
    }
}

const formSaga = (formName: string, getBeneficiaries: Selector<State, PensionBeneficiary[]>) =>
    formWrapper(formName, {
        *persistentEffects() {
            // @ts-ignore we won't type sagas
            yield takeEvery(REMOVE_BENEFICIARY, removeBeneficiarySaga, formName);
            // yield takeEvery(autocomplete.selectMatcher(ADDRESS_AUTOCOMPLETE), placeSelectedSaga, formName);
        },
        *initialize(idObject) {
            yield call(userInfo.checkUserVerified, FORM_STEPS_BACK);
            yield put(errorHandling.removeServiceErrors(formName));

            const beneficiaries: PensionBeneficiary[] = yield select(getBeneficiaries);
            if (beneficiaries) {
                const beneficiariesForInit = beneficiaries.length === 0 ? List().push(Map()) : fromJS(beneficiaries);
                return {
                    idObject,
                    beneficiaries: beneficiariesForInit.map(parseBeneficiary).toJS(),
                };
            }
            return {};
        },
        *save(values) {
            const data = values.update("beneficiaries", (list) => list.map((beneficiary) => beneficiary.delete("reactKey")));
            yield call(pensionDpsApi.updateBeneficiaries, data);
        },
        // @ts-ignore we won't type sagas
        *onSubmitFail() {
            yield call(scrollToFirstError);
        },
    });

function* removeBeneficiarySaga(formName, { meta }) {
    const beneficiaries = yield select(getFormFieldValue(formName, BENEFICIARIES_FIELD));
    const lastIndex = beneficiaries.count() - 1;

    yield put(arrayRemove(formName, BENEFICIARIES_FIELD, meta.index));
    yield all([
        yield put(unregisterField(formName, `${BENEFICIARIES_FIELD}[${lastIndex}].firstName`)),
        yield put(unregisterField(formName, `${BENEFICIARIES_FIELD}[${lastIndex}].lastName`)),
        yield put(unregisterField(formName, `${BENEFICIARIES_FIELD}[${lastIndex}].birthNumber`)),
        yield put(unregisterField(formName, `${BENEFICIARIES_FIELD}[${lastIndex}].share`)),
        yield put(unregisterField(formName, `${BENEFICIARIES_FIELD}[${lastIndex}].address.street`)),
        yield put(unregisterField(formName, `${BENEFICIARIES_FIELD}[${lastIndex}].address.descriptionNumber`)),
        yield put(unregisterField(formName, `${BENEFICIARIES_FIELD}[${lastIndex}].address.orientationNumber`)),
        yield put(unregisterField(formName, `${BENEFICIARIES_FIELD}[${lastIndex}].address.city`)),
        yield put(unregisterField(formName, `${BENEFICIARIES_FIELD}[${lastIndex}].address.zip`)),
        yield put(unregisterField(formName, `${BENEFICIARIES_FIELD}[${lastIndex}].address.state`)),
    ]);
}

// TODO autocomplete
// function* placeSelectedSaga(formName, {payload, meta}) {
//     const placeId = payload;
//     const detail = yield call(autocompleteApi.getPlaceDetail, placeId);
//     const placeDetailModel = PlaceDetail.fromServer(detail);
//     const autocompleteIndex = meta.name.split("/").pop();
//
//     yield all([
//         put(change(formName, `${BENEFICIARIES_FIELD}[${autocompleteIndex}].address.street`, placeDetailModel.route)),
//         put(change(formName, `${BENEFICIARIES_FIELD}[${autocompleteIndex}].address.descriptionNumber`, placeDetailModel.premise)),
//         put(change(formName, `${BENEFICIARIES_FIELD}[${autocompleteIndex}].address.orientationNumber`, placeDetailModel.street_number)),
//         put(change(formName, `${BENEFICIARIES_FIELD}[${autocompleteIndex}].address.city`, placeDetailModel.locality)),
//         put(change(formName, `${BENEFICIARIES_FIELD}[${autocompleteIndex}].address.zip`, placeDetailModel.postal_code)),
//         put(change(formName, `${BENEFICIARIES_FIELD}[${autocompleteIndex}].address.state`, placeDetailModel.country)),
//     ]);
// }
