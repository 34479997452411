import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { Icon, Typography } from "ui-library/atoms";
import { Box } from "ui-library/layouts";
import React from "react";
import {
    StyledTimeline,
    StyledTimelineConnector,
    StyledTimelineContent,
    StyledTimelineDot,
    StyledTimelineItem,
    StyledTimelineSeparator,
} from "ui-library/Styled";

import PropTypes from "prop-types";

export const Timeline = ({ data, activeStep }) => {
    //items []
    //item {}
    // - Title
    // - content
    // - date

    const determineStatus = (index) => {
        if (index < activeStep || activeStep === data.length - 1) {
            return "finished";
        } else if (index === activeStep) {
            return "progress";
        } else {
            return "waiting";
        }
    };

    const [expanded, setExpanded] = React.useState("timeline" + activeStep);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <StyledTimeline>
            {data.map((item, index) => {
                return (
                    <StyledTimelineItem key={index}>
                        <StyledTimelineSeparator>
                            <StyledTimelineDot $status={determineStatus(index)}>
                                {determineStatus(index) === "finished" && <Icon icon="circle-check" color="green" size={16} />}
                            </StyledTimelineDot>
                            {index + 1 < data.length && <StyledTimelineConnector $finished={index < activeStep ? true : false} />}
                        </StyledTimelineSeparator>
                        <StyledTimelineContent $status={determineStatus(index)}>
                            <Accordion
                                className="accordion content"
                                expanded={expanded === "timeline" + index}
                                onChange={handleChange("timeline" + index)}
                                key={index}
                            >
                                <AccordionSummary
                                    mb={2}
                                    className="accordion-summary"
                                    expandIcon={<Icon size={12} icon="chevron-down" />}
                                    aria-controls={"timeline" + index}
                                    id={"timeline" + index}
                                >
                                    <Box>
                                        <Typography className="content-title">{item.title}</Typography>
                                        {item.date && (
                                            <Typography color={"gray"} variant="caption">
                                                {item.date}
                                            </Typography>
                                        )}
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails className="accordion-details">
                                    <Typography color="black">{item.content}</Typography>
                                </AccordionDetails>
                            </Accordion>
                        </StyledTimelineContent>
                    </StyledTimelineItem>
                );
            })}
        </StyledTimeline>
    );
};

Timeline.propTypes = {
    data: PropTypes.array.isRequired,
    activeStep: PropTypes.number,
};
