import React, { FC } from "react";

import i18n from "core/i18n";
import { dateTimeToLocalFullDate, formatYesNo, localFullDate } from "core/formatters";
import { StandaloneLink } from "ui-library/atoms";
import { Box, GridItem } from "ui-library/layouts";
import { InfoPanel, LabelTextItem } from "ui-library/modules";
import { ContractPolicy, PensionDssAccountDetail } from "types";

type PensionContractOverviewGridItemProps = {
    contract: ContractPolicy;
    pensionAccountDetail: PensionDssAccountDetail;
    detailLink: string;
};

export const PensionContractOverviewGridItem: FC<PensionContractOverviewGridItemProps> = ({
    contract,
    pensionAccountDetail,
    detailLink,
}) => {
    const { t } = i18n.useTranslation();

    return (
        <GridItem display="flex" md={7} xs={12}>
            <InfoPanel
                icon="contract"
                title={t("common.contract")}
                headerAction={
                    <StandaloneLink to={detailLink} color="blue">
                        {t("common.detail")}
                    </StandaloneLink>
                }
            >
                <Box>
                    <LabelTextItem label={t("common.begin")} text={dateTimeToLocalFullDate(contract?.beginDate)} />
                    {contract?.endDate && <LabelTextItem label={t("common.end")} text={dateTimeToLocalFullDate(contract.endDate)} />}
                    <LabelTextItem
                        label={t("insurance.voluntaryContribution")}
                        text={formatYesNo(t, pensionAccountDetail.voluntaryContribution)}
                    />
                    <LabelTextItem
                        label={t("insurance.isTransferredContract")}
                        text={formatYesNo(t, pensionAccountDetail.isTransferredContract)}
                    />
                    <LabelTextItem
                        label={t("insurance.accountEstablishmentDate")}
                        text={localFullDate(pensionAccountDetail.pensionAccountEstablishmentDate)}
                    />
                </Box>
            </InfoPanel>
        </GridItem>
    );
};
