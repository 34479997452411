import React from "react";

import i18n from "core/i18n";
import { ModalSuccess } from "containers/modal";

export const ClaimNoteModalSuccess = ({ closeModal, props }) => {
    const { t } = i18n.useTranslation();
    return (
        <ModalSuccess title={t("success.claimNote.title.sent")} message={t("success.claimNote.text")} closeModal={closeModal} {...props} />
    );
};
