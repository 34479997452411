import React from "react";

import { AmountFilterOperator } from "containers/tableFilters";
import { Field, DatePicker, InputText, positiveNegativeAmountCharacters } from "core/form";
import i18n from "core/i18n";
import { Typography } from "ui-library/atoms";
import { TableFilterLayout, TableFilterRow } from "ui-library/layouts";

export const FinancialOperationsAdvancedFilter = () => {
    const { t } = i18n.useTranslation();

    return (
        <TableFilterLayout>
            <TableFilterRow>
                <Typography variant="p">{t("common.dateFrom")}</Typography>
                <Field name="dateFrom" component={DatePicker} />
            </TableFilterRow>
            <TableFilterRow>
                <Typography variant="p">{t("common.dateTo")}</Typography>
                <Field name="dateTo" component={DatePicker} />
            </TableFilterRow>
            <TableFilterRow>
                <Typography variant="p">{t("common.amount")}</Typography>
                <Field name="amount.operator" component={AmountFilterOperator} placeholder={t("common.choose")} />
                <Field name="amount.value" component={InputText} normalize={[positiveNegativeAmountCharacters]} />
            </TableFilterRow>
        </TableFilterLayout>
    );
};
