import { withTheme } from "@mui/styles";
import styled from "styled-components";

import { Box } from "ui-library/layouts/Box/Box";

export const StyledRolldownWrapper = withTheme(styled(Box)`
    position: relative;
    .rolldown-icon {
        display: flex;
        justify-content: center;
        &:before {
            width: 100%;
            content: "";
            height: 80px;
            background-image: linear-gradient(180deg, rgba(238, 242, 250, 0) 0%, #eef2fa 100%);
            position: absolute;
            bottom: 0;
            left: 0;
        }

        button {
            border-radius: 50%;
            position: absolute;
            left: 47%;
            bottom: -20px;
        }
    }
`);

export const StyledRolldownContainer = withTheme(styled(Box)`
    overflow: hidden;
    transition: max-height 1s;
    .rolldown-content {
        max-height: ${(props) => (props.$roll ? "auto" : "420px")};
        margin-bottom: ${(props) => (props.$roll ? "50px" : "0")};
    }
`);
