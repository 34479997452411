import React from "react";
import PropTypes, { instanceOf } from "prop-types";

import { Block, Typography } from "ui-library/atoms";
import { LabelTextItem } from "ui-library/modules";
import { ContentBox } from "ui-library/layouts";

import { localAmountWithCurrencyLegacy } from "core/formatters";
import i18n from "core/i18n";
import { date, fn } from "core/util";
import { InsuredRealEstateProperty } from "model/harmonized";

import { NonLifeCoverageItem } from "./NonLifeCoverageItem";
import { getLocalizedAddressAsList } from "core/localized";
import { MultilineContent } from "containers/MultilineContent";

const insuranceAmountLimit = ["AUTOSKP007", "AUTOCZP007", "HOMECZP002", "HOMESKP002", "HOMECZP004", "HOMESKP004"];

const addNonEmpty = (label, value) => !fn.isEmpty(value) && <LabelTextItem label={label} text={value} />;

export const NonLifeInsuranceCoverage = ({ productPackage }) => {
    const { t } = i18n.useTranslation();
    const validity = productPackage?.validity;
    const getAmountLabelText = insuranceAmountLimit.includes(productPackage.riskCode)
        ? t("insurance.insuranceAmountLimit")
        : t("insurance.insuranceAmount");

    const addressAsList = getLocalizedAddressAsList(t, productPackage.address);
    const formattedAddress = !fn.isEmpty(addressAsList) && addressAsList.size > 0 ? <MultilineContent value={addressAsList} /> : null;

    return (
        <Block variant="grayWhite">
            <ContentBox>
                <Typography variant="h3" component="h3">
                    {productPackage.coverageText}
                </Typography>
            </ContentBox>

            <ContentBox>
                {addNonEmpty(t("common.description"), productPackage.description)}
                {addNonEmpty(t("insurance.insuranceCost"), localAmountWithCurrencyLegacy(productPackage.insuranceCost))}
                {addNonEmpty(getAmountLabelText, localAmountWithCurrencyLegacy(productPackage.insuranceAmount))}
                {addNonEmpty(t("insurance.insuranceTo"), date.formatToDateWithUnlimited(validity?.dateValidTo, t("common.unlimitedDate")))}
                {addNonEmpty(t("home.insuredRealEstatePropertyAddress"), formattedAddress)}
            </ContentBox>

            {productPackage.coverages.map((coverage) => (
                <NonLifeCoverageItem key={coverage.reactKey} coverage={coverage} />
            ))}
        </Block>
    );
};

NonLifeInsuranceCoverage.propTypes = {
    productPackage: PropTypes.instanceOf(InsuredRealEstateProperty).isRequired,
};
