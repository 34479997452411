import { createSelector } from "reselect";

import { IdType } from "enums";
import i18n from "core/i18n";
import { getFormFieldValue, getFormFieldValueWithState, isFieldDirty } from "core/form";
import {
    ARE_ADDRESSES_IDENTICAL_FIELD,
    CONTACT_ADDRESS_SECTION,
    CONTACT_PERSON_TYPE_FIELD,
    PERMANENT_ADDRESS_SECTION,
    SHOW_CONTACT_PERSON_HIDDEN_FIELD,
} from "./constants";

export const isIdentificationCardIdDirty = (state, form) => isFieldDirty(state, form, "identificationCard.cardId");
export const isIdentificationCardIdDirtyCallback = (form) => (state) => isFieldDirty(state, form, "identificationCard.cardId");

export const isIdentificationTypeDirty = (state, form) => isFieldDirty(state, form, "identificationCard.identification");

export const getShowContactPerson = (state, formName) =>
    getFormFieldValueWithState(state, formName, `${CONTACT_ADDRESS_SECTION}.${SHOW_CONTACT_PERSON_HIDDEN_FIELD}`);
export const getShowContactPersonCallback = (formName) => (state) =>
    getFormFieldValueWithState(state, formName, `${CONTACT_ADDRESS_SECTION}.${SHOW_CONTACT_PERSON_HIDDEN_FIELD}`);

export const getClientType = (state, formName) => getFormFieldValueWithState(state, formName, "clientType");
export const getClientTypeCallback = (formName) => (state) => getFormFieldValueWithState(state, formName, "clientType");

const getFormContactPersonType = (state, formName) =>
    getFormFieldValueWithState(state, formName, `${CONTACT_ADDRESS_SECTION}.${CONTACT_PERSON_TYPE_FIELD}`);

export const getAddressesIdentical = (state, formName) =>
    getFormFieldValue(formName, `${CONTACT_ADDRESS_SECTION}.${ARE_ADDRESSES_IDENTICAL_FIELD}`)(state);
export const getAddressesIdenticalCallback = (formName) => (state) =>
    getFormFieldValue(formName, `${CONTACT_ADDRESS_SECTION}.${ARE_ADDRESSES_IDENTICAL_FIELD}`)(state);

export const getPermanentAddressCallback = (formName) => (state) => getFormFieldValueWithState(state, formName, PERMANENT_ADDRESS_SECTION);

export const createGetContactPersonType = (formName) =>
    createSelector(
        (state) => getClientType(state, formName),
        (state) => getFormContactPersonType(state, formName),
        (state) => getAddressesIdentical(state, formName),
        (state) => getShowContactPerson(state, formName),
        (clientType, formContactPersonType, addressesIdentical, showContactPerson) => {
            if (showContactPerson) {
                if (addressesIdentical) {
                    return clientType;
                } else {
                    return formContactPersonType;
                }
            } else {
                return null;
            }
        },
    );

export const getTranslatedIdTypeOptions = i18n.createGetLocalizedEnumOptions(IdType);
