import React, { FC } from "react";

import { GridLayout } from "ui-library/layouts";

import { FinancialStatementGridItem } from "../containers";

export const FinancialStatementTab: FC = () => (
    <GridLayout defaultSpacing>
        <FinancialStatementGridItem />
    </GridLayout>
);
