import { Outlet } from "react-router-dom";

import { Container, SidemenuLink } from "ui-library/atoms";
import { SubPageLayout, SubPageWrapper } from "ui-library/layouts";
import { PageLead, Sidemenu } from "ui-library/modules";

import { Tabs } from "routeConstants";
import { innerRoutes } from "routeUrls";
import i18n from "core/i18n";

export default () => {
    const { t } = i18n.useTranslation();

    return (
        <SubPageLayout>
            <Container>
                <PageLead title={t("payments.title")} lead={t("payments.subtitle")} />

                <SubPageWrapper>
                    <Sidemenu>
                        <SidemenuLink to={innerRoutes[Tabs.PAYMENTS_PAY]}>{t("common.pay")}</SidemenuLink>
                    </Sidemenu>

                    <Outlet />
                </SubPageWrapper>
            </Container>
        </SubPageLayout>
    );
};
