import { ContractPolicy, FinancialOperationsOverview, NormalizedData } from "types";

export const NAME = "user/contracts";

export interface ContractListState {
    contracts: NormalizedData<ContractPolicy>;
    activeContractIds: number[];
    inactiveContractIds: number[];
}

export const initialState: ContractListState = {
    contracts: {},
    activeContractIds: [],
    inactiveContractIds: [],
};

export type UpdateContractOverviewPayload = {
    idObject: number;
    data: any;
};

export type UpdateContractFinancialOperations = {
    idObject: number;
    data?: FinancialOperationsOverview;
    isFailed?: boolean;
};

export type ContractDerivedData = {
    isFutureContract: boolean;
    isInFutureOrTerminated: boolean;
    showEndDate: boolean;
    isEndedNotThisOrLastYear: boolean;
};

export type ContractsDerivedData = {
    hasMoreThanOneActiveContract: boolean;
    hasAnyThisMonthMovements: boolean;
    hasAnyLastMonthMovements: boolean;
    hasAnyFailedFetchingMovements: boolean;
};
