import React from "react";
import { useSelector } from "react-redux";
import { List } from "immutable";

import { Accordion, Typography, UnorderedList } from "ui-library/atoms";
import { Box } from "ui-library/layouts";
import { DataTable } from "ui-library/modules";

import i18n from "core/i18n";
import { AmountLegacy } from "model/common";
import { formatYesNo, localAmountWithCurrencyLegacy } from "core/formatters";

import { createGetCoveragesLegendsUniqa, getSelectedInsured } from "../selectors";

const insurantColumns = (t, onetimeInsurance) => [
    {
        field: "insuranceTariff",
        headerName: t("life.tariffCode"),
        sortable: false,
        flex: 2,
        renderCell: (params) => params.value?.code,
    },
    {
        field: "insuranceIndexation",
        headerName: t("life.indexation"),
        sortable: false,
        flex: 1,
        renderCell: (params) => formatYesNo(t, params.value),
    },
    {
        field: "insuranceLimit",
        headerName: t("insurance.insuranceAmount"),
        sortable: false,
        flex: 2,
        align: "right",
        headerAlign: "right",
        renderCell: (params) => localAmountWithCurrencyLegacy(params.value),
    },
    {
        field: "coverageCost",
        headerName: onetimeInsurance ? t("life.oneTimeInsuranceCost") : t("insurance.insuranceCost"),
        sortable: false,
        flex: 2,
        align: "right",
        headerAlign: "right",
        renderCell: (params) => localAmountWithCurrencyLegacy(params.value),
    },
];

const getInsuranceSum = (rows) => {
    if (rows.size === 0) {
        return null;
    }
    const amount = rows.reduce((acc, value) => acc + Number(value.coverageCost?.value), 0);
    return AmountLegacy.fromServer({ Value: `${amount}`, CurrencyCode: rows.first().coverageCost?.currencyCode });
};

export const RiskDetailPerson = () => {
    const { t } = i18n.useTranslation();

    const selectedInsuredData = useSelector(getSelectedInsured);
    const coverages = selectedInsuredData ? selectedInsuredData.get("coverages") : new List();

    const coveragesRows = selectedInsuredData && selectedInsuredData.get("coverages").toArray();
    const insuranceSum = getInsuranceSum(coverages);
    const tariffLegends = useSelector(createGetCoveragesLegendsUniqa(coverages));

    return (
        <>
            {/* Insured person tariff table with amounts */}
            <Box>
                <DataTable
                    disableColumnReorder
                    getRowId={(row) => row.reactKey}
                    rows={coveragesRows}
                    pageSize={100}
                    columns={insurantColumns(t, false)}
                    summaryRow={{
                        desc: t("life.insuranceCostSummary"),
                        total: localAmountWithCurrencyLegacy(insuranceSum),
                    }}
                />
            </Box>

            {/* Tariff legend */}
            <Box>
                <Accordion
                    id="tarifAccorID"
                    data={[
                        {
                            title: t("life.tarrifCodes"),
                            children: (
                                <UnorderedList>
                                    {tariffLegends.map((item) => (
                                        <Typography key={item}>{item}</Typography>
                                    ))}
                                </UnorderedList>
                            ),
                        },
                    ]}
                />
            </Box>
        </>
    );
};
