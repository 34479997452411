import React, { FC } from "react";
import { Selector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

import { Button, Container, Loading, Typography } from "ui-library/atoms";
import { ContentBox, Flexbox, Section } from "ui-library/layouts";
import { LabelTextItem } from "ui-library/modules";

import i18n from "core/i18n";
import { Field, form, getFormFieldValue, SegmentedControl } from "core/form";
import { ElectronicCommunicationCampaignApiResponse, State } from "types";

import { FIELD_AGREEMENT } from "./constants";

type EditFormProps = {
    handleSubmit: () => void;
    submitting: boolean;
    formName: string;
    getCampaignDataSelector: Selector<State, ElectronicCommunicationCampaignApiResponse>;
};

const CampaignElectronicCommunicationForm: FC<EditFormProps> = ({ formName, getCampaignDataSelector, handleSubmit, submitting }) => {
    const { t } = i18n.useTranslation();
    const { data } = useSelector(getCampaignDataSelector);
    const isAgreementFieldTrue = useSelector(getFormFieldValue(formName, FIELD_AGREEMENT));

    return (
        <Container maxWidth="md">
            <Loading fullPage loading={submitting} />

            {/* Title */}
            <Section>
                <Typography variant="h1">{t("form.campaignElectronicCommunication")}</Typography>
            </Section>

            {/* Introduction text */}
            <ContentBox mb={3}>
                <Typography component="p" markDown={t("form.campaignElectronicCommunication.text")} />
            </ContentBox>

            {/* Product specific data */}
            <ContentBox mb={5}>
                <LabelTextItem label={t("common.contractNumber")} text={data.contractNumber} />
                <LabelTextItem label={t("common.insuranceType")} text={data.productCrm} />
                <LabelTextItem label={t("holder.emailAddress")} text={data.email} />
            </ContentBox>

            {/* Check with footnote */}
            <ContentBox mb={5}>
                <Field
                    name={FIELD_AGREEMENT}
                    component={SegmentedControl}
                    label={t("form.campaignElectronicCommunication.agreementField")}
                    footNote={t("form.campaignElectronicCommunication.footNote")}
                />
            </ContentBox>

            {/* Submit button */}
            <Flexbox direction={{ lg: "row" }} justifyContent={"center"} gap={2} mb={10}>
                <Button color="green" size="large" onClick={handleSubmit} disabled={!isAgreementFieldTrue || submitting}>
                    {t("form.campaignElectronicCommunication.submit")}
                </Button>
            </Flexbox>
        </Container>
    );
};

export const createContainer = (formName: string, getCampaignDataSelector: Selector<State, ElectronicCommunicationCampaignApiResponse>) =>
    form(formName)((props) => (
        <CampaignElectronicCommunicationForm formName={formName} getCampaignDataSelector={getCampaignDataSelector} {...props} />
    ));
