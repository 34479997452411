import { withTheme } from "@mui/styles";
import styled from "styled-components";
import { Popover } from "@mui/material";

export const StyledPopover = withTheme(styled(Popover)`
    && {
        .MuiPopover-paper {
            box-shadow: 0px 20px 35px 0px #30313d1a;
            padding: 24px;
            border: 1px solid ${(props) => props.theme.palette.gray.softBorder};
            max-width: 670px;
            position: relative;
            h4 {
                margin-bottom: 16px;
            }
            p {
                color: ${(props) => props.theme.palette.gray.main};
                margin-bottom: 21px;
            }
            &:before {
                content: "";
                position: absolute;
                width: 10px;
                height: 10px;
                background: ${(props) => props.theme.palette.white.main};
                transform: rotate(45deg);
            }
        }
        &.popover-left {
            .MuiPopover-paper {
                overflow: inherit;
                &:before {
                    right: -6px;
                    top: calc(50% - 5px);
                    border-right: 1px solid ${(props) => props.theme.palette.gray.softBorder};
                    border-top: 1px solid ${(props) => props.theme.palette.gray.softBorder};
                }
            }
        }
        &.popover-top {
            .MuiPopover-paper {
                overflow: inherit;
                &:before {
                    bottom: -6px;
                    right: calc(50% - 5px);
                    border-right: 1px solid ${(props) => props.theme.palette.gray.softBorder};
                    border-bottom: 1px solid ${(props) => props.theme.palette.gray.softBorder};
                }
            }
        }
        &.popover-bottom {
            .MuiPopover-paper {
                overflow: inherit;
                &:before {
                    top: -6px;
                    right: calc(50% - 5px);
                    border-top: 1px solid ${(props) => props.theme.palette.gray.softBorder};
                    border-left: 1px solid ${(props) => props.theme.palette.gray.softBorder};
                }
            }
        }
        &.popover-right {
            .MuiPopover-paper {
                overflow: inherit;
                &:before {
                    left: -6px;
                    top: calc(50% - 5px);
                    border-left: 1px solid ${(props) => props.theme.palette.gray.softBorder};
                    border-bottom: 1px solid ${(props) => props.theme.palette.gray.softBorder};
                }
            }
        }
    }
`);
