import type { Selector } from "@reduxjs/toolkit";
import { List, Map } from "immutable";
import { createSelector } from "reselect";
import { v4 as uuid } from "uuid";

import { DEFAULT_EMPTY_PARAM } from "app/constants";
import { app, date, DEFAULT_CHART_COLOR, model } from "core/util";
import i18n from "core/i18n";
import userContracts from "core/userContracts";
import { localAmountWithCurrencyLegacy } from "core/formatters";
import entity from "core/entity";
import { DdsFunds } from "enums";

import {
    ElectronicCommunication,
    Holder,
    PaymentsScheduler,
    PensionBeneficiary,
    PensionDdsAccountDetail,
    PensionDdsContract,
    PensionDssDdsFundInvestmentStrategy,
    State,
} from "types";

import { ENTITY_FINANCIAL_OPERATIONS, ENTITY_FINANCIAL_STATEMENT, NAME } from "./constants";
import { PensionDssDdsFinancialStatement } from "model/pension";

/** contract data */
const getModel = app.createGetModel(NAME);
const getData = (state) => getModel(state).get("data");

export const getPensionDdsContractDetail: Selector<State, PensionDdsContract> = (state) => getData(state).pensionDdsContractDetail;

export const getIdObject: Selector<State, number> = (state) => getPensionDdsContractDetail(state).idObject || DEFAULT_EMPTY_PARAM;
export const getPolicyHolder: Selector<State, Holder> = (state) => getPensionDdsContractDetail(state).contractDetail.holder;
export const getElectronicCommunication: Selector<State, ElectronicCommunication> = (state) =>
    getPensionDdsContractDetail(state).contractDetail.electronicCommunication;
export const getPensionAccountDetail: Selector<State, PensionDdsAccountDetail> = (state) =>
    getPensionDdsContractDetail(state).contractDetail.pensionDdsAccountDetail;
export const getPaymentsScheduler: Selector<State, PaymentsScheduler> = (state) => getPensionAccountDetail(state).paymentsScheduler;
export const getStatementPassword: Selector<State, string> = (state) => getPensionAccountDetail(state).statementPassword;
export const getBeneficiaries: Selector<State, PensionBeneficiary[]> = (state) =>
    getPensionDdsContractDetail(state).contractDetail.beneficiaries;
export const getFundsInvestmentStrategies: Selector<State, PensionDssDdsFundInvestmentStrategy[]> = (state) =>
    getPensionAccountDetail(state).fundsInvestmentStrategies;
export const getStatementSettings: Selector<State, unknown> = (state) =>
    // @ts-ignore TODO: martin.rodin clientStatementSettings does not exist in the model, ask Karol about it
    getPensionDdsContractDetail(state).contractDetail.clientStatementSettings;

// Entity
export const getPensionDdsFinancialStatementEntity = (state) =>
    // @ts-ignore
    entity.getDataSelector(state, ENTITY_FINANCIAL_STATEMENT, PensionDssDdsFinancialStatement.fromServer());
export const getPensionDdsContractBalanceSheet = (state) => getPensionDdsFinancialStatementEntity(state).get("contractBalanceSheet");

// @ts-ignore
export const getFinancialOperationsEntity = (state) => entity.getDataSelector(state, ENTITY_FINANCIAL_OPERATIONS, new List());

/** user data */
export const getPolicy = createSelector(userContracts.getContractNormalizedData, getIdObject, (policies, idObject) =>
    idObject ? policies[idObject] : null,
);

export const getPolicyBeginDateYear = (state) => date.getYearFromStringDateTime(getPolicy(state)?.beginDate);

const getFunds = i18n.createGetLocalizedEnum(DdsFunds, "fundLink");

const getFinancialStatementChartItems = (financialStatement, funds, locale) =>
    financialStatement.pensionInvestmentsBasedOnFund
        .filter((item) => item.actualInvestmentsValue.value > 0)
        .map((item) => {
            const ddsFund = funds.find((fund) => fund.get("fundCode") === item.getIn(["investmentFund", "code"]));
            return {
                name: model.getLocaleCodeName(item.investmentFund, locale),
                color: ddsFund ? ddsFund.fundColor : DEFAULT_CHART_COLOR,
                value: item.actualInvestmentsValue.value,
                fullValue: localAmountWithCurrencyLegacy(item.actualInvestmentsValue),
                currency: item.actualInvestmentsValue.currencyCode,
            };
        })
        .toArray();

export const getFinancialStatementChartData = createSelector(
    getPensionDdsFinancialStatementEntity,
    getFunds,
    i18n.getLanguageCode,
    (financialStatement, funds, locale) =>
        Map({
            sumValue: financialStatement.actualInvestmentsValue.value,
            sumCurrency: financialStatement.actualInvestmentsValue.currencyCode,
            sumAmount: `${localAmountWithCurrencyLegacy(financialStatement.actualInvestmentsValue)}`,
            items: getFinancialStatementChartItems(financialStatement, funds, locale),
        }),
);

export const getFinancialOperations = createSelector(getFinancialOperationsEntity, (financialOperations) => {
    return financialOperations.map((financialOperation) => ({
        id: financialOperation.id,
        investmentFundName: financialOperation.investmentFundName,
        valueDate: financialOperation.valueDate,
        grossAmount: Map({
            amount: financialOperation.grossAmount,
            operationType: financialOperation.typeCode,
        }),
        pricePerShareAtOperationDateTime: financialOperation.pricePerShareAtOperationDateTime,
        numberOfShares: financialOperation.numberOfShares,
        actualPricePerShare: financialOperation.actualPricePerShare,
        type: financialOperation.type,
    }));
});
export const getFinancialOperationsFunds = createSelector(getFinancialOperationsEntity, (financialOperations) => {
    const languageCode = i18n.getLanguageCode();

    return financialOperations
        .map((operation) => operation.investmentFund)
        .toSet()
        .map((fund) => Map().set("value", fund.code).set("label", model.getLocaleCodeName(fund, languageCode)));
});
export const getFinancialOperationsTypes = createSelector(getFinancialOperationsEntity, (financialOperations) => {
    const languageCode = i18n.getLanguageCode();

    return financialOperations
        .map((operation) => operation.type)
        .toSet()
        .map((type) => Map().set("value", type.code).set("label", model.getLocaleCodeName(type, languageCode)));
});

export const getPensionDdsStatementData = createSelector(getPensionDdsFinancialStatementEntity, (financialStatement) => {
    if (!financialStatement) {
        return Map();
    }
    return [
        {
            reactKey: uuid(),
            investmentsSumInvested: financialStatement.investmentsSumInvested,
            investmentsSumNotInvested: financialStatement.investmentsSumNotInvested,
            actualInvestmentsValue: financialStatement.actualInvestmentsValue,
            valuationPercentage: financialStatement.valuationPercentage,
        },
    ];
});
