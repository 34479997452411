import React from "react";

import { Typography } from "ui-library/atoms";
import { ContentBox } from "ui-library/layouts";

import i18n from "core/i18n";

import { DssNewInvestmentAllocationLimits } from "../popover";

export const GuaranteedFundRatioReductionDisable = () => {
    const { t } = i18n.useTranslation();

    return (
        <ContentBox>
            <Typography component="p">
                {t("pension.fundsChange.dss.guaranteedFundRatioReductionDisablePartOne")}(<b>{t("pension.fundsChange.seeTable")} </b>
                <DssNewInvestmentAllocationLimits />
                {t("pension.fundsChange.dss.guaranteedFundRatioReductionDisablePartTwo")}
            </Typography>
        </ContentBox>
    );
};
