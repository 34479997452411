import { createSelector } from "reselect";
import { List, Map } from "immutable";

import entity from "core/entity";
import userContracts from "core/userContracts";
import router from "core/router";
import { app, claims } from "core/util";
import pageContext from "core/pageContext";
import { ContractOverview } from "model/common";
import { ClaimReportCategory } from "enums";
import { Params } from "routeConstants";

import { ENTITY_CONTRACT_OVERVIEW, NAME } from "./constants";

// Data
const getModel = app.createGetModel(NAME);
export const getStoredContractId = (state) => getModel(state).getIn(["appState", "selectedContractId"]);
export const getSelectedClaimCategoryId = (state) => router.getCurrentQuery(state)[Params.CLAIM_REPORT_CATEGORY];
export const getContractOverview = (state) => entity.getDataSelector(state, ENTITY_CONTRACT_OVERVIEW, ContractOverview.fromServer());
export const getLineOfBusinessClasses = (state) => getContractOverview(state).getIn(["lineOfBusinessClasses"]);
export const createGetCompliantLineOfBusinessClasses = (idObject) =>
    createSelector(
        getLineOfBusinessClasses,
        userContracts.createGetProductGroupLvl2ById(idObject),
        pageContext.getSiteId,
        (lineOfBusinessClasses, productGroupLvl2, siteId) =>
            claims.getCompliantLineOfBusinesses(lineOfBusinessClasses, productGroupLvl2, siteId),
    );
export const getSelectedLineOfBusinessClass = (state) => router.getCurrentQuery(state)[Params.CLAIM_LINE_OF_BUSINESS_CLASS];
export const getSelectedContractId = (state) => router.getCurrentQuery(state)[Params.PAYMENT_CONTRACT_ID];

export const getContracts = createSelector(userContracts.getContractList, (contracts) => {
    return contracts.map((contract) =>
        Map({
            value: String(contract.idObject),
            isActive: contract.isActive,
            contractNumber: contract.contractNumber,
            contractName: contract.contractName,
            productCRM: contract.productCRM,
            idEnuProductGroupLevelTwo: contract.idEnuProductGroupLevelTwo,
            beginDate: contract.beginDate,
            endDate: contract.endDate,
        }),
    );
});

export const getContractByIdObject = (idObject) =>
    createSelector(getContracts, (contracts) => {
        return contracts.find((contract) => {
            return contract.get("value") === idObject;
        });
    });

export const getIsContractActive = (idObject) =>
    createSelector(getContractByIdObject(idObject), (contract) => contract.get("isActive") === true);

export const getContractsByCategoryId = (categoryId) =>
    createSelector(getContracts, (contracts) => {
        if (!categoryId) {
            return List();
        }
        return contracts.filter((contract) =>
            ClaimReportCategory[categoryId]?.productGroupLvls2?.contains(contract.get("idEnuProductGroupLevelTwo")),
        );
    });

export const getIsAnyContractWithOwnNameByCategoryId = (categoryId) =>
    createSelector(getContractsByCategoryId(categoryId), (contracts) => {
        const result = contracts.filter((contract) => contract.get("contractName"));
        return result.length > 0;
    });

export const hasContractOfGivenCategoryId = (categoryId) =>
    createSelector(getContractsByCategoryId(categoryId), (contracts) => contracts && contracts.length !== 0);

export const hasOneContractOfGivenCategory = (categoryId) =>
    createSelector(getContractsByCategoryId(categoryId), (contracts) => {
        return contracts && contracts.length === 1;
    });

export const getSingleContractByCategoryId = (categoryId) =>
    createSelector(
        getContractsByCategoryId(categoryId),
        hasOneContractOfGivenCategory(categoryId),
        (activeContractsOfGivenCategory, hasOneContractOfGivenCategory) => {
            if (!hasOneContractOfGivenCategory) {
                return null;
            }
            return activeContractsOfGivenCategory && activeContractsOfGivenCategory[0].get("value");
        },
    );
