import { Frequency } from "enums";
import { PaymentsSchedulerLegacy } from "model/common";

export const getPaymentSchedulerOrEmptyScheduler = (paymentsScheduler) => {
    if (!paymentsScheduler) {
        return PaymentsSchedulerLegacy.fromServer();
    }
    return paymentsScheduler;
};

export const prepareValidFrequencyOptions = (paymentsScheduler) => {
    const validFrequencyOptions = Object.keys(Frequency);
    return validFrequencyOptions.includes(paymentsScheduler.getIn(["periodicity", "code"]))
        ? paymentsScheduler
        : paymentsScheduler.setIn(["periodicity", "code"], undefined);
};

export const GRAPH_COLORS = {
    0: "blueMainDarker",
    1: "greenMain",
    2: "orangeMain",
    3: "redMain",
    4: "blueCloud",
    5: "violetMain",
    6: "blueTyrkys",
    7: "pinkMain",
    8: "blueMainLighter",
    9: "greenMainDarker",
    10: "blueKing",
    11: "graySoftBorder",
    12: "redMainLighter",
    13: "orangeMainLighter",
    14: "blueSoft",
    15: "blueMuddy",
    16: "grayMainLighter",
    17: "orangeMainDarker",
    18: "redGradient",
    19: "greenSuccess",
};
