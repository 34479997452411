import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { ContentBox, Flexbox, TwoButtonBox } from "ui-library/layouts";
import { LabelTextItem, PageLead } from "ui-library/modules";
import { Button, Container } from "ui-library/atoms";

import i18n from "core/i18n";
import localized from "core/localized";
import router from "core/router";
import userContracts from "core/userContracts";
import { dateTimeToLocalFullDate } from "core/formatters";
import products from "core/products";

import { innerRoutes, pageRoutes } from "routeUrls";
import { Pages, Tabs } from "routeConstants";

import { getIncidentDetail } from "../selectors";
import { getImageCategory, getLocalizedInsuranceRisk, getLocalizedLineOfBusiness } from "../util";

export const IncidentHeader: FC = () => {
    const { t } = i18n.useTranslation();
    const navigate = useNavigate();

    const localizedLineOfBusiness = useSelector(localized.getLocalizedUniqaLineOfBusinessMap);
    const localizedInsuranceRisk = useSelector(localized.getLocalizedUniqaInsuranceRiskMap);

    const incidentDetail = useSelector(getIncidentDetail);

    const contractPolicy = userContracts.useGetContractByContractNumber(incidentDetail.policyNumber);
    const productDefinition = products.useGetProductDefinition(contractPolicy.idEnuProductGroupLevelTwo);
    const contractDetailLink =
        (productDefinition?.topLevelRoute &&
            contractPolicy?.idObject &&
            router.getStaticUrl(productDefinition.topLevelRoute, Tabs.DETAIL, { idObject: contractPolicy.idObject })) ||
        null;

    return (
        <Container>
            <PageLead
                leadImg={getImageCategory(incidentDetail)}
                title={`${getLocalizedLineOfBusiness(incidentDetail, localizedLineOfBusiness)}${getLocalizedInsuranceRisk(
                    incidentDetail,
                    localizedInsuranceRisk,
                )}`}
            >
                <ContentBox bigger>
                    <LabelTextItem fontVariant="perex" label={t("claim.incidentNumber")} text={incidentDetail.incidentNumber} />
                    <LabelTextItem fontVariant="perex" label={t("common.contractNumber")} text={incidentDetail.policyNumber} />
                    <LabelTextItem
                        fontVariant="perex"
                        label={t("common.creationDate")}
                        text={dateTimeToLocalFullDate(incidentDetail.incidentDate)}
                    />
                </ContentBox>

                <Flexbox gap={1}>
                    <TwoButtonBox>
                        <Button
                            color="blue"
                            variant="contained"
                            onClick={() => navigate(pageRoutes[Pages.CLAIMS] + "/" + innerRoutes[Tabs.CLAIMS_LIST])}
                        >
                            {t("incident.backToIncidentList")}
                        </Button>
                    </TwoButtonBox>
                    {contractDetailLink && (
                        <TwoButtonBox>
                            <Button variant={"outlined"} color={"blue"} onClick={() => navigate(contractDetailLink)}>
                                {t("common.showContract")}
                            </Button>
                        </TwoButtonBox>
                    )}
                </Flexbox>
            </PageLead>
        </Container>
    );
};
