import React, { FC } from "react";
import { useSelector } from "react-redux";
import { List } from "immutable";

import { Block, StandaloneLink, Typography } from "ui-library/atoms";
import { Box, ContentBox, GridItem, GridLayout } from "ui-library/layouts";
import { DataTable, InfoPanel } from "ui-library/modules";

import entity from "core/entity";
import fetching from "core/fetching";
import i18n from "core/i18n";
import { model } from "core/util";
import { PensionTypeEnum } from "enums";
import { InsuranceProductPaymentGridItem } from "containers/contract";
import { localAmountWithCurrencyLegacy, localFullDate } from "core/formatters";
import { DDS_QUICK_VIEW_MOVEMENT_TYPES, DEFAULT_TABLE_OVERVIEW_PAGE_SIZE, DSS_QUICK_VIEW_MOVEMENT_TYPES } from "app/constants";
import { GridItemVariant } from "types";

const columnsPp = (t) => [
    {
        field: "valueDate",
        headerName: t("common.date"),
        flex: 2,
        minWidth: 120,
        sortable: false,
        renderCell: (params) => localFullDate(params.value),
    },
    {
        field: "categoryName",
        headerName: t("common.name"),
        flex: 2,
        minWidth: 200,
        sortable: false,
    },
    {
        field: "grossAmount",
        headerName: t("common.amount"),
        flex: 1,
        minWidth: 170,
        align: "right",
        headerAlign: "right",
        sortable: false,
        renderCell: (params) => localAmountWithCurrencyLegacy(params.value),
    },
];

const columnsDps = (t) => [
    {
        field: "valueDate",
        headerName: t("investments.investmentDate"),
        flex: 1,
        sortable: false,
        renderCell: (params) => localFullDate(params.value),
    },
    {
        field: "type",
        headerName: t("common.actionType"),
        flex: 1,
        sortable: false,
    },
    {
        field: "grossAmount",
        headerName: t("investments.investmentBuyoutAmount"),
        flex: 1,
        minWidth: 170,
        align: "right",
        headerAlign: "right",
        sortable: false,
        renderCell: (params) => localAmountWithCurrencyLegacy(params.value),
    },
];

const columnsDssDds = (t, languageCode) => [
    {
        field: "paymentDate",
        headerName: t("investments.paymentDate"),
        flex: 1,
        sortable: false,
        renderCell: (params) => localFullDate(params.value),
    },
    {
        field: "type",
        headerName: t("common.actionType"),
        flex: 1.5,
        sortable: false,
        renderCell: (params) => model.getLocaleCodeName(params.value, languageCode),
    },
    {
        field: "grossAmount",
        headerName: t("investments.investmentBuyoutAmount"),
        flex: 1,
        minWidth: 160,
        align: "right",
        headerAlign: "right",
        sortable: false,
        renderCell: (params) => localAmountWithCurrencyLegacy(params.value),
    },
];

const getColumns = (t, languageCode, pensionType) => {
    switch (pensionType) {
        case PensionTypeEnum.PP:
            return columnsPp(t);
        case PensionTypeEnum.DPS:
            return columnsDps(t);
        default:
            return columnsDssDds(t, languageCode);
    }
};

export interface PensionFinancialOperationsOverviewGridItemProps {
    idObject: number;
    financialOperationsEntityName: string;
    paymentDataEntityName: string;
    pensionType: string;
    detailLink: string;
    hasQuickPayment: boolean;
}

export const PensionFinancialOperationsOverviewGridItem: FC<PensionFinancialOperationsOverviewGridItemProps> = ({
    idObject,
    financialOperationsEntityName,
    paymentDataEntityName,
    detailLink,
    pensionType,
    hasQuickPayment,
}) => {
    const { t } = i18n.useTranslation();
    const languageCode = i18n.getLanguageCode();

    const isFinOperationsLoading = useSelector(fetching.isFetching(financialOperationsEntityName, true));
    const isFinOperationsError = useSelector(entity.isError(financialOperationsEntityName));
    // @ts-ignore
    const financialOperations = useSelector(entity.getData(financialOperationsEntityName, new List()));

    const isDssDds = pensionType === PensionTypeEnum.DSS || pensionType === PensionTypeEnum.DDS;

    const financialOperationsTableData =
        pensionType === PensionTypeEnum.DDS
            ? financialOperations
                  .filter((row) => DDS_QUICK_VIEW_MOVEMENT_TYPES.includes(parseInt(row?.type?.code)))
                  .slice(0, DEFAULT_TABLE_OVERVIEW_PAGE_SIZE)
            : financialOperations
                  .filter((row) => DSS_QUICK_VIEW_MOVEMENT_TYPES.includes(parseInt(row?.type?.code)))
                  .slice(0, DEFAULT_TABLE_OVERVIEW_PAGE_SIZE);

    return (
        <GridItem xs={12}>
            <Block variant="white">
                <GridLayout spacing={8}>
                    <GridItem md={hasQuickPayment ? 7 : 12} xs={12}>
                        <InfoPanel
                            icon={"chart-bar"}
                            title={t("payments.overview")}
                            subTitleSection={
                                <ContentBox mt={3}>
                                    <Typography fontSize={14}>{t("common.financialOperationsNotice")}</Typography>
                                </ContentBox>
                            }
                            bgVariant={"clean"}
                            padding={false}
                            headerAction={
                                <StandaloneLink to={detailLink} color="blue">
                                    {t("common.all")}
                                </StandaloneLink>
                            }
                        >
                            <Box>
                                <DataTable
                                    disableColumnReorder
                                    pageSize={DEFAULT_TABLE_OVERVIEW_PAGE_SIZE}
                                    withWrapper={false}
                                    hideFooter
                                    headerHeight={isDssDds ? 62 : 42}
                                    getRowId={(row) => row.id}
                                    rows={financialOperationsTableData.toArray()}
                                    columns={getColumns(t, languageCode, pensionType)}
                                    emptyMsg={t("common.noData")}
                                    errorMsg={t("error.sectionUnavailable")}
                                    isLoading={isFinOperationsLoading}
                                    isError={isFinOperationsError}
                                />
                            </Box>
                        </InfoPanel>
                    </GridItem>
                    {hasQuickPayment && (
                        <InsuranceProductPaymentGridItem
                            idObject={idObject}
                            entityName={paymentDataEntityName}
                            variant={GridItemVariant.INCLUDED}
                        />
                    )}
                </GridLayout>
            </Block>
        </GridItem>
    );
};
