import { Flexbox } from "ui-library/layouts/Flexbox/Flexbox";

export const ButtonsLayout = ({ children, ...props }) => {
    return (
        <Flexbox flexDirection={{ xs: "column", md: "row" }} gap={1} {...props}>
            {children}
        </Flexbox>
    );
};

ButtonsLayout.propTypes = {};

ButtonsLayout.defaultProps = {};
