import { createSaga } from "./createSaga";
import { createContainer } from "./createContainer";

/**
 * Saga and Container creator for Contract name form
 */
const investmentProfileForm = {
    createSaga,
    createContainer,
};
export default investmentProfileForm;
