import { model } from "core/util";

import { AmountLegacy } from "../common";
import { Beneficiary } from "../harmonized";
import { Insured } from "../harmonized/Insured";

import { UniqaLifeInvestmentStrategy } from "./UniqaLifeInvestmentStrategy";

export const UniqaLifeAccountDetail = model.createModelLegacy("UniqaLifeAccountDetail", (json) => ({
    insureds: Insured.fromServerList(json.Insureds),
    beneficiaries: Beneficiary.fromServerList(json.Beneficiaries),
    investmentStrategies: UniqaLifeInvestmentStrategy.fromServerList(json.InvestmentStrategies),
    surrenderAmount: AmountLegacy.fromServer(json.SurrenderAmount),
    surrenderDate: json.SurrenderDate,
    deathSurrenderAmount: AmountLegacy.fromServer(json.DeathSurrenderAmount),
    deathPaymentCalculationDate: json.DeathPaymentCalculationDate,
}));
