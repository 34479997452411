import React from "react";
import PropTypes from "prop-types";

import { Typography } from "ui-library/atoms";

import { MutualFundsFinancialRequest } from "model/mutualFunds";
import { fn, nbsp } from "core/util";
import { MutualFundsPeriodicity } from "enums";
import i18n from "core/i18n";
import { localFullDate } from "core/formatters";

const join = (first, second) => {
    if (first && second) {
        return (
            <span>
                {first}
                {second}
            </span>
        );
    } else if (first) {
        return first;
    } else if (second) {
        return second;
    } else {
        return "";
    }
};

export const RealizationDateFormatter = ({ row }) => {
    const { t } = i18n.useTranslation();
    const dateFrom = row.firstTransactionDate;
    if (row.periodicityType === MutualFundsPeriodicity.ONETIME.id) {
        return !fn.isEmpty(dateFrom) && <Typography>{localFullDate(dateFrom)}</Typography>;
    }

    const dateTo = row.lastTransactionDate;

    const dateFromFormat = !fn.isEmpty(dateFrom) && <Typography>{`${t("common.from")}${nbsp}${localFullDate(dateFrom)}`}</Typography>;
    const dateToFormat = !fn.isEmpty(dateTo) && <Typography>{`${t("common.to")}${nbsp}${localFullDate(dateTo)}`}</Typography>;

    return join(dateFromFormat, dateToFormat);
};

RealizationDateFormatter.propTypes = {
    row: PropTypes.instanceOf(MutualFundsFinancialRequest).isRequired,
};
