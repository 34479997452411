import React, { FC } from "react";
import i18n from "core/i18n";
import { Box } from "ui-library/layouts";
import { Typography } from "ui-library/atoms";
import { ContractListContentType } from "../constants";

export interface EmptyContractsInfoProps {
    displayedContentType: ContractListContentType;
}

export const EmptyContractsInfo: FC<EmptyContractsInfoProps> = ({ displayedContentType }) => {
    const { t } = i18n.useTranslation();
    const textMsg =
        displayedContentType === ContractListContentType.ACTIVE_CONTRACTS
            ? t("common.emptyActiveContractList")
            : t("common.emptyFinishedContractList");
    return (
        <Box>
            <Typography variant="p">{textMsg}</Typography>
        </Box>
    );
};
