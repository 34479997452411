import { FC } from "react";

import { NAME } from "./constants";
import innerRoutes from "./innerRoutes";
import { ChangePasswordSuccess, ChangeUsernameSuccess } from "./components";

export { default as NotificationPage } from "./Container";

export const NotificationTabs: { ChangePasswordSuccess: FC; ChangeUsernameSuccess: FC } = {
    ChangePasswordSuccess,
    ChangeUsernameSuccess,
};

export default {
    NAME,
    innerRoutes,
};
