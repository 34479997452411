export const NAME = "core/enum";

export const BANK_LIST = "BANK_LIST";
export const CONTRACT_AGENT_CHANGE_REASONS = "CONTRACT_AGENT_CHANGE_REASONS";

export interface EnumState {
    content: {
        [enumName: string]: string[];
    };
}

export const initialState: EnumState = {
    content: {},
};

export interface SetEnumPayload {
    enumName: string;
    content: string[];
}
