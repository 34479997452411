import React, { FC } from "react";
import { InputLabelProps } from "@mui/material";

import { Typography } from "ui-library/atoms";
import { Box } from "ui-library/layouts";
import { StyledLabel } from "ui-library/Styled";

export interface LabelProps extends InputLabelProps {
    id: string;
    label?: string;
    labelDesc?: string;
    labelOptional?: string;
}

export const Label: FC<LabelProps> = ({ label, id, labelDesc, labelOptional, ...props }) => {
    return (
        <Box>
            <StyledLabel htmlFor={id} {...props}>
                <Typography variant="p" markDown={label} />
                {labelOptional && <span className="optional">({labelOptional})</span>}
            </StyledLabel>
        </Box>
    );
};
