import { model } from "core/util";
import { PensionDpsTypes as PensionDpsTypesType, PensionDpsTypesDto } from "types";

export const PensionDpsTypes = model.createModel<PensionDpsTypesDto, PensionDpsTypesType>((json) => ({
    pensionPlan: json.PensionPlan,
    pensionPlanCode: json.PensionPlanCode,
    pensionCode: json.PensionCode,
    pensionDescription: json.PensionDescription,
    pensionDate: json.PensionDate,
    retirementPension: json.RetirementPension,
    disabilityPension: json.DisabilityPension,
}));
