import { withTheme } from "@mui/styles";
import { Box } from "ui-library/layouts/Box/Box";
import styled from "styled-components";

export const StyledContractsScrollLayout = withTheme(styled(Box)`
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        overflow: hidden;
        width: calc(100% + 32px);
        margin-left: -16px;

        margin-right: 16px;
        padding-left: 16px;

        .scroll {
            display: flex;

            transition: ${(props) => (props.sliding ? "none" : "transform 2s ease")};
            transform: ${(props) => {
                if (!props.$sliding) return "translateX(calc(-80% - 20px))";
                if (props.$direction === "RIGHT") return "translateX(calc(2 * (-80% - 20px)))";
                return "translateX(0%)";
            }};
        }
        .item {
            flex: 1 0 80%;
        }
    }
`);
