import React from "react";
import { useDispatch } from "react-redux";

import { form } from "core/form";
import modal from "core/modal";
import i18n from "core/i18n";
import { Button } from "ui-library/atoms";
import { Flexbox, GridItem, GridLayout } from "ui-library/layouts";
import { SimpleModalWindow } from "containers/modal";
import { ModalBody, ModalFooter } from "ui-library/modules";

export const createContainer = (actions, { AdvancedFilter, BasicFilter, name }) => {
    const FilterForm = () => {
        const { t } = i18n.useTranslation();
        const dispatch = useDispatch();

        const openModal = () => dispatch(modal.openModalForm(name));
        const closeModal = () => dispatch(modal.closeModalForm(name));
        const resetFilter = () => dispatch(actions.resetFilter());

        return (
            <>
                <GridLayout spacing={1}>
                    <GridItem>
                        <BasicFilter />
                    </GridItem>
                    <GridItem>
                        {AdvancedFilter && (
                            <Button onClick={openModal} variant="outlined" endIcon="filter">
                                {t("common.filter")}
                            </Button>
                        )}
                    </GridItem>
                </GridLayout>

                {AdvancedFilter && (
                    <SimpleModalWindow modalName={name} icon="filter" title={t("common.filter")}>
                        <ModalBody>
                            <AdvancedFilter />
                        </ModalBody>
                        <ModalFooter>
                            <Flexbox gap={2} justifyContent="space-between">
                                <Button onClick={resetFilter} variant="outlined" color="blue">
                                    {t("tableFilters.reset")}
                                </Button>
                                <Button onClick={closeModal} variant="contained" color="blue">
                                    {t("common.close")}
                                </Button>
                            </Flexbox>
                        </ModalFooter>
                    </SimpleModalWindow>
                )}
            </>
        );
    };

    return form(name)(FilterForm);
};
