import { combineReducers } from "redux-immutable";

import { app } from "core/util";

import { CLEAR, SET_ERROR, SET_LOGIN_PAGE_STEP } from "./actions";
import { LOGIN_PAGE_STEP } from "./constants";
import { ResetPassword } from "./modules";

export default combineReducers({
    appState: combineReducers({
        loginPageStep: app.createDataReducer(SET_LOGIN_PAGE_STEP, LOGIN_PAGE_STEP.LOGIN_FORM, CLEAR),
        resetPassword: ResetPassword.reducer,
        error: app.createDataReducer(SET_ERROR),
    }),
});
