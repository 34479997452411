import { call, put, select } from "redux-saga/effects";
import { List } from "immutable";

import { asyncFieldValidator, asyncValidateFields, required, formWrapper, scrollToFirstError } from "core/form";
import errorHandling from "core/errorHandling";
import { sentry } from "core/util";
import userInfo from "core/userInfo";
import { validationApi } from "serverApi";

export function* createSaga(formName) {
    try {
        yield call(formSaga(formName));
    } catch (e) {
        sentry.captureException(e);
        yield put(errorHandling.addServiceError(formName, e.identifier));
    }
}

const formSaga = (formName) =>
    formWrapper(formName, {
        *initialize() {
            const client = yield select(userInfo.getClient);
            return {
                files: List(),
                phone: client.phone,
                actualStep: 0,
            };
        },
        *onSubmitFail() {
            yield call(scrollToFirstError);
        },
        *save(values) {
            yield call(userInfo.verify, values);
        },
        *asyncValidation(values, field) {
            return yield call(asyncValidateFields(asyncFieldValidator(["phone"], [required, validationApi.validateMobile])), field, values);
        },
    });
