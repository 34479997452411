import React from "react";

import { Typography } from "ui-library/atoms";
import { FormBlock } from "ui-library/modules";

import i18n from "core/i18n";

export const PisDisclaimerFormBlock = () => {
    const { t } = i18n.useTranslation();

    return (
        <FormBlock mb={4}>
            <Typography component="p" markDown={t("pension.fundsChange.pisDisclaimer")} />
        </FormBlock>
    );
};
