import { model } from "core/util";
import {
    ElectronicCommunicationCampaignApiResponse as ElectronicCommunicationCampaignApiResponseType,
    ElectronicCommunicationCampaignApiResponseDto,
} from "types";

import { ElectronicCommunicationCampaign } from "./ElectronicCommunicationCampaign";

export const ElectronicCommunicationCampaignApiResponse = model.createModel<
    ElectronicCommunicationCampaignApiResponseDto,
    ElectronicCommunicationCampaignApiResponseType
>((json) => ({
    result: json.result,
    data: ElectronicCommunicationCampaign.fromServer(json.data),
}));
