import { convertFromPeriodicity } from "enums";

import createModel from "../createModel";
import { AmountLegacy, CodeValueLegacy } from "../common";
import { MutualFundsAccountPaymentDetailLegacy } from "./MutualFundsAccountPaymentDetailLegacy";

export const MutualFundsFinancialRequest = createModel("MutualFundsFinancialRequest", (json) => ({
    id: json.Id,
    creationDate: json.CreationDate,
    sellScope: json.SellScope,
    transactionType: CodeValueLegacy.fromServer(json.TransactionType),
    periodicityType: json.PeriodicityType,
    // convert periodicity to frequency
    frequency: CodeValueLegacy.fromServer(convertFromPeriodicity(json.Frequency)),
    transactionFundFrom: CodeValueLegacy.fromServer(json.TransactionFundFrom),
    firstTransactionDate: json.FirstTransactionDate,
    lastTransactionDate: json.LastTransactionDate,
    transactionValueType: CodeValueLegacy.fromServer(json.TransactionValueType),
    transactionValue: AmountLegacy.fromServer(json.TransactionValue),
    numberOfSharesInTransaction: json.NumberOfSharesInTransaction,
    arbesTaType: json.ArbesTaType,
    transactionStatus: CodeValueLegacy.fromServer(json.TransactionStatus),
    transactionTo: MutualFundsAccountPaymentDetailLegacy.fromServer(json.TransactionTo),
}));
