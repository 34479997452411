import React from "react";
import PropTypes from "prop-types";
import { StyledYoutubeEmbed } from "ui-library/Styled";

export const YoutubeEmbed = ({ maxWidth, shadow, embedId, ...props }) => (
    <StyledYoutubeEmbed $maxWidth={maxWidth} $shadow={shadow} {...props}>
        <iframe
            src={`https://www.youtube.com/embed/${embedId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
        />
    </StyledYoutubeEmbed>
);

YoutubeEmbed.propTypes = {
    embedId: PropTypes.string.isRequired,
    shadow: PropTypes.bool,
    maxWidth: PropTypes.number,
};

YoutubeEmbed.defaultProps = {
    shadow: true,
};
