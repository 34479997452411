import { call, delay, put } from "redux-saga/effects";
import { List } from "immutable";

import fetching from "core/fetching";
import { sentry } from "core/util";
import slice from "./slice";

const beingLoaded = {};

export function* load(entity: string, loader) {
    if (!beingLoaded[entity]) {
        beingLoaded[entity] = true;
        try {
            // TODO: remove error when loading.
            yield put(fetching.startImmediate(entity));
            const result = yield call(loader);
            yield put(slice.actions.setData(entity, result));
        } catch (e) {
            sentry.captureException(e);
            // TODO: 401 Handling in response.
            // if (e instanceof fetch.NotAuthenticatedError) {
            //     yield put(auth.logOut());
            // }
            yield put(slice.actions.setError(entity));
        } finally {
            yield put(fetching.stop(entity));
            delete beingLoaded[entity];
        }
    }
}

export function* loadIntoMap(entity: string, id: string, loader) {
    // We are intentionally using custom name for being loaded and then entity for fetching and error.
    const entityName = `${entity}::${id}`;
    if (!beingLoaded[entityName]) {
        beingLoaded[entityName] = true;
        try {
            yield put(fetching.startImmediate(entity));
            yield put(fetching.startImmediate(entityName));
            const result = yield call(loader);
            yield put(slice.actions.setDataIntoMap(entity, id, result));
        } catch (e) {
            sentry.captureException(e);
            // TODO: 401 Handling in response.
            // if (e instanceof fetch.NotAuthenticatedError) {
            //     yield put(auth.logOut());
            // }
            yield put(slice.actions.setError(entity));
            yield put(slice.actions.setError(entityName));
        } finally {
            yield put(fetching.stop(entity));
            yield put(fetching.stop(entityName));
            delete beingLoaded[entityName];
        }
    }
}

export function* periodicallyCheckDocuments(initialTimeout: number, entity: string, loader) {
    let timeout = initialTimeout;

    while (true) {
        const contractEnqueuedDocuments = yield call(loader);
        yield put(slice.actions.setData(entity, contractEnqueuedDocuments));
        if (!List.isList(contractEnqueuedDocuments)) {
            break;
        }
        // @ts-ignore
        const isSomeEnqueued = contractEnqueuedDocuments.some((item) => item.status.code === "ESB_ENQUEUED");
        if (!isSomeEnqueued) {
            break;
        }
        timeout *= 2;
        yield delay(timeout);
    }
}

export function* periodicallyCheckClaims(timeout: number, entity: string, loader) {
    while (true) {
        yield delay(timeout);
        try {
            const claims = yield call(loader);
            yield put(slice.actions.setData(entity, claims));
        } catch (e) {
            sentry.captureException(e);
            yield put(slice.actions.setError(entity));
        }
    }
}
