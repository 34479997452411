import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { DataTable } from "ui-library/modules";
import { Button, StandaloneHtmlLink, StandaloneLink, Typography } from "ui-library/atoms";
import { Box, ButtonsLayout, GridItem } from "ui-library/layouts";

import { dateTimeToLocalFullDate, localAmountWithCurrencyLegacy, nettoProfitHyperLinkFormatter } from "core/formatters";
import i18n, { TFunction } from "core/i18n";
import router from "core/router";
import { useHasEditContractPermission } from "core/hooks";
import userContracts from "core/userContracts";
import { Pages, Params, Tabs } from "routeConstants";

import { canChangeInvestmentProfile, getActualValueOfInvestment, getFinancialStatementEntity } from "../selectors";

const createColumns = (t: TFunction, getProfitDetailUrl: (fund: string) => string, idObject: number, isInFutureOrTerminated: boolean) => [
    {
        headerName: t("investments.investmentProfile"),
        field: "investmentProfile",
        minWidth: 240,
        sortable: false,
        renderCell: (params) => (
            <StandaloneHtmlLink color="blue" href={params.value.get("href")} target={"_blank"}>
                {params.value.get("text")}
            </StandaloneHtmlLink>
        ),
    },
    {
        headerName: t("investments.depositedFunds"),
        field: "depositedFunds",
        align: "right",
        headerAlign: "right",
        minWidth: 140,
        renderCell: (params) => localAmountWithCurrencyLegacy(params.value),
    },
    {
        headerName: t("investments.paidEntryFee"),
        field: "paidEntryFee",
        align: "right",
        headerAlign: "right",
        minWidth: 140,
        renderCell: (params) => localAmountWithCurrencyLegacy(params.value),
    },
    {
        headerName: t("investments.investedAmount"),
        field: "investedAmount",
        align: "right",
        headerAlign: "right",
        minWidth: 140,
        renderCell: (params) => localAmountWithCurrencyLegacy(params.value),
    },
    {
        headerName: t("investments.actualInvestmentsValue"),
        field: "actualValueOfInvestments",
        align: "right",
        headerAlign: "right",
        minWidth: 140,
        renderCell: (params) => localAmountWithCurrencyLegacy(params.value),
    },
    {
        headerName: t("common.profit"),
        field: "profit",
        align: "right",
        headerAlign: "right",
        minWidth: 110,
        renderCell: (params) => nettoProfitHyperLinkFormatter(params.value, getProfitDetailUrl),
    },
    {
        headerName: t("common.payment"),
        field: "payment",
        align: "right",
        minWidth: 120,
        headerAlign: "right",
        renderCell: () =>
            !isInFutureOrTerminated && (
                <StandaloneLink color="blue" to={getPaymentUrl(idObject)}>
                    {t("common.invest")}
                </StandaloneLink>
            ),
    },
];

const getPaymentUrl = (contractId: number) =>
    router.getStaticUrl(Pages.ONLINE_PAYMENT, Tabs.PAYMENTS_PAY, {}, { [Params.PAYMENT_CONTRACT_ID]: contractId });

export const ActualInvestmentValueTable: FC = () => {
    const { t } = i18n.useTranslation();
    const data = useSelector(getActualValueOfInvestment);

    const { idObject } = useParams();
    const navigate = useNavigate();
    const financialStatementDate = useSelector(getFinancialStatementEntity)?.statementDate;
    const hasEditPermission = useHasEditContractPermission();
    const contract = userContracts.useGetContractById(idObject);
    const { isInFutureOrTerminated } = userContracts.useGetContractDerivedData(contract);

    const canChangeProfile = hasEditPermission && contract.canEdit && useSelector(canChangeInvestmentProfile);

    const onInvestmentProfileChange = () =>
        navigate(router.getStaticUrl(Pages.CONTRACT_REGULAR, Tabs.INVESTMENT_PROFILE_FORM, { idObject }));
    const getProfitDetailUrl = (fund: string) =>
        router.getStaticUrl(Pages.CONTRACT_REGULAR, Tabs.REGULAR_PROFIT_DETAIL, { idObject, fund });

    return (
        <GridItem xs={12}>
            <DataTable
                icon={"coins"}
                title={t("investments.actualInvestmentsValue")}
                headerHeight={60}
                hideFooter
                disableColumnReorder
                pageSize={20}
                getRowId={(row) => row.id}
                rows={data ? data.toArray() : []}
                columns={createColumns(t, getProfitDetailUrl, Number(idObject), isInFutureOrTerminated)}
                preContent={
                    canChangeProfile && (
                        <ButtonsLayout mb={4}>
                            <Button color="orange" variant="contained" onClick={onInvestmentProfileChange}>
                                {t("investments.investmentProfileChange")}
                            </Button>
                        </ButtonsLayout>
                    )
                }
                actions={
                    financialStatementDate ? (
                        <Box mr={2}>
                            <Typography component="p" color="gray">
                                {t("statements.dateTime", { statementDate: dateTimeToLocalFullDate(financialStatementDate) })}
                            </Typography>
                        </Box>
                    ) : null
                }
            />
        </GridItem>
    );
};
