import createActions from "./actions";
import createContainer from "./Container";
import createSaga from "./saga";
import createSelectors from "./selectors";
import createReducer from "./reducer";
import { createFormConstants, EPIN_PHASE_ISSUE } from "./constants";

export default ({ reducerPath, actionPrefix, formPrefix }) => {
    const formConstants = createFormConstants(formPrefix);
    const actions = createActions(actionPrefix);
    const selectors = createSelectors(reducerPath);
    const Container = createContainer(formConstants, selectors, actions);
    const saga = createSaga(formConstants, actions, selectors);
    const reducer = createReducer(actions);

    return {
        EPIN_PHASE_ISSUE,
        setEpinPhase: actions.setEpinPhase,
        cleanEpinIssueError: actions.cleanEpinIssueError,
        saga,
        Container,
        reducer,
    };
};
