import { createSelector } from "reselect";
import i18n from "core/i18n";
import { fn } from "core/util";
import { PaymentAmountType, ProductPaymentAmountName } from "enums";
import { Map, List } from "immutable";
import { localAmountWithCurrencyLegacy } from "core/formatters";

const getTranslatedLabels = i18n.createGetLocalizedEnum(ProductPaymentAmountName, "amountMsg", "debtAmountMsg");

const addOptionIfNonEmpty = (options, key, label, value) => {
    if (fn.isEmpty(value) || fn.isEmpty(value?.value)) {
        return;
    }

    options.push(
        Map({
            value: key,
            label: `${label}: ${localAmountWithCurrencyLegacy(value)}`,
        }),
    );
};

export const getContractPaymentOptions = (paymentInfo) =>
    createSelector(
        getTranslatedLabels,
        (state) => i18n.translateDirectly("payments.amountCombined.dropdown"),
        (translatedLabelEnum, combinedAmountLabelTranslated) => {
            const productGroupName = paymentInfo?.get("productGroupName");

            const paymentProductDefinition = translatedLabelEnum.filter((item) => item.get("id") === productGroupName).first();

            if (!paymentProductDefinition) {
                return new List();
            }

            const options = [];
            addOptionIfNonEmpty(options, PaymentAmountType.DEBT, paymentProductDefinition.debtAmountMsg, paymentInfo?.get("debtAmount"));
            addOptionIfNonEmpty(
                options,
                PaymentAmountType.ADVANCED,
                paymentProductDefinition.amountMsg,
                paymentInfo?.get("advancedSchedulerAmount"),
            );
            addOptionIfNonEmpty(options, PaymentAmountType.COMBINED, combinedAmountLabelTranslated, paymentInfo?.get("combinedAmount"));

            return new List(options);
        },
    );
