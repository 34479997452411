import { Record } from "immutable";

class OtpFormSteps extends Record({ id: null, stepNum: null }) {}

export default Object.freeze({
    EDIT_FORM: new OtpFormSteps({
        id: "EDIT_FORM",
        stepNum: 0,
    }),
    OTP_CONFIRMATION: new OtpFormSteps({
        id: "OTP_CONFIRMATION",
        stepNum: 1,
    }),
    RESULT_NOTIFICATION: new OtpFormSteps({
        id: "RESULT_NOTIFICATION",
        stepNum: 2,
    }),
});
