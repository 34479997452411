import React from "react";
import { useSelector } from "react-redux";

import { BubbleAccordion, Button, Loading, Typography } from "ui-library/atoms";
import { ButtonsLayout, ContentBox, GridItem } from "ui-library/layouts";
import { InfoPanel } from "ui-library/modules";

import i18n from "core/i18n";
import userContracts from "core/userContracts";
import fetching from "core/fetching";
import entity from "core/entity";
import pageContext from "core/pageContext";
import { useGetClaimReportingUrls, useGetSiteValue } from "core/hooks";

import { CallCenterContactData, ClaimReportCategory } from "enums";
import { ContractSelect } from "./ContractSelect";
import { ContractSummary } from "./ContractSummary";
import { HomeLineOfBusinessSelect } from "./HomeLineOfBusinessSelect";
import { createGetCompliantLineOfBusinessClasses, getSelectedContractId, getSelectedLineOfBusinessClass } from "../selectors";
import { ENTITY_CONTRACT_OVERVIEW, SECTION_TO_SCROLL_VIEW_CLASS } from "../constants";

const getFaqSkData = (t) => [
    {
        title: t("page.reportClaim.home.faq.titleOne"),
        children: t("page.reportClaim.home.faq.answerOne"),
    },
    {
        title: t("page.reportClaim.home.faq.titleTwo"),
        children: t("page.reportClaim.home.faq.answerTwo"),
    },
    {
        title: t("page.reportClaim.home.faq.titleThree"),
        children: t("page.reportClaim.home.faq.answerThree"),
    },
    {
        title: t("page.reportClaim.home.faq.titleFour"),
        children: t("page.reportClaim.home.faq.answerFour"),
    },
    {
        title: t("page.reportClaim.home.faq.titleFive"),
        children: t("page.reportClaim.home.faq.answerFive"),
    },
    {
        title: t("page.reportClaim.home.faq.titleSix"),
        children: t("page.reportClaim.home.faq.answerSix"),
    },
    {
        title: t("page.reportClaim.home.faq.titleSeven"),
        children: t("page.reportClaim.home.faq.answerSeven"),
    },
    {
        title: t("page.reportClaim.home.faq.titleEight"),
        children: t("page.reportClaim.home.faq.answerEight"),
    },
    {
        title: t("page.reportClaim.home.faq.titleNine"),
        children: t("page.reportClaim.home.faq.answerNine"),
    },
    {
        title: t("page.reportClaim.home.faq.titleTen"),
        children: t("page.reportClaim.home.faq.answerTen"),
    },
    {
        title: t("page.reportClaim.home.faq.titleEleven"),
        children: t("page.reportClaim.home.faq.answerEleven"),
    },
    {
        title: t("page.reportClaim.home.faq.titleTwelve"),
        children: t("page.reportClaim.home.faq.answerTwelve"),
    },
];

const getFaqCzData = (t) => [
    {
        title: t("page.reportClaim.home.faq.titleOne"),
        children: t("page.reportClaim.home.faq.answerOne"),
    },
    {
        title: t("page.reportClaim.home.faq.titleTwo"),
        children: t("page.reportClaim.home.faq.answerTwo"),
    },
    {
        title: t("page.reportClaim.home.faq.titleThree"),
        children: t("page.reportClaim.home.faq.answerThree"),
    },
    {
        title: t("page.reportClaim.home.faq.titleFour"),
        children: t("page.reportClaim.home.faq.answerFour"),
    },
    {
        title: t("page.reportClaim.home.faq.titleFive"),
        children: t("page.reportClaim.home.faq.answerFive"),
    },
    {
        title: t("page.reportClaim.home.faq.titleSix"),
        children: t("page.reportClaim.home.faq.answerSix"),
    },
    {
        title: t("page.reportClaim.home.faq.titleSeven"),
        children: t("page.reportClaim.home.faq.answerSeven"),
    },
    {
        title: t("page.reportClaim.home.faq.titleEight"),
        children: t("page.reportClaim.home.faq.answerEight"),
    },
    {
        title: t("page.reportClaim.home.faq.titleNine"),
        children: t("page.reportClaim.home.faq.answerNine"),
    },
    {
        title: t("page.reportClaim.home.faq.titleTen"),
        children: t("page.reportClaim.home.faq.answerTen"),
    },
    {
        title: t("page.reportClaim.home.faq.titleEleven"),
        children: t("page.reportClaim.home.faq.answerEleven"),
    },
];

export const ReportHomeSection = () => {
    const { t } = i18n.useTranslation();
    const { isSlovakSite } = pageContext.useGetPageLocalization();
    const selectedContractId = useSelector(getSelectedContractId);
    const selectedLineOfBusinessClass = useSelector(getSelectedLineOfBusinessClass);
    const compliantHomeClaimClasses = useSelector(createGetCompliantLineOfBusinessClasses(selectedContractId));
    const redirectUrlExternalSpecific = useSelector(
        userContracts.createGetFinalRedirectUrl(selectedContractId, selectedLineOfBusinessClass),
    );
    const isContractOverviewLoading = useSelector(fetching.isFetching(ENTITY_CONTRACT_OVERVIEW, false));
    const isError = useSelector(entity.isError(ENTITY_CONTRACT_OVERVIEW));
    const claimReportingUrls = useGetClaimReportingUrls();
    const getSiteValue = useGetSiteValue();

    return (
        <>
            <GridItem xs={12}>
                <div className={SECTION_TO_SCROLL_VIEW_CLASS}>
                    <InfoPanel
                        icon={"bullhorn"}
                        title={`${t("page.reportClaim.home")}`}
                        subTitleSection={
                            <ContentBox bigger>
                                <Typography markDown={t("page.reportClaim.homeIncludes")} />
                            </ContentBox>
                        }
                    >
                        <ContentBox>
                            <ContractSelect claimReportCategoryId={ClaimReportCategory.HOME_AND_LIABILITY.id} />
                        </ContentBox>
                        {selectedContractId && <ContractSummary selectedContractId={selectedContractId} />}
                        <Loading loading={isContractOverviewLoading}>
                            {!isError && selectedContractId && !compliantHomeClaimClasses.isEmpty() && (
                                <ContentBox>
                                    <HomeLineOfBusinessSelect compliantHomeClaimClasses={compliantHomeClaimClasses} />
                                </ContentBox>
                            )}
                            <ContentBox bigger mb={4}>
                                <ButtonsLayout>
                                    <Button
                                        color="blue"
                                        variant="contained"
                                        endIcon={"computer"}
                                        externalLink={
                                            compliantHomeClaimClasses.isEmpty() ? claimReportingUrls.home : redirectUrlExternalSpecific
                                        }
                                        disabled={
                                            (!selectedLineOfBusinessClass && !compliantHomeClaimClasses.isEmpty()) ||
                                            isContractOverviewLoading
                                        }
                                        newTab
                                    >
                                        {t("common.reportOnline")}
                                    </Button>

                                    <Button
                                        color="blue"
                                        variant="outlined"
                                        endIcon={"phone"}
                                        externalLink={`tel:${getSiteValue(CallCenterContactData.PHONE_AS_LINK)}`}
                                    >
                                        {getSiteValue(CallCenterContactData.PHONE)}
                                    </Button>
                                </ButtonsLayout>
                            </ContentBox>
                        </Loading>

                        <ContentBox>
                            <Typography variant="h4" markDown={t("page.reportClaim.titleWhatToPrepare")} />
                        </ContentBox>

                        <ContentBox bigger>
                            <Typography listVariant="check" markDown={t("page.reportClaim.home.whatToPrepare")} />
                        </ContentBox>
                    </InfoPanel>
                </div>
            </GridItem>

            <GridItem xs={12}>
                <ContentBox>
                    <Typography variant={"h3"}>{t("page.reportClaim.faqTitle")}</Typography>
                </ContentBox>
                <BubbleAccordion data={isSlovakSite ? getFaqSkData(t) : getFaqCzData(t)} id="faqAccordion" />
            </GridItem>
        </>
    );
};
