import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { InfoPanel, InfoText } from "ui-library/modules";
import { Box, ContentBox } from "ui-library/layouts";
import { Button, Typography } from "ui-library/atoms";

import i18n from "core/i18n";
import router from "core/router";
import { useHasEditContractPermission } from "core/hooks";
import userInfo from "core/userInfo";
import { dateTimeToLocalFullDate, localPhone } from "core/formatters";
import { UserVerificationStatus } from "enums";
import { Pages, Tabs } from "routeConstants";

export const UserVerification = () => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();

    const isVerified = useSelector(userInfo.getIsVerifiedUser);
    const requestDate = dateTimeToLocalFullDate(useSelector(userInfo.getVerificationRequestDate));
    const confirmDate = dateTimeToLocalFullDate(useSelector(userInfo.getVerificationConfirmDate));
    const verificationDenyReason = useSelector(userInfo.getVerificationDenyReason);
    const verificationDenyReasonComment = useSelector(userInfo.getVerificationDenyReasonComment);
    const phone = localPhone(useSelector(userInfo.getPhone));
    const isClientPerson = useSelector(userInfo.isClientPerson);
    const hasEditPermission = useHasEditContractPermission();

    const getUnverifiedTextMsg = (isClientPerson) =>
        isClientPerson ? UserVerificationStatus.UNVERIFIED.text : `${UserVerificationStatus.UNVERIFIED.text}PO`;

    let verificationStatus = UserVerificationStatus.UNVERIFIED.id;
    if (isVerified) {
        verificationStatus = UserVerificationStatus.VERIFIED.id;
    } else if (requestDate) {
        verificationStatus = UserVerificationStatus.VERIFICATION_IN_PROGRESS.id;
    }

    return (
        <InfoPanel
            actionButton={
                hasEditPermission &&
                verificationStatus === UserVerificationStatus.UNVERIFIED.id && (
                    <Box mt={3}>
                        <Button
                            onClick={() => dispatch(router.navigate(Pages.USER, Tabs.USER_VERIFICATION_FORM, {}, {}, false))}
                            variant="contained"
                            color="blue"
                        >
                            {t("common.verify")}
                        </Button>
                    </Box>
                )
            }
            icon="user-check"
            title={t("holder.verification")}
        >
            {verificationStatus === UserVerificationStatus.UNVERIFIED.id && (
                <>
                    <ContentBox>
                        <InfoText icon="alert" text={t("holder.unverified")} color="orange" />
                    </ContentBox>
                    <Box mb={1}>
                        <Typography markDown={t(getUnverifiedTextMsg(isClientPerson))} />
                    </Box>
                    <Box>
                        {!isVerified && confirmDate && (
                            <ContentBox mt={2}>
                                <Typography
                                    markDown={`${t("userSettings.verifyUser.verificationDenyReasonText", {
                                        confirmDate,
                                        verificationDenyReason,
                                        verificationDenyReasonComment,
                                    })}`}
                                />
                            </ContentBox>
                        )}
                    </Box>
                </>
            )}

            {verificationStatus === UserVerificationStatus.VERIFICATION_IN_PROGRESS.id && (
                <>
                    <Box mt={3} mb={3}>
                        <InfoText icon="horizontal-dots" text={t("holder.verificationInProgress")} color="blue" />
                    </Box>
                    <Box>
                        <Typography markDown={t(UserVerificationStatus.VERIFICATION_IN_PROGRESS.text, { requestDate })} />
                    </Box>
                </>
            )}

            {verificationStatus === UserVerificationStatus.VERIFIED.id && (
                <>
                    <Box mt={3} mb={3}>
                        <InfoText icon="check" text={t("holder.verified")} color="success" />
                    </Box>
                    <ContentBox>
                        <Typography markDown={t(UserVerificationStatus.VERIFIED.text, { requestDate, confirmDate, phone })} />
                    </ContentBox>
                </>
            )}
        </InfoPanel>
    );
};
