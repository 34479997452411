import { Icon, Image, Typography } from "ui-library/atoms";
import { Flexbox } from "ui-library/layouts";
import PropTypes from "prop-types";
import React from "react";
import { StyledAcknowledgeBlock } from "ui-library/Styled";

export const AcknowledgeBlock = ({ title, imgSrc }) => {
    return (
        <StyledAcknowledgeBlock>
            <Flexbox mb={3} gap={2} alignItems={"center"}>
                <Icon color="green" size={16} icon="circle-check" />
                <Typography variant="p">{title}</Typography>
            </Flexbox>
            <Flexbox justifyContent={"center"}>
                <Image shadow={false} alt={title} src={imgSrc} />
            </Flexbox>
        </StyledAcknowledgeBlock>
    );
};

AcknowledgeBlock.propTypes = {
    title: PropTypes.string.isRequired,
    imgSrc: PropTypes.string.isRequired,
};
