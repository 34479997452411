import { withTheme } from "@mui/styles";
import styled from "styled-components";
import { Box } from "@mui/material";

export const StyledLabelTextItem = withTheme(styled(Box)`
    display: ${(props) => (props.$fullWidth ? "flex" : "table-row")};
    justify-content: space-between;
    border-bottom: ${(props) => (props.$fullWidth ? "1px solid" + props.theme.palette.gray.line : "none")};
    padding: ${(props) => (props.$fullWidth ? "8px 32px 8px 0" : 0)};

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: block;
        position: relative;
    }
`);

export const StyledLabelTextItemCell = withTheme(styled(Box)`
    display: table-cell;
    max-width: 80%;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: block;
    }
    svg {
        &:hover {
            cursor: pointer;
        }
    }
`);
