import { Record } from "immutable";

const ChangePropositionPensionType = Record({ id: null, msg: null, changeMsg: null });

export default Object.freeze({
    PENSION_FUNDS_CHANGE: new ChangePropositionPensionType({
        id: "PENSION_FUNDS_CHANGE",
        msg: "changeProposition.pensionFundsChange",
    }),
    PENSION_INVESTMENT_STRATEGIES_CHANGE: new ChangePropositionPensionType({
        id: "PENSION_INVESTMENT_STRATEGIES_CHANGE",
        msg: "changeProposition.pensionInvestmentStrategy",
    }),
});
