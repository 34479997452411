import React from "react";
import PropTypes from "prop-types";

import { ModalBody, ModalFooter } from "ui-library/modules";
import { Box, ContentBox } from "ui-library/layouts";
import { Button, Loading, Typography } from "ui-library/atoms";

import { Field, form, InputPassword, meetPasswordCriteria, required } from "core/form";
import i18n from "core/i18n";
import { ModalFormLayout } from "containers/modal";
import { PasswordModalSuccess } from "./PasswordModalSuccess";

export const ModalPasswordChange = ({ canSubmit, handleSubmit, submitting }) => {
    const { t } = i18n.useTranslation();

    const passwordsAreEqual = (value, values) => {
        const newPassword = values.get("newPassword");
        const verificationPassword = values.get("verificationPassword");
        return newPassword === verificationPassword ? undefined : t("error.password.notEquals");
    };

    return (
        <>
            <ModalBody>
                <Loading loading={submitting}>
                    <Box>
                        <>
                            <ContentBox>
                                <Typography variant="caption" markDown={t("general.text.passwordChange")} />
                            </ContentBox>
                            <>
                                <ContentBox>
                                    <Field
                                        name="currentPassword"
                                        component={InputPassword}
                                        label={t("holder.currentPassword")}
                                        validateStatic={[required]}
                                    />
                                </ContentBox>
                                <ContentBox>
                                    <Field
                                        name="newPassword"
                                        component={InputPassword}
                                        label={t("holder.newPassword")}
                                        validateStatic={[required, meetPasswordCriteria]}
                                    />
                                </ContentBox>
                                <ContentBox>
                                    <Field
                                        name="verificationPassword"
                                        component={InputPassword}
                                        label={t("holder.verificationPassword")}
                                        validateStatic={[required, passwordsAreEqual]}
                                    />
                                </ContentBox>
                            </>
                        </>
                    </Box>
                </Loading>
            </ModalBody>
            <ModalFooter>
                <Button color="blue" variant="contained" onClick={handleSubmit} disabled={!canSubmit}>
                    {t("common.submitChange")}
                </Button>
            </ModalFooter>
        </>
    );
};

ModalPasswordChange.propTypes = {
    canSubmit: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
};

export const createContainer = (formName) =>
    form(formName)((props) => {
        const { t } = i18n.useTranslation();
        return (
            <ModalFormLayout
                formName={formName}
                formTitle={t("form.passwordChange")}
                formIcon="document"
                SuccessComponent={PasswordModalSuccess}
            >
                <ModalPasswordChange {...props} />
            </ModalFormLayout>
        );
    });
