import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { Option, PaymentAmountTypeOption, Select } from "ui-library/atoms";

import router from "core/router";
import { Pages, Params, Tabs } from "routeConstants";
import i18n from "core/i18n";

import { getAmountOptions, getAmountType, getContractId } from "../selectors";

export const AmountTypeSelect = () => {
    const { t } = i18n.useTranslation();
    const amountOptions = useSelector(getAmountOptions);
    const amountType = useSelector(getAmountType);
    const selectedContractId = useSelector(getContractId);
    const dispatch = useDispatch();

    // TODO fix args order after rebase
    const doTransition = (contractId, amountType) =>
        router.navigate(
            Pages.ONLINE_PAYMENT,
            Tabs.PAYMENTS_PAY,
            {},
            {
                [Params.PAYMENT_CONTRACT_ID]: contractId,
                [Params.PAYMENT_AMOUNT_TYPE]: amountType,
            },
            true,
        );

    const amountTypeChange = (amountType) => doTransition(selectedContractId, amountType);

    return (
        <Select
            value={amountType || ""}
            onChange={(event) => dispatch(amountTypeChange(event.target.value))}
            placeholder={t("payments.amountPlaceholder")}
            label="   "
            id="selectAmountType"
            fullWidth
            sx={{ marginTop: { xs: "16px" } }}
        >
            {amountOptions &&
                amountOptions.map((option) => (
                    <Option value={option.get("value")}>
                        <PaymentAmountTypeOption label={option.get("label")} />
                    </Option>
                ))}
        </Select>
    );
};
