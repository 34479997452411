import React from "react";
import PropTypes from "prop-types";

import { ModalBody, ModalFooter } from "ui-library/modules";
import { Box, ContentBox } from "ui-library/layouts";
import { Button, Loading, Typography } from "ui-library/atoms";

import { ModalFormLayout } from "containers/modal";
import { email, emailCharacters, Field, form, InputPassword, InputText, max50Characters, required } from "core/form";
import i18n from "core/i18n";
import { UsernameModalSuccess } from "./UsernameModalSuccess";

const ContractNameForm = ({ canSubmit, handleSubmit, submitting }) => {
    const { t } = i18n.useTranslation();
    return (
        <>
            <ModalBody>
                <Loading loading={submitting}>
                    <ContentBox>
                        <Typography variant="caption" markDown={t("changeUsername.description")} />
                    </ContentBox>
                    <Box>
                        <ContentBox>
                            <Field
                                name="username"
                                component={InputText}
                                label={t("holder.emailAddress")}
                                validateStatic={[required, email]}
                                normalize={[emailCharacters, max50Characters]}
                                preventDefaultNormalization
                            />
                        </ContentBox>
                        <ContentBox>
                            <Field name="password" component={InputPassword} label={t("holder.userPassword")} validateStatic={[required]} />
                        </ContentBox>
                    </Box>
                </Loading>
            </ModalBody>
            <ModalFooter>
                <Button color="blue" variant="contained" onClick={handleSubmit} disabled={!canSubmit}>
                    {t("common.submitChange")}
                </Button>
            </ModalFooter>
        </>
    );
};

ContractNameForm.propTypes = {
    canSubmit: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
};

export const createContainer = (formName) =>
    form(formName)((props) => {
        const { t } = i18n.useTranslation();
        return (
            <ModalFormLayout
                formName={formName}
                formTitle={t("form.changeUserName")}
                formIcon="document"
                SuccessComponent={UsernameModalSuccess}
            >
                <ContractNameForm {...props} />
            </ModalFormLayout>
        );
    });
