import { app } from "core/util";

import { NAME } from "./constants";

const getModel = app.createGetModel(NAME);
const getData = (state) => getModel(state).get("data");
const getAppState = (state) => getModel(state).get("appState");

export const getClientDocuments = (state) => getData(state).get("clientDocuments");
export const getDisplayedContentType = (state) => getAppState(state).get("displayedContentType");
export const getContractIdForPayment = (state) => getAppState(state).get("contractIdForPayment");
