import { model } from "core/util";
import { ClientStatementSettingsDto, MutualFundsClientStatementSettings as MutualFundsClientStatementSettingsType } from "types";

export const MutualFundsClientStatementSettings = model.createModel<ClientStatementSettingsDto, MutualFundsClientStatementSettingsType>(
    (json) => ({
        receiveBuyConfirmation: json.ReceiveBuyConfirmation,
        receiveSellConfirmation: json.ReceiveSellConfirmation,
        receiveStatement: json.ReceiveStatement,
        statementPeriodicity: json.StatementPeriodicity,
    }),
);
