import { v4 as uuid } from "uuid";

import { model } from "core/util";
import { RiskType } from "enums";

import { HolderLegacy } from "../holder";
import { AmountLegacy, SimpleCodeValue } from "../common";

import { Validity } from "./Validity";
import { Coverage } from "./Coverage";

export const Insured = model.createModelLegacy("Insured", (json) => ({
    reactKey: uuid(),
    riskType: RiskType.PERSON,
    insured: HolderLegacy.fromServer(json.Insured),
    identifier: json.Identifier,
    category: SimpleCodeValue.fromServer(json.Category),
    riskCode: json.RiskCode,
    insuranceCost: AmountLegacy.fromServer(json.InsuranceCost),
    validity: Validity.fromServer(json.Validity),
    coverages: Coverage.fromServerList(json.Coverages),
}));
