import { Record } from "immutable";

class LineOfBusinessClass extends Record({
    id: null,
    text: null,
    externalId: null,
}) {}

export default Object.freeze({
    MTPL: new LineOfBusinessClass({
        id: "MTPL",
        externalId: "MTPL_SKODCA",
        text: "page.reportClaim.mtpl",
    }),
    CASCO: new LineOfBusinessClass({
        id: "CASCO",
        externalId: "CASCO",
        text: "page.reportClaim.casco",
    }),
    GLASS: new LineOfBusinessClass({
        id: "GLASS",
        externalId: "GLASS",
        text: "page.reportClaim.glass",
    }),
    GLASS_CASCO: new LineOfBusinessClass({
        id: "GLASS_CASCO",
        externalId: "GLASS_CASCO",
        text: "page.reportClaim.glassCasco",
    }),
    TRAVEL: new LineOfBusinessClass({
        id: "TRAVEL",
        externalId: "TRAVEL",
    }),
    LIABILITY: new LineOfBusinessClass({
        id: "LIABILITY",
        externalId: "LIABILITY",
        text: "page.reportClaim.liability",
    }),
    PROPERTY: new LineOfBusinessClass({
        id: "PROPERTY",
        externalId: "PROPERTY",
        text: "page.reportClaim.property",
    }),
    HEALTH: new LineOfBusinessClass({
        id: "HEALTH",
    }),
    BANK: new LineOfBusinessClass({
        id: "BANK",
    }),
    LIFE: new LineOfBusinessClass({
        id: "LIFE",
        externalId: "LIFE",
    }),
    ACCIDENT: new LineOfBusinessClass({
        id: "ACCIDENT",
    }),
    LEGAL_PROTECTION: new LineOfBusinessClass({
        id: "LEGAL_PROTECTION",
    }),
});
