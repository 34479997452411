import { model } from "core/util";

import { AmountLegacy, CodeValueLegacy } from "../common";

export const MutualFundsAccountPaymentDetailLegacy = model.createModelLegacy("MutualFundsAccountPaymentDetail", (json) => ({
    fund: CodeValueLegacy.fromServer(json.Fund),
    iban: json.Iban,
    accountNumberPrefix: json.AccountNumberPrefix,
    accountNumber: json.AccountNumber,
    bankNumber: CodeValueLegacy.fromServer(json.BankNumber),
    bicCode: CodeValueLegacy.fromServer(json.BICCode),
    variableSymbol: json.VariableSymbol,
    specificSymbol: json.SpecificSymbol,
    transactionName: json.TransactionName,
    currencyCode: json.CurrencyCode,
    actualPricePerShare: AmountLegacy.fromServer(json.ActualPricePerShare),
}));
