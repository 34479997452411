import { NAME } from "./constants";
import reducer from "./reducer";
import saga from "./saga";
import innerRoutes from "./innerRoutes";
import { PayTab } from "./tabs";

export { default as PaymentPage } from "./Container";

export const PaymentsTabs = {
    PayTab,
};

export default {
    NAME,
    reducer,
    saga,
    innerRoutes,
};
