import { List, Record } from "immutable";

const PensionPpPensionPlanGroup = Record({ id: null, name: null, listOfPlans: new List(), documentLink: null });

export default Object.freeze({
    GROUP_1: new PensionPpPensionPlanGroup({
        id: "GROUP_1",
        name: "UNIQA I",
        listOfPlans: ["WPF I"],
        documentLink: "pension.planGroup1.documentUrl",
    }),
    GROUP_2: new PensionPpPensionPlanGroup({
        id: "GROUP_2",
        name: "UNIQA II",
        listOfPlans: ["BOPF I", "PF Energie I", "PF Energie II", "PF Everest I", "CR Fontána I", "WPF II"],
        documentLink: "pension.planGroup2.documentUrl",
    }),
    GROUP_3: new PensionPpPensionPlanGroup({
        id: "GROUP_3",
        name: "UNIQA III",
        listOfPlans: ["WPF III"],
        documentLink: "pension.planGroup3.documentUrl",
    }),
    GROUP_4: new PensionPpPensionPlanGroup({
        id: "GROUP_4",
        name: "UNIQA IV",
        listOfPlans: ["BHPF I", "BHPF II", "BHPF III", "PF VZP I", "PF VZP II", "PF VZP III", "CSLP IV"],
        documentLink: "pension.planGroup4.documentUrl",
    }),
    GROUP_5: new PensionPpPensionPlanGroup({
        id: "GROUP_5",
        name: "UNIQA V",
        listOfPlans: ["VOPF I", "VOPF II", "VOPF III", "VOPF IV", "VOPF V", "CSLP V"],
        documentLink: "pension.planGroup5.documentUrl",
    }),
    GROUP_6: new PensionPpPensionPlanGroup({
        id: "GROUP_6",
        name: "UNIQA VI",
        listOfPlans: ["CSLP VI"],
        documentLink: "pension.planGroup6.documentUrl",
    }),
    GROUP_7: new PensionPpPensionPlanGroup({
        id: "GROUP_7",
        name: "UNIQA VII",
        listOfPlans: ["WPF VII"],
        documentLink: "pension.planGroup7.documentUrl",
    }),
    GROUP_8: new PensionPpPensionPlanGroup({
        id: "GROUP_8",
        name: "UNIQA VIII",
        listOfPlans: ["AXA VIII"],
        documentLink: "pension.planGroup8.documentUrl",
    }),
    GROUP_9: new PensionPpPensionPlanGroup({
        id: "GROUP_9",
        name: "UNIQA IX",
        listOfPlans: ["AXA IX"],
        documentLink: "pension.planGroup9.documentUrl",
    }),
});
