import { API_PREFIX } from "app/constants";
import { fetch } from "core/util";
import { ElectronicCommunicationCampaignApiResponse } from "model/campaign";

const URL_PREFIX = `${API_PREFIX}/campaign`;

const getElectronicCommunicationData = (guid: string, idObject: number) =>
    fetch.doGet(`${URL_PREFIX}/electronic-communication/${guid}/${idObject}`, null, ElectronicCommunicationCampaignApiResponse.fromServer);

const setElectronicCommunication = (body) => fetch.doPut(`${URL_PREFIX}/electronic-communication`, body);

export default {
    getElectronicCommunicationData,
    setElectronicCommunication,
};
