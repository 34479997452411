import React, { FC } from "react";
import { useSelector } from "react-redux";

import i18n from "core/i18n";
import fetching from "core/fetching";
import { localFullDate } from "core/formatters";
import { documentsApi } from "serverApi";

import { Loading } from "ui-library/atoms";
import { DataTable } from "ui-library/modules";

import { FETCHING_CLIENT_DOCUMENTS } from "../constants";
import { getClientDocuments } from "../selectors";
import { DocumentLink } from "containers/DocumentLink";

const href = (idDocument: string) => documentsApi.getClientDocumentDownloadUrl(idDocument);

const Documents: FC = () => {
    const { t } = i18n.useTranslation();

    const isDocumentsFetching: boolean = useSelector(fetching.isFetching(FETCHING_CLIENT_DOCUMENTS));

    const rows = useSelector(getClientDocuments)
        .map((row) => row)
        .toArray();

    const columns = [
        {
            field: "typeLocalName",
            headerName: `${t("documents.documentName")}`,
            flex: 2.5,
            sortable: false,
            renderCell: (params) => {
                return <DocumentLink fileName={params.value} fileUrl={href(params.row.id)} />;
            },
        },
        {
            field: "status",
            headerName: `${t("common.type")}`,
            flex: 1,
            sortable: false,
            // TODO
            renderCell: (params) => "PDF",
        },
        {
            field: "date",
            headerName: `${t("common.date")}`,
            flex: 1,
            sortable: false,
            renderCell: (params) => localFullDate(params.value),
        },
    ];

    return (
        <Loading loading={isDocumentsFetching}>
            <DataTable
                disableColumnReorder
                getRowId={(row) => row.id}
                pageSize={10}
                rowsPerPageOptions={[5, 10, 15, 20]}
                rows={rows}
                columns={columns}
            />
        </Loading>
    );
};

export default Documents;
