import { fetch } from "core/util";
import { AccountInformation, AuthenticatedUser } from "model/user";
import { PersonalData } from "model/personalData";
import { API_PREFIX } from "app/constants";
import { List } from "immutable";

const CAPTCHA_FIELD_NAME = "g-recaptcha-response";

const getAccountInformation = () => fetch.doGet(`${API_PREFIX}/user/account-information`, null, AccountInformation.fromServer);
const getPersonalData = () => fetch.doGet(`${API_PREFIX}/rightToAccess`, null, PersonalData.fromServerList); // new List()
const getAuthenticatedUser = (noCache) => fetch.doGet(`${API_PREFIX}/user/authenticated-user`, { noCache }, AuthenticatedUser.fromServer);
const verify = (body) => fetch.doPost(`${API_PREFIX}/user/verify`, body);
const changeUsername = (body, recaptchaValue) =>
    fetch.doPut(`${API_PREFIX}/user/username`, body, undefined, undefined, { [CAPTCHA_FIELD_NAME]: recaptchaValue });

const changePassword = (body, recaptchaValue) =>
    fetch.doPut(`${API_PREFIX}/user/password`, body, undefined, undefined, { [CAPTCHA_FIELD_NAME]: recaptchaValue });

export default {
    getAccountInformation,
    getAuthenticatedUser,
    getPersonalData,
    verify,
    changeUsername,
    changePassword,
};
