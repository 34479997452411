import { NAME } from "./constants";

export const UNVERIFIED_BACK = `${NAME}/UNVERIFIED_BACK`;
export const CLEAR = `${NAME}/CLEAR`;
export const SET_PERSONAL_DATA = `${NAME}/SET_PERSONAL_DATA`;
export const SET_SELECTED_CONTRACT_GDPR_DATA = `${NAME}/SET_SELECTED_CONTRACT_GDPR_DATA`;

export const unverifiedBack = () => ({
    type: UNVERIFIED_BACK,
});

export const clear = () => ({
    type: CLEAR,
});

export const setPersonalData = (personalData) => ({
    type: SET_PERSONAL_DATA,
    payload: personalData,
});

export const setSelectedContractGdprData = (objectId) => ({
    type: SET_SELECTED_CONTRACT_GDPR_DATA,
    payload: objectId,
});
