import React, { FC, PropsWithChildren } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Modal, ModalHeader } from "ui-library/modules";

import modal from "core/modal";
import { ModalErrorBoundary } from "core/errorHandling";
import { ModalEditFormError } from "./ModalEditFormError";
import { ModalSuccess } from "./ModalSuccess";

export interface SimpleModalWindowProps extends PropsWithChildren {
    modalName: string;
    title: string;
    icon: string;
    SuccessComponent?: any;
    isSubmitting?: boolean;
    isSubmitted?: boolean;
    successTitle?: string;
    successText?: string;
}

export const SimpleModalWindow: FC<SimpleModalWindowProps> = ({
    children,
    modalName,
    title,
    icon,
    SuccessComponent = ModalSuccess,
    isSubmitting,
    isSubmitted,
    successTitle,
    successText,
}) => {
    const dispatch = useDispatch();

    const isModalFormOpen = useSelector(modal.isModalFormOpen(modalName));

    const closeModalForm = () => {
        if (!isSubmitting) {
            dispatch(modal.closeModalForm(modalName));
        }
    };

    return (
        <Modal size={"small"} open={isModalFormOpen} onClose={closeModalForm}>
            <ModalHeader closeAction={closeModalForm} icon={icon} title={title} />
            <ModalErrorBoundary ErrorComponent={ModalEditFormError} closeModal={closeModalForm} service={modalName}>
                {isSubmitted ? <SuccessComponent title={successTitle} message={successText} closeModal={closeModalForm} /> : children}
            </ModalErrorBoundary>
        </Modal>
    );
};
