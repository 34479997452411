import { localAmountWithCurrencyLegacy, localFullDate, negativeFormatter } from "core/formatters";
import { model } from "core/util";
import { Map } from "immutable";
import { FinancialMovementSimplified } from "types";

export const formatNonLifeRows = (rows): FinancialMovementSimplified[] =>
    rows.map((row) => ({
        id: row.id,
        date: row.get("movementDate"),
        amount: localAmountWithCurrencyLegacy(row.amount),
        description: localFullDate(row.movementDate),
    }));

export const formatAxaLifeRows = (rows, locale): FinancialMovementSimplified[] =>
    rows.map((row) => ({
        id: row.id,
        date: row.get("valueDate"),
        amount: localAmountWithCurrencyLegacy(row.grossAmount),
        description: model.getLocaleCodeName(row.type, locale),
    }));

export const formatUniqaLifeRows = (rows): FinancialMovementSimplified[] =>
    rows.map((row) => ({
        id: row.reactKey,
        date: row.get("movementDate"),
        amount: localAmountWithCurrencyLegacy(row.amount),
        description: localFullDate(row.movementDate),
    }));

export const formatMutualFundsRows = (rows, locale): FinancialMovementSimplified[] =>
    rows.map((row) => ({
        id: row.id,
        date: row.get("valueDate"),
        // @ts-ignore
        amount: negativeFormatter(new Map({ amount: row.payment, operationType: row.type.code })),
        description: model.getLocaleCodeName(row.type, locale),
    }));

export const formatPensionPp = (rows): FinancialMovementSimplified[] =>
    rows.map((row) => ({
        id: row.id,
        date: row.get("valueDate"),
        amount: localAmountWithCurrencyLegacy(row.grossAmount),
        description: row.categoryName,
    }));

export const formatPensionDps = (rows): FinancialMovementSimplified[] =>
    rows.map((row) => ({
        id: row.id,
        date: row.get("valueDate"),
        // @ts-ignore
        amount: negativeFormatter(new Map({ amount: row.grossAmount, operationType: row.typeCode })),
        description: row.type,
    }));

export const formatPensionDssDds = (rows, locale): FinancialMovementSimplified[] =>
    rows.map((row) => ({
        id: row.id,
        date: row.get("paymentDate"),
        amount: localAmountWithCurrencyLegacy(row.grossAmount),
        description: model.getLocaleCodeName(row.type, locale),
    }));
