import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ContentBox, GridItem } from "ui-library/layouts";
import { ContractOption, Loading, Option, Select, Typography } from "ui-library/atoms";
import { InfoPanel } from "ui-library/modules";

import entity from "core/entity";
import fetching from "core/fetching";
import i18n from "core/i18n";

import { setSelectedContractGdprData } from "../actions";
import { ENTITY_CONTRACTS_GDPR_DATA } from "../constants";
import { getContractGdprDataSections, getContractOptions, getSelectedContractGdprData } from "../selectors";

import { AttributeSection } from "./AttributeSection";

export const ContractGdprDataGridItem = () => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();

    const isContractsGdprDataLoading = useSelector(fetching.isFetching(ENTITY_CONTRACTS_GDPR_DATA, true));
    const isContractsGdprDataError = useSelector(entity.isError(ENTITY_CONTRACTS_GDPR_DATA));

    const selectedContractGdprData = useSelector(getSelectedContractGdprData);
    const contractOptions = useSelector(getContractOptions);

    const contractGdprData = useSelector(getContractGdprDataSections(selectedContractGdprData));
    const sections = contractGdprData && contractGdprData.sections;

    const onContractChange = (idObject) => {
        dispatch(setSelectedContractGdprData(idObject));
    };

    return (
        <GridItem xs={12}>
            <InfoPanel icon="user-settings" title={t("userSettings.personalDataProcessing.title")}>
                <ContentBox>
                    <Typography markDown={t("userSettings.personalDataProcessing.text")} />
                </ContentBox>

                {isContractsGdprDataError && <Typography>{t("error.sectionUnavailable")}</Typography>}
                {!isContractsGdprDataError && (
                    <Loading loading={isContractsGdprDataLoading}>
                        <ContentBox bigger>
                            <Select
                                value={selectedContractGdprData || ""}
                                onChange={(event) => onContractChange(event.target.value)}
                                fullWidth
                                label={t("common.contractNumber")}
                                placeholder={t("userSettings.personalData.contractSelectPlaceholder")}
                                id="selectContract"
                            >
                                {contractOptions &&
                                    contractOptions.map((contract) => (
                                        <Option value={contract.get("value")} key={`${contract.get("value")}`}>
                                            <ContractOption
                                                contractName={contract.get("contractName")}
                                                contractNumber={`${contract.get("contractNumber")}`}
                                            />
                                        </Option>
                                    ))}
                            </Select>
                        </ContentBox>

                        {sections && (
                            <ContentBox>
                                <InfoPanel>
                                    {sections &&
                                        sections.map((section) => (
                                            <Fragment key={section.id}>
                                                <AttributeSection section={section} activeContractId={selectedContractGdprData} />
                                            </Fragment>
                                        ))}
                                </InfoPanel>
                            </ContentBox>
                        )}
                    </Loading>
                )}
            </InfoPanel>
        </GridItem>
    );
};
