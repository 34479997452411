import { List } from "immutable";
import { takeLatest, put } from "redux-saga/effects";
import { SubmissionError, initialize as reduxFormInitialize } from "redux-form/immutable";
import objectPath from "object-path";

import { START_SUBMIT, ASYNC_VALIDATE, ON_SUBMIT_FAIL, RESET } from "./actions";

const GENERAL_ERROR_KEY = "_error"; // redux-form spec

export const initialize = function* (form, values) {
    yield put(reduxFormInitialize(form, values, false));
};

export const failSubmitWithCustomErrors = ({ reject }, errors) => {
    reject(new SubmissionError(errors));
};

export const failSubmitWithException = ({ reject }, exception) => {
    const errors = transformErrorsForForm(exception.response.fieldErrors || List());
    errors[GENERAL_ERROR_KEY] = List(exception.response.errors);
    reject(new SubmissionError(errors));
};

export const successSubmit = ({ resolve }) => resolve();

export const endAsyncValidation = ({ reject, resolve }, error) => {
    if (error) {
        reject(error);
    } else {
        resolve();
    }
};

const createFormMatcher =
    (formName, actionType) =>
    ({ type, meta }) =>
        type === actionType && meta && meta.form === formName;

export const takeLatestSubmit = (formName, func, ...args) => takeLatest(createFormMatcher(formName, START_SUBMIT), func, ...args);
export const takeLatestAsyncValidate = (formName, func, ...args) => takeLatest(createFormMatcher(formName, ASYNC_VALIDATE), func, ...args);
export const takeLatestOnSubmitFail = (formName, func, ...args) => takeLatest(createFormMatcher(formName, ON_SUBMIT_FAIL), func, ...args);
export const takeLatestReset = (formName, func, ...args) => takeLatest(createFormMatcher(formName, RESET), func, ...args);

export const transformErrorsForForm = (errors) =>
    errors.reduce((acc, fieldError) => {
        const path = transformFieldArray(fieldError.field);
        if (!objectPath.has(acc, path)) {
            objectPath.ensureExists(acc, path, List([fieldError.message]));
        } else {
            const list = objectPath.get(acc, path);
            objectPath.set(acc, path, list.push(fieldError.message));
        }
        return acc;
    }, {});

// regexp \] and \[ removes [] in array values and use . instead
export const transformFieldArray = (field) => field.replace(/\]/g, "").replace(/\[/g, ".");
