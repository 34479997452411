import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import pageContext from "core/pageContext";
import { Countries } from "enums";
import coreEnum from "core/enum";
import i18n from "core/i18n";

import AccountSectionCs from "./AccountSectionCs";
import AccountSectionSk from "./AccountSectionSk";

const countryTable = {
    [Countries.CZ.id]: AccountSectionCs,
    [Countries.SK.id]: AccountSectionSk,
};

const AccountSection = ({ bankListType, getShouldCheckIbanCountry, ...props }) => {
    const siteId = useSelector(pageContext.getSiteId);
    const bankListTypesSelector = coreEnum.createGetEnum(bankListType);
    const bankListTypes = useSelector(bankListTypesSelector);

    const getLocalizedCodeOptions = i18n.createGetLocalizedCodeOptions(bankListTypes);
    const bankCodeOptions = useSelector(getLocalizedCodeOptions);
    const shouldCheckIbanCountry = useSelector(getShouldCheckIbanCountry);

    const Component = countryTable[siteId];
    return <Component bankCodeOptions={bankCodeOptions} shouldCheckIbanCountry={shouldCheckIbanCountry} {...props} />;
};

const bankListPropType = PropTypes.oneOf([coreEnum.BANK_LIST]);

AccountSection.propTypes = {
    bankListType: bankListPropType.isRequired,
    getShouldCheckIbanCountry: PropTypes.func.isRequired,
};

export default AccountSection;
