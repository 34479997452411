import PropTypes from "prop-types";
import { StyledUnorderedListItem } from "ui-library/Styled";

import React from "react";
import { Icon } from "ui-library/atoms/Icons/Icon";

// podla variantu pridaj ikonku

export const addIcon = (props) => {
    if (props.variant === "circle") {
        return <Icon icon="circle" size={6} color="blue" />;
    } else if (props.variant === "check") {
        return <Icon icon="circle-check" size={16} color="green" />;
    } else if (props.variant === "error") {
        return <Icon icon="circle-error" size={16} color="red" />;
    } else {
        return null;
    }
};

export const ListItem = ({ children, compact, ...props }) => {
    return (
        <StyledUnorderedListItem $compact={compact} {...props}>
            {props.variant ? <span className="marker"> {addIcon(props)}</span> : " "}
            {children}
        </StyledUnorderedListItem>
    );
};

ListItem.propTypes = {
    variant: PropTypes.string,
    compact: PropTypes.bool,
};
