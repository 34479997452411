import React from "react";
import PropTypes from "prop-types";

import { RedeemPensionDpsRedeemTypes } from "enums";

import {
    PensionDpsEarlyPayment,
    PensionDpsOneTimeSettlementSection,
    PensionDpsPreRetirementSection,
    PensionDpsRegularPaymentSection,
} from "./redeemParameters";

export const RedeemParametersPensionDpsBasedOnType = ({
    formName,
    selectedRedeemType,
    selectedRedeemTypeParameters,
    contractAdditionalInfo,
}) => (
    <>
        {/* Pre retirement */}
        {RedeemPensionDpsRedeemTypes.DPS_PRE_RETIREMENT === selectedRedeemType && (
            <PensionDpsPreRetirementSection
                selectedRedeemTypeParameters={selectedRedeemTypeParameters}
                contractAdditionalInfo={contractAdditionalInfo}
            />
        )}

        {/* One time payment */}
        {RedeemPensionDpsRedeemTypes.DPS_RETIREMENT_PENSION_ONE_TIME_SETTLEMENT === selectedRedeemType && (
            <PensionDpsOneTimeSettlementSection selectedRedeemTypeParameters={selectedRedeemTypeParameters} />
        )}

        {/* Regular payments */}
        {RedeemPensionDpsRedeemTypes.DPS_RETIREMENT_PENSION_REGULAR_PENSION === selectedRedeemType && (
            <PensionDpsRegularPaymentSection formName={formName} selectedRedeemTypeParameters={selectedRedeemTypeParameters} />
        )}

        {/* Early payments */}
        {RedeemPensionDpsRedeemTypes.DPS_EARLY_PAYMENT_ONE_TIME_SETTLEMENT === selectedRedeemType && (
            <PensionDpsEarlyPayment selectedRedeemTypeParameters={selectedRedeemTypeParameters} />
        )}
    </>
);

RedeemParametersPensionDpsBasedOnType.propTypes = {
    formName: PropTypes.string.isRequired,
    selectedRedeemType: PropTypes.object.isRequired,
    selectedRedeemTypeParameters: PropTypes.object.isRequired,
    contractAdditionalInfo: PropTypes.object.isRequired,
};
