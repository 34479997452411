import { List, Map } from "immutable";
import { createSelector } from "reselect";
import { v4 as uuid } from "uuid";

import pageContext from "core/pageContext";
import entity from "core/entity";
import i18n from "core/i18n";
import userContracts from "core/userContracts";
import { DEFAULT_CHART_COLOR, DEFAULT_EMPTY_PARAM } from "app/constants";
import { localAmount, localAmountWithCurrencyLegacy, localCurrency, oneLineLegalEntityWithDescription } from "core/formatters";
import { app, claims, date, fn, model, nbsp } from "core/util";
import { LifeFunds } from "enums";
import { ClaimsEvents } from "model/claim";
import { Insured } from "model/harmonized";
import { ContractOverview } from "model/common";
import { LifeFinancialStatement } from "model/life";

import {
    ENTITY_CONTRACT_INCIDENTS,
    ENTITY_CONTRACT_OVERVIEW,
    ENTITY_FINANCIAL_OPERATIONS,
    ENTITY_FINANCIAL_STATEMENT,
    ENTITY_TAX_CERTIFICATE_AVAILABLE_YEARS,
    NAME,
} from "./constants";
import { getPaymentSchedulerOrEmptyScheduler, GRAPH_COLORS, prepareValidFrequencyOptions } from "./util";

const FINANCIAL_OPERATIONS_TRANSLATION_PREFIX = "life.financialOperation.";

const getSiteId = (state) => pageContext.getSiteId(state);

/** contract data */
const getModel = app.createGetModel(NAME);
const getData = (state) => getModel(state).get("data");
export const getPolicyData = (state) => userContracts.getContractById(state, Number(getIdObject(state)));
const getLifeContract = (state) => getData(state).get("lifeContractDetail");
export const getContractOverview = (state) => entity.getDataSelector(state, ENTITY_CONTRACT_OVERVIEW, ContractOverview.fromServer());
const getLineOfBusinessClasses = (state) => getContractOverview(state).getIn(["lineOfBusinessClasses"]);
export const createGetCompliantLineOfBusinessClasses = (idObject) =>
    createSelector(
        getLineOfBusinessClasses,
        userContracts.createGetProductGroupLvl2ById(idObject),
        (lineOfBusinessClasses, productGroupLvl2) => claims.getCompliantLineOfBusinesses(lineOfBusinessClasses, productGroupLvl2),
    );

export const getIsUniqaContract = (state) => getLifeContract(state).get("isUniqaContract");
export const getIdObject = (state) => getLifeContract(state).get("idObject") || DEFAULT_EMPTY_PARAM;

export const getLifeContractDetail = (state) => getLifeContract(state).get("contractDetail");
export const getPolicyHolder = (state) => getLifeContractDetail(state).get("holder");

export const getElectronicCommunication = (state) => getLifeContractDetail(state).get("electronicCommunication");

export const getLifeAccountDetail = (state) => getLifeContract(state).getIn(["contractDetail", "lifeAccountDetail"]);
export const getInsureds = (state) => getLifeAccountDetail(state).get("insureds");
export const getBeneficiaries = (state) => getLifeAccountDetail(state).get("beneficiaries");
export const getLifeInvestmentStrategy = (state) => getLifeAccountDetail(state).get("investmentStrategies");
export const getInsuredProperties = (state) => getLifeContractDetail(state).get("insuredRealEstateProperties");

export const getActualPaymentSchedulerAxa = (state) =>
    prepareValidFrequencyOptions(getPaymentSchedulerOrEmptyScheduler(getLifeAccountDetail(state).get("actualPaymentsScheduler")));
export const getAdvancedPaymentSchedulerAxa = (state) =>
    prepareValidFrequencyOptions(getPaymentSchedulerOrEmptyScheduler(getLifeAccountDetail(state).get("advancePaymentsScheduler")));
export const getActualPaymentSchedulerUniqa = (state) =>
    getPaymentSchedulerOrEmptyScheduler(getLifeContractDetail(state).get("actualPaymentsScheduler"));
export const getActualSchedulerRecordUniqa = (state) => getActualPaymentSchedulerUniqa(state).first();
export const getAdvancedPaymentSchedulerUniqa = (state) =>
    getPaymentSchedulerOrEmptyScheduler(getLifeContractDetail(state).get("advancePaymentsScheduler"));

export const getInsuranceCostSummary = (state) => getLifeContract(state).get("insuranceCostSummary");

export const getBalanceOnContractAxa = (state) => getLifeAccountDetail(state).balanceOnContract;
export const getBalanceOnContractUniqa = (state) => getLifeContractDetail(state).balanceOnContract;

export const getFinancialStatement = (state) => entity.getData(ENTITY_FINANCIAL_STATEMENT, LifeFinancialStatement.fromServer())(state);
export const getInvestmentFunds = (state) => getFinancialStatement(state).get("investmentFunds");
export const getInvestmentTypes = (state) => getFinancialStatement(state).get("investmentTypes");

export const getIncidents = (state) => entity.getDataSelector(state, ENTITY_CONTRACT_INCIDENTS, ClaimsEvents.fromServer());
export const getClaims = (state) => getIncidents(state).get("claims");

export const getTaxCertificateAvailableYears = (state) => entity.getDataSelector(state, ENTITY_TAX_CERTIFICATE_AVAILABLE_YEARS, List);

/** appState */
const getAppState = (state) => getModel(state).get("appState");
export const getDetailSelectedInsured = (state) => getAppState(state).get("detailSelectedInsured");
export const getStatementTab = (state) => getAppState(state).get("statementTab");

/** user data */
export const getPolicy = createSelector(userContracts.getContractNormalizedData, getIdObject, (policies, idObject) =>
    idObject ? policies[idObject] : null,
);

/** custom selectors */

// Is tax certificate from ECM available.
export const getIsTaxCertificateAvailable = createSelector(
    getTaxCertificateAvailableYears,
    (taxCertificateAvailableYears) => taxCertificateAvailableYears && taxCertificateAvailableYears.size > 0,
);

// Basic contract data.
export const createGetActualPaymentScheduler = createSelector(
    getIsUniqaContract,
    getActualPaymentSchedulerAxa,
    getActualPaymentSchedulerUniqa,
    (isUniqaContract, actualPaymentSchedulerAxa, actualPaymentSchedulerUniqa) =>
        isUniqaContract ? actualPaymentSchedulerUniqa : actualPaymentSchedulerAxa,
);

export const createGetAdvancedPaymentScheduler = createSelector(
    getIsUniqaContract,
    getAdvancedPaymentSchedulerAxa,
    getAdvancedPaymentSchedulerUniqa,
    (isUniqaContract, advancedPaymentSchedulerAxa, advancedPaymentSchedulerUniqa) =>
        isUniqaContract ? advancedPaymentSchedulerUniqa : advancedPaymentSchedulerAxa,
);

export const createGetBalanceOnContract = createSelector(
    getIsUniqaContract,
    getBalanceOnContractAxa,
    getBalanceOnContractUniqa,
    (isUniqaContract, balanceOnContractAxa, balanceOnContractUniqa) => (isUniqaContract ? balanceOnContractUniqa : balanceOnContractAxa),
);

export const getPolicyBeginDateYear = createSelector(getPolicyData, (policyData) => date.getYearFromStringDateTime(policyData?.beginDate));

// Beneficiaries
export const getBeneficiariesDataAxa = createSelector(getBeneficiaries, (beneficiaries) => {
    let insuredPersonBirthNumber = null;
    return beneficiaries
        .sort((beneficiary1, beneficiary2) => beneficiary1.insuredPerson.birthNumber - beneficiary2.insuredPerson.birthNumber)
        .map((beneficiary) => {
            const isSameInsuredPerson = insuredPersonBirthNumber === beneficiary.insuredPerson.birthNumber;
            insuredPersonBirthNumber = beneficiary.insuredPerson.birthNumber;
            return {
                reactKey: uuid(),
                insuredName: isSameInsuredPerson ? "" : beneficiary.insuredPerson,
                beneficiaryName: beneficiary,
                percentage: beneficiary.percentage,
            };
        });
});

export const getBeneficiariesDataUniqa = createSelector(getBeneficiaries, getInsureds, (beneficiaries, insureds) => {
    return beneficiaries
        .sort((beneficiary1, beneficiary2) => beneficiary1.insuredPerson.birthNumber - beneficiary2.insuredPerson.birthNumber)
        .map((beneficiary) => {
            const insured =
                insureds.filter((insuredItem) => insuredItem.riskCode === beneficiary.riskNumber).first() || Insured.fromServer();
            return {
                reactKey: uuid(),
                insuredName: insured.insured,
                beneficiaryName: beneficiary,
                percentage: beneficiary.percentage,
            };
        });
});

export const getBeneficiariesData = createSelector(
    getIsUniqaContract,
    getBeneficiariesDataAxa,
    getBeneficiariesDataUniqa,
    (isUniqaContract, beneficiariesDataAxa, beneficiariesDataUniqa) => (isUniqaContract ? beneficiariesDataUniqa : beneficiariesDataAxa),
);

// Insureds
export const getInsuredsSelectOptionsAxa = createSelector(getInsureds, (insureds) =>
    insureds.map((insured) => ({
        value: insured.get("reactKey"),
        label: oneLineLegalEntityWithDescription(insured.get("insured")),
    })),
);

export const getInsuredsSelectOptionsUniqa = createSelector(getInsureds, getInsuredProperties, (insureds, insuredProperties) => {
    const peopleInsurance = insureds.map((insured) => ({
        value: insured.get("reactKey"),
        label: oneLineLegalEntityWithDescription(insured.get("insured")),
    }));

    const propertyInsurance = insuredProperties.map((risk) => ({
        value: risk.reactKey,
        label: `${risk.description} - ${risk.category?.value}`,
    }));

    return peopleInsurance.concat(propertyInsurance);
});

export const getSelectedInsured = createSelector(getInsureds, getDetailSelectedInsured, (insureds, selectedInsured) =>
    insureds.filter((insured) => insured.get("reactKey") === selectedInsured).first(),
);

export const getSelectedRisk = createSelector(
    getInsureds,
    getInsuredProperties,
    getDetailSelectedInsured,
    (insureds, insuredProperties, selectedInsured) => {
        const foundInsuredPerson = insureds.find((insuredPerson) => insuredPerson.reactKey === selectedInsured);
        if (foundInsuredPerson) {
            return foundInsuredPerson;
        }

        const foundProperty = insuredProperties.find((property) => property.reactKey === selectedInsured);
        if (foundProperty) {
            return foundProperty;
        }
    },
);

export const createGetTariffLegendsAxa = (tariffs) =>
    createSelector(
        tariffs
            .map((tariff) => {
                const getLocalizedName = i18n.createGetLocalizedName();
                const insuranceTariff = tariff.get("insuranceTariff");
                return (state) => `${insuranceTariff.get("code")} \u2013 ${getLocalizedName(state, insuranceTariff)}`;
            })
            .toArray(),
        (...legends) => legends,
    );

export const createGetCoveragesLegendsUniqa = (coverages) =>
    createSelector(
        coverages
            .map((coverage) => {
                const insuranceTariff = coverage.get("insuranceTariff");
                return () => `${insuranceTariff.get("code")} \u2013 ${insuranceTariff.get("value")}`;
            })
            .toArray(),
        (...legends) => legends,
    );

// Financial operations
export const getFinancialOperations = createSelector(
    getIsUniqaContract,
    i18n.getLanguageCode,
    entity.getData(ENTITY_FINANCIAL_OPERATIONS, new List()),
    (isUniqaContract, languageCode, financialOperations) => {
        if (isUniqaContract) {
            return financialOperations.map((finOperation) => ({
                id: finOperation.reactKey,
                status: finOperation.status,
                valueDate: finOperation.dateOfIdentification,
                type: i18n.translateDirectly(`${FINANCIAL_OPERATIONS_TRANSLATION_PREFIX}${finOperation.type}`),
                amount: finOperation.amount,
            }));
        } else {
            return financialOperations.map((finOperation) => ({
                id: uuid(),
                status: finOperation.status,
                valueDate: finOperation.valueDate,
                type: model.getLocaleCodeName(finOperation.type, languageCode),
                amount: finOperation.grossAmount,
            }));
        }
    },
);
export const getFinancialOperationsTypes = createSelector(getFinancialOperations, (financialOperations) =>
    financialOperations.map((operation) => Map().set("label", operation.type).set("value", operation.type)).toSet(),
);

// Statement
export const createGetStatementDate = createSelector(getIsUniqaContract, getFinancialStatement, (isUniqaContract, financialStatement) =>
    isUniqaContract ? financialStatement.get("statementDate") : financialStatement.get("dateTime"),
);

// Investments funds
export const getInvestmentFundsDataAxa = createSelector(getInvestmentFunds, i18n.getLanguageCode, (lifeInvestmentFunds, languageCode) =>
    lifeInvestmentFunds
        .map((investmentFund) => ({
            reactKey: uuid(),
            fundCode: investmentFund.investmentFund.code,
            fundName: model.getLocaleCodeName(investmentFund.investmentFund, languageCode),
            fundType: model.getLocaleCodeName(investmentFund.investmentFundType, languageCode),
            fundUnitValue: investmentFund.investmentFundUnitValue,
            numberOfShares: investmentFund.investmentFundUnits,
            sharesValue: investmentFund.investmentFundValue,
        }))
        .sort((a, b) => a.fundCode.localeCompare(b.fundCode)),
);

export const getInvestmentFundsDataUniqa = createSelector(getInvestmentFunds, (lifeInvestmentFunds) => {
    return lifeInvestmentFunds
        .map((investmentFund) => investmentFund.investmentProgram)
        .toSet()
        .toList()
        .map((investmentProgram) => {
            const programFunds = lifeInvestmentFunds
                .filter((investmentFund) => investmentFund.investmentProgram.code === investmentProgram.code)
                .map((investmentFund) => ({
                    reactKey: uuid(),
                    fundCode: investmentFund.investmentFund?.code,
                    fundName: investmentFund.investmentFund?.value,
                    investmentProgram: investmentFund.investmentProgram?.value,
                    fundUnitValue: investmentFund.investmentFundUnitValue,
                    numberOfShares: investmentFund.investmentFundUnits,
                    sharesValue: investmentFund.investmentFundValue,
                }))
                .sort((a, b) => a.fundCode.localeCompare(b.fundCode));

            return {
                investmentProgram,
                programFunds,
            };
        });
});

// Investments strategy
export const getInvestmentStrategyDataAxa = createSelector(
    getLifeInvestmentStrategy,
    i18n.getLanguageCode,
    (lifeInvestmentStrategy, languageCode) =>
        lifeInvestmentStrategy.investmentFunds.map((investmentFund, index) => ({
            tariff: index === 0 ? lifeInvestmentStrategy.insuranceTariff.code : "",
            fundCode: investmentFund.investmentFund.code,
            fundName: model.getLocaleCodeName(investmentFund.investmentFund, languageCode),
            percentage: investmentFund.investmentPercentage,
        })),
);

export const getInvestmentStrategyDataUniqa = createSelector(getLifeInvestmentStrategy, (lifeInvestmentStrategy) =>
    lifeInvestmentStrategy.map((tariffData) => {
        const tariffPrograms = tariffData.investmentFunds
            .groupBy((fund) => fund.investmentProgram)
            .map((programFundsRaw, investmentProgram) => {
                const programFunds = programFundsRaw.map((investmentFund, index) => ({
                    fundCode: investmentFund?.investmentFund?.code,
                    fundName: investmentFund?.investmentFund?.value,
                    percentage: investmentFund?.investmentPercentage,
                }));

                return {
                    investmentProgram,
                    programFunds,
                };
            })
            .toList();

        return {
            tariff: tariffData.insuranceTariff,
            tariffPrograms,
        };
    }),
);

// Investment type
export const getInvestmentTypeDataAxa = createSelector(getInvestmentTypes, i18n.getLanguageCode, (lifeInvestmentType, languageCode) =>
    lifeInvestmentType.map((investmentType) => ({
        reactKey: uuid(),
        investmentFundType: model.getLocaleCodeName(investmentType.investmentFundType, languageCode),
        investmentFundUnits: investmentType.investmentFundUnits,
        investmentFundValue: investmentType.investmentFundValue,
    })),
);

// Chart Items.
export const getFinancialStatementChartItemsAxa = createSelector(
    getInvestmentFunds,
    getSiteId,
    i18n.getLanguageCode,
    (investmentFunds, site, languageCode) =>
        investmentFunds
            .groupBy((fund) => fund.investmentFund.code)
            .entrySeq()
            .toList()
            .map(([, funds]) => [
                funds.first(),
                funds
                    .map((fund) => parseFloat(fund.investmentFundValue.value))
                    .reduce(fn.sum)
                    .toString(),
            ])
            .map(([fund, sum]) => {
                const currentLifeFund = Object.values(LifeFunds).find(
                    (lifeFund) => lifeFund.getIn(["codes", site]) === fund.investmentFund.code,
                );
                return {
                    name: model.getLocaleCodeName(fund.investmentFund, languageCode),
                    color: currentLifeFund ? currentLifeFund.color : DEFAULT_CHART_COLOR,
                    value: sum,
                    fullValue: `${localAmount(sum)}${nbsp}${localCurrency(fund.investmentFundUnitValue?.currencyCode)}`,
                    currency: fund.investmentFundValue.currencyCode,
                };
            })
            .toArray(),
);

export const getFinancialStatementChartDataAxa = createSelector(
    getFinancialStatement,
    getFinancialStatementChartItemsAxa,
    (financialStatement, financialStatementChartItems) =>
        Map({
            sumValue: financialStatement.investmentFundValueSum.value,
            sumCurrency: financialStatement.investmentFundValueSum.currencyCode,
            sumAmount: localAmountWithCurrencyLegacy(financialStatement.investmentFundValueSum),
            items: financialStatementChartItems,
        }),
);

export const getFinancialStatementChartItemsUniqa = createSelector(getInvestmentFunds, (investmentFunds) =>
    investmentFunds
        .groupBy((fund) => fund.investmentFund.code)
        .entrySeq()
        .toList()
        .map(([, funds]) => [
            funds.first(),
            funds
                .map((fund) => parseFloat(fund.investmentFundValue.value))
                .reduce(fn.sum)
                .toString(),
        ])
        .map(([fund, sum], itemIndex) => ({
            name: fund.investmentFund?.value,
            color: GRAPH_COLORS[itemIndex],
            value: sum,
            fullValue: `${localAmount(sum)}${nbsp}${localCurrency(fund.investmentFundUnitValue?.currencyCode)}`,
            currency: fund.investmentFundValue.currencyCode,
        }))
        .toArray(),
);

export const getFinancialStatementChartDataUniqa = createSelector(
    getFinancialStatement,
    getFinancialStatementChartItemsUniqa,
    (financialStatement, financialStatementChartItems) =>
        Map({
            sumValue: financialStatement.investmentFundValueSum.value,
            sumCurrency: financialStatement.investmentFundValueSum.currencyCode,
            sumAmount: localAmountWithCurrencyLegacy(financialStatement.investmentFundValueSum),
            items: financialStatementChartItems,
        }),
);
