import { combineReducers } from "redux-immutable";

import { app } from "core/util";
import { SET_PAYMENT_SUCCESSFUL, SET_ERROR } from "./actions";

const errorReducer = (state = null, { type }) => {
    switch (type) {
        case SET_ERROR:
            return true;
        case SET_PAYMENT_SUCCESSFUL:
            return false;
        default:
            return state;
    }
};

export default combineReducers({
    isSuccessful: app.createDataReducer(SET_PAYMENT_SUCCESSFUL, null),
    error: errorReducer,
});
