import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { CodeValueLegacy } from "model/common";
import i18n from "core/i18n";
import { Typography } from "ui-library/atoms";

const mapStateToProps = (state, { value }) => {
    const getLocalizedName = i18n.createGetLocalizedName();
    return {
        value: getLocalizedName(state, value),
    };
};

const CodeNameFormatterInner = ({ value }) => <Typography>{value}</Typography>;

export const CodeNameFormatter = connect(mapStateToProps)(CodeNameFormatterInner);

CodeNameFormatterInner.propTypes = {
    value: PropTypes.instanceOf(CodeValueLegacy).isRequired,
};
