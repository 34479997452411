export const NAME = "investmentProfile";

export const PROFILE_SELECT = "PROFILE_SELECT";
export const PROFILE_CHANGE_FEE = "PROFILE_CHANGE_FEE";

export const FUND_PERCENTAGE_BREAKDOWN = "programStructure";
export const FUND_FIELD = "isin";
export const PERCENTAGE_FIELD = "percentage";
export const REALLOCATE_INVESTMENTS_FIELD = "reallocateInvestments";
export const SUM_PERCENTAGE_FIELD = "sumPercentageField";

export const INDIVIDUAL_PROFILE_CODE = "100";
export const MAX_FUND_COUNT = 6;
