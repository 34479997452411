import React, { useEffect, useState, useRef, createRef } from "react";
import { Box } from "ui-library/layouts";

import { StyledSidemenu, StyledSidemenuDropdownHeader, StyledSidemenuDropdownContent } from "ui-library/Styled";
import { Icon, Typography } from "ui-library/atoms";
import useBreakpoints from "ui-library/theme/breakpoints";
import { useLocation } from "react-router-dom";

export const Sidemenu = ({ children, ...props }) => {
    const [sideMenuOpen, setSideMenuOpen] = React.useState(false);
    const [activeLinkItem, setActiveLinkItem] = React.useState();
    const [isScrollingUp, setIsScrollingUp] = React.useState(false);
    const [sidenavOffsetFirstLoad, setSidenavOffsetFirstLoad] = useState(0);

    const breakpointMd = useBreakpoints("md");
    const location = useLocation();
    const [offset, setOffset] = useState(0);
    const [sidenavOffset, setSidenavOffset] = useState(0);
    const inputRef = useRef<HTMLInputElement>();

    const childrenArray = children.size > 1 ? children : [children];
    const linkRefs = useRef(childrenArray.map(() => createRef()));

    const determineActive = () => {
        linkRefs.current.forEach((item) => {
            if (item.current.firstChild.classList.contains("active")) {
                setActiveLinkItem(item.current.firstChild.innerText);
            }
        });
    };

    useEffect(() => {
        setSidenavOffset(inputRef.current.offsetTop);
        determineActive();
        const onScroll = () => {
            setIsScrollingUp(offset > window.pageYOffset);

            setOffset(window.pageYOffset);
        };
        window.addEventListener("scroll", onScroll, { passive: true });
        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, [sidenavOffset, offset]);

    useEffect(() => {
        setSideMenuOpen(false);
        setSidenavOffsetFirstLoad(inputRef.current.offsetTop);
    }, [location, sidenavOffsetFirstLoad]);

    const handleSideMenuToggle = () => {
        if (sideMenuOpen === true) {
            setSideMenuOpen(false);
        } else {
            setSideMenuOpen(true);
        }
    };

    const offsetDiff = offset >= sidenavOffsetFirstLoad - 70 ? "sidenav-fixed" : "";

    return (
        <StyledSidemenu {...props} $offset={offset} $scrollingUp={isScrollingUp} className={offsetDiff} ref={inputRef}>
            {!breakpointMd && (
                <>
                    {sideMenuOpen === false && (
                        <StyledSidemenuDropdownHeader justifyContent="space-between" alignItems="center" onClick={handleSideMenuToggle}>
                            <Typography component="p">{activeLinkItem}</Typography>
                            <Icon size={16} icon={sideMenuOpen ? "chevron-up" : "chevron-down"} color="blue" />
                        </StyledSidemenuDropdownHeader>
                    )}
                </>
            )}

            <StyledSidemenuDropdownContent className={sideMenuOpen && "opened-sidemenu"}>
                {!breakpointMd && (
                    <Box onClick={handleSideMenuToggle} className={"chevron-button"}>
                        <Icon size={16} icon={sideMenuOpen ? "chevron-up" : "chevron-down"} color="blue" />
                    </Box>
                )}
                {childrenArray.map((Child, index) => {
                    return (
                        <div ref={linkRefs.current[index]} key={index} className="sidemenu-item">
                            {Child}
                        </div>
                    );
                })}
            </StyledSidemenuDropdownContent>
        </StyledSidemenu>
    );
};
