import i18n from "core/i18n";
import { FAQ } from "ui-library/modules";
import { Box } from "ui-library/layouts";

const getFaqData = (t) => [
    {
        title: t("page.login.faq.questionOne"),
        children: t("page.login.faq.answerOne"),
    },
    {
        title: t("page.login.faq.questionTwo"),
        children: t("page.login.faq.answerTwo"),
    },
    {
        title: t("page.login.faq.questionThree"),
        children: t("page.login.faq.answerThree"),
    },
    {
        title: t("page.login.faq.questionFour"),
        children: t("page.login.faq.answerFour"),
    },
    {
        title: t("page.login.faq.questionFive"),
        children: t("page.login.faq.answerFive"),
    },
    {
        title: t("page.login.faq.questionSix"),
        children: t("page.login.faq.answerSix"),
    },
    {
        title: t("page.login.faq.questionSeven"),
        children: t("page.login.faq.answerSeven"),
    },
    {
        title: t("page.login.faq.questionEight"),
        children: t("page.login.faq.answerEight"),
    },
];

export const FaqSection = () => {
    const { t } = i18n.useTranslation();
    return (
        <Box mt={{ xs: 6, md: 12 }}>
            <FAQ title={t("common.faq")} data={getFaqData(t)} />
        </Box>
    );
};
