import router from "core/router";
import { call, fork } from "redux-saga/effects";

import entity from "core/entity";
import writeMessageForm from "form/modal/writeMessage";
import { Tabs } from "routeConstants";
import { messagesApi } from "serverApi";

import { ENTITY_REQUESTS, FORM_WRITE_MESSAGE } from "./constants";

export default router.routerWrapper({
    *onInnerRouteChange(name) {
        switch (name) {
            case Tabs.REQUESTS:
                yield fork(entity.load, ENTITY_REQUESTS, () => messagesApi.getRequests());
                break;
        }
    },
    *onModalOpen(modalName) {
        switch (modalName) {
            case FORM_WRITE_MESSAGE:
                yield call(writeMessageForm.createSaga, FORM_WRITE_MESSAGE);
                break;
        }
    },
    clearDataForPage() {
        return [entity.clearData(ENTITY_REQUESTS)];
    },
});
