import { getFormFieldValue, getFormFieldValueWithState } from "core/form";
import errorHandling, { OTP_WRONG_MOBILE_NUMBER_FIN_OPERATIONS } from "core/errorHandling";

import { OTP_ID_FIELD_NAME } from "./constants";

export default (reducerPath) => {
    const getModel = (state) => state.getIn(reducerPath);
    const isResent = (state) => getModel(state).get("resent");
    return {
        isResent,
        getPhoneNumber: (state) => getModel(state).get("phoneNumber"),
        // show when isResent to not disappear when OTP_ID_FIELD_NAME is nulled for resend.
        createIsVisibleSelector: (formName) => (state) =>
            !!getFormFieldValueWithState(state, formName, OTP_ID_FIELD_NAME) ||
            isResent(state) ||
            errorHandling.isServiceInErrorState(OTP_WRONG_MOBILE_NUMBER_FIN_OPERATIONS)(state),
    };
};
