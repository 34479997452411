import React from "react";
import { useSelector } from "react-redux";

import { ContentBox, Flexbox } from "ui-library/layouts";
import { LabelTextItem } from "ui-library/modules";
import { Typography } from "ui-library/atoms";

import i18n from "core/i18n";
import { dateTimeToLocalFullDate } from "core/formatters";

import { getContractByIdObject, getIsContractActive } from "../selectors";

export const ContractSummary = ({ selectedContractId }) => {
    const getContractByIdObjectSelector = getContractByIdObject(selectedContractId);
    const contract = useSelector(getContractByIdObjectSelector);
    const isActive = useSelector(getIsContractActive(selectedContractId));
    const { t } = i18n.useTranslation();
    return (
        <ContentBox>
            {!isActive && (
                <Typography
                    markDown={t("page.reportClaim.inactiveContractAlert", {
                        contractEndDate: dateTimeToLocalFullDate(contract.get("endDate")),
                    })}
                />
            )}
            <Flexbox display={{ xs: "flex", md: "table" }} flexDirection="column" mt={2}>
                <LabelTextItem label={t("common.contractNumber")} text={contract.get("contractNumber")} />
                {contract.get("contractName") && <LabelTextItem label={t("form.customContractName")} text={contract.get("contractName")} />}
                <LabelTextItem label={t("common.beginDate")} text={dateTimeToLocalFullDate(contract.get("beginDate"))} />
                <LabelTextItem label={t("common.endDate")} text={dateTimeToLocalFullDate(contract.get("endDate"))} />
            </Flexbox>
        </ContentBox>
    );
};
