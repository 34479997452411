import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { resetSection, submit } from "redux-form/immutable";

import { StandaloneLink, Typography } from "ui-library/atoms";
import { Box, ContentBox } from "ui-library/layouts";

import i18n from "core/i18n";
import { Field, InputText, max6Characters, numberCharacters } from "core/form";
import { nbsp } from "core/util";

import { OTP_CODE_FIELD_NAME, OTP_ID_FIELD_NAME } from "./constants";
import { ErrorBoundary, OTP_WRONG_MOBILE_NUMBER_FIN_OPERATIONS } from "core/errorHandling";

/**
 * We get weird number of X from server (xxxxxxxxxx605). So here, we use only numbers at the end.
 */
const formatPhoneNumber = (phoneString) => {
    const number = phoneString.replace(/x/g, "");
    return `+xxx${nbsp}xxx${nbsp}xxx${nbsp}${number}`;
};

export default (actions, selectors) => {
    const OtpContainer = ({ formName, stepNumber, ErrorComponent }) => {
        const { t } = i18n.useTranslation();
        const phone = useSelector(selectors.getPhoneNumber);
        const resent = useSelector(selectors.isResent);
        const title = (stepNumber ? `${stepNumber}. ` : "") + t("form.confirmation");

        const key = resent ? "form.otp.phonePinTextAgain" : "form.otp.phonePinText";
        const text = phone ? t(key, { phone: formatPhoneNumber(phone) }) : undefined;

        const dispatch = useDispatch();
        const onResend = () => {
            dispatch(resetSection(formName, OTP_ID_FIELD_NAME, OTP_CODE_FIELD_NAME));
            dispatch(actions.setResent());
            /**
             * Redux form obviously needs this to be done in new js event loop, since without it, it sends old values to onSubmit.
             */
            setTimeout(() => dispatch(submit(formName)), 0);
        };

        return (
            <Box>
                <ContentBox>
                    <Typography variant="h3">{title}</Typography>
                </ContentBox>

                <ErrorBoundary service={OTP_WRONG_MOBILE_NUMBER_FIN_OPERATIONS} ErrorComponent={ErrorComponent}>
                    <ContentBox>{text && <Typography markDown={text} />}</ContentBox>
                    <ContentBox mb={6}>
                        <Field
                            // isActivationCode
                            component={InputText}
                            name="otpCode"
                            label={t("form.otp.label")}
                            normalize={[numberCharacters, max6Characters]}
                            id="smsCode"
                            placeholder={t("form.otp.ePinPlaceholder")}
                        />
                        <StandaloneLink color="blue" onClick={onResend}>
                            {t("form.otp.resend")}
                        </StandaloneLink>
                    </ContentBox>
                </ErrorBoundary>
            </Box>
        );
    };

    OtpContainer.propTypes = {
        formName: PropTypes.string.isRequired,
        stepNumber: PropTypes.number,
        ErrorComponent: PropTypes.elementType.isRequired,
    };

    OtpContainer.defaultProps = {
        stepNumber: null,
    };

    return OtpContainer;
};
