import { Country, Locale, NormalizedData, NormalizedLocalizedData } from "types";

export enum Site {
    CZ = "CZ",
    SK = "SK",
}

export const Locales: NormalizedData<Locale> = {
    sk_SK: {
        id: "sk_SK",
        languageCode: "sk",
        languageCodeUpperCase: "SK",
        dateFormat: "DD.MM.YYYY",
    },
    cs_CZ: {
        id: "cs_CZ",
        languageCode: "cs",
        languageCodeUpperCase: "CS",
        dateFormat: "DD.MM.YYYY",
    },
    en_US: {
        id: "en_US",
        languageCode: "en",
        languageCodeUpperCase: "EN",
        dateFormat: "MM/DD/YYYY",
    },
};

// todo separate country, site, currency code, and reuse generated
export const Countries: NormalizedLocalizedData<Country> = {
    AD: { id: "AD", msg: "language.AD" },
    AE: { id: "AE", msg: "language.AE" },
    AF: { id: "AF", msg: "language.AF" },
    AG: { id: "AG", msg: "language.AG" },
    AI: { id: "AI", msg: "language.AI" },
    AL: { id: "AL", msg: "language.AL" },
    AM: { id: "AM", msg: "language.AM" },
    AO: { id: "AO", msg: "language.AO" },
    AQ: { id: "AQ", msg: "language.AQ" },
    AR: { id: "AR", msg: "language.AR" },
    AS: { id: "AS", msg: "language.AS" },
    AT: { id: "AT", msg: "language.AT" },
    AU: { id: "AU", msg: "language.AU" },
    AW: { id: "AW", msg: "language.AW" },
    AX: { id: "AX", msg: "language.AX" },
    AZ: { id: "AZ", msg: "language.AZ" },
    BA: { id: "BA", msg: "language.BA" },
    BB: { id: "BB", msg: "language.BB" },
    BD: { id: "BD", msg: "language.BD" },
    BE: { id: "BE", msg: "language.BE" },
    BF: { id: "BF", msg: "language.BF" },
    BG: { id: "BG", msg: "language.BG" },
    BH: { id: "BH", msg: "language.BH" },
    BI: { id: "BI", msg: "language.BI" },
    BJ: { id: "BJ", msg: "language.BJ" },
    BL: { id: "BL", msg: "language.BL" },
    BM: { id: "BM", msg: "language.BM" },
    BN: { id: "BN", msg: "language.BN" },
    BO: { id: "BO", msg: "language.BO" },
    BQ: { id: "BQ", msg: "language.BQ" },
    BR: { id: "BR", msg: "language.BR" },
    BS: { id: "BS", msg: "language.BS" },
    BT: { id: "BT", msg: "language.BT" },
    BV: { id: "BV", msg: "language.BV" },
    BW: { id: "BW", msg: "language.BW" },
    BY: { id: "BY", msg: "language.BY" },
    BZ: { id: "BZ", msg: "language.BZ" },
    CA: { id: "CA", msg: "language.CA" },
    CC: { id: "CC", msg: "language.CC" },
    CD: { id: "CD", msg: "language.CD" },
    CF: { id: "CF", msg: "language.CF" },
    CG: { id: "CG", msg: "language.CG" },
    CI: { id: "CI", msg: "language.CI" },
    CK: { id: "CK", msg: "language.CK" },
    CL: { id: "CL", msg: "language.CL" },
    CM: { id: "CM", msg: "language.CM" },
    CN: { id: "CN", msg: "language.CN" },
    CO: { id: "CO", msg: "language.CO" },
    CR: { id: "CR", msg: "language.CR" },
    CU: { id: "CU", msg: "language.CU" },
    CV: { id: "CV", msg: "language.CV" },
    CW: { id: "CW", msg: "language.CW" },
    CX: { id: "CX", msg: "language.CX" },
    CY: { id: "CY", msg: "language.CY" },
    CZ: { id: "CZ", msg: "language.CZ", currencyCode: "CZK", secondaryCountries: ["SK"] },
    DE: { id: "DE", msg: "language.DE" },
    DJ: { id: "DJ", msg: "language.DJ" },
    DK: { id: "DK", msg: "language.DK" },
    DM: { id: "DM", msg: "language.DM" },
    DO: { id: "DO", msg: "language.DO" },
    DZ: { id: "DZ", msg: "language.DZ" },
    EC: { id: "EC", msg: "language.EC" },
    EE: { id: "EE", msg: "language.EE" },
    EG: { id: "EG", msg: "language.EG" },
    EH: { id: "EH", msg: "language.EH" },
    ER: { id: "ER", msg: "language.ER" },
    ES: { id: "ES", msg: "language.ES" },
    ET: { id: "ET", msg: "language.ET" },
    FI: { id: "FI", msg: "language.FI" },
    FJ: { id: "FJ", msg: "language.FJ" },
    FK: { id: "FK", msg: "language.FK" },
    FM: { id: "FM", msg: "language.FM" },
    FO: { id: "FO", msg: "language.FO" },
    FR: { id: "FR", msg: "language.FR" },
    GA: { id: "GA", msg: "language.GA" },
    GB: { id: "GB", msg: "language.GB" },
    GD: { id: "GD", msg: "language.GD" },
    GE: { id: "GE", msg: "language.GE" },
    GF: { id: "GF", msg: "language.GF" },
    GG: { id: "GG", msg: "language.GG" },
    GH: { id: "GH", msg: "language.GH" },
    GI: { id: "GI", msg: "language.GI" },
    GL: { id: "GL", msg: "language.GL" },
    GM: { id: "GM", msg: "language.GM" },
    GN: { id: "GN", msg: "language.GN" },
    GP: { id: "GP", msg: "language.GP" },
    GQ: { id: "GQ", msg: "language.GQ" },
    GR: { id: "GR", msg: "language.GR" },
    GS: { id: "GS", msg: "language.GS" },
    GT: { id: "GT", msg: "language.GT" },
    GU: { id: "GU", msg: "language.GU" },
    GW: { id: "GW", msg: "language.GW" },
    GY: { id: "GY", msg: "language.GY" },
    HK: { id: "HK", msg: "language.HK" },
    HM: { id: "HM", msg: "language.HM" },
    HN: { id: "HN", msg: "language.HN" },
    HR: { id: "HR", msg: "language.HR" },
    HT: { id: "HT", msg: "language.HT" },
    HU: { id: "HU", msg: "language.HU" },
    CH: { id: "CH", msg: "language.CH" },
    ID: { id: "ID", msg: "language.ID" },
    IE: { id: "IE", msg: "language.IE" },
    IL: { id: "IL", msg: "language.IL" },
    IM: { id: "IM", msg: "language.IM" },
    IN: { id: "IN", msg: "language.IN" },
    IO: { id: "IO", msg: "language.IO" },
    IQ: { id: "IQ", msg: "language.IQ" },
    IR: { id: "IR", msg: "language.IR" },
    IS: { id: "IS", msg: "language.IS" },
    IT: { id: "IT", msg: "language.IT" },
    JE: { id: "JE", msg: "language.JE" },
    JM: { id: "JM", msg: "language.JM" },
    JO: { id: "JO", msg: "language.JO" },
    JP: { id: "JP", msg: "language.JP" },
    KE: { id: "KE", msg: "language.KE" },
    KG: { id: "KG", msg: "language.KG" },
    KH: { id: "KH", msg: "language.KH" },
    KI: { id: "KI", msg: "language.KI" },
    KM: { id: "KM", msg: "language.KM" },
    KN: { id: "KN", msg: "language.KN" },
    KP: { id: "KP", msg: "language.KP" },
    KR: { id: "KR", msg: "language.KR" },
    KW: { id: "KW", msg: "language.KW" },
    KY: { id: "KY", msg: "language.KY" },
    KZ: { id: "KZ", msg: "language.KZ" },
    LA: { id: "LA", msg: "language.LA" },
    LB: { id: "LB", msg: "language.LB" },
    LC: { id: "LC", msg: "language.LC" },
    LI: { id: "LI", msg: "language.LI" },
    LK: { id: "LK", msg: "language.LK" },
    LR: { id: "LR", msg: "language.LR" },
    LS: { id: "LS", msg: "language.LS" },
    LT: { id: "LT", msg: "language.LT" },
    LU: { id: "LU", msg: "language.LU" },
    LV: { id: "LV", msg: "language.LV" },
    LY: { id: "LY", msg: "language.LY" },
    MA: { id: "MA", msg: "language.MA" },
    MC: { id: "MC", msg: "language.MC" },
    MD: { id: "MD", msg: "language.MD" },
    ME: { id: "ME", msg: "language.ME" },
    MF: { id: "MF", msg: "language.MF" },
    MG: { id: "MG", msg: "language.MG" },
    MH: { id: "MH", msg: "language.MH" },
    MK: { id: "MK", msg: "language.MK" },
    ML: { id: "ML", msg: "language.ML" },
    MM: { id: "MM", msg: "language.MM" },
    MN: { id: "MN", msg: "language.MN" },
    MO: { id: "MO", msg: "language.MO" },
    MP: { id: "MP", msg: "language.MP" },
    MQ: { id: "MQ", msg: "language.MQ" },
    MR: { id: "MR", msg: "language.MR" },
    MS: { id: "MS", msg: "language.MS" },
    MT: { id: "MT", msg: "language.MT" },
    MU: { id: "MU", msg: "language.MU" },
    MV: { id: "MV", msg: "language.MV" },
    MW: { id: "MW", msg: "language.MW" },
    MX: { id: "MX", msg: "language.MX" },
    MY: { id: "MY", msg: "language.MY" },
    MZ: { id: "MZ", msg: "language.MZ" },
    NA: { id: "NA", msg: "language.NA" },
    NC: { id: "NC", msg: "language.NC" },
    NE: { id: "NE", msg: "language.NE" },
    NF: { id: "NF", msg: "language.NF" },
    NG: { id: "NG", msg: "language.NG" },
    NI: { id: "NI", msg: "language.NI" },
    NL: { id: "NL", msg: "language.NL" },
    NO: { id: "NO", msg: "language.NO" },
    NP: { id: "NP", msg: "language.NP" },
    NR: { id: "NR", msg: "language.NR" },
    NU: { id: "NU", msg: "language.NU" },
    NZ: { id: "NZ", msg: "language.NZ" },
    OM: { id: "OM", msg: "language.OM" },
    PA: { id: "PA", msg: "language.PA" },
    PE: { id: "PE", msg: "language.PE" },
    PF: { id: "PF", msg: "language.PF" },
    PG: { id: "PG", msg: "language.PG" },
    PH: { id: "PH", msg: "language.PH" },
    PK: { id: "PK", msg: "language.PK" },
    PL: { id: "PL", msg: "language.PL" },
    PM: { id: "PM", msg: "language.PM" },
    PN: { id: "PN", msg: "language.PN" },
    PR: { id: "PR", msg: "language.PR" },
    PS: { id: "PS", msg: "language.PS" },
    PT: { id: "PT", msg: "language.PT" },
    PW: { id: "PW", msg: "language.PW" },
    PY: { id: "PY", msg: "language.PY" },
    QA: { id: "QA", msg: "language.QA" },
    RE: { id: "RE", msg: "language.RE" },
    RO: { id: "RO", msg: "language.RO" },
    RS: { id: "RS", msg: "language.RS" },
    RU: { id: "RU", msg: "language.RU" },
    RW: { id: "RW", msg: "language.RW" },
    SA: { id: "SA", msg: "language.SA" },
    SB: { id: "SB", msg: "language.SB" },
    SC: { id: "SC", msg: "language.SC" },
    SD: { id: "SD", msg: "language.SD" },
    SE: { id: "SE", msg: "language.SE" },
    SG: { id: "SG", msg: "language.SG" },
    SH: { id: "SH", msg: "language.SH" },
    SI: { id: "SI", msg: "language.SI" },
    SJ: { id: "SJ", msg: "language.SJ" },
    SK: { id: "SK", msg: "language.SK", currencyCode: "EUR", secondaryCountries: ["CZ"] },
    SL: { id: "SL", msg: "language.SL" },
    SM: { id: "SM", msg: "language.SM" },
    SN: { id: "SN", msg: "language.SN" },
    SO: { id: "SO", msg: "language.SO" },
    SR: { id: "SR", msg: "language.SR" },
    SS: { id: "SS", msg: "language.SS" },
    ST: { id: "ST", msg: "language.ST" },
    SV: { id: "SV", msg: "language.SV" },
    SX: { id: "SX", msg: "language.SX" },
    SY: { id: "SY", msg: "language.SY" },
    SZ: { id: "SZ", msg: "language.SZ" },
    TC: { id: "TC", msg: "language.TC" },
    TD: { id: "TD", msg: "language.TD" },
    TF: { id: "TF", msg: "language.TF" },
    TG: { id: "TG", msg: "language.TG" },
    TH: { id: "TH", msg: "language.TH" },
    TJ: { id: "TJ", msg: "language.TJ" },
    TK: { id: "TK", msg: "language.TK" },
    TL: { id: "TL", msg: "language.TL" },
    TM: { id: "TM", msg: "language.TM" },
    TN: { id: "TN", msg: "language.TN" },
    TO: { id: "TO", msg: "language.TO" },
    TR: { id: "TR", msg: "language.TR" },
    TT: { id: "TT", msg: "language.TT" },
    TV: { id: "TV", msg: "language.TV" },
    TW: { id: "TW", msg: "language.TW" },
    TZ: { id: "TZ", msg: "language.TZ" },
    UA: { id: "UA", msg: "language.UA" },
    UG: { id: "UG", msg: "language.UG" },
    UM: { id: "UM", msg: "language.UM" },
    US: { id: "US", msg: "language.US" },
    UY: { id: "UY", msg: "language.UY" },
    UZ: { id: "UZ", msg: "language.UZ" },
    VA: { id: "VA", msg: "language.VA" },
    VC: { id: "VC", msg: "language.VC" },
    VE: { id: "VE", msg: "language.VE" },
    VG: { id: "VG", msg: "language.VG" },
    VI: { id: "VI", msg: "language.VI" },
    VN: { id: "VN", msg: "language.VN" },
    VU: { id: "VU", msg: "language.VU" },
    WF: { id: "WF", msg: "language.WF" },
    WS: { id: "WS", msg: "language.WS" },
    YE: { id: "YE", msg: "language.YE" },
    YT: { id: "YT", msg: "language.YT" },
    ZA: { id: "ZA", msg: "language.ZA" },
    ZM: { id: "ZM", msg: "language.ZM" },
    ZW: { id: "ZW", msg: "language.ZW" },
};
