import React from "react";
import { useSelector } from "react-redux";

import { ContentBox } from "ui-library/layouts";

import coreEnum from "core/enum";
import i18n from "core/i18n";
import { Field, InputText, maxLengthCharacters, required, SingleSelect, validAccountNumber, validAccountNumberPrefix } from "core/form";

import {
    BANK_ACCOUNT_NUMBER_FIELD,
    BANK_ACCOUNT_PREFIX_FIELD,
    BANK_CODE_FIELD,
    SPECIFIC_SYMBOL_FIELD,
    VARIABLE_SYMBOL_FIELD,
} from "../../constants";

export const AccountSection = ({ sectionName }) => {
    const { t } = i18n.useTranslation();
    const bankListTypesSelector = coreEnum.createGetEnum(coreEnum.BANK_LIST);
    const bankListTypes = useSelector(bankListTypesSelector);

    const getLocalizedCodeOptions = i18n.createGetLocalizedCodeOptions(bankListTypes);
    const bankCodeOptions = useSelector(getLocalizedCodeOptions);

    return (
        <>
            <ContentBox>
                <Field
                    name={`${sectionName}.${BANK_ACCOUNT_PREFIX_FIELD}`}
                    label={t("payments.targetAccountPrefix")}
                    component={InputText}
                    validateStatic={[validAccountNumberPrefix]}
                    normalize={maxLengthCharacters(6)}
                    md={6}
                />
            </ContentBox>
            <ContentBox fullWidth>
                <Field
                    name={`${sectionName}.${BANK_ACCOUNT_NUMBER_FIELD}`}
                    label={t("common.accountNumber")}
                    component={InputText}
                    validateStatic={[required, validAccountNumber]}
                    normalize={maxLengthCharacters(10)}
                    md={6}
                />
            </ContentBox>
            <ContentBox fullWidth>
                <Field
                    name={`${sectionName}.${BANK_CODE_FIELD}`}
                    label={t("payments.bankCode")}
                    component={SingleSelect}
                    options={bankCodeOptions}
                    validateStatic={[required]}
                    md={6}
                />
            </ContentBox>
            <ContentBox fullWidth>
                <Field
                    name={`${sectionName}.${VARIABLE_SYMBOL_FIELD}`}
                    label={t("payments.variableSymbol")}
                    component={InputText}
                    normalize={maxLengthCharacters(10)}
                    md={6}
                />
            </ContentBox>
            <ContentBox fullWidth>
                <Field
                    name={`${sectionName}.${SPECIFIC_SYMBOL_FIELD}`}
                    label={t("payments.specificSymbol")}
                    component={InputText}
                    normalize={maxLengthCharacters(10)}
                    md={6}
                />
            </ContentBox>
        </>
    );
};
