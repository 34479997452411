import { model } from "core/util";

const getSubstituteField = (primaryField, secondaryField) => {
    if (primaryField) {
        return primaryField.long_name;
    } else if (secondaryField) {
        return secondaryField.long_name;
    } else {
        return "";
    }
};

/**
 * Substitutes explained:
 * ---
 * ROUTE/LOCALITY
 * Small cities and villages often don't have street names, so we use then name of
 * village as street address (route) instead.
 * ---
 * PREMISE/STREET_NUMBER
 * Premise numbers often exist only in large cities. In small cities, Google API returns
 * premise number as street_number, so we need to set the premise number accordingly.
 * FYI:
 * premise = číslo popisné
 * streetNumber = číslo orientační
 * ---
 * LOCALITY/SUBLOCALITY
 * In big cities, sublocality is often more specific (e.g. Praha 9, instead of Praha),
 * so we use it instead.
 */

export const PlaceDetail = model.createModelLegacy("PlaceDetail", (json) => {
    if (json.address_components) {
        // eslint-disable-next-line camelcase
        const placeDetail = json.address_components.reduce(
            (acc, { types, long_name, short_name }) => ({ ...acc, ...{ [types[0]]: { long_name, short_name } } }),
            {},
        );

        return {
            route: getSubstituteField(placeDetail.route, placeDetail.locality),
            premise: getSubstituteField(placeDetail.premise, placeDetail.street_number),
            street_number: placeDetail.premise && placeDetail.street_number ? placeDetail.street_number.long_name : "",
            locality: getSubstituteField(placeDetail.sublocality_level_1, placeDetail.locality),
            postal_code: placeDetail.postal_code ? placeDetail.postal_code.long_name.replace(/\s+/g, "") : "",
            country: placeDetail.country ? placeDetail.country.short_name : "",
        };
    } else {
        return {
            route: null,
            premise: null,
            street_number: null,
            locality: null,
            postal_code: null,
            country: null,
        };
    }
});
