import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "@mui/material";
import { LicenseInfo } from "@mui/x-license-pro";

import i18n from "core/i18n";
import { EMPTY_ARRAY, EMPTY_OBJECT } from "core/util";
import { Icon, Loading, Typography } from "ui-library/atoms";
import { Box } from "ui-library/layouts";
import { LabelTextItem } from "ui-library/modules";
import { StyledDataTable, StyledDataTableWrapper } from "ui-library/Styled";
import { theme } from "ui-library/theme/theme";

import { TableFooter } from "./TableFooter";
import { TableHeader } from "./TableHeader";

LicenseInfo.setLicenseKey("d2aa22429256d6a322ba03220e1d0c14Tz00NDk5NyxFPTE2ODU3OTg0NDUwNjUsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

const isObjectEmpty = (value) =>
    typeof value === 'undefined' || value === null || value === '' ||
    (typeof value === "object" && Object.keys(value).length === 0 && Object.getPrototypeOf(value) === Object.prototype);

export const DataTable = ({
    actions,
    columns,
    emptyMsg,
    errorMsg,
    filterModel,
    headerHeight,
    hideFooter,
    icon,
    isError,
    isLoading,
    pageSize,
    preContent,
    rowDetail,
    rowHeight,
    rows,
    rowsPerPageOptions,
    summaryRow,
    title,
    withWrapper,
    ...props
}: // TODO: martin.rodin type the table later
{
    [prop: string]: any;
}) => {
    const { t } = i18n.useTranslation();
    const baseRef = useRef();
    const matches = useMediaQuery(theme.breakpoints.down("md"));

    const [internalPageSize, setInternalPageSize] = useState(pageSize);
    const [isScrollable, setIsScrollable] = useState(false);

    // TODO: this doesn't work properly, discuss with Karol
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (baseRef.current) {
            // @ts-ignore TODO: martin.rodin type later
            setIsScrollable(baseRef.current.clientWidth < baseRef.current.scrollWidth);
        }
    });

    const isEmpty = !rows || rows.length === 0;
    const emptyMessage = emptyMsg ? emptyMsg : t("common.noData");
    const errorMessage = errorMsg ? errorMsg : t("error.sectionUnavailable");
    // determine which columns are hidden
    const hiddenColumns = columns.filter((column) => column.hide);
    // defines height of master detail 1 item
    const labelHeight = matches ? 75 : 33;

    // add min width to columns
    columns.forEach((item) => {
        if (!item.minWidth) {
            item.minWidth = 100;
        }
    });

    return (
        <StyledDataTableWrapper $withWrapper={withWrapper}>
            <TableHeader actions={actions} icon={icon} isEmpty={isEmpty} isLoading={isLoading} title={title} />

            {preContent && preContent}

            <Loading loading={isLoading}>
                {isError && <CustomMessage message={errorMessage} />}
                {!isError && isEmpty && <CustomMessage message={emptyMessage} />}
                {!isError && !isEmpty && (
                    <StyledDataTable
                        ref={baseRef}
                        $hasScrollBar={isScrollable}
                        $summaryRow={summaryRow}
                        getDetailPanelContent={rowDetail ? ({ row }) => <RowDetail row={row} hiddenColumns={hiddenColumns} /> : null}
                        getDetailPanelHeight={({ row }) => hiddenColumns.length * labelHeight + 40} // optional, default is 500px
                        getRowClassName={(params) => `${params.row.style ? params.row.style : ""}`}
                        rowHeight={matches ? 52 : rowHeight}
                        pagination={!hideFooter}
                        headerHeight={headerHeight}
                        pageSize={isObjectEmpty(summaryRow) ? internalPageSize : 100}
                        onPageSizeChange={(newPageSize) => setInternalPageSize(newPageSize)}
                        rowsPerPageOptions={rowsPerPageOptions}
                        autoHeight
                        rows={rows}
                        columns={columns}
                        disableColumnFilter={true}
                        disableColumnMenu={true}
                        disableSelectionOnClick
                        hideFooter={hideFooter}
                        filterModel={{ items: filterModel }}
                        components={{
                            DetailPanelCollapseIcon: CollapseIcon,
                            DetailPanelExpandIcon: ExpandIcon,
                            Footer: TableFooter,
                        }}
                        componentsProps={{
                            pagination: { rowsPerPageOptions },
                            footer: { summaryRow, hideFooter, rowsPerPageOptions },
                        }}
                        {...props}
                    />
                )}
            </Loading>
        </StyledDataTableWrapper>
    );
};

DataTable.defaultProps = {
    actions: null,
    emptyMsg: "",
    errorMsg: "",
    filterModel: EMPTY_ARRAY,
    headerHeight: 42,
    hideFooter: false,
    icon: "",
    isError: false,
    isLoading: false,
    pageSize: 10,
    preContent: null,
    rowDetail: false,
    rowHeight: 42,
    rows: EMPTY_ARRAY,
    rowsPerPageOptions: [5, 10, 15, 20],
    summaryRow: EMPTY_OBJECT,
    title: "",
    withWrapper: true,
};

DataTable.propTypes = {
    actions: PropTypes.node,
    columns: PropTypes.arrayOf(PropTypes.object),
    emptyMsg: PropTypes.string,
    errorMsg: PropTypes.string,
    filterModel: PropTypes.array,
    headerHeight: PropTypes.number,
    hideFooter: PropTypes.bool,
    icon: PropTypes.string,
    isError: PropTypes.bool,
    isLoading: PropTypes.bool,
    pageSize: PropTypes.number,
    preContent: PropTypes.node,
    rowDetail: PropTypes.bool,
    rowHeight: PropTypes.number,
    rows: PropTypes.arrayOf(PropTypes.object),
    rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
    summaryRow: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    withWrapper: PropTypes.bool,
};

const CustomMessage = ({ message }) => <Typography>{message}</Typography>;

CustomMessage.propTypes = {
    message: PropTypes.string.isRequired,
};

const RowDetail = ({ row, hiddenColumns }) => (
    <Box my={2} pl={7.5}>
        <>
            {hiddenColumns.map((item, index) => (
                <LabelTextItem key={index} label={item.headerName} text={row[item.field]} />
            ))}
        </>
    </Box>
);

RowDetail.propTypes = {
    row: PropTypes.object.isRequired,
    hiddenColumns: PropTypes.array.isRequired,
};

const ExpandIcon = () => <Icon size={14} icon="chevron-down" />;
const CollapseIcon = () => <Icon size={14} icon="chevron-up" />;
