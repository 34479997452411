import { createSlice } from "@reduxjs/toolkit";
import { initialState, NAME } from "./constants";
import { initializeReducer } from "./reducer";

export const slice = createSlice({
    name: NAME,
    initialState,
    reducers: {
        initialize: initializeReducer,
    },
});

export default slice;
