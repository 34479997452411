import { combineReducers as immutableCombineReducers } from "redux-immutable";
import { combineReducers, createReducer } from "@reduxjs/toolkit";
import { List } from "immutable";

import { MutualFundsRegular } from "model/mutualFunds/regular";
import { app } from "core/util";
import { MutualFundsRegularContract } from "types";

import { clear, setMutualFundsRegularContractDetail, CLEAR, SET_AVAILABLE_PROFILES, SET_SELECTED_FUND } from "./actions";
import {
    ActualStatements,
    ChangePropositionModule,
    ExtraStatements,
    FinancialOperationsTableFilter,
    OtpModule,
    YearStatements,
} from "./modules";

const mutualFundsRegularContractDetail = createReducer<MutualFundsRegularContract>(MutualFundsRegular.fromServer(), (builder) => {
    builder.addCase(setMutualFundsRegularContractDetail, (_, { payload }) => payload);
    builder.addCase(clear, () => MutualFundsRegular.fromServer());
});

export default immutableCombineReducers({
    data: combineReducers({
        mutualFundsRegularContractDetail,
        availableProfiles: app.createDataReducer(SET_AVAILABLE_PROFILES, List(), CLEAR),
    }),
    appState: immutableCombineReducers({
        yearStatements: YearStatements.reducer,
        extraStatements: ExtraStatements.reducer,
        actualStatements: ActualStatements.reducer,
        otp: OtpModule.reducer,
        selectedFund: app.createDataReducer(SET_SELECTED_FUND, null, CLEAR),
        changeProposition: ChangePropositionModule.reducer,
        financialOperationsFilter: FinancialOperationsTableFilter.reducer,
    }),
});
