import fundsSellSwitchForm from "form/mutualFundsSellSwitch";
import { getLocalizedFundSellMeansDirect } from "core/localized";

import {
    getTargetFundCodes,
    getSourceFundCodes,
    getFundSellTypeOptions,
    getFundSellTargetOptions,
    createGetSourceFundOptions,
    createGetTargetFundOptions,
    getIdObject,
    getShouldCheckIbanCountry,
} from "../selectors";
import { OtpModule } from "../modules";
import { FORM_SELL_SWITCH } from "../constants";

export const FundsSellSwitchForm = fundsSellSwitchForm.createContainerDirect(
    FORM_SELL_SWITCH,
    OtpModule,
    getTargetFundCodes,
    getSourceFundCodes,
    createGetSourceFundOptions,
    createGetTargetFundOptions,
    getFundSellTypeOptions,
    getFundSellTargetOptions,
    getLocalizedFundSellMeansDirect,
    getIdObject,
    getShouldCheckIbanCountry,
    true,
);
