import React from "react";
import { useSelector } from "react-redux";

import { Field, SingleSelect } from "core/form";
import i18n from "core/i18n";

import { getFinancialOperationsTypes } from "../selectors";

export const FinancialOperationsBasicFilter = () => {
    const { t } = i18n.useTranslation();
    const typeOptions = useSelector(getFinancialOperationsTypes);

    return <Field name="type" component={SingleSelect} options={typeOptions} placeholder={t("pension.financialOperation.selectType")} />;
};
