import React from "react";
import PropTypes from "prop-types";

import { Typography } from "ui-library/atoms";
import { InfoPanel } from "ui-library/modules";
import { ContentBox } from "ui-library/layouts";

export const AlertBox = ({ mb, text }) => (
    <InfoPanel bgVariant={"blueGradient"} mb={mb}>
        <ContentBox>
            <Typography markDown={text} />
        </ContentBox>
    </InfoPanel>
);

AlertBox.propTypes = {
    mb: PropTypes.number,
    text: PropTypes.string.isRequired,
};

AlertBox.defaultProps = {
    mb: 0,
};
