/* tslint:disable */
/* eslint-disable */
/**
 *
 * @export
 * @interface AccountInfoDto
 */
export interface AccountInfoDto {
    /**
     *
     * @type {string}
     * @memberof AccountInfoDto
     */
    Username: string;
    /**
     *
     * @type {string}
     * @memberof AccountInfoDto
     */
    LastPasswordChange: string;
    /**
     *
     * @type {string}
     * @memberof AccountInfoDto
     */
    LastLogin: string;
    /**
     *
     * @type {string}
     * @memberof AccountInfoDto
     */
    LastLoginFailed: string;
    /**
     *
     * @type {number}
     * @memberof AccountInfoDto
     */
    FailedLoginsCount: number;
    /**
     *
     * @type {boolean}
     * @memberof AccountInfoDto
     */
    UserEnabled: boolean;
    /**
     *
     * @type {boolean}
     * @memberof AccountInfoDto
     */
    IsPasswordExpired: boolean;
}
/**
 *
 * @export
 * @interface AddressDto
 */
export interface AddressDto {
    /**
     *
     * @type {string}
     * @memberof AddressDto
     */
    AddressTypeCode: AddressTypeCodeEnum;
    /**
     *
     * @type {string}
     * @memberof AddressDto
     */
    Street: string;
    /**
     *
     * @type {string}
     * @memberof AddressDto
     */
    OrientationNumber: string;
    /**
     *
     * @type {string}
     * @memberof AddressDto
     */
    DescriptionNumber: string;
    /**
     *
     * @type {string}
     * @memberof AddressDto
     */
    City: string;
    /**
     *
     * @type {string}
     * @memberof AddressDto
     */
    PostalCode: string;
    /**
     *
     * @type {string}
     * @memberof AddressDto
     */
    CountryCode: AddressDtoCountryCodeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum AddressTypeCodeEnum {
    PER = "PER",
    CON = "CON",
}
/**
 * @export
 * @enum {string}
 */
export enum AddressDtoCountryCodeEnum {
    Ad = "AD",
    Ae = "AE",
    Af = "AF",
    Ag = "AG",
    Ai = "AI",
    Al = "AL",
    Am = "AM",
    Ao = "AO",
    Aq = "AQ",
    Ar = "AR",
    As = "AS",
    At = "AT",
    Au = "AU",
    Aw = "AW",
    Ax = "AX",
    Az = "AZ",
    Ba = "BA",
    Bb = "BB",
    Bd = "BD",
    Be = "BE",
    Bf = "BF",
    Bg = "BG",
    Bh = "BH",
    Bi = "BI",
    Bj = "BJ",
    Bl = "BL",
    Bm = "BM",
    Bn = "BN",
    Bo = "BO",
    Bq = "BQ",
    Br = "BR",
    Bs = "BS",
    Bt = "BT",
    Bv = "BV",
    Bw = "BW",
    By = "BY",
    Bz = "BZ",
    Ca = "CA",
    Cc = "CC",
    Cd = "CD",
    Cf = "CF",
    Cg = "CG",
    Ci = "CI",
    Ck = "CK",
    Cl = "CL",
    Cm = "CM",
    Cn = "CN",
    Co = "CO",
    Cr = "CR",
    Cu = "CU",
    Cv = "CV",
    Cw = "CW",
    Cx = "CX",
    Cy = "CY",
    Cz = "CZ",
    De = "DE",
    Dj = "DJ",
    Dk = "DK",
    Dm = "DM",
    Do = "DO",
    Dz = "DZ",
    Ec = "EC",
    Ee = "EE",
    Eg = "EG",
    Eh = "EH",
    Er = "ER",
    Es = "ES",
    Et = "ET",
    Fi = "FI",
    Fj = "FJ",
    Fk = "FK",
    Fm = "FM",
    Fo = "FO",
    Fr = "FR",
    Ga = "GA",
    Gb = "GB",
    Gd = "GD",
    Ge = "GE",
    Gf = "GF",
    Gg = "GG",
    Gh = "GH",
    Gi = "GI",
    Gl = "GL",
    Gm = "GM",
    Gn = "GN",
    Gp = "GP",
    Gq = "GQ",
    Gr = "GR",
    Gs = "GS",
    Gt = "GT",
    Gu = "GU",
    Gw = "GW",
    Gy = "GY",
    Hk = "HK",
    Hm = "HM",
    Hn = "HN",
    Hr = "HR",
    Ht = "HT",
    Hu = "HU",
    Ch = "CH",
    Id = "ID",
    Ie = "IE",
    Il = "IL",
    Im = "IM",
    In = "IN",
    Io = "IO",
    Iq = "IQ",
    Ir = "IR",
    Is = "IS",
    It = "IT",
    Je = "JE",
    Jm = "JM",
    Jo = "JO",
    Jp = "JP",
    Ke = "KE",
    Kg = "KG",
    Kh = "KH",
    Ki = "KI",
    Km = "KM",
    Kn = "KN",
    Kp = "KP",
    Kr = "KR",
    Kw = "KW",
    Ky = "KY",
    Kz = "KZ",
    La = "LA",
    Lb = "LB",
    Lc = "LC",
    Li = "LI",
    Lk = "LK",
    Lr = "LR",
    Ls = "LS",
    Lt = "LT",
    Lu = "LU",
    Lv = "LV",
    Ly = "LY",
    Ma = "MA",
    Mc = "MC",
    Md = "MD",
    Me = "ME",
    Mf = "MF",
    Mg = "MG",
    Mh = "MH",
    Mk = "MK",
    Ml = "ML",
    Mm = "MM",
    Mn = "MN",
    Mo = "MO",
    Mp = "MP",
    Mq = "MQ",
    Mr = "MR",
    Ms = "MS",
    Mt = "MT",
    Mu = "MU",
    Mv = "MV",
    Mw = "MW",
    Mx = "MX",
    My = "MY",
    Mz = "MZ",
    Na = "NA",
    Nc = "NC",
    Ne = "NE",
    Nf = "NF",
    Ng = "NG",
    Ni = "NI",
    Nl = "NL",
    No = "NO",
    Np = "NP",
    Nr = "NR",
    Nu = "NU",
    Nz = "NZ",
    Om = "OM",
    Pa = "PA",
    Pe = "PE",
    Pf = "PF",
    Pg = "PG",
    Ph = "PH",
    Pk = "PK",
    Pl = "PL",
    Pm = "PM",
    Pn = "PN",
    Pr = "PR",
    Ps = "PS",
    Pt = "PT",
    Pw = "PW",
    Py = "PY",
    Qa = "QA",
    Re = "RE",
    Ro = "RO",
    Rs = "RS",
    Ru = "RU",
    Rw = "RW",
    Sa = "SA",
    Sb = "SB",
    Sc = "SC",
    Sd = "SD",
    Se = "SE",
    Sg = "SG",
    Sh = "SH",
    Si = "SI",
    Sj = "SJ",
    Sk = "SK",
    Sl = "SL",
    Sm = "SM",
    Sn = "SN",
    So = "SO",
    Sr = "SR",
    Ss = "SS",
    St = "ST",
    Sv = "SV",
    Sx = "SX",
    Sy = "SY",
    Sz = "SZ",
    Tc = "TC",
    Td = "TD",
    Tf = "TF",
    Tg = "TG",
    Th = "TH",
    Tj = "TJ",
    Tk = "TK",
    Tl = "TL",
    Tm = "TM",
    Tn = "TN",
    To = "TO",
    Tr = "TR",
    Tt = "TT",
    Tv = "TV",
    Tw = "TW",
    Tz = "TZ",
    Ua = "UA",
    Ug = "UG",
    Um = "UM",
    Us = "US",
    Uy = "UY",
    Uz = "UZ",
    Va = "VA",
    Vc = "VC",
    Ve = "VE",
    Vg = "VG",
    Vi = "VI",
    Vn = "VN",
    Vu = "VU",
    Wf = "WF",
    Ws = "WS",
    Ye = "YE",
    Yt = "YT",
    Za = "ZA",
    Zm = "ZM",
    Zw = "ZW",
}
/**
 *
 * @export
 * @interface AgentDto
 */
export interface AgentDto {
    /**
     *
     * @type {number}
     * @memberof AgentDto
     */
    IdAgentContractAgent: number;
    /**
     *
     * @type {AgentLegalEntityDto}
     * @memberof AgentDto
     */
    Agent: AgentLegalEntityDto;
    /**
     *
     * @type {BranchDto}
     * @memberof AgentDto
     */
    Branch: BranchDto;
    /**
     *
     * @type {SocialNetworkDto}
     * @memberof AgentDto
     */
    SocialNetwork: SocialNetworkDto;
    /**
     *
     * @type {Array<string>}
     * @memberof AgentDto
     */
    ProductList: Array<string>;
}
/**
 *
 * @export
 * @interface AgentLegalEntityDto
 */
export interface AgentLegalEntityDto {
    /**
     *
     * @type {string}
     * @memberof AgentLegalEntityDto
     */
    CountryCode: AgentLegalEntityDtoCountryCodeEnum;
    /**
     *
     * @type {string}
     * @memberof AgentLegalEntityDto
     */
    Description: string;
    /**
     *
     * @type {string}
     * @memberof AgentLegalEntityDto
     */
    FirstName: string;
    /**
     *
     * @type {string}
     * @memberof AgentLegalEntityDto
     */
    LastName: string;
    /**
     *
     * @type {string}
     * @memberof AgentLegalEntityDto
     */
    BirthLastName: string;
    /**
     *
     * @type {string}
     * @memberof AgentLegalEntityDto
     */
    TitleBefore: string;
    /**
     *
     * @type {string}
     * @memberof AgentLegalEntityDto
     */
    TitleAfter: string;
    /**
     *
     * @type {string}
     * @memberof AgentLegalEntityDto
     */
    SexCode: string;
    /**
     *
     * @type {string}
     * @memberof AgentLegalEntityDto
     */
    LegalPersonalityCode: AgentLegalEntityDtoLegalPersonalityCodeEnum;
    /**
     *
     * @type {string}
     * @memberof AgentLegalEntityDto
     */
    BirthDate: string;
    /**
     *
     * @type {string}
     * @memberof AgentLegalEntityDto
     */
    BirthPlace: string;
    /**
     *
     * @type {string}
     * @memberof AgentLegalEntityDto
     */
    BirthNumber: string;
    /**
     *
     * @type {string}
     * @memberof AgentLegalEntityDto
     */
    CompanyName: string;
    /**
     *
     * @type {string}
     * @memberof AgentLegalEntityDto
     */
    CompanyID: string;
    /**
     *
     * @type {string}
     * @memberof AgentLegalEntityDto
     */
    Citizenship: string;
    /**
     *
     * @type {Array<IdDto>}
     * @memberof AgentLegalEntityDto
     */
    IdList: Array<IdDto>;
    /**
     *
     * @type {Array<AddressDto>}
     * @memberof AgentLegalEntityDto
     */
    AddressList: Array<AddressDto>;
    /**
     *
     * @type {string}
     * @memberof AgentLegalEntityDto
     */
    Email: string;
    /**
     *
     * @type {string}
     * @memberof AgentLegalEntityDto
     */
    Phone: string;
    /**
     *
     * @type {number}
     * @memberof AgentLegalEntityDto
     */
    IdSubject: number;
    /**
     *
     * @type {string}
     * @memberof AgentLegalEntityDto
     */
    SocialSecurityNumber: string;
    /**
     *
     * @type {string}
     * @memberof AgentLegalEntityDto
     */
    InasId: string;
    /**
     *
     * @type {string}
     * @memberof AgentLegalEntityDto
     */
    InasContactId: string;
    /**
     *
     * @type {boolean}
     * @memberof AgentLegalEntityDto
     */
    AddressesIdentical: boolean;
    /**
     *
     * @type {boolean}
     * @memberof AgentLegalEntityDto
     */
    ShowPersonInContactAddressForm: boolean;
    /**
     *
     * @type {string}
     * @memberof AgentLegalEntityDto
     */
    DocumentPin: string;
    /**
     *
     * @type {number}
     * @memberof AgentLegalEntityDto
     */
    IdAgentPrimary: number;
    /**
     *
     * @type {number}
     * @memberof AgentLegalEntityDto
     */
    IdAgentIason: number;
    /**
     *
     * @type {boolean}
     * @memberof AgentLegalEntityDto
     */
    Is: boolean;
    /**
     *
     * @type {boolean}
     * @memberof AgentLegalEntityDto
     */
    Private: boolean;
    /**
     *
     * @type {boolean}
     * @memberof AgentLegalEntityDto
     */
    Mobile: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum AgentLegalEntityDtoCountryCodeEnum {
    Ad = "AD",
    Ae = "AE",
    Af = "AF",
    Ag = "AG",
    Ai = "AI",
    Al = "AL",
    Am = "AM",
    Ao = "AO",
    Aq = "AQ",
    Ar = "AR",
    As = "AS",
    At = "AT",
    Au = "AU",
    Aw = "AW",
    Ax = "AX",
    Az = "AZ",
    Ba = "BA",
    Bb = "BB",
    Bd = "BD",
    Be = "BE",
    Bf = "BF",
    Bg = "BG",
    Bh = "BH",
    Bi = "BI",
    Bj = "BJ",
    Bl = "BL",
    Bm = "BM",
    Bn = "BN",
    Bo = "BO",
    Bq = "BQ",
    Br = "BR",
    Bs = "BS",
    Bt = "BT",
    Bv = "BV",
    Bw = "BW",
    By = "BY",
    Bz = "BZ",
    Ca = "CA",
    Cc = "CC",
    Cd = "CD",
    Cf = "CF",
    Cg = "CG",
    Ci = "CI",
    Ck = "CK",
    Cl = "CL",
    Cm = "CM",
    Cn = "CN",
    Co = "CO",
    Cr = "CR",
    Cu = "CU",
    Cv = "CV",
    Cw = "CW",
    Cx = "CX",
    Cy = "CY",
    Cz = "CZ",
    De = "DE",
    Dj = "DJ",
    Dk = "DK",
    Dm = "DM",
    Do = "DO",
    Dz = "DZ",
    Ec = "EC",
    Ee = "EE",
    Eg = "EG",
    Eh = "EH",
    Er = "ER",
    Es = "ES",
    Et = "ET",
    Fi = "FI",
    Fj = "FJ",
    Fk = "FK",
    Fm = "FM",
    Fo = "FO",
    Fr = "FR",
    Ga = "GA",
    Gb = "GB",
    Gd = "GD",
    Ge = "GE",
    Gf = "GF",
    Gg = "GG",
    Gh = "GH",
    Gi = "GI",
    Gl = "GL",
    Gm = "GM",
    Gn = "GN",
    Gp = "GP",
    Gq = "GQ",
    Gr = "GR",
    Gs = "GS",
    Gt = "GT",
    Gu = "GU",
    Gw = "GW",
    Gy = "GY",
    Hk = "HK",
    Hm = "HM",
    Hn = "HN",
    Hr = "HR",
    Ht = "HT",
    Hu = "HU",
    Ch = "CH",
    Id = "ID",
    Ie = "IE",
    Il = "IL",
    Im = "IM",
    In = "IN",
    Io = "IO",
    Iq = "IQ",
    Ir = "IR",
    Is = "IS",
    It = "IT",
    Je = "JE",
    Jm = "JM",
    Jo = "JO",
    Jp = "JP",
    Ke = "KE",
    Kg = "KG",
    Kh = "KH",
    Ki = "KI",
    Km = "KM",
    Kn = "KN",
    Kp = "KP",
    Kr = "KR",
    Kw = "KW",
    Ky = "KY",
    Kz = "KZ",
    La = "LA",
    Lb = "LB",
    Lc = "LC",
    Li = "LI",
    Lk = "LK",
    Lr = "LR",
    Ls = "LS",
    Lt = "LT",
    Lu = "LU",
    Lv = "LV",
    Ly = "LY",
    Ma = "MA",
    Mc = "MC",
    Md = "MD",
    Me = "ME",
    Mf = "MF",
    Mg = "MG",
    Mh = "MH",
    Mk = "MK",
    Ml = "ML",
    Mm = "MM",
    Mn = "MN",
    Mo = "MO",
    Mp = "MP",
    Mq = "MQ",
    Mr = "MR",
    Ms = "MS",
    Mt = "MT",
    Mu = "MU",
    Mv = "MV",
    Mw = "MW",
    Mx = "MX",
    My = "MY",
    Mz = "MZ",
    Na = "NA",
    Nc = "NC",
    Ne = "NE",
    Nf = "NF",
    Ng = "NG",
    Ni = "NI",
    Nl = "NL",
    No = "NO",
    Np = "NP",
    Nr = "NR",
    Nu = "NU",
    Nz = "NZ",
    Om = "OM",
    Pa = "PA",
    Pe = "PE",
    Pf = "PF",
    Pg = "PG",
    Ph = "PH",
    Pk = "PK",
    Pl = "PL",
    Pm = "PM",
    Pn = "PN",
    Pr = "PR",
    Ps = "PS",
    Pt = "PT",
    Pw = "PW",
    Py = "PY",
    Qa = "QA",
    Re = "RE",
    Ro = "RO",
    Rs = "RS",
    Ru = "RU",
    Rw = "RW",
    Sa = "SA",
    Sb = "SB",
    Sc = "SC",
    Sd = "SD",
    Se = "SE",
    Sg = "SG",
    Sh = "SH",
    Si = "SI",
    Sj = "SJ",
    Sk = "SK",
    Sl = "SL",
    Sm = "SM",
    Sn = "SN",
    So = "SO",
    Sr = "SR",
    Ss = "SS",
    St = "ST",
    Sv = "SV",
    Sx = "SX",
    Sy = "SY",
    Sz = "SZ",
    Tc = "TC",
    Td = "TD",
    Tf = "TF",
    Tg = "TG",
    Th = "TH",
    Tj = "TJ",
    Tk = "TK",
    Tl = "TL",
    Tm = "TM",
    Tn = "TN",
    To = "TO",
    Tr = "TR",
    Tt = "TT",
    Tv = "TV",
    Tw = "TW",
    Tz = "TZ",
    Ua = "UA",
    Ug = "UG",
    Um = "UM",
    Us = "US",
    Uy = "UY",
    Uz = "UZ",
    Va = "VA",
    Vc = "VC",
    Ve = "VE",
    Vg = "VG",
    Vi = "VI",
    Vn = "VN",
    Vu = "VU",
    Wf = "WF",
    Ws = "WS",
    Ye = "YE",
    Yt = "YT",
    Za = "ZA",
    Zm = "ZM",
    Zw = "ZW",
}
/**
 * @export
 * @enum {string}
 */
export enum AgentLegalEntityDtoLegalPersonalityCodeEnum {
    Fo = "FO",
    Po = "PO",
}
/**
 *
 * @export
 * @interface AmountDto
 */
export interface AmountDto {
    /**
     *
     * @type {string}
     * @memberof AmountDto
     */
    CurrencyCode: string;
    /**
     *
     * @type {number}
     * @memberof AmountDto
     */
    Value: number;
}
/**
 *
 * @export
 * @interface AnnouncementDto
 */
export interface AnnouncementDto {
    /**
     *
     * @type {number}
     * @memberof AnnouncementDto
     */
    Id: number;
    /**
     *
     * @type {string}
     * @memberof AnnouncementDto
     */
    Subject: string;
    /**
     *
     * @type {string}
     * @memberof AnnouncementDto
     */
    Content: string;
    /**
     *
     * @type {string}
     * @memberof AnnouncementDto
     */
    Date: string;
    /**
     *
     * @type {string}
     * @memberof AnnouncementDto
     */
    StatusCode: AnnouncementDtoStatusCodeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum AnnouncementDtoStatusCodeEnum {
    Read = "READ",
    Unread = "UNREAD",
}
/**
 *
 * @export
 * @interface AuthenticatedClientDto
 */
export interface AuthenticatedClientDto {
    /**
     *
     * @type {LegalEntityDto}
     * @memberof AuthenticatedClientDto
     */
    Client: LegalEntityDto;
    /**
     *
     * @type {ClientVerificationDto}
     * @memberof AuthenticatedClientDto
     */
    Verification: ClientVerificationDto;
    /**
     *
     * @type {ClientConsentDto}
     * @memberof AuthenticatedClientDto
     */
    Consent: ClientConsentDto;
    /**
     *
     * @type {ClientDisclaimerDto}
     * @memberof AuthenticatedClientDto
     */
    Disclaimer: ClientDisclaimerDto;
}
/**
 *
 * @export
 * @interface BankAccountDto
 */
export interface BankAccountDto {
    /**
     *
     * @type {string}
     * @memberof BankAccountDto
     */
    Iban: string;
    /**
     *
     * @type {string}
     * @memberof BankAccountDto
     */
    Prefix: string;
    /**
     *
     * @type {string}
     * @memberof BankAccountDto
     */
    Number: string;
    /**
     *
     * @type {string}
     * @memberof BankAccountDto
     */
    BankCode: string;
    /**
     *
     * @type {string}
     * @memberof BankAccountDto
     */
    VariableSymbol: string;
    /**
     *
     * @type {string}
     * @memberof BankAccountDto
     */
    SpecificSymbol: string;
}
/**
 *
 * @export
 * @interface BranchDto
 */
export interface BranchDto {
    /**
     *
     * @type {string}
     * @memberof BranchDto
     */
    AddressTypeCode: BranchDtoAddressTypeCodeEnum;
    /**
     *
     * @type {string}
     * @memberof BranchDto
     */
    Street: string;
    /**
     *
     * @type {string}
     * @memberof BranchDto
     */
    OrientationNumber: string;
    /**
     *
     * @type {string}
     * @memberof BranchDto
     */
    DescriptionNumber: string;
    /**
     *
     * @type {string}
     * @memberof BranchDto
     */
    City: string;
    /**
     *
     * @type {string}
     * @memberof BranchDto
     */
    PostalCode: string;
    /**
     *
     * @type {string}
     * @memberof BranchDto
     */
    CountryCode: BranchDtoCountryCodeEnum;
    /**
     *
     * @type {number}
     * @memberof BranchDto
     */
    Latitude: number;
    /**
     *
     * @type {number}
     * @memberof BranchDto
     */
    Longitude: number;
    /**
     *
     * @type {OpeningTimesDto}
     * @memberof BranchDto
     */
    OpeningTimes: OpeningTimesDto;
}

/**
 * @export
 * @enum {string}
 */
export enum BranchDtoAddressTypeCodeEnum {
    Per = "PER",
    Con = "CON",
}
/**
 * @export
 * @enum {string}
 */
export enum BranchDtoCountryCodeEnum {
    Ad = "AD",
    Ae = "AE",
    Af = "AF",
    Ag = "AG",
    Ai = "AI",
    Al = "AL",
    Am = "AM",
    Ao = "AO",
    Aq = "AQ",
    Ar = "AR",
    As = "AS",
    At = "AT",
    Au = "AU",
    Aw = "AW",
    Ax = "AX",
    Az = "AZ",
    Ba = "BA",
    Bb = "BB",
    Bd = "BD",
    Be = "BE",
    Bf = "BF",
    Bg = "BG",
    Bh = "BH",
    Bi = "BI",
    Bj = "BJ",
    Bl = "BL",
    Bm = "BM",
    Bn = "BN",
    Bo = "BO",
    Bq = "BQ",
    Br = "BR",
    Bs = "BS",
    Bt = "BT",
    Bv = "BV",
    Bw = "BW",
    By = "BY",
    Bz = "BZ",
    Ca = "CA",
    Cc = "CC",
    Cd = "CD",
    Cf = "CF",
    Cg = "CG",
    Ci = "CI",
    Ck = "CK",
    Cl = "CL",
    Cm = "CM",
    Cn = "CN",
    Co = "CO",
    Cr = "CR",
    Cu = "CU",
    Cv = "CV",
    Cw = "CW",
    Cx = "CX",
    Cy = "CY",
    Cz = "CZ",
    De = "DE",
    Dj = "DJ",
    Dk = "DK",
    Dm = "DM",
    Do = "DO",
    Dz = "DZ",
    Ec = "EC",
    Ee = "EE",
    Eg = "EG",
    Eh = "EH",
    Er = "ER",
    Es = "ES",
    Et = "ET",
    Fi = "FI",
    Fj = "FJ",
    Fk = "FK",
    Fm = "FM",
    Fo = "FO",
    Fr = "FR",
    Ga = "GA",
    Gb = "GB",
    Gd = "GD",
    Ge = "GE",
    Gf = "GF",
    Gg = "GG",
    Gh = "GH",
    Gi = "GI",
    Gl = "GL",
    Gm = "GM",
    Gn = "GN",
    Gp = "GP",
    Gq = "GQ",
    Gr = "GR",
    Gs = "GS",
    Gt = "GT",
    Gu = "GU",
    Gw = "GW",
    Gy = "GY",
    Hk = "HK",
    Hm = "HM",
    Hn = "HN",
    Hr = "HR",
    Ht = "HT",
    Hu = "HU",
    Ch = "CH",
    Id = "ID",
    Ie = "IE",
    Il = "IL",
    Im = "IM",
    In = "IN",
    Io = "IO",
    Iq = "IQ",
    Ir = "IR",
    Is = "IS",
    It = "IT",
    Je = "JE",
    Jm = "JM",
    Jo = "JO",
    Jp = "JP",
    Ke = "KE",
    Kg = "KG",
    Kh = "KH",
    Ki = "KI",
    Km = "KM",
    Kn = "KN",
    Kp = "KP",
    Kr = "KR",
    Kw = "KW",
    Ky = "KY",
    Kz = "KZ",
    La = "LA",
    Lb = "LB",
    Lc = "LC",
    Li = "LI",
    Lk = "LK",
    Lr = "LR",
    Ls = "LS",
    Lt = "LT",
    Lu = "LU",
    Lv = "LV",
    Ly = "LY",
    Ma = "MA",
    Mc = "MC",
    Md = "MD",
    Me = "ME",
    Mf = "MF",
    Mg = "MG",
    Mh = "MH",
    Mk = "MK",
    Ml = "ML",
    Mm = "MM",
    Mn = "MN",
    Mo = "MO",
    Mp = "MP",
    Mq = "MQ",
    Mr = "MR",
    Ms = "MS",
    Mt = "MT",
    Mu = "MU",
    Mv = "MV",
    Mw = "MW",
    Mx = "MX",
    My = "MY",
    Mz = "MZ",
    Na = "NA",
    Nc = "NC",
    Ne = "NE",
    Nf = "NF",
    Ng = "NG",
    Ni = "NI",
    Nl = "NL",
    No = "NO",
    Np = "NP",
    Nr = "NR",
    Nu = "NU",
    Nz = "NZ",
    Om = "OM",
    Pa = "PA",
    Pe = "PE",
    Pf = "PF",
    Pg = "PG",
    Ph = "PH",
    Pk = "PK",
    Pl = "PL",
    Pm = "PM",
    Pn = "PN",
    Pr = "PR",
    Ps = "PS",
    Pt = "PT",
    Pw = "PW",
    Py = "PY",
    Qa = "QA",
    Re = "RE",
    Ro = "RO",
    Rs = "RS",
    Ru = "RU",
    Rw = "RW",
    Sa = "SA",
    Sb = "SB",
    Sc = "SC",
    Sd = "SD",
    Se = "SE",
    Sg = "SG",
    Sh = "SH",
    Si = "SI",
    Sj = "SJ",
    Sk = "SK",
    Sl = "SL",
    Sm = "SM",
    Sn = "SN",
    So = "SO",
    Sr = "SR",
    Ss = "SS",
    St = "ST",
    Sv = "SV",
    Sx = "SX",
    Sy = "SY",
    Sz = "SZ",
    Tc = "TC",
    Td = "TD",
    Tf = "TF",
    Tg = "TG",
    Th = "TH",
    Tj = "TJ",
    Tk = "TK",
    Tl = "TL",
    Tm = "TM",
    Tn = "TN",
    To = "TO",
    Tr = "TR",
    Tt = "TT",
    Tv = "TV",
    Tw = "TW",
    Tz = "TZ",
    Ua = "UA",
    Ug = "UG",
    Um = "UM",
    Us = "US",
    Uy = "UY",
    Uz = "UZ",
    Va = "VA",
    Vc = "VC",
    Ve = "VE",
    Vg = "VG",
    Vi = "VI",
    Vn = "VN",
    Vu = "VU",
    Wf = "WF",
    Ws = "WS",
    Ye = "YE",
    Yt = "YT",
    Za = "ZA",
    Zm = "ZM",
    Zw = "ZW",
}
/**
 *
 * @export
 * @interface ChangePropositionDto
 */
export interface ChangePropositionDto {
    /**
     *
     * @type {string}
     * @memberof ChangePropositionDto
     */
    Type: ChangePropositionDtoTypeEnum;
    /**
     *
     * @type {MutualFundsFinancialRequestDto}
     * @memberof ChangePropositionDto
     */
    MutualFundsTransaction: MutualFundsFinancialRequestDto;
    /**
     *
     * @type {MutualFundsProfileChangeDto}
     * @memberof ChangePropositionDto
     */
    MutualFundsProfileChange: MutualFundsProfileChangeDto;
    /**
     *
     * @type {PensionFundsChangeDto}
     * @memberof ChangePropositionDto
     */
    PensionFundsChange: PensionFundsChangeDto;
    /**
     *
     * @type {PensionInvestmentStrategiesChangeDto}
     * @memberof ChangePropositionDto
     */
    PensionInvestmentStrategiesChange: PensionInvestmentStrategiesChangeDto;
}

/**
 * @export
 * @enum {string}
 */
export enum ChangePropositionDtoTypeEnum {
    MutualFundsTransaction = "MUTUAL_FUNDS_TRANSACTION",
    MutualFundsProfileChange = "MUTUAL_FUNDS_PROFILE_CHANGE",
    PensionFundsChange = "PENSION_FUNDS_CHANGE",
    PensionInvestmentStrategiesChange = "PENSION_INVESTMENT_STRATEGIES_CHANGE",
}
/**
 *
 * @export
 * @interface ClaimAddressDto
 */
export interface ClaimAddressDto {
    /**
     *
     * @type {string}
     * @memberof ClaimAddressDto
     */
    City: string;
    /**
     *
     * @type {string}
     * @memberof ClaimAddressDto
     */
    Street: string;
    /**
     *
     * @type {string}
     * @memberof ClaimAddressDto
     */
    ZipCode: string;
    /**
     *
     * @type {string}
     * @memberof ClaimAddressDto
     */
    PlaceDescription: string;
}
/**
 *
 * @export
 * @interface ClaimDto
 */
export interface ClaimDto {
    /**
     *
     * @type {string}
     * @memberof ClaimDto
     */
    IncidentId: string;
    /**
     *
     * @type {string}
     * @memberof ClaimDto
     */
    ClaimId: string;
    /**
     *
     * @type {string}
     * @memberof ClaimDto
     */
    ClaimNumber: string;
    /**
     *
     * @type {boolean}
     * @memberof ClaimDto
     */
    ShouldDisplaySum: boolean;
    /**
     *
     * @type {number}
     * @memberof ClaimDto
     */
    LossSum: number;
    /**
     *
     * @type {string}
     * @memberof ClaimDto
     */
    ReportDate: string;
    /**
     *
     * @type {string}
     * @memberof ClaimDto
     */
    ClosureDate: string;
    /**
     *
     * @type {string}
     * @memberof ClaimDto
     */
    ClosureType: ClaimDtoClosureTypeEnum;
    /**
     *
     * @type {string}
     * @memberof ClaimDto
     */
    PlateNumber: string;
    /**
     *
     * @type {KeyValueDto}
     * @memberof ClaimDto
     */
    DamageScope: KeyValueDto;
    /**
     *
     * @type {string}
     * @memberof ClaimDto
     */
    ClaimState: ClaimDtoClaimStateEnum;
    /**
     *
     * @type {string}
     * @memberof ClaimDto
     */
    ClaimCategory: ClaimDtoClaimCategoryEnum;
    /**
     *
     * @type {ClaimRiskDto}
     * @memberof ClaimDto
     */
    Risk: ClaimRiskDto;
    /**
     *
     * @type {ClaimSubjectDto}
     * @memberof ClaimDto
     */
    Subject: ClaimSubjectDto;
    /**
     *
     * @type {ClaimantDto}
     * @memberof ClaimDto
     */
    Claimant: ClaimantDto;
}

/**
 * @export
 * @enum {string}
 */
export enum ClaimDtoClosureTypeEnum {
    ClaimWithoutPayment = "CLAIM_WITHOUT_PAYMENT",
    ClaimWithPayment = "CLAIM_WITH_PAYMENT",
    ClaimDeclinedPayment = "CLAIM_DECLINED_PAYMENT",
    IncidentWithoutPayment = "INCIDENT_WITHOUT_PAYMENT",
    IncidentWithPayment = "INCIDENT_WITH_PAYMENT",
}
/**
 * @export
 * @enum {string}
 */
export enum ClaimDtoClaimStateEnum {
    NoAssignee = "NO_ASSIGNEE",
    Check = "CHECK",
    Assembly = "ASSEMBLY",
    Assessment = "ASSESSMENT",
    Review = "REVIEW",
    Archive = "ARCHIVE",
    Autocompletition = "AUTOCOMPLETITION",
    PermanentInjury = "PERMANENT_INJURY",
    Rent = "RENT",
}
/**
 * @export
 * @enum {string}
 */
export enum ClaimDtoClaimCategoryEnum {
    Car = "CAR",
    Property = "PROPERTY",
    Health = "HEALTH",
    Other = "OTHER",
    Building = "BUILDING",
    Resources = "RESOURCES",
    Equipment = "EQUIPMENT",
    Person = "PERSON",
    Liability = "LIABILITY",
}
/**
 *
 * @export
 * @interface ClaimRiskDto
 */
export interface ClaimRiskDto {
    /**
     *
     * @type {string}
     * @memberof ClaimRiskDto
     */
    FirstName: string;
    /**
     *
     * @type {string}
     * @memberof ClaimRiskDto
     */
    LastName: string;
    /**
     *
     * @type {string}
     * @memberof ClaimRiskDto
     */
    IdentificationNumber: string;
    /**
     *
     * @type {string}
     * @memberof ClaimRiskDto
     */
    BirthDate: string;
}
/**
 *
 * @export
 * @interface ClaimSubjectDto
 */
export interface ClaimSubjectDto {
    /**
     *
     * @type {string}
     * @memberof ClaimSubjectDto
     */
    Type: string;
    /**
     *
     * @type {string}
     * @memberof ClaimSubjectDto
     */
    Model: string;
    /**
     *
     * @type {string}
     * @memberof ClaimSubjectDto
     */
    Brand: string;
    /**
     *
     * @type {string}
     * @memberof ClaimSubjectDto
     */
    Vin: string;
    /**
     *
     * @type {string}
     * @memberof ClaimSubjectDto
     */
    LicensePlate: string;
    /**
     *
     * @type {string}
     * @memberof ClaimSubjectDto
     */
    DamageDescription: string;
}
/**
 *
 * @export
 * @interface ClaimantDto
 */
export interface ClaimantDto {
    /**
     *
     * @type {string}
     * @memberof ClaimantDto
     */
    Salutation: string;
    /**
     *
     * @type {string}
     * @memberof ClaimantDto
     */
    Title: string;
    /**
     *
     * @type {string}
     * @memberof ClaimantDto
     */
    FirstName: string;
    /**
     *
     * @type {string}
     * @memberof ClaimantDto
     */
    MiddleName: string;
    /**
     *
     * @type {string}
     * @memberof ClaimantDto
     */
    LastName: string;
    /**
     *
     * @type {string}
     * @memberof ClaimantDto
     */
    CompanyName: string;
}
/**
 *
 * @export
 * @interface ClaimsDto
 */
export interface ClaimsDto {
    /**
     *
     * @type {Array<ClaimDto>}
     * @memberof ClaimsDto
     */
    Claims: Array<ClaimDto>;
    /**
     *
     * @type {string}
     * @memberof ClaimsDto
     */
    ClaimsDetailLink: string;
    /**
     *
     * @type {string}
     * @memberof ClaimsDto
     */
    LifeClaimsDetailLink: string;
    /**
     *
     * @type {string}
     * @memberof ClaimsDto
     */
    NonLifeClaimsDetailLink: string;
}
/**
 *
 * @export
 * @interface ClientConsentDto
 */
export interface ClientConsentDto {
    /**
     *
     * @type {number}
     * @memberof ClientConsentDto
     */
    IdConsent: number;
    /**
     *
     * @type {string}
     * @memberof ClientConsentDto
     */
    BeginDate: string;
    /**
     *
     * @type {string}
     * @memberof ClientConsentDto
     */
    EndDate: string;
    /**
     *
     * @type {string}
     * @memberof ClientConsentDto
     */
    ConsentVersionCode: string;
    /**
     *
     * @type {string}
     * @memberof ClientConsentDto
     */
    ConsentURL: string;
    /**
     *
     * @type {string}
     * @memberof ClientConsentDto
     */
    ModifyDate: string;
}
/**
 *
 * @export
 * @interface ClientDisclaimerDto
 */
export interface ClientDisclaimerDto {
    /**
     *
     * @type {number}
     * @memberof ClientDisclaimerDto
     */
    IdConsent: number;
    /**
     *
     * @type {string}
     * @memberof ClientDisclaimerDto
     */
    ConsentVersionCode: string;
}
/**
 *
 * @export
 * @interface ClientDocumentDto
 */
export interface ClientDocumentDto {
    /**
     *
     * @type {string}
     * @memberof ClientDocumentDto
     */
    Id: string;
    /**
     *
     * @type {string}
     * @memberof ClientDocumentDto
     */
    Guid: string;
    /**
     *
     * @type {string}
     * @memberof ClientDocumentDto
     */
    Status: string;
    /**
     *
     * @type {string}
     * @memberof ClientDocumentDto
     */
    Date: string;
    /**
     *
     * @type {string}
     * @memberof ClientDocumentDto
     */
    TypeCode: string;
    /**
     *
     * @type {string}
     * @memberof ClientDocumentDto
     */
    TypeLocalName: string;
}
/**
 *
 * @export
 * @interface ClientVerificationDto
 */
export interface ClientVerificationDto {
    /**
     *
     * @type {string}
     * @memberof ClientVerificationDto
     */
    RequestDate: string;
    /**
     *
     * @type {string}
     * @memberof ClientVerificationDto
     */
    ConfirmDate: string;
    /**
     *
     * @type {boolean}
     * @memberof ClientVerificationDto
     */
    Verified: boolean;
}
/**
 *
 * @export
 * @interface CodeValueDto
 */
export interface CodeValueDto {
    /**
     *
     * @type {string}
     * @memberof CodeValueDto
     */
    Code: string;
    /**
     *
     * @type {Array<LocalizedNameDto>}
     * @memberof CodeValueDto
     */
    Names: Array<LocalizedNameDto>;
}
/**
 *
 * @export
 * @interface ConfigDto
 */
export interface ConfigDto {
    /**
     *
     * @type {string}
     * @memberof ConfigDto
     */
    recaptchaSiteKey: string;
    /**
     *
     * @type {Array<string>}
     * @memberof ConfigDto
     */
    featureFlags: Array<string>;
    /**
     *
     * @type {string}
     * @memberof ConfigDto
     */
    disclaimerVersion: string;
    /**
     *
     * @type {string}
     * @memberof ConfigDto
     */
    disclaimerText: string;
    /**
     *
     * @type {string}
     * @memberof ConfigDto
     */
    vapidPublicKey: string;
    /**
     *
     * @type {string}
     * @memberof ConfigDto
     */
    youtubePromoId: string;
    /**
     *
     * @type {string}
     * @memberof ConfigDto
     */
    analyticsTrackId: string;
    /**
     *
     * @type {number}
     * @memberof ConfigDto
     */
    sessionMaxInactiveInterval: number;
    authUri: string;
    logoutUri: string;
    clientId: string;
    notAllowedTargetFundCodesSk: Array<string>;
    notAllowedTargetFundCodesCz: Array<string>;
    claimReportingUrl: string;
    /**
     *
     * @type {InfoTextsDto}
     * @memberof ConfigDto
     */
    infoTexts: InfoTextsDto;
    /**
     *
     * @type {ClaimReportingUrlsDto}
     * @memberof ConfigDto
     */
    claimReportingUrls: ClaimReportingUrlsDto;
}
/**
 *
 * @export
 * @interface ConsentDto
 */
export interface ConsentDto {
    /**
     *
     * @type {string}
     * @memberof ConsentDto
     */
    Version: string;
    /**
     *
     * @type {string}
     * @memberof ConsentDto
     */
    Text: string;
}
/**
 *
 * @export
 * @interface ContractAgentChangeRequestDto
 */
export interface ContractAgentChangeRequestDto {
    /**
     *
     * @type {number}
     * @memberof ContractAgentChangeRequestDto
     */
    RequestId: number;
    /**
     *
     * @type {string}
     * @memberof ContractAgentChangeRequestDto
     */
    RequestDateTime: string;
    /**
     *
     * @type {string}
     * @memberof ContractAgentChangeRequestDto
     */
    AgentNumberNew: string;
    /**
     *
     * @type {string}
     * @memberof ContractAgentChangeRequestDto
     */
    AgentNumberOriginal: string;
}
/**
 *
 * @export
 * @interface ContractChangePropositionDto
 */
export interface ContractChangePropositionDto {
    /**
     *
     * @type {string}
     * @memberof ContractChangePropositionDto
     */
    Id: string;
    /**
     *
     * @type {string}
     * @memberof ContractChangePropositionDto
     */
    ContractNumber: string;
    /**
     *
     * @type {string}
     * @memberof ContractChangePropositionDto
     */
    Status: ContractChangePropositionDtoStatusEnum;
    /**
     *
     * @type {string}
     * @memberof ContractChangePropositionDto
     */
    AgentId: string;
    /**
     *
     * @type {boolean}
     * @memberof ContractChangePropositionDto
     */
    IsActiveAdvisor: boolean;
    /**
     *
     * @type {string}
     * @memberof ContractChangePropositionDto
     */
    CreationDateTime: string;
    /**
     *
     * @type {string}
     * @memberof ContractChangePropositionDto
     */
    LastChangeDateTime: string;
    /**
     *
     * @type {ChangePropositionDto}
     * @memberof ContractChangePropositionDto
     */
    ChangeProposition: ChangePropositionDto;
}

/**
 * @export
 * @enum {string}
 */
export enum ContractChangePropositionDtoStatusEnum {
    AwaitingAcceptation = "AWAITING_ACCEPTATION",
    Accepted = "ACCEPTED",
    Declined = "DECLINED",
    Cancelled = "CANCELLED",
}
/**
 *
 * @export
 * @interface ContractDetailDto
 */
export interface ContractDetailDto {
    /**
     *
     * @type {LegalEntityDto}
     * @memberof ContractDetailDto
     */
    Holder: LegalEntityDto;
    /**
     *
     * @type {ElectronicCommunicationDto}
     * @memberof ContractDetailDto
     */
    ElectronicCommunication: ElectronicCommunicationDto;
}
/**
 *
 * @export
 * @interface ContractDto
 */
export interface ContractDto {
    /**
     *
     * @type {number}
     * @memberof ContractDto
     */
    idObject: number;
    /**
     *
     * @type {ContractDetailDto}
     * @memberof ContractDto
     */
    contractDetail: ContractDetailDto;
}
/**
 *
 * @export
 * @interface ContractOverviewDto
 */
export interface ContractOverviewDto {
    /**
     *
     * @type {PaymentsSchedulerDto}
     * @memberof ContractOverviewDto
     */
    PaymentScheduler: PaymentsSchedulerDto;
    /**
     *
     * @type {AmountDto}
     * @memberof ContractOverviewDto
     */
    MonthlyPayment: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof ContractOverviewDto
     */
    InvestedAmount: AmountDto;
    /**
     *
     * @type {boolean}
     * @memberof ContractOverviewDto
     */
    ElectronicCommunicationEnabled: boolean;
    /**
     *
     * @type {Array<string>}
     * @memberof ContractOverviewDto
     */
    LineOfBusinessClasses: Array<string>;
}
/**
 *
 * @export
 * @interface DocumentUploadDto
 */
export interface DocumentUploadDto {
    /**
     *
     * @type {string}
     * @memberof DocumentUploadDto
     */
    FileName: string;
    /**
     *
     * @type {string}
     * @memberof DocumentUploadDto
     */
    FileFormat: string;
    /**
     *
     * @type {string}
     * @memberof DocumentUploadDto
     */
    Data: string;
    /**
     *
     * @type {number}
     * @memberof DocumentUploadDto
     */
    Size: number;
}
/**
 *
 * @export
 * @interface ElectronicCommunicationDto
 */
export interface ElectronicCommunicationDto {
    /**
     *
     * @type {boolean}
     * @memberof ElectronicCommunicationDto
     */
    ElectronicCommunicationSet: boolean;
    /**
     *
     * @type {string}
     * @memberof ElectronicCommunicationDto
     */
    ElectronicCommunicationType: string;
}
/**
 *
 * @export
 * @interface EnqueuedDocumentDto
 */
export interface EnqueuedDocumentDto {
    /**
     *
     * @type {number}
     * @memberof EnqueuedDocumentDto
     */
    Id: number;
    /**
     *
     * @type {CodeValueDto}
     * @memberof EnqueuedDocumentDto
     */
    Type: CodeValueDto;
    /**
     *
     * @type {string}
     * @memberof EnqueuedDocumentDto
     */
    ContractNumber: string;
    /**
     *
     * @type {CodeValueDto}
     * @memberof EnqueuedDocumentDto
     */
    Status: CodeValueDto;
    /**
     *
     * @type {string}
     * @memberof EnqueuedDocumentDto
     */
    Requested: string;
    /**
     *
     * @type {string}
     * @memberof EnqueuedDocumentDto
     */
    LastChange: string;
}
/**
 *
 * @export
 * @interface FieldErrorDto
 */
export interface FieldErrorDto {
    /**
     *
     * @type {string}
     * @memberof FieldErrorDto
     */
    Field: string;
    /**
     *
     * @type {string}
     * @memberof FieldErrorDto
     */
    Message: string;
}
/**
 *
 * @export
 * @interface FormActiveOperationsPhoneNumberDto
 */
export interface FormActiveOperationsPhoneNumberDto {
    /**
     *
     * @type {number}
     * @memberof FormActiveOperationsPhoneNumberDto
     */
    IdObject: number;
    /**
     *
     * @type {string}
     * @memberof FormActiveOperationsPhoneNumberDto
     */
    NewActiveOperationsPhoneNumber: string;
    /**
     *
     * @type {string}
     * @memberof FormActiveOperationsPhoneNumberDto
     */
    OtpId: string;
    /**
     *
     * @type {string}
     * @memberof FormActiveOperationsPhoneNumberDto
     */
    OtpCode: string;
}
/**
 *
 * @export
 * @interface FormAddressDto
 */
export interface FormAddressDto {
    /**
     *
     * @type {string}
     * @memberof FormAddressDto
     */
    Street: string;
    /**
     *
     * @type {string}
     * @memberof FormAddressDto
     */
    OrientationNumber: string;
    /**
     *
     * @type {string}
     * @memberof FormAddressDto
     */
    DescriptionNumber: string;
    /**
     *
     * @type {string}
     * @memberof FormAddressDto
     */
    City: string;
    /**
     *
     * @type {string}
     * @memberof FormAddressDto
     */
    Zip: string;
    /**
     *
     * @type {string}
     * @memberof FormAddressDto
     */
    State: FormAddressDtoStateEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum FormAddressDtoStateEnum {
    Ad = "AD",
    Ae = "AE",
    Af = "AF",
    Ag = "AG",
    Ai = "AI",
    Al = "AL",
    Am = "AM",
    Ao = "AO",
    Aq = "AQ",
    Ar = "AR",
    As = "AS",
    At = "AT",
    Au = "AU",
    Aw = "AW",
    Ax = "AX",
    Az = "AZ",
    Ba = "BA",
    Bb = "BB",
    Bd = "BD",
    Be = "BE",
    Bf = "BF",
    Bg = "BG",
    Bh = "BH",
    Bi = "BI",
    Bj = "BJ",
    Bl = "BL",
    Bm = "BM",
    Bn = "BN",
    Bo = "BO",
    Bq = "BQ",
    Br = "BR",
    Bs = "BS",
    Bt = "BT",
    Bv = "BV",
    Bw = "BW",
    By = "BY",
    Bz = "BZ",
    Ca = "CA",
    Cc = "CC",
    Cd = "CD",
    Cf = "CF",
    Cg = "CG",
    Ci = "CI",
    Ck = "CK",
    Cl = "CL",
    Cm = "CM",
    Cn = "CN",
    Co = "CO",
    Cr = "CR",
    Cu = "CU",
    Cv = "CV",
    Cw = "CW",
    Cx = "CX",
    Cy = "CY",
    Cz = "CZ",
    De = "DE",
    Dj = "DJ",
    Dk = "DK",
    Dm = "DM",
    Do = "DO",
    Dz = "DZ",
    Ec = "EC",
    Ee = "EE",
    Eg = "EG",
    Eh = "EH",
    Er = "ER",
    Es = "ES",
    Et = "ET",
    Fi = "FI",
    Fj = "FJ",
    Fk = "FK",
    Fm = "FM",
    Fo = "FO",
    Fr = "FR",
    Ga = "GA",
    Gb = "GB",
    Gd = "GD",
    Ge = "GE",
    Gf = "GF",
    Gg = "GG",
    Gh = "GH",
    Gi = "GI",
    Gl = "GL",
    Gm = "GM",
    Gn = "GN",
    Gp = "GP",
    Gq = "GQ",
    Gr = "GR",
    Gs = "GS",
    Gt = "GT",
    Gu = "GU",
    Gw = "GW",
    Gy = "GY",
    Hk = "HK",
    Hm = "HM",
    Hn = "HN",
    Hr = "HR",
    Ht = "HT",
    Hu = "HU",
    Ch = "CH",
    Id = "ID",
    Ie = "IE",
    Il = "IL",
    Im = "IM",
    In = "IN",
    Io = "IO",
    Iq = "IQ",
    Ir = "IR",
    Is = "IS",
    It = "IT",
    Je = "JE",
    Jm = "JM",
    Jo = "JO",
    Jp = "JP",
    Ke = "KE",
    Kg = "KG",
    Kh = "KH",
    Ki = "KI",
    Km = "KM",
    Kn = "KN",
    Kp = "KP",
    Kr = "KR",
    Kw = "KW",
    Ky = "KY",
    Kz = "KZ",
    La = "LA",
    Lb = "LB",
    Lc = "LC",
    Li = "LI",
    Lk = "LK",
    Lr = "LR",
    Ls = "LS",
    Lt = "LT",
    Lu = "LU",
    Lv = "LV",
    Ly = "LY",
    Ma = "MA",
    Mc = "MC",
    Md = "MD",
    Me = "ME",
    Mf = "MF",
    Mg = "MG",
    Mh = "MH",
    Mk = "MK",
    Ml = "ML",
    Mm = "MM",
    Mn = "MN",
    Mo = "MO",
    Mp = "MP",
    Mq = "MQ",
    Mr = "MR",
    Ms = "MS",
    Mt = "MT",
    Mu = "MU",
    Mv = "MV",
    Mw = "MW",
    Mx = "MX",
    My = "MY",
    Mz = "MZ",
    Na = "NA",
    Nc = "NC",
    Ne = "NE",
    Nf = "NF",
    Ng = "NG",
    Ni = "NI",
    Nl = "NL",
    No = "NO",
    Np = "NP",
    Nr = "NR",
    Nu = "NU",
    Nz = "NZ",
    Om = "OM",
    Pa = "PA",
    Pe = "PE",
    Pf = "PF",
    Pg = "PG",
    Ph = "PH",
    Pk = "PK",
    Pl = "PL",
    Pm = "PM",
    Pn = "PN",
    Pr = "PR",
    Ps = "PS",
    Pt = "PT",
    Pw = "PW",
    Py = "PY",
    Qa = "QA",
    Re = "RE",
    Ro = "RO",
    Rs = "RS",
    Ru = "RU",
    Rw = "RW",
    Sa = "SA",
    Sb = "SB",
    Sc = "SC",
    Sd = "SD",
    Se = "SE",
    Sg = "SG",
    Sh = "SH",
    Si = "SI",
    Sj = "SJ",
    Sk = "SK",
    Sl = "SL",
    Sm = "SM",
    Sn = "SN",
    So = "SO",
    Sr = "SR",
    Ss = "SS",
    St = "ST",
    Sv = "SV",
    Sx = "SX",
    Sy = "SY",
    Sz = "SZ",
    Tc = "TC",
    Td = "TD",
    Tf = "TF",
    Tg = "TG",
    Th = "TH",
    Tj = "TJ",
    Tk = "TK",
    Tl = "TL",
    Tm = "TM",
    Tn = "TN",
    To = "TO",
    Tr = "TR",
    Tt = "TT",
    Tv = "TV",
    Tw = "TW",
    Tz = "TZ",
    Ua = "UA",
    Ug = "UG",
    Um = "UM",
    Us = "US",
    Uy = "UY",
    Uz = "UZ",
    Va = "VA",
    Vc = "VC",
    Ve = "VE",
    Vg = "VG",
    Vi = "VI",
    Vn = "VN",
    Vu = "VU",
    Wf = "WF",
    Ws = "WS",
    Ye = "YE",
    Yt = "YT",
    Za = "ZA",
    Zm = "ZM",
    Zw = "ZW",
}
/**
 *
 * @export
 * @interface FormAttachmentDto
 */
export interface FormAttachmentDto {
    /**
     *
     * @type {string}
     * @memberof FormAttachmentDto
     */
    Data: string;
    /**
     *
     * @type {string}
     * @memberof FormAttachmentDto
     */
    FileFormat: string;
    /**
     *
     * @type {string}
     * @memberof FormAttachmentDto
     */
    FileName: string;
}
/**
 *
 * @export
 * @interface FormChangeBeneficiariesDto
 */
export interface FormChangeBeneficiariesDto {
    /**
     *
     * @type {number}
     * @memberof FormChangeBeneficiariesDto
     */
    IdObject: number;
    /**
     *
     * @type {Array<FormChangeBeneficiariesPersonDto>}
     * @memberof FormChangeBeneficiariesDto
     */
    Beneficiaries: Array<FormChangeBeneficiariesPersonDto>;
}
/**
 *
 * @export
 * @interface FormChangeBeneficiariesPersonDto
 */
export interface FormChangeBeneficiariesPersonDto {
    /**
     *
     * @type {string}
     * @memberof FormChangeBeneficiariesPersonDto
     */
    SubjectType: FormChangeBeneficiariesPersonDtoSubjectTypeEnum;
    /**
     *
     * @type {string}
     * @memberof FormChangeBeneficiariesPersonDto
     */
    FirstName: string;
    /**
     *
     * @type {string}
     * @memberof FormChangeBeneficiariesPersonDto
     */
    LastName: string;
    /**
     *
     * @type {string}
     * @memberof FormChangeBeneficiariesPersonDto
     */
    BirthNumber: string;
    /**
     *
     * @type {string}
     * @memberof FormChangeBeneficiariesPersonDto
     */
    CompanyId: string;
    /**
     *
     * @type {string}
     * @memberof FormChangeBeneficiariesPersonDto
     */
    CompanyName: string;
    /**
     *
     * @type {number}
     * @memberof FormChangeBeneficiariesPersonDto
     */
    Share: number;
    /**
     *
     * @type {FormAddressDto}
     * @memberof FormChangeBeneficiariesPersonDto
     */
    Address: FormAddressDto;
}

/**
 * @export
 * @enum {string}
 */
export enum FormChangeBeneficiariesPersonDtoSubjectTypeEnum {
    Individual = "INDIVIDUAL",
    Company = "COMPANY",
}
/**
 *
 * @export
 * @interface FormChangePropositions
 */
export interface FormChangePropositions {
    /**
     *
     * @type {number}
     * @memberof FormChangePropositions
     */
    IdObject: number;
    /**
     *
     * @type {UpdateContractChangePropositionDto}
     * @memberof FormChangePropositions
     */
    ChangePropositions: UpdateContractChangePropositionDto;
}
/**
 *
 * @export
 * @interface FormClaimNoteDto
 */
export interface FormClaimNoteDto {
    /**
     *
     * @type {string}
     * @memberof FormClaimNoteDto
     */
    ClaimNote: string;
}
/**
 *
 * @export
 * @interface FormContractNameDto
 */
export interface FormContractNameDto {
    /**
     *
     * @type {number}
     * @memberof FormContractNameDto
     */
    IdObject: number;
    /**
     *
     * @type {string}
     * @memberof FormContractNameDto
     */
    ContractName: string;
}
/**
 *
 * @export
 * @interface FormContributionPaymentsDto
 */
export interface FormContributionPaymentsDto {
    /**
     *
     * @type {number}
     * @memberof FormContributionPaymentsDto
     */
    IdObject: number;
    /**
     *
     * @type {number}
     * @memberof FormContributionPaymentsDto
     */
    Amount: number;
    /**
     *
     * @type {string}
     * @memberof FormContributionPaymentsDto
     */
    FrequencyOfPayment: FormContributionPaymentsDtoFrequencyOfPaymentEnum;
    /**
     *
     * @type {string}
     * @memberof FormContributionPaymentsDto
     */
    PaymentContribution: FormContributionPaymentsDtoPaymentContributionEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum FormContributionPaymentsDtoFrequencyOfPaymentEnum {
    None = "NONE",
    Onetime = "ONETIME",
    Monthly = "MONTHLY",
    Quarterly = "QUARTERLY",
    Halfyearly = "HALFYEARLY",
    Yearly = "YEARLY",
}
/**
 * @export
 * @enum {string}
 */
export enum FormContributionPaymentsDtoPaymentContributionEnum {
    BankAccount = "BY_BANK_ACCOUNT",
    Cash = "BY_CASH",
    DirectDebit = "BY_DIRECT_DEBIT",
    PostalOrder = "BY_POSTAL_ORDER",
    Sipo = "BY_SIPO",
    Employment = "BY_EMPLOYMENT",
}
/**
 *
 * @export
 * @interface FormDocumentRequestDto
 */
export interface FormDocumentRequestDto {
    /**
     *
     * @type {number}
     * @memberof FormDocumentRequestDto
     */
    IdObject: number;
    /**
     *
     * @type {string}
     * @memberof FormDocumentRequestDto
     */
    DocumentType: FormDocumentRequestDtoDocumentTypeEnum;
    /**
     *
     * @type {FormDocumentRequestParamsDto}
     * @memberof FormDocumentRequestDto
     */
    DocumentParams: FormDocumentRequestParamsDto;
}

/**
 * @export
 * @enum {string}
 */
export enum FormDocumentRequestDtoDocumentTypeEnum {
    YearlyStatement = "YEARLY_STATEMENT",
    ExtraStatement = "EXTRA_STATEMENT",
    TaxStatement = "TAX_STATEMENT",
    ActualStatement = "ACTUAL_STATEMENT",
    StatusStatement = "STATUS_STATEMENT",
    GreenCard = "GREEN_CARD",
    BuySellConfirmation = "BUY_SELL_CONFIRMATION",
}
/**
 *
 * @export
 * @interface FormDocumentRequestParamsDto
 */
export interface FormDocumentRequestParamsDto {
    /**
     *
     * @type {number}
     * @memberof FormDocumentRequestParamsDto
     */
    Year: number;
    /**
     *
     * @type {number}
     * @memberof FormDocumentRequestParamsDto
     */
    IdOperation: number;
}
/**
 *
 * @export
 * @interface FormElectronicCommunicationDto
 */
export interface FormElectronicCommunicationDto {
    /**
     *
     * @type {Array<number>}
     * @memberof FormElectronicCommunicationDto
     */
    IdObjects: Array<number>;
    /**
     *
     * @type {boolean}
     * @memberof FormElectronicCommunicationDto
     */
    Enabled: boolean;
    /**
     *
     * @type {string}
     * @memberof FormElectronicCommunicationDto
     */
    Phone: string;
    /**
     *
     * @type {string}
     * @memberof FormElectronicCommunicationDto
     */
    Email: string;
}
/**
 *
 * @export
 * @interface FormEmployerContributionPaymentsDto
 */
export interface FormEmployerContributionPaymentsDto {
    /**
     *
     * @type {number}
     * @memberof FormEmployerContributionPaymentsDto
     */
    IdObject: number;
    /**
     *
     * @type {boolean}
     * @memberof FormEmployerContributionPaymentsDto
     */
    EnableEmployerContribution: boolean;
    /**
     *
     * @type {string}
     * @memberof FormEmployerContributionPaymentsDto
     */
    EmployerName: string;
    /**
     *
     * @type {string}
     * @memberof FormEmployerContributionPaymentsDto
     */
    IdentificationNumber: string;
}
/**
 *
 * @export
 * @interface FormHomeAdditionalServiceRequestDto
 */
export interface FormHomeAdditionalServiceRequestDto {
    /**
     *
     * @type {number}
     * @memberof FormHomeAdditionalServiceRequestDto
     */
    IdObject: number;
    /**
     *
     * @type {string}
     * @memberof FormHomeAdditionalServiceRequestDto
     */
    Phone: string;
    /**
     *
     * @type {boolean}
     * @memberof FormHomeAdditionalServiceRequestDto
     */
    PersonalDataConsent: boolean;
    /**
     *
     * @type {string}
     * @memberof FormHomeAdditionalServiceRequestDto
     */
    PersonalDataConsentVersion: string;
}
/**
 *
 * @export
 * @interface FormIdentificationCardDto
 */
export interface FormIdentificationCardDto {
    /**
     *
     * @type {string}
     * @memberof FormIdentificationCardDto
     */
    Identification: FormIdentificationCardDtoIdentificationEnum;
    /**
     *
     * @type {string}
     * @memberof FormIdentificationCardDto
     */
    CardId: string;
    /**
     *
     * @type {string}
     * @memberof FormIdentificationCardDto
     */
    ReleaseDate: string;
    /**
     *
     * @type {string}
     * @memberof FormIdentificationCardDto
     */
    DateOfExpiry: string;
    /**
     *
     * @type {string}
     * @memberof FormIdentificationCardDto
     */
    Authority: string;
}

/**
 * @export
 * @enum {string}
 */
export enum FormIdentificationCardDtoIdentificationEnum {
    Op = "OP",
    Cp = "CP",
}
/**
 *
 * @export
 * @interface FormInvestmentProfileDto
 */
export interface FormInvestmentProfileDto {
    /**
     *
     * @type {number}
     * @memberof FormInvestmentProfileDto
     */
    IdObject: number;
    /**
     *
     * @type {string}
     * @memberof FormInvestmentProfileDto
     */
    NewProfileCode: string;
    /**
     *
     * @type {string}
     * @memberof FormInvestmentProfileDto
     */
    OtpId: string;
    /**
     *
     * @type {string}
     * @memberof FormInvestmentProfileDto
     */
    OtpCode: string;
}
/**
 *
 * @export
 * @interface FormLicencePlateNumberDto
 */
export interface FormLicencePlateNumberDto {
    /**
     *
     * @type {number}
     * @memberof FormLicencePlateNumberDto
     */
    IdObject: number;
    /**
     *
     * @type {string}
     * @memberof FormLicencePlateNumberDto
     */
    LicencePlateNumber: string;
}
/**
 *
 * @export
 * @interface FormMessageDto
 */
export interface FormMessageDto {
    /**
     *
     * @type {string}
     * @memberof FormMessageDto
     */
    Message: string;
    /**
     *
     * @type {string}
     * @memberof FormMessageDto
     */
    Email: string;
    /**
     *
     * @type {Array<FormAttachmentDto>}
     * @memberof FormMessageDto
     */
    Files: Array<FormAttachmentDto>;
}
/**
 *
 * @export
 * @interface FormPensionFundsChangeDto
 */
export interface FormPensionFundsChangeDto {
    /**
     *
     * @type {number}
     * @memberof FormPensionFundsChangeDto
     */
    IdObject: number;
    /**
     *
     * @type {Array<PensionFundChangeItem>}
     * @memberof FormPensionFundsChangeDto
     */
    TransferFundsData: Array<PensionFundChangeItem>;
    /**
     *
     * @type {Array<PensionFundChangeItem>}
     * @memberof FormPensionFundsChangeDto
     */
    ChangeStrategyData: Array<PensionFundChangeItem>;
    /**
     *
     * @type {string}
     * @memberof FormPensionFundsChangeDto
     */
    OtpId: string;
    /**
     *
     * @type {string}
     * @memberof FormPensionFundsChangeDto
     */
    OtpCode: string;
}
/**
 *
 * @export
 * @interface FormPensionStatementSettingsDto
 */
export interface FormPensionStatementSettingsDto {
    /**
     *
     * @type {number}
     * @memberof FormPensionStatementSettingsDto
     */
    IdObject: number;
    /**
     *
     * @type {boolean}
     * @memberof FormPensionStatementSettingsDto
     */
    ReceiveStatement: boolean;
    /**
     *
     * @type {string}
     * @memberof FormPensionStatementSettingsDto
     */
    StatementPeriodicity: FormPensionStatementSettingsDtoStatementPeriodicityEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum FormPensionStatementSettingsDtoStatementPeriodicityEnum {
    Quarterly = "QUARTERLY",
    Halfyearly = "HALFYEARLY",
    Yearly = "YEARLY",
}
/**
 *
 * @export
 * @interface FormPersonDto
 */
export interface FormPersonDto {
    /**
     *
     * @type {string}
     * @memberof FormPersonDto
     */
    TitleBefore: string;
    /**
     *
     * @type {string}
     * @memberof FormPersonDto
     */
    FirstName: string;
    /**
     *
     * @type {string}
     * @memberof FormPersonDto
     */
    LastName: string;
    /**
     *
     * @type {string}
     * @memberof FormPersonDto
     */
    TitleAfter: string;
}
/**
 *
 * @export
 * @interface FormPolicyHolderDataDto
 */
export interface FormPolicyHolderDataDto {
    /**
     *
     * @type {number}
     * @memberof FormPolicyHolderDataDto
     */
    IdObject: number;
    /**
     *
     * @type {Array<number>}
     * @memberof FormPolicyHolderDataDto
     */
    AdditionalIdObjects: Array<number>;
    /**
     *
     * @type {string}
     * @memberof FormPolicyHolderDataDto
     */
    ClientType: FormPolicyHolderDataDtoClientTypeEnum;
    /**
     *
     * @type {FormPersonDto}
     * @memberof FormPolicyHolderDataDto
     */
    Person: FormPersonDto;
    /**
     *
     * @type {string}
     * @memberof FormPolicyHolderDataDto
     */
    CompanyName: string;
    /**
     *
     * @type {FormAddressDto}
     * @memberof FormPolicyHolderDataDto
     */
    PermanentAddress: FormAddressDto;
    /**
     *
     * @type {string}
     * @memberof FormPolicyHolderDataDto
     */
    ContactPersonType: FormPolicyHolderDataDtoContactPersonTypeEnum;
    /**
     *
     * @type {FormPersonDto}
     * @memberof FormPolicyHolderDataDto
     */
    ContactPerson: FormPersonDto;
    /**
     *
     * @type {string}
     * @memberof FormPolicyHolderDataDto
     */
    ContactCompanyName: string;
    /**
     *
     * @type {FormAddressDto}
     * @memberof FormPolicyHolderDataDto
     */
    ContactAddress: FormAddressDto;
    /**
     *
     * @type {FormIdentificationCardDto}
     * @memberof FormPolicyHolderDataDto
     */
    IdentificationCard: FormIdentificationCardDto;
    /**
     *
     * @type {string}
     * @memberof FormPolicyHolderDataDto
     */
    Mobile: string;
    /**
     *
     * @type {string}
     * @memberof FormPolicyHolderDataDto
     */
    Email: string;
    /**
     *
     * @type {Array<DocumentUploadDto>}
     * @memberof FormPolicyHolderDataDto
     */
    Files: Array<DocumentUploadDto>;
    /**
     *
     * @type {boolean}
     * @memberof FormPolicyHolderDataDto
     */
    PersonalDataConsent: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum FormPolicyHolderDataDtoClientTypeEnum {
    Individual = "INDIVIDUAL",
    Company = "COMPANY",
}
/**
 * @export
 * @enum {string}
 */
export enum FormPolicyHolderDataDtoContactPersonTypeEnum {
    Individual = "INDIVIDUAL",
    Company = "COMPANY",
}
/**
 *
 * @export
 * @interface FormResetPasswordDto
 */
export interface FormResetPasswordDto {
    /**
     *
     * @type {string}
     * @memberof FormResetPasswordDto
     */
    ContractNumber: string;
    /**
     *
     * @type {string}
     * @memberof FormResetPasswordDto
     */
    BirthNumberOrCompanyId: string;
    /**
     *
     * @type {string}
     * @memberof FormResetPasswordDto
     */
    RecaptchaResponse: string;
}
/**
 *
 * @export
 * @interface FormStatementSettingsDto
 */
export interface FormStatementSettingsDto {
    /**
     *
     * @type {number}
     * @memberof FormStatementSettingsDto
     */
    IdObject: number;
    /**
     *
     * @type {boolean}
     * @memberof FormStatementSettingsDto
     */
    ReceiveBuyConfirmation: boolean;
    /**
     *
     * @type {boolean}
     * @memberof FormStatementSettingsDto
     */
    ReceiveSellConfirmation: boolean;
    /**
     *
     * @type {boolean}
     * @memberof FormStatementSettingsDto
     */
    ReceiveStatement: boolean;
    /**
     *
     * @type {string}
     * @memberof FormStatementSettingsDto
     */
    StatementPeriodicity: FormStatementSettingsDtoStatementPeriodicityEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum FormStatementSettingsDtoStatementPeriodicityEnum {
    Weekly = "WEEKLY",
    Monthly = "MONTHLY",
    Quarterly = "QUARTERLY",
    Biannually = "BIANNUALLY",
}
/**
 *
 * @export
 * @interface FormStatementsPasswordDto
 */
export interface FormStatementsPasswordDto {
    /**
     *
     * @type {number}
     * @memberof FormStatementsPasswordDto
     */
    IdObject: number;
    /**
     *
     * @type {string}
     * @memberof FormStatementsPasswordDto
     */
    Password: string;
}
/**
 *
 * @export
 * @interface FormTaxOptimumDto
 */
export interface FormTaxOptimumDto {
    /**
     *
     * @type {number}
     * @memberof FormTaxOptimumDto
     */
    IdObject: number;
    /**
     *
     * @type {boolean}
     * @memberof FormTaxOptimumDto
     */
    EnableTaxOptimization: boolean;
    /**
     *
     * @type {string}
     * @memberof FormTaxOptimumDto
     */
    HolderPhoneNumber: string;
}
/**
 *
 * @export
 * @interface FormUploadRequiredDocumentsDto
 */
export interface FormUploadRequiredDocumentsDto {
    /**
     *
     * @type {Array<DocumentUploadDto>}
     * @memberof FormUploadRequiredDocumentsDto
     */
    Files: Array<DocumentUploadDto>;
    /**
     *
     * @type {RequiredDocumentDto}
     * @memberof FormUploadRequiredDocumentsDto
     */
    RequiredDocument: RequiredDocumentDto;
}
/**
 *
 * @export
 * @interface FormVehicleLicenceNumberDto
 */
export interface FormVehicleLicenceNumberDto {
    /**
     *
     * @type {number}
     * @memberof FormVehicleLicenceNumberDto
     */
    IdObject: number;
    /**
     *
     * @type {string}
     * @memberof FormVehicleLicenceNumberDto
     */
    VehicleLicenceNumber: string;
}
/**
 *
 * @export
 * @interface GdprConsentDto
 */
export interface GdprConsentDto {
    /**
     *
     * @type {number}
     * @memberof GdprConsentDto
     */
    IdConsent: number;
    /**
     *
     * @type {number}
     * @memberof GdprConsentDto
     */
    IdInteraction: number;
    /**
     *
     * @type {number}
     * @memberof GdprConsentDto
     */
    IdEnuConsentEntity: number;
    /**
     *
     * @type {string}
     * @memberof GdprConsentDto
     */
    ConsentEntity: string;
    /**
     *
     * @type {number}
     * @memberof GdprConsentDto
     */
    IdEnuConsentReason: number;
    /**
     *
     * @type {string}
     * @memberof GdprConsentDto
     */
    ConsentReason: string;
    /**
     *
     * @type {number}
     * @memberof GdprConsentDto
     */
    EndDate: number;
    /**
     *
     * @type {number}
     * @memberof GdprConsentDto
     */
    IdENUConsentEndReason: number;
    /**
     *
     * @type {string}
     * @memberof GdprConsentDto
     */
    ConsentEndReason: string;
    /**
     *
     * @type {string}
     * @memberof GdprConsentDto
     */
    IsActive: string;
}
/**
 *
 * @export
 * @interface GreenCardHeaderDto
 */
export interface GreenCardHeaderDto {
    /**
     *
     * @type {string}
     * @memberof GreenCardHeaderDto
     */
    ContractNumber: string;
    /**
     *
     * @type {string}
     * @memberof GreenCardHeaderDto
     */
    Vehicle: string;
    /**
     *
     * @type {string}
     * @memberof GreenCardHeaderDto
     */
    LicencePlateNumber: string;
    /**
     *
     * @type {string}
     * @memberof GreenCardHeaderDto
     */
    VinNumber: string;
    /**
     *
     * @type {string}
     * @memberof GreenCardHeaderDto
     */
    ValidFrom: string;
    /**
     *
     * @type {string}
     * @memberof GreenCardHeaderDto
     */
    ValidTo: string;
}
/**
 *
 * @export
 * @interface HarmonizedFinancialOperationDto
 */
export interface HarmonizedFinancialOperationDto {
    /**
     *
     * @type {number}
     * @memberof HarmonizedFinancialOperationDto
     */
    Id: number;
    /**
     *
     * @type {string}
     * @memberof HarmonizedFinancialOperationDto
     */
    Type: HarmonizedFinancialOperationDtoTypeEnum;
    /**
     *
     * @type {AmountDto}
     * @memberof HarmonizedFinancialOperationDto
     */
    Amount: AmountDto;
    /**
     *
     * @type {string}
     * @memberof HarmonizedFinancialOperationDto
     */
    MovementDate: string;
    /**
     *
     * @type {string}
     * @memberof HarmonizedFinancialOperationDto
     */
    DateOfIdentification: string;
    /**
     *
     * @type {string}
     * @memberof HarmonizedFinancialOperationDto
     */
    Status: FinancialOperationStatusEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum FinancialOperationStatusEnum {
    Pending = "PENDING",
    Processed = "PROCESSES",
}

/**
 * @export
 * @enum {string}
 */
export enum HarmonizedFinancialOperationDtoTypeEnum {
    Credit = "CREDIT",
    Debit = "DEBIT",
}
/**
 *
 * @export
 * @interface IdDto
 */
export interface IdDto {
    /**
     *
     * @type {string}
     * @memberof IdDto
     */
    IdTypeCode: IdDtoIdTypeCodeEnum;
    /**
     *
     * @type {string}
     * @memberof IdDto
     */
    Number: string;
    /**
     *
     * @type {string}
     * @memberof IdDto
     */
    ValidFrom: string;
    /**
     *
     * @type {string}
     * @memberof IdDto
     */
    ValidTo: string;
    /**
     *
     * @type {string}
     * @memberof IdDto
     */
    Autority: string;
}

/**
 * @export
 * @enum {string}
 */
export enum IdDtoIdTypeCodeEnum {
    Op = "OP",
    Cp = "CP",
}
/**
 *
 * @export
 * @interface IdObjectDto
 */
export interface IdObjectDto {
    /**
     *
     * @type {number}
     * @memberof IdObjectDto
     */
    IdObject: number;
}
/**
 *
 * @export
 * @interface IdentificationCardDto
 */
export interface IdentificationCardDto {
    /**
     *
     * @type {string}
     * @memberof IdentificationCardDto
     */
    IdNumber: string;
    /**
     *
     * @type {string}
     * @memberof IdentificationCardDto
     */
    IdType: string;
}
/**
 *
 * @export
 * @interface IncidentDetailDto
 */
export interface IncidentDetailDto {
    /**
     *
     * @type {IncidentDto}
     * @memberof IncidentDetailDto
     */
    Detail: IncidentDto;
    /**
     *
     * @type {Array<ClaimDto>}
     * @memberof IncidentDetailDto
     */
    Claims: Array<ClaimDto>;
}
/**
 *
 * @export
 * @interface IncidentDto
 */
export interface IncidentDto {
    /**
     *
     * @type {string}
     * @memberof IncidentDto
     */
    IncidentId: string;
    /**
     *
     * @type {string}
     * @memberof IncidentDto
     */
    IncidentNumber: string;
    /**
     *
     * @type {string}
     * @memberof IncidentDto
     */
    PolicyNumber: string;
    /**
     *
     * @type {Array<string>}
     * @memberof IncidentDto
     */
    LineOfBusinesses: Array<string>;
    /**
     *
     * @type {string}
     * @memberof IncidentDto
     */
    IncidentState: IncidentDtoIncidentStateEnum;
    /**
     *
     * @type {string}
     * @memberof IncidentDto
     */
    IncidentDate: string;
    /**
     *
     * @type {string}
     * @memberof IncidentDto
     */
    ReportDate: string;
    /**
     *
     * @type {number}
     * @memberof IncidentDto
     */
    LossSum: number;
    /**
     *
     * @type {KeyValueDto}
     * @memberof IncidentDto
     */
    IncidentCause: KeyValueDto;
    /**
     *
     * @type {KeyValueDto}
     * @memberof IncidentDto
     */
    InsuranceRisk: KeyValueDto;
    /**
     *
     * @type {boolean}
     * @memberof IncidentDto
     */
    BonusMalus: boolean;
    /**
     *
     * @type {ClaimAddressDto}
     * @memberof IncidentDto
     */
    Address: ClaimAddressDto;
}

/**
 * @export
 * @enum {string}
 */
export enum IncidentDtoIncidentStateEnum {
    Opened = "OPENED",
    Closed = "CLOSED",
}
/**
 *
 * @export
 * @interface InfoTextsDto
 */
export interface InfoTextsDto {
    /**
     *
     * @type {boolean}
     * @memberof InfoTextsDto
     */
    enableLoginAlert: boolean;
    /**
     *
     * @type {boolean}
     * @memberof InfoTextsDto
     */
    enableHomepageAlert: boolean;
    /**
     *
     * @type {string}
     * @memberof InfoTextsDto
     */
    loginText: string;
    /**
     *
     * @type {string}
     * @memberof InfoTextsDto
     */
    homepageText: string;
}
/**
 *
 * @export
 * @interface ClaimReportingUrlsDto
 */
export interface ClaimReportingUrlsDto {
    /**
     *
     * @type {string}
     * @memberof ClaimReportingUrlsDto
     */
    base: string;
    /**
     *
     * @type {string}
     * @memberof ClaimReportingUrlsDto
     */
    vehicle: string;
    /**
     *
     * @type {string}
     * @memberof ClaimReportingUrlsDto
     */
    home: string;
    /**
     *
     * @type {string}
     * @memberof ClaimReportingUrlsDto
     */
    travel: string;
    /**
     *
     * @type {string}
     * @memberof ClaimReportingUrlsDto
     */
    life: string;
}
/**
 *
 * @export
 * @interface InspectionOrderDto
 */
export interface InspectionOrderDto {
    /**
     *
     * @type {string}
     * @memberof InspectionOrderDto
     */
    Supplier: string;
    /**
     *
     * @type {Array<string>}
     * @memberof InspectionOrderDto
     */
    Tasks: Array<string>;
    /**
     *
     * @type {string}
     * @memberof InspectionOrderDto
     */
    PlannedDate: string;
    /**
     *
     * @type {string}
     * @memberof InspectionOrderDto
     */
    State: InspectionOrderDtoStateEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum InspectionOrderDtoStateEnum {
    New = "NEW",
    Ordered = "ORDERED",
    Waiting = "WAITING",
    Canceled = "CANCELED",
    Accepted = "ACCEPTED",
    Closed = "CLOSED",
    Technician = "TECHNICIAN",
    ExtTechnician = "EXT_TECHNICIAN",
}
/**
 *
 * @export
 * @interface KeyValueDto
 */
export interface KeyValueDto {
    /**
     *
     * @type {string}
     * @memberof KeyValueDto
     */
    Key: string;
    /**
     *
     * @type {string}
     * @memberof KeyValueDto
     */
    Value: string;
}
/**
 *
 * @export
 * @interface Keys
 */
export interface Keys {
    /**
     *
     * @type {string}
     * @memberof Keys
     */
    P256dh: string;
    /**
     *
     * @type {string}
     * @memberof Keys
     */
    Auth: string;
}
/**
 *
 * @export
 * @interface LegalEntityDto
 */
export interface LegalEntityDto {
    /**
     *
     * @type {string}
     * @memberof LegalEntityDto
     */
    CountryCode: LegalEntityDtoCountryCodeEnum;
    /**
     *
     * @type {string}
     * @memberof LegalEntityDto
     */
    Description: string;
    /**
     *
     * @type {string}
     * @memberof LegalEntityDto
     */
    FirstName: string;
    /**
     *
     * @type {string}
     * @memberof LegalEntityDto
     */
    LastName: string;
    /**
     *
     * @type {string}
     * @memberof LegalEntityDto
     */
    BirthLastName: string;
    /**
     *
     * @type {string}
     * @memberof LegalEntityDto
     */
    TitleBefore: string;
    /**
     *
     * @type {string}
     * @memberof LegalEntityDto
     */
    TitleAfter: string;
    /**
     *
     * @type {string}
     * @memberof LegalEntityDto
     */
    SexCode: string;
    /**
     *
     * @type {string}
     * @memberof LegalEntityDto
     */
    LegalPersonalityCode: LegalEntityDtoLegalPersonalityCodeEnum;
    /**
     *
     * @type {string}
     * @memberof LegalEntityDto
     */
    BirthDate: string;
    /**
     *
     * @type {string}
     * @memberof LegalEntityDto
     */
    BirthPlace: string;
    /**
     *
     * @type {string}
     * @memberof LegalEntityDto
     */
    BirthNumber: string;
    /**
     *
     * @type {string}
     * @memberof LegalEntityDto
     */
    CompanyName: string;
    /**
     *
     * @type {string}
     * @memberof LegalEntityDto
     */
    CompanyID: string;
    /**
     *
     * @type {string}
     * @memberof LegalEntityDto
     */
    Citizenship: string;
    /**
     *
     * @type {boolean}
     * @memberof LegalEntityDto
     */
    IsPrivate: boolean;
    /**
     *
     * @type {Array<IdDto>}
     * @memberof LegalEntityDto
     */
    IDs: Array<IdDto>;
    /**
     *
     * @type {Array<AddressDto>}
     * @memberof LegalEntityDto
     */
    Address: Array<AddressDto>;
    /**
     *
     * @type {string}
     * @memberof LegalEntityDto
     */
    Email: string;
    /**
     *
     * @type {string}
     * @memberof LegalEntityDto
     */
    Phone: string;
    /**
     *
     * @type {number}
     * @memberof LegalEntityDto
     */
    IdSubject: number;
    /**
     *
     * @type {boolean}
     * @memberof LegalEntityDto
     */
    IsMobile: boolean;
    /**
     *
     * @type {string}
     * @memberof LegalEntityDto
     */
    SocialSecurityNumber: string;
    /**
     *
     * @type {string}
     * @memberof LegalEntityDto
     */
    InasId: string;
    /**
     *
     * @type {string}
     * @memberof LegalEntityDto
     */
    InasContactId: string;
    /**
     *
     * @type {boolean}
     * @memberof LegalEntityDto
     */
    AddressesIdentical: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LegalEntityDto
     */
    ShowPersonInContactAddressForm: boolean;
    /**
     *
     * @type {string}
     * @memberof LegalEntityDto
     */
    DocumentPIN: string;
}

/**
 * @export
 * @enum {string}
 */
export enum LegalEntityDtoCountryCodeEnum {
    Ad = "AD",
    Ae = "AE",
    Af = "AF",
    Ag = "AG",
    Ai = "AI",
    Al = "AL",
    Am = "AM",
    Ao = "AO",
    Aq = "AQ",
    Ar = "AR",
    As = "AS",
    At = "AT",
    Au = "AU",
    Aw = "AW",
    Ax = "AX",
    Az = "AZ",
    Ba = "BA",
    Bb = "BB",
    Bd = "BD",
    Be = "BE",
    Bf = "BF",
    Bg = "BG",
    Bh = "BH",
    Bi = "BI",
    Bj = "BJ",
    Bl = "BL",
    Bm = "BM",
    Bn = "BN",
    Bo = "BO",
    Bq = "BQ",
    Br = "BR",
    Bs = "BS",
    Bt = "BT",
    Bv = "BV",
    Bw = "BW",
    By = "BY",
    Bz = "BZ",
    Ca = "CA",
    Cc = "CC",
    Cd = "CD",
    Cf = "CF",
    Cg = "CG",
    Ci = "CI",
    Ck = "CK",
    Cl = "CL",
    Cm = "CM",
    Cn = "CN",
    Co = "CO",
    Cr = "CR",
    Cu = "CU",
    Cv = "CV",
    Cw = "CW",
    Cx = "CX",
    Cy = "CY",
    Cz = "CZ",
    De = "DE",
    Dj = "DJ",
    Dk = "DK",
    Dm = "DM",
    Do = "DO",
    Dz = "DZ",
    Ec = "EC",
    Ee = "EE",
    Eg = "EG",
    Eh = "EH",
    Er = "ER",
    Es = "ES",
    Et = "ET",
    Fi = "FI",
    Fj = "FJ",
    Fk = "FK",
    Fm = "FM",
    Fo = "FO",
    Fr = "FR",
    Ga = "GA",
    Gb = "GB",
    Gd = "GD",
    Ge = "GE",
    Gf = "GF",
    Gg = "GG",
    Gh = "GH",
    Gi = "GI",
    Gl = "GL",
    Gm = "GM",
    Gn = "GN",
    Gp = "GP",
    Gq = "GQ",
    Gr = "GR",
    Gs = "GS",
    Gt = "GT",
    Gu = "GU",
    Gw = "GW",
    Gy = "GY",
    Hk = "HK",
    Hm = "HM",
    Hn = "HN",
    Hr = "HR",
    Ht = "HT",
    Hu = "HU",
    Ch = "CH",
    Id = "ID",
    Ie = "IE",
    Il = "IL",
    Im = "IM",
    In = "IN",
    Io = "IO",
    Iq = "IQ",
    Ir = "IR",
    Is = "IS",
    It = "IT",
    Je = "JE",
    Jm = "JM",
    Jo = "JO",
    Jp = "JP",
    Ke = "KE",
    Kg = "KG",
    Kh = "KH",
    Ki = "KI",
    Km = "KM",
    Kn = "KN",
    Kp = "KP",
    Kr = "KR",
    Kw = "KW",
    Ky = "KY",
    Kz = "KZ",
    La = "LA",
    Lb = "LB",
    Lc = "LC",
    Li = "LI",
    Lk = "LK",
    Lr = "LR",
    Ls = "LS",
    Lt = "LT",
    Lu = "LU",
    Lv = "LV",
    Ly = "LY",
    Ma = "MA",
    Mc = "MC",
    Md = "MD",
    Me = "ME",
    Mf = "MF",
    Mg = "MG",
    Mh = "MH",
    Mk = "MK",
    Ml = "ML",
    Mm = "MM",
    Mn = "MN",
    Mo = "MO",
    Mp = "MP",
    Mq = "MQ",
    Mr = "MR",
    Ms = "MS",
    Mt = "MT",
    Mu = "MU",
    Mv = "MV",
    Mw = "MW",
    Mx = "MX",
    My = "MY",
    Mz = "MZ",
    Na = "NA",
    Nc = "NC",
    Ne = "NE",
    Nf = "NF",
    Ng = "NG",
    Ni = "NI",
    Nl = "NL",
    No = "NO",
    Np = "NP",
    Nr = "NR",
    Nu = "NU",
    Nz = "NZ",
    Om = "OM",
    Pa = "PA",
    Pe = "PE",
    Pf = "PF",
    Pg = "PG",
    Ph = "PH",
    Pk = "PK",
    Pl = "PL",
    Pm = "PM",
    Pn = "PN",
    Pr = "PR",
    Ps = "PS",
    Pt = "PT",
    Pw = "PW",
    Py = "PY",
    Qa = "QA",
    Re = "RE",
    Ro = "RO",
    Rs = "RS",
    Ru = "RU",
    Rw = "RW",
    Sa = "SA",
    Sb = "SB",
    Sc = "SC",
    Sd = "SD",
    Se = "SE",
    Sg = "SG",
    Sh = "SH",
    Si = "SI",
    Sj = "SJ",
    Sk = "SK",
    Sl = "SL",
    Sm = "SM",
    Sn = "SN",
    So = "SO",
    Sr = "SR",
    Ss = "SS",
    St = "ST",
    Sv = "SV",
    Sx = "SX",
    Sy = "SY",
    Sz = "SZ",
    Tc = "TC",
    Td = "TD",
    Tf = "TF",
    Tg = "TG",
    Th = "TH",
    Tj = "TJ",
    Tk = "TK",
    Tl = "TL",
    Tm = "TM",
    Tn = "TN",
    To = "TO",
    Tr = "TR",
    Tt = "TT",
    Tv = "TV",
    Tw = "TW",
    Tz = "TZ",
    Ua = "UA",
    Ug = "UG",
    Um = "UM",
    Us = "US",
    Uy = "UY",
    Uz = "UZ",
    Va = "VA",
    Vc = "VC",
    Ve = "VE",
    Vg = "VG",
    Vi = "VI",
    Vn = "VN",
    Vu = "VU",
    Wf = "WF",
    Ws = "WS",
    Ye = "YE",
    Yt = "YT",
    Za = "ZA",
    Zm = "ZM",
    Zw = "ZW",
}
/**
 * @export
 * @enum {string}
 */
export enum LegalEntityDtoLegalPersonalityCodeEnum {
    Fo = "FO",
    Po = "PO",
}
/**
 *
 * @export
 * @interface LocalizedNameDto
 */
export interface LocalizedNameDto {
    /**
     *
     * @type {string}
     * @memberof LocalizedNameDto
     */
    Name: string;
    /**
     *
     * @type {string}
     * @memberof LocalizedNameDto
     */
    LanguageCode: string;
}
/**
 *
 * @export
 * @interface LoginResultDto
 */
export interface LoginResultDto {
    /**
     *
     * @type {string}
     * @memberof LoginResultDto
     */
    LoginUnsuccessfulReason: LoginResultDtoLoginUnsuccessfulReasonEnum;
    /**
     *
     * @type {boolean}
     * @memberof LoginResultDto
     */
    RecaptchaRequired: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LoginResultDto
     */
    LoggedInSuccessfully: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum LoginResultDtoLoginUnsuccessfulReasonEnum {
    ReCaptcha = "RE_CAPTCHA",
    BadCredentials = "BAD_CREDENTIALS",
    SiteMismatch = "SITE_MISMATCH",
    WrongUserData = "WRONG_USER_DATA",
}
/**
 *
 * @export
 * @interface LoginUserDto
 */
export interface LoginUserDto {
    /**
     *
     * @type {string}
     * @memberof LoginUserDto
     */
    RecaptchaResponse: string;
    /**
     *
     * @type {string}
     * @memberof LoginUserDto
     */
    Username: string;
    /**
     *
     * @type {string}
     * @memberof LoginUserDto
     */
    Password: string;
}
/**
 *
 * @export
 * @interface MobileDto
 */
export interface MobileDto {
    /**
     *
     * @type {string}
     * @memberof MobileDto
     */
    Value: string;
}
/**
 *
 * @export
 * @interface MutualFundsAccountPaymentDetailDto
 */
export interface MutualFundsAccountPaymentDetailDto {
    /**
     *
     * @type {string}
     * @memberof MutualFundsAccountPaymentDetailDto
     */
    Iban: string;
    /**
     *
     * @type {string}
     * @memberof MutualFundsAccountPaymentDetailDto
     */
    AccountNumberPrefix: string;
    /**
     *
     * @type {string}
     * @memberof MutualFundsAccountPaymentDetailDto
     */
    AccountNumber: string;
    /**
     *
     * @type {CodeValueDto}
     * @memberof MutualFundsAccountPaymentDetailDto
     */
    BankNumber: CodeValueDto;
    /**
     *
     * @type {CodeValueDto}
     * @memberof MutualFundsAccountPaymentDetailDto
     */
    BicCode: CodeValueDto;
    /**
     *
     * @type {string}
     * @memberof MutualFundsAccountPaymentDetailDto
     */
    VariableSymbol: string;
    /**
     *
     * @type {string}
     * @memberof MutualFundsAccountPaymentDetailDto
     */
    SpecificSymbol: string;
    /**
     *
     * @type {string}
     * @memberof MutualFundsAccountPaymentDetailDto
     */
    TransactionName: string;
    /**
     *
     * @type {string}
     * @memberof MutualFundsAccountPaymentDetailDto
     */
    CurrencyCode: string;
    /**
     *
     * @type {CodeValueDto}
     * @memberof MutualFundsAccountPaymentDetailDto
     */
    Fund: CodeValueDto;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsAccountPaymentDetailDto
     */
    ActualPricePerShare: AmountDto;
}
/**
 *
 * @export
 * @interface MutualFundsActualValueOfInvestmentsDto
 */
export interface MutualFundsActualValueOfInvestmentsDto {
    /**
     *
     * @type {CodeValueDto}
     * @memberof MutualFundsActualValueOfInvestmentsDto
     */
    Fund: CodeValueDto;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsActualValueOfInvestmentsDto
     */
    InvestedAmount: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsActualValueOfInvestmentsDto
     */
    NettoInvestedAmount: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsActualValueOfInvestmentsDto
     */
    EntryFee: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsActualValueOfInvestmentsDto
     */
    OutputFee: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsActualValueOfInvestmentsDto
     */
    RoundingFee: AmountDto;
    /**
     *
     * @type {string}
     * @memberof MutualFundsActualValueOfInvestmentsDto
     */
    NumberOfShares: string;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsActualValueOfInvestmentsDto
     */
    ActualShareValue: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsActualValueOfInvestmentsDto
     */
    ActualValueOfInvestments: AmountDto;
    /**
     *
     * @type {string}
     * @memberof MutualFundsActualValueOfInvestmentsDto
     */
    Profit: string;
}
/**
 *
 * @export
 * @interface MutualFundsDirectCancelInstructionDto
 */
export interface MutualFundsDirectCancelInstructionDto {
    /**
     *
     * @type {number}
     * @memberof MutualFundsDirectCancelInstructionDto
     */
    IdObject: number;
    /**
     *
     * @type {string}
     * @memberof MutualFundsDirectCancelInstructionDto
     */
    IdRequest: string;
    /**
     *
     * @type {string}
     * @memberof MutualFundsDirectCancelInstructionDto
     */
    OtpId: string;
    /**
     *
     * @type {string}
     * @memberof MutualFundsDirectCancelInstructionDto
     */
    OtpCode: string;
}
/**
 *
 * @export
 * @interface MutualFundsDirectFinancialOperationDto
 */
export interface MutualFundsDirectFinancialOperationDto {
    /**
     *
     * @type {string}
     * @memberof MutualFundsDirectFinancialOperationDto
     */
    Id: string;
    /**
     *
     * @type {string}
     * @memberof MutualFundsDirectFinancialOperationDto
     */
    ValueDate: string;
    /**
     *
     * @type {CodeValueDto}
     * @memberof MutualFundsDirectFinancialOperationDto
     */
    Type: CodeValueDto;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsDirectFinancialOperationDto
     */
    Payment: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsDirectFinancialOperationDto
     */
    NettoInvestedAmount: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsDirectFinancialOperationDto
     */
    FeePayment: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsDirectFinancialOperationDto
     */
    RoundingFee: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsDirectFinancialOperationDto
     */
    InvestedAmount: AmountDto;
    /**
     *
     * @type {CodeValueDto}
     * @memberof MutualFundsDirectFinancialOperationDto
     */
    Fund: CodeValueDto;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsDirectFinancialOperationDto
     */
    PricePerShareAtOperationDateTime: AmountDto;
    /**
     *
     * @type {string}
     * @memberof MutualFundsDirectFinancialOperationDto
     */
    NumberOfShares: string;
}
/**
 *
 * @export
 * @interface MutualFundsDirectFinancialStatementDto
 */
export interface MutualFundsDirectFinancialStatementDto {
    /**
     *
     * @type {string}
     * @memberof MutualFundsDirectFinancialStatementDto
     */
    StatementDate: string;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsDirectFinancialStatementDto
     */
    InvestedAmountSum: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsDirectFinancialStatementDto
     */
    ActualValueOfInvestmentsSum: AmountDto;
    /**
     *
     * @type {string}
     * @memberof MutualFundsDirectFinancialStatementDto
     */
    ProfitSum: string;
    /**
     *
     * @type {Array<MutualFundsActualValueOfInvestmentsDto>}
     * @memberof MutualFundsDirectFinancialStatementDto
     */
    ActualValueOfInvestmentsBasedOnFund: Array<MutualFundsActualValueOfInvestmentsDto>;
    /**
     *
     * @type {Array<MutualFundsInvestmentsProfitDto>}
     * @memberof MutualFundsDirectFinancialStatementDto
     */
    InvestmentsProfits: Array<MutualFundsInvestmentsProfitDto>;
}
/**
 *
 * @export
 * @interface MutualFundsFinancialRequestDto
 */
export interface MutualFundsFinancialRequestDto {
    /**
     *
     * @type {string}
     * @memberof MutualFundsFinancialRequestDto
     */
    Id: string;
    /**
     *
     * @type {string}
     * @memberof MutualFundsFinancialRequestDto
     */
    CreationDate: string;
    /**
     *
     * @type {CodeValueDto}
     * @memberof MutualFundsFinancialRequestDto
     */
    TransactionType: CodeValueDto;
    /**
     *
     * @type {string}
     * @memberof MutualFundsFinancialRequestDto
     */
    PeriodicityType: string;
    /**
     *
     * @type {CodeValueDto}
     * @memberof MutualFundsFinancialRequestDto
     */
    Frequency: CodeValueDto;
    /**
     *
     * @type {CodeValueDto}
     * @memberof MutualFundsFinancialRequestDto
     */
    TransactionFundFrom: CodeValueDto;
    /**
     *
     * @type {string}
     * @memberof MutualFundsFinancialRequestDto
     */
    FirstTransactionDate: string;
    /**
     *
     * @type {string}
     * @memberof MutualFundsFinancialRequestDto
     */
    LastTransactionDate: string;
    /**
     *
     * @type {CodeValueDto}
     * @memberof MutualFundsFinancialRequestDto
     */
    TransactionValueType: CodeValueDto;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsFinancialRequestDto
     */
    TransactionValue: AmountDto;
    /**
     *
     * @type {string}
     * @memberof MutualFundsFinancialRequestDto
     */
    NumberOfSharesInTransaction: string;
    /**
     *
     * @type {string}
     * @memberof MutualFundsFinancialRequestDto
     */
    ArbesTaType: string;
    /**
     *
     * @type {CodeValueDto}
     * @memberof MutualFundsFinancialRequestDto
     */
    TransactionStatus: CodeValueDto;
    /**
     *
     * @type {MutualFundsAccountPaymentDetailDto}
     * @memberof MutualFundsFinancialRequestDto
     */
    TransactionTo: MutualFundsAccountPaymentDetailDto;
    /**
     *
     * @type {boolean}
     * @memberof MutualFundsFinancialRequestDto
     */
    IsCreatedInMyAxa: boolean;
}
/**
 *
 * @export
 * @interface MutualFundsInvestmentsProfitDto
 */
export interface MutualFundsInvestmentsProfitDto {
    /**
     *
     * @type {string}
     * @memberof MutualFundsInvestmentsProfitDto
     */
    Date: string;
    /**
     *
     * @type {CodeValueDto}
     * @memberof MutualFundsInvestmentsProfitDto
     */
    Fund: CodeValueDto;
    /**
     *
     * @type {string}
     * @memberof MutualFundsInvestmentsProfitDto
     */
    NumberOfShares: string;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsInvestmentsProfitDto
     */
    PurchasePrice: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsInvestmentsProfitDto
     */
    NettoPurchasePrice: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsInvestmentsProfitDto
     */
    PurchasePricePerShare: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsInvestmentsProfitDto
     */
    ActualPrice: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsInvestmentsProfitDto
     */
    ActualPricePerShare: AmountDto;
    /**
     *
     * @type {string}
     * @memberof MutualFundsInvestmentsProfitDto
     */
    Profit: string;
}
/**
 *
 * @export
 * @interface MutualFundsPaymentDetailDto
 */
export interface MutualFundsPaymentDetailDto {
    /**
     *
     * @type {string}
     * @memberof MutualFundsPaymentDetailDto
     */
    AccountNumber: string;
    /**
     *
     * @type {string}
     * @memberof MutualFundsPaymentDetailDto
     */
    SkQrCode: string;
    /**
     *
     * @type {CodeValueDto}
     * @memberof MutualFundsPaymentDetailDto
     */
    Fund: CodeValueDto;
}
/**
 *
 * @export
 * @interface MutualFundsProfileChangeDto
 */
export interface MutualFundsProfileChangeDto {
    /**
     *
     * @type {string}
     * @memberof MutualFundsProfileChangeDto
     */
    EffectiveDate: string;
    /**
     *
     * @type {CodeValueDto}
     * @memberof MutualFundsProfileChangeDto
     */
    TargetProfile: CodeValueDto;
}
/**
 *
 * @export
 * @interface MutualFundsRegularFinancialOperationDetailDto
 */
export interface MutualFundsRegularFinancialOperationDetailDto {
    /**
     *
     * @type {string}
     * @memberof MutualFundsRegularFinancialOperationDetailDto
     */
    Id: string;
    /**
     *
     * @type {CodeValueDto}
     * @memberof MutualFundsRegularFinancialOperationDetailDto
     */
    OperationType: CodeValueDto;
    /**
     *
     * @type {string}
     * @memberof MutualFundsRegularFinancialOperationDetailDto
     */
    ValueDate: string;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsRegularFinancialOperationDetailDto
     */
    Payment: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsRegularFinancialOperationDetailDto
     */
    FeePayment: AmountDto;
    /**
     *
     * @type {CodeValueDto}
     * @memberof MutualFundsRegularFinancialOperationDetailDto
     */
    TransactionFundFrom: CodeValueDto;
    /**
     *
     * @type {MutualFundsAccountPaymentDetailDto}
     * @memberof MutualFundsRegularFinancialOperationDetailDto
     */
    TransactionTo: MutualFundsAccountPaymentDetailDto;
    /**
     *
     * @type {string}
     * @memberof MutualFundsRegularFinancialOperationDetailDto
     */
    TransactionDate: string;
    /**
     *
     * @type {string}
     * @memberof MutualFundsRegularFinancialOperationDetailDto
     */
    NumberOfSharesInTransaction: string;
    /**
     *
     * @type {CodeValueDto}
     * @memberof MutualFundsRegularFinancialOperationDetailDto
     */
    TransactionStatus: CodeValueDto;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsRegularFinancialOperationDetailDto
     */
    RoundingFee: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsRegularFinancialOperationDetailDto
     */
    InvestedAmount: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsRegularFinancialOperationDetailDto
     */
    NettoInvestedAmount: AmountDto;
    /**
     *
     * @type {CodeValueDto}
     * @memberof MutualFundsRegularFinancialOperationDetailDto
     */
    InvestmentProfile: CodeValueDto;
    /**
     *
     * @type {Array<MutualFundsRegularFinancialOperationFundDto>}
     * @memberof MutualFundsRegularFinancialOperationDetailDto
     */
    Funds: Array<MutualFundsRegularFinancialOperationFundDto>;
}
/**
 *
 * @export
 * @interface MutualFundsRegularFinancialOperationDto
 */
export interface MutualFundsRegularFinancialOperationDto {
    /**
     *
     * @type {string}
     * @memberof MutualFundsRegularFinancialOperationDto
     */
    Id: string;
    /**
     *
     * @type {string}
     * @memberof MutualFundsRegularFinancialOperationDto
     */
    ValueDate: string;
    /**
     *
     * @type {CodeValueDto}
     * @memberof MutualFundsRegularFinancialOperationDto
     */
    Type: CodeValueDto;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsRegularFinancialOperationDto
     */
    Payment: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsRegularFinancialOperationDto
     */
    NettoInvestedAmount: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsRegularFinancialOperationDto
     */
    FeePayment: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsRegularFinancialOperationDto
     */
    RoundingFee: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsRegularFinancialOperationDto
     */
    InvestedAmount: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsRegularFinancialOperationDto
     */
    CumulativeFee: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsRegularFinancialOperationDto
     */
    CumulativeInvestment: AmountDto;
}
/**
 *
 * @export
 * @interface MutualFundsRegularFinancialOperationFundDto
 */
export interface MutualFundsRegularFinancialOperationFundDto {
    /**
     *
     * @type {string}
     * @memberof MutualFundsRegularFinancialOperationFundDto
     */
    ShareOfInvestmentInTheFund: string;
    /**
     *
     * @type {CodeValueDto}
     * @memberof MutualFundsRegularFinancialOperationFundDto
     */
    Fund: CodeValueDto;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsRegularFinancialOperationFundDto
     */
    Amount: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsRegularFinancialOperationFundDto
     */
    NettoAmount: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsRegularFinancialOperationFundDto
     */
    RoundingFee: AmountDto;
    /**
     *
     * @type {string}
     * @memberof MutualFundsRegularFinancialOperationFundDto
     */
    DateOfInstructionGeneration: string;
    /**
     *
     * @type {string}
     * @memberof MutualFundsRegularFinancialOperationFundDto
     */
    DateOfInstructionRealization: string;
    /**
     *
     * @type {string}
     * @memberof MutualFundsRegularFinancialOperationFundDto
     */
    NumberOfPurchasedShares: string;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsRegularFinancialOperationFundDto
     */
    PriceOfOneShare: AmountDto;
}
/**
 *
 * @export
 * @interface MutualFundsRegularFinancialStatementDto
 */
export interface MutualFundsRegularFinancialStatementDto {
    /**
     *
     * @type {string}
     * @memberof MutualFundsRegularFinancialStatementDto
     */
    StatementDate: string;
    /**
     *
     * @type {CodeValueDto}
     * @memberof MutualFundsRegularFinancialStatementDto
     */
    InvestmentProfile: CodeValueDto;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsRegularFinancialStatementDto
     */
    DepositedFunds: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsRegularFinancialStatementDto
     */
    PaidEntryFee: AmountDto;
    /**
     *
     * @type {boolean}
     * @memberof MutualFundsRegularFinancialStatementDto
     */
    IsEntryFeePaid: boolean;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsRegularFinancialStatementDto
     */
    InvestedAmount: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsRegularFinancialStatementDto
     */
    NettoInvestedAmount: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsRegularFinancialStatementDto
     */
    ActualValueOfInvestments: AmountDto;
    /**
     *
     * @type {string}
     * @memberof MutualFundsRegularFinancialStatementDto
     */
    Profit: string;
    /**
     *
     * @type {Array<MutualFundsActualValueOfInvestmentsDto>}
     * @memberof MutualFundsRegularFinancialStatementDto
     */
    ActualValueOfInvestmentsBasedOnFund: Array<MutualFundsActualValueOfInvestmentsDto>;
    /**
     *
     * @type {Array<MutualFundsInvestmentsProfitDto>}
     * @memberof MutualFundsRegularFinancialStatementDto
     */
    InvestmentsProfits: Array<MutualFundsInvestmentsProfitDto>;
}
/**
 *
 * @export
 * @interface MutualFundsRegularProfileChangeDto
 */
export interface MutualFundsRegularProfileChangeDto {
    /**
     *
     * @type {boolean}
     * @memberof MutualFundsRegularProfileChangeDto
     */
    IsChangeAvailable: boolean;
    /**
     *
     * @type {AmountDto}
     * @memberof MutualFundsRegularProfileChangeDto
     */
    ChangeFee: AmountDto;
    /**
     *
     * @type {CodeValueDto}
     * @memberof MutualFundsRegularProfileChangeDto
     */
    DisapprovalReason: CodeValueDto;
}
/**
 *
 * @export
 * @interface MutualFundsTransactionDto
 */
export interface MutualFundsTransactionDto {
    /**
     *
     * @type {number}
     * @memberof MutualFundsTransactionDto
     */
    IdObject: number;
    /**
     *
     * @type {number}
     * @memberof MutualFundsTransactionDto
     */
    ProductGroupId: number;
    /**
     *
     * @type {string}
     * @memberof MutualFundsTransactionDto
     */
    SellScope: MutualFundsTransactionDtoSellScopeEnum;
    /**
     *
     * @type {string}
     * @memberof MutualFundsTransactionDto
     */
    SourceFund: string;
    /**
     *
     * @type {string}
     * @memberof MutualFundsTransactionDto
     */
    Means: MutualFundsTransactionDtoMeansEnum;
    /**
     *
     * @type {number}
     * @memberof MutualFundsTransactionDto
     */
    Quantity: number;
    /**
     *
     * @type {number}
     * @memberof MutualFundsTransactionDto
     */
    Amount: number;
    /**
     *
     * @type {string}
     * @memberof MutualFundsTransactionDto
     */
    Type: MutualFundsTransactionDtoTypeEnum;
    /**
     *
     * @type {string}
     * @memberof MutualFundsTransactionDto
     */
    Date: string;
    /**
     *
     * @type {string}
     * @memberof MutualFundsTransactionDto
     */
    StartDate: string;
    /**
     *
     * @type {string}
     * @memberof MutualFundsTransactionDto
     */
    EndDate: string;
    /**
     *
     * @type {string}
     * @memberof MutualFundsTransactionDto
     */
    Frequency: MutualFundsTransactionDtoFrequencyEnum;
    /**
     *
     * @type {string}
     * @memberof MutualFundsTransactionDto
     */
    Target: MutualFundsTransactionDtoTargetEnum;
    /**
     *
     * @type {string}
     * @memberof MutualFundsTransactionDto
     */
    TargetFund: string;
    /**
     *
     * @type {BankAccountDto}
     * @memberof MutualFundsTransactionDto
     */
    Account: BankAccountDto;
    /**
     *
     * @type {string}
     * @memberof MutualFundsTransactionDto
     */
    Note: string;
    /**
     *
     * @type {string}
     * @memberof MutualFundsTransactionDto
     */
    OtpId: string;
    /**
     *
     * @type {string}
     * @memberof MutualFundsTransactionDto
     */
    OtpCode: string;
}

/**
 * @export
 * @enum {string}
 */
export enum MutualFundsTransactionDtoSellScopeEnum {
    Fund = "FUND",
    Program = "PROGRAM",
}
/**
 * @export
 * @enum {string}
 */
export enum MutualFundsTransactionDtoMeansEnum {
    Amount = "AMOUNT",
    Quantity = "QUANTITY",
    Sellall = "SELLALL",
}
/**
 * @export
 * @enum {string}
 */
export enum MutualFundsTransactionDtoTypeEnum {
    Onetime = "ONETIME",
    Periodical = "PERIODICAL",
}
/**
 * @export
 * @enum {string}
 */
export enum MutualFundsTransactionDtoFrequencyEnum {
    None = "NONE",
    Onetime = "ONETIME",
    Monthly = "MONTHLY",
    Quarterly = "QUARTERLY",
    Halfyearly = "HALFYEARLY",
    Yearly = "YEARLY",
}
/**
 * @export
 * @enum {string}
 */
export enum MutualFundsTransactionDtoTargetEnum {
    Sell = "SELL",
    Switch = "SWITCH",
}
/**
 *
 * @export
 * @interface NotificationContentDto
 */
export interface NotificationContentDto {
    /**
     *
     * @type {string}
     * @memberof NotificationContentDto
     */
    Id: string;
    /**
     *
     * @type {string}
     * @memberof NotificationContentDto
     */
    Content: string;
}
/**
 *
 * @export
 * @interface NotificationDto
 */
export interface NotificationDto {
    /**
     *
     * @type {string}
     * @memberof NotificationDto
     */
    Id: string;
    /**
     *
     * @type {string}
     * @memberof NotificationDto
     */
    Date: string;
    /**
     *
     * @type {string}
     * @memberof NotificationDto
     */
    MessageSubject: string;
    /**
     *
     * @type {string}
     * @memberof NotificationDto
     */
    ContractNumber: string;
    /**
     *
     * @type {string}
     * @memberof NotificationDto
     */
    Product: string;
    /**
     *
     * @type {string}
     * @memberof NotificationDto
     */
    CommunicationChannel: NotificationDtoCommunicationChannelEnum;
    /**
     *
     * @type {string}
     * @memberof NotificationDto
     */
    SentTo: string;
    /**
     *
     * @type {boolean}
     * @memberof NotificationDto
     */
    Attachment: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum NotificationDtoCommunicationChannelEnum {
    Email = "EMAIL",
    Sms = "SMS",
}
/**
 *
 * @export
 * @interface OnlinePaymentLinkDto
 */
export interface OnlinePaymentLinkDto {
    /**
     *
     * @type {string}
     * @memberof OnlinePaymentLinkDto
     */
    PaymentLink: string;
    /**
     *
     * @type {string}
     * @memberof OnlinePaymentLinkDto
     */
    RequestId: string;
}
/**
 *
 * @export
 * @interface OnlinePaymentStatusDto
 */
export interface OnlinePaymentStatusDto {
    /**
     *
     * @type {boolean}
     * @memberof OnlinePaymentStatusDto
     */
    Successful: boolean;
}
/**
 *
 * @export
 * @interface OpeningTimesDto
 */
export interface OpeningTimesDto {
    /**
     *
     * @type {string}
     * @memberof OpeningTimesDto
     */
    Monday: string;
    /**
     *
     * @type {string}
     * @memberof OpeningTimesDto
     */
    Tuesday: string;
    /**
     *
     * @type {string}
     * @memberof OpeningTimesDto
     */
    Wednesday: string;
    /**
     *
     * @type {string}
     * @memberof OpeningTimesDto
     */
    Thursday: string;
    /**
     *
     * @type {string}
     * @memberof OpeningTimesDto
     */
    Friday: string;
    /**
     *
     * @type {string}
     * @memberof OpeningTimesDto
     */
    Saturday: string;
    /**
     *
     * @type {string}
     * @memberof OpeningTimesDto
     */
    Sunday: string;
}
/**
 *
 * @export
 * @interface OtpChallengeDto
 */
export interface OtpChallengeDto {
    /**
     *
     * @type {string}
     * @memberof OtpChallengeDto
     */
    Message: string;
    /**
     *
     * @type {string}
     * @memberof OtpChallengeDto
     */
    PhoneNumber: string;
    /**
     *
     * @type {string}
     * @memberof OtpChallengeDto
     */
    OtpId: string;
}
/**
 *
 * @export
 * @interface PasswordResetDto
 */
export interface PasswordResetDto {
    /**
     *
     * @type {string}
     * @memberof PasswordResetDto
     */
    CurrentPassword: string;
    /**
     *
     * @type {string}
     * @memberof PasswordResetDto
     */
    NewPassword: string;
    /**
     *
     * @type {string}
     * @memberof PasswordResetDto
     */
    VerificationPassword: string;
    /**
     *
     * @type {string}
     * @memberof PasswordResetDto
     */
    ContractNumber: string;
    /**
     *
     * @type {string}
     * @memberof PasswordResetDto
     */
    OtpId: string;
    /**
     *
     * @type {string}
     * @memberof PasswordResetDto
     */
    OtpCode: string;
    /**
     *
     * @type {string}
     * @memberof PasswordResetDto
     */
    AxaUuid: string;
}
/**
 *
 * @export
 * @interface PasswordUpdateDto
 */
export interface PasswordUpdateDto {
    /**
     *
     * @type {string}
     * @memberof PasswordUpdateDto
     */
    CurrentPassword: string;
    /**
     *
     * @type {string}
     * @memberof PasswordUpdateDto
     */
    NewPassword: string;
    /**
     *
     * @type {string}
     * @memberof PasswordUpdateDto
     */
    VerificationPassword: string;
}
/**
 *
 * @export
 * @interface PaymentAmountDto
 */
export interface PaymentAmountDto {
    /**
     *
     * @type {string}
     * @memberof PaymentAmountDto
     */
    CurrencyCode: string;
    /**
     *
     * @type {number}
     * @memberof PaymentAmountDto
     */
    Value: number;
}
/**
 *
 * @export
 * @interface PaymentInfoDto
 */
export interface PaymentInfoDto {
    /**
     *
     * @type {string}
     * @memberof PaymentInfoDto
     */
    AccountNumber: string;
    /**
     *
     * @type {string}
     * @memberof PaymentInfoDto
     */
    VariableSymbol: string;
    /**
     *
     * @type {string}
     * @memberof PaymentInfoDto
     */
    SpecificSymbol: string;
    /**
     *
     * @type {Array<MutualFundsPaymentDetailDto>}
     * @memberof PaymentInfoDto
     */
    FundsToInvest: Array<MutualFundsPaymentDetailDto>;
    /**
     *
     * @type {boolean}
     * @memberof PaymentInfoDto
     */
    HasDebtAndScheduler: boolean;
    /**
     *
     * @type {PaymentAmountDto}
     * @memberof PaymentInfoDto
     */
    DebtAmount: PaymentAmountDto;
    /**
     *
     * @type {PaymentAmountDto}
     * @memberof PaymentInfoDto
     */
    AdvancedSchedulerAmount: PaymentAmountDto;
    /**
     *
     * @type {PaymentAmountDto}
     * @memberof PaymentInfoDto
     */
    CombinedAmount: PaymentAmountDto;
    /**
     *
     * @type {string}
     * @memberof PaymentInfoDto
     */
    DebtSkQrCode: string;
    /**
     *
     * @type {string}
     * @memberof PaymentInfoDto
     */
    AdvancedSchedulerSkQrCode: string;
    /**
     *
     * @type {string}
     * @memberof PaymentInfoDto
     */
    CombinedAmountSkQrCode: string;
    /**
     *
     * @type {boolean}
     * @memberof PaymentInfoDto
     */
    CanPayOnline: boolean;
    /**
     *
     * @type {string}
     * @memberof PaymentInfoDto
     */
    ProductGroupName: PaymentInfoDtoProductGroupNameEnum;
    /**
     *
     * @type {boolean}
     * @memberof PaymentInfoDto
     */
    UniqaContract: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum PaymentInfoDtoProductGroupNameEnum {
    Life = "LIFE",
    Vehicle = "VEHICLE",
    Home = "HOME",
    MutualFunds = "MUTUAL_FUNDS",
    Pension = "PENSION",
    Travel = "TRAVEL",
}
/**
 *
 * @export
 * @interface PaymentModeDto
 */
export interface PaymentModeDto {
    /**
     *
     * @type {string}
     * @memberof PaymentModeDto
     */
    Code: PaymentModeDtoCodeEnum;
    /**
     *
     * @type {Array<LocalizedNameDto>}
     * @memberof PaymentModeDto
     */
    Names: Array<LocalizedNameDto>;
}

/**
 * @export
 * @enum {string}
 */
export enum PaymentModeDtoCodeEnum {
    BankAccount = "BY_BANK_ACCOUNT",
    Cash = "BY_CASH",
    DirectDebit = "BY_DIRECT_DEBIT",
    PostalOrder = "BY_POSTAL_ORDER",
    Sipo = "BY_SIPO",
    Employment = "BY_EMPLOYMENT",
}
/**
 *
 * @export
 * @interface PaymentsSchedulerDto
 */
export interface PaymentsSchedulerDto {
    /**
     *
     * @type {PeriodicityDto}
     * @memberof PaymentsSchedulerDto
     */
    Periodicity: PeriodicityDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PaymentsSchedulerDto
     */
    ModalAmount: AmountDto;
    /**
     *
     * @type {PaymentModeDto}
     * @memberof PaymentsSchedulerDto
     */
    PaymentMode: PaymentModeDto;
    /**
     *
     * @type {string}
     * @memberof PaymentsSchedulerDto
     */
    PaymentType: PaymentsSchedulerDtoPaymentTypeEnum;
    /**
     *
     * @type {string}
     * @memberof PaymentsSchedulerDto
     */
    PaymentStartDate: string;
    /**
     *
     * @type {string}
     * @memberof PaymentsSchedulerDto
     */
    PaymentEndDate: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PaymentsSchedulerDtoPaymentTypeEnum {
    A = "A",
    P = "P",
}
/**
 *
 * @export
 * @interface PensionDpsContractBalanceSheetDto
 */
export interface PensionDpsContractBalanceSheetDto {
    /**
     *
     * @type {AmountDto}
     * @memberof PensionDpsContractBalanceSheetDto
     */
    HolderPaymentsTotal: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionDpsContractBalanceSheetDto
     */
    GovernmentPaymentsTotal: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionDpsContractBalanceSheetDto
     */
    EmployerPaymentsTotal: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionDpsContractBalanceSheetDto
     */
    OtherPensionContractsPaymentsTotal: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionDpsContractBalanceSheetDto
     */
    OtherPaymentsTotal: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionDpsContractBalanceSheetDto
     */
    AxaClubLoyaltyCardTotal: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionDpsContractBalanceSheetDto
     */
    WithdrawalAmountTotal: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionDpsContractBalanceSheetDto
     */
    TaxesAndRoundingAmountTotal: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionDpsContractBalanceSheetDto
     */
    EarningsAmountTotal: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionDpsContractBalanceSheetDto
     */
    PaymentsAmountTotal: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionDpsContractBalanceSheetDto
     */
    NetAmountTotal: AmountDto;
}
/**
 *
 * @export
 * @interface PensionDpsFinancialOperationDto
 */
export interface PensionDpsFinancialOperationDto {
    /**
     *
     * @type {string}
     * @memberof PensionDpsFinancialOperationDto
     */
    Id: string;
    /**
     *
     * @type {string}
     * @memberof PensionDpsFinancialOperationDto
     */
    Type: string;
    /**
     *
     * @type {string}
     * @memberof PensionDpsFinancialOperationDto
     */
    TypeCode: string;
    /**
     *
     * @type {string}
     * @memberof PensionDpsFinancialOperationDto
     */
    InvestmentFundCode: string;
    /**
     *
     * @type {string}
     * @memberof PensionDpsFinancialOperationDto
     */
    InvestmentFundName: string;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionDpsFinancialOperationDto
     */
    GrossAmount: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionDpsFinancialOperationDto
     */
    NetAmount: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionDpsFinancialOperationDto
     */
    PricePerShareAtOperationDateTime: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionDpsFinancialOperationDto
     */
    ActualPricePerShare: AmountDto;
    /**
     *
     * @type {string}
     * @memberof PensionDpsFinancialOperationDto
     */
    NumberOfShares: string;
    /**
     *
     * @type {string}
     * @memberof PensionDpsFinancialOperationDto
     */
    ValueDate: string;
    /**
     *
     * @type {string}
     * @memberof PensionDpsFinancialOperationDto
     */
    Status: string;
}
/**
 *
 * @export
 * @interface PensionDpsFinancialStatementDto
 */
export interface PensionDpsFinancialStatementDto {
    /**
     *
     * @type {string}
     * @memberof PensionDpsFinancialStatementDto
     */
    DateTime: string;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionDpsFinancialStatementDto
     */
    NetAmountTotal: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionDpsFinancialStatementDto
     */
    InvestmentsSumInvested: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionDpsFinancialStatementDto
     */
    InvestmentsSumNotInvested: AmountDto;
    /**
     *
     * @type {number}
     * @memberof PensionDpsFinancialStatementDto
     */
    SumShares: number;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionDpsFinancialStatementDto
     */
    InvestmentsValue: AmountDto;
    /**
     *
     * @type {number}
     * @memberof PensionDpsFinancialStatementDto
     */
    ValuationPercentage: number;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionDpsFinancialStatementDto
     */
    ActualInvestmentsValue: AmountDto;
    /**
     *
     * @type {Array<PensionDpsInvestmentsStatementDto>}
     * @memberof PensionDpsFinancialStatementDto
     */
    PensionInvestmentsFundsInvestmentsStatements: Array<PensionDpsInvestmentsStatementDto>;
    /**
     *
     * @type {PensionDpsContractBalanceSheetDto}
     * @memberof PensionDpsFinancialStatementDto
     */
    ContractBalanceSheet: PensionDpsContractBalanceSheetDto;
}
/**
 *
 * @export
 * @interface PensionDpsInvestmentsStatementDto
 */
export interface PensionDpsInvestmentsStatementDto {
    /**
     *
     * @type {string}
     * @memberof PensionDpsInvestmentsStatementDto
     */
    FundCode: string;
    /**
     *
     * @type {string}
     * @memberof PensionDpsInvestmentsStatementDto
     */
    FundName: string;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionDpsInvestmentsStatementDto
     */
    InvestmentsSumInvested: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionDpsInvestmentsStatementDto
     */
    InvestmentsSumNotInvested: AmountDto;
    /**
     *
     * @type {number}
     * @memberof PensionDpsInvestmentsStatementDto
     */
    SumShares: number;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionDpsInvestmentsStatementDto
     */
    PricePerShare: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionDpsInvestmentsStatementDto
     */
    ActualInvestmentsValue: AmountDto;
    /**
     *
     * @type {number}
     * @memberof PensionDpsInvestmentsStatementDto
     */
    ValuationPercentage: number;
}
/**
 *
 * @export
 * @interface PensionDssDdsContractBalanceSheetDto
 */
export interface PensionDssDdsContractBalanceSheetDto {
    /**
     *
     * @type {AmountDto}
     * @memberof PensionDssDdsContractBalanceSheetDto
     */
    CompulsoryAmountTransfersTotal: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionDssDdsContractBalanceSheetDto
     */
    CompulsoryPaymentsTotal: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionDssDdsContractBalanceSheetDto
     */
    VoluntaryAmountTransfersTotal: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionDssDdsContractBalanceSheetDto
     */
    VoluntaryPaymentsTotal: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionDssDdsContractBalanceSheetDto
     */
    EmployerPaymentsTotal: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionDssDdsContractBalanceSheetDto
     */
    HolderPaymentsTotal: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionDssDdsContractBalanceSheetDto
     */
    ExtraordinaryEmployerPaymentsTotal: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionDssDdsContractBalanceSheetDto
     */
    ExtraordinaryHolderPaymentsTotal: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionDssDdsContractBalanceSheetDto
     */
    OtherPaymentsTotal: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionDssDdsContractBalanceSheetDto
     */
    OtherPensionContractsPaymentsTotal: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionDssDdsContractBalanceSheetDto
     */
    PaymentsAmountTotal: AmountDto;
}
/**
 *
 * @export
 * @interface PensionDssDdsFinancialOperationDto
 */
export interface PensionDssDdsFinancialOperationDto {
    /**
     *
     * @type {string}
     * @memberof PensionDssDdsFinancialOperationDto
     */
    Id: string;
    /**
     *
     * @type {string}
     * @memberof PensionDssDdsFinancialOperationDto
     */
    PaymentDate: string;
    /**
     *
     * @type {CodeValueDto}
     * @memberof PensionDssDdsFinancialOperationDto
     */
    Type: CodeValueDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionDssDdsFinancialOperationDto
     */
    GrossAmount: AmountDto;
    /**
     *
     * @type {CodeValueDto}
     * @memberof PensionDssDdsFinancialOperationDto
     */
    InvestmentFund: CodeValueDto;
    /**
     *
     * @type {string}
     * @memberof PensionDssDdsFinancialOperationDto
     */
    InvestmentDate: string;
    /**
     *
     * @type {string}
     * @memberof PensionDssDdsFinancialOperationDto
     */
    InvestmentPeriod: string;
    /**
     *
     * @type {string}
     * @memberof PensionDssDdsFinancialOperationDto
     */
    NumberOfShares: string;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionDssDdsFinancialOperationDto
     */
    PricePerShareAtOperationDateTime: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionDssDdsFinancialOperationDto
     */
    ActualPricePerShare: AmountDto;
}
/**
 *
 * @export
 * @interface PensionDssDdsFinancialStatementDto
 */
export interface PensionDssDdsFinancialStatementDto {
    /**
     *
     * @type {string}
     * @memberof PensionDssDdsFinancialStatementDto
     */
    Date: string;
    /**
     *
     * @type {string}
     * @memberof PensionDssDdsFinancialStatementDto
     */
    SumNumberOfShares: string;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionDssDdsFinancialStatementDto
     */
    ActualInvestmentsValue: AmountDto;
    /**
     *
     * @type {Array<PensionDssDdsInvestmentsBasedOnFundDto>}
     * @memberof PensionDssDdsFinancialStatementDto
     */
    PensionInvestmentsBasedOnFund: Array<PensionDssDdsInvestmentsBasedOnFundDto>;
    /**
     *
     * @type {PensionDssDdsContractBalanceSheetDto}
     * @memberof PensionDssDdsFinancialStatementDto
     */
    ContractBalanceSheet: PensionDssDdsContractBalanceSheetDto;
}
/**
 *
 * @export
 * @interface PensionDssDdsInvestmentsBasedOnFundDto
 */
export interface PensionDssDdsInvestmentsBasedOnFundDto {
    /**
     *
     * @type {CodeValueDto}
     * @memberof PensionDssDdsInvestmentsBasedOnFundDto
     */
    InvestmentFund: CodeValueDto;
    /**
     *
     * @type {string}
     * @memberof PensionDssDdsInvestmentsBasedOnFundDto
     */
    NumberOfShares: string;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionDssDdsInvestmentsBasedOnFundDto
     */
    PricePerShare: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionDssDdsInvestmentsBasedOnFundDto
     */
    ActualInvestmentsValue: AmountDto;
    /**
     *
     * @type {string}
     * @memberof PensionDssDdsInvestmentsBasedOnFundDto
     */
    Type: string;
}
/**
 *
 * @export
 * @interface PensionFundAndPercentageDto
 */
export interface PensionFundAndPercentageDto {
    /**
     *
     * @type {string}
     * @memberof PensionFundAndPercentageDto
     */
    Percentage: string;
    /**
     *
     * @type {CodeValueDto}
     * @memberof PensionFundAndPercentageDto
     */
    Fund: CodeValueDto;
}
/**
 *
 * @export
 * @interface PensionFundChangeItem
 */
export interface PensionFundChangeItem {
    /**
     *
     * @type {number}
     * @memberof PensionFundChangeItem
     */
    FundId: number;
    /**
     *
     * @type {number}
     * @memberof PensionFundChangeItem
     */
    Percents: number;
}
/**
 *
 * @export
 * @interface PensionFundsChangeDto
 */
export interface PensionFundsChangeDto {
    /**
     *
     * @type {string}
     * @memberof PensionFundsChangeDto
     */
    EffectiveDate: string;
    /**
     *
     * @type {Array<PensionFundAndPercentageDto>}
     * @memberof PensionFundsChangeDto
     */
    NewPensionFunds: Array<PensionFundAndPercentageDto>;
    /**
     *
     * @type {Array<PensionFundAndPercentageDto>}
     * @memberof PensionFundsChangeDto
     */
    NewInvestmentStrategyFunds: Array<PensionFundAndPercentageDto>;
}
/**
 *
 * @export
 * @interface PensionFundsChangePermittedDto
 */
export interface PensionFundsChangePermittedDto {
    /**
     *
     * @type {boolean}
     * @memberof PensionFundsChangePermittedDto
     */
    FundsChangePermitted: boolean;
}
/**
 *
 * @export
 * @interface PensionFundsChangePermittedByTypeDto
 */
export interface PensionFundsChangePermittedByTypeDto {
    /**
     *
     * @type {string}
     * @memberof PensionFundsChangePermittedByTypeDto
     */
    ChangeStrategyDataPossible: string;
    /**
     *
     * @type {string}
     * @memberof PensionFundsChangePermittedByTypeDto
     */
    TransferFundsDataPossible: string;
    /**
     *
     * @type {string}
     * @memberof PensionFundsChangePermittedByTypeDto
     */
    IsGuaranteedFundRatioReductionPossible: string;
    /**
     *
     * @type {string}
     * @memberof PensionFundsChangePermittedByTypeDto
     */
    Error: string;
}

/**
 *
 * @export
 * @interface PensionInvestmentStrategiesChangeDto
 */
export interface PensionInvestmentStrategiesChangeDto {
    /**
     *
     * @type {string}
     * @memberof PensionInvestmentStrategiesChangeDto
     */
    EffectiveDate: string;
    /**
     *
     * @type {Array<PensionFundAndPercentageDto>}
     * @memberof PensionInvestmentStrategiesChangeDto
     */
    NewInvestmentStrategyFunds: Array<PensionFundAndPercentageDto>;
}
/**
 *
 * @export
 * @interface PensionPpContractBalanceSheetDto
 */
export interface PensionPpContractBalanceSheetDto {
    /**
     *
     * @type {AmountDto}
     * @memberof PensionPpContractBalanceSheetDto
     */
    HolderPaymentsTotal: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionPpContractBalanceSheetDto
     */
    ThirdPartyPaymentsTotal: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionPpContractBalanceSheetDto
     */
    GovernmentPaymentsTotal: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionPpContractBalanceSheetDto
     */
    EmployerPaymentsTotal: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionPpContractBalanceSheetDto
     */
    OtherPensionContractsPaymentsTotal: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionPpContractBalanceSheetDto
     */
    OtherPaymentsTotal: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionPpContractBalanceSheetDto
     */
    AxaClubLoyaltyCardTotal: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionPpContractBalanceSheetDto
     */
    AssetCapitalizationTotal: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionPpContractBalanceSheetDto
     */
    WithdrawalAmountTotal: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionPpContractBalanceSheetDto
     */
    TaxesAndRoundingAmountTotal: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionPpContractBalanceSheetDto
     */
    EarningsAmountTotal: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionPpContractBalanceSheetDto
     */
    PaymentsAmountTotal: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionPpContractBalanceSheetDto
     */
    NetAmountTotal: AmountDto;
}
/**
 *
 * @export
 * @interface PensionPpFinancialOperationDto
 */
export interface PensionPpFinancialOperationDto {
    /**
     *
     * @type {string}
     * @memberof PensionPpFinancialOperationDto
     */
    Id: string;
    /**
     *
     * @type {string}
     * @memberof PensionPpFinancialOperationDto
     */
    Type: string;
    /**
     *
     * @type {string}
     * @memberof PensionPpFinancialOperationDto
     */
    CategoryCode: string;
    /**
     *
     * @type {string}
     * @memberof PensionPpFinancialOperationDto
     */
    CategoryName: string;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionPpFinancialOperationDto
     */
    GrossAmount: AmountDto;
    /**
     *
     * @type {AmountDto}
     * @memberof PensionPpFinancialOperationDto
     */
    NetAmount: AmountDto;
    /**
     *
     * @type {string}
     * @memberof PensionPpFinancialOperationDto
     */
    InitDate: string;
    /**
     *
     * @type {string}
     * @memberof PensionPpFinancialOperationDto
     */
    ValueDate: string;
    /**
     *
     * @type {string}
     * @memberof PensionPpFinancialOperationDto
     */
    Status: string;
}
/**
 *
 * @export
 * @interface PensionPpFinancialStatementDto
 */
export interface PensionPpFinancialStatementDto {
    /**
     *
     * @type {string}
     * @memberof PensionPpFinancialStatementDto
     */
    DateTime: string;
    /**
     *
     * @type {PensionPpContractBalanceSheetDto}
     * @memberof PensionPpFinancialStatementDto
     */
    ContractBalanceSheet: PensionPpContractBalanceSheetDto;
}
/**
 *
 * @export
 * @interface PeriodicityDto
 */
export interface PeriodicityDto {
    /**
     *
     * @type {string}
     * @memberof PeriodicityDto
     */
    Code: PeriodicityDtoCodeEnum;
    /**
     *
     * @type {Array<LocalizedNameDto>}
     * @memberof PeriodicityDto
     */
    Names: Array<LocalizedNameDto>;
}

/**
 * @export
 * @enum {string}
 */
export enum PeriodicityDtoCodeEnum {
    None = "NONE",
    Onetime = "ONETIME",
    Monthly = "MONTHLY",
    Quarterly = "QUARTERLY",
    Halfyearly = "HALFYEARLY",
    Yearly = "YEARLY",
}
/**
 *
 * @export
 * @interface PhoneDto
 */
export interface PhoneDto {
    /**
     *
     * @type {string}
     * @memberof PhoneDto
     */
    Value: string;
}
/**
 *
 * @export
 * @interface PhoneNumberShortDto
 */
export interface PhoneNumberShortDto {
    /**
     *
     * @type {string}
     * @memberof PhoneNumberShortDto
     */
    PhoneNumberShort: string;
    /**
     *
     * @type {boolean}
     * @memberof PhoneNumberShortDto
     */
    ResendPinCountExhausted: boolean;
}
/**
 *
 * @export
 * @interface PolicyDto
 */
export interface PolicyDto {
    /**
     *
     * @type {number}
     * @memberof PolicyDto
     */
    IdObject: number;
    /**
     *
     * @type {string}
     * @memberof PolicyDto
     */
    ContractNumber: string;
    /**
     *
     * @type {string}
     * @memberof PolicyDto
     */
    CountryCode: string;
    /**
     *
     * @type {string}
     * @memberof PolicyDto
     */
    ContractName: string;
    /**
     *
     * @type {number}
     * @memberof PolicyDto
     */
    IdEnuProductGroup: number;
    /**
     *
     * @type {string}
     * @memberof PolicyDto
     */
    ProductGroup: string;
    /**
     *
     * @type {number}
     * @memberof PolicyDto
     */
    IdEnuProductGroupLvl2: number;
    /**
     *
     * @type {string}
     * @memberof PolicyDto
     */
    ProductGroupLvl2: string;
    /**
     *
     * @type {number}
     * @memberof PolicyDto
     */
    IdEnuProduct: number;
    /**
     *
     * @type {string}
     * @memberof PolicyDto
     */
    ProductCrm: string;
    /**
     *
     * @type {string}
     * @memberof PolicyDto
     */
    BeginDate: string;
    /**
     *
     * @type {string}
     * @memberof PolicyDto
     */
    EndDate: string;
    /**
     *
     * @type {string}
     * @memberof PolicyDto
     */
    SignatureDate: string;
    /**
     *
     * @type {string}
     * @memberof PolicyDto
     */
    Spz: string;
    /**
     *
     * @type {string}
     * @memberof PolicyDto
     */
    Brand: string;
    /**
     *
     * @type {string}
     * @memberof PolicyDto
     */
    Model: string;
    /**
     *
     * @type {string}
     * @memberof PolicyDto
     */
    HomeAddress: string;
    /**
     *
     * @type {number}
     * @memberof PolicyDto
     */
    IdAgentContractAgent: number;
    /**
     *
     * @type {ContractAgentChangeRequestDto}
     * @memberof PolicyDto
     */
    ContractAgentChangeRequestDto: ContractAgentChangeRequestDto;
    /**
     *
     * @type {boolean}
     * @memberof PolicyDto
     */
    Active: boolean;
    /**
     *
     * @type {boolean}
     * @memberof PolicyDto
     */
    UniqaContract: boolean;
}
/**
 *
 * @export
 * @interface PushNotificationDto
 */
export interface PushNotificationDto {
    /**
     *
     * @type {string}
     * @memberof PushNotificationDto
     */
    AxaUUID: string;
    /**
     *
     * @type {string}
     * @memberof PushNotificationDto
     */
    Title: string;
    /**
     *
     * @type {string}
     * @memberof PushNotificationDto
     */
    Body: string;
    /**
     *
     * @type {string}
     * @memberof PushNotificationDto
     */
    Date: string;
}
/**
 *
 * @export
 * @interface PushNotificationSubscriptionDto
 */
export interface PushNotificationSubscriptionDto {
    /**
     *
     * @type {string}
     * @memberof PushNotificationSubscriptionDto
     */
    Endpoint: string;
    /**
     *
     * @type {string}
     * @memberof PushNotificationSubscriptionDto
     */
    ExpirationTime: string;
    /**
     *
     * @type {Keys}
     * @memberof PushNotificationSubscriptionDto
     */
    Keys: Keys;
}
/**
 *
 * @export
 * @interface RequestDto
 */
export interface RequestDto {
    /**
     *
     * @type {string}
     * @memberof RequestDto
     */
    RequestDateTime: string;
    /**
     *
     * @type {number}
     * @memberof RequestDto
     */
    IdRequest: number;
    /**
     *
     * @type {Array<string>}
     * @memberof RequestDto
     */
    RequestType: Array<string>;
    /**
     *
     * @type {string}
     * @memberof RequestDto
     */
    ContractNumber: string;
    /**
     *
     * @type {string}
     * @memberof RequestDto
     */
    RequestStatusCode: RequestDtoRequestStatusCodeEnum;
    /**
     *
     * @type {string}
     * @memberof RequestDto
     */
    RequestChangeAuthor: string;
}

/**
 * @export
 * @enum {string}
 */
export enum RequestDtoRequestStatusCodeEnum {
    New = "NEW",
    InProgress = "IN_PROGRESS",
    Confirmed = "CONFIRMED",
    Denied = "DENIED",
}
/**
 *
 * @export
 * @interface RequiredDocumentDto
 */
export interface RequiredDocumentDto {
    /**
     *
     * @type {RequiredDocumentTypeDto}
     * @memberof RequiredDocumentDto
     */
    DocumentType: RequiredDocumentTypeDto;
    /**
     *
     * @type {string}
     * @memberof RequiredDocumentDto
     */
    CounterParty: RequiredDocumentDtoCounterPartyEnum;
    /**
     *
     * @type {string}
     * @memberof RequiredDocumentDto
     */
    State: RequiredDocumentDtoStateEnum;
    /**
     *
     * @type {string}
     * @memberof RequiredDocumentDto
     */
    OtherDescription: string;
}

/**
 * @export
 * @enum {string}
 */
export enum RequiredDocumentDtoCounterPartyEnum {
    Aggrieved = "AGGRIEVED",
    Insured = "INSURED",
    Other = "OTHER",
    Insurant = "INSURANT",
}
/**
 * @export
 * @enum {string}
 */
export enum RequiredDocumentDtoStateEnum {
    Required = "REQUIRED",
    NotRequired = "NOT_REQUIRED",
    Completed = "COMPLETED",
    CompletedChecked = "COMPLETED_CHECKED",
}
/**
 *
 * @export
 * @interface RequiredDocumentTypeDto
 */
export interface RequiredDocumentTypeDto {
    /**
     *
     * @type {string}
     * @memberof RequiredDocumentTypeDto
     */
    Name: string;
    /**
     *
     * @type {string}
     * @memberof RequiredDocumentTypeDto
     */
    EcmDocumentType: string;
    /**
     *
     * @type {string}
     * @memberof RequiredDocumentTypeDto
     */
    EcmSubDocumentType: string;
    /**
     *
     * @type {string}
     * @memberof RequiredDocumentTypeDto
     */
    EcmIndividualText: string;
    /**
     *
     * @type {boolean}
     * @memberof RequiredDocumentTypeDto
     */
    EcmConvertToPdf: boolean;
}
/**
 *
 * @export
 * @interface ResetPasswordDataDto
 */
export interface ResetPasswordDataDto {
    /**
     *
     * @type {number}
     * @memberof ResetPasswordDataDto
     */
    IdSubject: number;
    /**
     *
     * @type {string}
     * @memberof ResetPasswordDataDto
     */
    Phone: string;
    /**
     *
     * @type {string}
     * @memberof ResetPasswordDataDto
     */
    AxaUuid: string;
}
/**
 *
 * @export
 * @interface RightToAccessAtrributesDto
 */
export interface RightToAccessAtrributesDto {
    /**
     *
     * @type {string}
     * @memberof RightToAccessAtrributesDto
     */
    AttributeName: string;
    /**
     *
     * @type {string}
     * @memberof RightToAccessAtrributesDto
     */
    AttributeValue: string;
}
/**
 *
 * @export
 * @interface RightToAccessDto
 */
export interface RightToAccessDto {
    /**
     *
     * @type {number}
     * @memberof RightToAccessDto
     */
    IdRole: number;
    /**
     *
     * @type {string}
     * @memberof RightToAccessDto
     */
    RoleType: string;
    /**
     *
     * @type {Array<RightToAccessAtrributesDto>}
     * @memberof RightToAccessDto
     */
    Attributes: Array<RightToAccessAtrributesDto>;
}
/**
 *
 * @export
 * @interface RightToAccessWithPolicyIdDto
 */
export interface RightToAccessWithPolicyIdDto {
    /**
     *
     * @type {number}
     * @memberof RightToAccessWithPolicyIdDto
     */
    PolicyId: number;
    /**
     *
     * @type {string}
     * @memberof RightToAccessWithPolicyIdDto
     */
    ContractNumber: string;
    /**
     *
     * @type {string}
     * @memberof RightToAccessWithPolicyIdDto
     */
    ProductGroup: string;
    /**
     *
     * @type {Array<RightToAccessDto>}
     * @memberof RightToAccessWithPolicyIdDto
     */
    RightToAccess: Array<RightToAccessDto>;
}
/**
 *
 * @export
 * @interface SocialNetworkDto
 */
export interface SocialNetworkDto {
    /**
     *
     * @type {string}
     * @memberof SocialNetworkDto
     */
    Facebook: string;
    /**
     *
     * @type {string}
     * @memberof SocialNetworkDto
     */
    LinkedIn: string;
}
/**
 *
 * @export
 * @interface StringValueDto
 */
export interface StringValueDto {
    /**
     *
     * @type {string}
     * @memberof StringValueDto
     */
    Value: string;
}
/**
 *
 * @export
 * @interface UpdateConsentDto
 */
export interface UpdateConsentDto {
    /**
     *
     * @type {Array<IdObjectDto>}
     * @memberof UpdateConsentDto
     */
    IdObjects: Array<IdObjectDto>;
    /**
     *
     * @type {string}
     * @memberof UpdateConsentDto
     */
    ConsentTypeCode: UpdateConsentDtoConsentTypeCodeEnum;
    /**
     *
     * @type {string}
     * @memberof UpdateConsentDto
     */
    ConsentVersionCode: string;
    /**
     *
     * @type {string}
     * @memberof UpdateConsentDto
     */
    ConsentAction: string;
}

/**
 * @export
 * @enum {string}
 */
export enum UpdateConsentDtoConsentTypeCodeEnum {
    Elkom = "ELKOM",
    Gdpr = "GDPR",
    Disclaimer = "DISCLAIMER",
    GdprHomeAdditionalServices = "GDPR_HOME_ADDITIONAL_SERVICES",
}
/**
 *
 * @export
 * @interface UpdateContractChangePropositionDto
 */
export interface UpdateContractChangePropositionDto {
    /**
     *
     * @type {string}
     * @memberof UpdateContractChangePropositionDto
     */
    Id: string;
    /**
     *
     * @type {string}
     * @memberof UpdateContractChangePropositionDto
     */
    Status: UpdateContractChangePropositionDtoStatusEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum UpdateContractChangePropositionDtoStatusEnum {
    AwaitingAcceptation = "AWAITING_ACCEPTATION",
    Accepted = "ACCEPTED",
    Declined = "DECLINED",
    Cancelled = "CANCELLED",
}
/**
 *
 * @export
 * @interface UsernameUpdateDto
 */
export interface UsernameUpdateDto {
    /**
     *
     * @type {string}
     * @memberof UsernameUpdateDto
     */
    Username: string;
    /**
     *
     * @type {string}
     * @memberof UsernameUpdateDto
     */
    Password: string;
}
/**
 *
 * @export
 * @interface ValidationErrorDto
 */
export interface ValidationErrorDto {
    /**
     *
     * @type {Array<string>}
     * @memberof ValidationErrorDto
     */
    Errors: Array<string>;
    /**
     *
     * @type {Array<FieldErrorDto>}
     * @memberof ValidationErrorDto
     */
    FieldErrors: Array<FieldErrorDto>;
    /**
     *
     * @type {string}
     * @memberof ValidationErrorDto
     */
    ErrorCode: string;
}
/**
 *
 * @export
 * @interface VerificationDto
 */
export interface VerificationDto {
    /**
     *
     * @type {Array<DocumentUploadDto>}
     * @memberof VerificationDto
     */
    Files: Array<DocumentUploadDto>;
    /**
     *
     * @type {string}
     * @memberof VerificationDto
     */
    Phone: string;
    /**
     *
     * @type {boolean}
     * @memberof VerificationDto
     */
    PersonalDataConsent: boolean;
}
