import { withTheme } from "@mui/styles";
import styled from "styled-components";
import { Flexbox } from "ui-library/layouts/Flexbox/Flexbox";

export const StyledLoggedUser = withTheme(styled(Flexbox)`
    .icon {
        transform: ${(props) => (props.$open ? "rotate(180deg)" : "rotate(0)")};
    }

    &:hover {
        cursor: pointer;
    }
`);
