import { withTheme } from "@mui/styles";
import styled from "styled-components";
import { Box } from "ui-library/layouts/Box/Box";

export const StyledDropzoneContainer = withTheme(styled(Box)`
    .dropzone {
        width: 100%;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='gray' stroke-width='1' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        padding: 24px;
        display: flex;
        justify-content: center;
        &:hover {
            cursor: pointer;
        }
    }
`);
