import { createSelector } from "reselect";
import { Map, List } from "immutable";

import entity from "core/entity";
import userInfo from "core/userInfo";
import { app } from "core/util";
import { oneLineNameWithTitles } from "core/formatters";
import { gdprData } from "core/userInfo/actions";

import { ENTITY_CONTRACTS_GDPR_DATA, NAME, PAGE_STATE } from "./constants";

export const getModel = app.createGetModel(NAME);
export const getGdprPageState = (state) => getModel(state).get("gdprPageState", PAGE_STATE.INITIAL);

// Entity
const getContractsGdprData = (state) => entity.getDataSelector(state, ENTITY_CONTRACTS_GDPR_DATA, new List());

// App state
const getAppState = (state) => getModel(state).get("appState");
export const getSelectedContractGdprData = (state) => getAppState(state).get("selectedContractGdprData");

// Custom selectors.
export const getFormattedUsername = createSelector(userInfo.getClient, (client) => (client ? oneLineNameWithTitles(client) : ""));

export const getContractOptions = createSelector(getContractsGdprData, (contractsGdprData) =>
    contractsGdprData.map((gdprData) =>
        Map({
            value: gdprData.objectId,
            label: gdprData.productGroup ? `${gdprData.contractNumber} - ${gdprData.productGroup}` : gdprData.contractNumber,
            contractNumber: gdprData.contractNumber,
            contractName: gdprData.productGroup,
        }),
    ),
);

export const getContractGdprDataSections = (idObject) =>
    createSelector(getContractsGdprData, (contractsGdprData) => contractsGdprData.findLast((gdprData) => gdprData.objectId === idObject));
