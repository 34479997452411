import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { GridLayout } from "ui-library/layouts";

import router from "core/router";
import fetching from "core/fetching";
import userContracts from "core/userContracts";
import { ClaimReportCategory, IndividualPersonType } from "enums";
import { Pages, Tabs } from "routeConstants";

import {
    AgentOverviewGridItem,
    ClaimsOverviewGridItem,
    ContractDocumentsOverviewGridItem,
    HolderOverviewGridItemLegacy,
    NonLifeContractOverviewGridItem,
    NonLifeFinancialOperationsOverviewGridItem,
} from "containers/contract";
import { ENTITY_CONTRACT_DOCUMENTS, ENTITY_CONTRACT_OVERVIEW, ENTITY_FINANCIAL_OPERATIONS, ENTITY_PAYMENT_INFORMATION } from "../constants";
import {
    createGetCompliantLineOfBusinessClasses,
    getActualPaymentScheduler,
    getAdditionalCoverages,
    getClaims,
    getPolicyHolder,
} from "../selectors";

export const OverviewTab = () => {
    const { idObject } = useParams();
    const contract = userContracts.useGetContractById(idObject);
    const { isInFutureOrTerminated } = userContracts.useGetContractDerivedData(contract);
    const actualScheduler = useSelector(getActualPaymentScheduler);
    const additionalCoverages = useSelector(getAdditionalCoverages);
    const holder = useSelector(getPolicyHolder);
    const claims = useSelector(getClaims);
    const isContractOverviewLoading = useSelector(fetching.isFetching(ENTITY_CONTRACT_OVERVIEW, false));
    const compliantLineOfBusinessClasses = useSelector(createGetCompliantLineOfBusinessClasses(idObject));

    return (
        <GridLayout defaultSpacing>
            <NonLifeFinancialOperationsOverviewGridItem
                idObject={Number(idObject)}
                financialOperationsEntityName={ENTITY_FINANCIAL_OPERATIONS}
                paymentDataEntityName={ENTITY_PAYMENT_INFORMATION}
                detailLink={router.getStaticUrl(Pages.CONTRACT_VEHICLE, Tabs.FINANCIAL_OPERATIONS, { idObject })}
                getIsContractEditable={contract.canEdit}
                hasQuickPayment={!isInFutureOrTerminated}
            />

            <NonLifeContractOverviewGridItem
                policy={contract}
                additionalCoverages={additionalCoverages}
                actualScheduler={actualScheduler}
                detailLink={router.getStaticUrl(Pages.CONTRACT_VEHICLE, Tabs.DETAIL, { idObject })}
            />

            <HolderOverviewGridItemLegacy
                holder={holder}
                individualPersonType={IndividualPersonType.CONTRACT_HOLDER}
                detailLink={router.getStaticUrl(Pages.CONTRACT_VEHICLE, Tabs.HOLDER, { idObject })}
            />

            <ContractDocumentsOverviewGridItem
                idObject={idObject}
                entityName={ENTITY_CONTRACT_DOCUMENTS}
                detailLink={router.getStaticUrl(Pages.CONTRACT_VEHICLE, Tabs.DOCUMENTS, { idObject })}
            />

            <ClaimsOverviewGridItem
                detailLink={router.getStaticUrl(Pages.CONTRACT_VEHICLE, Tabs.CLAIMS, { idObject })}
                claims={claims}
                idObject={idObject}
                categoryParam={ClaimReportCategory.VEHICLE.id}
                compliantLineOfBusinessClasses={compliantLineOfBusinessClasses}
                isContractOverviewLoading={isContractOverviewLoading}
            />

            <AgentOverviewGridItem idObject={idObject} detailLink={router.getStaticUrl(Pages.CONTRACT_VEHICLE, Tabs.AGENT, { idObject })} />
        </GridLayout>
    );
};
