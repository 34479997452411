import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { Block, Typography } from "ui-library/atoms";
import { LabelTextItem } from "ui-library/modules";
import { ContentBox, GridLayout } from "ui-library/layouts";

import i18n from "core/i18n";
import { NonLifeCoverageItem } from "containers/contract";
import { localAmountWithCurrencyLegacy } from "core/formatters";
import { date, fn } from "core/util";
import { InsuredRealEstateProperty } from "model/harmonized";

import { getNumberOfInsuredVehicles } from "../selectors";

import { VehicleGridItem } from "./VehicleGridItem";
import { VehicleOwnerOperatorGridItem } from "./VehicleOwnerOperatorGridItem";

const { EntityType } = VehicleOwnerOperatorGridItem;

const insuranceAmountLimit = ["AUTOSKP007", "AUTOCZP007", "HOMECZP002", "HOMESKP002", "HOMECZP004", "HOMESKP004"];

const addNonEmpty = (label, value) => !fn.isEmpty(value) && <LabelTextItem label={label} text={value} />;

export const VehicleInsuranceCoverage = ({ isOnlyOneRisk, productPackage }) => {
    const { t } = i18n.useTranslation();
    const validity = productPackage?.validity;
    const getAmountLabelText = insuranceAmountLimit.includes(productPackage.riskCode)
        ? t("insurance.insuranceAmountLimit")
        : t("insurance.insuranceAmount");

    const numberOfInsuredVehicles = useSelector(getNumberOfInsuredVehicles);
    const onlyOneVehicle = numberOfInsuredVehicles === 1;

    return (
        <Block variant="grayWhite">
            {productPackage.coverageText && (
                <ContentBox>
                    <Typography variant="h3" component="h3">
                        {productPackage.coverageText}
                    </Typography>
                </ContentBox>
            )}

            {isOnlyOneRisk && (
                <ContentBox>
                    <Typography variant="p">{`${productPackage.licencePlateNumber} - ${productPackage.category?.value}`}</Typography>
                </ContentBox>
            )}

            <ContentBox>
                {addNonEmpty(t("insurance.insuranceCost"), localAmountWithCurrencyLegacy(productPackage.insuranceCost))}
                {addNonEmpty(getAmountLabelText, localAmountWithCurrencyLegacy(productPackage.insuranceAmount))}
                {addNonEmpty(t("insurance.insuranceTo"), date.formatToDateWithUnlimited(validity?.dateValidTo, t("common.unlimitedDate")))}
            </ContentBox>

            {productPackage.coverages.map((coverage) => (
                <NonLifeCoverageItem key={coverage.reactKey} coverage={coverage} />
            ))}

            {!onlyOneVehicle && (
                <GridLayout defaultSpacing>
                    <VehicleGridItem insuredVehicle={productPackage} />
                    {productPackage.vehicleOwner && (
                        <VehicleOwnerOperatorGridItem entityType={EntityType.VEHICLE_OWNER} entity={productPackage.vehicleOwner} />
                    )}
                    {productPackage.vehicleOperator && (
                        <VehicleOwnerOperatorGridItem entityType={EntityType.VEHICLE_OPERATOR} entity={productPackage.vehicleOperator} />
                    )}
                </GridLayout>
            )}
        </Block>
    );
};

VehicleInsuranceCoverage.propTypes = {
    productPackage: PropTypes.object.isRequired,
    isOnlyOneRisk: PropTypes.bool.isRequired,
};
