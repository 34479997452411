import React, { FC } from "react";

import { Box } from "ui-library/layouts";
import { StandaloneLink } from "ui-library/atoms";

import i18n from "core/i18n";
import router from "core/router";
import { Pages, Tabs } from "routeConstants";

const getSellSwitchFormUrl = (mainPageConst: Pages, idObject: number, requestId: string, actionType: string) =>
    router.getStaticUrl(mainPageConst, Tabs.SELL_SWITCH_FORM_WITH_ACTION, { idObject, requestId, actionType });

export interface CopyCancelActionsFormatterProps {
    mainPageConst: Pages;
    idObject: number;
    requestId: string;
    isCancelEnabled: boolean;
}

export const CopyCancelActionsFormatter: FC<CopyCancelActionsFormatterProps> = ({
    mainPageConst,
    idObject,
    requestId,
    isCancelEnabled,
}) => {
    const { t } = i18n.useTranslation();
    return (
        <span>
            <Box>
                <StandaloneLink color="blue" to={getSellSwitchFormUrl(mainPageConst, idObject, requestId, "copy")}>
                    {t("investments.copy")}
                </StandaloneLink>
            </Box>
            {isCancelEnabled && (
                <Box>
                    <StandaloneLink color="blue" to={getSellSwitchFormUrl(mainPageConst, idObject, requestId, "cancel")}>
                        {t("common.cancel")}
                    </StandaloneLink>
                </Box>
            )}
        </span>
    );
};
