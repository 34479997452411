import { Record } from "immutable";

class MutualFundsTransactionStatus extends Record({ id: null, msg: null, groupedValue: null }) {}

// you always need edit both enums MutualFundsTransactionStatus / MutualFundsTransactionStatusDropdown
export default Object.freeze({
    NE: new MutualFundsTransactionStatus({
        id: "NE",
        msg: "investments.transactionStatusReady",
        groupedValue: "READY",
    }),
    RE: new MutualFundsTransactionStatus({
        id: "RE",
        msg: "investments.transactionStatusReady",
        groupedValue: "READY",
    }),
    PR: new MutualFundsTransactionStatus({
        id: "PR",
        msg: "investments.transactionStatusProcessed",
        groupedValue: "PROCESSED",
    }),
    EX: new MutualFundsTransactionStatus({
        id: "EX",
        msg: "investments.transactionStatusProcessed",
        groupedValue: "PROCESSED",
    }),
    SE: new MutualFundsTransactionStatus({
        id: "SE",
        msg: "investments.transactionStatusCompleted",
        groupedValue: "COMPLETED",
    }),
    CL: new MutualFundsTransactionStatus({
        id: "CL",
        msg: "investments.transactionStatusCompleted",
        groupedValue: "COMPLETED",
    }),
    RG: new MutualFundsTransactionStatus({
        id: "RG",
        msg: "investments.transactionStatusCanceled",
        groupedValue: "CANCELED",
    }),
    CA: new MutualFundsTransactionStatus({
        id: "CA",
        msg: "investments.transactionStatusCanceled",
        groupedValue: "CANCELED",
    }),
    RD: new MutualFundsTransactionStatus({
        id: "RD",
        msg: "investments.transactionStatusCanceled",
        groupedValue: "CANCELED",
    }),
    CI: new MutualFundsTransactionStatus({
        id: "CI",
        msg: "investments.transactionStatusReady",
        groupedValue: "READY",
    }),
    CM: new MutualFundsTransactionStatus({
        id: "CM",
        msg: "investments.transactionStatusCanceled",
        groupedValue: "CANCELED",
    }),
    A: new MutualFundsTransactionStatus({
        id: "A",
        msg: "investments.transactionStatusActive",
        groupedValue: "ACTIVE",
    }),
    C: new MutualFundsTransactionStatus({
        id: "C",
        msg: "investments.transactionStatusEnded",
        groupedValue: "ENDED",
    }),
    D: new MutualFundsTransactionStatus({
        id: "D",
        msg: "investments.transactionStatusEnded",
        groupedValue: "ENDED",
    }),
    N: new MutualFundsTransactionStatus({
        id: "N",
        msg: "investments.transactionStatusActive",
        groupedValue: "ACTIVE",
    }),
    P: new MutualFundsTransactionStatus({
        id: "P",
        msg: "investments.transactionStatusActive",
        groupedValue: "ACTIVE",
    }),
    S: new MutualFundsTransactionStatus({
        id: "S",
        msg: "investments.transactionStatusEnded",
        groupedValue: "ENDED",
    }),
    T: new MutualFundsTransactionStatus({
        id: "T",
        msg: "investments.transactionStatusEnded",
        groupedValue: "ENDED",
    }),
    U: new MutualFundsTransactionStatus({
        id: "U",
        msg: "investments.transactionStatusEnded",
        groupedValue: "ENDED",
    }),
});
