import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";

import { Button, Loading, Typography } from "ui-library/atoms";

import i18n from "core/i18n";
import { form } from "core/form";

import { AvailableBanksAndCards } from "./AvailableBanksAndCards";
import { getAmountType } from "../selectors";
import { CREATE_PAYMENT_LINK_FORM } from "../constants";

const OnlinePayment = ({ handleSubmit, submitting }) => {
    const { t } = i18n.useTranslation();
    const amountType = useSelector(getAmountType);
    return (
        <Box pt={6} pb={2} px={2}>
            <AvailableBanksAndCards />
            <Box pt={4}>
                <Typography variant="p" component="p">
                    {t("payments.meansOnline.text")}
                </Typography>
            </Box>
            <Box pt={4}>
                <Loading loading={submitting}>
                    <Button color="green" variant="contained" startIcon="link" disabled={!amountType} onClick={handleSubmit}>
                        {t("common.payCaps")}
                    </Button>
                </Loading>
            </Box>
        </Box>
    );
};

export default form(CREATE_PAYMENT_LINK_FORM)(OnlinePayment);
