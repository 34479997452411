import { Record } from "immutable";

class PaymentContribution extends Record({ id: null, msg: null }) {}

export default Object.freeze({
    ByPostalOrder: new PaymentContribution({ id: "ByPostalOrder", msg: "payments.ByPostalOrder" }),
    ByEmployment: new PaymentContribution({ id: "ByEmployment", msg: "payments.ByEmployment" }),
    ByBankAccount: new PaymentContribution({ id: "ByBankAccount", msg: "payments.ByBankAccount" }),
    ByDirectDebit: new PaymentContribution({ id: "ByDirectDebit", msg: "payments.ByDirectDebit" }),
    BySipo: new PaymentContribution({ id: "BySipo", msg: "payments.BySipo" }),
    ByCash: new PaymentContribution({ id: "ByCash", msg: "payments.ByCash" }),
});
