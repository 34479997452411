import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { PopoverTooltip } from "ui-library/atoms";
import { GridItem } from "ui-library/layouts";
import { DataTable } from "ui-library/modules";

import { DEFAULT_TABLE_PAGE_SIZE, DEFAULT_TABLE_ROWS_OPTIONS } from "app/constants";
import entity from "core/entity";
import fetching from "core/fetching";
import {
    localAmountInvestmentWithCurrencyEurLegacy,
    localAmountNoDecimal,
    localAmountWithCurrencyLegacy,
    localFullDate,
    negativeFormatter,
} from "core/formatters";
import i18n from "core/i18n";
import { amountWithOperationTypeOperators } from "modules/tableFilter";

import { FinancialOperationPrintButton } from "./FinancialOperationPrintButton";

import { ENTITY_FINANCIAL_OPERATIONS, FUND_COLUMN, NETTO_INVESTED_AMOUNT_COLUMN, TYPE_COLUMN, VALUE_DATE_COLUMN } from "../constants";
import { FinancialOperationsTableFilter } from "../modules";
import { getFinancialOperations } from "../selectors";

const columns = (t, idObject) => [
    {
        field: VALUE_DATE_COLUMN,
        headerName: t("common.date"),
        type: "date",
        minWidth: 80,
        renderCell: (params) => localFullDate(params.value),
    },
    {
        field: FUND_COLUMN,
        headerName: t("common.fund"),
        minWidth: 160,
        sortable: false,
    },
    {
        field: TYPE_COLUMN,
        headerName: t("common.actionType"),
        minWidth: 35,
        sortable: false,
        align: "right",
        headerAlign: "right",
    },
    {
        headerName: t("investments.numberOfShares"),
        field: "numberOfShares",
        renderCell: (params) => localAmountNoDecimal(params.value),
        minWidth: 130,
        sortable: false,
        align: "right",
        headerAlign: "right",
    },
    {
        headerName: t("investments.shareValueAtOperationDateTime"),
        field: "pricePerShareAtOperationDateTime",
        renderCell: (params) => localAmountInvestmentWithCurrencyEurLegacy(params.value),
        minWidth: 130,
        sortable: false,
        align: "right",
        headerAlign: "right",
    },
    {
        headerName: t("investments.grossInvestedAmount"),
        field: "investedAmount",
        renderCell: (params) => negativeFormatter(params.value),
        minWidth: 130,
        sortable: false,
        align: "right",
        headerAlign: "right",
    },
    {
        headerName: t("common.fee"),
        field: "fee",
        renderCell: (params) => localAmountWithCurrencyLegacy(params.value),
        minWidth: 60,
        sortable: false,
        align: "right",
        headerAlign: "right",
    },
    {
        headerName: (
            <>
                {t("investments.investmentBuyoutAmount")}{" "}
                <PopoverTooltip size={14} variant="info" title={t("investments.nettoInvestedAmount.mutualFundsDirect.footnote")} />
            </>
        ),
        field: NETTO_INVESTED_AMOUNT_COLUMN,
        filterOperators: amountWithOperationTypeOperators,
        renderCell: (params) => negativeFormatter(params.value),
        minWidth: 160,
        sortable: false,
        align: "right",
        headerAlign: "right",
    },
    {
        headerName: t("common.print"),
        field: "id",
        sortable: false,
        renderCell: (params) => <FinancialOperationPrintButton idObject={idObject} idOperation={params.value} />,
        align: "center",
        headerAlign: "center",
    },
];

export const MovementsOverviewGridItem = () => {
    const { t } = i18n.useTranslation();
    const { idObject } = useParams();
    const financialOperations = useSelector(getFinancialOperations);
    const isFinOperationsLoading = useSelector(fetching.isFetching(ENTITY_FINANCIAL_OPERATIONS, true));
    const isFinOperationsError = useSelector(entity.isError(ENTITY_FINANCIAL_OPERATIONS));
    const filterModel = useSelector(FinancialOperationsTableFilter.getFilterModel);

    return (
        <GridItem xs={12}>
            <DataTable
                icon="chart-bar"
                title={t("general.statements.operationOverview")}
                headerHeight={62}
                actions={<FinancialOperationsTableFilter.Container />}
                disableColumnReorder
                pageSize={DEFAULT_TABLE_PAGE_SIZE}
                rowsPerPageOptions={DEFAULT_TABLE_ROWS_OPTIONS}
                getRowId={(row) => row.id}
                rows={financialOperations.toArray()}
                columns={columns(t, idObject)}
                emptyMsg={t("common.noData")}
                errorMsg={t("error.sectionUnavailable")}
                isLoading={isFinOperationsLoading}
                isError={isFinOperationsError}
                filterModel={filterModel}
            />
        </GridItem>
    );
};
