import { withTheme } from "@mui/styles";
import styled from "styled-components";

import { Flexbox } from "ui-library/layouts/Flexbox/Flexbox";

export const StyledFileInfo = withTheme(styled(Flexbox)`
    padding: ${(props) => (props.$tableVariant ? "16px 16px 16px 0" : "12px")};
    border-bottom: 1px solid ${(props) => props.theme.palette.gray.line};
    &:last-child {
        border-bottom: none;
    }
    &:hover {
        cursor: ${(props) => (props.$disabled ? "auto" : "pointer")};
        background: ${(props) => props.theme.palette.blue.soft};
    }
`);
