import React from "react";

import newDssSettingsTable from "assets/dss/new_dss_settings.PNG";
import { Image, PopoverTooltip } from "ui-library/atoms";
import { Box } from "ui-library/layouts";

export const DssNewInvestmentAllocationLimits = () => (
    <PopoverTooltip
        content={
            <Box sx={{ width: 630 }}>
                <Image alt={"table"} src={newDssSettingsTable} shadow={false} />
            </Box>
        }
    />
);
