import React from "react";
import PropTypes from "prop-types";

import { localPhone, oneLineNameWithTitles } from "core/formatters";
import i18n from "core/i18n";
import { Box, GridItem } from "ui-library/layouts";
import { InfoPanel, LabelTextItem } from "ui-library/modules";
import { StandaloneLink } from "ui-library/atoms";
import { IndividualPersonType } from "enums";

const getPersonNameLine = (t, holder) => <LabelTextItem label={t("holder.fullName")} text={oneLineNameWithTitles(holder)} />;
const getCompanyNameLine = (t, holder) => <LabelTextItem label={t("holder.companyName")} text={holder.companyName} />;

export const HolderOverviewGridItemLegacy = ({ holder, individualPersonType, detailLink }) => {
    const { t } = i18n.useTranslation();

    return (
        <GridItem display={"flex"} md={5} xs={12}>
            <InfoPanel
                icon={"user"}
                title={t(individualPersonType.msg)}
                headerAction={
                    <StandaloneLink to={detailLink} color="blue">
                        {t("common.detail")}
                    </StandaloneLink>
                }
            >
                <Box>
                    {holder.isPerson ? getPersonNameLine(t, holder) : getCompanyNameLine(t, holder)}
                    <LabelTextItem label={t("holder.phone")} text={localPhone(holder.get("phone"))} />
                    <LabelTextItem label={t("holder.email")} text={holder.get("email")} />
                </Box>
            </InfoPanel>
        </GridItem>
    );
};

HolderOverviewGridItemLegacy.propTypes = {
    holder: PropTypes.object.isRequired,
    individualPersonType: PropTypes.oneOf(Object.values(IndividualPersonType)).isRequired,
    detailLink: PropTypes.string.isRequired,
};
