export default (actionPrefix) => {
    const SET_EPIN_PHASE = `${actionPrefix}/SET_EPIN_PHASE`;
    const SET_EPIN_ISSUE_ERROR = `${actionPrefix}/SET_EPIN_ISSUE_ERROR`;
    const SET_EPIN_PASSWORD_ERROR = `${actionPrefix}/SET_EPIN_PASSWORD_ERROR`;
    const SET_EPIN_VERIFICATION_ERROR = `${actionPrefix}/SET_EPIN_VERIFICATION_ERROR`;
    const SET_DUPLICATE_CONTRACT_NUMBER = `${actionPrefix}/SET_DUPLICATE_CONTRACT_NUMBER`;
    const SET_RESEND_PIN = `${actionPrefix}/SET_RESEND_PIN`;

    return {
        SET_EPIN_PHASE,
        setEpinPhase: (phase, data) => ({
            type: SET_EPIN_PHASE,
            meta: { phase },
            payload: data,
        }),

        SET_EPIN_ISSUE_ERROR,
        setEpinIssueError: (error) => ({
            type: SET_EPIN_ISSUE_ERROR,
            payload: error,
        }),
        cleanEpinIssueError: () => ({
            type: SET_EPIN_ISSUE_ERROR,
            payload: null,
        }),

        SET_EPIN_PASSWORD_ERROR,
        setEpinPasswordError: (error) => ({
            type: SET_EPIN_PASSWORD_ERROR,
            payload: error,
        }),

        SET_EPIN_VERIFICATION_ERROR,
        setEpinVerificationError: (error) => ({
            type: SET_EPIN_VERIFICATION_ERROR,
            payload: error,
        }),

        SET_DUPLICATE_CONTRACT_NUMBER,
        setDuplicateContractNumber: (contractNumber) => ({
            type: SET_DUPLICATE_CONTRACT_NUMBER,
            payload: contractNumber,
        }),

        SET_RESEND_PIN,
        setResendPin: () => ({
            type: SET_RESEND_PIN,
        }),
    };
};
