import React, { Fragment } from "react";

export const MultilineContent = ({ value }) => (
    <>
        {value.map((line) => (
            <Fragment key={line}>
                {line}
                <br />
            </Fragment>
        ))}
    </>
);
