import React from "react";
import { useSelector } from "react-redux";

import { GridItem } from "ui-library/layouts";
import { InfoPanel, Timeline } from "ui-library/modules";

import i18n from "core/i18n";
import { dateTimeToLocalFullDate } from "core/formatters";

import { getClaimData, getIncidentDetail } from "../selectors";
import { getClaimStateObj } from "../util";

import { WriteClaimOrOrderNoteButton } from "./button";

const timeLineStatusItems = (t, registrationDate) => [
    {
        title: t("claim.claimState.registration"),
        date: dateTimeToLocalFullDate(registrationDate),
    },
    {
        title: t("claim.claimState.check"),
        content: t("claim.claimState.check.description"),
    },
    {
        title: t("claim.claimState.documentationProcessing"),
        content: t("claim.claimState.documentationProcessing.description"),
    },
    {
        title: t("claim.claimState.liquidation"),
        content: t("claim.claimState.liquidation.description"),
    },
    {
        title: t("claim.claimState.closed"),
        content: t("claim.claimState.closed.description"),
    },
];

export const ClaimStateTimeLine = () => {
    const { t } = i18n.useTranslation();
    const incidentDetail = useSelector(getIncidentDetail);
    const claimDetail = useSelector(getClaimData);
    const claimState = getClaimStateObj(claimDetail.get("claimState"));

    const canWriteNote = incidentDetail?.canWriteNote;

    return (
        <GridItem display={"flex"} lg={5} xs={12}>
            <InfoPanel
                actionStretch={true}
                icon="eye"
                title={t("claim.state")}
                actionButton={canWriteNote && <WriteClaimOrOrderNoteButton type={WriteClaimOrOrderNoteButton.Type.CLAIM} />}
            >
                <Timeline data={timeLineStatusItems(t, claimDetail.get("reportDate"))} activeStep={claimState.step} />
            </InfoPanel>
        </GridItem>
    );
};
