import createModel from "../createModel";

export const GreenCardHeader = createModel("GreenCardHeader", (json) => ({
    contractNumber: json.ContractNumber,
    vehicle: json.Vehicle,
    licencePlateNumber: json.LicencePlateNumber,
    vinNumber: json.VinNumber,
    validFrom: json.ValidFrom,
    validTo: json.ValidTo,
}));
