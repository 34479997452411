import React, { FC, PropsWithChildren } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import router from "core/router";
import { PageBoundary } from "containers/PageBoundary";
import { FULL_PAGE_FORMS } from "routeConstants";
import { ProductPageLayout, StaticPageLayout } from "ui-library/layouts";

export const ProductDetailPageLayout: FC<PropsWithChildren> = ({ children }) => {
    const { innerName } = useSelector(router.getPageInfo);

    if (FULL_PAGE_FORMS.includes(innerName)) {
        return (
            <StaticPageLayout>
                <PageBoundary>
                    <Outlet />
                </PageBoundary>
            </StaticPageLayout>
        );
    }

    return <ProductPageLayout>{children}</ProductPageLayout>;
};
