import { combineReducers as immutableCombineReducers } from "redux-immutable";
import { combineReducers, createReducer } from "@reduxjs/toolkit";

import { ElectronicCommunicationCampaignApiResponse as ElectronicCommunicationCampaignApiResponseModel } from "model/campaign";
import { ElectronicCommunicationCampaignApiResponse } from "types";

import { clear, setElectronicCommunicationCampaignData } from "./actions";

const electronicCommunicationCampaignData = createReducer<ElectronicCommunicationCampaignApiResponse>(
    ElectronicCommunicationCampaignApiResponseModel.fromServer(),
    (builder) => {
        builder.addCase(setElectronicCommunicationCampaignData, (_, { payload }) => payload);
        builder.addCase(clear, () => ElectronicCommunicationCampaignApiResponseModel.fromServer());
    },
);

export default immutableCombineReducers({
    data: combineReducers({
        electronicCommunicationCampaignData,
    }),
});
