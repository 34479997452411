import { combineReducers } from "redux-immutable";

import { app } from "core/util";
import { TravelInsurance } from "model/travel";

import { CLEAR, SET_DETAIL_SELECTED_INSURED, SET_STATEMENT_TAB, SET_TRAVEL_CONTRACT_DETAIL } from "./actions";

export default combineReducers({
    data: combineReducers({
        travelContractDetail: app.createDataReducer(SET_TRAVEL_CONTRACT_DETAIL, TravelInsurance.fromServer(), CLEAR),
    }),
    appState: combineReducers({
        detailSelectedInsured: app.createDataReducer(SET_DETAIL_SELECTED_INSURED, "", CLEAR),
        statementTab: app.createDataReducer(SET_STATEMENT_TAB, 0, CLEAR),
    }),
});
