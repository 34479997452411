import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { StandaloneLink } from "ui-library/atoms";
import { Dropdown, DropdownItem } from "ui-library/modules";

import i18n from "core/i18n";
import modal from "core/modal";

export const ContributionsEditDropdown = ({ modalContractOwnerPaymentsFormName }) => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const isDropdownOpen = Boolean(anchorEl);

    const openDropdown = (event) => setAnchorEl(event.currentTarget);
    const closeDropdown = () => setAnchorEl(null);

    const openModalForm = (formName) => {
        closeDropdown();
        dispatch(modal.openModalForm(formName));
    };

    return (
        <>
            <StandaloneLink color="blue" onClick={openDropdown}>
                {t("common.edit")}
            </StandaloneLink>

            <Dropdown position={"left"} anchorEl={anchorEl} open={isDropdownOpen} onClose={closeDropdown}>
                <DropdownItem onClick={() => openModalForm(modalContractOwnerPaymentsFormName)}>
                    {t("form.policyHolderPayments")}
                </DropdownItem>
            </Dropdown>
        </>
    );
};

ContributionsEditDropdown.propTypes = {
    modalContractOwnerPaymentsFormName: PropTypes.string.isRequired,
};
