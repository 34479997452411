import { fetch } from "core/util";
import { API_PREFIX } from "app/constants";
import { Agent } from "model/agent";

const URL_PREFIX = `${API_PREFIX}/agent`;

const getAllAgents = () => fetch.doGet(`${URL_PREFIX}`, null, Agent.fromServerList);

export default {
    getAllAgents,
};
