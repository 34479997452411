import { List } from "immutable";
import createModel from "../createModel";

export const Claim = createModel("Claim", (json) => ({
    isUniqaClaim: json.IsUniqaClaim,
    incidentId: json.IncidentId,
    lineOfBusinesses: List(json.LineOfBusinesses),
    incidentCause: json.IncidentCause,
    insuranceRisk: json.InsuranceRisk,
    policyNumber: json.PolicyNumber,
    claimNumber: json.ClaimNumber,
    claimType: json.ClaimType,
    claimSubType: json.ClaimSubType,
    claimStatus: json.ClaimStatus,
    categoryClaim: json.CategoryClaim,
    insuredFirstName: json.InsuredFirstName,
    insuredLastName: json.InsuredLastName,
    lastStateChange: json.LastStateChange,
    eventDate: json.EventDate,
    registrationDate: json.RegistrationDate,
    paid: json.Paid,
    isClaimantOwnerContract: json.IsClaimantOwnerContract,
    externalNo: json.ExternalNo,
}));
