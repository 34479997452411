import { model } from "core/util";

import { AmountLegacy, CodeValueLegacy } from "../common";

export const MutualFundsActualValueOfInvestmentsBasedOnFund = model.createModelLegacy(
    "MutualFundsActualValueOfInvestmentsBasedOnFund",
    (json) => ({
        fund: CodeValueLegacy.fromServer(json.Fund),
        investedAmount: AmountLegacy.fromServer(json.InvestedAmount),
        nettoInvestedAmount: AmountLegacy.fromServer(json.NettoInvestedAmount),
        entryFee: AmountLegacy.fromServer(json.EntryFee),
        outputFee: AmountLegacy.fromServer(json.OutputFee),
        roundingFee: AmountLegacy.fromServer(json.RoundingFee),
        numberOfShares: json.NumberOfShares,
        actualShareValue: AmountLegacy.fromServer(json.ActualShareValue),
        actualValueOfInvestments: AmountLegacy.fromServer(json.ActualValueOfInvestments),
        profit: json.Profit,
        nettoProfit: json.NettoProfit,
    }),
);
