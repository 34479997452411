import { model } from "core/util";

import { HolderLegacy } from "../holder";

export const Beneficiary = model.createModelLegacy("Beneficiary", (json) => ({
    percentage: json.Percentage,
    riskNumber: json.RiskNumber,
    insuredPerson: HolderLegacy.fromServer(json.InsuredPerson),
    beneficiary: HolderLegacy.fromServer(json.Beneficiary),
    description: json.Description,
}));
