import { List } from "immutable";

import { fn } from "core/util";

import { getLocalizedCountryCode } from "./getLocalizedCountryCode";

export const getLocalizedAddressAsList = (t, address, addEmptySpace = false) => {
    const result = [];

    if (address) {
        // Street line
        let streetLine = "";
        if (address.street) {
            streetLine += address.street;
        }

        if (address.descriptionNumber) {
            if (streetLine.length > 0) {
                streetLine += " ";
            }
            streetLine += address.descriptionNumber;
        }

        if (address.orientationNumber) {
            if (address.descriptionNumber) {
                streetLine += "/";
            } else if (streetLine.length > 0) {
                streetLine += " ";
            }
            streetLine += address.orientationNumber;
        }
        if (!fn.isEmpty(streetLine)) {
            result.push(streetLine);
        }

        // City line
        let cityLine = "";
        if (address.city) {
            cityLine += address.city;
        }

        if (address.postalCode) {
            if (cityLine.length > 0) {
                cityLine += ", ";
            }
            cityLine += address.postalCode;
        }
        if (!fn.isEmpty(cityLine)) {
            result.push(cityLine);
        }

        // Country line
        if (address.countryCode) {
            const localizedCountry = getLocalizedCountryCode(t, address.countryCode);
            if (!fn.isEmpty(localizedCountry)) {
                result.push(localizedCountry);
            }
        }

        // Empty space
        if (addEmptySpace) {
            result.push(" ");
        }
    }

    return List(result);
};
