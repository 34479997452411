import React from "react";
import { useSelector } from "react-redux";

import entity from "core/entity";
import fetching from "core/fetching";
import i18n from "core/i18n";
import { dateTimeToLocalFullDate, formatFinancialStatementAmount } from "core/formatters";
import { PensionPpCategoryEnum as CategoryEnum } from "enums";
import { Icon, Typography } from "ui-library/atoms";
import { DataTable } from "ui-library/modules";
import { GridItem } from "ui-library/layouts";

import { ENTITY_FINANCIAL_STATEMENT } from "../constants";
import { getContractBalanceSheet, getFinancialStatement } from "../selectors";

const columns = (t) => [
    {
        field: "name",
        headerName: t("common.actionType"),
        flex: 2,
        minWidth: 250,
        sortable: false,
    },
    {
        field: "category",
        headerName: t("common.category"),
        flex: 1,
        minWidth: 150,
        sortable: false,
        renderCell: (params) => (params.value ? formatCategoryRow(t, params.value) : ""),
    },
    {
        field: "amount",
        headerName: t("common.amount"),
        flex: 1,
        minWidth: 120,
        sortable: false,
        align: "right",
        headerAlign: "right",
        renderCell: (params) => formatFinancialStatementAmount(t, params.value, true),
    },
];

const prepareAmountRow = (name, category, amount) => ({
    name,
    category,
    amount,
});

const prepareSummaryRow = (t, desc, amount, textType) => ({
    desc,
    total: formatFinancialStatementAmount(t, amount, true),
    textType,
});

const formatCategoryRow = (t, category) => (
    <>
        <Icon color={category.color} size={16} icon="circle" />
        <Typography>&nbsp;{t(category.key)}</Typography>
    </>
);

const getData = (t, contractBalanceSheet) => [
    prepareAmountRow(t("account.holderPayments"), CategoryEnum.RECEIVED, contractBalanceSheet.get("holderPaymentsTotal")),
    prepareAmountRow(t("account.thirdPartyPayments"), CategoryEnum.RECEIVED, contractBalanceSheet.get("thirdPartyPaymentsTotal")),
    prepareAmountRow(t("account.governmentPayments"), CategoryEnum.RECEIVED, contractBalanceSheet.get("governmentPaymentsTotal")),
    prepareAmountRow(t("account.employerPayments"), CategoryEnum.RECEIVED, contractBalanceSheet.get("employerPaymentsTotal")),
    prepareAmountRow(
        t("account.otherPensionContractsPayments"),
        CategoryEnum.TRANSFERS,
        contractBalanceSheet.get("otherPensionContractsPaymentsTotal"),
    ),
    prepareAmountRow(t("account.otherPayments"), CategoryEnum.OTHER, contractBalanceSheet.get("otherPaymentsTotal")),
    prepareAmountRow(t("account.axaClubLoyaltyCard"), CategoryEnum.OTHER, contractBalanceSheet.get("axaClubLoyaltyCardTotal")),
    prepareAmountRow(t("common.profit"), CategoryEnum.PROFIT, contractBalanceSheet.get("assetCapitalizationTotal")),
    prepareAmountRow(t("account.withdrawal"), CategoryEnum.PAID, contractBalanceSheet.get("withdrawalAmountTotal")),
    prepareAmountRow(t("account.taxesAfterRounding"), CategoryEnum.PAID, contractBalanceSheet.get("taxesAndRoundingAmountTotal")),
];

const getSummaryRows = (t, contractBalanceSheet) => [
    prepareSummaryRow(t, t("account.earningsAmountTotal"), contractBalanceSheet.get("earningsAmountTotal")),
    prepareSummaryRow(t, t("account.paymentsAmountTotal"), contractBalanceSheet.get("paymentsAmountTotal")),
    prepareSummaryRow(t, t("account.actualState"), contractBalanceSheet.get("netAmountTotal"), "b"),
];

export const FinancialStatementGridItem = () => {
    const { t } = i18n.useTranslation();
    const isStatementLoading = useSelector(fetching.isFetching(ENTITY_FINANCIAL_STATEMENT, true));
    const isStatementError = useSelector(entity.isError(ENTITY_FINANCIAL_STATEMENT));

    const financialStatement = useSelector(getFinancialStatement);
    const contractBalanceSheet = useSelector(getContractBalanceSheet);
    const statementDate = dateTimeToLocalFullDate(financialStatement.dateTime);

    return (
        <GridItem xs={12}>
            <DataTable
                icon="document-dollar"
                title={t("account.actualState")}
                actions={<Typography color="gray">{t("statements.dateTime", { statementDate })}</Typography>}
                disableColumnReorder
                getRowId={(row) => row.name}
                columns={columns(t)}
                rows={getData(t, contractBalanceSheet)}
                summaryRow={getSummaryRows(t, contractBalanceSheet)}
                errorMsg={t("error.sectionUnavailable")}
                isLoading={isStatementLoading}
                isError={isStatementError}
            />
        </GridItem>
    );
};
