import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { GridLayout } from "ui-library/layouts";

import router from "core/router";
import fetching from "core/fetching";
import userContracts from "core/userContracts";

import { ClaimReportCategory, IndividualPersonType } from "enums";
import { Pages, Tabs } from "routeConstants";

import {
    AgentOverviewGridItem,
    ClaimsOverviewGridItem,
    ContractDocumentsOverviewGridItem,
    HolderOverviewGridItemLegacy,
    LifeFinancialOperationsOverviewGridItem,
} from "containers/contract";
import { ENTITY_CONTRACT_DOCUMENTS, ENTITY_CONTRACT_OVERVIEW, ENTITY_FINANCIAL_OPERATIONS, ENTITY_PAYMENT_INFORMATION } from "../constants";
import {
    createGetCompliantLineOfBusinessClasses,
    getActualPaymentScheduler,
    getClaims,
    getFinancialOperations,
    getPolicyHolder,
} from "../selectors";
import { ContractOverviewGridItem } from "../containers";

export const OverviewTab: FC = () => {
    const { idObject } = useParams();
    const contract = userContracts.useGetContractById(idObject);
    const { isInFutureOrTerminated } = userContracts.useGetContractDerivedData(contract);
    const actualScheduler = useSelector(getActualPaymentScheduler);
    const claims = useSelector(getClaims);
    const holder = useSelector(getPolicyHolder);
    const isContractOverviewLoading = useSelector(fetching.isFetching(ENTITY_CONTRACT_OVERVIEW, false));
    const compliantLineOfBusinessClasses = useSelector(createGetCompliantLineOfBusinessClasses(idObject));

    return (
        <GridLayout defaultSpacing>
            <LifeFinancialOperationsOverviewGridItem
                idObject={Number(idObject)}
                financialOperationsEntityName={ENTITY_FINANCIAL_OPERATIONS}
                selector={getFinancialOperations}
                paymentDataEntityName={ENTITY_PAYMENT_INFORMATION}
                detailLink={router.getStaticUrl(Pages.CONTRACT_TRAVEL, Tabs.FINANCIAL_OPERATIONS, { idObject })}
                hasQuickPayment={!isInFutureOrTerminated}
            />

            <ContractOverviewGridItem
                policy={contract}
                actualScheduler={actualScheduler}
                detailLink={router.getStaticUrl(Pages.CONTRACT_TRAVEL, Tabs.DETAIL, { idObject })}
            />

            <HolderOverviewGridItemLegacy
                holder={holder}
                individualPersonType={IndividualPersonType.CONTRACT_HOLDER}
                detailLink={router.getStaticUrl(Pages.CONTRACT_TRAVEL, Tabs.HOLDER, { idObject })}
            />

            <ContractDocumentsOverviewGridItem
                idObject={Number(idObject)}
                entityName={ENTITY_CONTRACT_DOCUMENTS}
                detailLink={router.getStaticUrl(Pages.CONTRACT_TRAVEL, Tabs.DOCUMENTS, { idObject })}
            />

            <ClaimsOverviewGridItem
                claims={claims}
                detailLink={router.getStaticUrl(Pages.CONTRACT_TRAVEL, Tabs.CLAIMS, { idObject })}
                idObject={idObject}
                categoryParam={ClaimReportCategory.TRAVEL.id}
                compliantLineOfBusinessClasses={compliantLineOfBusinessClasses}
                isContractOverviewLoading={isContractOverviewLoading}
            />

            <AgentOverviewGridItem
                idObject={Number(idObject)}
                detailLink={router.getStaticUrl(Pages.CONTRACT_TRAVEL, Tabs.AGENT, { idObject })}
            />
        </GridLayout>
    );
};
