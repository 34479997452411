import React, { FC } from "react";

import { GridLayout } from "ui-library/layouts";

import { PisGridItems } from "../containers";

export const PisTab: FC = () => (
    <GridLayout defaultSpacing>
        <PisGridItems />
    </GridLayout>
);
