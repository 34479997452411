import React from "react";
import PropTypes from "prop-types";

import { Typography } from "ui-library/atoms";
import { ContentBox, Section } from "ui-library/layouts";

import i18n from "core/i18n";

import { FormWideDisabledProvider } from "core/form";
import { PensionDssFundsChangeType } from "enums";

import { CHANGE_STRATEGY_DATA } from "../constants";
import { PercentageSelectDss } from "../components";
import { StrategyCombinedMandatoryDisclaimer, StrategyVoluntaryDisclaimer } from "../components/textBlock";

const DssStrategySection = ({ formName, changeType, actualStrategies, isChangeStrategyDataPossible, disabled = false }) => {
    const { t } = i18n.useTranslation();
    const isVoluntary = changeType === PensionDssFundsChangeType.VOLUNTARY.id;
    const title = isVoluntary
        ? t("pension.fundsChange.changeInvestmentStrategy.voluntaryTitle")
        : t("pension.fundsChange.changeInvestmentStrategy.title");
    const isDisabled = !isChangeStrategyDataPossible || disabled;

    return (
        <Section>
            <FormWideDisabledProvider value={isDisabled}>
                {/* Section title */}
                <ContentBox>
                    <Typography variant="h3">{`2. ${title}`}</Typography>
                </ContentBox>

                {/* Section Disclaimer */}
                {!isVoluntary && <StrategyCombinedMandatoryDisclaimer />}
                {isVoluntary && <StrategyVoluntaryDisclaimer />}

                {/* Form title */}
                <ContentBox>
                    <Typography fontWeight={600} component="p">
                        {t("pension.fundsChange.changeInvestmentStrategy.choose")}
                    </Typography>
                </ContentBox>

                {/* Form or not allowed at the moment text. */}
                {isChangeStrategyDataPossible ? (
                    <PercentageSelectDss formName={formName} sectionName={CHANGE_STRATEGY_DATA} actualStrategies={actualStrategies} />
                ) : (
                    <ContentBox>
                        <Typography color="red" component="p">
                            {t("pension.fundsChange.unprocessedInvestmentStrategy")}
                        </Typography>
                    </ContentBox>
                )}
            </FormWideDisabledProvider>
        </Section>
    );
};

DssStrategySection.propTypes = {
    formName: PropTypes.string.isRequired,
    changeType: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    actualStrategies: PropTypes.object.isRequired,
};

export default DssStrategySection;
