import { withTheme } from "@mui/styles";
import styled from "styled-components";
import { Box } from "ui-library/layouts/Box/Box";
export const StyledStaticPageLayout = withTheme(styled(Box)`
    background: linear-gradient(180deg, #eef2fa 0%, rgba(238, 242, 250, 0) 100%);
    padding: 96px 0 40px;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        padding: 48px 0;
    }
`);
