import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { Button, Container, Loading } from "ui-library/atoms";
import { Flexbox } from "ui-library/layouts";
import { FormBlock } from "ui-library/modules";

import entity from "core/entity";
import fetching from "core/fetching";
import i18n from "core/i18n";
import { OtpWrongNumberDds } from "containers/error";
import { FullPageOtpFormLayout } from "containers/fullPageForm";
import errorHandling, { OTP_WRONG_MOBILE_NUMBER_FIN_OPERATIONS } from "core/errorHandling";
import { form, FormErrorBox } from "core/form";

import { BackToFinancialStatementButton, DdsFundsChangeComponent, FundsChangeDdsNotPermittedComponent } from "./components";

const DdsContainer = ({ canSubmit, handleSubmit, formName, submitting, OtpModule, fundsChangePermittedEntity, idObject, slowServices }) => {
    const DdsErrorComponent = () => <OtpWrongNumberDds idObject={idObject} />;

    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();
    const onOtpBack = () => dispatch(OtpModule.clear(formName));

    const otpWrongNumberErrorExists = useSelector(errorHandling.isServiceInErrorState(OTP_WRONG_MOBILE_NUMBER_FIN_OPERATIONS));
    const isFetchingAnySlow = useSelector(fetching.isFetchingAny(slowServices));
    const isFundsChangePermittedLoading = useSelector(fetching.isFetching(fundsChangePermittedEntity, true));

    const isOtpVisible = useSelector(OtpModule.createIsVisibleSelector(formName));
    const fundsChangePermittedObj = useSelector(entity.getData(fundsChangePermittedEntity));

    return (
        <Container maxWidth="md">
            <Loading fullPage loading={submitting || isFetchingAnySlow || isFundsChangePermittedLoading} />

            <FormErrorBox form={formName} />

            <FormBlock>
                {fundsChangePermittedObj?.isFundsChangePermitted ? (
                    <DdsFundsChangeComponent formName={formName} isOtpVisible={isOtpVisible} />
                ) : (
                    <FundsChangeDdsNotPermittedComponent />
                )}

                {isOtpVisible && <OtpModule.Container formName={formName} stepNumber={3} ErrorComponent={DdsErrorComponent} />}

                <Flexbox gap={2} justifyContent="space-between">
                    {/* Back Button */}
                    {isOtpVisible && !otpWrongNumberErrorExists ? (
                        <Button onClick={onOtpBack} variant="outlined" color="blue">
                            {t("form.otp.backToFirstStep")}
                        </Button>
                    ) : (
                        <BackToFinancialStatementButton idObject={idObject} />
                    )}

                    {/* Submit Button */}
                    <Button onClick={handleSubmit} variant="contained" color="blue" disabled={!canSubmit || otpWrongNumberErrorExists}>
                        {t("common.send")}
                    </Button>
                </Flexbox>
            </FormBlock>
        </Container>
    );
};

DdsContainer.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    canSubmit: PropTypes.bool.isRequired,
    formName: PropTypes.string.isRequired,
    submitting: PropTypes.bool.isRequired,
    idObject: PropTypes.number.isRequired,
    OtpModule: PropTypes.object.isRequired,
    slowServices: PropTypes.any.isRequired,
    fundsChangePermittedEntity: PropTypes.string.isRequired,
};

export const createContainerDds = (formName, idObjectSelector, OtpModule, slowServices, fundsChangePermittedEntity) =>
    form(formName)((props) => {
        const { t } = i18n.useTranslation();
        const idObject = useSelector(idObjectSelector);

        return (
            <FullPageOtpFormLayout
                formName={formName}
                formTitle={t("pension.fundsChange.title")}
                BackButton={<BackToFinancialStatementButton idObject={idObject} />}
            >
                <DdsContainer
                    {...props}
                    formName={formName}
                    idObject={idObject}
                    OtpModule={OtpModule}
                    slowServices={slowServices}
                    fundsChangePermittedEntity={fundsChangePermittedEntity}
                />
            </FullPageOtpFormLayout>
        );
    });
