import { Record, Map } from "immutable";
import { Countries } from "./pageContext";

export const LifeFunds = Record({ id: null, codes: Map(), name: null, color: null, link: null });

export default Object.freeze({
    MONETARY_FUND: new LifeFunds({
        // Peněžní fond
        id: "MONETARY_FUND",
        codes: Map({
            [Countries.CZ.id]: "AXAF01",
            [Countries.SK.id]: "AXAF01S",
        }),
        name: "fundName.MONETARY_FUND",
        color: "greenSuccess",
        link: "fundLink.MONETARY_FUND",
    }),
    BOND_FUND: new LifeFunds({
        // Dluhopisový fond
        id: "BOND_FUND",
        codes: Map({
            [Countries.CZ.id]: "AXAF02",
            [Countries.SK.id]: "AXAF02S",
        }),
        name: "fundName.BOND_FUND",
        color: "blueCloud",
        link: "fundLink.BOND_FUND",
    }),
    MIXED_FUND: new LifeFunds({
        // Smíšený fond
        id: "MIXED_FUND",
        codes: Map({
            [Countries.CZ.id]: "AXAF03",
            [Countries.SK.id]: "",
        }),
        name: "fundName.MIXED_FUND",
        color: "blueKing",
        link: "fundLink.MIXED_FUND",
    }),
    FOREIGN_FUND: new LifeFunds({
        // Zahraniční fond
        id: "FOREIGN_FUND",
        codes: Map({
            [Countries.CZ.id]: "AXAF04",
            [Countries.SK.id]: "AXAF03S",
        }),
        name: "fundName.FOREIGN_FUND",
        color: "orangeMainLighter",
        link: "fundLink.FOREIGN_FUND",
    }),
    REAL_ESTATE_FUND: new LifeFunds({
        // Realitný fond
        id: "REAL_ESTATE_FUND",
        codes: Map({
            [Countries.CZ.id]: "AXAF06",
            [Countries.SK.id]: "AXAF06S",
        }),
        name: "fundName.REAL_ESTATE_FUND",
        color: "violetMain",
        link: "fundLink.REAL_ESTATE_FUND",
    }),
    CONSERVATIVE_FUND: new LifeFunds({
        // Konzervativní fond
        id: "CONSERVATIVE_FUND",
        codes: Map({
            [Countries.CZ.id]: "AXAF08",
            [Countries.SK.id]: "",
        }),
        name: "",
        color: "pinkMain",
        link: "",
    }),
    CEE_BOND_FUND: new LifeFunds({
        // AXA CEE dluhopisový fond
        id: "CEE_BOND_FUND",
        codes: Map({
            [Countries.CZ.id]: "AXACEE01",
            [Countries.SK.id]: "AXACEE01S",
        }),
        name: "fundName.CEE_BOND_FUND",
        color: "greenMain",
        link: "fundLink.CEE_BOND_FUND",
    }),
    CEE_EQUITY_FUND: new LifeFunds({
        // AXA CEE akciový fond/Equity fund
        id: "CEE_EQUITY_FUND",
        codes: Map({
            [Countries.CZ.id]: "AXACEE02",
            [Countries.SK.id]: "AXACEE02S",
        }),
        name: "fundName.CEE_EQUITY_FUND",
        color: "grayMain",
        link: "fundLink.CEE_EQUITY_FUND",
    }),
    SELECTION_EMERGING_EQUITY_FUND: new LifeFunds({
        // AXA Selection Emerging Equity
        id: "SELECTION_EMERGING_EQUITY_FUND",
        codes: Map({
            [Countries.CZ.id]: "AXAS02",
            [Countries.SK.id]: "AXAS02S",
        }),
        name: "fundName.SELECTION_EMERGING_EQUITY_FUND",
        color: "grayMainDarker",
        link: "fundLink.SELECTION_EMERGING_EQUITY_FUND",
    }),
    SELECTION_OPORTUNITIES_FUND: new LifeFunds({
        // AXA Selection Opportunities
        id: "SELECTION_OPORTUNITIES_FUND",
        codes: Map({
            [Countries.CZ.id]: "AXAS01",
            [Countries.SK.id]: "AXAS01S",
        }),
        name: "fundName.SELECTION_OPORTUNITIES_FUND",
        color: "grayWhiteLighter",
        link: "fundLink.SELECTION_OPORTUNITIES_FUND",
    }),
    PROGRESIVE_ETF_FUND: new LifeFunds({
        // Progresive ETF
        id: "PROGRESIVE_ETF_FUND",
        codes: Map({
            [Countries.CZ.id]: "AXAF13",
            [Countries.SK.id]: "AXAF13S",
        }),
        name: "fundName.PROGRESIVE_ETF_FUND",
        color: "orangeMainDarker",
        link: "fundLink.PROGRESIVE_ETF_FUND",
    }),
    DYNAMIC_ETF_FUND: new LifeFunds({
        // Dynamic ETF
        id: "DYNAMIC_ETF_FUND",
        codes: Map({
            [Countries.CZ.id]: "AXAF14",
            [Countries.SK.id]: "AXAF14S",
        }),
        name: "fundName.DYNAMIC_ETF_FUND",
        color: "orangeMain",
        link: "fundLink.DYNAMIC_ETF_FUND",
    }),
    BALANCED_ETF_FUND: new LifeFunds({
        // Balanced ETF
        id: "BALANCED_ETF_FUND",
        codes: Map({
            [Countries.CZ.id]: "AXAF15",
            [Countries.SK.id]: "AXAF15S",
        }),
        name: "fundName.BALANCED_ETF_FUND",
        color: "blueMuddy",
        link: "fundLink.BALANCED_ETF_FUND",
    }),
    COMODITY_ETF_FUND: new LifeFunds({
        // Commodity ETF
        id: "COMODITY_ETF_FUND",
        codes: Map({
            [Countries.CZ.id]: "AXAF16",
            [Countries.SK.id]: "AXAF16S",
        }),
        name: "fundName.COMODITY_ETF_FUND",
        color: "redMain",
        link: "fundLink.COMODITY_ETF_FUND",
    }),
    GROWTH_TRENDS_BALANCED_FUND: new LifeFunds({
        // AXA Growth Trends Balanced
        id: "GROWTH_TRENDS_BALANCED_FUND",
        codes: Map({
            [Countries.CZ.id]: "KUM1",
            [Countries.SK.id]: "KUM1S",
        }),
        name: "fundName.GROWTH_TRENDS_BALANCED_FUND",
        color: "blueSky",
        link: "fundLink.GROWTH_TRENDS_BALANCED_FUND",
    }),
    DIVERSIFIED_FUTURE_FUND: new LifeFunds({
        // AXA Diversified Future
        id: "DIVERSIFIED_FUTURE_FUND",
        codes: Map({
            [Countries.CZ.id]: "KUM2",
            [Countries.SK.id]: "KUM2S",
        }),
        name: "fundName.DIVERSIFIED_FUTURE_FUND",
        color: "blueMainDarker",
        link: "fundLink.DIVERSIFIED_FUTURE_FUND",
    }),
    TALENTED_COUNTRIES_FUND: new LifeFunds({
        // AXA Talented Countries
        id: "TALENTED_COUNTRIES_FUND",
        codes: Map({
            [Countries.CZ.id]: "KUM3",
            [Countries.SK.id]: "KUM3S",
        }),
        name: "fundName.TALENTED_COUNTRIES_FUND",
        color: "blueMain",
        link: "fundLink.TALENTED_COUNTRIES_FUND",
    }),
    MILLENIUM_TRENDS_FUND: new LifeFunds({
        // AXA Millenium Trends
        id: "MILLENIUM_TRENDS_FUND",
        codes: Map({
            [Countries.CZ.id]: "KUM4",
            [Countries.SK.id]: "KUM4S",
        }),
        name: "fundName.MILLENIUM_TRENDS_FUND",
        color: "blueMainLighter",
        link: "fundLink.MILLENIUM_TRENDS_FUND",
    }),
    PARTNERS_10_FUND: new LifeFunds({
        // Partners vyvazena strategie 10
        id: "PARTNERS_10_FUND",
        codes: Map({
            [Countries.CZ.id]: "AXAPAR10",
            [Countries.SK.id]: "",
        }),
        name: "",
        color: "blueTyrkys",
        link: "",
    }),
    PARTNERS_13_FUND: new LifeFunds({
        // Partners dynamicka strategie 13
        id: "PARTNERS_13_FUND",
        codes: Map({
            [Countries.CZ.id]: "AXAPAR13",
            [Countries.SK.id]: "",
        }),
        name: "",
        color: "redMainDarker",
        link: "",
    }),
    CONSERVATIVE_OPORTUNITIES_FUND: new LifeFunds({
        // AXA Conservative Opportunities / conservative Euro plus
        id: "CONSERVATIVE_OPORTUNITIES_FUND",
        codes: Map({
            [Countries.CZ.id]: "KUM15",
            [Countries.SK.id]: "KUM11S",
        }),
        name: "fundName.CONSERVATIVE_OPORTUNITIES_FUND",
        color: "redMainLighter",
        link: "fundLink.CONSERVATIVE_OPORTUNITIES_FUND",
    }),
    BALANCED_SOLUTIONS_FUND: new LifeFunds({
        // AXA Balanced Solutions
        id: "BALANCED_SOLUTIONS_FUND",
        codes: Map({
            [Countries.CZ.id]: "KUM12",
            [Countries.SK.id]: "KUM12S",
        }),
        name: "fundName.BALANCED_SOLUTIONS_FUND",
        color: "blueSoft",
        link: "fundLink.BALANCED_SOLUTIONS_FUND",
    }),
    DYNAMIC_TREND_FUND: new LifeFunds({
        // AXA Dynamic Trend
        id: "DYNAMIC_TREND_FUND",
        codes: Map({
            [Countries.CZ.id]: "KUM13",
            [Countries.SK.id]: "KUM13S",
        }),
        name: "fundName.DYNAMIC_TREND_FUND",
        color: "blueSoft",
        link: "fundLink.DYNAMIC_TREND_FUND",
    }),
    DYNAMIC_GROWTH_FUND: new LifeFunds({
        // AXA Dynamic Growth
        id: "DYNAMIC_GROWTH_FUND",
        codes: Map({
            [Countries.CZ.id]: "KUM14",
            [Countries.SK.id]: "KUM14S",
        }),
        name: "fundName.DYNAMIC_GROWTH_FUND",
        color: "greenMainDarker",
        link: "fundLink.DYNAMIC_GROWTH_FUND",
    }),
    STRATEGIC_ALLOCATION_FUND: new LifeFunds({
        // Fond strategické alokace
        id: "STRATEGIC_ALLOCATION_FUND",
        codes: Map({
            [Countries.CZ.id]: "AXAWD1",
            [Countries.SK.id]: "",
        }),
        name: "",
        color: "greenMainLighter",
        link: "",
    }),
    BALANCED_ALLOCATION_FUND: new LifeFunds({
        // Fond vyvážené alokace
        id: "BALANCED_ALLOCATION_FUND",
        codes: Map({
            [Countries.CZ.id]: "AXAWD2",
            [Countries.SK.id]: "",
        }),
        name: "",
        color: "grayMainLighter",
        link: "",
    }),
    GROWTH_ASSET_ALLOCATION_FUND: new LifeFunds({
        // Fond růstové alokace aktiv
        id: "GROWTH_ASSET_ALLOCATION_FUND",
        codes: Map({
            [Countries.CZ.id]: "FRAA",
            [Countries.SK.id]: "",
        }),
        name: "",
        color: "grayWhiteDarker",
        link: "",
    }),
    MIXED_ALLOCATION_FUND: new LifeFunds({
        // Fond smíšené alokace aktiv
        id: "MIXED_ALLOCATION_FUND",
        codes: Map({
            [Countries.CZ.id]: "FSAA",
            [Countries.SK.id]: "",
        }),
        name: "",
        color: "graySoftBorder",
        link: "",
    }),
    PPLI_BOND_STRATEGY_FUND: new LifeFunds({
        // PPLI Bond Strategy
        id: "PPLI_BOND_STRATEGY_FUND",
        codes: Map({
            [Countries.CZ.id]: "PPLI1",
            [Countries.SK.id]: "",
        }),
        name: "",
        color: "grayBorder",
        link: "",
    }),
    PPLI_MULTIASSET_STRATEGY_FUND: new LifeFunds({
        // PPLI Multi-Asset Strategy
        id: "PPLI_MULTIASSET_STRATEGY_FUND",
        codes: Map({
            [Countries.CZ.id]: "PPLI2",
            [Countries.SK.id]: "",
        }),
        name: "",
        color: "grayLine",
        link: "",
    }),
    SUCCESS_ABSOLUTE_FUND: new LifeFunds({
        // Success Absolute
        id: "SUCCESS_ABSOLUTE_FUND",
        codes: Map({
            [Countries.CZ.id]: "SUCF01",
            [Countries.SK.id]: "",
        }),
        name: "",
        color: "grayDark",
        link: "",
    }),
    SUCCESS_RELATIVE_FUND: new LifeFunds({
        // Success Relative
        id: "SUCCESS_RELATIVE_FUND",
        codes: Map({
            [Countries.CZ.id]: "SUCF02",
            [Countries.SK.id]: "SUCF02S",
        }),
        name: "",
        color: "blueKing",
        link: "",
    }),
    CONSERVATIVE_EURO_PLUS_FUND: new LifeFunds({
        // Conservative Euro Plus - SK
        id: "CONSERVATIVE_EURO_PLUS_FUND",
        codes: Map({
            [Countries.CZ.id]: "",
            [Countries.SK.id]: "",
        }),
        name: "",
        color: "violetMain",
        link: "",
    }),
    MULTI_ASSET_SOLUTION_FUND: new LifeFunds({
        // AXA Multi Asset Solution - SK
        id: "MULTI_ASSET_SOLUTION_FUND",
        codes: Map({
            [Countries.CZ.id]: "",
            [Countries.SK.id]: "MASS",
        }),
        name: "",
        color: "pinkMain",
        link: "",
    }),
    SELECTION_GLOBAL_EQUITY_FUND: new LifeFunds({
        // AXA Selection Global Equity - SK - repetition??
        id: "SELECTION_GLOBAL_EQUITY_FUND",
        codes: Map({
            [Countries.CZ.id]: "",
            [Countries.SK.id]: "AXAS03S",
        }),
        name: "fundName.SELECTION_GLOBAL_EQUITY_FUND",
        color: "orangeMain",
        link: "fundLink.SELECTION_GLOBAL_EQUITY_FUND",
    }),
    ALPHA_BETA_STRATEGIES_FUND: new LifeFunds({
        // AXA Alpha/Beta Strategies - SK
        id: "ALPHA_BETA_STRATEGIES_FUND",
        codes: Map({
            [Countries.CZ.id]: "",
            [Countries.SK.id]: "ABGESS",
        }),
        name: "",
        color: "redMain",
        link: "",
    }),
    SMALL_CAP_FUND: new LifeFunds({
        // Small Cap SK - SK
        id: "SMALL_CAP_FUND",
        codes: Map({
            [Countries.CZ.id]: "",
            [Countries.SK.id]: "SCAP01S",
        }),
        name: "",
        color: "grayMain",
        link: "",
    }),
    IM_AB_WORLD_FUND: new LifeFunds({
        // AXA IM/AB World Funds - SK
        id: "IM_AB_WORLD_FUND",
        codes: Map({
            [Countries.CZ.id]: "",
            [Countries.SK.id]: "AIMFS",
        }),
        name: "",
        color: "greenMain",
        link: "",
    }),
    SUCCESS_ABSOLUTE_FUND_SK: new LifeFunds({
        // Success Absolute - SK
        id: "SUCCESS_ABSOLUTE_FUND_SK",
        codes: Map({
            [Countries.CZ.id]: "",
            [Countries.SK.id]: "SUCF01S",
        }),
        name: "",
        color: "blueMain",
        link: "",
    }),
});
