import React from "react";
import PropTypes from "prop-types";

import { SubmitButton } from "containers/bulkChange";
import { Flexbox } from "ui-library/layouts";

import { BackToPolicyHolderButton } from "./BackToPolicyHolderButton";

export const ButtonsBox = ({ idObject, formName, canSubmit, handleSubmit }) => {
    return (
        <Flexbox gap={2} justifyContent="space-between">
            <BackToPolicyHolderButton idObject={idObject} />
            <SubmitButton handleSubmit={handleSubmit} formName={formName} canSubmit={canSubmit} />
        </Flexbox>
    );
};

ButtonsBox.propTypes = {
    idObject: PropTypes.number.isRequired,
    formName: PropTypes.string.isRequired,
    canSubmit: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};
