import { createSelector, createStructuredSelector } from "reselect";
import { List, Map } from "immutable";
import moment from "moment";

import { fn, model } from "core/util";
import pageContext from "core/pageContext";

import instance from "./instance";

export const translateDirectly = (key, args) => instance.t(key, args);

/**
 * Creates selector which returns localized name from a list according to current localization.
 * Returns undefined when none is found.
 */
export const createGetLocalizedName = () => createSelector((state, code) => code, getLanguageCode, model.getLocaleCodeName);

/**
 * Creates a selector which:
 * 1) Turns enum into map of `id -> msg` (message identifier)
 * 2) translates this map into map of `id -> message`
 */
export const createGetLocalizedEnumMap = (enumObject) => {
    const enumMap = Object.values(enumObject)
        .map((item) => [item.id, () => instance.t(item.msg)])
        .reduce((acc, [key, value]) => Object.assign(acc, { [key]: value }), {});
    return createStructuredSelector(enumMap);
};

export const createGetLocalizedEnumOptions = (enumObject) =>
    createSelector(createGetLocalizedEnumMap(enumObject), (msgMap) =>
        List(Object.entries(msgMap).map(([value, label]) => Map({ label, value }))),
    );

/**
 * Creates selector which translates specified properties of enum. Returns enum as a list with translated properties.
 */
export const createGetLocalizedEnum = (enumObject, ...properties) => {
    const createEnumItemPropertyMap = (enumItem) =>
        properties.map((property) => [property, () => instance.t(enumItem[property])]).reduce(fn.toObject, {});
    const enumList = Object.values(enumObject);
    const enumMap = enumList.map((item) => [item.id, createStructuredSelector(createEnumItemPropertyMap(item))]).reduce(fn.toObject, {});
    return createSelector(createStructuredSelector(enumMap), (translatedOptions) =>
        List(enumList.map((item) => item.merge(translatedOptions[item.id]))),
    );
};

/**
 * Returns language code of current localization.
 */
export const getLanguageCode = (state) => pageContext.getLocale(state).languageCode;

export const getDateFormat = (state) => pageContext.getLocale(state).dateFormat;

const defaultGetLabel = (code, name) => name;

export const createAllOptionsSelectorForEnum = (enumObject, valueAttribute = "code", messageAttribute = "msg") =>
    createSelector(() =>
        Object.values(enumObject).map((enumElement) =>
            Map({
                value: enumElement.get(valueAttribute),
                label: translateDirectly(enumElement.get(messageAttribute)),
            }),
        ),
    );

export const createGetLocalizedCodeOptions = (codes, getLabel = defaultGetLabel) =>
    createSelector(getLanguageCode, (languageCode) =>
        codes
            .map((code) => [code.code, model.getLocaleCodeName(code, languageCode)])
            .filter(([, name]) => !!name)
            .map(([code, name]) =>
                Map({
                    value: code,
                    label: getLabel(code, name),
                }),
            ),
    );

/**
 * Creates selector which returns localized name from a list according to current localization.
 * Returns undefined when none is found.
 */
// TODO unify and rename
export const createGetLocalizedNameWithCode = (code) =>
    createSelector(getLanguageCode, (languageCode) => model.getLocaleCodeName(code, languageCode));

export const createGetFormattedDate = (timestamp) => (state) => moment.utc(timestamp).format(getDateFormat(state));
