import { withTheme } from "@mui/styles";
import styled from "styled-components";
import { FormGroup, FormControlLabel } from "@mui/material";
import Switch from "@mui/material/Switch";

export const StyledSwitch = withTheme(styled(Switch)`
    &&& {
        width: 48px;
        height: 24px;
        padding: 0px;
        .MuiSwitch-switchBase {
            padding: 4px;
        }
        .MuiSwitch-thumb {
            box-shadow: none;
        }
        .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
            background: ${(props) => props.theme.palette.blue.main};
        }
        .Mui-checked {
            transform: translateX(24px);
            color: ${(props) => props.theme.palette.white.main};
        }
        .MuiSwitch-thumb {
            width: 16px;
            height: 16px;
        }
        .MuiSwitch-track {
            height: 24px;
            border-radius: 99px;
            opacity: 1;
            background: ${(props) => props.theme.palette.gray.border};
        }
    }
`);

export const StyledSwitchFormGroup = withTheme(styled(FormGroup)`
    &&& {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
`);
export const StyledSwitchFormControlLabel = withTheme(styled(FormControlLabel)`
    && {
        margin-right: 8px;
        margin-left: 0;
        label {
            margin-bottom: 0;
            font-weight: 400;
            white-space: pre-wrap;
        }
        &:hover {
            label {
                cursor: pointer;
            }
        }
    }
`);
