import React, { FC } from "react";
import { useSelector } from "react-redux";

import { ContentBox } from "ui-library/layouts";
import { Radio, Typography } from "ui-library/atoms";

import i18n from "core/i18n";
import pageContext from "core/pageContext";
import user from "core/user";
import { Field, getFormFieldValue, RadioSelect } from "core/form";
import { FundsTransferType } from "types";

import { FUNDS_TRANSFER_TYPE, SECTION_BANK_ACCOUNT } from "../../constants";

import { AccountSectionCs } from "./AccountSectionCs";
import { AccountSectionSk } from "./AccountSectionSk";

type AccountSectionProps = {
    formName: string;
};

export const AccountSection: FC<AccountSectionProps> = ({ formName }) => {
    const { t } = i18n.useTranslation();
    const isSkSite = useSelector(pageContext.getIsSlovakSite);
    const selectedFundsTransferType = useSelector(getFormFieldValue(formName, FUNDS_TRANSFER_TYPE));
    const isOneTimeChangeRole = useSelector(user.getIsOneTimeChangeRole);

    return (
        <>
            <ContentBox>
                <Typography variant="h4" markDown={t("vehicle.change.contractCancellation.fundsTransfer")} />
            </ContentBox>

            {isOneTimeChangeRole && (
                <>
                    <ContentBox>
                        <Typography markDown={t("change.fundsTransfer.infoText")} />
                    </ContentBox>

                    <ContentBox fullWidth>
                        <Field component={RadioSelect} name={FUNDS_TRANSFER_TYPE} direction="row">
                            <Radio
                                id={FundsTransferType.INSURANCE_PAYMENT_BANK_ACCOUNT}
                                value={FundsTransferType.INSURANCE_PAYMENT_BANK_ACCOUNT}
                                label={t("change.fundsTransfer.type.insurancePaymentBankAccount")}
                            />
                            <Radio
                                id={FundsTransferType.OTHER_BANK_ACCOUNT}
                                value={FundsTransferType.OTHER_BANK_ACCOUNT}
                                label={t("change.fundsTransfer.type.OtherBankAccount")}
                            />
                        </Field>
                    </ContentBox>
                </>
            )}

            {selectedFundsTransferType === FundsTransferType.OTHER_BANK_ACCOUNT && (
                <>
                    {isSkSite && <AccountSectionSk shouldCheckIbanCountry={false} sectionName={SECTION_BANK_ACCOUNT} />}
                    {!isSkSite && <AccountSectionCs sectionName={SECTION_BANK_ACCOUNT} />}
                </>
            )}
        </>
    );
};
