import { createAction } from "@reduxjs/toolkit";

import { app } from "core/util";
import { PensionDdsContract } from "types";

import { NAME } from "./constants";

export const setPensionDdsContractDetail = app.createActionWithPayload<PensionDdsContract>(`${NAME}/SET_PENSION_DDS_CONTRACT_DETAIL`);

export const clear = createAction(`${NAME}/CLEAR`);
