import React, { FC } from "react";
import { useSelector } from "react-redux";

import useBreakpoints from "ui-library/theme/breakpoints";
import { Box, ContentBox } from "ui-library/layouts";
import { Loading, Tab, TabPanel, Tabs, Typography } from "ui-library/atoms";
import { InfoPanel, InfoText } from "ui-library/modules";

import i18n from "core/i18n";
import fetching from "core/fetching";
import userContracts from "core/userContracts";

import { FinOperationsOverviewItem } from "./FinOperationsOverviewItem";

const INDEX_THIS_MONTH = 0;
const INDEX_LAST_MONTH = 1;

export const FinOperationsOverviewInfoPanel: FC = () => {
    const breakpointMd = useBreakpoints("md");
    const { t } = i18n.useTranslation();

    const [lastPayments, setLastPayments] = React.useState(0);
    const handleLastPayments = (event, newValue) => setLastPayments(newValue);

    const activeContracts = userContracts.useGetActiveContracts();
    const { hasAnyLastMonthMovements, hasAnyThisMonthMovements, hasAnyFailedFetchingMovements } =
        userContracts.useGetContractsDerivedData();

    const isLoading = useSelector(fetching.isFetching(fetching.FETCHING_CONTRACTS_FINANCIAL_OPERATIONS, true));

    return (
        <InfoPanel droppable={!breakpointMd} bgVariant={"white"} icon={"chart-bar"} title={t("common.lastMovements")}>
            <Box>
                <ContentBox>
                    <Tabs value={lastPayments} onChange={handleLastPayments}>
                        <Tab label={t("common.thisMonth")} />
                        <Tab label={t("common.lastMonth")} />
                    </Tabs>
                </ContentBox>

                <Loading loading={isLoading} />

                {hasAnyFailedFetchingMovements && (
                    <ContentBox>
                        <InfoText icon="alert" text={t("error.paymentsUnavailable")} color="red" />
                    </ContentBox>
                )}

                <TabPanel value={lastPayments} index={INDEX_THIS_MONTH}>
                    {!isLoading && !hasAnyThisMonthMovements && <Typography>{t("common.noMovementsInPeriod")}</Typography>}
                    {hasAnyThisMonthMovements &&
                        activeContracts
                            .filter((contract) => contract?.financialOperations?.thisMonthMovements.size > 0)
                            .map((contract) => <FinOperationsOverviewItem key={contract.idObject} contract={contract} isThisMonth />)}
                </TabPanel>

                <TabPanel value={lastPayments} index={INDEX_LAST_MONTH}>
                    {!isLoading && !hasAnyLastMonthMovements && <Typography>{t("common.noMovementsInPeriod")}</Typography>}
                    {hasAnyLastMonthMovements &&
                        activeContracts
                            .filter((contract) => contract?.financialOperations?.lastMountMovements.size > 0)
                            .map((contract) => <FinOperationsOverviewItem key={contract.idObject} contract={contract} />)}
                </TabPanel>
            </Box>
        </InfoPanel>
    );
};
