import { Record } from "immutable";

const UniqaInsuranceRisk = Record({ id: null, msg: null });

export default Object.freeze({
    RISK_20XF00101: new UniqaInsuranceRisk({
        id: "20XF00101",
        msg: "claim.risk.20XF00101",
    }),
    RISK_20XF00102: new UniqaInsuranceRisk({
        id: "20XF00102",
        msg: "claim.risk.20XF00102",
    }),
    RISK_20XF00103: new UniqaInsuranceRisk({
        id: "20XF00103",
        msg: "claim.risk.20XF00103",
    }),
    RISK_20XF00104: new UniqaInsuranceRisk({
        id: "20XF00104",
        msg: "claim.risk.20XF00104",
    }),
    RISK_20XF00105: new UniqaInsuranceRisk({
        id: "20XF00105",
        msg: "claim.risk.20XF00105",
    }),
    RISK_20XF00106: new UniqaInsuranceRisk({
        id: "20XF00106",
        msg: "claim.risk.20XF00106",
    }),
    RISK_20XF00107: new UniqaInsuranceRisk({
        id: "20XF00107",
        msg: "claim.risk.20XF00107",
    }),
    RISK_20XF00108: new UniqaInsuranceRisk({
        id: "20XF00108",
        msg: "claim.risk.20XF00108",
    }),
    RISK_20XF00109: new UniqaInsuranceRisk({
        id: "20XF00109",
        msg: "claim.risk.20XF00109",
    }),

    RISK_20XF00201: new UniqaInsuranceRisk({
        id: "20XF00201",
        msg: "claim.risk.20XF00201",
    }),

    RISK_20XF00301: new UniqaInsuranceRisk({
        id: "20XF00301",
        msg: "claim.risk.20XF00301",
    }),
    RISK_20XF00302: new UniqaInsuranceRisk({
        id: "20XF00302",
        msg: "claim.risk.20XF00302",
    }),
    RISK_20XF00303: new UniqaInsuranceRisk({
        id: "20XF00303",
        msg: "claim.risk.20XF00303",
    }),

    RISK_20XF00401: new UniqaInsuranceRisk({
        id: "20XF00401",
        msg: "claim.risk.20XF00401",
    }),

    RISK_XF00501: new UniqaInsuranceRisk({
        id: "XF00501",
        msg: "claim.risk.XF00501",
    }),

    RISK_20XF00606: new UniqaInsuranceRisk({
        id: "20XF00606",
        msg: "claim.risk.20XF00606",
    }),
    RISK_20XF00607: new UniqaInsuranceRisk({
        id: "20XF00607",
        msg: "claim.risk.20XF00607",
    }),

    RISK_20XF00701: new UniqaInsuranceRisk({
        id: "20XF00701",
        msg: "claim.risk.20XF00701",
    }),

    RISK_20XF00731: new UniqaInsuranceRisk({
        id: "20XF00731",
        msg: "claim.risk.20XF00731",
    }),
    RISK_20XF00732: new UniqaInsuranceRisk({
        id: "20XF00732",
        msg: "claim.risk.20XF00732",
    }),
});
