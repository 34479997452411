import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

import useBreakpoints from "ui-library/theme/breakpoints";
import { Button, Typography } from "ui-library/atoms";
import { InfoPanel } from "ui-library/modules";
import { ContentBox } from "ui-library/layouts";

import i18n from "core/i18n";
import router from "core/router";
import { Pages, Tabs } from "routeConstants";

export const MarketingCommunicationInfoPanel: FC = () => {
    const { t } = i18n.useTranslation();
    const navigate = useNavigate();
    const breakpointMd = useBreakpoints("md");

    return (
        <InfoPanel
            droppable={!breakpointMd}
            bgVariant={"blueGradient"}
            icon={"like"}
            title={t("page.contractsList.marketing.title")}
            actionButton={
                <Button variant={"outlined"} color={"blue"} onClick={() => navigate(router.getStaticUrl(Pages.USER, Tabs.DATA_PROCESSING))}>
                    {t("common.doHaveInterest")}
                </Button>
            }
        >
            <ContentBox>
                <Typography color={"gray"} variant={"p"} markDown={t("page.contractsList.marketing.text")} />
            </ContentBox>
        </InfoPanel>
    );
};
