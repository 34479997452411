import React from "react";
import PropTypes from "prop-types";
import { ContentBox } from "ui-library/layouts";

import i18n from "core/i18n";
import { Field, InputText, maxValue100, numberCharacters, ReduxFormSection } from "core/form";
import { DdsSavingStrategyFund } from "enums";

import { DDS_CONTRIBUTORY_FIELD_NAME, DDS_GLOBAL_FIELD_NAME, DDS_INDEX_FIELD_NAME } from "../constants";

const PLACEHOLDER = "%";

const PercentageSelectDds = ({ sectionName }) => {
    const { t } = i18n.useTranslation();

    return (
        <ReduxFormSection name={sectionName}>
            <ContentBox>
                <Field
                    component={InputText}
                    name={DDS_GLOBAL_FIELD_NAME}
                    label={t(DdsSavingStrategyFund.GLOBAL.msg)}
                    placeholder={PLACEHOLDER}
                    normalize={[numberCharacters, maxValue100]}
                />
            </ContentBox>
            <ContentBox>
                <Field
                    component={InputText}
                    name={DDS_CONTRIBUTORY_FIELD_NAME}
                    label={t(DdsSavingStrategyFund.CONTRIBUTORY.msg)}
                    placeholder={PLACEHOLDER}
                    normalize={[numberCharacters, maxValue100]}
                />
            </ContentBox>
            <ContentBox>
                <Field
                    component={InputText}
                    name={DDS_INDEX_FIELD_NAME}
                    label={t(DdsSavingStrategyFund.INDEX.msg)}
                    placeholder={PLACEHOLDER}
                    normalize={[numberCharacters, maxValue100]}
                />
            </ContentBox>
        </ReduxFormSection>
    );
};

PercentageSelectDds.propTypes = {
    sectionName: PropTypes.string.isRequired,
};

export default PercentageSelectDds;
