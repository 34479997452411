import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import i18n from "core/i18n";
import { Field, form, InputPassword, meetPasswordCriteria, required } from "core/form";
import { Button, ErrorContent, Loading } from "ui-library/atoms";
import { BannerFlexbox, Box, Flexbox, GridItem, GridLayout } from "ui-library/layouts";

export const PasswordEpinFormContainer = ({ handleSubmit, canSubmit, submitting, selectors }) => {
    const { t } = i18n.useTranslation();
    const epinPasswordError = useSelector(selectors.getEpinPasswordError);

    const passwordEquals = (value, values) => {
        const newPassword = values.get("newPassword");
        const verificationPassword = values.get("verificationPassword");
        return newPassword === verificationPassword ? undefined : t("error.password.notEquals");
    };

    return (
        <>
            <Loading fullPage loading={submitting} />

            <BannerFlexbox>
                <GridLayout spacing={1}>
                    <GridItem xs={12} md={4.5}>
                        <Field
                            name="newPassword"
                            component={InputPassword}
                            size="large"
                            label={t("holder.newPassword")}
                            onEnter={handleSubmit}
                            validateStatic={[required, meetPasswordCriteria]}
                        />
                    </GridItem>

                    <GridItem xs={12} md={4.5}>
                        <Field
                            name="verificationPassword"
                            component={InputPassword}
                            size="large"
                            label={t("holder.verificationPassword")}
                            onEnter={handleSubmit}
                            validateStatic={[passwordEquals]}
                        />
                    </GridItem>

                    <GridItem xs={12} md={2}>
                        <Box mt={{ xs: 0, md: 0, lg: 3.625 }}>
                            <Button color="green" variant="contained" size={"large"} onClick={handleSubmit} disabled={!canSubmit}>
                                {t("common.continue")}
                            </Button>
                        </Box>
                    </GridItem>
                </GridLayout>
            </BannerFlexbox>

            {epinPasswordError && (
                <Flexbox alignItems={"top"} gap={2} mt={2}>
                    <ErrorContent content={epinPasswordError} />
                </Flexbox>
            )}
        </>
    );
};

PasswordEpinFormContainer.propTypes = {
    selectors: PropTypes.object.isRequired,
    canSubmit: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
};

export const createPasswordEpinForm = (formName, selectors) =>
    form(formName)((props) => <PasswordEpinFormContainer selectors={selectors} {...props} />);
