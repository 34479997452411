import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Button, Container, Typography } from "ui-library/atoms";
import { FormBlock } from "ui-library/modules";
import { ContentBox, Flexbox, Section } from "ui-library/layouts";

import i18n from "core/i18n";
import router from "core/router";
import { Pages, Tabs } from "routeConstants";

export const UnverifiedUserTab = () => {
    const { t } = i18n.useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const stepsBack = searchParams.get("type") ? -2 : -1;

    return (
        <>
            <Container maxWidth="md">
                <Section>
                    <Typography variant="h1">{t("holder.unverifiedTitle")}</Typography>
                </Section>
            </Container>
            <Container maxWidth="md">
                <FormBlock>
                    <ContentBox mb={6}>
                        <Typography component="p" markDown={t("userSettings.unverified.text")} />
                    </ContentBox>
                    <Flexbox gap={2} justifyContent="space-between">
                        {/* Back Button */}
                        <Button onClick={() => navigate(stepsBack)} variant="outlined" color="blue">
                            {t("common.back")}
                        </Button>

                        {/* Submit Button */}
                        <Button
                            onClick={() => navigate(router.getStaticUrl(Pages.USER, Tabs.VERIFICATION))}
                            variant="contained"
                            color="blue"
                        >
                            {t("userSettings.verifyUser.toVerification")}
                        </Button>
                    </Flexbox>
                </FormBlock>
            </Container>
            <ContentBox mb={10} />
        </>
    );
};
