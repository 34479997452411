import PropTypes from "prop-types";
import * as React from "react";
import { StyledFilterButton } from "ui-library/Styled";

export const FilterButton = ({ isActive, children, ...props }) => {
    return (
        <StyledFilterButton variant="outlined" endIcon={"filter"} $isActive={isActive}>
            {children}
        </StyledFilterButton>
    );
};

FilterButton.propTypes = {
    isActive: PropTypes.bool,
};
