import { createAction } from "@reduxjs/toolkit";

import { app } from "core/util";
import { ElectronicCommunicationCampaignApiResponse } from "types";

import { NAME } from "./constants";

export const setElectronicCommunicationCampaignData = app.createActionWithPayload<ElectronicCommunicationCampaignApiResponse>(
    `${NAME}/SET_ELECTRONIC_COMMUNICATION_CAMPAIGN_DATA`,
);

export const clear = createAction(`${NAME}/CLEAR`);
