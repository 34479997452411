import React, { FC, ReactNode } from "react";
import { Grid, GridProps } from "@mui/material";

type GridLayoutProps = {
    children: ReactNode;
    defaultSpacing?: boolean;
    spacing?: any;
};

export const GridLayout: FC<GridLayoutProps> = ({ children, defaultSpacing, spacing, ...props }) => {
    return (
        <Grid spacing={defaultSpacing ? { xs: 3, md: 4 } : spacing} container {...props}>
            {children}
        </Grid>
    );
};

type GridItemProps = {
    children: ReactNode;
} & GridProps;

export const GridItem: FC<GridItemProps> = ({ children, ...props }) => {
    return (
        <Grid item {...props}>
            {children}
        </Grid>
    );
};
