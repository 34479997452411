import { withTheme } from "@mui/styles";
import styled from "styled-components";
import { StyledBlock } from "ui-library/Styled/Layouts/Blocks";

export const StyledInfoBox = withTheme(styled(StyledBlock)`
    position: relative;
    width: 100%;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        min-width: 300px;
        max-width: 400px;
        text-align: center;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
        min-width: 100%;
    }
`);
