import { model } from "core/util";
import { ElectronicCommunicationCampaign as ElectronicCommunicationCampaignType, ElectronicCommunicationCampaignDto } from "types";

export const ElectronicCommunicationCampaign = model.createModel<ElectronicCommunicationCampaignDto, ElectronicCommunicationCampaignType>(
    (json) => ({
        contractNumber: json.contractNumber,
        productCrm: json.productCrm,
        email: json.email,
    }),
);
