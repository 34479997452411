import React, { FC } from "react";

import { LabelTextItem } from "ui-library/modules";

import i18n from "core/i18n";
import { dateTimeToLocalFullDate } from "core/formatters";
import userContracts from "core/userContracts";

import { DesktopTabletContractCardProps } from "./DesktopTabletContractCard";
import ContractOverview from "./ContractOverview";

export const MobileContractCard: FC<DesktopTabletContractCardProps> = ({ contract }) => {
    const { t } = i18n.useTranslation();
    const { showEndDate } = userContracts.useGetContractDerivedData(contract);

    return (
        <>
            <LabelTextItem label={t("common.beginDate")} text={dateTimeToLocalFullDate(contract.beginDate)} />
            {showEndDate ? (
                <LabelTextItem text={<ContractOverview contract={contract} />} />
            ) : (
                <LabelTextItem label={t("common.endDate")} text={dateTimeToLocalFullDate(contract.endDate)} />
            )}
        </>
    );
};
