import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Typography } from "ui-library/atoms";
import { GridItem, GridLayout } from "ui-library/layouts";
import { InfoPanel } from "ui-library/modules";

import i18n from "core/i18n";
import modal from "core/modal";
import user from "core/user";
import { useHasEditContractPermission } from "core/hooks";

import { USER_PASSWORD_FORM, USERNAME_FORM } from "../constants";
import { ModalPasswordForm, ModalUsernameForm } from "../form";

export const UserProfile = () => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();
    const username = useSelector(user.getUserAccount);
    const hasEditPermission = useHasEditContractPermission();

    const openModalForm = (formName) => dispatch(modal.openModalForm(formName));

    return (
        <InfoPanel icon="user-settings" title={t("holder.credentials")}>
            <GridLayout spacing={1} alignItems="flex-end">
                <GridItem xs={12} md={5} mb={3} mt={2}>
                    <Typography variant="h4">{t("holder.userName")}</Typography>
                    <Typography>{username ? username.username : "-"}</Typography>
                </GridItem>
                <GridItem xs={12} md={7} mb={3}>
                    {hasEditPermission && (
                        <Button onClick={() => openModalForm(USERNAME_FORM)} color="blue" variant="text">
                            {t("common.edit")}
                        </Button>
                    )}
                </GridItem>

                <GridItem xs={12} md={5} mb={3}>
                    <Typography variant="h4">{t("holder.userPassword")}</Typography>
                    <Typography>********</Typography>
                </GridItem>
                <GridItem xs={12} md={7} mb={3}>
                    {hasEditPermission && (
                        <Button onClick={() => openModalForm(USER_PASSWORD_FORM)} color="blue" variant="text">
                            {t("common.edit")}
                        </Button>
                    )}
                </GridItem>
            </GridLayout>

            {/* Modals */}
            <ModalPasswordForm />
            <ModalUsernameForm />
        </InfoPanel>
    );
};
