import { call, put } from "redux-saga/effects";
import { PayloadActionCreator } from "@reduxjs/toolkit/src/createAction";

import errorHandling from "core/errorHandling";
import router from "core/router";
import { formWrapper } from "core/form";
import { Pages, Tabs } from "routeConstants";
import { sentry } from "core/util";
import { ElectronicCommunicationCampaignApiResponse as ElectronicCommunicationCampaignApiResponseModel } from "model/campaign";
import { CampaignsDataStatusEnum, ElectronicCommunicationCampaignApiResponse } from "types";
import { campaignApi } from "serverApi";

import { FIELD_GUID, FIELD_ID_OBJECT } from "./constants";

export function* createSaga(
    formName: string,
    setDataAction: PayloadActionCreator<ElectronicCommunicationCampaignApiResponse>,
    guid: string,
    idObject: number,
) {
    try {
        yield call(formSaga(formName, setDataAction), guid, idObject);
    } catch (e) {
        sentry.captureException(e);
        yield put(errorHandling.addServiceError(formName, e.identifier));
    }
}

const formSaga = (formName: string, setDataAction: PayloadActionCreator<ElectronicCommunicationCampaignApiResponse>) =>
    // @ts-ignore we won't type sagas
    formWrapper(formName, {
        *initialize(guid, idObject) {
            yield put(errorHandling.removeServiceErrors(formName));

            return {
                [FIELD_GUID]: guid,
                [FIELD_ID_OBJECT]: idObject,
            };
        },
        *save(values) {
            try {
                const response = yield call(campaignApi.setElectronicCommunication, values);

                if (response.result === CampaignsDataStatusEnum.UPDATED) {
                    yield put(router.navigate(Pages.CAMPAIGN, Tabs.ELECTRONIC_COMMUNICATION_SUCCESS));
                } else {
                    yield put(setDataAction(response));
                }
            } catch (e) {
                // Set Error as result.
                const errorResult = ElectronicCommunicationCampaignApiResponseModel.fromServer({ result: CampaignsDataStatusEnum.ERROR });
                yield put(setDataAction(errorResult));
            }
        },
    });
