import React from "react";

import { Pages } from "routeConstants";
import { ProfitDetailTable as ProfitDetail } from "containers";

import { getIdObject, getSelectedProfitDetail } from "../selectors";

export const ProfitDetailTable = () => (
    <ProfitDetail
        getProfitDetail={getSelectedProfitDetail}
        getIdObject={getIdObject}
        topPage={Pages.CONTRACT_REGULAR}
        isRegularContractType
    />
);
