import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { fromJS } from "immutable";

import { ContentBox } from "ui-library/layouts";
import { Typography } from "ui-library/atoms";

import i18n from "core/i18n";
import autocomplete from "core/autocomplete";
import { Field, required, SingleSelect } from "core/form";

import {
    ADDRESS_AUTOCOMPLETE,
    ADDRESS_SECTION,
    BANK_ACCOUNT_SECTION,
    PAYMENT_TYPE_ACCOUNT,
    PAYMENT_TYPE_ADDRESS,
    PAYMENT_TYPE_FIELD,
} from "../constants";
import { getPaymentTypeValue } from "../selectors";

import { AccountSection, AddressSection } from "./paymentSettings";

const paymentOptions = (t) => {
    const options = [];
    options.push({ label: t("form.pensionRedeemSavings.paymentType.address"), value: PAYMENT_TYPE_ADDRESS });
    options.push({ label: t("form.pensionRedeemSavings.paymentType.account"), value: PAYMENT_TYPE_ACCOUNT });
    return fromJS(options);
};

const AddressAutocomplete = autocomplete.createSingle(ADDRESS_AUTOCOMPLETE);

export const PaymentSettingsSection = ({ formName }) => {
    const { t } = i18n.useTranslation();

    const paymentType = useSelector(getPaymentTypeValue(formName));

    return (
        <>
            <ContentBox mt={4}>
                <Typography variant="h4" markDown={t("form.pensionRedeemSavings.payment")} />
            </ContentBox>

            <ContentBox>
                <Field name={PAYMENT_TYPE_FIELD} component={SingleSelect} options={paymentOptions(t)} validateStatic={[required]} />
            </ContentBox>

            <ContentBox>
                {paymentType === PAYMENT_TYPE_ADDRESS && (
                    <AddressSection sectionName={ADDRESS_SECTION} addressAutocomplete={AddressAutocomplete} />
                )}
                {paymentType === PAYMENT_TYPE_ACCOUNT && <AccountSection sectionName={BANK_ACCOUNT_SECTION} />}
            </ContentBox>
        </>
    );
};

PaymentSettingsSection.propTypes = {
    formName: PropTypes.string.isRequired,
};
