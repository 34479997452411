import React, { FC } from "react";
import { useSelector } from "react-redux";
import userContracts from "core/userContracts";
import { Block } from "ui-library/atoms";
import { Flexbox, GridItem, GridLayout } from "ui-library/layouts";
import { ViewType } from "ui-library/modules/enums";
import useBreakpoints from "ui-library/theme/breakpoints";

import { ContractListContentType } from "../constants";
import { ContractBox } from "./ContractBox";
import { EmptyContractsInfo } from "../components";

export interface ContractsProps {
    displayedContentType: ContractListContentType;
    viewType: ViewType;
}

const Contracts: FC<ContractsProps> = ({ displayedContentType, viewType }) => {
    const isFullWidth = useBreakpoints("md");

    const activeContractIds = useSelector(userContracts.getActiveContractIds) || [];
    const inactiveContractIds = useSelector(userContracts.getInactiveContractIds) || [];
    const contractsToDisplay: number[] =
        displayedContentType === ContractListContentType.ACTIVE_CONTRACTS ? activeContractIds : inactiveContractIds;

    // Empty contracts list.
    if (!contractsToDisplay) {
        return <EmptyContractsInfo displayedContentType={displayedContentType} />;
    }

    // Desktop / tablet version.
    if (isFullWidth) {
        return (
            <Flexbox gap={2}>
                <GridLayout defaultSpacing>
                    {contractsToDisplay.map((idObject) => (
                        <GridItem key={idObject} display={"flex"} xs={viewType === ViewType.BASE ? 12 : 6}>
                            <ContractBox idObject={idObject} viewType={viewType} displayedContentType={displayedContentType} />
                        </GridItem>
                    ))}
                </GridLayout>
            </Flexbox>
        );
    }

    // Mobile version.
    return (
        <Block variant="white">
            {contractsToDisplay.map((idObject) => (
                <ContractBox
                    isMobileView
                    idObject={idObject}
                    key={idObject}
                    viewType={viewType}
                    displayedContentType={displayedContentType}
                />
            ))}
        </Block>
    );
};

export default Contracts;
