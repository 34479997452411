export const PRODUCT_LINE_FIELD = "productGroupId";

export const CANCEL_FIELD_ID = "cancelFieldId";
export const TYPE_FIELD = "type";
export const MEANS_FIELD = "means";

export const ACCOUNT_OWNER_IDENTIFICATION_SECTION = "accountOwnerIdentification";
export const IS_ACCOUNT_OWNER_CONTRACT_OWNER_FIELD = "isAccountOwnerContractOwner";
export const IS_ACCOUNT_OWNER_COMPANY_FIELD = "isAccountOwnerCompany";
export const COMPANY_NAME_FIELD = "companyName";
export const FULL_NAME_FIELD = "fullName";
export const BIRTH_NUMBER_FIELD = "birthNumber";
export const DATE_OF_BIRTH_FIELD = "dateOfBirth";
export const COMPANY_ID_FIELD = "companyId";
export const DONT_HAVE_BIRTH_NUMBER_FIELD = "dontHaveBirthNumber";
