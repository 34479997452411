import { model } from "core/util";
import {
    PensionContractAdditionalInformation as PensionContractAdditionalInformationType,
    PensionContractAdditionalInformationDto,
} from "types";

import { PensionRedeemInvestmentsOption } from "./PensionRedeemInvestmentsOption";
import { BankAccount, SimpleCodeValue } from "../../common";

export const PensionContractAdditionalInformation = model.createModel<
    PensionContractAdditionalInformationDto,
    PensionContractAdditionalInformationType
>((json) => ({
    age: json.age,
    redeemInvestmentsOptions: PensionRedeemInvestmentsOption.fromServerList(json.redeemInvestmentsOptions),
    account: BankAccount.fromServer(json.account),
    insuranceCompanies: SimpleCodeValue.fromServerList(json.insuranceCompanies),
}));
