import { Icon } from "ui-library/atoms/Icons/Icon";
import { Typography } from "ui-library/atoms/Typography/Typography";
import * as React from "react";
import { StyledBubbleAccordion, StyledBubbleAccordionSummary, StyledBubbleAccordionDetails } from "ui-library/Styled";
import PropTypes from "prop-types";

export const BubbleAccordion = ({ id, data, ...props }) => {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <>
            {data
                ? data.map(({ title, children }, i) => (
                      <StyledBubbleAccordion {...props} expanded={expanded === id + i} onChange={handleChange(id + i)} key={i}>
                          <StyledBubbleAccordionSummary
                              key={i}
                              expandIcon={
                                  expanded === id + i ? (
                                      <Icon size={12} icon="chevron-up" color="blue" />
                                  ) : (
                                      <Icon size={12} icon="chevron-right" color="blue" />
                                  )
                              }
                              aria-controls={id + i}
                              id={id + i}
                          >
                              <Typography variant="p" component="p">
                                  {title}
                              </Typography>
                          </StyledBubbleAccordionSummary>
                          <StyledBubbleAccordionDetails>
                              <Typography listVariant="check" variant="p" component="p" markDown={children} />
                          </StyledBubbleAccordionDetails>
                      </StyledBubbleAccordion>
                  ))
                : ""}
        </>
    );
};

BubbleAccordion.propTypes = {
    id: PropTypes.any.isRequired,
    data: PropTypes.array,
};
