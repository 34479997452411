import { Record } from "immutable";

class MutualFundsStatementFrequencySetting extends Record({ id: null, msg: null }) {}

export default Object.freeze({
    QUARTERLY: new MutualFundsStatementFrequencySetting({
        id: "QUARTERLY",
        msg: "common.quarterly",
    }),
    BIANNUALLY: new MutualFundsStatementFrequencySetting({
        id: "BIANNUALLY",
        msg: "common.halfYearly",
    }),
});
