import { createSelector } from "reselect";

import pageContext from "core/pageContext";

import { FeatureFlag } from "types";
import { Countries } from "enums";

import { AuthState, NAME } from "./constants";

const getModel = (state): AuthState => state.get(NAME);

export const isLoggedIn = (state) => getModel(state).logged;

export const getInactiveInterval = (state) => getModel(state).inactiveInterval;

export const getLoginUnsuccessfulReason = (state) => getModel(state).loginUnsuccessfulReason;

export const getIsSsoLoginEnabled: (state) => boolean = createSelector(
    pageContext.getFeatureFlagEnabled(FeatureFlag.SK_SSO_LOGIN),
    pageContext.getFeatureFlagEnabled(FeatureFlag.CZ_SSO_LOGIN),
    pageContext.getSiteId,
    (skSsoFeatureFlag, czSsoFeatureFlag, siteId) =>
        (siteId === Countries.SK.id && skSsoFeatureFlag) || (siteId === Countries.CZ.id && czSsoFeatureFlag),
);
