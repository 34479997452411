import { tableFilter } from "modules/tableFilter";

import { NAME } from "./constants";
import { FinancialOperationsAdvancedFilter, FinancialOperationsBasicFilter, financialOperationsDefaultOperators } from "./filters";

export const FinancialOperationsTableFilter = tableFilter({
    actionPrefix: `${NAME}/financialOperationsFilter`,
    reducerPath: [NAME, "appState", "financialOperationsFilter"],
    AdvancedFilter: FinancialOperationsAdvancedFilter,
    BasicFilter: FinancialOperationsBasicFilter,
    defaultOperators: financialOperationsDefaultOperators,
});
