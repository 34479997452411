import { NAME } from "./constants";

export const ACCEPT = `${NAME}/ACCEPT`;
export const SET_DISCLAIMER_STEP = `${NAME}/SET_DISCLAIMER_STEP`;
export const CLEAR = `${NAME}/CLEAR`;

export const accept = () => ({
    type: ACCEPT,
});

export const setDisclaimerStep = (step) => ({
    type: SET_DISCLAIMER_STEP,
    payload: step,
});

export const clear = () => ({
    type: CLEAR,
});
