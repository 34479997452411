import { fromJS } from "immutable";

import { TableFilterOperator } from "enums";

import { GROSS_AMOUNT_COLUMN, INVESTMENT_FUND_COLUMN, PAYMENT_DATE_COLUMN, TYPE_COLUMN } from "../constants";

export const financialOperationsDefaultOperators = fromJS({
    fund: { column: INVESTMENT_FUND_COLUMN, operator: TableFilterOperator.CODE_VALUE_EQUALS },
    dateFrom: { column: PAYMENT_DATE_COLUMN, operator: TableFilterOperator.AFTER },
    dateTo: { column: PAYMENT_DATE_COLUMN, operator: TableFilterOperator.BEFORE },
    type: { column: TYPE_COLUMN, operator: TableFilterOperator.CODE_VALUE_EQUALS },
    amount: { column: GROSS_AMOUNT_COLUMN, operator: "" },
});
