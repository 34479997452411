import { fetch } from "core/util";
import { API_PREFIX } from "app/constants";
import { PensionDps, PensionDpsFinancialOperation, PensionDpsFinancialStatement } from "model/pension/dps";
import { PensionContractAdditionalInformation } from "model/pension/additionalInfomation";

const productUriPrefix = `${API_PREFIX}/product/pension-dps`;
const getPensionDpsContractDetail = (idObject) =>
    fetch.doGet(`${productUriPrefix}/${idObject}/contract-detail`, null, PensionDps.fromServer);
const getPensionDpsContractAdditionalInfo = (idObject) =>
    fetch.doGet(`${productUriPrefix}/${idObject}/additional-information`, null, PensionContractAdditionalInformation.fromServer);
const getPensionDpsFinancialOperations = (idObject) =>
    fetch.doGet(`${productUriPrefix}/${idObject}/financial-operations`, null, PensionDpsFinancialOperation.fromServerList);
const getPensionDpsFinancialStatement = (idObject) =>
    fetch.doGet(`${productUriPrefix}/${idObject}/financial-statement`, null, PensionDpsFinancialStatement.fromServer);
const updateBeneficiaries = (body) => fetch.doPut(`${productUriPrefix}/update-beneficiaries`, body);
const updateStatementSettings = (body) => fetch.doPut(`${productUriPrefix}/statement-settings`, body);
const putRedeemSavings = (values) => fetch.doPut(`${productUriPrefix}/redeem-savings`, values);

const pensionDpsApi = {
    getPensionDpsContractDetail,
    getPensionDpsContractAdditionalInfo,
    getPensionDpsFinancialOperations,
    getPensionDpsFinancialStatement,
    updateBeneficiaries,
    updateStatementSettings,
    putRedeemSavings,
};

export default pensionDpsApi;
