import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Block, Option, Select, Typography } from "ui-library/atoms";
import { Box, ContentBox, Flexbox, GridItem } from "ui-library/layouts";
import { InfoPanel, LabelTextItem } from "ui-library/modules";

import i18n from "core/i18n";
import router from "core/router";

import { setDetailSelectedInsured } from "../actions";
import { getDetailSelectedInsured, getInsuredsSelectOptions, getSelectedRisk } from "../selectors";
import { RiskType } from "enums";
import { NonLifeInsuranceCoverage } from "containers/contract";
import { Pages, Tabs } from "routeConstants";

export const InsuredTravelPersonGridItem: FC = () => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();

    const { idObject } = useParams();
    const insuredsSelectOptions = useSelector(getInsuredsSelectOptions);
    const actualChooseSelection = useSelector(getDetailSelectedInsured);
    const selectedRisk = useSelector(getSelectedRisk);
    const link = router.getStaticUrl(Pages.CONTRACT_TRAVEL, Tabs.DOCUMENTS, { idObject });

    return (
        <GridItem xs={12}>
            <InfoPanel
                icon={"user-check"}
                title={
                    <Flexbox alignItems="center" gap={1}>
                        {t("insurance.coverages")}
                    </Flexbox>
                }
            >
                <Box>
                    {/* Select */}
                    <ContentBox>
                        <Select
                            value={actualChooseSelection}
                            onChange={(event) => dispatch(setDetailSelectedInsured(event.target.value))}
                            placeholder={t("common.chooseInsurance")}
                            label={t("insurance.insurance")}
                            id="selectOther"
                            fullWidth
                        >
                            {actualChooseSelection && (
                                <Option key="" value="">
                                    {t("common.noneSelected")}
                                </Option>
                            )}
                            {insuredsSelectOptions &&
                                insuredsSelectOptions.map((selectOption) => (
                                    <Option key={selectOption.value} value={selectOption.value}>
                                        {selectOption.label}
                                    </Option>
                                ))}
                        </Select>
                    </ContentBox>

                    {selectedRisk && selectedRisk.riskType === RiskType.PERSON && (
                        <Block variant="grayWhite">
                            {selectedRisk.get("coverages").map((coverage) => (
                                <ContentBox mb={3}>
                                    <LabelTextItem
                                        minWidthLabel
                                        label={t("insurance.insuranceAmountLimit")}
                                        text={coverage.computedInsuranceLimit}
                                    />
                                    <LabelTextItem minWidthLabel label={t("common.description")} text={coverage.coverageText} />
                                </ContentBox>
                            ))}
                        </Block>
                    )}

                    {selectedRisk && selectedRisk.riskType === RiskType.PROPERTY && (
                        <>
                            <NonLifeInsuranceCoverage productPackage={selectedRisk} />
                            <ContentBox mt={3}>
                                <Typography markDown={t("insurance.coveragesNote1")} />
                            </ContentBox>
                            <ContentBox>
                                <Typography markDown={t("insurance.lifeCoveragesNote2", { link })} internalAppLink />
                            </ContentBox>
                        </>
                    )}
                </Box>
            </InfoPanel>
        </GridItem>
    );
};
