import { fetch } from "core/util";
import { API_PREFIX } from "app/constants";
import { CodeValueLegacy } from "model/common";

const URL_PREFIX = `${API_PREFIX}/enum`;

const getBanksList = () => fetch.doGet(`${URL_PREFIX}/banks`, null, CodeValueLegacy.fromServerList);

export default {
    getBanksList,
};
