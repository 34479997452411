import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Option, Select, Typography } from "ui-library/atoms";
import { Box, ContentBox, Flexbox, GridItem } from "ui-library/layouts";
import { InfoPanel } from "ui-library/modules";

import i18n from "core/i18n";
import router from "core/router";
import { NonLifeInsuranceCoverage } from "containers/contract";
import { Pages, Tabs } from "routeConstants";

import { setDetailSelectedCoverage } from "../actions";
import { getDetailSelectedCoverage, getInsuredsSelectOptions, getSelectedRisk } from "../selectors";

export const InsuranceCoverageGridItem = () => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();

    const { idObject } = useParams();
    const insuredsSelectOptions = useSelector(getInsuredsSelectOptions);
    const actualChooseSelection = useSelector(getDetailSelectedCoverage);
    const selectedRisk = useSelector(getSelectedRisk);
    const link = router.getStaticUrl(Pages.CONTRACT_HOME, Tabs.DOCUMENTS, { idObject });
    return (
        <GridItem xs={12}>
            <InfoPanel
                icon={"user-check"}
                title={
                    <Flexbox alignItems="center" gap={1}>
                        {t("insurance.coverages")}
                    </Flexbox>
                }
            >
                <Box>
                    <ContentBox>
                        <Select
                            value={actualChooseSelection}
                            onChange={(event) => dispatch(setDetailSelectedCoverage(event.target.value))}
                            placeholder={t("common.chooseInsurance")}
                            label={t("insurance.insurance")}
                            id="selectOther"
                            fullWidth
                        >
                            {actualChooseSelection && (
                                <Option key="" value="">
                                    {t("common.noneSelected")}
                                </Option>
                            )}
                            {insuredsSelectOptions &&
                                insuredsSelectOptions.map((selectOption) => (
                                    <Option key={selectOption.value} value={selectOption.value}>
                                        {selectOption.label}
                                    </Option>
                                ))}
                        </Select>
                    </ContentBox>
                    {selectedRisk && (
                        <>
                            <NonLifeInsuranceCoverage productPackage={selectedRisk} />
                            <ContentBox mt={3}>
                                <Typography markDown={t("insurance.coveragesNote1")} />
                            </ContentBox>
                            <ContentBox>
                                <Typography markDown={t("insurance.coveragesNote2", { link })} internalAppLink />
                            </ContentBox>
                        </>
                    )}
                </Box>
            </InfoPanel>
        </GridItem>
    );
};
