import { NAME } from "./constants";

export const SET_CLIENT_DOCUMENTS = `${NAME}/SET_CLIENT_DOCUMENTS`;
export const SET_DISPLAYED_CONTENT_TYPE = `${NAME}/SET_DISPLAYED_CONTENT_TYPE`;
export const CLEAR_DISPLAYED_CONTENT_TYPE = `${NAME}/CLEAR_DISPLAYED_CONTENT_TYPE`;
export const SET_CONTRACT_ID_FOR_PAYMENT = `${NAME}/SET_CONTRACT_ID_FOR_PAYMENT`;

export const setClientDocuments = (clientDocuments, error = false) => ({
    type: SET_CLIENT_DOCUMENTS,
    meta: { error },
    payload: clientDocuments,
});

export const clearDisplayedContentType = () => ({
    type: CLEAR_DISPLAYED_CONTENT_TYPE,
});

export const setDisplayedContentType = (contentType) => ({
    type: SET_DISPLAYED_CONTENT_TYPE,
    payload: contentType,
});

export const setContractIdForPayment = (contractId) => ({
    type: SET_CONTRACT_ID_FOR_PAYMENT,
    payload: contractId,
});
