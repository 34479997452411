import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";

import i18n from "core/i18n";
import { Box, Flexbox } from "ui-library/layouts";
import { Button } from "ui-library/atoms";
import { Dropdown, DropdownItem } from "ui-library/modules";

export interface ContractCardActionButtonsQuickLink {
    msg: string;
    url: string;
}

export interface ContractCardActionButtonsProps {
    detailLink: string;
    quickAccessLinks?: ContractCardActionButtonsQuickLink[];
}

export const ContractCardActionButtons: FC<ContractCardActionButtonsProps> = ({ detailLink, quickAccessLinks }) => {
    const { t } = i18n.useTranslation();
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleDropdownClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleDropdownClose = () => {
        setAnchorEl(null);
    };

    const handleNavigate = (path) => {
        navigate(path);
        handleDropdownClose();
    };

    return (
        <Flexbox gap={1}>
            <Box>
                <Button
                    onClick={() => {
                        navigate(detailLink);
                    }}
                    color="blue"
                    variant="contained"
                    className="contract-detail"
                >
                    {t("common.detail")}
                </Button>
            </Box>
            {quickAccessLinks && (
                <Box>
                    <Button startIcon={"eclipse-vertical"} variant={"outlined"} color={"blue"} onClick={handleDropdownClick}>
                        {t("contractList.quickActions")}
                    </Button>
                    <Dropdown position={"left"} anchorEl={anchorEl} open={open} onClose={handleDropdownClose}>
                        {quickAccessLinks.map((link) => {
                            return (
                                <DropdownItem
                                    key={uuid()}
                                    onClick={() => {
                                        handleNavigate(link.url);
                                    }}
                                >
                                    {t(link.msg)}
                                </DropdownItem>
                            );
                        })}
                    </Dropdown>
                </Box>
            )}
        </Flexbox>
    );
};
