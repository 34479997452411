import { model } from "core/util";

import { Holder } from "model/holder";
import { ElectronicCommunication } from "model/electronicCommunication";
import { MutualFundsDirectContractDetail as MutualFundsDirectContractDetailType, MutualFundsDirectContractDetailDto } from "types";

import { MutualFundsAccountPaymentDetail } from "../MutualFundsAccountPaymentDetail";
import { MutualFundsContractParameters } from "../MutualFundsContractParameters";
import { MutualFundsClientStatementSettings } from "../MutualFundsClientStatementSettings";

export const MutualFundsDirectContractDetail = model.createModel<MutualFundsDirectContractDetailDto, MutualFundsDirectContractDetailType>(
    (json) => ({
        contractParameters: MutualFundsContractParameters.fromServer(json.ContractParameters),
        holder: Holder.fromServer(json.Holder),
        mutualFundPayments: MutualFundsAccountPaymentDetail.fromServerList(json.MutualFundPayments),
        electronicCommunication: ElectronicCommunication.fromServer(json.ElectronicCommunication),
        clientStatementSettings: MutualFundsClientStatementSettings.fromServer(json.ClientStatementSettings),
        shouldCheckIbanCountry: json.ShouldCheckIbanCountry,
    }),
);
