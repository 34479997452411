import React, { FC } from "react";

import i18n from "core/i18n";
import { GridItem } from "ui-library/layouts";
import { InfoPanel } from "ui-library/modules";
import { StandaloneLink } from "ui-library/atoms";

import { PieChartWithFundsTable } from "./PieChartWithFundsTable";

type InvestmentsOverviewGridItemProps = {
    detailLink: string;
};

export const InvestmentsOverviewGridItem: FC<InvestmentsOverviewGridItemProps> = ({ detailLink }) => {
    const { t } = i18n.useTranslation();

    return (
        <GridItem display={"flex"} xs={12} md={12}>
            <InfoPanel
                icon={"chart-line"}
                title={t("insurance.investmentStateBasedOnFunds")}
                headerAction={
                    <StandaloneLink to={detailLink} color="blue">
                        {t("common.detail")}
                    </StandaloneLink>
                }
            >
                <PieChartWithFundsTable />
            </InfoPanel>
        </GridItem>
    );
};
