import { fn } from "core/util";
import { Address } from "types";

import { getLocalizedCountryCode } from "./getLocalizedCountryCode";
import { TFunction } from "../i18n";

export const getLocalizedOneLineAddressFull = (t: TFunction, address: Address) => {
    let result = "";

    if (address) {
        if (address.street) {
            result += address.street;
        }

        if (address.descriptionNumber) {
            if (result.length > 0) {
                result += " ";
            }
            result += address.descriptionNumber;
        }

        if (address.orientationNumber) {
            if (address.descriptionNumber) {
                result += "/";
            } else if (result.length > 0) {
                result += " ";
            }
            result += address.orientationNumber;
        }

        if (address.city) {
            if (result.length > 0) {
                result += ", ";
            }
            result += address.city;
        }

        if (address.postalCode) {
            if (result.length > 0) {
                result += ", ";
            }
            result += address.postalCode;
        }
        if (address.countryCode) {
            const localizedCountry = getLocalizedCountryCode(t, address.countryCode);
            if (!fn.isEmpty(localizedCountry)) {
                if (result.length > 0) {
                    result += ", ";
                }
                result += localizedCountry;
            }
        }
    }
    return result;
};
