import React, { FC } from "react";
import { useSelector } from "react-redux";

import { Button, Container, StandaloneHtmlLink, StandaloneLink, Typography } from "ui-library/atoms";
import { Box, Flexbox } from "ui-library/layouts";
import { StyledFooter } from "ui-library/Styled";

import auth from "core/auth";
import i18n from "core/i18n";
import router from "core/router";
import { useGetSiteValue } from "core/hooks";
import { ExternalUrl } from "enums";
import { Pages } from "routeConstants";

export const Footer: FC = () => {
    const { t } = i18n.useTranslation();
    const isLogged = useSelector(auth.isLoggedIn);
    const getSiteValue = useGetSiteValue();
    const externalDislaimerUrl = getSiteValue(ExternalUrl.DISCLAIMER_PUBLIC);

    return (
        <StyledFooter>
            <Container>
                <Flexbox
                    className={"footer-content"}
                    flexDirection={{ xs: "column", md: "row" }}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <Flexbox mb={{ xs: 3, md: 0 }} justifyContent={"space-between"} width={{ xs: "100%", md: "auto" }}>
                        <Box display={"inline"} mr={{ xs: 0, md: 4 }}>
                            <Typography variant="p">{t("footer.copyright")}</Typography>
                        </Box>

                        {isLogged ? (
                            <StandaloneLink color="gray" to={router.getStaticUrl(Pages.DISCLAIMER)}>
                                {t("footer.termsAndConditions")}
                            </StandaloneLink>
                        ) : (
                            <StandaloneHtmlLink href={externalDislaimerUrl} target="_blank">
                                {t("footer.privacyPolicy.public")}
                            </StandaloneHtmlLink>
                        )}
                    </Flexbox>
                    <Box>
                        <Flexbox sx={{ gap: "8px" }}>
                            <Button
                                color="blue"
                                variant="outlined"
                                startIcon="social-facebook"
                                externalLink={getSiteValue(ExternalUrl.FACEBOOK)}
                                newTab
                            />
                            <Button
                                color="blue"
                                variant="outlined"
                                startIcon="social-twitter-x"
                                externalLink={getSiteValue(ExternalUrl.TWITTER)}
                                newTab
                            />
                            <Button
                                color="blue"
                                variant="outlined"
                                startIcon="social-youtube"
                                externalLink={getSiteValue(ExternalUrl.YOUTUBE)}
                                newTab
                            />
                        </Flexbox>
                    </Box>
                </Flexbox>
            </Container>
        </StyledFooter>
    );
};
