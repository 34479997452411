import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

import i18n from "core/i18n";
import router from "core/router";
import userContracts from "core/userContracts";
import products from "core/products";

import { Tabs } from "routeConstants";
import { Button } from "ui-library/atoms";

const getPolicyHolderLink = (topLevelRoute, idObject) => {
    if (!topLevelRoute || !idObject) {
        return null;
    }
    return router.getStaticUrl(topLevelRoute, Tabs.DETAIL, { idObject });
};

type BackToPolicyDetailButtonProps = {
    idObject: number;
};

export const BackToPolicyDetailButton: FC<BackToPolicyDetailButtonProps> = ({ idObject }) => {
    const { t } = i18n.useTranslation();
    const navigate = useNavigate();

    const contract = userContracts.useGetContractById(idObject);

    // For cases when rendering of form is sooner that store contains idObject. Happens on browser back.
    if (!contract) {
        return null;
    }

    const productDefinition = products.useGetProductDefinition(contract?.idEnuProductGroupLevelTwo);
    const backToPolicyDetailLink = getPolicyHolderLink(productDefinition.topLevelRoute, idObject);

    return (
        <Button onClick={() => navigate(backToPolicyDetailLink)} variant="outlined" color="blue">
            {t("common.back")}
        </Button>
    );
};
