import React from "react";
import { useSelector } from "react-redux";

import { GridItem } from "ui-library/layouts";
import { Loading, Typography } from "ui-library/atoms";
import { InfoPanel, PieChart } from "ui-library/modules";

import i18n from "core/i18n";
import { formatPieChartData } from "core/formatters";
import entity from "core/entity";
import fetching from "core/fetching";

import { getFinancialStatementChartData } from "../selectors";
import { ENTITY_FINANCIAL_STATEMENT } from "../constants";

export const InvestmentsOverviewPieChartGridItem = () => {
    const { t } = i18n.useTranslation();
    const isStatementLoading = useSelector(fetching.isFetching(ENTITY_FINANCIAL_STATEMENT, true));
    const isStatementError = useSelector(entity.isError(ENTITY_FINANCIAL_STATEMENT));
    const chartData = useSelector(getFinancialStatementChartData);

    return (
        <GridItem xs={12}>
            <InfoPanel icon={"chart-line"} title={t("investments.investmentsOverview")}>
                <Loading loading={isStatementLoading}>
                    {isStatementError && <Typography>{t("error.sectionUnavailable")}</Typography>}
                    {!isStatementError && (
                        <PieChart
                            data={formatPieChartData(chartData.get("sumValue"), chartData.get("items"))}
                            legendTitle={t("insurance.funds")}
                            legendValue={chartData.get("sumAmount")}
                        />
                    )}
                </Loading>
            </InfoPanel>
        </GridItem>
    );
};
