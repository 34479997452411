import * as React from "react";
import Box from "@mui/material/Box";

import PropTypes from "prop-types";
import { StyledMasonry } from "ui-library/Styled";

export const MasonryGrid = ({ columns, spacing, ...props }) => {
    return (
        <Box>
            <StyledMasonry columns={columns} spacing={spacing}>
                {props.data ? props.data.map((item, i) => <Box key={i}>{item.children}</Box>) : props.children}
            </StyledMasonry>
        </Box>
    );
};

MasonryGrid.propTypes = {
    columns: PropTypes.object,
    spacing: PropTypes.number,
};

MasonryGrid.defaultProps = {
    columns: { md: 1, lg: 2 },
    spacing: 4,
};
