import type { Selector } from "@reduxjs/toolkit";
import { nanoid } from "@reduxjs/toolkit";
import { List, Map } from "immutable";
import { createSelector } from "reselect";

import i18n from "core/i18n";
import entity from "core/entity";
import { DEFAULT_EMPTY_PARAM } from "app/constants";
import { app, date, DEFAULT_CHART_COLOR, model } from "core/util";
import { formatLocalizedValueMap, localAmountWithCurrencyLegacy } from "core/formatters";
import { DdsDssFundTypes, DssFunds, PensionDssFundsChangeType } from "enums";
import { PensionDssDdsFinancialStatement } from "model/pension";
import {
    ChangeResponseType,
    ElectronicCommunication,
    Holder,
    PaymentsScheduler,
    PensionBeneficiary,
    PensionDssAccountDetail,
    PensionDssContract,
    PensionDssDdsFundInvestmentStrategy,
    Pis,
    State,
} from "types";

import { ENTITY_FINANCIAL_OPERATIONS, ENTITY_FINANCIAL_STATEMENT, NAME } from "./constants";
import userContracts from "core/userContracts";

/** contract data */
const getModel = app.createGetModel(NAME);
const getData = (state) => getModel(state).get("data");

export const getPensionDssContractDetail: Selector<State, PensionDssContract> = (state) => getData(state).pensionDssContractDetail;

export const getIdObject: Selector<State, number> = (state) => getPensionDssContractDetail(state).idObject || DEFAULT_EMPTY_PARAM;
export const getPolicyBeginDateYear = (state) => date.getYearFromStringDateTime(getPolicy(state)?.beginDate);
export const getPolicyHolder: Selector<State, Holder> = (state) => getPensionDssContractDetail(state).contractDetail.holder;
export const getElectronicCommunication: Selector<State, ElectronicCommunication> = (state) =>
    getPensionDssContractDetail(state).contractDetail.electronicCommunication;
export const isElectronicCommunicationSet: Selector<State, boolean> = (state) =>
    getPensionDssContractDetail(state).contractDetail.electronicCommunication.electronicCommunicationSet || false;
export const getPensionAccountDetail: Selector<State, PensionDssAccountDetail> = (state) =>
    getPensionDssContractDetail(state).contractDetail.pensionDssAccountDetail;
export const getPaymentsScheduler: Selector<State, PaymentsScheduler> = (state) => getPensionAccountDetail(state).paymentsScheduler;
export const getStatementPassword: Selector<State, string> = (state) => getPensionAccountDetail(state).statementPassword;

export const getPis: Selector<State, Pis> = (state) => getPensionAccountDetail(state).pis;
export const getDisplayEnableButton: Selector<State, boolean> = (state) => getPis(state).displayEnableButton;
export const getBeneficiaries: Selector<State, PensionBeneficiary[]> = (state) =>
    getPensionDssContractDetail(state).contractDetail.beneficiaries;
export const getFundsInvestmentStrategies: Selector<State, PensionDssDdsFundInvestmentStrategy[]> = (state) =>
    getPensionAccountDetail(state).fundsInvestmentStrategies;
export const getFundsInvestmentVoluntaryStrategies: Selector<State, PensionDssDdsFundInvestmentStrategy[]> = (state) =>
    getPensionAccountDetail(state).fundsInvestmentVoluntaryStrategies;

export const getInvestmentStrategy: Selector<State, string> = (state) => getPensionAccountDetail(state).investmentStrategy;
// export const

// Custom selectors
export const getFundsChangeFormChangeType: Selector<State, String> = (state) => getData(state).fundsChangeFormChangeType;
export const getPisFormChangeResult: Selector<State, ChangeResponseType> = (state) => getData(state).pisFormChangeResult;
// Entity
export const getPensionDssFinancialStatementEntity = (state) =>
    // @ts-ignore
    entity.getDataSelector(state, ENTITY_FINANCIAL_STATEMENT, PensionDssDdsFinancialStatement.fromServer());
export const getPensionDssContractBalanceSheet = (state) => getPensionDssFinancialStatementEntity(state).get("contractBalanceSheet");

// @ts-ignore
export const getFinancialOperationsEntity = (state) => entity.getDataSelector(state, ENTITY_FINANCIAL_OPERATIONS, new List());

/** user data */
export const getPolicy = createSelector(userContracts.getContractNormalizedData, getIdObject, (policies, idObject) =>
    idObject ? policies[idObject] : null,
);

const getFunds = i18n.createGetLocalizedEnum(DssFunds, "fundLink");
const getFundTypes = i18n.createGetLocalizedEnumMap(DdsDssFundTypes);

const getFinancialStatementChartItems = (financialStatement, funds, fundTypes, locale) =>
    financialStatement.pensionInvestmentsBasedOnFund
        .filter((item) => item.actualInvestmentsValue.value > 0)
        .map((item) => {
            const dssFund = funds.find((fund) => fund.get("fundCode") === item.getIn(["investmentFund", "code"]));
            const fundType = formatLocalizedValueMap(item?.type, fundTypes);

            return {
                name: `${model.getLocaleCodeName(item.investmentFund, locale)} (${fundType})`,
                color: dssFund ? dssFund.fundColor : DEFAULT_CHART_COLOR,
                value: item.actualInvestmentsValue.value,
                fullValue: localAmountWithCurrencyLegacy(item.actualInvestmentsValue),
                currency: item.actualInvestmentsValue.currencyCode,
            };
        })
        .toArray();

export const getFinancialStatementChartData = createSelector(
    getPensionDssFinancialStatementEntity,
    getFunds,
    getFundTypes,
    i18n.getLanguageCode,
    (financialStatement, funds, fundTypes, locale) =>
        Map({
            sumValue: financialStatement.actualInvestmentsValue.value,
            sumCurrency: financialStatement.actualInvestmentsValue.currencyCode,
            sumAmount: `${localAmountWithCurrencyLegacy(financialStatement.actualInvestmentsValue)}`,
            items: getFinancialStatementChartItems(financialStatement, funds, fundTypes, locale),
        }),
);

export const getFinancialOperations = createSelector(getFinancialOperationsEntity, (financialOperations) => {
    return financialOperations.map((financialOperation) => ({
        id: financialOperation.id,
        investmentFundName: financialOperation.investmentFundName,
        valueDate: financialOperation.valueDate,
        grossAmount: Map({
            amount: financialOperation.grossAmount,
            operationType: financialOperation.typeCode,
        }),
        pricePerShareAtOperationDateTime: financialOperation.pricePerShareAtOperationDateTime,
        numberOfShares: financialOperation.numberOfShares,
        actualPricePerShare: financialOperation.actualPricePerShare,
        type: financialOperation.type,
    }));
});
export const getFinancialOperationsFunds = createSelector(getFinancialOperationsEntity, (financialOperations) => {
    const languageCode = i18n.getLanguageCode();

    return financialOperations
        .map((operation) => operation.investmentFund)
        .toSet()
        .map((fund) => Map().set("value", fund.code).set("label", model.getLocaleCodeName(fund, languageCode)));
});
export const getFinancialOperationsTypes = createSelector(getFinancialOperationsEntity, (financialOperations) => {
    const languageCode = i18n.getLanguageCode();

    return financialOperations
        .map((operation) => operation.type)
        .toSet()
        .map((type) => Map().set("value", type.code).set("label", model.getLocaleCodeName(type, languageCode)));
});

export const getPensionDssStatementData = createSelector(getPensionDssFinancialStatementEntity, (financialStatement) => {
    if (!financialStatement) {
        return Map();
    }
    return [
        {
            reactKey: nanoid(),
            investmentsSumInvested: financialStatement.investmentsSumInvested,
            investmentsSumNotInvested: financialStatement.investmentsSumNotInvested,
            actualInvestmentsValue: financialStatement.actualInvestmentsValue,
            valuationPercentage: financialStatement.valuationPercentage,
        },
    ];
});

export const getActualFundsStrategiesByType = (type) =>
    createSelector(getPensionAccountDetail, (pensionAccountDetail) => {
        const strategyData =
            type === PensionDssFundsChangeType.VOLUNTARY.id
                ? pensionAccountDetail.fundsInvestmentVoluntaryStrategies
                : pensionAccountDetail.fundsInvestmentStrategies;

        const outputList = {};
        strategyData.forEach((fundData) => (outputList[fundData.investmentFund.code] = fundData.investmentPercentage));
        return Map(outputList);
    });
