import * as React from "react";
import PropTypes from "prop-types";
import { StyledTabs, StyledTab, StyledTabPanel } from "ui-library/Styled";
import { Typography } from "ui-library/atoms/Typography/Typography";

export const Tabs = ({ onChange, value, children, ...props }) => {
    return (
        <StyledTabs
            TabIndicatorProps={{ children: <span className="custom-indicator" /> }}
            value={value}
            onChange={onChange}
            variant="scrollable"
            scrollButtons="auto"
            {...props}
        >
            {children}
        </StyledTabs>
    );
};

Tabs.propTypes = {
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
};

export const TabPanel = ({ children, value, index, ...props }) => {
    return (
        <StyledTabPanel hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...props}>
            {value === index && <>{children}</>}
        </StyledTabPanel>
    );
};

TabPanel.propTypes = {
    value: PropTypes.any.isRequired,
    index: PropTypes.any.isRequired,
};

export const Tab = ({ label, ...props }) => {
    return <StyledTab label={<Typography variant="p">{label}</Typography>} {...props} />;
};

Tab.propTypes = {
    label: PropTypes.string.isRequired,
};
