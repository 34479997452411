import { model } from "core/util";

import { AmountLegacy } from "../../common";

export const PensionDpsFinancialOperation = model.createModelLegacy("PensionDpsFinancialOperation", (json) => ({
    id: json.Id,
    valueDate: json.ValueDate,
    type: json.Type,
    typeCode: json.TypeCode,
    investmentFundCode: json.InvestmentFundCode,
    investmentFundName: json.InvestmentFundName,
    grossAmount: AmountLegacy.fromServer(json.GrossAmount),
    pricePerShareAtOperationDateTime: AmountLegacy.fromServer(json.PricePerShareAtOperationDateTime),
    numberOfShares: json.NumberOfShares,
    actualPricePerShare: AmountLegacy.fromServer(json.ActualPricePerShare),
}));
