import { RssItem } from "types";

export const NAME = "core/cms";

export interface CmsContentState {
    rss: RssItem[];
}

export const initialState: CmsContentState = {
    rss: [],
};
