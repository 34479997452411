import { List, Map } from "immutable";
import { combineReducers } from "redux-immutable";

import { ERROR_DATA_VALUE } from "core/util/app/createDataReducer";

import { getLatestAvailableYear } from "./util";

export default (actions, yearToRemoveFromList) => {
    const { CHANGE_DATA, SET_AVAILABLE_YEARS, ADD_MESSAGE, STATEMENTS_SUBMIT } = actions;

    const updatedSelectedYear = (state = getLatestAvailableYear(yearToRemoveFromList), action) => {
        switch (action.type) {
            case CHANGE_DATA:
                return action.payload;
            case SET_AVAILABLE_YEARS:
                if (action.meta && action.meta.error === true) {
                    return ERROR_DATA_VALUE;
                } else if (!action.payload.isEmpty()) {
                    return action.payload.get(0);
                }
                return state;
            default:
                return state;
        }
    };

    const setAvailableYears = (state = List(), action) => {
        if (action.type === SET_AVAILABLE_YEARS) {
            if (action.meta && action.meta.error === true) {
                return ERROR_DATA_VALUE;
            } else {
                return action.payload.map((item) =>
                    Map({
                        value: item,
                        label: item.toString(),
                    }),
                );
            }
        } else {
            return state;
        }
    };

    const getMessagesReducer = (state = List(), action) => {
        if (action.type === ADD_MESSAGE) {
            return state.push(action.payload);
        } else {
            return state;
        }
    };

    const getSubmittingReducer = (state = false, action) => {
        if (action.type === STATEMENTS_SUBMIT) {
            return true;
        } else if (action.type === ADD_MESSAGE) {
            return false;
        } else {
            return state;
        }
    };

    return combineReducers({
        statementsGenerateMsg: getMessagesReducer,
        selectedYear: updatedSelectedYear,
        availableYears: setAvailableYears,
        submitting: getSubmittingReducer,
    });
};
