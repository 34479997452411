import React from "react";
import PropTypes from "prop-types";

import { ContentBox } from "ui-library/layouts";

import { Field, InputText, required, max50Characters, upperLettersNumbers, validIban, validateSkIban } from "core/form";
import i18n from "core/i18n";

import AccountSectionCommon from "./AccountSectionCommon";

const AccountSectionSk = ({shouldCheckIbanCountry}) => {
    const { t } = i18n.useTranslation();
    return (
        <>
            <ContentBox fullWidth>
                <Field
                    name="account.iban"
                    label={t("investments.sellSwitch.targetAccountIban")}
                    component={InputText}
                    validateStatic={shouldCheckIbanCountry ? [required, validIban,  validateSkIban] : [required, validIban]}
                    normalize={[upperLettersNumbers, max50Characters]}
                    md={6}
                />
            </ContentBox>
            <AccountSectionCommon />
        </>
    );
};

AccountSectionSk.propTypes = {
    shouldCheckIbanCountry: PropTypes.bool.isRequired,
}

export default AccountSectionSk;
