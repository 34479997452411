import { combineReducers } from "redux-immutable";
import { List, Map } from "immutable";

import { app } from "core/util";
import { PaymentMethod } from "enums";

import { SET_SELECTS, SET_PAYMENT_METHOD } from "./actions";

const contractIdReducer = (state = null, { type, payload }) => {
    switch (type) {
        case SET_SELECTS:
            return payload.contractId;
        default:
            return state;
    }
};

const productGroupReducer = (state = null, { type, payload }) => {
    switch (type) {
        case SET_SELECTS:
            return payload.productGroup;
        default:
            return state;
    }
};

const fundIdReducer = (state = null, { type, payload }) => {
    switch (type) {
        case SET_SELECTS:
            return payload.fundId;
        default:
            return state;
    }
};

const fundListReducer = (state = List(), { type, payload }) => {
    switch (type) {
        case SET_SELECTS:
            return payload.info && payload.info.fundsToInvest ? payload.info.fundsToInvest.map((fund) => fund.fund) : List();
        default:
            return state;
    }
};

const paymentInfoReducer = (state = null, { type, payload }) => {
    switch (type) {
        case SET_SELECTS:
            return payload.info;
        default:
            return state;
    }
};

const amountTypeReducer = (state = null, { type, payload }) => {
    switch (type) {
        case SET_SELECTS:
            return payload.amountType;
        default:
            return state;
    }
};

export default combineReducers({
    contractId: contractIdReducer,
    fundId: fundIdReducer,
    paymentInfo: paymentInfoReducer,
    funds: fundListReducer,
    amountType: amountTypeReducer,
    productGroup: productGroupReducer,
    paymentMethod: app.createDataReducer(SET_PAYMENT_METHOD, PaymentMethod.TRANSFER.tabId),
});
