import React, { FC } from "react";

import { GridLayout } from "ui-library/layouts";

import { ActiveOperationsGridItem, FinancialRequestsGridItem, InvestmentsNewBuyActionGridItem, NewSellSwitchGridItem } from "../containers";
import { ModalActiveOperationsPhoneNumberForm } from "../form";

export const FinancialOperationsTab: FC = () => {
    return (
        <GridLayout defaultSpacing>
            <NewSellSwitchGridItem />
            <ActiveOperationsGridItem />
            <FinancialRequestsGridItem />
            <InvestmentsNewBuyActionGridItem />

            {/* Modals */}
            <ModalActiveOperationsPhoneNumberForm />
        </GridLayout>
    );
};
