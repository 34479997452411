import { StepConnector, StepLabel } from "@mui/material";
import { withTheme } from "@mui/styles";
import { Box } from "ui-library/layouts/Box/Box";
import styled from "styled-components";

const stepIconState = (props) => {
    if (props.ownerState.active) {
        return `
        border : 4px solid ${props.theme.palette.green.main};
        background-color: white;
        `;
    } else if (props.ownerState.completed) {
        return `
        background-color:  ${props.theme.palette.green.main};
        `;
    } else {
        return `
            background-color : ${props.theme.palette.gray.mainLighter};
        `;
    }
};

export const StyledStepperConnector = withTheme(styled(StepConnector)`
    top: 22px;
    left: calc(-50% + 15.5px);
    right: calc(50% + 15.5px);

    .MuiStepConnector-line {
        border-color: ${(props) => props.theme.palette.gray.mainLighter};
        border-top-width: 4px;
    }

    &.Mui-active,
    &.Mui-completed {
        .MuiStepConnector-line {
            border-color: ${(props) => props.theme.palette.green.main};
        }
    }
`);

export const StyledStepperIcon = withTheme(styled("span")`
    box-sizing: border-box;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    ${(props) => stepIconState(props)};
`);

export const StyledStepLabel = withTheme(styled(StepLabel)`
    .MuiStepLabel-alternativeLabel {
        margin-top: 8px;
        font-size: 16px;
        line-height: 24px;
        color: ${(props) => props.theme.palette.gray.main};
    }

    .Mui-completed {
        font-weight: 400;
    }

    .Mui-active {
        color: ${(props) => props.theme.palette.black.main};
        font-weight: 700;
    }
`);

export const StyledStepperCircle = withTheme(styled(Box)`
    .background {
        position: absolute;
        z-index: 1;
        right: 0;
        svg {
            color: ${(props) => props.theme.palette.gray.line};
            circle {
                stroke-dashoffset: 0px !important;
            }
        }
    }
    .foreground {
        position: relative;
        z-index: 2;
        svg {
            color: ${(props) => props.theme.palette.green.success};

            circle {
            }
        }
    }
    .inner {
        top: 2px;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
    }
`);
