import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Button, Container, Image, MenuLink, Hamburger } from "ui-library/atoms";
import { Box, Flexbox } from "ui-library/layouts";
import { StyledHeader } from "ui-library/Styled";
import logo from "ui-library/assets/images/logo.svg";
import { useNavigate } from "react-router-dom";
import { LoggedUser } from "ui-library/modules/LoggedUser/LoggedUser";
import { Dropdown, DropdownItem } from "ui-library/modules/Dropdown/Dropdown";
import useBreakpoints from "ui-library/theme/breakpoints";
import { LogoWrapper, MenuWrapper } from "ui-library/Styled/Modules/Header";
import { useLocation } from "react-router-dom";

export const Header = ({ isLogged, ...props }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const location = useLocation();

    useEffect(() => {
        setMenuOpen(false);
    }, [location]);

    const [menuOpen, setMenuOpen] = React.useState();

    const handleMenuToggle = () => {
        if (menuOpen === true) {
            setMenuOpen(false);
        } else {
            setMenuOpen(true);
        }
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    let navigate = useNavigate();

    const handleLogoClick = () => {
        navigate("/");
    };

    const handleRedirect = (path) => {
        navigate(path);
        handleClose();
    };

    const breakpointMd = useBreakpoints("md");

    return (
        <StyledHeader {...props} className={menuOpen ? "opened-header-menu" : ""}>
            <Container>
                <Flexbox
                    className={"header"}
                    flexDirection={{ xs: "row", md: "row" }}
                    alignItems={"center"}
                    justifyContent={{ xs: "unset", md: "space-between" }}
                >
                    <LogoWrapper>
                        <Image shadow={false} src={logo} onClick={handleLogoClick} alt={"Logo"} />
                    </LogoWrapper>

                    {isLogged ? (
                        <>
                            <MenuWrapper order={{ xs: 1, md: 0 }} className={menuOpen ? "opened" : ""}>
                                <MenuLink notificationCount={"5"} to="/contracts">
                                    Zmluvy
                                </MenuLink>
                                <MenuLink to="/payments">Platba</MenuLink>
                                <MenuLink to="/reports">Správy</MenuLink>
                                <MenuLink to="/insurance-events">Poistné udalosti</MenuLink>
                                <MenuLink to="/contact">Kontakt</MenuLink>
                            </MenuWrapper>
                            <Box order={{ xs: 0, md: 1 }} mr={{ xs: 0, md: 0 }}>
                                <LoggedUser name={"František Kovacs"} open={open} onClick={handleClick} />
                                <Dropdown
                                    position={"right"}
                                    title={"Váš osobný profil"}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    <DropdownItem onClick={() => handleRedirect("/profile/personal")} icon={"address-card"}>
                                        Zmena osobných údajov
                                    </DropdownItem>
                                    <DropdownItem onClick={() => handleRedirect("/profile/process")} icon={"user-settings"}>
                                        Spracovanie údajov
                                    </DropdownItem>
                                    <DropdownItem onClick={() => handleRedirect("/profile/settings")} icon={"settings"}>
                                        Nastavenie účtu
                                    </DropdownItem>
                                    <DropdownItem onClick={() => handleRedirect("/")} icon={"logout"}>
                                        Odhlásiť sa
                                    </DropdownItem>
                                </Dropdown>
                            </Box>
                        </>
                    ) : (
                        <MenuWrapper order={{ xs: 1, md: 0 }} className={menuOpen ? "opened" : ""}>
                            {!breakpointMd && <MenuLink to="/contact">Kontakt</MenuLink>}
                            {breakpointMd && (
                                <Box mr={4}>
                                    <Button variant="text" color="blue" onClick={() => navigate("/contact")}>
                                        Kontakt
                                    </Button>
                                </Box>
                            )}

                            <Flexbox gap={1} flexDirection={{ xs: "column", md: "row" }} mt={{ xs: 3, md: 0 }}>
                                <Button startIcon={"user"} variant="outlined" color="blue">
                                    Prihlásenie
                                </Button>
                                <Button variant="contained" color="blue">
                                    Registrácia
                                </Button>
                            </Flexbox>
                        </MenuWrapper>
                    )}
                    {!breakpointMd && (
                        <Box>
                            <Hamburger onClick={handleMenuToggle} isOpenState={menuOpen} />
                        </Box>
                    )}
                </Flexbox>
            </Container>
        </StyledHeader>
    );
};

Header.propTypes = {
    isLogged: PropTypes.bool.isRequired,
};

Header.defaultProps = {};
