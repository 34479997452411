import { fetch } from "core/util";
import { API_PREFIX } from "app/constants";
import { ClaimsEvents } from "model/claim";
import { Life, LifeFinancialOperation, LifeFinancialStatement } from "model/life";

const productUriPrefix = `${API_PREFIX}/product/life`;
const getLifeContractDetail = (idObject) => fetch.doGet(`${productUriPrefix}/${idObject}/contract-detail`, null, Life.fromServer);
const getLifeFinancialOperations = (idObject) =>
    fetch.doGet(`${productUriPrefix}/${idObject}/financial-operations`, null, LifeFinancialOperation.fromServerList);
const getLifeFinancialStatement = (objectId) =>
    fetch.doGet(`${productUriPrefix}/${objectId}/financial-statement`, null, LifeFinancialStatement.fromServer);
const getLifeClaims = (idObject) => fetch.doGet(`${productUriPrefix}/${idObject}/claims`, null, ClaimsEvents.fromServer);

export default {
    getLifeContractDetail,
    getLifeFinancialOperations,
    getLifeFinancialStatement,
    getLifeClaims,
};
