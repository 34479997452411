import React from "react";
import { Typography } from "ui-library/atoms/Typography/Typography";
import { StyledBankIDLabel, StyledButton } from "ui-library/Styled";
import PropTypes from "prop-types";

export const BankIDButton = ({ label, size, ...props }) => {
    return (
        <StyledButton variant="contained" size={size} $borderRadius={10} color={"black"} {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="20" viewBox="0 0 100 20" fill="none">
                <path
                    d="M85.0971 4.01468H89.6021C93.154 4.01468 94.9238 6.95483 94.9238 9.87027C94.9238 12.8104 93.154 15.7259 89.6021 15.7259H85.0971V14.367H87.0031V5.37357H85.0971V4.01468ZM82.5105 19.3949H89.7382C96.1985 19.3949 99.4163 14.6264 99.4163 9.87027C99.4163 5.10179 96.1985 0.345666 89.7382 0.345666H82.5105V19.3949ZM75.1467 4.01468H79.2432V0.333313H75.1467V4.01468ZM75.1467 5.37357H79.2432V19.3949H75.1467V5.37357ZM58.1296 19.3949V14.7129H59.4909L63.5874 19.3949H67.6468V18.036L62.4859 12.2669L67.4983 6.74481V5.38592H63.5998L59.4909 10.0556V11.9333H58.1296V0.333313H54.0331V19.3949H58.1296ZM46.8054 19.3949H50.9019V10.8956C50.9019 7.3872 48.34 5.10179 45.4688 5.10179C44.2683 5.10179 43.0183 5.50946 41.8921 6.38656V7.96782H40.5307V5.37357H36.9787V19.3949H41.0752V11.6368C41.0752 9.67261 42.5728 8.64726 44.0455 8.64726C45.4316 8.64726 46.8054 9.57378 46.8054 11.5009V19.3949ZM26.1372 8.5855C27.9689 8.5855 29.4912 10.0556 29.4912 12.3657C29.4912 14.6758 27.9689 16.1459 26.1372 16.1459C24.3056 16.1459 22.6719 14.6758 22.6719 12.3657C22.6719 10.0556 24.3056 8.5855 26.1372 8.5855ZM18.4393 12.3657C18.4393 16.8871 21.6818 19.6666 24.8996 19.6666C26.1868 19.6666 27.4615 19.2343 28.5258 18.246V16.8006H29.8872V19.3825H33.7114V5.37357H29.8872V7.95546H28.5258V6.5101C27.4615 5.53416 26.1744 5.08944 24.8996 5.08944C21.6942 5.10179 18.4393 7.86899 18.4393 12.3657ZM4.56565 3.73055H9.52848C11.0507 3.73055 11.8428 4.81766 11.8428 5.90477C11.8428 6.99189 11.0507 8.079 9.52848 8.079H5.92702V11.6245H9.88738C11.4096 11.6245 12.2017 12.7116 12.2017 13.7987C12.2017 14.8858 11.4096 15.9729 9.88738 15.9729H4.56565V3.73055ZM14.8131 10.4015H12.5854V9.04258H14.5532C15.667 8.11606 16.2487 6.78188 16.2487 5.42298C16.2363 2.8905 14.2438 0.333313 10.0978 0.333313H0.333008V19.3949H10.4814C14.6522 19.3949 16.6695 16.7512 16.6695 14.1693C16.6695 12.7239 16.0383 11.328 14.8131 10.4015Z"
                    fill="white"
                />
            </svg>
            {label && (
                <StyledBankIDLabel>
                    <Typography
                        component="p"
                        sx={{ fontFamily: "inherit" }}
                        lineHeight={16}
                        fontSize={14}
                        variant="p"
                        color="white"
                        fontWeight={600}
                    >
                        {label}
                    </Typography>
                </StyledBankIDLabel>
            )}
        </StyledButton>
    );
};

BankIDButton.propTypes = {
    label: PropTypes.string,
    size: PropTypes.string,
};
