import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { ContractDocuments } from "containers/contract";
import { GridLayout } from "ui-library/layouts";

import { ENTITY_CONTRACT_DOCUMENTS } from "../constants";
import { getInsuredVehicleWithMtpl } from "../selectors";
import { GreenCardGridItem } from "../containers";

export const DocumentsTab = () => {
    const { idObject } = useParams();
    const insuredVehiclesWithMtlp = useSelector(getInsuredVehicleWithMtpl);

    return (
        <GridLayout defaultSpacing>
            {insuredVehiclesWithMtlp.size > 0 && <GreenCardGridItem />}

            <ContractDocuments idObject={idObject} entityName={ENTITY_CONTRACT_DOCUMENTS} />
        </GridLayout>
    );
};
