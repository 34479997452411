import { Record } from "immutable";

const ChangePropositionType = Record({ id: null, msg: null, changeMsg: null });

export default Object.freeze({
    MUTUAL_FUNDS_TRANSACTION: new ChangePropositionType({
        id: "MUTUAL_FUNDS_TRANSACTION",
        msg: "investments.newSellSwitchAction",
    }),
    MUTUAL_FUNDS_PROFILE_CHANGE: new ChangePropositionType({
        id: "MUTUAL_FUNDS_PROFILE_CHANGE",
        msg: "form.investmentProfile",
    }),
    PENSION_FUNDS_CHANGE: new ChangePropositionType({
        id: "PENSION_FUNDS_CHANGE",
        msg: "changeProposition.pensionFunds",
    }),
    PENSION_INVESTMENT_STRATEGIES_CHANGE: new ChangePropositionType({
        id: "PENSION_INVESTMENT_STRATEGIES_CHANGE",
        msg: "changeProposition.pensionFunds",
    }),
});
