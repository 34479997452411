import { Record } from "immutable";
import RedeemPensionPpPensionTypes from "./RedeemPensionPpPensionTypes";

const RedeemPensionPpRedeemTypes = Record({ id: null, msg: null, description: null, pensionType: null, type: null, code: null });

export default Object.freeze({
    // RETIREMENT_PENSION
    PP_RETIREMENT_PENSION_ONE_TIME_SETTLEMENT: new RedeemPensionPpRedeemTypes({
        id: "PP_RETIREMENT_PENSION_ONE_TIME_SETTLEMENT",
        msg: "pension.redeemType.oneTimeSettlement",
        description: "pension.description.pp.retirementPension.oneTimeSettlement",
        pensionType: RedeemPensionPpPensionTypes.RETIREMENT_PENSION,
        type: "ONE_TIME_PAYMENT",
        code: "511",
    }),
    PP_RETIREMENT_PENSION_REGULAR_PENSION: new RedeemPensionPpRedeemTypes({
        id: "PP_RETIREMENT_PENSION_REGULAR_PENSION",
        msg: "pension.redeemType.regularPension",
        description: "pension.description.pp.retirementPension.regularPension",
        pensionType: RedeemPensionPpPensionTypes.RETIREMENT_PENSION,
        type: "PENSION",
        code: "112",
    }),
    PP_RETIREMENT_PENSION_LIFETIME_PENSION: new RedeemPensionPpRedeemTypes({
        id: "PP_RETIREMENT_PENSION_LIFETIME_PENSION",
        msg: "pension.redeemType.lifetimePension",
        description: "pension.description.retirementPension.lifetimePension",
        pensionType: RedeemPensionPpPensionTypes.RETIREMENT_PENSION,
        type: "PENSION",
        code: "111",
    }),

    // SERVICE_PENSION
    PP_SERVICE_PENSION_ONE_TIME_SETTLEMENT: new RedeemPensionPpRedeemTypes({
        id: "PP_SERVICE_PENSION_ONE_TIME_SETTLEMENT",
        msg: "pension.redeemType.oneTimeSettlement",
        description: "pension.description.servicePension.oneTimeSettlement",
        pensionType: RedeemPensionPpPensionTypes.SERVICE_PENSION,
        type: "ONE_TIME_PAYMENT",
        code: "521",
    }),
    PP_SERVICE_PENSION_REGULAR_PENSION: new RedeemPensionPpRedeemTypes({
        id: "PP_SERVICE_PENSION_REGULAR_PENSION",
        msg: "pension.redeemType.regularPension",
        description: "pension.description.servicePension.regularPension",
        pensionType: RedeemPensionPpPensionTypes.SERVICE_PENSION,
        type: "PENSION",
        code: "122",
    }),
    PP_SERVICE_PENSION_LIFETIME_PENSION: new RedeemPensionPpRedeemTypes({
        id: "PP_SERVICE_PENSION_LIFETIME_PENSION",
        msg: "pension.redeemType.lifetimePension",
        description: "pension.description.servicePension.lifetimePension",
        pensionType: RedeemPensionPpPensionTypes.SERVICE_PENSION,
        type: "PENSION",
        code: "121",
    }),

    // EARLY_PAYMENT
    PP_EARLY_PAYMENT_ONE_TIME_SETTLEMENT: new RedeemPensionPpRedeemTypes({
        id: "PP_EARLY_PAYMENT_ONE_TIME_SETTLEMENT",
        msg: "pension.redeemType.earlyPayment",
        description: "pension.description.earlyPayment.oneTimeSettlement",
        pensionType: RedeemPensionPpPensionTypes.EARLY_PAYMENT,
        type: "TERMINATION",
        code: "1",
    }),
});
