import React from "react";
import PropTypes from "prop-types";

import { ChangeProposition } from "model/changePropositions";

import { PROPOSITION_PAGE_STATE } from "../../constants";

import { MutualFundsTransactionDetail } from "./MutualFundsTransactionDetail";
import { MutualFundsProfileChangeDetail } from "./MutualFundsProfileChangeDetail";
import { PensionInvestmentStrategiesChangeDetail } from "./PensionInvestmentStrategiesChangeDetail";
import { PensionFundsChangeDetail } from "./PensionFundsChangeDetail";

const ChangePropositionInnerDetail = ({ pageState, changeProposition }) => {
    switch (pageState.changePropositionPageState) {
        case PROPOSITION_PAGE_STATE.MUTUAL_FUNDS_TRANSACTION:
            return <MutualFundsTransactionDetail mutualFundsTransaction={changeProposition.mutualFundsTransaction} />;
        case PROPOSITION_PAGE_STATE.MUTUAL_FUNDS_PROFILE_CHANGE:
            return <MutualFundsProfileChangeDetail mutualFundsProfileChange={changeProposition.mutualFundsProfileChange} />;
        case PROPOSITION_PAGE_STATE.PENSION_FUNDS_CHANGE:
            return <PensionFundsChangeDetail pensionFundsChange={changeProposition.pensionFundsChange} />;
        case PROPOSITION_PAGE_STATE.PENSION_INVESTMENT_STRATEGIES_CHANGE:
            return (
                <PensionInvestmentStrategiesChangeDetail
                    pensionInvestmentStrategiesChange={changeProposition.pensionInvestmentStrategiesChange}
                />
            );
        default:
            return "";
    }
};

ChangePropositionInnerDetail.propTypes = {
    changeProposition: PropTypes.instanceOf(ChangeProposition).isRequired,
    pageState: PropTypes.shape({
        changePropositionId: PropTypes.string.isRequired,
        changePropositionPageState: PropTypes.oneOf(Object.values(PROPOSITION_PAGE_STATE)).isRequired,
    }).isRequired,
};

export default ChangePropositionInnerDetail;
