import { Icon } from "ui-library/atoms/Icons/Icon";
import { Typography } from "ui-library/atoms/Typography/Typography";
import * as React from "react";
import { StyledAccordion, StyledAccordionDetails, StyledAccordionSummary } from "ui-library/Styled";
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import { useDispatch } from "react-redux";
import { InlineBox } from "ui-library/layouts";
import { localFullDate } from "core/formatters";
import userInfo from "core/userInfo";

export const MessagesAccordion = ({ id, compact, messages, disableUserInfoDispatch, messageTextAsMarkdown, ...props }) => {
    const [expanded, setExpanded] = React.useState(false);
    const dispatch = useDispatch();

    const handleChange = (panel, message) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        if (!disableUserInfoDispatch) {
            dispatch(userInfo.expandMessage(message.id, isExpanded, message.unread));
        }
    };

    return (
        <>
            {messages.map((message, i) => {
                return (
                    <StyledAccordion {...props} expanded={expanded === id + i} onChange={handleChange(id + i, message)} key={i}>
                        <StyledAccordionSummary
                            key={i}
                            expandIcon={
                                expanded === id + i ? (
                                    <Icon size={12} icon="minus" color="gray" />
                                ) : (
                                    <Icon size={12} icon="plus" color="gray" />
                                )
                            }
                            aria-controls={id + i}
                            id={id + i}
                            className={compact && "header-compact"}
                        >
                            {/*Title */}
                            {message.date && (
                                <InlineBox mr={2}>
                                    <Typography component="span" variant="span" color="gray">
                                        {localFullDate(message.date)}
                                    </Typography>
                                </InlineBox>
                            )}
                            <Typography variant={message.unread ? "b" : "p"}>{message.subject}</Typography>
                        </StyledAccordionSummary>

                        <StyledAccordionDetails>
                            {messageTextAsMarkdown ? (
                                <Typography variant="p" markDown={message.get("content")} />
                            ) : (
                                <Typography variant="p">
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: message.get("content"),
                                        }}
                                    />
                                </Typography>
                            )}
                        </StyledAccordionDetails>
                    </StyledAccordion>
                );
            })}
        </>
    );
};

MessagesAccordion.propTypes = {
    compact: PropTypes.bool,
    id: PropTypes.string.isRequired,
    messages: ImmutablePropTypes.listOf(Number).isRequired,
    disableUserInfoDispatch: PropTypes.bool,
    messageTextAsMarkdown: PropTypes.bool,
};

MessagesAccordion.defaultProps = {
    compact: false,
    disableUserInfoDispatch: false,
    messageTextAsMarkdown: false,
};
