import React from "react";
import PropTypes from "prop-types";
import { List } from "immutable";

import { RedeemPensionPpRedeemTypes } from "enums";

import {
    PensionPpEarlyPaymentOrOneTimeSettlementSection,
    PensionPpLifetimePaymentSection,
    PensionPpRegularPaymentSection,
} from "./redeemParameters";

const regularPaymentList = List.of(
    RedeemPensionPpRedeemTypes.PP_RETIREMENT_PENSION_REGULAR_PENSION,
    RedeemPensionPpRedeemTypes.PP_SERVICE_PENSION_REGULAR_PENSION,
);
const lifetimePaymentList = List.of(
    RedeemPensionPpRedeemTypes.PP_RETIREMENT_PENSION_LIFETIME_PENSION,
    RedeemPensionPpRedeemTypes.PP_SERVICE_PENSION_LIFETIME_PENSION,
);
const earlyPaymentOrOneTimeSettlementList = List.of(
    RedeemPensionPpRedeemTypes.PP_EARLY_PAYMENT_ONE_TIME_SETTLEMENT,
    RedeemPensionPpRedeemTypes.PP_RETIREMENT_PENSION_ONE_TIME_SETTLEMENT,
    RedeemPensionPpRedeemTypes.PP_SERVICE_PENSION_ONE_TIME_SETTLEMENT,
);
export const RedeemParametersPensionPpBasedOnType = ({ formName, selectedRedeemType, selectedRedeemTypeParameters }) => (
    <>
        {/* Regular payments for limited time */}
        {regularPaymentList.contains(selectedRedeemType) && (
            <PensionPpRegularPaymentSection formName={formName} selectedRedeemTypeParameters={selectedRedeemTypeParameters} />
        )}

        {/* Lifetime payments */}
        {lifetimePaymentList.contains(selectedRedeemType) && (
            <PensionPpLifetimePaymentSection selectedRedeemTypeParameters={selectedRedeemTypeParameters} />
        )}

        {/* Early payments */}
        {earlyPaymentOrOneTimeSettlementList.contains(selectedRedeemType) && (
            <PensionPpEarlyPaymentOrOneTimeSettlementSection
                selectedRedeemType={selectedRedeemType}
                selectedRedeemTypeParameters={selectedRedeemTypeParameters}
            />
        )}
    </>
);

RedeemParametersPensionPpBasedOnType.propTypes = {
    formName: PropTypes.string.isRequired,
    selectedRedeemType: PropTypes.object.isRequired,
    selectedRedeemTypeParameters: PropTypes.object.isRequired,
};
