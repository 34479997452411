import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { BannerFlexbox, Box, Flexbox, GridItem, GridLayout } from "ui-library/layouts";
import { Button, ErrorContent, Loading } from "ui-library/atoms";

import auth from "core/auth";
import i18n from "core/i18n";

import { Field, form, InputPassword, InputText } from "core/form";
import { LOGIN_FORM } from "../constants";
import { getLoginError } from "../selectors";

const ERRORS = {
    [auth.UnsuccessfulReason.BAD_CREDENTIALS]: "error.page.login.wrongCredentials",
    [auth.UnsuccessfulReason.ERROR_MISSING_RECAPTCHA]: "error.page.login.recaptchaIssue",
};

const LoginFormContainer = ({ handleSubmit, submitting }) => {
    const { t } = i18n.useTranslation();
    const loginError = useSelector(getLoginError);

    return (
        <>
            <Loading fullPage loading={submitting} />

            {/* Loggin form */}
            <BannerFlexbox>
                <GridLayout spacing={1}>
                    <GridItem xs={12} md={4.5}>
                        <Field
                            name="username"
                            component={InputText}
                            size="large"
                            label={t("common.contractNumberOrEmail")}
                            onEnter={handleSubmit}
                        />
                    </GridItem>

                    <GridItem xs={12} md={4.5}>
                        <Field name="password" component={InputPassword} size="large" label={t("common.password")} onEnter={handleSubmit} />
                    </GridItem>

                    <GridItem xs={12} md={2}>
                        <Box mt={3.625}>
                            <Button onClick={handleSubmit} color="green" variant="contained" size="large">
                                {t("common.login")}
                            </Button>
                        </Box>
                    </GridItem>
                </GridLayout>
            </BannerFlexbox>

            {/* Login error message */}
            {loginError && (
                <Flexbox alignItems={"top"} gap={2} mt={2}>
                    <ErrorContent content={t(ERRORS[loginError])} />
                </Flexbox>
            )}
        </>
    );
};

LoginFormContainer.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
};

export const LoginForm = form(LOGIN_FORM)(LoginFormContainer);
