import { withTheme } from "@mui/styles";
import styled from "styled-components";
import { Flexbox } from "ui-library/layouts/Flexbox/Flexbox";

export const StyledContractSwitch = withTheme(styled(Flexbox)`
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.palette.gray.line};

    .left {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-right: 1px solid ${(props) => props.theme.palette.gray.line};
    }
    .right {
        border-bottom-left-radius: 0px;
        border-top-left-radius: 0px;
    }
    .active {
        border: 1px solid ${(props) => props.theme.palette.blue.main};
        background-color: ${(props) => props.theme.palette.blue.soft};
        svg {
            path {
                fill: ${(props) => props.theme.palette.blue.main};
            }
        }
    }
`);
