import React, { FC } from "react";

import { Container, Typography } from "ui-library/atoms";
import { ContentBox } from "ui-library/layouts";

import i18n from "core/i18n";

export const ErrorContent: FC = () => {
    const { t } = i18n.useTranslation();

    return (
        <>
            <Container maxWidth="md">
                {/* Title */}
                <ContentBox mt={10} mb={5}>
                    <Typography variant="h1">{t("campaign.electronicCommunication.errorTitle")}</Typography>
                </ContentBox>

                {/* Introduction text */}
                <ContentBox mb={10}>
                    <Typography component="p" markDown={t("campaign.electronicCommunication.errorText")} />
                </ContentBox>
            </Container>
        </>
    );
};
