import { app } from "core/util";

import { NAME } from "./constants";

const getModel = app.createGetModel(NAME);

export const getPhoneShort = (state) => getModel(state).get("phoneNumberShort");
export const getIsResendEpinCountExhausted = (state) => getModel(state).get("resendEpinCountExhausted");
export const getIsHashInvalid = (state) => getModel(state).get("invalidHash");
export const getIsError = (state) => getModel(state).get("error");
