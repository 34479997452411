import { withTheme } from "@mui/styles";
import styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "ui-library/layouts/Box/Box";

export const StyledCircularLoader = withTheme(styled(CircularProgress)`
    svg {
        color: ${(props) => props.theme.palette.blue.main};
    }
`);

export const StyledLoaderWrapper = withTheme(styled(Box)`
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
`);

export const StyledLoaderComponentWrapper = withTheme(styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 24px;
`);
