import { fetch } from "core/util";
import { API_PREFIX } from "app/constants";
import {
    MutualFundsRegular,
    MutualFundsRegularFinancialOperation,
    MutualFundsRegularFinancialOperationDetail,
    MutualFundsRegularFinancialStatement,
    MutualFundsRegularProfileChange,
    MutualFundsRegularAvailableFundsProfileChange,
} from "model/mutualFunds/regular";
import { MutualFundsFinancialRequest } from "model/mutualFunds";
import { CodeValueLegacy } from "../model/common";

const productUriPrefix = `${API_PREFIX}/product/mutual-funds-regular`;
const getMutualFundsRegularContractDetail = (idObject) =>
    fetch.doGet(`${productUriPrefix}/${idObject}/contract-detail`, null, MutualFundsRegular.fromServer);
const getMutualFundsRegularFinancialOperations = (idObject) =>
    fetch.doGet(`${productUriPrefix}/${idObject}/financial-operations`, null, MutualFundsRegularFinancialOperation.fromServerList);
const getMutualFundsRegularFinancialStatement = (idObject) =>
    fetch.doGet(`${productUriPrefix}/${idObject}/financial-statement`, null, MutualFundsRegularFinancialStatement.fromServer);
const getMutualFundsRegularFinancialRequests = (idObject) =>
    fetch.doGet(`${productUriPrefix}/${idObject}/financial-requests`, null, MutualFundsFinancialRequest.fromServerList);
const getMutualFundsRegularAvailableProfiles = (idObject) =>
    fetch.doGet(`${productUriPrefix}/${idObject}/available-profiles`, null, CodeValueLegacy.fromServerList);
const getMutualFundsRegularAvailableFundsProfileChange = (idObject) =>
    fetch.doGet(
        `${productUriPrefix}/${idObject}/available-funds-profile-change`,
        null,
        MutualFundsRegularAvailableFundsProfileChange.fromServer,
    );
const getMutualFundsRegularChangeProfile = (idObject, profileCode) =>
    fetch.doGet(`${productUriPrefix}/${idObject}/${profileCode}/change-profile`, null, MutualFundsRegularProfileChange.fromServer);
const getMutualFundsRegularFinancialOperationDetail = (objectId, operationId) =>
    fetch.doGet(
        `${productUriPrefix}/${objectId}/${operationId}/financial-operation-detail`,
        null,
        MutualFundsRegularFinancialOperationDetail.fromServer,
    );
const sellOrSwitchFund = (transaction) => fetch.doPut(`${productUriPrefix}/sell-or-switch-fund`, transaction);

export default {
    getMutualFundsRegularContractDetail,
    getMutualFundsRegularFinancialOperations,
    getMutualFundsRegularFinancialStatement,
    getMutualFundsRegularFinancialRequests,
    getMutualFundsRegularAvailableProfiles,
    getMutualFundsRegularAvailableFundsProfileChange,
    getMutualFundsRegularChangeProfile,
    getMutualFundsRegularFinancialOperationDetail,
    sellOrSwitchFund,
};
