/**
 * Utilities for initiation and calling of Google Places API
 */

let sessionToken = null;
let autocompleteService = null;
let bounds = null;
let placesService = null;
let placesServiceStatusOk = null;

export const initPlaces = () => {
    const { maps } = window.google;

    sessionToken = new maps.places.AutocompleteSessionToken();
    autocompleteService = new maps.places.AutocompleteService();
    bounds = new maps.LatLngBounds(new maps.LatLng(47.409, 12.407), new maps.LatLng(50.96, 23.069));
    // PlacesService requires a reference to Google Map object https://stackoverflow.com/a/38708720
    const map = new maps.Map(document.createElement("div"));
    placesService = new maps.places.PlacesService(map);
    placesServiceStatusOk = maps.places.PlacesServiceStatus.OK;
};

export const doGetPlacePredictions = (searchText) =>
    new Promise((resolve) => {
        if (autocompleteService) {
            autocompleteService.getPlacePredictions(
                { bounds, input: searchText, sessionToken, types: ["geocode"] },
                (predictions, status) => {
                    if (status === placesServiceStatusOk) {
                        resolve(predictions);
                    } else {
                        const error = new Error("Google Places getPlacePredictions service returned result with non-OK status.");
                        // eslint-disable-next-line no-console
                        console.log(error);
                        resolve([]);
                    }
                },
            );
        } else {
            const error = new Error("Google Places autocompleteService was not initiated properly.");
            // eslint-disable-next-line no-console
            console.log(error);
            resolve([]);
        }
    });

export const doGetPlaceDetail = (placeId) =>
    new Promise((resolve) => {
        if (placesService) {
            placesService.getDetails({ fields: ["address_components"], placeId, sessionToken }, (placeResult, status) => {
                if (status === placesServiceStatusOk) {
                    resolve(placeResult);
                } else {
                    const error = new Error("Google Places getDetails service returned result with non-OK status.");
                    // eslint-disable-next-line no-console
                    console.log(error);
                    resolve({});
                }
            });
        } else {
            const error = new Error("Google Places placesService was not initiated properly.");
            // eslint-disable-next-line no-console
            console.log(error);
            resolve({});
        }
    });
