import { PageModule } from "app/activePages";

import { CorePackage } from "./corePackages";

const getAppReducers = (packages: CorePackage[], pageModules: PageModule[], extraReducers = {}) => {
    const packageReducers = packages
        .filter((module) => module.reducer)
        .reduce((result, module) => ({ [module.NAME]: module.reducer, ...result }), {});
    const pagesReducers = pageModules
        .map((pageModule) => pageModule.pageDefinition)
        .filter((pageDefinition) => pageDefinition.reducer)
        .reduce((result, pageDefinition) => ({ [pageDefinition.NAME]: pageDefinition.reducer, ...result }), {});
    return {
        ...packageReducers,
        ...pagesReducers,
        ...extraReducers,
    };
};

export default getAppReducers;
