import { Record } from "immutable";

class FrequencyNonLife extends Record({ id: null, msg: null }) {}

export default Object.freeze({
    QUARTERLY: new FrequencyNonLife({
        id: "QUARTERLY",
        msg: "common.quarterly",
    }),
    HALFYEARLY: new FrequencyNonLife({
        id: "HALFYEARLY",
        msg: "common.halfYearly",
    }),
    YEARLY: new FrequencyNonLife({
        id: "YEARLY",
        msg: "common.yearly",
    }),
});
