import { Record } from "immutable";

class MutualFundsDirectAmountType extends Record({ id: null, msg: null }) {}

export default Object.freeze({
    AMOUNT: new MutualFundsDirectAmountType({
        id: "AMOUNT",
        msg: "common.amount",
    }),
    QUANTITY: new MutualFundsDirectAmountType({
        id: "QUANTITY",
        msg: "investments.quantity",
    }),
    SELL_ALL: new MutualFundsDirectAmountType({
        id: "SELLALL",
        msg: "investments.sellAll",
    }),
});
