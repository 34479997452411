import createReducer from "./reducer";
import createActions from "./actions";
import createSelectors from "./selectors";
import createContainer from "./Container";
import createSaga from "./saga";

export { YEARLY_STATEMENT, EXTRA_STATEMENT, TAX_STATEMENT, ACTUAL_STATEMENT, YEARLY_STATEMENT_ONLY_LAST_YEAR } from "./constants";

export default ({
    reducerPath,
    actionPrefix,
    getId,
    enqueuedDocumentsEntity,
    documentType,
    fetchAvailableYears,
    yearToRemoveFromList,
    getPolicyBeginDateYear,
    documentsPath,
}) => {
    const actions = createActions(actionPrefix);
    const selectors = createSelectors(reducerPath, documentType, getPolicyBeginDateYear, yearToRemoveFromList, fetchAvailableYears);
    const Container = createContainer(
        getId,
        actions,
        selectors,
        documentType,
        fetchAvailableYears,
        getPolicyBeginDateYear,
        yearToRemoveFromList,
    );
    const saga = createSaga(actions, selectors, getId, documentType, documentsPath, enqueuedDocumentsEntity);
    const reducer = createReducer(actions, yearToRemoveFromList);

    return {
        saga,
        Container,
        reducer,
        setAvailableYears: actions.setAvailableYears,
        getAvailableYears: selectors.getAvailableYears,
        isError: selectors.isError,
        shouldDisplaySelector: selectors.showForm,
    };
};
