import React, { FC, PropsWithChildren } from "react";
import { StyledLeadLayout } from "ui-library/Styled";
import { BoxProps } from "@mui/material";

export interface LeadLayoutProps extends PropsWithChildren, BoxProps {}

export const LeadLayout: FC<LeadLayoutProps> = ({ children, ...props }) => {
    return (
        <StyledLeadLayout variant="white" shadow={true} {...props}>
            {children}
        </StyledLeadLayout>
    );
};
