import { model } from "core/util";
import { PensionDssContractDetail as PensionDssContractDetailType, PensionDssContractDetailDto } from "types";

import { Holder } from "../../holder";
import { ElectronicCommunication } from "../../electronicCommunication";
import { PensionBeneficiary } from "../PensionBeneficiary";
import { PensionDssAccountDetail } from "./PensionDssAccountDetail";

export const PensionDssContractDetail = model.createModel<PensionDssContractDetailDto, PensionDssContractDetailType>((json) => ({
    holder: Holder.fromServer(json.Holder),
    pensionDssAccountDetail: PensionDssAccountDetail.fromServer(json.PensionAccountDetail),
    beneficiaries: PensionBeneficiary.fromServerList(json.Beneficiaries),
    electronicCommunication: ElectronicCommunication.fromServer(json.ElectronicCommunication),
}));
