import { FC, PropsWithChildren } from "react";
import { StyledCircularLoader, StyledLoaderComponentWrapper, StyledLoaderWrapper } from "ui-library/Styled";

export interface LoadingProps extends PropsWithChildren {
    loading: boolean;
    fullPage?: boolean;
}

export const Loading: FC<LoadingProps> = ({ fullPage, loading, children }) => {
    if (fullPage && loading) {
        return (
            <StyledLoaderWrapper className="fullpage-loader">
                <StyledCircularLoader />
            </StyledLoaderWrapper>
        );
    } else if (fullPage && !loading) {
        return null;
    } else if (loading) {
        return (
            <StyledLoaderComponentWrapper>
                <StyledCircularLoader />
            </StyledLoaderComponentWrapper>
        );
    } else return <>{children}</>;
};
