import { Record } from "immutable";

class MutualFundsPeriodicity extends Record({ id: null, msg: null, type: null }) {}

export default Object.freeze({
    ONETIME: new MutualFundsPeriodicity({
        id: "ONETIME",
        msg: "investments.sellSwitch.oneTime",
    }),
    PERIODICAL: new MutualFundsPeriodicity({
        id: "PERIODICAL",
        msg: "investments.sellSwitch.periodical",
    }),
});
