import React, { FC } from "react";
import { StyledError } from "ui-library/Styled";
import { Icon, Typography } from "ui-library/atoms";

// Needs to be same as in core/form - ugly way of not passing this value.
const ERROR_COMPONENT_CLASS = "error_component_class";

export interface ErrorProps {
    content: string;
}

export const ErrorContent: FC<ErrorProps> = ({ content }) => {
    if (content !== "")
        return (
            <StyledError className={ERROR_COMPONENT_CLASS}>
                <Icon sx={{ marginRight: "5px" }} size={16} icon={"circle-error"} color={"red"} />
                <Typography variant="caption" color="red">
                    {content}
                </Typography>
            </StyledError>
        );
    else return null;
};
