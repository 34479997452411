import React from "react";
import { useSelector } from "react-redux";

import { Field, SingleSelect } from "core/form";
import i18n from "core/i18n";

import { getFinancialOperationsCategoryNames } from "../selectors";

export const FinancialOperationsBasicFilter = () => {
    const { t } = i18n.useTranslation();
    const categoryOptions = useSelector(getFinancialOperationsCategoryNames);

    return (
        <Field
            name="categoryName"
            component={SingleSelect}
            options={categoryOptions}
            placeholder={t("pension.financialOperation.selectName")}
        />
    );
};
