import { withTheme } from "@mui/styles";
import styled from "styled-components";

export const StyledImage = withTheme(styled("img")`
    width: 100%;
    height: 100%;
    object-fit: ${(props) => (props.$objectFit ? props.$objectFit : "fill")};

    border-radius: ${(props) => props.$borderRadius + "px"};
    box-shadow: ${(props) => (props.$shadow ? "0px 20px 35px 0px #30313d1a" : "")};

    filter: ${(props) => (props.$desatured ? "grayscale(100%)" : "")};
`);
