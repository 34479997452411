import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { Box, ContentBox } from "ui-library/layouts";
import { Button, Typography } from "ui-library/atoms";

import i18n from "core/i18n";
import { fn } from "core/util";

import { getNotApplicableRedeems, getPensionPlanGroup } from "../selectors";
import { NotApplicableRedeemsSection } from "containers/contract";

export const RedeemSavingsPensionPpNoOptionsText = ({ backButtonAction }) => {
    const { t } = i18n.useTranslation();
    const pensionPlanGroup = useSelector(getPensionPlanGroup);
    const pensionPlanDocumentLink = !fn.isEmpty(pensionPlanGroup) ? t(pensionPlanGroup.documentLink) : "";
    const pensionPlanGroupName = pensionPlanGroup?.name || "";

    return (
        <>
            <ContentBox>
                {/* First line */}
                <Typography component="p" markDown={t("pension.redeemSavings.pp.noOptions.part1", { pensionPlanGroupName })} />

                <NotApplicableRedeemsSection notApplicableRedeemsSelector={getNotApplicableRedeems} />

                {/* Second line */}
                <Typography component="p">
                    {t("pension.redeemSavings.pp.noOptions.part2", { pensionPlanGroupName })}
                    <a target="_blank" href={pensionPlanDocumentLink}>
                        {t("common.hereLowerCase")}
                    </a>
                    .
                </Typography>

                {/* Second line */}
                <Typography component="p" markDown={t("pension.redeemSavings.pp.noOptions.part3")} />
            </ContentBox>
            <Box mt={4}>
                <Button onClick={backButtonAction} variant="outlined" color="blue">
                    {t("common.back")}
                </Button>
            </Box>
        </>
    );
};

RedeemSavingsPensionPpNoOptionsText.propTypes = {
    backButtonAction: PropTypes.func.isRequired,
};
