import React from "react";
import PropTypes from "prop-types";

import { Typography } from "ui-library/atoms";
import { ContentBox, Section } from "ui-library/layouts";

import i18n from "core/i18n";

import { TRANSFER_FUNDS_DATA } from "../constants";
import { PercentageSelectDds } from "../components";
import { TransferExample } from "../components/textBlock";

const DdsTransferSection = ({ formName }) => {
    const { t } = i18n.useTranslation();

    return (
        <Section>
            {/* Section title */}
            <ContentBox>
                <Typography variant="h3">{`1. ${t("pension.fundsChange.transferPensionUnits.title")}`}</Typography>
            </ContentBox>

            {/* Section Disclaimer */}
            <ContentBox>
                <Typography component="p" markDown={t("pension.fundsChange.transferPensionUnits.dds.text")} />
            </ContentBox>

            {/* Transfer example */}
            <TransferExample isDds />

            {/* Form title */}
            <ContentBox>
                <Typography fontWeight={600} component="p">
                    {t("pension.fundsChange.transferPensionUnits.choose")}
                </Typography>
            </ContentBox>

            {/* Form */}
            <PercentageSelectDds formName={formName} sectionName={TRANSFER_FUNDS_DATA} />
        </Section>
    );
};

DdsTransferSection.propTypes = {
    formName: PropTypes.string.isRequired,
};

export default DdsTransferSection;
