export const NAME = "core/error-handling";

export const PAYMENT_UNAVAILABLE = "PAYMENT_UNAVAILABLE";

export const OTP_WRONG_MOBILE_NUMBER_EPIN = "OTP_WRONG_MOBILE_NUMBER_EPIN";
export const OTP_WRONG_MOBILE_NUMBER_FIN_OPERATIONS = "OTP_WRONG_MOBILE_NUMBER_FIN_OPERATIONS";
export const OTP_WRONG_MOBILE_ERROR_CODE = "SEC_SMS_004";

export const CONTRACT_FUNDS_SELL_SWITCH_UNAVAILABLE = "CONTRACT_FUNDS_SELL_SWITCH_UNAVAILABLE";
export const ACTIVE_OPERATIONS_PHONE_NUMBER_FORM_UNAVAILABLE = "ACTIVE_OPERATIONS_PHONE_NUMBER_FORM_UNAVAILABLE";
export const INVESTMENT_PROFILE_CHANGE_FORM_UNAVAILABLE = "INVESTMENT_PROFILE_CHANGE_FORM_UNAVAILABLE";

export interface ErrorHandlingState {
    serviceErrors: {
        [serviceName: string]: string[];
    };
}

export const initialState: ErrorHandlingState = {
    serviceErrors: {},
};
