import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import { InfoPanel, LabelTextItem } from "ui-library/modules";
import { Box, ContentBox, GridItem } from "ui-library/layouts";
import { Tab, TabPanel, Tabs, Typography } from "ui-library/atoms";

import entity from "core/entity";
import fetching from "core/fetching";
import i18n from "core/i18n";
import pageContext from "core/pageContext";
import { dateTimeToLocalFullDate, formatSurrenderAmount } from "core/formatters";

import { setStatementTab } from "../actions";
import { ENTITY_FINANCIAL_STATEMENT, STATEMENT_TAB_AXA, STATEMENT_TAB_UNIQA } from "../constants";
import { createGetStatementDate, getIsUniqaContract, getLifeAccountDetail, getStatementTab } from "../selectors";

import {
    AxaInvestmentsFundsTable,
    AxaInvestmentsStrategyTable,
    AxaInvestmentsTypeTable,
    UniqaInvestmentsFundsTable,
    UniqaInvestmentsStrategyTable,
} from "./table";
import { PieChartWithFundsTable } from "./PieChartWithFundsTable";

export const FinancialStatementGridItem: FC = () => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();
    const activeTab = useSelector(getStatementTab);

    const isSlovakSite = useSelector(pageContext.getIsSlovakSite);
    const isUniqaContract = useSelector(getIsUniqaContract);
    const isStatementLoading = useSelector(fetching.isFetching(ENTITY_FINANCIAL_STATEMENT, true));
    const isStatementError = useSelector(entity.isError(ENTITY_FINANCIAL_STATEMENT));

    const statementDate = useSelector(createGetStatementDate);

    const lifeAccountDetail = useSelector(getLifeAccountDetail);
    const surrenderAmount = formatSurrenderAmount(
        isUniqaContract,
        isSlovakSite,
        statementDate,
        lifeAccountDetail?.surrenderAmount,
        lifeAccountDetail?.surrenderDate,
    );
    const deathSurrenderAmount = formatSurrenderAmount(
        isUniqaContract,
        isSlovakSite,
        statementDate,
        lifeAccountDetail?.deathSurrenderAmount,
        lifeAccountDetail?.deathPaymentCalculationDate,
    );

    return (
        <GridItem xs={12}>
            <InfoPanel
                icon={"chart-line"}
                title={t("investments.investmentsState")}
                headerAction={
                    statementDate && (
                        <Typography color="gray">
                            {t("statements.dateTime", { statementDate: dateTimeToLocalFullDate(statementDate) })}
                        </Typography>
                    )
                }
            >
                {/* Surrender amounts */}
                {(surrenderAmount || deathSurrenderAmount) && (
                    <ContentBox bigger={!!statementDate}>
                        {surrenderAmount && <LabelTextItem label={t("life.surrenderAmount")} text={surrenderAmount} />}
                        {deathSurrenderAmount && <LabelTextItem label={t("life.deathSurrenderAmount")} text={deathSurrenderAmount} />}
                    </ContentBox>
                )}

                {/* Financial statement section is displayed based on whether we do have statement date or not */}
                {statementDate && (
                    <>
                        {/* Tab buttons */}
                        <Tabs value={activeTab} onChange={(event, newValue) => dispatch(setStatementTab(newValue))}>
                            <Tab label={isUniqaContract ? t("investments.state.BasedOnProgram") : t("investments.state.BasedOnFunds")} />
                            {!isUniqaContract && <Tab label={t("investments.state.BasedOfType")} />}
                            <Tab label={t("insurance.investmentStrategy")} />
                        </Tabs>

                        {/* Tab content */}
                        {isUniqaContract ? (
                            <>
                                <TabPanel value={activeTab} index={STATEMENT_TAB_UNIQA.BASED_ON_FUNDS}>
                                    <UniqaInvestmentsFundsTable />
                                </TabPanel>
                                <TabPanel value={activeTab} index={STATEMENT_TAB_UNIQA.INVESTMENT_STRATEGY}>
                                    <UniqaInvestmentsStrategyTable />
                                </TabPanel>
                            </>
                        ) : (
                            <>
                                <TabPanel value={activeTab} index={STATEMENT_TAB_AXA.BASED_ON_FUNDS}>
                                    <AxaInvestmentsFundsTable />
                                </TabPanel>
                                <TabPanel value={activeTab} index={STATEMENT_TAB_AXA.BASED_ON_TYPE}>
                                    <AxaInvestmentsTypeTable />
                                </TabPanel>
                                <TabPanel value={activeTab} index={STATEMENT_TAB_AXA.INVESTMENT_STRATEGY}>
                                    <AxaInvestmentsStrategyTable />
                                </TabPanel>
                            </>
                        )}

                        {/* Chart and sum data */}
                        {!isStatementLoading && !isStatementError && (
                            <Box>
                                <Typography variant="h4">{t("insurance.investmentStateBasedOnFunds")}</Typography>
                                <Box>
                                    <PieChartWithFundsTable />
                                </Box>
                            </Box>
                        )}
                    </>
                )}
            </InfoPanel>
        </GridItem>
    );
};
