import { Container, Typography } from "ui-library/atoms";
import { ContentBox, GridItem, GridLayout, LeadOffsetLayout } from "ui-library/layouts";
import { InfoBox } from "ui-library/modules";
import i18n from "core/i18n";

export const LeadPanels = () => {
    const { t } = i18n.useTranslation();

    return (
        <Container>
            <LeadOffsetLayout>
                <GridLayout alignItems="center" spacing={4}>
                    <GridItem display="flex" justifyContent={"center"} xs={12} md={4}>
                        <InfoBox iconText={"1."} title={t("page.registration.panels.stepOne.title")}>
                            <ContentBox bigger>
                                <Typography varioant="caption" color={"gray"}>
                                    {t("page.registration.panels.stepOne.content")}
                                </Typography>
                            </ContentBox>
                        </InfoBox>
                    </GridItem>

                    <GridItem display="flex" justifyContent={"center"} xs={12} md={4}>
                        <InfoBox iconText={"2."} title={t("page.registration.panels.stepTwo.title")}>
                            <ContentBox bigger>
                                <Typography varioant="caption" color={"gray"}>
                                    {t("page.registration.panels.stepTwo.content")}
                                </Typography>
                            </ContentBox>
                        </InfoBox>
                    </GridItem>

                    <GridItem display="flex" justifyContent={"center"} xs={12} md={4}>
                        <InfoBox iconText={"3."} title={t("page.registration.panels.stepThree.title")}>
                            <ContentBox bigger>
                                <Typography varioant="caption" color={"gray"}>
                                    {t("page.registration.panels.stepThree.content")}
                                </Typography>
                            </ContentBox>
                        </InfoBox>
                    </GridItem>
                </GridLayout>
            </LeadOffsetLayout>
        </Container>
    );
};
