import { fn } from "core/util";
import { TFunction } from "core/i18n";

export const formatEmptyValueWithDash = (value) => (fn.isEmpty(value) ? "-" : value);

export const formatValueOrEmptyString = (value) => {
    if (value) {
        return value;
    } else {
        return "";
    }
};

export const formatTwoValuesToOneText = (firstValue: string, secondValue: string): string => {
    let result = "";

    if (firstValue) {
        result += firstValue;
    }
    if (secondValue) {
        result += ` ${secondValue}`;
    }

    return result.trim();
};

export const formatYesNo = (t: TFunction, value: boolean) => (value ? t("common.yes") : t("common.no"));
