import { createModel } from "./createModel";
import createModelLegacy from "./createModelLegacy";
import { getAddressByTypeCode } from "./getAddressByTypeCode";
import { getAddressByTypeCodeLegacy } from "./getAddressByTypeCodeLegacy";
import { getPersonId } from "./getPersonId";
import { getPersonIdLegacy } from "./getPersonIdLegacy";
import { getLocaleCodeName } from "./getLocaleCodeName";
import getHyperLinkForCodeList from "./getHyperLinkForCodeList";
import getHyperLinkForPensionsCodeList from "./getHyperLinkForPensionsCodeList";
import { getPlainObjAddressByTypeCode } from "./getPlainObjAddressByTypeCode";
import getPlainObjAddressByTypeCodeLegacy from "./getPlainObjAddressByTypeCodeLegacy";
import { OPTION_ALL } from "./constants";

const model = {
    OPTION_ALL,
    createModel,
    createModelLegacy,
    getAddressByTypeCode,
    getAddressByTypeCodeLegacy,
    getHyperLinkForCodeList,
    getHyperLinkForPensionsCodeList,
    getPersonId,
    getPersonIdLegacy,
    getLocaleCodeName,
    getPlainObjAddressByTypeCode,
    getPlainObjAddressByTypeCodeLegacy,
};

export default model;
