import React from "react";
import { useSelector } from "react-redux";
import { GridLayout } from "ui-library/layouts";

import { ModalClaimNoteForm, ModalUploadClaimDocumentsForm } from "../form";
import { getClaimData, getIsMultiClaimIncident, getSelectedClaimId } from "../selectors";

import { ClaimSelect } from "./ClaimSelect";
import { ClaimStateTimeLine } from "./ClaimStateTimeLine";
import { ClaimDocuments } from "./ClaimDocuments";
import { ClaimDetailLife } from "./ClaimDetailLife";
import { ClaimDetailVehicle } from "./ClaimDetailVehicle";
import { ClaimDetailOther } from "./ClaimDetailOther";

const getClaimDetail = (claimData) => {
    switch (claimData.claimCategory) {
        case "CAR":
            return <ClaimDetailVehicle />;
        case "PERSON":
            return <ClaimDetailLife />;
        default:
            return <ClaimDetailOther />;
    }
};

export const ClaimDetail = () => {
    const isMultiClaimIncident = useSelector(getIsMultiClaimIncident);
    const selectedClaimId = useSelector(getSelectedClaimId);
    const claimData = useSelector(getClaimData);

    return (
        <GridLayout defaultSpacing>
            {isMultiClaimIncident && <ClaimSelect />}

            {selectedClaimId && (
                <>
                    <ClaimStateTimeLine />

                    {getClaimDetail(claimData)}

                    <ClaimDocuments />
                </>
            )}

            {/* Modal */}
            <ModalClaimNoteForm />
            <ModalUploadClaimDocumentsForm />
        </GridLayout>
    );
};
