import { call, fork, put, select } from "redux-saga/effects";

import { Params, Tabs } from "routeConstants";
import fetching from "core/fetching";
import { sentry } from "core/util";
import { paymentApi } from "serverApi";
import router from "core/router";
import userContracts from "core/userContracts";
import errorHandling, { PAYMENT_UNAVAILABLE } from "core/errorHandling";
import { formWrapper } from "core/form";
import { PaymentAmountType } from "enums";

import { CREATE_PAYMENT_LINK_FORM, NAME } from "./constants";
import { setSelects } from "./actions";
import { getAmountType, getContractId, getProductGroupNameUrlParam } from "./selectors";

export default router.routerWrapper({
    *onInnerRouteChange(name, params, query) {
        yield call(routeChangeSaga, name, query);
    },
    clearDataForPage() {
        return [setSelects(null, null, null, null, null), errorHandling.removeServiceErrors([PAYMENT_UNAVAILABLE])];
    },
});

function* routeChangeSaga(name, query) {
    switch (name) {
        case Tabs.PAYMENTS_PAY: {
            const contractId = query[Params.PAYMENT_CONTRACT_ID];
            const fundId = query[Params.PAYMENT_FUND_ID];
            const amountType = query[Params.PAYMENT_AMOUNT_TYPE];

            yield* fetching.withFetching(NAME, function* () {
                if (contractId) {
                    try {
                        const productGroup = yield select(userContracts.createGetProductGroupUrlNameById(contractId));
                        const info = yield call(paymentApi.get, productGroup, contractId);

                        if (!info.fundsToInvest.isEmpty() && !fundId) {
                            yield put(
                                setSelects(
                                    info,
                                    productGroup,
                                    contractId,
                                    info.fundsToInvest.first().fund.code,
                                    PaymentAmountType.ADVANCED,
                                ),
                            );
                        } else if (info.fundsToInvest.isEmpty() && fundId) {
                            yield put(setSelects(info, productGroup, contractId, fundId, PaymentAmountType.ADVANCED));
                        } else if (amountType) {
                            yield put(setSelects(info, productGroup, contractId, null, amountType));
                        } else {
                            yield put(setSelects(info, productGroup, contractId, fundId, null));
                        }
                    } catch (e) {
                        sentry.captureException(e);
                        yield put(errorHandling.addServiceError(PAYMENT_UNAVAILABLE, e.identifier));
                    }
                } else {
                    yield put(setSelects(null, null, null, null, null));
                }
            });
            yield fork(createPaymentLinkFormSaga);
            break;
        }
        default:
            break;
    }
}

const createPaymentLinkFormSaga = formWrapper(CREATE_PAYMENT_LINK_FORM, {
    *save() {
        const productGroup = yield select(getProductGroupNameUrlParam);
        const idObject = yield select(getContractId);
        const amountType = yield select(getAmountType);
        const paymentLinkObj = yield call(paymentApi.getPaymentLink, productGroup, idObject, amountType);
        window.open(paymentLinkObj.paymentLink, "_self");
    },
});
