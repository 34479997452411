function NotAuthorizedError(error) {
    this.status = error.status;
    if (error.response) {
        this.response = error.response.body;
        this.identifier = error.response.headers?.identifier;
    }
}

NotAuthorizedError.prototype = Object.create(Error.prototype);
NotAuthorizedError.prototype.name = "NotAuthorizedError";
NotAuthorizedError.prototype.constructor = NotAuthorizedError;

export default NotAuthorizedError;
