import React from "react";
import PropTypes from "prop-types";

import { Flexbox } from "ui-library/layouts";

import { BackToRedeemSavingsButton } from "./BackToRedeemSavingsButton";

export const StepThreeButtonsBox = ({ idObject }) => {
    return (
        <Flexbox mt={6} gap={2} justifyContent="space-between">
            <BackToRedeemSavingsButton idObject={idObject} />
        </Flexbox>
    );
};

StepThreeButtonsBox.propTypes = {
    idObject: PropTypes.number.isRequired,
};
