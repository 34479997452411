import { withTheme } from "@mui/styles";

import { Box } from "ui-library/layouts/Box/Box";
import styled from "styled-components";

export const StyledProductPageLayout = withTheme(styled(Box)`
    padding: 96px 0 64px;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        padding: 24px 0 32px;
    }
`);
