import React, { Component } from "react";
import PropTypes from "prop-types";
import { v4 as uuid } from "uuid";
import { List } from "immutable";
import { connect } from "react-redux";

import i18n from "core/i18n";

import { TO_TRANSLATE_PREFIX } from "./validation";

class FormField extends Component {
    constructor(props) {
        super(props);
        this.id = uuid();
    }

    render() {
        const { inputComponent, ...rest } = this.props;
        const InputComponent = inputComponent;

        return <InputComponent {...rest} id={this.id} />;
    }
}

FormField.propTypes = {
    inputComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
};

const mapStateToProps = (state, { meta }) => {
    /**
     * Ugly ugly hack, but best performance. We change INSIDE of the passed object, so the reference is still same.
     */
    // eslint-disable-next-line no-param-reassign
    meta.error = (meta.error || List()).map((msg) => {
        if (msg.startsWith(TO_TRANSLATE_PREFIX)) {
            return i18n.translateDirectly(msg.replace(TO_TRANSLATE_PREFIX, ""));
        }
        return msg;
    });
    return { meta };
};

export default connect(mapStateToProps)(FormField);
