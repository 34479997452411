import React, { FC } from "react";
import { useSelector } from "react-redux";

import { DataTable } from "ui-library/modules";

import i18n, { TFunction } from "core/i18n";
import localized from "core/localized";
import {
    dateTimeToLocalFullDate,
    formatAmountOrNumberOfShares,
    formatLocalizedValueMap,
    FormatMutualFundsAccountPayment,
} from "core/formatters";
import { fn } from "core/util";
import { FundSellScope, MutualFunds } from "enums";

import { DetailLinkAction } from "./DetailLinkAction";

const columns = (t: TFunction, sellSwitchMap, frequencyMap, changePropositionStatusMap, actions) => [
    {
        sortable: false,
        field: "creationDateTime",
        headerName: t("changeProposition.date"),
        minWidth: 100,
        renderCell: (params) => dateTimeToLocalFullDate(params.value),
    },
    {
        sortable: false,
        field: "data-type",
        headerName: t("changeProposition.transactionType"),
        minWidth: 80,
        renderCell: (params) =>
            formatLocalizedValueMap(params.row.changeProposition?.mutualFundsTransaction?.transactionType?.code, sellSwitchMap),
    },
    {
        sortable: false,
        field: "data-fund",
        headerName: t("investments.transactionFundFrom"),
        minWidth: 160,
        renderCell: (params) => {
            const transaction = params.row.changeProposition?.mutualFundsTransaction;
            if (transaction?.sellScope === FundSellScope.PROGRAM.id) {
                return t("investments.sellSwitch.scope.program");
            }
            const fundEnum = fn.getEnumItemByCode(MutualFunds, transaction?.transactionFundFrom?.code);
            return t(fundEnum?.msg);
        },
    },
    {
        sortable: false,
        field: "data-periodicity",
        headerName: t("investments.transactionInterval"),
        minWidth: 110,
        renderCell: (params) =>
            formatLocalizedValueMap(params.row.changeProposition?.mutualFundsTransaction?.periodicityType, frequencyMap),
    },
    {
        sortable: false,
        field: "data-value",
        headerName: t("investments.transactionValue"),
        minWidth: 110,
        renderCell: (params) => {
            const transaction = params.row.changeProposition?.mutualFundsTransaction;
            return formatAmountOrNumberOfShares(
                transaction?.transactionValueType,
                transaction?.transactionValue,
                transaction.numberOfSharesInTransaction,
                t("investments.sellAll"),
            );
        },
    },
    {
        sortable: false,
        field: "data-target",
        headerName: t("investments.transactionFundTo"),
        minWidth: 240,
        renderCell: (params) =>
            FormatMutualFundsAccountPayment({ value: params.row.changeProposition?.mutualFundsTransaction?.transactionTo }),
    },
    {
        sortable: false,
        field: "status",
        headerName: t("changeProposition.state"),
        flex: 2,
        renderCell: (params) => formatLocalizedValueMap(params.value, changePropositionStatusMap),
    },
    {
        sortable: false,
        field: "id",
        headerName: t("changeProposition.detail"),
        flex: 2,
        renderCell: (params) => <DetailLinkAction request={params.row} actions={actions} />,
    },
];

export interface ChangePropositionsTransactionTableProps {
    actions: any;
    selectors: any;
}

export const ChangePropositionsTransactionTable: FC<ChangePropositionsTransactionTableProps> = ({ selectors, actions }) => {
    const { t } = i18n.useTranslation();
    const sellSwitchMap = useSelector(localized.getLocalizedFundSellSwitchMap);
    const frequencyMap = useSelector(localized.getLocalizedMutualFundsPeriodicityMap);
    const changePropositionStatusMap = useSelector(localized.getLocalizedChangePropositionStatusMap);

    const data = useSelector(selectors.getChangePropositionsTransactionData);

    return (
        <DataTable
            withWrapper={false}
            disableColumnReorder
            headerHeight={60}
            rowHeight={55}
            getRowId={(row) => row.id}
            // @ts-ignore
            rows={data && data.toArray()}
            columns={columns(t, sellSwitchMap, frequencyMap, changePropositionStatusMap, actions)}
        />
    );
};
