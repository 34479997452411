import React, { FC } from "react";

import { formatBirthNumber, localFullDate, oneLineNameWithTitles } from "core/formatters";
import i18n from "core/i18n";
import { getLocalizedOneLineAddressFull } from "core/localized";
import { model } from "core/util";
import { Box } from "ui-library/layouts";
import { LabelTextItem } from "ui-library/modules";
import { AddressTypeCodeEnum, Holder } from "types";

type HolderPersonSectionProps = {
    holder: Holder;
    isExtended: boolean;
    showInsuranceNumber: boolean;
};

export const HolderPersonSection: FC<HolderPersonSectionProps> = ({ holder, isExtended, showInsuranceNumber }) => {
    const { t } = i18n.useTranslation();
    const personId = model.getPersonId(holder.ids, t);
    const birthSSNumberTile = birthAndInsuranceNumberTitle(t("holder.birthNumber"), showInsuranceNumber, t("holder.insuranceNumber"));

    return (
        <Box>
            <LabelTextItem label={t("holder.fullName")} text={oneLineNameWithTitles(holder)} />
            {isExtended && <LabelTextItem label={t("holder.citizenship")} text={holder.citizenship} />}
            <LabelTextItem label={t("holder.birthDate")} text={localFullDate(holder.birthDate)} />
            <LabelTextItem label={birthSSNumberTile} text={formatBirthNumber(holder.birthNumber)} />
            {isExtended && <LabelTextItem label={personId.label} text={personId.number} />}
            <LabelTextItem
                label={t("holder.permanentAddress")}
                text={getLocalizedOneLineAddressFull(t, model.getAddressByTypeCode(holder, AddressTypeCodeEnum.PER))}
            />
        </Box>
    );
};

const birthAndInsuranceNumberTitle = (birthNumber: string, showInsuranceNumber: boolean, insuranceNumber: string) =>
    showInsuranceNumber ? birthNumber.concat(" / ").concat(insuranceNumber) : birthNumber;
