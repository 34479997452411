import { withTheme } from "@mui/styles";
import styled from "styled-components";
import { Tooltip } from "@mui/material";

export const StyledTooltip = withTheme(styled(Tooltip)`
    max-height: 18px;

    &:hover,
    &:focus,
    &:active {
        cursor: pointer;
        svg path {
            fill: ${(props) => props.theme.palette.blue.main};
        }
    }
`);
