import PropTypes from "prop-types";
import React from "react";
import { Icon, Typography } from "ui-library/atoms";
import { Box, Flexbox } from "ui-library/layouts";
import { StyledLoggedUser } from "ui-library/Styled";
import useBreakpoints from "ui-library/theme/breakpoints";

export const LoggedUser = ({ name, open, isImpersonation, impersonator, ...props }) => {
    const textColor = isImpersonation ? "orange" : "gray";
    return (
        <StyledLoggedUser alignItems={"center"} $open={open} {...props}>
            <Flexbox alignItems={"center"} p={{ xs: 1.5, md: 0 }} mr={{ xs: 0, md: 1 }}>
                {isImpersonation && <Icon color={textColor} size={18} icon={"user-check"} />}
                <Icon color={textColor} size={18} icon={"user"} />
            </Flexbox>
            {useBreakpoints("md") && (
                <>
                    <Box mr={2}>
                        <Typography variant="link" color={textColor}>
                            {name}
                            {isImpersonation && (
                                <>
                                    <br />
                                    {`(${impersonator})`}
                                </>
                            )}
                        </Typography>
                    </Box>
                    <Flexbox alignItems={"center"}>
                        <Icon className={"icon"} size={16} color="blue" icon={"chevron-down"} />
                    </Flexbox>
                </>
            )}
        </StyledLoggedUser>
    );
};

LoggedUser.propTypes = {
    name: PropTypes.string,
    open: PropTypes.bool,
    isImpersonation: PropTypes.bool,
};

LoggedUser.defaultProps = {
    name: "Pouzivatel",
    isImpersonation: false,
    impersonator: null,
};
