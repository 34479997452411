import React from "react";

import i18n from "core/i18n";
import { email, emailCharacters, Field, InputText, max50Characters, required } from "core/form";
import { ContentBox, Section } from "ui-library/layouts";
import { SectionDescription, SectionTitle } from "../components";

const EmailFormBox = () => {
    const { t } = i18n.useTranslation();

    return (
        <Section>
            <SectionTitle title={t("holder.emailAddress")} />
            <SectionDescription content={t("form.editPolicyHolder.changeEmail")} />
            <ContentBox>
                <Field
                    name="email"
                    component={InputText}
                    label={t("holder.emailAddress")}
                    validateStatic={[email, required]}
                    normalize={[emailCharacters, max50Characters]}
                    preventDefaultNormalization
                />
            </ContentBox>
        </Section>
    );
};

export default EmailFormBox;
