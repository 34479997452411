import React from "react";

import { ButtonsLayout, ContentBox, GridItem } from "ui-library/layouts";
import { InfoPanel } from "ui-library/modules";
import { Loading } from "ui-library/atoms";

import i18n from "core/i18n";
import pageContext from "core/pageContext";

import { FeatureFlag } from "types";
import { ReportClaimButton, ReportClaimButtonOld } from "containers";

export const ReportClaimGridItem = ({ idObject, claimCategoryId, compliantLineOfBusinessClasses, isContractOverviewLoading }) => {
    const isClaimReportNewEnabled = pageContext.useIsFeatureFlagEnabled(FeatureFlag.CLAIM_REPORT_NEW);
    const { t } = i18n.useTranslation();
    return (
        <GridItem xs={12}>
            <InfoPanel icon={"bullhorn"} title={t("page.reportClaim.title")}>
                <ContentBox bigger>
                    <Loading loading={isContractOverviewLoading}>
                        <ButtonsLayout>
                            {isClaimReportNewEnabled ? (
                                <ReportClaimButton
                                    compliantLineOfBusinessClasses={compliantLineOfBusinessClasses}
                                    isContractOverviewLoading={isContractOverviewLoading}
                                    idObject={idObject}
                                    categoryParam={claimCategoryId}
                                    isOutlined={true}
                                />
                            ) : (
                                <ReportClaimButtonOld isOutlined={true} />
                            )}
                        </ButtonsLayout>
                    </Loading>
                </ContentBox>
            </InfoPanel>
        </GridItem>
    );
};
