import { combineReducers } from "redux-immutable";

import { app } from "core/util";
import { UniqaIncidentDetail } from "model/uniqaClaims";

import { CLEAR, SET_DOCUMENT_TO_UPLOAD_REACT_KEY, SET_INCIDENT_DETAIL, SET_SELECTED_CLAIM_ID } from "./actions";

export default combineReducers({
    data: combineReducers({
        incidentDetail: app.createDataReducer(SET_INCIDENT_DETAIL, UniqaIncidentDetail.fromServer(), CLEAR),
    }),
    appState: combineReducers({
        selectedClaimId: app.createDataReducer(SET_SELECTED_CLAIM_ID, "", CLEAR),
        selectedDocumentToUploadReactKey: app.createDataReducer(SET_DOCUMENT_TO_UPLOAD_REACT_KEY, null, CLEAR),
    }),
});
