import { FC } from "react";

import { Flexbox, FlexboxProps } from "./Flexbox";

export const BannerFlexbox: FC<FlexboxProps> = ({ direction, justifyContent, alignItems, gap, children, ...props }) => {
    return (
        <Flexbox alignItems={{ md: "flex-start" }} justifyContent={{ xs: "flex-start" }} direction={{ xs: "column", lg: "row" }} gap={2}>
            {children}
        </Flexbox>
    );
};
