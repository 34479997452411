import { call } from "redux-saga/effects";

import { paymentApi } from "serverApi";
import router from "core/router";

import { setError, setPaymentSuccessful } from "./actions";

export default router.routerWrapper({
    *getDataForPage(params) {
        try {
            const result = yield call(paymentApi.getPaymentStatus, params.requestId);
            return [setPaymentSuccessful(result.isSuccessful)];
        } catch (e) {
            return [setError()];
        }
    },
    clearDataForPage() {
        return [setPaymentSuccessful(null)];
    },
});
