import { v4 as uuid } from "uuid";

import createModel from "../createModel";

export const UniqaClaimInspectionOrder = createModel("UniqaClaimInspectionOrder", (json) => ({
    reactKey: uuid(),
    supplier: json.supplier,
    tasks: json.tasks,
    plannedDate: json.plannedDate,
    state: json.state,
}));
