import React from "react";
import PropTypes from "prop-types";

import { Box, ContentBox } from "ui-library/layouts";
import { Button, Typography } from "ui-library/atoms";

import i18n from "core/i18n";

export const RedeemSavingsNotPossibleText = ({ descriptionText, backButtonAction }) => {
    const { t } = i18n.useTranslation();
    return (
        <>
            <ContentBox>
                <Typography markDown={descriptionText} />
            </ContentBox>
            <Box mt={4}>
                <Button onClick={backButtonAction} variant="outlined" color="blue">
                    {t("common.back")}
                </Button>
            </Box>
        </>
    );
};

RedeemSavingsNotPossibleText.propTypes = {
    descriptionText: PropTypes.string.isRequired,
    backButtonAction: PropTypes.func.isRequired,
};
