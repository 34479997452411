import { CodeValue } from "types";

import fn from "../fn";

export const getLocaleCodeName = (codeValue: CodeValue, languageCode: string): string => {
    const localizedNames = (codeValue && codeValue.localizedName) || [];
    const localizedName = localizedNames.find((name) => name?.languageCode?.toUpperCase() === languageCode?.toUpperCase());
    if (fn.isEmpty(localizedName)) {
        const firstItem = localizedNames.find((item) => !fn.isEmpty(item.name));
        return firstItem ? firstItem.name : "";
    } else {
        return localizedName.name;
    }
};
