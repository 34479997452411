import React from "react";
import PropTypes from "prop-types";

import i18n from "core/i18n";
import { Icon, Option, Select, Typography } from "ui-library/atoms";
import { Box, Flexbox } from "ui-library/layouts";
import { StyledPaging } from "ui-library/Styled";
import useBreakpoints from "ui-library/theme/breakpoints";

export const TablePagination = ({ page, pageCount, pageSize, apiRef, rowsPerPageOptions }) => {
    const { t } = i18n.useTranslation();

    const handlePageSizeChange = (event) => {
        apiRef.current.setPageSize(event.target.value);
    };

    const handleFirst = () => {
        if (page !== 0 && pageCount > 1) {
            apiRef.current.setPage(0);
        }
    };

    const handlePrev = () => {
        if (page - 1 >= 0 && pageCount > 1) {
            apiRef.current.setPage(page - 1);
        }
    };

    const handleNext = () => {
        if (page + 1 < pageCount && pageCount > 1) {
            apiRef.current.setPage(page + 1);
        }
    };

    const handleLast = () => {
        if (page + 1 < pageCount && pageCount > 1) {
            apiRef.current.setPage(pageCount);
        }
    };

    const prevDisabled = () => {
        if (page === 0) {
            return true;
        } else {
            return false;
        }
    };

    const nextDisabled = () => {
        if (page + 1 >= pageCount) {
            return true;
        } else {
            return false;
        }
    };

    const isMdBreak = useBreakpoints("md");

    return (
        <StyledPaging
            flexDirection={{ xs: "column", md: "row" }}
            mt={{ xs: 1, md: 3 }}
            alignItems="center"
            justifyContent="flex-end"
            gap={{ xs: 1, md: 6 }}
        >
            {isMdBreak && (
                <Flexbox gap={1} alignItems={"center"}>
                    <Typography variant="caption" color="gray">
                        {t("common.rows")}
                    </Typography>
                    <Box maxWidth={"60px"}>
                        <Select id="pageSize" value={pageSize} onChange={handlePageSizeChange}>
                            {rowsPerPageOptions.map((item, index) => (
                                <Option key={index} value={item}>
                                    {item}
                                </Option>
                            ))}
                        </Select>
                    </Box>
                </Flexbox>
            )}

            <Flexbox gap={1}>
                <Typography variant="caption" color="gray">
                    {t("common.page")}
                </Typography>
                <Typography variant="caption">
                    {page + 1} z {pageCount}
                </Typography>
            </Flexbox>

            <Flexbox gap={1}>
                <Box onClick={handleFirst} className={"page-icon " + (prevDisabled() ? " disabled" : "able")}>
                    <Icon icon="chevron-left-last" />
                </Box>
                <Box onClick={handlePrev} className={"page-icon " + (prevDisabled() ? "disabled" : "able")}>
                    <Icon icon="chevron-left" />
                </Box>
                <Box onClick={handleNext} className={"page-icon " + (nextDisabled() ? "disabled" : "able")}>
                    <Icon icon="chevron-right" />
                </Box>
                <Box onClick={handleLast} className={"page-icon " + (nextDisabled() ? "disabled" : "able")}>
                    <Icon icon="chevron-right-last" />
                </Box>
            </Flexbox>
        </StyledPaging>
    );
};

TablePagination.propTypes = {
    page: PropTypes.number,
    pageCount: PropTypes.number,
    pageSize: PropTypes.number,
    apiRef: PropTypes.object,
    rowsPerPageOptions: PropTypes.array,
};

TablePagination.defaultProps = {};
