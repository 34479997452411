import { fetch } from "core/util";
import { API_PREFIX } from "app/constants";

const productUriPrefix = `${API_PREFIX}/product/mutual-funds`;
const cancelInstruction = (idObject, idRequest, values) =>
    fetch.doPut(`${productUriPrefix}/cancel-instruction`, {
        ...values,
        idObject,
        idRequest,
    });

const statementSettings = (body) => fetch.doPut(`${productUriPrefix}/statement-settings`, body);

export default {
    cancelInstruction,
    statementSettings,
};
