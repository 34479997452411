import { all, call, put, select } from "redux-saga/effects";
import { change } from "redux-form/immutable";

import { getFormFieldValue } from "core/form";
import { fn } from "core/util";
import { PlaceDetail } from "model/autocomplete";
import { autocompleteApi } from "serverApi";

import {
    CITY_FORM_FIELD,
    CONTRACT_TERMINATION_DATE_FIELD,
    DESCRIPTION_NUMBER_FORM_FIELD,
    INSURANCE_COMPANY_FIELD,
    ORIENTATION_NUMBER_FORM_FIELD,
    PAYMENT_TYPE_FIELD,
    PENSION_TYPE_FIELD,
    REDEEM_EXTRAORDINARY_PAYMENT_PERCENT_FIELD,
    REDEEM_PAYMENT_FREQUENCY_FIELD,
    REDEEM_PERIOD_IN_MONTHS_FIELD,
    REDEEM_START_FROM_FIELD,
    REDEEM_TYPE_FIELD,
    STATE_FORM_FIELD,
    STREET_FORM_FIELD,
    ZIP_FORM_FIELD,
} from "./constants";

export function* placeSelectedSaga(formName, addressType, { payload }) {
    const detail = yield call(autocompleteApi.getPlaceDetail, payload);
    const placeDetailModel = PlaceDetail.fromServer(detail);

    yield all([
        put(change(formName, `${addressType}.${STREET_FORM_FIELD}`, placeDetailModel.route)),
        put(change(formName, `${addressType}.${DESCRIPTION_NUMBER_FORM_FIELD}`, placeDetailModel.premise)),
        put(change(formName, `${addressType}.${ORIENTATION_NUMBER_FORM_FIELD}`, placeDetailModel.street_number)),
        put(change(formName, `${addressType}.${CITY_FORM_FIELD}`, placeDetailModel.locality)),
        put(change(formName, `${addressType}.${ZIP_FORM_FIELD}`, placeDetailModel.postal_code)),
        put(change(formName, `${addressType}.${STATE_FORM_FIELD}`, placeDetailModel.country)),
    ]);
}
export function* prepareDefaultTypeValues(redeemAllowedPensionTypesSelector, redeemAllowedRedeemTypesSelector) {
    let defaultPensionType = null;
    let defaultRedeemType = null;

    const allowedPensionTypes = yield select(redeemAllowedPensionTypesSelector);
    if (allowedPensionTypes.size === 1) {
        defaultPensionType = allowedPensionTypes.first().id;
        const allowedRedeemTypes = yield select(redeemAllowedRedeemTypesSelector(allowedPensionTypes.first()));
        if (allowedRedeemTypes.size === 1) {
            defaultRedeemType = allowedRedeemTypes.first().id;
        }
    }

    return {
        defaultPensionType,
        defaultRedeemType,
    };
}

export function* pensionTypeFieldChangeSaga(formName, pensionTypeEnum, redeemAllowedRedeemTypesSelector) {
    const selectedPensionTypeFormValue = yield select(getFormFieldValue(formName, PENSION_TYPE_FIELD));
    if (fn.isEmpty(selectedPensionTypeFormValue)) {
        return;
    }

    // On every pension type change - we need to clean up typed in values.
    yield put(change(formName, REDEEM_START_FROM_FIELD, null));
    yield put(change(formName, REDEEM_PAYMENT_FREQUENCY_FIELD, null));
    yield put(change(formName, REDEEM_PERIOD_IN_MONTHS_FIELD, null));
    yield put(change(formName, REDEEM_EXTRAORDINARY_PAYMENT_PERCENT_FIELD, null));
    yield put(change(formName, CONTRACT_TERMINATION_DATE_FIELD, null));
    yield put(change(formName, INSURANCE_COMPANY_FIELD, null));
    yield put(change(formName, PAYMENT_TYPE_FIELD, null));

    // Handle situation when for selected pension type only 1 redeem type exits.
    // In this case no select box is displayed and redeem type is set automatically.
    const selectedPensionType = fn.getEnumItemById(pensionTypeEnum, selectedPensionTypeFormValue);
    const allowedRedeemTypes = yield select(redeemAllowedRedeemTypesSelector(selectedPensionType));

    if (allowedRedeemTypes.size === 1) {
        yield put(change(formName, REDEEM_TYPE_FIELD, allowedRedeemTypes.first().id));
    } else {
        yield put(change(formName, REDEEM_TYPE_FIELD, null));
    }
}
