import React from "react";
import { useSelector } from "react-redux";

import pageContext from "core/pageContext";
import { Countries } from "enums";

import CzQrCode from "./CzQrCode";
import SkQrCode from "./SkQrCode";

export const LocalQrCode = () => {
    const siteId = useSelector(pageContext.getSiteId);
    if (siteId === Countries.CZ.id) {
        return <CzQrCode />;
    } else if (siteId === Countries.SK.id) {
        return <SkQrCode />;
    }
    return null;
};
