import { combineReducers } from "redux-immutable";

import userInfo from "core/userInfo";

import { SET_PERSONAL_DATA, SET_SELECTED_CONTRACT_GDPR_DATA } from "./actions";
import { PAGE_STATE } from "./constants";

const selectedContractGdprData = (state = null, action) => {
    switch (action.type) {
        case SET_PERSONAL_DATA:
            return action.payload?.get(0).objectId;
        case SET_SELECTED_CONTRACT_GDPR_DATA:
            return action.payload;
        default:
            return state;
    }
};

const gdprPageState = (state = PAGE_STATE.INITIAL, { type }) => {
    switch (type) {
        case userInfo.revokingGdprActionGroup.START:
            return PAGE_STATE.REVOKING;
        case userInfo.acceptingGdprActionGroup.START:
            return PAGE_STATE.ACCEPTING;
        case userInfo.revokingGdprActionGroup.RESPONSE:
        case userInfo.acceptingGdprActionGroup.RESPONSE:
        case userInfo.revokingGdprActionGroup.CANCEL:
        case userInfo.acceptingGdprActionGroup.CANCEL:
            return PAGE_STATE.INITIAL;
        default:
            return state;
    }
};

export default combineReducers({
    data: combineReducers({
        gdprPageState,
    }),
    appState: combineReducers({
        selectedContractGdprData,
    }),
});
