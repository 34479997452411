import { NAME } from "./constants";

export const START_SUBMIT = `${NAME}/START_SUBMIT`;
export const ASYNC_VALIDATE = `${NAME}/ASYNC_VALIDATE`;
export const ON_SUBMIT_FAIL = `${NAME}/ON_SUBMIT_FAIL`;
export const ADD_OTP_FORM_SUCCESS = `${NAME}/ADD_OTP_FORM_SUCCESS`;
export const RESET_TOP_FORM_SUCCESS = `${NAME}/RESET_TOP_FORM_SUCCESS`;
export const RESET = `${NAME}/RESET`;

export const startSubmit = (form, data, resolve, reject) => ({
    type: START_SUBMIT,
    meta: { form, resolve, reject },
    payload: data,
});

export const asyncValidate = (form, field, data, resolve, reject) => ({
    type: ASYNC_VALIDATE,
    meta: { form, resolve, reject, field },
    payload: data,
});

export const onSubmitFail = (form) => ({
    type: ON_SUBMIT_FAIL,
    meta: { form },
});

export const reset = (form) => ({
    type: RESET,
    meta: { form },
});

// Otp Forms
export const addOtpFormSuccess = (formName) => ({
    type: ADD_OTP_FORM_SUCCESS,
    payload: { formName },
});

export const resetOtpFormSuccess = (formName) => ({
    type: RESET_TOP_FORM_SUCCESS,
    payload: { formName },
});
