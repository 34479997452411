import { Record } from "immutable";

class PensionDssFundsChangeType extends Record({ id: null, msg: null }) {}
export default Object.freeze({
    COMBINED: new PensionDssFundsChangeType({
        id: "COMBINED",
        msg: "pension.dss.fundsChange.COMBINED",
    }),
    VOLUNTARY: new PensionDssFundsChangeType({
        id: "VOLUNTARY",
        msg: "pension.dss.fundsChange.VOLUNTARY",
    }),
    COMPULSORY: new PensionDssFundsChangeType({
        id: "COMPULSORY",
        msg: "pension.dss.fundsChange.COMPULSORY",
    }),
});
