import { Map } from "immutable";
import { fn } from "core/util";
import { SubjectType } from "enums";

export const pensionBeneficiaryShareFormatter = (share = Map()) => {
    const numerator = share.get("numerator");

    if (fn.isEmpty(numerator)) {
        return "";
    }

    if (share.get("shareType") === "P") {
        return numerator.split(".")[0];
    } else {
        return "";
    }
};

export const removeAttributesBySubjectType = (beneficiary) => {
    const subjectType = beneficiary.get("subjectType");
    if (subjectType === SubjectType.INDIVIDUAL.id) {
        return beneficiary.delete("companyName").delete("companyId");
    } else {
        return beneficiary.delete("firstName").delete("lastName").delete("birthNumber");
    }
};
