import { model } from "core/util";

import { AmountLegacy, SimpleCodeValue } from "../common";

export const PaymentRequest = model.createModelLegacy("PaymentRequest", (json) => ({
    paymentRequestType: SimpleCodeValue.fromServer(json.PaymentRequestType),
    paymentRequestAmountDue: AmountLegacy.fromServer(json.PaymentRequestAmountDue),
    paymentRequestDebtMonths: json.PaymentRequestDebtMonths,
    paymentRequestPrintDate: json.PaymentRequestPrintDate,
    paymentEndDate: json.PaymentEndDate,
}));
