import React from "react";
import { Outlet } from "react-router-dom";

import { Container, SidemenuLink } from "ui-library/atoms";
import { SubPageLayout, SubPageWrapper } from "ui-library/layouts";
import { PageLead, Sidemenu } from "ui-library/modules";

import i18n from "core/i18n";
import pageContext from "core/pageContext";

import { innerRoutes } from "routeUrls";
import { Tabs } from "routeConstants";
import { FeatureFlag } from "types";

export default () => {
    const { t } = i18n.useTranslation();
    const isClaimReportNewEnabled = pageContext.useIsFeatureFlagEnabled(FeatureFlag.CLAIM_REPORT_NEW);
    return (
        <SubPageLayout>
            <Container>
                <PageLead title={t("incident.incidents")} lead={t("claim.lead")} />
                <SubPageWrapper>
                    <Sidemenu>
                        {isClaimReportNewEnabled ? (
                            <SidemenuLink to={innerRoutes[Tabs.REPORT_CLAIM]}>{t("menu.tab.reportClaim")}</SidemenuLink>
                        ) : (
                            <SidemenuLink to={innerRoutes[Tabs.REPORT_CLAIM_OLD]}>{t("menu.tab.reportClaim")}</SidemenuLink>
                        )}
                        <SidemenuLink to={innerRoutes[Tabs.CLAIMS_LIST]}>{t("menu.tab.claimsStatus")}</SidemenuLink>
                    </Sidemenu>
                    <Outlet />
                </SubPageWrapper>
            </Container>
        </SubPageLayout>
    );
};
