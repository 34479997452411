import { API_PREFIX } from "app/constants";
import { fetch } from "core/util";
import { TravelInsurance } from "model/travel";
import { ClaimsEvents } from "model/claim";

import { HarmonizedFinancialOperation } from "../model/harmonized";

const productUriPrefix = `${API_PREFIX}/product/travel`;
const getTravelContractDetail = (idObject) =>
    fetch.doGet(`${productUriPrefix}/${idObject}/contract-detail`, null, TravelInsurance.fromServer);
const getTravelFinancialOperations = (idObject) =>
    fetch.doGet(`${productUriPrefix}/${idObject}/financial-operations`, null, HarmonizedFinancialOperation.fromServerList);
const getTravelIncidents = (idObject) => fetch.doGet(`${productUriPrefix}/${idObject}/claims`, null, ClaimsEvents.fromServer);

export default {
    getTravelContractDetail,
    getTravelFinancialOperations,
    getTravelIncidents,
};
