import React from "react";

import { Typography } from "ui-library/atoms";
import { ContentBox } from "ui-library/layouts";

import { fn } from "core/util";

export default ({ errorMessage }) => {
    return (
        <ContentBox>
            <Typography component="p" markDown={fn.formatMarkdownText(errorMessage)} />
        </ContentBox>
    );
};
