import { createSelector } from "reselect";
import { List, Map } from "immutable";

import { Countries } from "enums";
import i18n from "core/i18n";
import pageContext from "core/pageContext";

export const getLocalizedDomesticCountryOption = createSelector(
    i18n.createGetLocalizedEnumMap(Countries),
    pageContext.getSiteId,
    (countryMap, siteId) => {
        const primary = Map({ value: siteId, label: countryMap[siteId] });
        return List.of(primary);
    },
);
