import React, { FC, ReactNode } from "react";
import { Typography } from "ui-library/atoms";
import { Box } from "ui-library/layouts";
import { StyledLabelTextItem, StyledLabelTextItemCell } from "ui-library/Styled";

export interface LabelTextItemProps {
    label?: ReactNode;
    text: ReactNode;
    iconAction?: ReactNode;
    fontVariant?: string;
    fullWidth?: boolean;
    minWidthLabel?: boolean;
    minWidthPx?: string;
}

export const LabelTextItem: FC<LabelTextItemProps> = ({
    fullWidth,
    fontVariant = "p",
    label,
    text,
    iconAction,
    minWidthLabel,
    minWidthPx,
}) => {
    return (
        <StyledLabelTextItem $fullWidth={fullWidth} alignItems={"center"}>
            <StyledLabelTextItemCell
                pb={fullWidth ? {} : { xs: 0, md: 1 }}
                style={minWidthLabel ? { minWidth: minWidthPx || "150px" } : {}}
            >
                <Typography variant={fontVariant === "p" ? "caption" : fontVariant} color="gray">
                    {label}
                </Typography>
            </StyledLabelTextItemCell>
            <StyledLabelTextItemCell pl={{ xs: 0, md: 2 }} pb={fullWidth ? {} : { xs: 2, md: 1 }}>
                <Box display={"flex"} flexDirection={"row"} alignItems="center">
                    {React.isValidElement(text) ? (
                        <>{text}</>
                    ) : (
                        <Typography variant={fontVariant} component="p">
                            {text}
                        </Typography>
                    )}
                    {iconAction && <Box pl={{ xs: 2, md: 2 }}>{iconAction}</Box>}
                </Box>
            </StyledLabelTextItemCell>
        </StyledLabelTextItem>
    );
};
