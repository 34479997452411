import { NAME } from "./constants";

export const SET_LIFE_CONTRACT_DETAIL = `${NAME}/SET_LIFE_CONTRACT_DETAIL`;
export const SET_DETAIL_SELECTED_INSURED = `${NAME}/SET_DETAIL_SELECTED_INSURED`;
export const SET_STATEMENT_TAB = `${NAME}/SET_STATEMENT_TAB`;
export const CLEAR = `${NAME}/CLEAR`;

export const setLifeContractDetail = (contractDetail) => ({
    type: SET_LIFE_CONTRACT_DETAIL,
    payload: contractDetail,
});

export const setDetailSelectedInsured = (selectedInsured) => ({
    type: SET_DETAIL_SELECTED_INSURED,
    payload: selectedInsured,
});

export const setStatementTab = (tabIndex) => ({
    type: SET_STATEMENT_TAB,
    payload: tabIndex,
});

export const clear = () => ({
    type: CLEAR,
});
