import React from "react";
import PropTypes from "prop-types";

import { Box, ContentBox } from "ui-library/layouts";
import { Button, Typography } from "ui-library/atoms";

import i18n from "core/i18n";

import { getNotApplicableRedeems } from "../selectors";
import { NotApplicableRedeemsSection } from "containers/contract";

export const RedeemSavingsPensionDpsNoOptionsText = ({ backButtonAction }) => {
    const { t } = i18n.useTranslation();

    return (
        <>
            <ContentBox>
                {/* First line */}
                <Typography component="p" markDown={t("pension.redeemSavings.dps.noOptions.part1")} />

                <NotApplicableRedeemsSection notApplicableRedeemsSelector={getNotApplicableRedeems} />

                {/* Second line */}
                <Typography component="p" markDown={t("pension.redeemSavings.dps.noOptions.part2")} />
            </ContentBox>
            <Box mt={4}>
                <Button onClick={backButtonAction} variant="outlined" color="blue">
                    {t("common.back")}
                </Button>
            </Box>
        </>
    );
};

RedeemSavingsPensionDpsNoOptionsText.propTypes = {
    backButtonAction: PropTypes.func.isRequired,
};
