import { v4 as uuid } from "uuid";
import { model } from "core/util";
import { AmountLegacy, SimpleCodeValue } from "../common";
import { Validity } from "./Validity";

export const Coverage = model.createModelLegacy("Coverage", (json) => ({
    reactKey: uuid(),
    identifier: json.Identifier,
    coverageNumber: json.CoverageNumber,
    category: SimpleCodeValue.fromServer(json.Category),
    coverageCode: json.CoverageCode,
    validity: Validity.fromServer(json.Validity),
    insuranceLimit: AmountLegacy.fromServer(json.InsuranceLimit),
    coverageCost: AmountLegacy.fromServer(json.CoverageCost),
    insuranceParticipationPercent: json.InsuranceParticipationPercent,
    insuranceParticipation: AmountLegacy.fromServer(json.InsuranceParticipation),
    insuranceParticipationMin: AmountLegacy.fromServer(json.InsuranceParticipationMin),
    insuranceParticipationMax: AmountLegacy.fromServer(json.InsuranceParticipationMax),
    insuranceParticipationMaxTheftPercentage: json.InsuranceParticipationMaxTheftPercentage,
    isPrimary: json.IsPrimary,
    isOneTimeTariff: json.IsOneTimeTariff,
    insuranceTariff: SimpleCodeValue.fromServer(json.InsuranceTariff),
    insuranceIndexation: json.InsuranceIndexation,
    coverageText: json.CoverageText,
    computedInsuranceLimit: json.ComputedInsuranceLimit,
}));
