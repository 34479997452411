import { call, delay, fork, put, select } from "redux-saga/effects";

import entity from "core/entity";
import router from "core/router";
import { contractsApi, incidentsApi } from "serverApi";

import { Pages, Params, Tabs } from "routeConstants";
import userContracts from "core/userContracts";

import { clear, setStoredContractId } from "./actions";
import { ENTITY_CLIENT_INCIDENTS, ENTITY_CONTRACT_OVERVIEW, SECTION_TO_SCROLL_VIEW_CLASS } from "./constants";
import {
    createGetCompliantLineOfBusinessClasses,
    getSelectedClaimCategoryId,
    getSelectedContractId,
    getSelectedLineOfBusinessClass,
    getSingleContractByCategoryId,
    getStoredContractId,
} from "./selectors";

export default router.routerWrapper({
    *onPageEnter() {
        yield fork(entity.load, ENTITY_CLIENT_INCIDENTS, () => incidentsApi.getClientIncidents());
        yield fork(scrollToPrefilledSelect);
        yield fork(entity.periodicallyCheckClaims, 60 * 1000, ENTITY_CLIENT_INCIDENTS, () => incidentsApi.getClientIncidents());
    },
    *onInnerRouteChange(name, params, query) {
        yield call(routeChangeSaga, name, query);
    },
    clearDataForPage() {
        return [clear(), entity.clearData(ENTITY_CONTRACT_OVERVIEW)];
    },
});

function* routeChangeSaga(name, query) {
    switch (name) {
        case Tabs.REPORT_CLAIM: {
            const selectedClaimCategoryId = yield select(getSelectedClaimCategoryId);
            const singleContractId = yield select(getSingleContractByCategoryId(selectedClaimCategoryId));
            const selectedContractId = yield select(getSelectedContractId);
            const contractId = query[Params.PAYMENT_CONTRACT_ID];
            const storedContractId = yield select(getStoredContractId);

            if ((contractId && contractId !== storedContractId) || (singleContractId && !selectedContractId)) {
                yield put(entity.clearData(ENTITY_CONTRACT_OVERVIEW));
                const productGroup = yield select(userContracts.createGetProductGroupUrlNameById(contractId || singleContractId));
                yield call(entity.load, ENTITY_CONTRACT_OVERVIEW, () =>
                    contractsApi.getContractOverview(productGroup, contractId || singleContractId),
                );
                yield put(setStoredContractId(contractId || singleContractId));

                if (singleContractId && !selectedContractId) {
                    yield call(scrollToPrefilledSelect);
                }
                yield call(navigateToReportClaimTab, contractId || singleContractId, selectedClaimCategoryId);
            }
            const compliantLineOfBusinessClasses = yield select(createGetCompliantLineOfBusinessClasses(selectedContractId));
            const selectedProductGroupClass = yield select(getSelectedLineOfBusinessClass);
            if (selectedContractId && compliantLineOfBusinessClasses.size === 1 && !selectedProductGroupClass) {
                yield call(navigateToReportClaimTab, selectedContractId, selectedClaimCategoryId, compliantLineOfBusinessClasses.first());
            }
        }
    }
}

function* navigateToReportClaimTab(contractId, claimCategoryId, lineOfBusiness = null) {
    const queryParams = {
        [Params.PAYMENT_CONTRACT_ID]: contractId,
        [Params.CLAIM_REPORT_CATEGORY]: claimCategoryId,
    };

    if (lineOfBusiness) {
        queryParams[Params.CLAIM_LINE_OF_BUSINESS_CLASS] = lineOfBusiness;
    }
    yield put(router.navigate(Pages.CLAIMS, Tabs.REPORT_CLAIM, {}, queryParams, false));
}

export function* scrollToPrefilledSelect() {
    yield delay(1000);
    const elements = document.querySelectorAll(`.${SECTION_TO_SCROLL_VIEW_CLASS}`);
    if (elements.length) {
        elements[0].parentElement.parentElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    }
}
