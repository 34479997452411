import React, { FC } from "react";

import i18n from "core/i18n";
import { Field } from "core/form";
import userContracts from "core/userContracts";

import { Typography } from "ui-library/atoms";
import { ContentBox, Section } from "ui-library/layouts";

import BulkChangeSummary from "./BulkChangeSummary";
import ContractsPicker from "./ContractsPicker";

export interface BulkChangeFormBoxProps {
    idObject: number;
    showSummary?: boolean;
    loading?: boolean;
}

export const BulkChangeFormBox: FC<BulkChangeFormBoxProps> = ({ idObject, showSummary = true, loading, ...props }) => {
    const { t } = i18n.useTranslation();
    const { hasMoreThanOneActiveContract } = userContracts.useGetContractsDerivedData();

    return (
        hasMoreThanOneActiveContract && (
            <Section>
                <ContentBox>
                    <Typography variant="h3">{t("form.bulkChange.title")}</Typography>
                </ContentBox>

                {showSummary && <BulkChangeSummary {...props} />}

                <Field name="additionalIdObjects" component={ContractsPicker} idObject={idObject} />
            </Section>
        )
    );
};
