import React from "react";
import PropTypes from "prop-types";
import { Map } from "immutable";

import { Box, ContentBox } from "ui-library/layouts";

import i18n from "core/i18n";
import { Field, required, SingleSelect } from "core/form";

import { PENSION_TYPE_FIELD } from "../constants";

export const SelectPensionTypeSection = ({ allowedPensionTypes }) => {
    if (allowedPensionTypes.size === 1) {
        return null;
    }

    const { t } = i18n.useTranslation();
    const options = allowedPensionTypes.map((pensionType) => Map().set("label", t(pensionType.msg)).set("value", pensionType.id));

    return (
        <Box>
            <ContentBox>
                <Field
                    name={PENSION_TYPE_FIELD}
                    label={t("form.pensionRedeemSavings.pensionType")}
                    component={SingleSelect}
                    validateStatic={[required]}
                    options={options}
                />
            </ContentBox>
        </Box>
    );
};

SelectPensionTypeSection.propTypes = {
    allowedPensionTypes: PropTypes.object.isRequired,
};
