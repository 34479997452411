import { model } from "core/util";

import { AmountLegacy, CodeValueLegacy } from "../common";

export const PensionDssDdsFinancialOperation = model.createModelLegacy("PensionDssDdsFinancialOperation", (json) => ({
    id: json.Id,
    paymentDate: json.PaymentDate,
    type: CodeValueLegacy.fromServer(json.Type),
    grossAmount: AmountLegacy.fromServer(json.GrossAmount),
    investmentFund: CodeValueLegacy.fromServer(json.InvestmentFund),
    investmentDate: json.InvestmentDate,
    investmentPeriod: json.InvestmentPeriod,
    numberOfShares: json.NumberOfShares,
    pricePerShareAtOperationDateTime: AmountLegacy.fromServer(json.PricePerShareAtOperationDateTime),
    actualPricePerShare: AmountLegacy.fromServer(json.ActualPricePerShare),
}));
