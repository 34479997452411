import { useSelector } from "react-redux";

import { BlogCard, Button } from "ui-library/atoms";
import { MasonryGrid } from "ui-library/layouts";
import { ArticlesList } from "ui-library/modules";

import cmsContent from "core/cmsContent";
import i18n from "core/i18n";
import { useGetSiteValue } from "core/hooks";
import { ExternalUrl } from "enums";

export const PatalieNewsGridItem = () => {
    const { t } = i18n.useTranslation();
    const patalieItems = useSelector(cmsContent.getRssItems);
    const getSiteValue = useGetSiteValue();

    return (
        <ArticlesList
            title={t("page.contractList.news.title")}
            description={t("page.contractList.news.description")}
            action={
                <Button variant="outlined" color="blue" newTab externalLink={getSiteValue(ExternalUrl.PATALIE)}>
                    {t("common.showMore")}
                </Button>
            }
        >
            <MasonryGrid columns={{ sm: 1, md: 2, lg: 2 }} spacing={3}>
                {patalieItems.map((item) => (
                    <a key={item.link} href={item.link} target="_blank" style={{ textDecoration: "none" }}>
                        <BlogCard title={item.title} perex={item.description} thumbSrc={item.thumbnail} padding={false} shadow={false} />
                    </a>
                ))}
            </MasonryGrid>
        </ArticlesList>
    );
};
