import React, { FC } from "react";

import { InfoPanel, LabelTextItem } from "ui-library/modules";
import { Box, Flexbox, GridItem } from "ui-library/layouts";
import { PopoverTooltip, Typography } from "ui-library/atoms";

import i18n from "core/i18n";
import { formatBalanceOverdue } from "core/formatters";

export interface BalanceOverdueGridItemProps {
    contractBalance: any;
}

export const BalanceOverdueGridItem: FC<BalanceOverdueGridItemProps> = ({ contractBalance }) => {
    const { t } = i18n.useTranslation();
    const formattedOverdue = formatBalanceOverdue(t, contractBalance);

    return (
        <GridItem xs={12} md={12}>
            <InfoPanel
                icon={"money-funnel"}
                title={
                    <Flexbox alignItems="center" gap={1}>
                        {t("insurance.balanceOverdue")}
                        <Box>
                            <PopoverTooltip title="" content={t("insurance.nonlifeBalanceFootnote")} />
                        </Box>
                    </Flexbox>
                }
            >
                <LabelTextItem label={formattedOverdue.title} text={<Typography fontWeight={500}>{formattedOverdue.value}</Typography>} />
            </InfoPanel>
        </GridItem>
    );
};
