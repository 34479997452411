import React from "react";

import { StandaloneHtmlLink, Typography } from "ui-library/atoms";
import PropTypes from "prop-types";

export const ExternalLinkFormatter = ({href, text}) => {
    if (href) {
        return (
            <StandaloneHtmlLink color="blue" href={href} target="_blank">
                {text}
            </StandaloneHtmlLink>
        );
    } else {
        return <Typography>{text}</Typography>;
    }
};

ExternalLinkFormatter.propTypes = {
    href: PropTypes.string,
    text: PropTypes.string.isRequired,
};

ExternalLinkFormatter.defaultProps = {
    href: null,
};
