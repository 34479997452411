import slice from "./slice";
import * as selectors from "./selectors";
import saga from "./saga";
import { BANK_LIST } from "./constants";

/**
 * Module for enums. Either static ones, or dynamically loaded from server.
 */
const enums = {
    NAME: slice.name,
    reducer: slice.reducer,
    ...slice.actions,
    ...selectors,

    saga,

    BANK_LIST,
};

export default enums;
