import { model } from "core/util";

import { Amount, CodeValue } from "model/common";
import { MutualFundsRegularAccountDetail as MutualFundsRegularAccountDetailType, MutualFundsRegularAccountDetailDto } from "types";

import { MutualFundsAccountPaymentDetail } from "../MutualFundsAccountPaymentDetail";

export const MutualFundsRegularAccountDetail = model.createModel<MutualFundsRegularAccountDetailDto, MutualFundsRegularAccountDetailType>(
    (json) => ({
        accountDetailForPayment: MutualFundsAccountPaymentDetail.fromServer(json.AccountDetailForPayment),
        monthlyPayment: Amount.fromServer(json.MonthlyPayment),
        agreedPeriod: json.AgreedPeriod,
        investmentProfile: CodeValue.fromServer(json.InvestmentProfile),
        wayOfPaymentOfTheFee: CodeValue.fromServer(json.WayOfPaymentOfTheFee),
        targetAmount: Amount.fromServer(json.TargetAmount),
        fee: Amount.fromServer(json.Fee),
    }),
);
