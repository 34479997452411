import React from "react";
import { useSelector } from "react-redux";

import { ContentBox, Section } from "ui-library/layouts";
import { Typography } from "ui-library/atoms";

import {
    amountCharacters,
    Field,
    getFormFieldValue,
    InputText,
    max10Characters,
    maxLengthCharacters,
    numberCharacters,
    required,
    SingleSelect,
} from "core/form";
import i18n from "core/i18n";
import { MutualFundsDirectAmountType } from "enums";
import PropTypes from "prop-types";

export const MeansSectionDirect = ({ formName, getLocalizedFundSellMeansDirect }) => {
    const { t } = i18n.useTranslation();
    const meansFieldSelector = getFormFieldValue(formName, "means");
    const meansFieldValue = useSelector(meansFieldSelector);

    const typeOptions = useSelector(getLocalizedFundSellMeansDirect);

    return (
        <Section>
            <ContentBox>
                <Typography variant="h3">{t("investments.sellSwitch.way")}</Typography>
            </ContentBox>
            <ContentBox fullWidth>
                <Field
                    name="means"
                    component={SingleSelect}
                    label={t("investments.sellSwitch.direct.meansLabel")}
                    options={typeOptions}
                    validateStatic={[required]}
                    placeholder={t("common.choose")}
                />
            </ContentBox>
            {meansFieldValue === MutualFundsDirectAmountType.QUANTITY.id && (
                <ContentBox fullWidth>
                    <Field
                        name="quantity"
                        label={t("investments.sellSwitch.meansQuantity")}
                        validateStatic={[required]}
                        normalize={[numberCharacters, max10Characters]}
                        component={InputText}
                        md={3}
                    />
                </ContentBox>
            )}
            {meansFieldValue === MutualFundsDirectAmountType.AMOUNT.id && (
                <ContentBox fullWidth>
                    <Field
                        name="amount"
                        label={t("payments.meansAmount")}
                        validateStatic={[required]}
                        normalize={[amountCharacters, maxLengthCharacters(9)]}
                        component={InputText}
                        md={3}
                    />
                </ContentBox>
            )}
        </Section>
    );
};

MeansSectionDirect.propTypes = {
    formName: PropTypes.string.isRequired,
    getLocalizedFundSellMeansDirect: PropTypes.func.isRequired,
};
