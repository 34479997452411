import { withTheme } from "@mui/styles";
import styled from "styled-components";
import { StyledBlock } from "ui-library/Styled/Layouts/Blocks";

const contentStyle = (props) => {
    if (props.$droppable) {
        return `
        overflow: hidden;
        transition: max-height 1s;
        max-height: ${props.$more ? "auto" : "0px"};
        `;
    }
};

export const StyledInfoPanel = withTheme(styled(StyledBlock)`
    width: 100%;
    position: relative;

    .img {
        position: absolute;
        max-width: 180px;
        right: 20px;
        bottom: 20px;
        object-fit: unset;
        height: auto;
        @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
            position: relative;
            display: block;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
        }
    }

    .chevron {
        &:hover {
            cursor: pointer;
        }
    }

    .content {
        ${(props) => contentStyle(props)};
    }
`);
