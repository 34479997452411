import { API_PREFIX } from "app/constants";
import { fetch } from "core/util";
import { PensionPp, PensionPpFinancialOperation, PensionPpFinancialStatement } from "model/pension/pp";
import { PensionContractAdditionalInformation } from "model/pension/additionalInfomation";

const productUriPrefix = `${API_PREFIX}/product/pension-pp`;
const getPensionPpContractDetail = (idObject) => fetch.doGet(`${productUriPrefix}/${idObject}/contract-detail`, null, PensionPp.fromServer);
const getPensionPpContractAdditionalInfo = (idObject) =>
    fetch.doGet(`${productUriPrefix}/${idObject}/additional-information`, null, PensionContractAdditionalInformation.fromServer);
const getPensionPpFinancialOperations = (idObject) =>
    fetch.doGet(`${productUriPrefix}/${idObject}/financial-operations`, null, PensionPpFinancialOperation.fromServerList);
const getPensionPpFinancialStatement = (idObject) =>
    fetch.doGet(`${productUriPrefix}/${idObject}/financial-statement`, null, PensionPpFinancialStatement.fromServer);

const updateBeneficiaries = (values) => fetch.doPut(`${productUriPrefix}/update-beneficiaries`, values);
const putRedeemSavings = (values) => fetch.doPut(`${productUriPrefix}/redeem-savings`, values);

const pensionPpApi = {
    getPensionPpContractDetail,
    getPensionPpContractAdditionalInfo,
    getPensionPpFinancialOperations,
    getPensionPpFinancialStatement,
    updateBeneficiaries,
    putRedeemSavings,
};

export default pensionPpApi;
