import { fetch } from "core/util";
import { API_PREFIX } from "app/constants";
import { ConsentItem } from "model/consent";

const setDisclaimerConsent = (version) =>
    fetch.doPost(`${API_PREFIX}/consent`, {
        ConsentTypeCode: "DISCLAIMER",
        ConsentVersionCode: version,
    });

const revokeGdprConsent = () =>
    fetch.doPost(`${API_PREFIX}/consent`, {
        ConsentTypeCode: "GDPR",
        ConsentAction: "close",
    });

const acceptGdprConsent = (version) =>
    fetch.doPost(`${API_PREFIX}/consent`, {
        ConsentTypeCode: "GDPR",
        ConsentVersionCode: version,
        ConsentAction: "add",
    });

const getGdprConfirmed = () => fetch.doGet(`${API_PREFIX}/consent/gdpr-is-confirmed`);
const getGdprVersionAndText = () => fetch.doGet(`${API_PREFIX}/consent/gdpr-to-approve`);
const getGdprTableData = (id) => fetch.doGet(`${API_PREFIX}/consent/gdpr-approved/${id}`, undefined, ConsentItem.fromServerList);
const getGdprFormVersionAndText = () => fetch.doGet(`${API_PREFIX}/consent/gdpr-home-additional-services`);

export default {
    setDisclaimerConsent,
    getGdprVersionAndText,
    revokeGdprConsent,
    acceptGdprConsent,
    getGdprConfirmed,
    getGdprTableData,
    getGdprFormVersionAndText,
};
