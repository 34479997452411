import { ChangePropositionType } from "enums";

export const NAME = "CHANGE_PROPOSITIONS_MODULE";

export const PROPOSITION_PAGE_STATE = {
    INITIAL: "INITIAL",
    MUTUAL_FUNDS_TRANSACTION: ChangePropositionType.MUTUAL_FUNDS_TRANSACTION.id,
    MUTUAL_FUNDS_PROFILE_CHANGE: ChangePropositionType.MUTUAL_FUNDS_PROFILE_CHANGE.id,
    PENSION_FUNDS_CHANGE: ChangePropositionType.PENSION_FUNDS_CHANGE.id,
    PENSION_INVESTMENT_STRATEGIES_CHANGE: ChangePropositionType.PENSION_INVESTMENT_STRATEGIES_CHANGE.id,
};

export const MODAL_CHANGE_PROPOSITION_DETAIL = `${NAME}/MODAL_CHANGE_PROPOSITION_DETAIL`;

export const FETCHING_CHANGE_PROPOSITION_CANCEL = `${NAME}/FETCHING_CHANGE_PROPOSITION_CANCEL`;
