import { call, takeEvery } from "redux-saga/effects";

import { messagesApi } from "serverApi";
import { sentry } from "core/util";

import { EXPAND_MESSAGE } from "./actions";

export default function* () {
    yield takeEvery(EXPAND_MESSAGE, readMessageSaga);
}

function* readMessageSaga({ payload }) {
    if (payload.expand && payload.unread) {
        try {
            yield call(messagesApi.setMessageRead, payload.id);
        } catch (e) {
            sentry.captureException(e);
        }
    }
}
