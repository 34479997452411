import { routerSaga, setPageByLocationDirectly, startRouting } from "./saga";

import { registerRoutes } from "./staticRouteRegister";
import * as util from "./util";

import slice from "./slice";
import * as selectors from "./selectors";
import { LANG_PARAM } from "./constants";

const router = {
    NAME: slice.name,
    reducer: slice.reducer,
    ...slice.actions,
    ...selectors,
    ...util,

    saga: routerSaga,
    setPageByLocationDirectly,
    startRouting,

    registerRoutes,

    LANG_PARAM,
};

export default router;
