import { createSaga } from "./createSaga";
import { createContainer } from "./createContainer";

/**
 * Saga and Container creator for Statement Password.
 */
const statementPassword = {
    createSaga,
    createContainer,
};
export default statementPassword;
