import React, { ComponentType, FC } from "react";

import { Typography } from "ui-library/atoms";
import { InfoPanel, LabelTextItem } from "ui-library/modules";
import { ContentBox, GridItem } from "ui-library/layouts";

import i18n from "core/i18n";
import { useHasEditContractPermission } from "core/hooks";
import { EditLinksDropdown } from "containers/EditLinksDropdown";

type PensionSkStatementsSettingsProps = {
    statementPassword: string;
    editStatementPassFormName: string;
    EditStatementPassForm: ComponentType;
};

export const PensionSkStatementsSettings: FC<PensionSkStatementsSettingsProps> = ({
    statementPassword,
    editStatementPassFormName,
    EditStatementPassForm,
}) => {
    const { t } = i18n.useTranslation();
    const hasEditPermission = useHasEditContractPermission();

    const editLinks = [
        {
            formConst: editStatementPassFormName,
            name: "pension.statementsPassword.title",
        },
    ];

    return (
        <GridItem display="flex" xs={12}>
            <InfoPanel
                icon="document"
                title={t("statements.statementSettings")}
                headerAction={hasEditPermission && <EditLinksDropdown links={editLinks} />}
            >
                <ContentBox>
                    <LabelTextItem label={t("pension.statementsPassword.title")} text={statementPassword} />
                    <Typography variant="caption">{t("pension.statementsPassword.description")}</Typography>
                </ContentBox>
            </InfoPanel>

            {/* Modals */}
            <EditStatementPassForm />
        </GridItem>
    );
};
