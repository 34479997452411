import React from "react";
import PropTypes from "prop-types";

import { ContentBox } from "ui-library/layouts";

import i18n from "core/i18n";
import { Field, InputText, maxValue100, numberCharacters, ReduxFormSection } from "core/form";
import { fn } from "core/util";
import { DssSavingStrategyFund } from "enums";

import { CHANGE_STRATEGY_DATA, DSS_BOND_FIELD_NAME, DSS_INDEXED_FIELD_NAME, DSS_STOCK_FIELD_NAME } from "../constants";

const PLACEHOLDER = "%";

const formatPercentage = (value) => (!fn.isEmpty(value) ? value : "0");

const PercentageSelectDss = ({ sectionName, actualStrategies }) => {
    const { t } = i18n.useTranslation();
    const prepareLabel = (fund) =>
        sectionName === CHANGE_STRATEGY_DATA
            ? `${t(fund?.msg)} (${t("pension.currentInvestmentPercentage")} ${formatPercentage(actualStrategies?.get(fund?.fundId))}%)`
            : t(fund?.msg);

    return (
        <ReduxFormSection name={sectionName}>
            <ContentBox>
                <Field
                    component={InputText}
                    name={DSS_BOND_FIELD_NAME}
                    label={prepareLabel(DssSavingStrategyFund.BOND)}
                    placeholder={PLACEHOLDER}
                    normalize={[numberCharacters, maxValue100]}
                />
            </ContentBox>
            <ContentBox>
                <Field
                    component={InputText}
                    name={DSS_STOCK_FIELD_NAME}
                    label={prepareLabel(DssSavingStrategyFund.STOCK)}
                    placeholder={PLACEHOLDER}
                    normalize={[numberCharacters, maxValue100]}
                />
            </ContentBox>
            <ContentBox>
                <Field
                    component={InputText}
                    name={DSS_INDEXED_FIELD_NAME}
                    label={prepareLabel(DssSavingStrategyFund.INDEXED)}
                    placeholder={PLACEHOLDER}
                    normalize={[numberCharacters, maxValue100]}
                />
            </ContentBox>
        </ReduxFormSection>
    );
};

PercentageSelectDss.propTypes = {
    sectionName: PropTypes.string.isRequired,
};

export default PercentageSelectDss;
