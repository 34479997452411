export default (actionPrefix) => {
    const SET_RESENT = `${actionPrefix}/SET_RESENT`;
    const SET_PHONE_NUMBER = `${actionPrefix}/SET_PHONE_NUMBER`;
    const CLEAR = `${actionPrefix}/CLEAR`;

    return {
        SET_RESENT,
        setResent: () => ({
            type: SET_RESENT,
        }),

        SET_PHONE_NUMBER,
        setPhoneNumber: (number) => ({
            type: SET_PHONE_NUMBER,
            payload: number,
        }),
        CLEAR,
        clear: (formName) => ({
            type: CLEAR,
            payload: formName,
        }),
    };
};
