import { fetch } from "core/util";
import { API_PREFIX } from "app/constants";
import { Policy } from "model/policy";
import { ContractOverview } from "model/common";

const getContracts = () => fetch.doGet(`${API_PREFIX}/contract`, null, Policy.fromServerList);
const getContractOverview = (productGroup, idObject) =>
    fetch.doGet(`${API_PREFIX}/contract/${productGroup}/${idObject}/detail`, null, ContractOverview.fromServer);

export default {
    getContracts,
    getContractOverview,
};
