import React from "react";
import { Outlet } from "react-router-dom";

import { Container, SidemenuLink } from "ui-library/atoms";
import { SubPageWrapper } from "ui-library/layouts";
import { Sidemenu } from "ui-library/modules";

import i18n from "core/i18n";
import router from "core/router";
import { Pages, Tabs } from "routeConstants";

import { Header } from "./containers";
import { ProductDetailPageLayout } from "containers/contract";

export default () => {
    const { t } = i18n.useTranslation();

    return (
        <ProductDetailPageLayout>
            <Container>
                <Header />

                <SubPageWrapper>
                    <Sidemenu>
                        <SidemenuLink to={router.getStaticUrl(Pages.USER, Tabs.VERIFICATION)}>{t("holder.verification")}</SidemenuLink>
                        <SidemenuLink to={router.getStaticUrl(Pages.USER, Tabs.USER_ACCOUNT)}>{t("holder.credentials")}</SidemenuLink>
                        <SidemenuLink to={router.getStaticUrl(Pages.USER, Tabs.DATA_PROCESSING)}>
                            {t("userSettings.personalDataProcessing.title")}
                        </SidemenuLink>
                    </Sidemenu>

                    <Outlet />
                </SubPageWrapper>
            </Container>
        </ProductDetailPageLayout>
    );
};
