import React from "react";
import PropTypes from "prop-types";

import { Box, ContentBox } from "ui-library/layouts";

import i18n from "core/i18n";
import { DatePicker, Field, required } from "core/form";
import { date } from "core/util";

import { REDEEM_START_FROM_FIELD } from "../../constants";

export const PensionDpsOneTimeSettlementSection = ({ selectedRedeemTypeParameters }) => {
    const { t } = i18n.useTranslation();

    const minDate = Date.parse(selectedRedeemTypeParameters?.claimDateFrom);
    const maxDate = Date.parse(selectedRedeemTypeParameters?.claimDateTo);

    return (
        <Box mt={4}>
            <ContentBox>
                <Field
                    name={REDEEM_START_FROM_FIELD}
                    label={t("form.pensionRedeemSavings.paymentInMonth")}
                    component={DatePicker}
                    validateStatic={[required]}
                    minDate={minDate}
                    maxDate={maxDate}
                    inputFormat={"MM/yyyy"}
                    mask="__/____"
                    views={date.getMonthYearViewOptions(minDate, maxDate)}
                    md={4}
                />
            </ContentBox>
        </Box>
    );
};

PensionDpsOneTimeSettlementSection.propTypes = {
    selectedRedeemTypeParameters: PropTypes.object.isRequired,
};
