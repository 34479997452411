import styled from "styled-components";

import { Box } from "../Box/Box";

export const TableFilterRow = styled(Box)`
    display: grid;
    grid-template-columns: 0.5fr 1fr 1fr;
    column-gap: 1rem;
    align-items: center;
`;
