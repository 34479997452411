import React, { FC } from "react";
import { PopoverProps as MaterialPopoverProps } from "@mui/material";
import { StyledPopover } from "ui-library/Styled";
import { Typography, Button } from "ui-library/atoms";

export type PopoverPosition = "right" | "bottom" | "left" | "top" | "default";

export interface PopoverProps extends MaterialPopoverProps {
    id: string;
    position: PopoverPosition;
    title: string;
    content: any;
    buttonLabel: string;
}

const anchorVertical = (position: string) => {
    let anchorVertical: string;
    switch (position) {
        case "right":
            anchorVertical = "center";
            break;
        case "bottom":
            anchorVertical = "bottom";
            break;
        case "left":
            anchorVertical = "center";
            break;
        case "top":
            anchorVertical = "top";
            break;
        default:
            anchorVertical = "center";
            break;
    }
    return anchorVertical;
};

const anchorHorizontal = (position: string) => {
    let anchorHorizontal: string;
    switch (position) {
        case "right":
            anchorHorizontal = "right";
            break;
        case "bottom":
            anchorHorizontal = "center";
            break;
        case "left":
            anchorHorizontal = "left";
            break;
        case "top":
            anchorHorizontal = "center";
            break;
        default:
            anchorHorizontal = "center";
            break;
    }
    return anchorHorizontal;
};

const transformOriginVertical = (position: string) => {
    switch (position) {
        case "right":
            return "center";
        case "bottom":
            return "top";
        case "left":
            return "center";
        case "top":
            return "bottom";
        default:
            return "center";
    }
};

function transformOriginHorizontal(position: string) {
    switch (position) {
        case "right":
            return "left";
        case "bottom":
            return "center";
        case "left":
            return "right";
        case "top":
            return "center";
        default:
            return "center";
    }
}

export const Popover: FC<PopoverProps> = ({
    id = "popover-1",
    open,
    anchorEl,
    onClose,
    position = "left",
    title,
    content,
    buttonLabel,
    ...props
}) => {
    return (
        <StyledPopover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            className={"popover-" + position}
            anchorOrigin={{
                vertical: anchorVertical(position),
                horizontal: anchorHorizontal(position),
            }}
            transformOrigin={{
                vertical: transformOriginVertical(position),
                horizontal: transformOriginHorizontal(position),
            }}
            {...props}
        >
            {title && <Typography variant="h4">{title}</Typography>}
            <Typography>{content}</Typography>
            <Button onClick={onClose} variant="outlined" color="blue">
                {buttonLabel}
            </Button>
        </StyledPopover>
    );
};
