import React from "react";
import { useSelector } from "react-redux";

import { StandaloneLink, Typography } from "ui-library/atoms";
import { Box, Flexbox } from "ui-library/layouts";
import { InfoText, PageLead } from "ui-library/modules";
import profileLead from "ui-library/assets/images/profile-lead.svg";

import i18n from "core/i18n";
import router from "core/router";
import { useHasEditContractPermission } from "core/hooks";
import userInfo from "core/userInfo";
import user from "core/user";
import { timestampToDateTime } from "core/formatters";
import { UserVerificationStatus } from "enums";
import { Pages, Tabs } from "routeConstants";

import { getFormattedUsername } from "../selectors";

export const Header = () => {
    const username = useSelector(getFormattedUsername);

    const isVerified = useSelector(userInfo.getIsVerifiedUser);
    const requestDate = useSelector(userInfo.getVerificationRequestDate);
    const hasEditPermission = useHasEditContractPermission();

    let verificationStatus = UserVerificationStatus.UNVERIFIED;
    if (isVerified) {
        verificationStatus = UserVerificationStatus.VERIFIED;
    } else if (requestDate) {
        verificationStatus = UserVerificationStatus.VERIFICATION_IN_PROGRESS;
    }
    const { t } = i18n.useTranslation();
    const { lastLogin } = useSelector(user.getUserAccount);

    return (
        <PageLead
            title={username}
            leadImg={profileLead}
            subTitleSection={
                <Flexbox alignItems={"center"} gap={2}>
                    <InfoText color={verificationStatus.color} icon={verificationStatus.icon} text={t(verificationStatus.title)} />
                    {hasEditPermission && verificationStatus.id === UserVerificationStatus.UNVERIFIED.id && (
                        <>
                            <StandaloneLink color={"blue"} to={router.getStaticUrl(Pages.USER, Tabs.VERIFICATION)}>
                                {t("common.verify")}
                            </StandaloneLink>
                        </>
                    )}
                </Flexbox>
            }
        >
            <Box>
                <Typography variant="p">{`${t("holder.lastLogin.success")} ${timestampToDateTime(lastLogin)}`}</Typography>
            </Box>
        </PageLead>
    );
};
