import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { GridItem } from "ui-library/layouts";
import { InfoPanel } from "ui-library/modules";
import { Accordion } from "ui-library/atoms";

import i18n from "core/i18n";
import userContracts from "core/userContracts";

export const GenerateStatementGridItem = ({ statements }) => {
    const { t } = i18n.useTranslation();
    const visibleModules = statements.filter((item) => useSelector(item.shouldDisplaySelector));

    const contract = userContracts.useGetContractByUrlParam();
    const { isFutureContract, isEndedNotThisOrLastYear } = userContracts.useGetContractDerivedData(contract);

    if (isFutureContract || isEndedNotThisOrLastYear) {
        return null;
    }

    return (
        <GridItem xs={12}>
            <InfoPanel icon={"paper-bill"} title={t("statements.statementsAndConfirmations")}>
                <Accordion id="statementsAccordion" data={visibleModules} />
            </InfoPanel>
        </GridItem>
    );
};

GenerateStatementGridItem.propTypes = {
    statements: PropTypes.array.isRequired,
};

GenerateStatementGridItem.setStatement = (title, statementModule) => ({
    title,
    children: <statementModule.Container />,
    shouldDisplaySelector: statementModule.shouldDisplaySelector,
});
