import { combineReducers } from "redux-immutable";

export default (actions) => {
    const { SET_PAGE_STATE, SET_CANCELLATION_SUBMITTED } = actions;

    const changePropositionsPageState = (state = null, action) => {
        switch (action.type) {
            case SET_PAGE_STATE:
                return action.payload;
            default:
                return state;
        }
    };

    const isCancellationSubmitted = (state = null, action) => {
        switch (action.type) {
            case SET_CANCELLATION_SUBMITTED:
                return action.payload;
            default:
                return state;
        }
    };

    return combineReducers({
        pageState: changePropositionsPageState,
        isCancellationSubmitted: isCancellationSubmitted,
    });
};
