import React, { FC, useState } from "react";
import { InputAdornment, StandardTextFieldProps } from "@mui/material";

import { StyledTextField } from "ui-library/Styled";
import { ErrorContent, Icon, PopoverTooltip, Typography } from "ui-library/atoms";
import { Box, Flexbox } from "ui-library/layouts";

import { Label } from "./Label";

export interface PasswordProps extends StandardTextFieldProps {
    id: string;
    label?: string;
    tooltip?: string;
    error?: boolean;
    errorContent?: string;
    sufix?: string;
    labelDesc?: string;
    labelOptional?: string;
}

export const Password: FC<PasswordProps> = ({
    labelDesc,
    errorContent,
    id,
    label,
    tooltip,
    error,
    sufix,
    rows,
    size = "medium",
    labelOptional,
    ...props
}) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleEye = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div>
            <Box>
                <Flexbox alignItems={"flex-start"} gap={1}>
                    <Label label={label} id={id} labelOptional={labelOptional} />
                    <Box mt={0.5}>{tooltip && <PopoverTooltip title={tooltip} />}</Box>
                </Flexbox>
                {labelDesc && (
                    <Box mt={0.5} mb={1}>
                        <Typography color={"gray"} variant="caption">
                            {labelDesc}
                        </Typography>
                    </Box>
                )}
            </Box>
            <StyledTextField
                $password={true}
                type={showPassword ? "text" : "password"}
                id={id}
                error={error}
                label={null}
                hiddenLabel={true}
                variant="outlined"
                $size={size}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Icon className="icon" onClick={handleEye} size={16} icon="eye" />
                        </InputAdornment>
                    ),
                }}
                {...props}
            />

            {error ? <ErrorContent content={errorContent} /> : ""}
        </div>
    );
};
