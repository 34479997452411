import React, { FC } from "react";

import { InfoPanel } from "ui-library/modules";
import { ContentBox } from "ui-library/layouts";
import { Typography } from "ui-library/atoms";

export interface InfoMessageInfoPanelProps {
    text: string;
}

export const InfoMessageInfoPanel: FC<InfoMessageInfoPanelProps> = ({ text }) => (
    <InfoPanel bgVariant={"blueGradient"}>
        <ContentBox>
            <Typography markDown={text} />
        </ContentBox>
    </InfoPanel>
);

export default InfoMessageInfoPanel;
