import { call, put } from "redux-saga/effects";

import auth from "core/auth";
import errorHandling from "core/errorHandling";
import { formWrapper, validateWithRecaptcha } from "core/form";
import { fn, sentry } from "core/util";
import { userApi } from "serverApi";

export function* createSaga(formName, idObject) {
    try {
        yield call(formSaga(formName), idObject);
    } catch (e) {
        sentry.captureException(e);
        yield put(errorHandling.addServiceError(formName, e.identifier));
    }
}

const formSaga = (formName) =>
    formWrapper(formName, {
        initialize() {
            return {};
        },
        *save(values) {
            const recaptchaValue = yield call(validateWithRecaptcha);

            if (fn.isEmpty(recaptchaValue) && process.env.RECAPTCHA_REQUIRED) {
                // yield put(setError(auth.ERROR_MISSING_RECAPTCHA));
                sentry.captureException(new Error("Google recaptcha missing"));
            } else {
                yield call(
                    userApi.changeUsername,
                    {
                        username: values.get("username"),
                        password: values.get("password"),
                    },
                    recaptchaValue,
                );
                yield put(auth.logOut(auth.LogoutAction.USERNAME_CHANGED));
            }
        },
    });
