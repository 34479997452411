import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { Button, Loading } from "ui-library/atoms";
import { Box, ContentBox, Flexbox } from "ui-library/layouts";
import { ModalBody, ModalFooter } from "ui-library/modules";

import i18n from "core/i18n";
import { ModalOtpFormLayout } from "containers/modal";
import errorHandling, { OTP_WRONG_MOBILE_NUMBER_FIN_OPERATIONS } from "core/errorHandling";
import { Field, form, InputText, max20Characters, phoneNumberCharacters } from "core/form";

const ActiveOperationsPhoneNumberForm = ({
    isActiveOperationsEnabledSelector,
    formName,
    canSubmit,
    handleSubmit,
    submitting,
    OtpModule,
    isRegular,
}) => {
    const { t } = i18n.useTranslation();

    const isActiveOperationsEnabled = useSelector(isActiveOperationsEnabledSelector);
    const isOtpVisibleSelector = OtpModule.createIsVisibleSelector(formName);
    const isOtpVisible = useSelector(isOtpVisibleSelector);
    const otpWrongNumberErrorExists = useSelector(errorHandling.isServiceInErrorState(OTP_WRONG_MOBILE_NUMBER_FIN_OPERATIONS));

    const dispatch = useDispatch();
    const onOtpBack = () => dispatch(OtpModule.clear(formName));

    return (
        <>
            <ModalBody>
                <Loading loading={submitting}>
                    {!isOtpVisible && (
                        <Box>
                            <ContentBox>
                                <Field
                                    name="newActiveOperationsPhoneNumber"
                                    component={InputText}
                                    label={t("form.activeOperationsPhoneNumber")}
                                    // validated async in saga!
                                    normalize={[phoneNumberCharacters, max20Characters]}
                                    onEnter={handleSubmit}
                                />
                            </ContentBox>
                        </Box>
                    )}
                    {isOtpVisible && (
                        <OtpModule.Container
                            formName={formName}
                            // ErrorComponent={isRegular ? OtpWrongNumberRegular : OtpWrongNumberDirect}
                        />
                    )}
                </Loading>
            </ModalBody>
            <ModalFooter>
                <Flexbox gap={2} justifyContent="space-between">
                    {isOtpVisible && !otpWrongNumberErrorExists && (
                        <Button onClick={onOtpBack} variant="outlined" color="blue">
                            {t("form.otp.backToFirstStep")}
                        </Button>
                    )}
                    {isActiveOperationsEnabled && (
                        <Button onClick={handleSubmit} variant="contained" color="blue" disabled={!canSubmit || otpWrongNumberErrorExists}>
                            {t("common.send")}
                        </Button>
                    )}
                </Flexbox>
            </ModalFooter>
        </>
    );
};

ActiveOperationsPhoneNumberForm.propTypes = {
    isActiveOperationsEnabledSelector: PropTypes.func.isRequired,
    formName: PropTypes.string.isRequired,
    canSubmit: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    OtpModule: PropTypes.object.isRequired,
    isRegular: PropTypes.bool.isRequired,
};

export const createContainer = (formName, isActiveOperationsEnabledSelector, OtpModule, isRegular) =>
    form(formName, { asyncValidationFields: ["newActiveOperationsPhoneNumber"] })((props) => {
        const { t } = i18n.useTranslation();
        return (
            <ModalOtpFormLayout formName={formName} formTitle={t("form.activeOperationsPhoneNumber")} formIcon="circle-dollar">
                <ActiveOperationsPhoneNumberForm
                    {...props}
                    formName={formName}
                    isActiveOperationsEnabledSelector={isActiveOperationsEnabledSelector}
                    OtpModule={OtpModule}
                    isRegular={isRegular}
                />
            </ModalOtpFormLayout>
        );
    });
