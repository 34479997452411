import { NAME } from "./constants";
import reducer from "./reducer";
import saga from "./saga";
import innerRoutes from "./innerRoutes";

import { DataProcessingTab, ProfileTab, UnverifiedUserTab, VerificationTab } from "./tabs";

export { default as UserSettingsPage } from "./Container";

export const UserSettingsTabs = {
    DataProcessingTab,
    ProfileTab,
    VerificationTab,
    UnverifiedUserTab,
};

export { UserVerificationForm } from "./form";

export default {
    NAME,
    reducer,
    saga,
    innerRoutes,
};
