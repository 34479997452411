import { Box } from "ui-library/layouts/Box/Box";
import { withTheme } from "@mui/styles";
import styled from "styled-components";
import { Dialog } from "@mui/material";

export const StyledModal = withTheme(styled(Dialog)`
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        .MuiPaper-root {
            max-height: 80%;
            border-radius: 10px 10px 0 0;
            width: 100%;
            margin: 0;
            position: absolute;
            bottom: 0;
            max-width: unset;
        }
    }
`);

export const StyledModalHeader = withTheme(styled(Box)`
    background-color: ${(props) => props.theme.palette.blue.soft};
    padding: 24px;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        position: fixed;
        width: 100%;
        z-index: 9999;
        border-radius: 10px 10px 0 0;
    }

    .close-btn {
        &:hover {
            cursor: pointer;
        }
    }
`);

export const StyledModalBody = withTheme(styled(Box)`
    padding: 24px;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        margin-top: 80px;
        margin-bottom: 90px;
    }
`);

export const StyledModalFooter = withTheme(styled(Box)`
    padding: 24px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    border-top: 1px solid ${(props) => props.theme.palette.gray.line};
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        position: fixed;
        width: 100%;
        bottom: 0;
        z-index: 9999;
        background-color: ${(props) => props.theme.palette.white.main};
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 20px;
    }
`);
