import { v4 as uuid } from "uuid";

import { model } from "core/util";
import { RiskType } from "enums";

import { AmountLegacy, LegalEntityLegacy, SimpleCodeValue } from "../common";

import { Validity } from "./Validity";
import { Coverage } from "./Coverage";

export const InsuredVehicle = model.createModelLegacy("InsuredVehicle", (json) => ({
    reactKey: uuid(),
    riskType: RiskType.VEHICLE,
    identifier: json.Identifier,
    lineOfBusinessClasses: json.LineOfBusinessClasses,
    description: json.description,
    category: SimpleCodeValue.fromServer(json.Category),
    riskCode: json.RiskCode,
    insuranceCost: AmountLegacy.fromServer(json.InsuranceCost),
    validity: Validity.fromServer(json.Validity),
    coverages: Coverage.fromServerList(json.Coverages),
    manufacturer: json.Manufacturer,
    model: json.Model,
    vinNumber: json.VinNumber,
    licencePlateNumber: json.LicencePlateNumber,
    vehicleLicenceNumber: json.VehicleLicenceNumber,
    vehicleOwner: LegalEntityLegacy.fromServer(json.VehicleOwner),
    vehicleOperator: LegalEntityLegacy.fromServer(json.VehicleOperator),
}));
