import { Record } from "immutable";

const RedeemPensionPpPensionTypes = Record({ id: null, msg: null, description: null });

export default Object.freeze({
    RETIREMENT_PENSION: new RedeemPensionPpPensionTypes({
        id: "RETIREMENT_PENSION",
        msg: "pension.retirement",
        description: "pension.redeemSavings.pp.option.retirementPension",
    }),
    SERVICE_PENSION: new RedeemPensionPpPensionTypes({
        id: "SERVICE_PENSION",
        msg: "pension.service",
        description: "pension.redeemSavings.pp.option.servicePension",
    }),
    EARLY_PAYMENT: new RedeemPensionPpPensionTypes({
        id: "EARLY_PAYMENT",
        msg: "pension.earlyPayment",
        description: "pension.redeemSavings.pp.option.earlyPayment",
    }),
});
