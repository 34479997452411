import { combineReducers } from "redux-immutable";

import { AccountInformation } from "model/user";

import { SET_USER_ACCOUNT } from "./actions";

const userAccount = (state = new AccountInformation(), { type, payload }) => {
    switch (type) {
        case SET_USER_ACCOUNT:
            return payload.userAccount;
        default:
            return state;
    }
};

export default combineReducers({
    userAccount,
});
