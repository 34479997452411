import { call, put } from "redux-saga/effects";
import { formWrapper } from "core/form";
import errorHandling from "core/errorHandling";
import { sentry } from "core/util";
import { incidentsApi } from "serverApi";

export function* createSaga(formName, incidentId, claimId) {
    try {
        yield call(formSaga(formName), incidentId, claimId);
    } catch (e) {
        sentry.captureException(e);
        yield put(errorHandling.addServiceError(formName, e.identifier));
    }
}

const formSaga = (formName) =>
    formWrapper(formName, {
        *initialize() {
            yield put(errorHandling.removeServiceErrors(formName));
            return {};
        },
        *save(values, incidentId, claimId) {
            yield call(incidentsApi.sendClaimNote, incidentId, claimId, values);
            return values;
        },
    });
