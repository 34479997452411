import { formatLocalizedValueMap } from "core/formatters";
import { model } from "core/util";

import { PROPOSITION_PAGE_STATE } from "./constants";
import { ChangePropositionType } from "enums";
import { Tabs } from "routeConstants";

export const getOperationTypeValue = (pageState, changeProposition, sellSwitchMap, changePropositionTypeMap) => {
    switch (pageState.changePropositionPageState) {
        case PROPOSITION_PAGE_STATE.MUTUAL_FUNDS_TRANSACTION:
            return formatLocalizedValueMap(
                changeProposition?.changeProposition?.mutualFundsTransaction?.transactionType?.code,
                sellSwitchMap,
            );
        case PROPOSITION_PAGE_STATE.MUTUAL_FUNDS_PROFILE_CHANGE:
        case PROPOSITION_PAGE_STATE.PENSION_FUNDS_CHANGE:
        case PROPOSITION_PAGE_STATE.PENSION_INVESTMENT_STRATEGIES_CHANGE:
            return formatLocalizedValueMap(pageState?.changePropositionPageState, changePropositionTypeMap);
        default:
            return "";
    }
};

export const getChangeType = (row, locale, changePropositionChangeMap, changePropositionPensionTypeMap) => {
    const changePropositionsType = row.changeProposition.type;
    switch (changePropositionsType) {
        case PROPOSITION_PAGE_STATE.MUTUAL_FUNDS_PROFILE_CHANGE:
            return model.getLocaleCodeName(row.changeProposition.mutualFundsProfileChange.targetProfile, locale);
        case PROPOSITION_PAGE_STATE.PENSION_FUNDS_CHANGE:
        case PROPOSITION_PAGE_STATE.PENSION_INVESTMENT_STRATEGIES_CHANGE:
            return formatLocalizedValueMap(changePropositionsType, changePropositionPensionTypeMap);
        default:
            return formatLocalizedValueMap(changePropositionsType, changePropositionChangeMap);
    }
};

export const getTabRouteByAction = (changePropositionsType) => {
    switch (changePropositionsType) {
        // TODO: modal window ??
        // case (ChangePropositionType.MUTUAL_FUNDS_PROFILE_CHANGE.id):
        // return mutualFundsProfileChangeFormConstant;
        case ChangePropositionType.MUTUAL_FUNDS_TRANSACTION.id:
            return Tabs.SELL_SWITCH_FORM;
        case ChangePropositionType.PENSION_FUNDS_CHANGE.id:
        case ChangePropositionType.PENSION_INVESTMENT_STRATEGIES_CHANGE.id:
            return Tabs.PENSION_DDS_FUNDS_CHANGE_FORM;
        default:
            return null;
    }
};
