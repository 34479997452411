import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { ContentBox, Flexbox, Section } from "ui-library/layouts";
import { Button, Loading, Typography } from "ui-library/atoms";
import { FormBlock } from "ui-library/modules";

import i18n from "core/i18n";
import { FormWideDisabledProvider, getFormFieldValue } from "core/form";
import pageContext from "core/pageContext";

import { FeatureFlag } from "types";
import { FundSellTarget, MutualFundsPeriodicity } from "enums";

import AccountOwnerIdentification from "./AccountOwnerIdentification";
import TargetSectionDirect from "./TargetSectionDirect";
import { MeansSectionDirect } from "./MeansSectionDirect";
import { TypeSectionDirect } from "./TypeSectionDirect";
import { SourceSectionDirect } from "./SourceSectionDirect";

export const InnerContainerDirect = ({
    handleSubmit,
    submitting,
    isOtpVisible,
    otpWrongNumberErrorExists,
    onOtpBack,
    isCancelling,
    OtpModule,
    formName,
    canSubmit,
    onBack,
    getTargetFundCodes,
    getSourceFundCodes,
    createGetSourceFundOptions,
    createGetTargetFundOptions,
    getFundSellTypeOptions,
    getFundSellTargetOptions,
    getLocalizedFundSellMeansDirect,
    getShouldCheckIbanCountry,
}) => {
    const { t } = i18n.useTranslation();

    const typeFieldSelector = getFormFieldValue(formName, "type");
    const typeFieldValue = useSelector(typeFieldSelector);

    const targetFieldSelector = getFormFieldValue(formName, "target");
    const targetFieldValue = useSelector(targetFieldSelector);
    const isOwnerVerifyFeatureEnabled = pageContext.useIsFeatureFlagEnabled(FeatureFlag.MUTUAL_FUNDS_ACCOUNT_OWNER_VERIFY);

    return (
        <FormBlock>
            <Loading loading={submitting}>
                {!isOtpVisible && (
                    <>
                        <FormWideDisabledProvider value={isCancelling}>
                            <SourceSectionDirect
                                getSourceFundCodes={getSourceFundCodes}
                                createGetSourceFundOptions={createGetSourceFundOptions}
                            />

                            <MeansSectionDirect formName={formName} getLocalizedFundSellMeansDirect={getLocalizedFundSellMeansDirect} />

                            <TypeSectionDirect formName={formName} getFundSellTypeOptions={getFundSellTypeOptions} />

                            <TargetSectionDirect
                                formName={formName}
                                getFundSellTargetOptions={getFundSellTargetOptions}
                                createGetTargetFundOptions={createGetTargetFundOptions}
                                getTargetFundCodes={getTargetFundCodes}
                                getShouldCheckIbanCountry={getShouldCheckIbanCountry}
                            />
                            {isOwnerVerifyFeatureEnabled &&
                                targetFieldValue === FundSellTarget.SELL.id &&
                                typeFieldValue === MutualFundsPeriodicity.ONETIME.id && <AccountOwnerIdentification formName={formName} />}
                        </FormWideDisabledProvider>

                        <Section>
                            <ContentBox>
                                <Typography Typography fontSize={12} markDown={t("investments.sellSwitch.mutualFundsDirect.footnote")} />
                            </ContentBox>
                        </Section>
                    </>
                )}

                {isOtpVisible && (
                    <OtpModule.Container
                        formName={formName}
                        // ErrorComponent={OtpWrongNumberDirect}
                    />
                )}
            </Loading>

            <Flexbox gap={2} justifyContent="space-between">
                <Button onClick={isOtpVisible && !otpWrongNumberErrorExists ? onOtpBack : onBack} variant="outlined" color="blue">
                    {isOtpVisible && !otpWrongNumberErrorExists ? t("form.otp.backToFirstStep") : t("common.back")}
                </Button>
                <Button onClick={handleSubmit} variant="contained" color="blue" disabled={!canSubmit || otpWrongNumberErrorExists}>
                    {isCancelling ? t("investments.sellSwitch.cancel") : t("common.send")}
                </Button>
            </Flexbox>
        </FormBlock>
    );
};

InnerContainerDirect.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    OtpModule: PropTypes.object.isRequired,
    formName: PropTypes.string.isRequired,
    canSubmit: PropTypes.bool.isRequired,
    isOtpVisible: PropTypes.bool.isRequired,
    otpWrongNumberErrorExists: PropTypes.bool.isRequired,
    getTargetFundCodes: PropTypes.func.isRequired,
    getSourceFundCodes: PropTypes.func.isRequired,
    createGetSourceFundOptions: PropTypes.func.isRequired,
    createGetTargetFundOptions: PropTypes.func.isRequired,
    getFundSellTypeOptions: PropTypes.func.isRequired,
    getFundSellTargetOptions: PropTypes.func.isRequired,
    getLocalizedFundSellMeansDirect: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
    onOtpBack: PropTypes.func.isRequired,
};
