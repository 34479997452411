import { model } from "core/util";
import { PensionDssAccountDetail as PensionDssAccountDetailType, PensionDssAccountDetailDto } from "types";

import { PaymentsScheduler } from "../../common";
import { PensionDssDdsFundInvestmentStrategy } from "../PensionDssDdsFundInvestmentStrategy";
import { Pis } from "./Pis";

export const PensionDssAccountDetail = model.createModel<PensionDssAccountDetailDto, PensionDssAccountDetailType>((json) => ({
    pensionAccountEstablishmentDate: json.PensionAccountEstablishmentDate,
    signDate: json.SignDate,
    beginDate: json.BeginDate,
    paymentsScheduler: PaymentsScheduler.fromServer(json.PaymentsScheduler),
    voluntaryContribution: json.VoluntaryContribution,
    isTransferredContract: json.IsTransferredContract,
    isGuaranteedFundRatioReductionSet: json.IsGuaranteedFundRatioReductionSet,
    fundsInvestmentStrategies: PensionDssDdsFundInvestmentStrategy.fromServerList(json.FundsInvestmentStrategies),
    fundsInvestmentVoluntaryStrategies: PensionDssDdsFundInvestmentStrategy.fromServerList(json.FundsInvestmentVoluntaryStrategies),
    statementPassword: json.StatementPassword,
    pis: Pis.fromServer(json.Pis),
    investmentStrategy: json.InvestmentStrategy,
    isPassive: json.IsPassive,
}));
