import { Pages, Tabs } from "routeConstants";

export const NAME = "core/router";

export const HOME = "HOME";

export const LANG_PARAM = "lang";

export type PageInformation = {
    name: Pages;
    innerName?: Tabs;
    params?: any;
    query?: any;
    redirect?: string;
};

export type ActivePageStatus =
    | {
          isLoading: true;
          isError: false;
      }
    | {
          isLoading: false;
          isError: false;
      }
    | {
          isLoading: false;
          isError: true;
          msgKey?: string;
          errorIdentifier?: string;
      };

export interface RoutingState {
    pageInformation: PageInformation;
    activePageStatus: ActivePageStatus;
}

export const initialState: RoutingState = {
    pageInformation: {
        name: null,
    },
    activePageStatus: {
        isLoading: false,
        isError: false,
    },
};

export interface ActivePageErrorPayload {
    identifier: string;
    msgKey: string;
}

export interface NavigatePayload extends PageInformation {
    replace?: boolean;
}

export interface NavigateExternalPayload {
    url: string;
    query: any;
}
