import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import i18n from "core/i18n";

import { Option, Select, Typography } from "ui-library/atoms";
import { Box, Flexbox } from "ui-library/layouts";
import { ContractSwitch } from "ui-library/modules";
import useBreakpoints from "ui-library/theme/breakpoints";
import { ViewType } from "ui-library/modules/enums";

import { setDisplayedContentType } from "../actions";
import { getDisplayedContentType } from "../selectors";
import { ContractListContentType } from "../constants";

export interface FilterProps {
    viewType: ViewType;
    setViewType: (viewType: ViewType) => {};
}

export const Filter: FC<FilterProps> = ({ viewType, setViewType }) => {
    const { t } = i18n.useTranslation();
    const isFullWidth = useBreakpoints("md");

    const displayedContentType = useSelector(getDisplayedContentType);
    const dispatch = useDispatch();

    return (
        <Flexbox direction={{ xs: "column", sm: "row" }} justifyContent={"space-between"}>
            <Box mb={{ xs: 2, md: 0 }}>
                <Typography variant="h2">{t("common.contractList")}</Typography>
            </Box>
            <Box>
                <Flexbox alignItems={"center"} gap={1}>
                    <Select
                        value={displayedContentType}
                        onChange={(event) => dispatch(setDisplayedContentType(event.target.value))}
                        fullWidth
                        placeholder={t("common.choose")}
                        id="selectContentType"
                    >
                        <Option value={ContractListContentType.ACTIVE_CONTRACTS}>{t("common.activeContractList")}</Option>
                        <Option value={ContractListContentType.INACTIVE_CONTRACTS}>{t("common.finishedContractList")}</Option>
                        <Option value={ContractListContentType.DOCUMENTS}>{t("documents.clientDocuments")}</Option>
                    </Select>

                    {isFullWidth && <ContractSwitch state={viewType} setState={setViewType} />}
                </Flexbox>
            </Box>
        </Flexbox>
    );
};
