import { Record } from "immutable";

class AmountFilterOperator extends Record({ id: null, msg: null }) {}

export default Object.freeze({
    LESS: new AmountFilterOperator({
        id: "amountLess",
        msg: "tableFilter.less",
    }),
    MORE: new AmountFilterOperator({
        id: "amountMore",
        msg: "tableFilter.more",
    }),
    EQUAL: new AmountFilterOperator({
        id: "amountEqual",
        msg: "tableFilter.equal",
    }),
});
