import React from "react";
import PropTypes from "prop-types";

import { ContentBox } from "ui-library/layouts";
import { LabelTextItem } from "ui-library/modules";

import i18n from "core/i18n";
import { localPhone, oneLineNameWithTitles } from "core/formatters";
import { getLocalizedOneLineAddressFull } from "core/localized";
import { model } from "core/util";
import userContracts from "core/userContracts";

import { AddressTypeCodeEnum } from "types";

export const ClientContractDataSection = ({ idObject, policyHolder }) => {
    const { t } = i18n.useTranslation();
    const contract = userContracts.useGetContractById(idObject);

    return (
        <ContentBox mt={4}>
            <LabelTextItem label={t("common.contractNumber")} text={contract?.contractNumber} />
            <LabelTextItem label={t("person.participant")} text={oneLineNameWithTitles(policyHolder)} />
            <LabelTextItem
                label={t("holder.contactAddress")}
                text={getLocalizedOneLineAddressFull(t, model.getAddressByTypeCode(policyHolder, AddressTypeCodeEnum.CON))}
            />
            <LabelTextItem label={t("holder.email")} text={policyHolder.email} />
            <LabelTextItem label={t("holder.phone")} text={localPhone(policyHolder.phone)} />
        </ContentBox>
    );
};

ClientContractDataSection.propTypes = {
    idObject: PropTypes.number.isRequired,
    policyHolder: PropTypes.object.isRequired,
};
