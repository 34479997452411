import { FC } from "react";
import { Box, BoxProps } from "./Box";

export const InlineBox: FC<BoxProps> = ({ children, fullWidth, ...props }) => {
    return (
        <Box component="span" display="inline-block" mr={2} width={fullWidth ? 1 : props.width} {...props}>
            {children}
        </Box>
    );
};
