import PropTypes from "prop-types";
import * as React from "react";
import { StyledBadge } from "ui-library/Styled";

export const Badge = ({ children, childPadding, badgeContent, ...props }) => {
    return (
        <StyledBadge $childPadding={childPadding} badgeContent={badgeContent} {...props}>
            {children}
        </StyledBadge>
    );
};

Badge.propTypes = {
    badgeContent: PropTypes.string,
    childPadding: PropTypes.number,
};

Badge.defaultProps = {
    childPadding: 0,
};
