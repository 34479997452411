import React from "react";
import { useParams } from "react-router-dom";

import { Container, Loading, Typography } from "ui-library/atoms";
import { ContentBox, Section, StaticPageLayout } from "ui-library/layouts";
import { useSelector } from "react-redux";

import fetching from "core/fetching";
import { HASH_PLACEHOLDER } from "core/util";
import { NAME } from "../constants";

import i18n from "core/i18n";

import { getIsError, getIsHashInvalid, getIsResendEpinCountExhausted, getPhoneShort } from "../selectors";

const getText = (isHashMissing, isHashInvalid, phoneShort, isResendEpinCountExhausted, isError, t) => {
    if (isHashMissing) {
        return t("epinExternal.invalidHash");
    }
    if (isError) {
        return t("epinExternal.error");
    } else if (isResendEpinCountExhausted) {
        return t("epinExternal.resendEpinCountExhausted");
    } else if (isHashInvalid) {
        return t("epinExternal.invalidHash");
    } else if (phoneShort) {
        return t("epinExternal.success", { phone: phoneShort });
    } else {
        return null;
    }
};

export const InnerContainer = () => {
    const { hash } = useParams();
    const isHashMissing = hash === HASH_PLACEHOLDER;

    const { t } = i18n.useTranslation();
    const phoneShort = useSelector(getPhoneShort);
    const isResendEpinCountExhausted = useSelector(getIsResendEpinCountExhausted);
    const isHashInvalid = useSelector(getIsHashInvalid);
    const isError = useSelector(getIsError);
    const text = getText(isHashMissing, isHashInvalid, phoneShort, isResendEpinCountExhausted, isError, t);
    const loading = useSelector(fetching.isFetching(NAME, true));
    return (
        <StaticPageLayout>
            <Loading loading={loading}>
                <Container maxWidth="md">
                    <Section>
                        <Typography variant="h1">{t("epinExternal.title")}</Typography>
                    </Section>
                </Container>
                <Container maxWidth="md">
                    <ContentBox mb={10}>
                        <Typography component="p" markDown={text} />
                    </ContentBox>
                </Container>
                <ContentBox mb={60} />
            </Loading>
        </StaticPageLayout>
    );
};
