import { model } from "core/util";
import { PensionDdsContractDetail as PensionDdsContractDetailType, PensionDdsContractDetailDto } from "types";

import { Holder } from "../../holder";
import { ElectronicCommunication } from "../../electronicCommunication";
import { PensionBeneficiary } from "../PensionBeneficiary";
import { PensionDdsAccountDetail } from "./PensionDdsAccountDetail";

export const PensionDdsContractDetail = model.createModel<PensionDdsContractDetailDto, PensionDdsContractDetailType>((json) => ({
    holder: Holder.fromServer(json.Holder),
    pensionDdsAccountDetail: PensionDdsAccountDetail.fromServer(json.PensionAccountDetail),
    beneficiaries: PensionBeneficiary.fromServerList(json.Beneficiaries),
    electronicCommunication: ElectronicCommunication.fromServer(json.ElectronicCommunication),
}));
