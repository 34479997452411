import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ContractListState, initialState, NAME, UpdateContractFinancialOperations, UpdateContractOverviewPayload } from "./constants";
import { ContractPolicy } from "types";
import { ContractStatus } from "enums";

export const setContracts: CaseReducer<ContractListState, PayloadAction<ContractPolicy[]>> = (state, { payload }) => {
    state.contracts = payload.reduce((acc, contract) => {
        acc[contract.idObject + ""] = contract;
        return acc;
    }, {});

    state.activeContractIds = payload
        .filter((contract) => contract.isActive || contract.idEnuContractStatus === ContractStatus.ACCEPTED)
        .map((contract) => contract.idObject);

    state.inactiveContractIds = payload
        .filter((contract) => !contract.isActive && contract.idEnuContractStatus !== ContractStatus.ACCEPTED)
        .map((contract) => contract.idObject);
};

export const updateContractOverview: CaseReducer<ContractListState, PayloadAction<UpdateContractOverviewPayload>> = (
    state,
    { payload: { data, idObject } },
) => {
    const contract = state.contracts[idObject];
    if (contract) {
        state.contracts[idObject].overview = data;
    }
};

export const updateContractFinancialOperations: CaseReducer<ContractListState, PayloadAction<UpdateContractFinancialOperations>> = (
    state,
    { payload: { data, idObject, isFailed } },
) => {
    const contract = state.contracts[idObject];
    if (contract && !isFailed) {
        contract.financialOperations = data;
    }
};

export const slice = createSlice({
    name: NAME,
    initialState,
    reducers: {
        setContracts,
        updateContractOverview,
        updateContractFinancialOperations,
    },
});

export default slice;
