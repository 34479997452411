import React from "react";
import PropTypes from "prop-types";

import { InsuranceDedactibleInfo, LabelTextItem } from "ui-library/modules";

import i18n from "core/i18n";
import { formatPercentage, localAmountNoDecimalWithCurrencyLegacy, localAmountWithCurrencyLegacy } from "core/formatters";
import { date, fn } from "core/util";
import { Coverage } from "model/harmonized";

const formatInsuranceLimit = (t, limit) =>
    fn.isAmountValueZeroOrValueNull(limit) ? t("common.byContractTerms") : localAmountNoDecimalWithCurrencyLegacy(limit);

const addNonEmpty = (label, value) => !fn.isEmpty(value) && <LabelTextItem minWidthLabel label={label} text={value} />;

const addNonZeroValue = (label, value, formatter) =>
    !fn.isEmptyOrZero(value) && <LabelTextItem minWidthLabel label={label} text={formatter(value)} />;

const addNonZeroAmount = (label, amount, formatter) =>
    !fn.isAmountValueZeroOrValueNull(amount) && <LabelTextItem minWidthLabel label={label} text={formatter(amount)} />;

export const NonLifeCoverageItem = ({ coverage }) => {
    const { t } = i18n.useTranslation();
    const validity = coverage.validity;

    return (
        <InsuranceDedactibleInfo cause={coverage.coverageText}>
            {addNonEmpty(t("insurance.limit"), formatInsuranceLimit(t, coverage.insuranceLimit))}
            {addNonEmpty(t("insurance.coverageCost"), formatInsuranceLimit(t, coverage.coverageCost))}

            {addNonZeroValue(t("insurance.participation"), coverage.insuranceParticipationPercent, formatPercentage)}
            {addNonZeroAmount(t("insurance.participation"), coverage.insuranceParticipation, localAmountWithCurrencyLegacy)}
            {addNonZeroAmount(t("insurance.participationMin"), coverage.insuranceParticipationMin, localAmountNoDecimalWithCurrencyLegacy)}
            {addNonZeroAmount(t("insurance.participationMax"), coverage.insuranceParticipationMax, localAmountNoDecimalWithCurrencyLegacy)}
            {addNonEmpty(t("insurance.participationMaxTheft"), coverage.insuranceParticipationMaxTheftPercentage, formatPercentage)}

            {addNonEmpty(
                t("insurance.coverageValidFromTo"),
                date.formatCoverageFromTo(validity?.dateValidFrom, validity?.dateValidTo, t("common.unlimitedDate")),
            )}
            {addNonEmpty(t("insurance.coverageDescription"), coverage.insuranceTariff?.value)}
        </InsuranceDedactibleInfo>
    );
};

NonLifeCoverageItem.propTypes = {
    coverage: PropTypes.instanceOf(Coverage).isRequired,
};
