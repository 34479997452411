import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { ContentBox, GridItem } from "ui-library/layouts";
import { DataTable } from "ui-library/modules";
import { Typography } from "ui-library/atoms";

import entity from "core/entity";
import fetching from "core/fetching";
import i18n from "core/i18n";
import pageContext from "core/pageContext";
import { FinancialOperationStatusFormatter, localAmountWithCurrencyLegacy, localFullDate } from "core/formatters";

import { FeatureFlag } from "types";
import { DEFAULT_TABLE_PAGE_SIZE, DEFAULT_TABLE_ROWS_OPTIONS } from "app/constants";

const columns = (t) => [
    {
        field: "valueDate",
        headerName: t("common.date"),
        flex: 2,
        sortable: false,
        renderCell: (params) => localFullDate(params.value),
    },
    {
        field: "type",
        headerName: t("common.categoryName"),
        flex: 2,
        sortable: false,
    },
    {
        field: "status",
        headerName: t("common.status"),
        flex: 2,
        sortable: false,
        renderCell: (params) => (
            <FinancialOperationStatusFormatter status={params.value} statusPendingText={t("payments.status.pending")} />
        ),
    },
    {
        field: "amount",
        headerName: t("common.amount"),
        flex: 1,
        align: "right",
        headerAlign: "right",
        sortable: false,
        renderCell: (params) => localAmountWithCurrencyLegacy(params.value),
    },
];

export const LifeFinancialOperationsGridItem = ({ entityName, financialOperationSelector }) => {
    const { t } = i18n.useTranslation();
    const isFinOperationsLoading = useSelector(fetching.isFetching(entityName, true));
    const isFinOperationsError = useSelector(entity.isError(entityName));
    const financialOperations = useSelector(financialOperationSelector);

    const isOnlinePaymentsEnabled = pageContext.useIsFeatureFlagEnabled(FeatureFlag.PAYMENT_CENTER_ONLINE_PAYMENTS);
    const paymentsTitle = isOnlinePaymentsEnabled ? t("payments.pendingPayments.info") : t("common.financialOperationsNotice");

    return (
        <GridItem xs={12}>
            <DataTable
                icon={"chart-bar"}
                title={t("payments.overview")}
                preContent={
                    <ContentBox>
                        <Typography fontSize={14}>{paymentsTitle}</Typography>
                    </ContentBox>
                }
                disableColumnReorder
                pageSize={DEFAULT_TABLE_PAGE_SIZE}
                rowsPerPageOptions={DEFAULT_TABLE_ROWS_OPTIONS}
                getRowId={(row) => row.id}
                rows={financialOperations.toArray()}
                columns={columns(t)}
                emptyMsg={t("common.noMovementsOnContract")}
                errorMsg={t("error.sectionUnavailable")}
                isLoading={isFinOperationsLoading}
                isError={isFinOperationsError}
            />
        </GridItem>
    );
};

LifeFinancialOperationsGridItem.propTypes = {
    entityName: PropTypes.string.isRequired,
    financialOperationSelector: PropTypes.func.isRequired,
};
