import { Record } from "immutable";

const RequiredDocumentState = Record({ id: null, msg: null, isCompleted: false });

export default Object.freeze({
    REQUIRED: new RequiredDocumentState({
        id: "REQUIRED",
        msg: "common.required",
        isCompleted: false,
    }),
    NOT_REQUIRED: new RequiredDocumentState({
        id: "NOT_REQUIRED",
        msg: "common.notRequired",
        isCompleted: false,
    }),
    COMPLETED: new RequiredDocumentState({
        id: "COMPLETED",
        msg: "common.completed",
        isCompleted: true,
    }),
    COMPLETED_CHECKED: new RequiredDocumentState({
        id: "COMPLETED_CHECKED",
        msg: "common.completed",
        isCompleted: true,
    }),
});
