import React from "react";
import PropTypes from "prop-types";

import { Box, Flexbox } from "ui-library/layouts";
import useBreakpoints from "ui-library/theme/breakpoints";

import { Icon, Typography } from "../../atoms";

export const TableHeader = ({ actions, icon, isEmpty, isLoading, title }) => {
    const isBreakMD = useBreakpoints("md");

    const showActions = !isEmpty && !isLoading && actions;

    return (
        <>
            {(icon || title) && (
                <Flexbox mb={{ xs: 0, md: 4 }} justifyContent={"space-between"} alignItems={{ xs: "baseline", md: "center" }}>
                    <Flexbox alignItems={"center"} gap={1.5} mb={{ xs: 2, md: 0 }}>
                        {icon && <Icon size={24} color="blue" icon={icon} />}
                        <Box>{title && <Typography variant="h3">{title}</Typography>}</Box>
                    </Flexbox>
                    {showActions && isBreakMD && (
                        <Flexbox gap={1} alignItems={"center"}>
                            {actions}
                        </Flexbox>
                    )}
                </Flexbox>
            )}

            {showActions && !isBreakMD && (
                <Flexbox mb={2} gap={1} alignItems={"center"}>
                    {actions}
                </Flexbox>
            )}
        </>
    );
};

TableHeader.propTypes = {
    actions: null,
};

TableHeader.propTypes = {
    actions: PropTypes.node,
    icon: PropTypes.string.isRequired,
    isEmpty: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
};
