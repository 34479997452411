import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { Button } from "ui-library/atoms";

import i18n from "core/i18n";
import router from "core/router";
import userContracts from "core/userContracts";
import products from "core/products";

import { Tabs } from "routeConstants";

const getRedeemSavingsLink = (topLevelRoute, idObject) => {
    if (!topLevelRoute || !idObject) {
        return null;
    }
    return router.getStaticUrl(topLevelRoute, Tabs.REDEEM_SAVINGS, { idObject });
};

export const BackToRedeemSavingsButton = ({ idObject }) => {
    const { t } = i18n.useTranslation();
    const navigate = useNavigate();

    const contract = userContracts.useGetContractById(idObject);
    const productDefinition = products.useGetProductDefinition(contract?.idEnuProductGroupLevelTwo);
    const backToPolicyHolderLink = getRedeemSavingsLink(productDefinition.topLevelRoute, idObject);

    return (
        <Button onClick={() => navigate(backToPolicyHolderLink)} variant="outlined" color="blue">
            {t("common.back")}
        </Button>
    );
};

BackToRedeemSavingsButton.propTypes = {
    idObject: PropTypes.number.isRequired,
};
