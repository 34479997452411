import React from "react";

import { Accordion } from "ui-library/atoms";
import { ContentBox } from "ui-library/layouts";

import i18n from "core/i18n";

export const TransferExample = ({ isDds = false }) => {
    const { t } = i18n.useTranslation();

    return (
        <ContentBox bigger>
            <Accordion
                id="accordion"
                data={[
                    {
                        title: t("pension.fundsChange.transferPensionUnits.example"),
                        children: isDds
                            ? t("pension.fundsChange.transferPensionUnits.fullExampleDds")
                            : t("pension.fundsChange.transferPensionUnits.fullExampleDss"),
                    },
                ]}
            />
        </ContentBox>
    );
};
