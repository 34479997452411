import { PageModule } from "app/activePages";

let allRoutes: { [name: string]: PageModule } = {};

export const registerRoutes = (activePages: { [name: string]: PageModule }) => {
    allRoutes = { ...allRoutes, ...activePages };
    return activePages;
};

export const getAllRoutes = () => allRoutes;

export const getTopLevelRouteUrl = (name: string): PageModule => getAllRoutes()[name];

export const getTopLevelRoutePackage = (name: string) => getAllRoutes()[name].pageDefinition;

const getParamNames = (path): string[] => {
    if (!path || !path.topRouteUrl) {
        return [];
    }
    let paramNames: string[] = [];
    let positionStart: number = null;
    let positionEnd: number = null;

    path.topRouteUrl.split("").forEach((char, index) => {
        if (char === ":") {
            positionStart = index + 1;
        } else if (char === "/" && positionStart) {
            positionEnd = index - 1;
        }
        if (index === path.length - 1 && positionStart) {
            positionEnd = path.length - 1;
        }
        if (positionStart && positionEnd) {
            paramNames.push(path.substring(positionStart, positionEnd + 1));
            positionStart = null;
            positionEnd = null;
        }
    });
    return paramNames;
};

export const getTopPathParamNames = (name: string) => getParamNames(getTopLevelRouteUrl(name));
