import React from "react";
import PropTypes from "prop-types";

import { ContentBox } from "ui-library/layouts";

import { Field, InputText, maxLengthCharacters, required, SingleSelect, validAccountNumber, validAccountNumberPrefix } from "core/form";
import i18n from "core/i18n";

import AccountSectionCommon from "./AccountSectionCommon";

const AccountSectionCs = ({ bankCodeOptions }) => {
    const { t } = i18n.useTranslation();
    return (
        <>
            <ContentBox>
                <Field
                    name="account.prefix"
                    label={t("payments.targetAccountPrefix")}
                    component={InputText}
                    validateStatic={[validAccountNumberPrefix]}
                    normalize={maxLengthCharacters(6)}
                    md={6}
                />
            </ContentBox>
            <ContentBox fullWidth>
                <Field
                    name="account.number"
                    label={t("common.accountNumber")}
                    component={InputText}
                    validateStatic={[required, validAccountNumber]}
                    normalize={maxLengthCharacters(10)}
                    md={6}
                />
            </ContentBox>
            <ContentBox fullWidth>
                <Field
                    name="account.bankCode"
                    label={t("payments.bankCode")}
                    component={SingleSelect}
                    options={bankCodeOptions}
                    validateStatic={[required]}
                    md={6}
                />
            </ContentBox>
            <AccountSectionCommon />
        </>
    );
};

AccountSectionCs.propTypes = {
    bankCodeOptions: PropTypes.object.isRequired,
};

export default AccountSectionCs;
