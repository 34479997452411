import { app } from "core/util";

import { NAME } from "./constants";

/** contract data */
const getModel = app.createGetModel(NAME);

/** appState */
const getAppState = (state) => getModel(state).get("appState");
export const getDisclaimerStep = (state) => getAppState(state).get("disclaimerStep");
