import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Typography } from "ui-library/atoms";
import { ContentBox, Box, GridItem, GridLayout } from "ui-library/layouts";

import i18n from "core/i18n";
import { Field, InputText, maxValue100, numberCharacters, required, SingleSelect } from "core/form";

import { deleteFundWithPercentageRow } from "../actions";
import { FUND_FIELD, FUND_PERCENTAGE_BREAKDOWN, PERCENTAGE_FIELD } from "../constants";
import { getAvailableFundsOptionsForRow } from "../selectors";

export const FundWithPercentage = ({ index, formName, availableFundsProfileChangeOptionsSelector }) => {
    const dispatch = useDispatch();
    const removeFundWithPercentageRow = (index) => dispatch(deleteFundWithPercentageRow(index));
    const { t } = i18n.useTranslation();

    const availableFundsOptions = useSelector(getAvailableFundsOptionsForRow(formName, availableFundsProfileChangeOptionsSelector, index));

    return (
        <>
            <ContentBox>
                <GridLayout spacing={0.5}>
                    <GridItem xs={12} sm={6} md={6}>
                        <Box mt={0.5}>
                            <Field
                                name={`${FUND_PERCENTAGE_BREAKDOWN}[${index}].${FUND_FIELD}`}
                                component={SingleSelect}
                                options={availableFundsOptions}
                                disableSuccessTick // validated on BE, don't show success prematurely to user
                                validateStatic={[required]}
                            />
                        </Box>
                    </GridItem>
                    <GridItem xs={4} sm={2} md={2}>
                        <Field
                            name={`${FUND_PERCENTAGE_BREAKDOWN}[${index}].${PERCENTAGE_FIELD}`}
                            component={InputText}
                            disableSuccessTick // validated on BE, don't show success prematurely to user
                            normalize={[numberCharacters, maxValue100]}
                            validateStatic={[required]}
                        />
                    </GridItem>
                    <GridItem xs={1} sm={1} md={1}>
                        <Box mt={2}>
                            <Typography fontSize={16}>%</Typography>
                        </Box>
                    </GridItem>
                    <GridItem xs={5} sm={2} md={2}>
                        <Box mt={2}>
                            <Typography fontSize={16}>{t("common.minFivePercent")}</Typography>
                        </Box>
                    </GridItem>
                    <GridItem xs={2} sm={1} md={1}>
                        <Box mt={0.5}>
                            <Button
                                variant="outlined"
                                startIcon="trash-can"
                                color="blue"
                                onClick={() => removeFundWithPercentageRow(index)}
                            ></Button>
                        </Box>
                    </GridItem>
                </GridLayout>
            </ContentBox>
        </>
    );
};
