import { fromJS } from "immutable";

import { TableFilterOperator } from "enums";

import { FUND_COLUMN, NETTO_INVESTED_AMOUNT_COLUMN, TYPE_COLUMN, VALUE_DATE_COLUMN } from "../constants";

export const financialOperationsDefaultOperators = fromJS({
    type: { column: TYPE_COLUMN, operator: TableFilterOperator.CONTAINS },
    fund: { column: FUND_COLUMN, operator: TableFilterOperator.CONTAINS },
    dateFrom: { column: VALUE_DATE_COLUMN, operator: TableFilterOperator.AFTER },
    dateTo: { column: VALUE_DATE_COLUMN, operator: TableFilterOperator.BEFORE },
    amount: { column: NETTO_INVESTED_AMOUNT_COLUMN, operator: "" },
});
