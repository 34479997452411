import PropTypes from "prop-types";
import * as React from "react";

import { StyledScreenMessageLayout } from "ui-library/Styled";

export const ScreenMessageLayout = ({ children, ...props }) => {
    return <StyledScreenMessageLayout {...props}>{children}</StyledScreenMessageLayout>;
};

ScreenMessageLayout.propTypes = {
    children: PropTypes.node,
};
