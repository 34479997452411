import React from "react";
import { GridLayout } from "ui-library/layouts";

import { ContractGdprDataGridItem, GdprGridItem } from "../containers";
import { ModalGdprConsentForm } from "../form";

export const DataProcessingTab = () => (
    <GridLayout spacing={2}>
        <ContractGdprDataGridItem />
        <GdprGridItem />

        {/* Modal */}
        <ModalGdprConsentForm />
    </GridLayout>
);
