import React from "react";

import { BubbleAccordion, Button, Typography } from "ui-library/atoms";
import { ButtonsLayout, ContentBox, GridItem } from "ui-library/layouts";
import { InfoPanel } from "ui-library/modules";

import i18n from "core/i18n";
import pageContext from "core/pageContext";
import { useGetClaimReportingUrls, useGetSiteValue } from "core/hooks";
import { CallCenterContactData } from "enums";

const getFaqSkData = (t) => [
    {
        title: t("page.reportClaim.life.faq.titleOne"),
        children: t("page.reportClaim.life.faq.answerOne"),
    },
    {
        title: t("page.reportClaim.life.faq.titleTwo"),
        children: t("page.reportClaim.life.faq.answerTwo"),
    },
    {
        title: t("page.reportClaim.life.faq.titleThree"),
        children: t("page.reportClaim.life.faq.answerThree"),
    },
    {
        title: t("page.reportClaim.life.faq.titleFour"),
        children: t("page.reportClaim.life.faq.answerFour"),
    },
];

const getFaqCzData = (t) => [
    {
        title: t("page.reportClaim.life.faq.titleOne"),
        children: t("page.reportClaim.life.faq.answerOne"),
    },
    {
        title: t("page.reportClaim.life.faq.titleTwo"),
        children: t("page.reportClaim.life.faq.answerTwo"),
    },
    {
        title: t("page.reportClaim.life.faq.titleThree"),
        children: t("page.reportClaim.life.faq.answerThree"),
    },
    {
        title: t("page.reportClaim.life.faq.titleFour"),
        children: t("page.reportClaim.life.faq.answerFour"),
    },
];

export const ReportLifeSection = () => {
    const { t } = i18n.useTranslation();
    const { isSlovakSite } = pageContext.useGetPageLocalization();
    const claimReportingUrls = useGetClaimReportingUrls();
    const getSiteValue = useGetSiteValue();

    return (
        <>
            <GridItem xs={12}>
                <InfoPanel icon={"bullhorn"} title={t("page.reportClaim.life")}>
                    <ContentBox bigger>
                        <ButtonsLayout>
                            <Button color="blue" variant="contained" endIcon={"computer"} externalLink={claimReportingUrls.life} newTab>
                                {t("common.reportOnline")}
                            </Button>

                            <Button
                                color="blue"
                                variant="outlined"
                                endIcon={"phone"}
                                externalLink={`tel:${getSiteValue(CallCenterContactData.PHONE_AS_LINK)}`}
                            >
                                {getSiteValue(CallCenterContactData.PHONE)}
                            </Button>
                        </ButtonsLayout>
                    </ContentBox>

                    <ContentBox>
                        <Typography variant="h4" markDown={t("page.reportClaim.titleWhatToPrepare")} />
                    </ContentBox>

                    <ContentBox bigger>
                        <Typography listVariant="check" markDown={t("page.reportClaim.life.whatToPrepare")} />
                    </ContentBox>
                </InfoPanel>
            </GridItem>

            <GridItem xs={12}>
                <ContentBox>
                    <Typography variant={"h3"}>{t("page.reportClaim.faqTitle")}</Typography>
                </ContentBox>
                <BubbleAccordion data={isSlovakSite ? getFaqSkData(t) : getFaqCzData(t)} id="faqAccordion" />
            </GridItem>
        </>
    );
};
