import { model } from "core/util";
import { AmountLegacy } from "../common";

export const PensionDssDdsContractBalanceSheet = model.createModelLegacy("PensionDssDdsContractBalanceSheet", (json) => ({
    compulsoryAmountTransfersTotal: AmountLegacy.fromServer(json.CompulsoryAmountTransfersTotal),
    compulsoryPaymentsTotal: AmountLegacy.fromServer(json.CompulsoryPaymentsTotal),
    voluntaryAmountTransfersTotal: AmountLegacy.fromServer(json.VoluntaryAmountTransfersTotal),
    voluntaryPaymentsTotal: AmountLegacy.fromServer(json.VoluntaryPaymentsTotal),
    holderPaymentsTotal: AmountLegacy.fromServer(json.HolderPaymentsTotal),
    employerPaymentsTotal: AmountLegacy.fromServer(json.EmployerPaymentsTotal),
    extraordinaryHolderPaymentsTotal: AmountLegacy.fromServer(json.ExtraordinaryHolderPaymentsTotal),
    extraordinaryEmployerPaymentsTotal: AmountLegacy.fromServer(json.ExtraordinaryEmployerPaymentsTotal),
    otherPaymentsTotal: AmountLegacy.fromServer(json.OtherPaymentsTotal),
    otherPensionContractsPaymentsTotal: AmountLegacy.fromServer(json.OtherPensionContractsPaymentsTotal),
    paymentsAmountTotal: AmountLegacy.fromServer(json.PaymentsAmountTotal),
}));
