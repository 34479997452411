import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import router from "core/router";
import { AgentOverviewGridItem, ContractDocumentsOverviewGridItem, HolderOverviewGridItem } from "containers/contract";
import { IndividualPersonType } from "enums";
import { Pages, Tabs } from "routeConstants";
import { GridLayout } from "ui-library/layouts";

import { ENTITY_CONTRACT_DOCUMENTS } from "../constants";
import { getPolicyHolder } from "../selectors";
import { InvestmentsOverviewPieChartGridItem } from "../containers";

export const OverviewTab: FC = () => {
    const { idObject } = useParams();
    const holder = useSelector(getPolicyHolder);

    return (
        <GridLayout defaultSpacing>
            <InvestmentsOverviewPieChartGridItem />

            <HolderOverviewGridItem
                holder={holder}
                individualPersonType={IndividualPersonType.SHAREHOLDER}
                detailLink={router.getStaticUrl(Pages.CONTRACT_REGULAR, Tabs.HOLDER, { idObject })}
            />

            <ContractDocumentsOverviewGridItem
                idObject={Number(idObject)}
                entityName={ENTITY_CONTRACT_DOCUMENTS}
                detailLink={router.getStaticUrl(Pages.CONTRACT_REGULAR, Tabs.DOCUMENTS, { idObject })}
            />

            <AgentOverviewGridItem
                idObject={Number(idObject)}
                detailLink={router.getStaticUrl(Pages.CONTRACT_REGULAR, Tabs.AGENT, { idObject })}
            />
        </GridLayout>
    );
};
