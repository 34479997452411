import React from "react";
import IPropTypes from "react-immutable-proptypes";
import PropTypes from "prop-types";
import { List } from "immutable";

import { Dropzone } from "ui-library/modules";

import { ACCEPTED_FILE_SIZE_BYTES, ACCEPTED_FILE_TYPES } from "../validation";

const FileUpload = ({ input, disabled, maxUploadedFiles, acceptedTypes, uploadErrorMsgMap, meta }) => (
    <Dropzone
        value={input.value || new List()}
        onChange={input.onChange}
        disabled={disabled}
        maxUploadedFiles={maxUploadedFiles}
        acceptedTypes={acceptedTypes}
        acceptedFileSize={ACCEPTED_FILE_SIZE_BYTES}
        uploadErrorMsgMap={uploadErrorMsgMap}
        meta={meta}
    />
);

FileUpload.propTypes = {
    meta: PropTypes.shape({
        error: IPropTypes.list,
        touched: PropTypes.bool.isRequired,
        asyncValidating: PropTypes.bool,
        dirty: PropTypes.bool,
        valid: PropTypes.bool,
        form: PropTypes.string,
    }).isRequired,
    input: PropTypes.shape({
        value: PropTypes.any,
        onChange: PropTypes.func.isRequired,
    }).isRequired,
    disabled: PropTypes.bool,
    maxUploadedFiles: PropTypes.number,
    acceptedTypes: PropTypes.object,
};

FileUpload.defaultProps = {
    disabled: false,
    maxUploadedFiles: 2,
    acceptedTypes: ACCEPTED_FILE_TYPES,
};

export default FileUpload;
