import { model } from "core/util";

import { AmountLegacy } from "../../common";

export const PensionPpFinancialOperation = model.createModelLegacy("PensionPpFinancialOperation", (json) => ({
    id: json.Id,
    type: json.Type,
    categoryCode: json.CategoryCode,
    categoryName: json.CategoryName,
    grossAmount: AmountLegacy.fromServer(json.GrossAmount),
    netAmount: AmountLegacy.fromServer(json.NetAmount),
    initDate: json.InitDate,
    valueDate: json.ValueDate,
    status: json.Status,
}));
