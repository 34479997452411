import { DssSavingStrategyFund, DdsSavingStrategyFund } from "enums";

// backend enums, do not change lightly
export const CHANGE_STRATEGY_DATA = "changeStrategyData";
export const TRANSFER_FUNDS_DATA = "transferFundsData";
export const GUARANTEED_FUND_RATIO_REDUCTION = "guaranteedFundRatioReduction";

export const DSS_BOND_FIELD_NAME = DssSavingStrategyFund.BOND.id;
export const DSS_STOCK_FIELD_NAME = DssSavingStrategyFund.STOCK.id;
export const DSS_INDEXED_FIELD_NAME = DssSavingStrategyFund.INDEXED.id;

export const DDS_GLOBAL_FIELD_NAME = DdsSavingStrategyFund.GLOBAL.id;
export const DDS_CONTRIBUTORY_FIELD_NAME = DdsSavingStrategyFund.CONTRIBUTORY.id;
export const DDS_INDEX_FIELD_NAME = DdsSavingStrategyFund.INDEX.id;

export const TRANSFER_FUNDS_DATA_DDS_CONTRIBUTORY = `${TRANSFER_FUNDS_DATA}.${DDS_CONTRIBUTORY_FIELD_NAME}`;
export const TRANSFER_FUNDS_DATA_DDS_INDEX = `${TRANSFER_FUNDS_DATA}.${DDS_INDEX_FIELD_NAME}`;
export const TRANSFER_FUNDS_DATA_DDS_GLOBAL = `${TRANSFER_FUNDS_DATA}.${DDS_GLOBAL_FIELD_NAME}`;

export const CHANGE_STRATEGY_DATA_DDS_CONTRIBUTORY = `${CHANGE_STRATEGY_DATA}.${DDS_CONTRIBUTORY_FIELD_NAME}`;
export const CHANGE_STRATEGY_DATA_DDS_INDEX = `${CHANGE_STRATEGY_DATA}.${DDS_INDEX_FIELD_NAME}`;
export const CHANGE_STRATEGY_DATA_DDS_GLOBAL = `${CHANGE_STRATEGY_DATA}.${DDS_GLOBAL_FIELD_NAME}`;

export const TRANSFER_FUNDS_DATA_DSS_STOCK = `${TRANSFER_FUNDS_DATA}.${DSS_STOCK_FIELD_NAME}`;
export const TRANSFER_FUNDS_DATA_DSS_INDEXED = `${TRANSFER_FUNDS_DATA}.${DSS_INDEXED_FIELD_NAME}`;
export const TRANSFER_FUNDS_DATA_DSS_BOND = `${TRANSFER_FUNDS_DATA}.${DSS_BOND_FIELD_NAME}`;

export const CHANGE_STRATEGY_DATA_DSS_STOCK = `${CHANGE_STRATEGY_DATA}.${DSS_STOCK_FIELD_NAME}`;
export const CHANGE_STRATEGY_DATA_DSS_INDEXED = `${CHANGE_STRATEGY_DATA}.${DSS_INDEXED_FIELD_NAME}`;
export const CHANGE_STRATEGY_DATA_DSS_BOND = `${CHANGE_STRATEGY_DATA}.${DSS_BOND_FIELD_NAME}`;
