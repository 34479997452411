import { NAME } from "./constants";

export const SET_SELECTS = `${NAME}/SET_SELECTS`;
export const SET_PAYMENT_METHOD = `${NAME}/SET_PAYMENT_METHOD`;

export const setSelects = (info, productGroup, contractId = null, fundId = null, amountType = null) => ({
    type: SET_SELECTS,
    payload: { contractId, productGroup, fundId, info, amountType },
});

export const setPaymentMethod = (method) => ({
    type: SET_PAYMENT_METHOD,
    payload: method,
});
