import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import useBreakpoints from "ui-library/theme/breakpoints";
import { InfoPanel } from "ui-library/modules";
import { Button } from "ui-library/atoms";
import { ContentBox } from "ui-library/layouts";

import i18n from "core/i18n";
import userContracts from "core/userContracts";
import { ContractSelect } from "containers";
import router from "core/router";
import { Pages, Params, Tabs } from "routeConstants";

import { setContractIdForPayment } from "../../actions";
import { getContractIdForPayment } from "../../selectors";

export const QuickPaymentInfoPanel: FC = () => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();
    const contracts = userContracts.useGetActiveContracts();

    const contractId = useSelector(getContractIdForPayment);

    const transitionAction = router.navigate(
        Pages.ONLINE_PAYMENT,
        Tabs.PAYMENTS_PAY,
        {},
        { [Params.PAYMENT_CONTRACT_ID]: contractId },
        true,
    );

    const breakpointMd = useBreakpoints("md");
    return (
        <InfoPanel
            droppable={!breakpointMd}
            bgVariant={"white"}
            icon={"credit-card"}
            title={t("payments.quickPayment")}
            actionButton={
                <Button variant={"contained"} color={"green"} disabled={!contractId} onClick={() => dispatch(transitionAction)}>
                    {t("payments.processToPayment")}
                </Button>
            }
        >
            <ContentBox>
                <ContractSelect
                    contracts={contracts}
                    setContractIdForPaymentAction={setContractIdForPayment}
                    contractIdForPaymentSelector={getContractIdForPayment}
                />
            </ContentBox>
        </InfoPanel>
    );
};
