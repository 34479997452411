import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Map } from "immutable";

import { ContentBox } from "ui-library/layouts";

import i18n from "core/i18n";
import { ContinueButton, InPageFormButtonsLayout } from "containers/inPageForm";
import { Field, getFormFieldValue, required, SingleSelect } from "core/form";
import { VehicleCancellationReason } from "types";

import { FIELD_CANCELLATION_REASON, SECTION_CONTACT_PERSON, SECTION_MARGIN, VIRTUAL_FIELD_CANCELLATION_REASONS } from "../constants";

import { AccountSection, CancellationNoteSection, ChangeDateSection, ContactPersonSection, RemovedFromRegisterationSection } from "./field";

type StepOneProps = {
    formName: string;
    handleSubmit: () => void;
    isSubmitting: boolean;
    canSubmit: boolean;
};

export const StepOne: FC<StepOneProps> = ({ formName, handleSubmit, isSubmitting, canSubmit }) => {
    const { t } = i18n.useTranslation();

    const cancellationReasons = useSelector(getFormFieldValue(formName, VIRTUAL_FIELD_CANCELLATION_REASONS));
    const cancellationReasonsOptions = cancellationReasons.map((item) => Map({ value: item.code, label: item.label }));
    const selectedCancellationReason = useSelector(getFormFieldValue(formName, FIELD_CANCELLATION_REASON));

    return (
        <>
            <ContentBox fullWidth mb={SECTION_MARGIN}>
                <Field
                    name={FIELD_CANCELLATION_REASON}
                    component={SingleSelect}
                    label={t("vehicle.change.contractCancellation.cancellationReason")}
                    options={cancellationReasonsOptions}
                    validateStatic={[required]}
                />
            </ContentBox>

            {selectedCancellationReason && <ChangeDateSection formName={formName} />}

            {selectedCancellationReason === VehicleCancellationReason.BY_AGREEMENT && <CancellationNoteSection />}

            {selectedCancellationReason === VehicleCancellationReason.INSURER_DEATH && (
                <ContactPersonSection formName={formName} sectionName={SECTION_CONTACT_PERSON} isSubmitting={isSubmitting} />
            )}

            {selectedCancellationReason === VehicleCancellationReason.REMOVED_FROM_REGISTER && <RemovedFromRegisterationSection />}

            {selectedCancellationReason && <AccountSection formName={formName} />}

            <InPageFormButtonsLayout
                rightButton={
                    <ContinueButton
                        handleSubmit={handleSubmit}
                        disabled={!selectedCancellationReason || !canSubmit || isSubmitting}
                        isSubmitting={isSubmitting}
                    />
                }
            />
        </>
    );
};
