import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { Icon, Typography } from "ui-library/atoms";
import { Box, ContentBox, Flexbox } from "ui-library/layouts";

import { SUBMIT_OK } from "../constants";

export const MessageViewer = ({ getMessagesFromState, internalAppLink }) => {
    const messages = useSelector(getMessagesFromState);

    return (
        <ContentBox sx={{ marginTop: "16px" }}>
            {messages
                .map((item, index) => (
                    // lint disabled for: 'messages' is a list of strings, that we are only adding new items to, so there is no need for more complicated key
                    // eslint-disable-next-line react/no-array-index-key
                    <Flexbox key={index} alignItems={{ xs: "baseline", sm: "center" }}>
                        <Icon
                            size={14}
                            icon={item.type === SUBMIT_OK ? "circle-check" : "circle-error"}
                            color={item.type === SUBMIT_OK ? "green" : "red"}
                        />
                        <Box ml={1}>
                            <Typography component="p" fontSize={14} markDown={item.message} internalAppLink={internalAppLink} />
                        </Box>
                    </Flexbox>
                ))
                .toArray()}
        </ContentBox>
    );
};

MessageViewer.propTypes = {
    getMessagesFromState: PropTypes.func.isRequired,
    internalAppLink: PropTypes.bool,
};

MessageViewer.defaultProps = {
    internalAppLink: false,
};
