type ModelType<JsonType, ResultType> = {
    fromServer: (json?: JsonType) => ResultType;
    fromServerList: (json?: JsonType[]) => ResultType[];
};

export const createModel = <JsonType, ResultType>(transform: (json: JsonType) => ResultType) => {
    const model = transform({} as JsonType) as ModelType<JsonType, ResultType>;
    model.fromServer = (json = {} as JsonType) => transform(json);
    model.fromServerList = (json = [] as JsonType[]) => json.map((item) => model.fromServer(item));
    return model;
};
