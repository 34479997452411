import React from "react";
import PropTypes from "prop-types";

import i18n from "core/i18n";
import { Icon, Option, Select, Typography } from "ui-library/atoms";
import { Box, Flexbox } from "ui-library/layouts";
import { StyledPaging } from "ui-library/Styled";
import useBreakpoints from "ui-library/theme/breakpoints";

export const Pagination = ({ initPage, initPageSize, itemsPerPage, itemsCount, onChangeSize, onChangePage, ...props }) => {
    const { t } = i18n.useTranslation();

    const [page, setPage] = React.useState(initPage);
    const [pageSize, setPageSize] = React.useState(initPageSize);
    const [pageCount, setPageCount] = React.useState(Math.ceil(itemsCount / initPageSize));
    const isSMBreak = useBreakpoints("sm");

    const calculatePageCount = (pageSize) => {
        setPageCount(Math.ceil(itemsCount / pageSize));
    };

    const handlePageSizeChange = (event) => {
        setPageSize(event.target.value);
        calculatePageCount(event.target.value);
        onChangeSize(event.target.value);
        onChangePage(0);
        setPage(0);
    };

    const handlePageChange = (page) => {
        onChangePage(page);
        setPage(page);
    };

    const handleFirst = () => {
        if (page !== 0 && pageCount > 1) {
            handlePageChange(0);
        }
    };

    const handlePrev = () => {
        if (page - 1 >= 0 && pageCount > 1) {
            handlePageChange(page - 1);
        }
    };

    const handleNext = () => {
        if (page + 1 < pageCount && pageCount > 1) {
            handlePageChange(page + 1);
        }
    };

    const handleLast = () => {
        if (page + 1 < pageCount && pageCount > 1) {
            handlePageChange(pageCount - 1);
        }
    };

    const prevDisabled = () => {
        if (page === 0) {
            return true;
        } else {
            return false;
        }
    };

    const nextDisabled = () => {
        if (page + 1 >= pageCount) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <StyledPaging
            mt={{ xs: 1, sm: 3 }}
            alignItems="center"
            justifyContent="flex-end"
            gap={{ xs: 1, sm: 6 }}
            flexDirection={{ xs: "column", sm: "row" }}
        >
            {isSMBreak && (
                <Flexbox gap={1} alignItems={"center"} width={{ xs: "100%", sm: "auto" }} justifyContent={{ xs: "flex-end", sm: "center" }}>
                    <Typography variant="caption" color="gray">
                        {t("common.rows")}
                    </Typography>
                    <Box maxWidth={"60px"}>
                        <Select id="pageSize" value={pageSize} onChange={handlePageSizeChange}>
                            {itemsPerPage.map((item, index) => (
                                <Option key={index} value={item}>
                                    {item}
                                </Option>
                            ))}
                        </Select>
                    </Box>
                </Flexbox>
            )}

            <Flexbox gap={1} order={{ xs: 2, sm: 1 }}>
                <Typography variant="caption" color="gray">
                    {t("common.page")}
                </Typography>
                <Typography variant="caption">
                    {page + 1} z {pageCount}
                </Typography>
            </Flexbox>

            <Flexbox gap={1}>
                <Box onClick={handleFirst} className={"page-icon " + (prevDisabled() ? " disabled" : "able")}>
                    <Icon icon="chevron-left-last" />
                </Box>
                <Box onClick={handlePrev} className={"page-icon " + (prevDisabled() ? "disabled" : "able")}>
                    <Icon icon="chevron-left" />
                </Box>
                <Box onClick={handleNext} className={"page-icon " + (nextDisabled() ? "disabled" : "able")}>
                    <Icon icon="chevron-right" />
                </Box>
                <Box onClick={handleLast} className={"page-icon " + (nextDisabled() ? "disabled" : "able")}>
                    <Icon icon="chevron-right-last" />
                </Box>
            </Flexbox>
        </StyledPaging>
    );
};

Pagination.propTypes = {
    initPage: PropTypes.number,
    initPageSize: PropTypes.number,
    itemsPerPage: PropTypes.array,
    itemsCount: PropTypes.number,
    onChangeSize: PropTypes.func,
    onChangePage: PropTypes.func,
};

Pagination.defaultProps = {
    initPage: 0,
    initPageSize: 10,
    itemsPerPage: [5, 10, 20],
};
