import PropTypes from "prop-types";
import { StyledContainer } from "ui-library/Styled";
import React from "react";

export const Container = ({ children, ...props }) => {
    return <StyledContainer {...props}>{children}</StyledContainer>;
};

Container.propTypes = {
    variant: PropTypes.string,
};

Container.defaultProps = {
    variant: "clean",
};
