import { Menu, MenuItem } from "@mui/material";
import { withTheme } from "@mui/styles";
import styled from "styled-components";

export const StyledDropdown = withTheme(styled(Menu)`
    .MuiMenu-paper {
        border: 1px solid ${(props) => props.theme.palette.gray.softBorder};
        box-shadow: 0px 20px 35px 0px #30313d1a;
        padding: 24px;
        overflow: inherit;
        .MuiMenu-list {
            padding: 0;
        }
        &:before {
            content: "";
            position: absolute;
            width: 10px;
            height: 10px;
            background: ${(props) => props.theme.palette.white.main};
            transform: rotate(315deg);
            border-right: 1px solid ${(props) => props.theme.palette.gray.softBorder};
            border-top: 1px solid ${(props) => props.theme.palette.gray.softBorder};
            top: -6px;
        }
        @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
            &:before {
                content: none;
            }
        }
    }

    &.dropdown-right {
        left: 22px;
        @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
            left: 5px;
        }
        .MuiMenu-paper {
            &:before {
                right: 25px;
            }
        }
    }
    &.dropdown-left {
        .MuiMenu-paper {
            &:before {
                left: 25px;
            }
        }
    }
`);

export const StyledDropdownItem = withTheme(styled(MenuItem)`
    margin-left: -24px;
    padding: 9px 9px 9px 24px;
    width: calc(100% + 48px);
    border-bottom: 1px solid ${(props) => props.theme.palette.gray.line};

    &:hover {
        background-color: rgba(0, 0, 0, 0.04);
    }
    &:last-child {
        border-bottom: none;
    }
`);
