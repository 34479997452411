import React from "react";

import carOption from "ui-library/assets/images/car-option.svg";
import houseOption from "ui-library/assets/images/house-option.svg";
import lifeInsuranceOption from "ui-library/assets/images/life-insurance-option.svg";
import travelInsuranceOption from "ui-library/assets/images/travel-insurance-option.svg";
import { Typography } from "ui-library/atoms";
import { ContentBox, GridItem, GridLayout } from "ui-library/layouts";
import { ImageLabelRadio, ImageLabelRadioGroup } from "ui-library/modules";

import i18n from "core/i18n";

import { ReportHomeSection, ReportLifeSection, ReportTravelSection, ReportVehicleSection } from "../containers-old";

const SECTION_VEHICLE = "VEHICLE";
const SECTION_HOME = "HOME";
const SECTION_TRAVEL = "TRAVEL";
const SECTION_LIFE = "SECTION_LIFE";

export const ReportClaimTabOld = () => {
    const { t } = i18n.useTranslation();

    const [insuranceEvent, setInsuranceEvent] = React.useState("");
    const insuranceEventChange = (event) => setInsuranceEvent(event.target.value);

    return (
        <GridLayout defaultSpacing>
            <GridItem md={12}>
                <ContentBox bigger>
                    <Typography component="h2" variant="h2">
                        {t("page.reportClaim.title")}
                    </Typography>
                </ContentBox>

                <ImageLabelRadioGroup value={insuranceEvent} onChange={insuranceEventChange}>
                    <GridLayout spacing={{ xs: 1, md: 4 }}>
                        <GridItem lg={3} md={6} xs={12}>
                            <ImageLabelRadio
                                id={SECTION_VEHICLE}
                                value={SECTION_VEHICLE}
                                labelImgSrc={carOption}
                                labelTitle={t("page.reportClaim.vehicle")}
                            />
                        </GridItem>
                        <GridItem lg={3} md={6} xs={12}>
                            <ImageLabelRadio
                                id={SECTION_HOME}
                                value={SECTION_HOME}
                                labelImgSrc={houseOption}
                                labelTitle={t("page.reportClaim.home")}
                                labelSubtitle={t("page.reportClaim.homeIncludes")}
                                small
                            />
                        </GridItem>
                        <GridItem lg={3} md={6} xs={12}>
                            <ImageLabelRadio
                                id={SECTION_TRAVEL}
                                value={SECTION_TRAVEL}
                                labelImgSrc={travelInsuranceOption}
                                labelTitle={t("page.reportClaim.travel")}
                            />
                        </GridItem>
                        <GridItem lg={3} md={6} xs={12}>
                            <ImageLabelRadio
                                id={SECTION_LIFE}
                                value={SECTION_LIFE}
                                labelImgSrc={lifeInsuranceOption}
                                labelTitle={t("page.reportClaim.life")}
                            />
                        </GridItem>
                    </GridLayout>
                </ImageLabelRadioGroup>
            </GridItem>

            {insuranceEvent === SECTION_VEHICLE && <ReportVehicleSection />}
            {insuranceEvent === SECTION_HOME && <ReportHomeSection />}
            {insuranceEvent === SECTION_TRAVEL && <ReportTravelSection />}
            {insuranceEvent === SECTION_LIFE && <ReportLifeSection />}
        </GridLayout>
    );
};
