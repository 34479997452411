import { NAME } from "./constants";
import { createAction } from "@reduxjs/toolkit";

import { app } from "core/util";
import { MutualFundsDirectContract } from "types";

export const SEND_ENQUEUE_REQUEST = `${NAME}/SEND_ENQUEUE_REQUEST`;
export const RESTART_ENQUEUE_CHECKING = `${NAME}/RESTART_ENQUEUE_CHECKING`;
export const CLEAR = `${NAME}/CLEAR`;

export const setMutualFundsDirectContractDetail = app.createActionWithPayload<MutualFundsDirectContract>(
    `${NAME}/SET_MUTUAL_FUNDS_DIRECT_CONTRACT_DETAIL`,
);

export const sendEnqueueRequest = (idObject, idOperation) => ({
    type: SEND_ENQUEUE_REQUEST,
    payload: { idObject, idOperation },
});

export const restartEnqueueChecking = () => ({
    type: RESTART_ENQUEUE_CHECKING,
});

export const clear = createAction(`${NAME}/CLEAR`);
