import { Address } from "types";

export const getPlainObjAddressByTypeCode = (
    addresses: Address[],
    typeCode: any,
    addressesIdentical?: any,
    contactPersonType?: any,
    showPersonInContactAddressForm?: any,
) => {
    const addressItem = addresses && addresses.find((item) => item.addressTypeCode === typeCode);
    if (!addressItem) {
        return {};
    }
    return {
        addressesIdentical,
        contactSubject: {
            // @ts-ignore todo: martin.rodin these two properties doesn't seem to exist -- check with Karol
            contactPerson: addressItem.contactPerson,
            // @ts-ignore
            contactCompanyName: addressItem.contactCompanyName,
            contactPersonType,
            showPersonInContactAddressForm,
        },
        street: addressItem.street,
        orientationNumber: addressItem.orientationNumber,
        descriptionNumber: addressItem.descriptionNumber,
        city: addressItem.city,
        zip: addressItem.postalCode,
        state: addressItem.countryCode,
    };
};
