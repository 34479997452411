import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from "@mui/lab";
import { withTheme } from "@mui/styles";
import styled from "styled-components";

const dotVariant = (props) => {
    switch (props.$status) {
        case "waiting":
            return `
            border : 2px solid  ${props.theme.palette.gray.mainLighter};
            background : ${props.theme.palette.gray.mainLighter};
        `;
        case "progress":
            return `
            border : 2px solid  ${props.theme.palette.green.main};
            background : ${props.theme.palette.white.main};
        `;
        case "finished":
            return `
            padding : 0;
            border : none;
            background : transparent;
            `;
        default:
            return "";
    }
};

const contentVariant = (props) => {
    switch (props.$status) {
        case "waiting":
            return `
            color : ${props.theme.palette.gray.main};
            path {
                fill : ${props.theme.palette.gray.main};
            }
        }
           
        `;
        case "progress":
            return `
            color :   ${props.theme.palette.orange.main};
            svg {
                path {
                    fill :  ${props.theme.palette.orange.main};
                }
            }
            font-weight : 700;
         
        `;
        case "finished":
            return `
            color : ${props.theme.palette.black.main};
            svg {
                path {
                    fill :  ${props.theme.palette.black.main};
                }
            }
            `;
        default:
            return "";
    }
};

export const StyledTimeline = withTheme(styled(Timeline)`
    padding: 0;
    margin: 0;
`);

export const StyledTimelineItem = withTheme(styled(TimelineItem)`
    min-height: unset;
    &:before {
        flex: 0;
        padding: 0;
    }
`);

export const StyledTimelineSeparator = withTheme(styled(TimelineSeparator)``);

export const StyledTimelineDot = withTheme(styled(TimelineDot)`
    padding: 6px;
    box-shadow: none;
    ${(props) => dotVariant(props)};
    margin: 0;
`);
export const StyledTimelineConnector = withTheme(styled(TimelineConnector)`
    background-color: ${(props) => (props.$finished ? props.theme.palette.green.main : props.theme.palette.gray.mainLighter)};
`);
export const StyledTimelineContent = withTheme(styled(TimelineContent)`
    padding: 0px 8px;
    margin-top: -4px;

    .content {
        background: transparent;
        ${(props) => contentVariant(props)};
    }

    .accordion {
        .Mui-expanded {
            margin: 0;
        }
    }

    .accordion-summary {
        min-height: unset;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 8px;
        border-bottom: 3px solid transparent;
        margin-bottom: 24px;

        padding: 0;
        transition: all ease 200ms;
        &:hover {
            filter: brightness(80%);
        }
        .MuiAccordionSummary-content {
            margin: 0;
            flex-grow: 0;
        }
        .MuiAccordionSummary-expandIconWrapper {
            margin: 6px 0;
        }
        &.Mui-expanded {
            transition: all ease 200ms;
            min-height: unset;
            p {
                font-weight: 700;
            }
        }
    }

    .accordion-details {
        padding: 0 0 20px 0;
    }
`);
