import React, { FC } from "react";

import { StyledDropdownOption } from "ui-library/Styled";
import { Box } from "ui-library/layouts/Box/Box";
import { Typography } from "ui-library/atoms/Typography/Typography";

export interface ContractOptionProps {
    contractName?: string;
    contractNumber?: string;
}

export const ContractOption: FC<ContractOptionProps> = ({ contractName = "", contractNumber = "", ...props }) => (
    <StyledDropdownOption complexOption={true} py={{ xs: 0.5, md: 0 }} direction={{ xs: "column", md: "row" }} {...props}>
        <>
            <Box>
                <Typography sx={{ width: "90px", display: "block", textAlign: { xs: "none", md: "right" } }} mr={1.75} variant="b">
                    {contractNumber}
                </Typography>
            </Box>
            <Box pr={2}>
                <Typography variant="p">{contractName}</Typography>
            </Box>
        </>
    </StyledDropdownOption>
);
