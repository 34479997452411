import { v4 as uuid } from "uuid";

import { model } from "core/util";
import { AmountLegacy, CodeValueLegacy } from "../common";

export const PensionDssDdsInvestmentsBasedOnFund = model.createModelLegacy("PensionDssDdsInvestmentsBasedOnFund", (json) => ({
    reactKey: uuid(),
    investmentFund: CodeValueLegacy.fromServer(json.InvestmentFund),
    numberOfShares: json.NumberOfShares,
    pricePerShare: AmountLegacy.fromServer(json.PricePerShare),
    actualInvestmentsValue: AmountLegacy.fromServer(json.ActualInvestmentsValue),
    type: json.Type,
}));
