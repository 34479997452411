import React from "react";

import { Link, Typography } from "ui-library/atoms";
import { fn, model } from "core/util";

import { formatPercentage } from "./numberFormatter";

export const nettoProfitHyperLinkFormatter = (profit, getProfitUrl) => {
    if (!profit || fn.isEmpty(profit.get("nettoProfit"))) {
        return "";
    }
    if (profit.get("sumRow")) {
        return <Typography>{formatPercentage(profit.get("nettoProfit"))}</Typography>;
    } else if (!profit.get("fundIsin")) {
        return <Link to={getProfitUrl(model.OPTION_ALL)}>{formatPercentage(profit.get("nettoProfit"))}</Link>;
    } else {
        return <Link to={getProfitUrl(profit.get("fundIsin"))}>{formatPercentage(profit.get("nettoProfit"))}</Link>;
    }
};
