import React from "react";

import { Box, ContentBox } from "ui-library/layouts";

import { BeneficiaryPerson } from "./BeneficiaryPerson";
import { BeneficiaryAddress } from "./BeneficiaryAddress";

export const BeneficiaryDps = ({ index }) => (
    <Box>
        <ContentBox>
            <BeneficiaryPerson index={index} />
        </ContentBox>
        <ContentBox>
            <BeneficiaryAddress index={index} />
        </ContentBox>
    </Box>
);
