import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import i18n from "core/i18n";
import modal from "core/modal";
import { Button } from "ui-library/atoms";

import { FORM_EDIT_CLAIM_NOTE } from "../../constants";

export const WriteClaimOrOrderNoteButton = ({ type }) => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();
    const buttonTitle = type === WriteClaimOrOrderNoteButton.Type.CLAIM ? t("claim.askQuestion") : t("claim.questionAboutOrder");

    return (
        <Button
            onClick={() => dispatch(modal.openModalForm(FORM_EDIT_CLAIM_NOTE))}
            startIcon={"chat"}
            variant={type === WriteClaimOrOrderNoteButton.Type.CLAIM ? "contained" : "outlined"}
            color="blue"
        >
            {buttonTitle}
        </Button>
    );
};

WriteClaimOrOrderNoteButton.propTypes = {
    type: PropTypes.string.isRequired,
};

WriteClaimOrOrderNoteButton.Type = Object.freeze({
    CLAIM: "CLAIM",
    ORDER: "ORDER",
});
