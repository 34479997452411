import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field as ReduxFormField } from "redux-form/immutable";
import { List } from "immutable";
import memoizeOne from "memoize-one";

import FormField from "./FormField";
import { compose, addDefaultNormalization } from "./normalization";
import { Consumer } from "./FormWideDisabledContext";

/**
 * This will create new single validator from array of validators, which will call each of them and collect error messages to
 * the immutable List. When no validator returns error, new validator will return undefined too.
 * This is to replace default redux form behavior, when only first failing validator is returned.
 */
const createGroupedValidation =
    (...validators) =>
    (value, values, formParams, fieldName) => {
        if (validators.length > 0 && formParams.asyncValidationFields && formParams.asyncValidationFields.includes(fieldName)) {
            console.error(
                `⛔ Field "${fieldName}" cannot contain both sync and async validations. Move sync validations to the async validation saga. ⛔`,
            );
        }
        const errorArray = validators.map((validationFn) => validationFn(value, values, formParams, fieldName)).filter((error) => !!error);
        if (errorArray.length > 0) {
            return List(errorArray);
        }
        return undefined;
    };

class Field extends Component {
    constructor(props) {
        super(props);
        /**
         * For each instance of field, we will create memoized function for grouping ensuring, that for similar group of validators,
         * new grouped validator have same reference.
         * Its important to call them with destructuring, since we care about identities of validators inside of array, not about identity
         * of array, which will be new everytime.
         * This is to prevent strange behaviour of redux-form, which was failing when each render, new validators references were given.
         *
         * WARNING: currently, its working only for non-changeable validations. For ability to change validations during component lifecycle,
         * you need to implement this in componentWillUpdate (or deriveStateFromProps as for react 16)
         */
        this.groupErrorValidators = memoizeOne(createGroupedValidation);
    }

    render() {
        const { component, validateStatic, normalize, disabled, validateDisabled, ...rest } = this.props;

        const passedProps = {
            component: FormField,
            inputComponent: component,
            normalize: compose(...addDefaultNormalization(normalize)),
            ...rest,
        };
        return (
            <Consumer>
                {(formWideDisable) => {
                    const fieldDisabled = formWideDisable || disabled;
                    return (
                        <ReduxFormField
                            {...passedProps}
                            disabled={fieldDisabled || validateDisabled}
                            validate={!fieldDisabled || validateDisabled ? [this.groupErrorValidators(...validateStatic)] : []}
                        />
                    );
                }}
            </Consumer>
        );
    }
}

Field.propTypes = {
    name: PropTypes.string.isRequired,
    component: PropTypes.any.isRequired,
    validateStatic: PropTypes.array,
    normalize: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
    disabled: PropTypes.bool,
};

Field.defaultProps = {
    validateStatic: [],
    normalize: null,
    disabled: false,
};

export default Field;
