import React, { FC } from "react";

import { GridLayout } from "ui-library/layouts";

import { ActualAccountBalanceGridItem, ActualInvestmentValue, PensionInvestmentStrategiesGridItem } from "../containers";

export const FinancialStatementTab: FC = () => {
    return (
        <GridLayout defaultSpacing>
            <ActualInvestmentValue />
            <PensionInvestmentStrategiesGridItem />
            <ActualAccountBalanceGridItem />
        </GridLayout>
    );
};
