import PropTypes from "prop-types";

import React from "react";

import { Typography, Icon } from "ui-library/atoms";

import { StyledInfoText } from "ui-library/Styled";

export const InfoText = ({ color, icon, text, ...props }) => {
    return (
        <StyledInfoText gap={1} alignItems={"center"} {...props}>
            <Icon size={16} color={color} icon={icon} />
            <Typography color={color}>{text}</Typography>
        </StyledInfoText>
    );
};

InfoText.propTypes = {
    color: PropTypes.string,
    icon: PropTypes.string,
    text: PropTypes.string,
};
