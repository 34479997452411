import { Record } from "immutable";

class DssFunds extends Record({ id: null, fundCode: null, fundName: null, fundColor: null, fundLink: null }) {}

export default Object.freeze({
    CONSERVATIVE: new DssFunds({
        id: "1",
        fundCode: "1",
        fundColor: "greenMain",
        fundLink: "fundLink.dss.SHARE",
    }),
    BOND: new DssFunds({
        id: "3",
        fundCode: "3",
        fundColor: "blueTyrkys",
        fundLink: "fundLink.dss.BOND",
    }),
    BALANCED: new DssFunds({
        id: "4",
        fundCode: "4",
        fundColor: "pinkMain",
        fundLink: "fundLink.dss.INDEX",
    }),
});
