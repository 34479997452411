import { call, put, select, takeEvery } from "redux-saga/effects";

import entity from "core/entity";
import i18n from "core/i18n";
import router from "core/router";
import { documentsApi } from "serverApi";

import { MESSAGE_MAPPING } from "./messages";
import { SUBMIT_ERROR, SUBMIT_OK, TAX_STATEMENT, YEARLY_STATEMENT } from "./constants";
import { API_STATEMENT_MAPPING } from "modules/statementsGetter/mapping";

/**
 * @param actions
 * @param selectors
 * @param getId of contract
 * @param documentType, year, extra, tax statements
 * @param documentsPath
 */
export default (actions, selectors, getId, documentType, documentsPath, enqueuedDocumentsEntity) => {
    return function* () {
        yield takeEvery(actions.STATEMENTS_SUBMIT, handleSubmitSaga);
    };

    function* handleSubmitSaga() {
        try {
            const idObject = yield select(getId);
            const year = yield select(selectors.getSelectedYear);
            const apiDocumentType = API_STATEMENT_MAPPING[documentType];

            if (documentType === YEARLY_STATEMENT || documentType === TAX_STATEMENT) {
                yield call(documentsApi.generateAndEnqueueDocument, idObject, apiDocumentType, { year });
            } else {
                yield call(documentsApi.generateAndEnqueueDocument, idObject, apiDocumentType);
            }

            // At this moment we are working in array this way. getStatic url no longer works with array.
            const link = router.getStaticUrl(documentsPath[0], documentsPath[1], { idObject: idObject });
            const message = i18n.translateDirectly(MESSAGE_MAPPING[documentType], { year, link });
            yield put(actions.addMessage({ message, type: SUBMIT_OK }));

            // If entity for enqueued documents is set we want to periodically check / refresh documents state.
            if (enqueuedDocumentsEntity) {
                yield call(entity.periodicallyCheckDocuments, 250, enqueuedDocumentsEntity, () =>
                    documentsApi.getContractEnqueuedDocuments(idObject),
                );
            }
        } catch (e) {
            const message = i18n.translateDirectly("error.generateDocumentUnavailable");
            yield put(actions.addMessage({ message, type: SUBMIT_ERROR }));
        }
    }
};
