import React, { FC, PropsWithChildren } from "react";
import { useSelector } from "react-redux";

import { isServiceInErrorState } from "./selectors";

export interface ErrorBoundaryProps extends PropsWithChildren {
    service: string;
    ErrorComponent?: React.ComponentType;
}

export const ErrorBoundary: FC<ErrorBoundaryProps> = ({ children, service, ErrorComponent, ...props }) => {
    const isServiceInError = useSelector(isServiceInErrorState(service));

    if (isServiceInError) {
        return <ErrorComponent {...props} />;
    } else {
        return <>{children}</>;
    }
};
