import { model } from "core/util";

import { AmountLegacy, PaymentsSchedulerLegacy } from "../common";

import { LifeInsureds } from "./LifeInsureds";
import { LifeBeneficiaries } from "./LifeBeneficiaries";
import { LifePaymentRequests } from "./LifePaymentRequests";
import { LifeBalanceOnContract } from "./LifeBalanceOnContract";
import { LifeInvestmentStrategy } from "./LifeInvestmentStrategy";

export const LifeAccountDetail = model.createModelLegacy("LifeAccountDetail", (json) => ({
    insureds: LifeInsureds.fromServerList(json.Insureds),
    beneficiaries: LifeBeneficiaries.fromServerList(json.Beneficiaries),
    insuranceCostSummary: AmountLegacy.fromServer(json.InsuranceCostSummary),
    actualPaymentsScheduler: PaymentsSchedulerLegacy.fromServer(json.ActualPaymentsScheduler),
    advancePaymentsScheduler: PaymentsSchedulerLegacy.fromServer(json.AdvancePaymentsScheduler),
    paymentRequests: LifePaymentRequests.fromServerList(json.PaymentRequests),
    balanceOnContract: LifeBalanceOnContract.fromServer(json.BalanceOnContract),
    investmentStrategies: LifeInvestmentStrategy.fromServer(json.InvestmentStrategies),
    surrenderAmount: AmountLegacy.fromServer(json.SurrenderAmount),
}));
