import { model } from "core/util";
import { CodeValue } from "types";

export const getBatchPlan = (batchPlan: CodeValue, locale: string, byLaw: string) => {
    if (!batchPlan) {
        return "";
    }

    return batchPlan.code === "7" ? byLaw : model.getLocaleCodeName(batchPlan, locale);
};
