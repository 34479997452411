import React, { FC, PropsWithChildren } from "react";
import { ResponsiveStyleValue } from "@mui/system";

import { Box, BoxProps } from "@mui/material";

export interface FlexboxProps extends PropsWithChildren, BoxProps {
    direction?: ResponsiveStyleValue<"row" | "column">;
    justifyContent?: ResponsiveStyleValue<
        "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly" | "stretch" | "unset"
    >;
    alignItems?: ResponsiveStyleValue<"flex-start" | "flex-end" | "center" | "baseline" | "stretch">;
    gap?: ResponsiveStyleValue<number>;
}

export const Flexbox: FC<FlexboxProps> = ({ direction, justifyContent, alignItems, gap, children, ...props }) => (
    <Box display="flex" flexDirection={direction} justifyContent={justifyContent} alignItems={alignItems} gap={gap} {...props}>
        {children}
    </Box>
);
