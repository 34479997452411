import React from "react";

import { Typography } from "ui-library/atoms";
import { Box, ContentBox } from "ui-library/layouts";
import { LabelTextItem } from "ui-library/modules";

export const AttributeSection = ({ section }) => (
    <ContentBox bigger>
        <Typography variant={"h4"}>{section.title}</Typography>
        <Box mt={1}>
            {section.attributes.map((attribute) => (
                <LabelTextItem key={attribute.name} label={attribute.name} text={attribute.value}></LabelTextItem>
            ))}
        </Box>
    </ContentBox>
);
