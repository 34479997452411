import React from "react";
import PropTypes from "prop-types";

import { StyledDropdownOption } from "ui-library/Styled";

import { Box } from "ui-library/layouts/Box/Box";

import { Typography } from "ui-library/atoms/Typography/Typography";

export const InsurantOption = ({ eventNumber, eventType, eventDate, ...props }) => (
    <StyledDropdownOption complexOption={true} py={{ xs: 0.5, md: 0 }} direction={{ xs: "column", md: "row" }} {...props} {...props}>
        <Box>
            <Typography mr={1.75} variant="p" color={"gray"}>
                {eventNumber}
            </Typography>
        </Box>
        <Box pr={2}>
            <Typography variant="p">{eventType}</Typography>
        </Box>
        <Box sx={{ marginLeft: { xs: "0px", md: "auto" } }}>
            <Typography variant="c" color="gray">
                Dátum vzniku {eventDate}
            </Typography>
        </Box>
    </StyledDropdownOption>
);

InsurantOption.propTypes = {
    eventNumber: PropTypes.string,
    eventType: PropTypes.string,
    eventDate: PropTypes.string,
    complexOption: PropTypes.bool,
};

InsurantOption.defaultProps = {
    complexOption: true,
};
