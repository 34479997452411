import createModel from "../createModel";
import { AmountLegacy } from "../common/AmountLegacy";
import { MutualFundsPaymentDetail } from "./MutualFundsPaymentDetail";

export const PaymentInfo = createModel("PaymentInfo", (json) => ({
    isUniqaContract: json.isUniqaContract,
    accountNumber: json.accountNumber,
    variableSymbol: json.variableSymbol,
    specificSymbol: json.specificSymbol,
    fundsToInvest: MutualFundsPaymentDetail.fromServerList(json.fundsToInvest),
    debtAmount: AmountLegacy.fromServer(json.debtAmount),
    advancedSchedulerAmount: AmountLegacy.fromServer(json.advancedSchedulerAmount),
    combinedAmount: AmountLegacy.fromServer(json.combinedAmount),
    debtSkQrCode: json.debtSkQrCode,
    advancedSchedulerSkQrCode: json.advancedSchedulerSkQrCode,
    combinedAmountSkQrCode: json.combinedAmountSkQrCode,
    canPayOnline: json.canPayOnline,
    productGroupName: json.productGroupName,
}));
