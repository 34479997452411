import React from "react";
import { useSelector } from "react-redux";

import { Typography } from "ui-library/atoms";
import { ContentBox, GridItem } from "ui-library/layouts";
import { InfoPanel, LabelTextItem } from "ui-library/modules";

import { dateTimeToLocalFullDate } from "core/formatters";
import i18n from "core/i18n";

import { getClaimData, getIsAssistanceInsuranceRisk } from "../selectors";
import { formatAdvancedPayment, formatClaimStatusWithPaymentInfo, getClaimStateObj, isWithPayment } from "../util";
import { ClaimOrders } from "./ClaimOrders";

const formatDamageScope = (damageScope) => (damageScope?.value ? damageScope.value : "-");

export const ClaimDetailOther = () => {
    const { t } = i18n.useTranslation();
    const claimDetail = useSelector(getClaimData);
    const claimState = getClaimStateObj(claimDetail.get("claimState"));
    const isClaimClosed = claimState.isClaimClosed;
    const advancePayment = !isClaimClosed ? formatAdvancedPayment(t, claimDetail) : null;
    const isAssistanceInsuranceRisk = useSelector(getIsAssistanceInsuranceRisk);
    const withPayment = isWithPayment(claimDetail.closureType);

    return (
        <GridItem lg={7} xs={12}>
            <ContentBox bigger>
                <InfoPanel icon="document-info" title={t("claim.information")}>
                    <ContentBox bigger>
                        <LabelTextItem label={t("claim.damageDescription")} text={formatDamageScope(claimDetail.damageScope)} />
                        <LabelTextItem label={t("claim.incidentRegistrationDate")} text={dateTimeToLocalFullDate(claimDetail.reportDate)} />
                        {isClaimClosed && (
                            <LabelTextItem label={t("claim.claimClosureDate")} text={dateTimeToLocalFullDate(claimDetail.closureDate)} />
                        )}
                        <LabelTextItem label={t("claim.claimActualState")} text={t(claimState.msg)} />
                        {isClaimClosed && (
                            <LabelTextItem label={t("claim.closureType")} text={formatClaimStatusWithPaymentInfo(t, claimDetail)} />
                        )}
                        {advancePayment && <LabelTextItem label={t("claim.advancePayment")} text={advancePayment} />}
                        {isClaimClosed && withPayment && isAssistanceInsuranceRisk && (
                            <Typography fontSize={14} color={"red"}>
                                {t("claim.assistanceInsuranceRiskNote")}
                            </Typography>
                        )}
                    </ContentBox>
                </InfoPanel>
            </ContentBox>
            <ClaimOrders />
        </GridItem>
    );
};
