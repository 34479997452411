import { model } from "core/util";

import { AmountLegacy, CodeValueLegacy } from "../common";

export const LifeInvestmentFund = model.createModelLegacy("LifeInvestmentFund", (json) => ({
    investmentFund: CodeValueLegacy.fromServer(json.InvestmentFund),
    investmentFundType: CodeValueLegacy.fromServer(json.InvestmentFundType),
    investmentFundUnits: json.InvestmentFundUnits,
    investmentFundUnitValue: AmountLegacy.fromServer(json.InvestmentFundUnitValue),
    investmentFundValue: AmountLegacy.fromServer(json.InvestmentFundValue),
}));
