import { v4 as uuid } from "uuid";
import { model } from "core/util";

import { HolderLegacy } from "../holder";
import { LifeTariff } from "./LifeTariff";
import { Coverage } from "../harmonized";

export const LifeInsureds = model.createModelLegacy("LifeInsureds", (json) => ({
    reactKey: uuid(),
    insured: HolderLegacy.fromServer(json.Insured),
    tariff: LifeTariff.fromServerList(json.Tariff),
}));
