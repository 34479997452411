import { BrowserHistory } from "history";

import activePages from "app/activePages";
import { default as initSaga } from "app/saga";

import getAppReducers from "./getAppReducers";
import corePackages from "./corePackages";
import createAppSaga from "./createAppSaga";
import createProvider from "./createProvider";

const createRootStore = (history: BrowserHistory) => {
    const reducers = getAppReducers(corePackages, Object.values(activePages));
    const mainSaga = createAppSaga(corePackages, initSaga);

    return createProvider(reducers, mainSaga, history);
};

export default createRootStore;
