import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { Section } from "ui-library/layouts";
import { Container, Loading } from "ui-library/atoms";
import { Step, Stepper } from "ui-library/modules";
import useBreakpoints from "ui-library/theme/breakpoints";

import i18n from "core/i18n";
import { form, getFormFieldValue } from "core/form";
import { FullPageOtpFormLayout } from "containers/fullPageForm";
import { RedeemPensionDpsPensionTypes, RedeemPensionDpsRedeemTypes } from "enums";

import { BackToRedeemSavingsButton } from "./components";
import { ACTIVE_STEP_VIRTUAL_FIELD } from "./constants";

import { PensionDpsStepOneForm, StepTwoForm, StepThreeForm } from "./containers/form";

const DpsRedeemSavingsForm = ({
    handleSubmit,
    canSubmit,
    submitting,
    formName,
    idObject,
    policyHolder,
    contractAdditionalInfo,
    allowedPensionTypes,
    redeemAllowedRedeemTypesSelector,
    redeemParametersSelector,
    notApplicableRedeemsSelector,
}) => {
    const { t } = i18n.useTranslation();
    const isMDBreak = useBreakpoints("md");
    const activeStep = useSelector(getFormFieldValue(formName, ACTIVE_STEP_VIRTUAL_FIELD)) || 0;
    const isLoading = submitting || activeStep === undefined;

    return (
        <Container maxWidth="md">
            {/* Form steps */}
            <Section>
                <Stepper displayAsCircle={!isMDBreak} activeStep={activeStep}>
                    <Step label={t("form.pensionRedeemSavings.step1")} />
                    <Step label={t("form.pensionRedeemSavings.step2")} />
                    <Step label={t("form.pensionRedeemSavings.step3")} />
                </Stepper>
            </Section>

            <Loading fullPage loading={isLoading} />

            {/* Step 1 - redeem form */}
            {activeStep === 0 && (
                <PensionDpsStepOneForm
                    handleSubmit={handleSubmit}
                    canSubmit={canSubmit}
                    formName={formName}
                    idObject={idObject}
                    contractAdditionalInfo={contractAdditionalInfo}
                    allowedPensionTypes={allowedPensionTypes}
                    redeemAllowedRedeemTypesSelector={redeemAllowedRedeemTypesSelector}
                    redeemParametersSelector={redeemParametersSelector}
                    notApplicableRedeemsSelector={notApplicableRedeemsSelector}
                />
            )}

            {/* Step 2 - recapitulation */}
            {activeStep === 1 && (
                <StepTwoForm
                    formName={formName}
                    handleSubmit={handleSubmit}
                    canSubmit={canSubmit}
                    idObject={idObject}
                    policyHolder={policyHolder}
                    pensionTypesEnum={RedeemPensionDpsPensionTypes}
                    redeemTypesEnum={RedeemPensionDpsRedeemTypes}
                />
            )}

            {/* Step 3 - request authorization */}
            {activeStep === 2 && <StepThreeForm formName={formName} idObject={idObject} />}
        </Container>
    );
};

DpsRedeemSavingsForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    canSubmit: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    formName: PropTypes.string.isRequired,
    idObject: PropTypes.any.isRequired,
    policyHolder: PropTypes.object.isRequired,
    allowedPensionTypes: PropTypes.object.isRequired,
    redeemAllowedRedeemTypesSelector: PropTypes.func.isRequired,
    redeemParametersSelector: PropTypes.func.isRequired,
    notApplicableRedeemsSelector: PropTypes.func.isRequired,
};

export default (
    formName,
    getIdObject,
    getPolicyHolder,
    getContractAdditionalInfo,
    redeemAllowedPensionTypesSelector,
    redeemAllowedRedeemTypesSelector,
    redeemParametersSelector,
    notApplicableRedeemsSelector,
) =>
    form(
        formName,
        {},
    )((props) => {
        const { t } = i18n.useTranslation();
        const allowedPensionTypes = useSelector(redeemAllowedPensionTypesSelector);
        const idObject = useSelector(getIdObject);
        const policyHolder = useSelector(getPolicyHolder);
        const contractAdditionalInfo = useSelector(getContractAdditionalInfo);

        return (
            <FullPageOtpFormLayout
                formName={formName}
                formTitle={t("form.pensionRedeemSavings.title")}
                BackButton={<BackToRedeemSavingsButton idObject={idObject} />}
            >
                <DpsRedeemSavingsForm
                    {...props}
                    formName={formName}
                    idObject={idObject}
                    policyHolder={policyHolder}
                    contractAdditionalInfo={contractAdditionalInfo}
                    allowedPensionTypes={allowedPensionTypes}
                    redeemAllowedRedeemTypesSelector={redeemAllowedRedeemTypesSelector}
                    redeemParametersSelector={redeemParametersSelector}
                    notApplicableRedeemsSelector={notApplicableRedeemsSelector}
                />
            </FullPageOtpFormLayout>
        );
    });
