import { Grid } from "@mui/material";
import { withTheme } from "@mui/styles";
import styled from "styled-components";

export const StyledHeaderAction = withTheme(styled(Grid)`
    && {
        .agent-header-action {
            right: 24px;
            top: 24px;
            position: absolute;
        }
    }
`);
