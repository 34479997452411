import PropTypes from "prop-types";
import * as React from "react";
import { StyledStaticPageLayout } from "ui-library/Styled";

export const StaticPageLayout = ({ children, ...props }) => {
    return <StyledStaticPageLayout {...props}>{children}</StyledStaticPageLayout>;
};

StaticPageLayout.propTypes = {
    children: PropTypes.node,
};
