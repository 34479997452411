import React from "react";
import { useSelector } from "react-redux";

import { QrCode } from "containers";
import { iban, fn } from "core/util";

import { getAccountNumber, getAmountValue, getVariableSymbol, getSpecificSymbol } from "../selectors";

const CzQrCode = () => {
    const accountNumber = useSelector(getAccountNumber);
    const variableSymbol = useSelector(getVariableSymbol);
    const specificSymbol = useSelector(getSpecificSymbol);
    const amountValue = useSelector(getAmountValue);
    if (!accountNumber) {
        return null;
    }
    const [account, bankCode] = accountNumber.split("/");

    // https://www.cnb.cz/cs/platebni_styk/iban/iban_mezinar_cislo_uctu.html
    const ibanAccountNumber = iban.generateIBAN(`${bankCode}${account.padStart(16, "0")}`, "CZ");
    const dataString = fn
        .array(
            "SPD*1.0",
            `*ACC:${ibanAccountNumber}`,
            amountValue && `*AM:${amountValue}`,
            `*CC:CZK`,
            variableSymbol && `*X-VS:${variableSymbol}`,
            specificSymbol && `*X-SS:${specificSymbol}`,
        )
        .join("");

    return <QrCode dataString={dataString} />;
};

export default CzQrCode;
