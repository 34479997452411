import { model } from "core/util";

import { Company } from "./Company";

export const DebtDetail = model.createModelLegacy("DebtDetail", (json) => ({
    id: json.id,
    status: json.processingStatus,
    note: json.note,
    variableSymbol: json.variableSymbol,
    company: Company.fromServer(json.company),
}));
