import React from "react";
import { useSelector } from "react-redux";

import { ContentBox, Section } from "ui-library/layouts";
import { Typography } from "ui-library/atoms";

import {
    amountCharacters,
    Field,
    getFormFieldValue,
    InputText,
    max10Characters,
    maxLengthCharacters,
    numberCharacters,
    required,
    SingleSelect,
} from "core/form";
import i18n from "core/i18n";
import { FundSellScope, MutualFundsDirectAmountType } from "enums";
import PropTypes from "prop-types";

function getDescription(sellScope, t) {
    if (sellScope === FundSellScope.PROGRAM.id) {
        return t("investments.sellSwitch.regular.program.meansDescription");
    }
    return t("investments.sellSwitch.regular.fund.meansDescription");
}

export const MeansSectionRegular = ({ formName, getLocalizedFundSellMeansRegular, sellScope, isGenNextSelector }) => {
    const { t } = i18n.useTranslation();
    const meansFieldSelector = getFormFieldValue(formName, "means");
    const meansFieldValue = useSelector(meansFieldSelector);
    const typeOptions = useSelector(getLocalizedFundSellMeansRegular);
    const isGenNext = useSelector(isGenNextSelector);

    return (
        <Section>
            <ContentBox>
                <Typography variant="h3">{t("investments.sellSwitch.amount")}</Typography>
            </ContentBox>
            {!isGenNext && (
                <ContentBox>
                    <Typography>{getDescription(sellScope, t)}</Typography>
                </ContentBox>
            )}
            <ContentBox fullWidth>
                <Field
                    name="means"
                    component={SingleSelect}
                    label={
                        isGenNext ? t("investments.sellSwitch.regular.nextGen.meansLabel") : t("investments.sellSwitch.regular.meansLabel")
                    }
                    options={typeOptions}
                    validateStatic={[required]}
                    placeholder={t("common.choose")}
                />
            </ContentBox>
            {meansFieldValue === MutualFundsDirectAmountType.AMOUNT.id && (
                <ContentBox fullWidth>
                    <Field
                        name="amount"
                        label={t("payments.meansAmount")}
                        validateStatic={[required]}
                        normalize={[amountCharacters, maxLengthCharacters(9)]}
                        component={InputText}
                        md={3}
                    />
                </ContentBox>
            )}
        </Section>
    );
};

MeansSectionRegular.propTypes = {
    formName: PropTypes.string.isRequired,
    getLocalizedFundSellMeansRegular: PropTypes.func.isRequired,
    isGenNextSelector: PropTypes.func.isRequired,
    sellScope: PropTypes.string,
};
