import avatarDefaultImg from "ui-library/assets/images/avatar-a 1.svg";
import { Image, Typography } from "ui-library/atoms";
import { Box } from "ui-library/layouts";
import PropTypes from "prop-types";
import React from "react";

export const AgentInfo = ({ title, imgSrc, actionButton }) => {
    return (
        <Box>
            <Box mb={1} width={64} height={64}>
                <Image shadow={false} alt={title} src={imgSrc ? imgSrc : avatarDefaultImg} borderRadius={999999} />
            </Box>
            <Box mb={3}>
                <Typography variant="h3">{title}</Typography>
            </Box>

            {actionButton}
        </Box>
    );
};

AgentInfo.propTypes = {
    title: PropTypes.string.isRequired,
    imgSrc: PropTypes.string,
    actionButton: PropTypes.node,
};
