import createModel from "../createModel";
import { CodeValueLegacy } from "../common/CodeValueLegacy";

export const ClientEnqueuedDocument = createModel("ClientEnqueuedDocument", (json) => ({
    id: json.DocumentId,
    type: CodeValueLegacy.fromServer(json.DocumentType),
    contractNumber: json.ContractNumber,
    status: CodeValueLegacy.fromServer(json.StatusCode),
    requested: json.Requested,
    lastChange: json.LastChange,
}));
