import React, { FC } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { StandaloneLink } from "ui-library/atoms";
import { Box, GridItem } from "ui-library/layouts";
import { InfoPanel, LabelTextItem } from "ui-library/modules";

import i18n from "core/i18n";
import localized from "core/localized";
import { dateTimeToLocalFullDate } from "core/formatters";
import { fn } from "core/util";
import { ContractPolicy } from "types";
import userContracts from "core/userContracts";

export interface ContractOverviewGridItemProps {
    policy: ContractPolicy;
    actualScheduler: any;
    detailLink: string;
}

export const ContractOverviewGridItem: FC<ContractOverviewGridItemProps> = ({ policy, actualScheduler, detailLink }) => {
    const { t } = i18n.useTranslation();
    const { isInFutureOrTerminated } = userContracts.useGetContractDerivedData(policy);

    const formattedSchedulerPayment = useSelector(
        localized.getLocalizedSchedulerPaymentFullLegacy(fn.getItemOrFirstItemWhenIterable(actualScheduler), false, true),
    );

    return (
        <GridItem display={"flex"} md={7} xs={12}>
            <InfoPanel
                icon={"contract"}
                title={t("common.contract")}
                headerAction={
                    <StandaloneLink to={detailLink} color="blue">
                        {t("common.detail")}
                    </StandaloneLink>
                }
            >
                <Box>
                    <LabelTextItem
                        label={t("insurance.insuranceTo")}
                        text={policy?.endDate ? dateTimeToLocalFullDate(policy.endDate) : t("common.unlimitedDate")}
                    />
                    {!isInFutureOrTerminated && <LabelTextItem label={t("insurance.insuranceCost")} text={formattedSchedulerPayment} />}
                </Box>
            </InfoPanel>
        </GridItem>
    );
};
