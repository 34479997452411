import PropTypes from "prop-types";

import { Typography } from "ui-library/atoms";
import { InfoBox } from "ui-library/modules";
import { ContentBox, GridItem } from "ui-library/layouts";

import i18n from "core/i18n";

export const LeadPanelItem = ({ icon, title, text }) => {
    const { t } = i18n.useTranslation();
    return (
        <GridItem display="flex" justifyContent={"center"} xs={12} md={3} style={{ minHeight: "272px" }}>
            <InfoBox icon={icon} title={title}>
                <ContentBox>
                    <Typography variant="caption" color={"gray"}>
                        {text}
                    </Typography>
                </ContentBox>
            </InfoBox>
        </GridItem>
    );
};

LeadPanelItem.propTypes = {
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
};
