import React, { FC } from "react";
import { useSelector } from "react-redux";

import { formatContractOverview } from "core/formatters";
import i18n from "core/i18n";
import localized from "core/localized";
import pageContext from "core/pageContext";

import { ContractPolicy } from "types";

export interface ContractOverviewProps {
    contract: ContractPolicy;
}

export const ContractOverview: FC<ContractOverviewProps> = ({ contract }) => {
    const { t } = i18n.useTranslation();
    const frequencyMap = useSelector(localized.getLocalizedFrequencyMap);
    const { isSlovakSite } = pageContext.useGetPageLocalization();

    const overview = formatContractOverview(t, contract, frequencyMap, {}, isSlovakSite);
    return <>{overview}</>;
};

export default ContractOverview;
