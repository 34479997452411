import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Typography } from "ui-library/atoms";
import { ContentBox, GridItem } from "ui-library/layouts";
import { FileInfo, InfoPanel } from "ui-library/modules";

import i18n from "core/i18n";
import { documentsApi } from "serverApi";

import { getTaxCertificateAvailableYears } from "../selectors";

export const TaxStatementGridItem = () => {
    const { t } = i18n.useTranslation();
    const { idObject } = useParams();

    const availableYears = useSelector(getTaxCertificateAvailableYears);

    const downloadTaxStatementUrl = (year) => documentsApi.getLifeTaxCertificateDownloadUrl(idObject, year);

    return (
        <GridItem xs={12}>
            <InfoPanel icon={"paper-bill"} title={t("statements.taxCertificate")}>
                <>
                    <ContentBox mb={1}>
                        <Typography fontSize={14} markDown={t("statements.taxStatement.note")} />
                    </ContentBox>
                    {availableYears.map((year) => (
                        <FileInfo
                            key={year}
                            icon="download"
                            fileName={`${t("common.year")} ${year} ${t("common.downloadPdfNotCapital")}`}
                            onClick={() => window.open(downloadTaxStatementUrl(year))}
                        />
                    ))}
                </>
            </InfoPanel>
        </GridItem>
    );
};
