import activeOperationsPhoneNumberForm from "form/modal/activeOperationsPhoneNumber";
import { FORM_EDIT_ACTIVE_OPERATIONS_PHONE_NUMBER } from "../constants";
import { isActiveOperationsEnabled } from "../selectors";
import { OtpModule } from "../modules";

export const ModalActiveOperationsPhoneNumberForm = activeOperationsPhoneNumberForm.createContainer(
    FORM_EDIT_ACTIVE_OPERATIONS_PHONE_NUMBER,
    isActiveOperationsEnabled,
    OtpModule,
    false,
);
