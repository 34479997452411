import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { Box, Flexbox } from "ui-library/layouts";
import { Button, Loading } from "ui-library/atoms";
import { LabelTextItem, ModalBody, ModalFooter } from "ui-library/modules";

import i18n from "core/i18n";
import fetching from "core/fetching";
import localized from "core/localized";
import modal from "core/modal";
import router from "core/router";
import userInfo from "core/userInfo";
import { dateTimeToLocalFullDate, formatLocalizedValueMap, oneLineNameWithTitles } from "core/formatters";
import { useHasEditContractPermission } from "core/hooks";
import { SimpleModalWindow } from "containers/modal";
import { Params } from "routeConstants";

import { FETCHING_CHANGE_PROPOSITION_CANCEL, MODAL_CHANGE_PROPOSITION_DETAIL } from "../../constants";
import { getOperationTypeValue, getTabRouteByAction } from "../../util";

import ChangePropositionInnerDetail from "./ChangePropositionInnerDetail";

export const ChangePropositionDetail = ({
    idObjectSelector,
    pageStateSelector,
    changePropositionDetailSelector,
    isCancellationSubmittedSelector,
    updatePropositionStateAction,
    contractConstant,
}) => {
    const dispatch = useDispatch();
    const { t } = i18n.useTranslation();

    const isSubmitting = useSelector(fetching.isFetching(FETCHING_CHANGE_PROPOSITION_CANCEL));
    const isSubmitted = useSelector(isCancellationSubmittedSelector);

    const pageState = useSelector(pageStateSelector);
    const changeProposition = useSelector(changePropositionDetailSelector);
    const changePropositionStatusMap = useSelector(localized.getLocalizedChangePropositionStatusMap);
    const idObject = useSelector(idObjectSelector);
    const agent = useSelector(userInfo.createGetAgentByObjectId(idObject));

    const sellSwitchMap = useSelector(localized.getLocalizedFundSellSwitchMap);
    const changePropositionTypeMap = useSelector(localized.getLocalizedChangePropositionTypeMap);

    const tabRoute = getTabRouteByAction(changeProposition?.changeProposition?.type);
    const transitionAction = router.navigate(
        contractConstant,
        tabRoute,
        { idObject },
        { [Params.CHANGE_PROPOSITION_ID]: changeProposition.id },
        true,
    );
    const hasEditPermission = useHasEditContractPermission();

    return (
        <SimpleModalWindow
            title={t("changeProposition.detail")}
            modalName={MODAL_CHANGE_PROPOSITION_DETAIL}
            icon={"chart-bar"}
            isSubmitting={isSubmitting}
            isSubmitted={isSubmitted}
            successTitle={t("changeProposition.agentSuggestionDenied")}
            successText={" "}
        >
            <ModalBody>
                <Loading loading={isSubmitting}>
                    <Box>
                        <LabelTextItem
                            fontVariant="caption"
                            label={t("changeProposition.agent")}
                            text={oneLineNameWithTitles(agent.legalEntity)}
                        />
                        <LabelTextItem fontVariant="caption" label={t("changeProposition.agentPhone")} text={agent.legalEntity.phone} />
                        <LabelTextItem
                            fontVariant="caption"
                            label={t("changeProposition.date")}
                            text={dateTimeToLocalFullDate(changeProposition.creationDateTime)}
                        />
                        <LabelTextItem
                            fontVariant="caption"
                            label={t("changeProposition.transactionType")}
                            text={getOperationTypeValue(pageState, changeProposition, sellSwitchMap, changePropositionTypeMap)}
                        />
                        <LabelTextItem
                            fontVariant="caption"
                            label={t("changeProposition.state")}
                            text={formatLocalizedValueMap(changeProposition.status, changePropositionStatusMap)}
                        />
                    </Box>
                    <ChangePropositionInnerDetail pageState={pageState} changeProposition={changeProposition.changeProposition} />
                </Loading>
            </ModalBody>
            <ModalFooter>
                {isSubmitted || !hasEditPermission ? (
                    <Button
                        onClick={() => dispatch(modal.closeModalForm(MODAL_CHANGE_PROPOSITION_DETAIL))}
                        color="blue"
                        variant="contained"
                    >
                        {t("common.close")}
                    </Button>
                ) : (
                    <Flexbox gap={2} justifyContent="space-between">
                        <Button
                            onClick={() => dispatch(updatePropositionStateAction())}
                            variant="outlined"
                            color="blue"
                            disabled={isSubmitting}
                        >
                            {t("common.decline")}
                        </Button>

                        <Button onClick={() => dispatch(transitionAction)} color="blue" disabled={isSubmitting}>
                            {t("common.accept")}
                        </Button>
                    </Flexbox>
                )}
            </ModalFooter>
        </SimpleModalWindow>
    );
};

ChangePropositionDetail.propTypes = {
    idObjectSelector: PropTypes.func.isRequired,
    pageStateSelector: PropTypes.func.isRequired,
    changePropositionDetailSelector: PropTypes.func.isRequired,
    isCancellationSubmittedSelector: PropTypes.func.isRequired,
    updatePropositionStateAction: PropTypes.func.isRequired,
    contractConstant: PropTypes.string.isRequired,
};
