export const NAME = "page-contract-list";

export const FETCHING_CLIENT_DOCUMENTS = `${NAME}/FETCHING_CONTRACT_DOCUMENTS`;

export enum ContractListContentType {
    ACTIVE_CONTRACTS = "activeContractList",
    INACTIVE_CONTRACTS = "finishedContractList",
    DOCUMENTS = "clientDocuments",
}

export type ContractListPageState = {
    data: {
        clientDocuments: any[];
    };
    state: {
        contractIdForPayment: any;
        displayedContentType: ContractListContentType;
    };
};

export const initialState: ContractListPageState = {
    data: {
        clientDocuments: [],
    },
    state: {
        contractIdForPayment: null,
        displayedContentType: ContractListContentType.ACTIVE_CONTRACTS,
    },
};

export type SetClientDocumentsPayload = {
    clientDocuments: any[];
    error?: boolean;
};
