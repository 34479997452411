import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { LabelTextItem } from "ui-library/modules";

import i18n from "core/i18n";
import { getLocalizedOneLineAddressFull } from "core/localized";
import { getFormFieldValue } from "core/form";

import {
    ADDRESS_SECTION,
    BANK_ACCOUNT_NUMBER_FIELD,
    BANK_ACCOUNT_PREFIX_FIELD,
    BANK_ACCOUNT_SECTION,
    BANK_CODE_FIELD,
    CITY_FORM_FIELD,
    DESCRIPTION_NUMBER_FORM_FIELD,
    ORIENTATION_NUMBER_FORM_FIELD,
    PAYMENT_TYPE_ACCOUNT,
    PAYMENT_TYPE_ADDRESS,
    PAYMENT_TYPE_FIELD,
    STATE_FORM_FIELD,
    STREET_FORM_FIELD,
    VARIABLE_SYMBOL_FIELD,
    ZIP_FORM_FIELD,
} from "../../constants";

export const formatPayment = (t, formName) => {
    const paymentType = useSelector(getFormFieldValue(formName, PAYMENT_TYPE_FIELD));

    // Address
    const street = useSelector(getFormFieldValue(formName, `${ADDRESS_SECTION}.${STREET_FORM_FIELD}`));
    const descriptionNumber = useSelector(getFormFieldValue(formName, `${ADDRESS_SECTION}.${DESCRIPTION_NUMBER_FORM_FIELD}`));
    const orientationNumber = useSelector(getFormFieldValue(formName, `${ADDRESS_SECTION}.${ORIENTATION_NUMBER_FORM_FIELD}`));
    const city = useSelector(getFormFieldValue(formName, `${ADDRESS_SECTION}.${CITY_FORM_FIELD}`));
    const postalCode = useSelector(getFormFieldValue(formName, `${ADDRESS_SECTION}.${ZIP_FORM_FIELD}`));
    const countryCode = useSelector(getFormFieldValue(formName, `${ADDRESS_SECTION}.${STATE_FORM_FIELD}`));

    // Bank account
    const accountPrefix = useSelector(getFormFieldValue(formName, `${BANK_ACCOUNT_SECTION}.${BANK_ACCOUNT_PREFIX_FIELD}`)) || null;
    const accountNumber = useSelector(getFormFieldValue(formName, `${BANK_ACCOUNT_SECTION}.${BANK_ACCOUNT_NUMBER_FIELD}`)) || "";
    const bankCode = useSelector(getFormFieldValue(formName, `${BANK_ACCOUNT_SECTION}.${BANK_CODE_FIELD}`)) || "";
    const variableSymbol = useSelector(getFormFieldValue(formName, `${BANK_ACCOUNT_SECTION}.${VARIABLE_SYMBOL_FIELD}`)) || "";

    if (paymentType === PAYMENT_TYPE_ADDRESS) {
        const address = {
            street,
            descriptionNumber,
            orientationNumber,
            city,
            postalCode,
            countryCode,
        };
        return `${t("form.pensionRedeemSavings.recapitulation.paymentAddress")} ${getLocalizedOneLineAddressFull(t, address)}`;
    } else if (paymentType === PAYMENT_TYPE_ACCOUNT) {
        const accountPrefixPart = accountPrefix ? `${accountPrefix} - ` : "";
        const bankAccount = `${t(
            "form.pensionRedeemSavings.recapitulation.paymentAccount",
        )} ${accountPrefixPart}${accountNumber} / ${bankCode}`;
        return variableSymbol !== "" ? `${bankAccount}, ${t("payments.variableSymbol")} ${variableSymbol}` : bankAccount;
    }
    return "";
};
export const PaymentLabelTextItem = ({ formName, minWidthPx }) => {
    const { t } = i18n.useTranslation();

    return (
        <LabelTextItem
            minWidthLabel
            minWidthPx={minWidthPx}
            label={t("form.pensionRedeemSavings.recapitulation.paymentType")}
            text={formatPayment(t, formName)}
        />
    );
};

PaymentLabelTextItem.propTypes = {
    formName: PropTypes.string.isRequired,
    minWidthPx: PropTypes.string,
};

PaymentLabelTextItem.defaultProps = {
    minWidthPx: null,
};
