import PropTypes from "prop-types";
import * as React from "react";

import { StyledSubPageLayout } from "ui-library/Styled";

export const SubPageLayout = ({ children, ...props }) => {
    return <StyledSubPageLayout {...props}>{children}</StyledSubPageLayout>;
};

SubPageLayout.propTypes = {
    children: PropTypes.node,
};
