import React from "react";
import PropTypes from "prop-types";

import i18n from "core/i18n";
import autocomplete from "core/autocomplete";
import { Section } from "ui-library/layouts";

import { SectionDescription, SectionTitle } from "../components";
import { PERMANENT_ADDRESS_AUTOCOMPLETE, PERMANENT_ADDRESS_SECTION } from "../constants";
import AddressFieldsContainer from "./AddressFields";

const PermanentAddressAutocomplete = autocomplete.createSingle(PERMANENT_ADDRESS_AUTOCOMPLETE);

const PermanentAddressFormBox = ({ formName, isCompany }) => {
    const { t } = i18n.useTranslation();

    return (
        <Section>
            <SectionTitle title={isCompany ? t("holder.companyAddress") : t("holder.permanentAddress")} />
            <SectionDescription
                content={isCompany ? t("form.editPolicyHolder.companyChangeAddress") : t("form.editPolicyHolder.changeAddress")}
            />
            <AddressFieldsContainer
                formName={formName}
                sectionName={PERMANENT_ADDRESS_SECTION}
                addressAutocomplete={PermanentAddressAutocomplete}
            />
        </Section>
    );
};

PermanentAddressFormBox.propTypes = {
    formName: PropTypes.string.isRequired,
    isCompany: PropTypes.bool.isRequired,
};

export default PermanentAddressFormBox;
