import { withTheme } from "@mui/styles";
import styled from "styled-components";
import { Box } from "ui-library/layouts/Box/Box";
import { StyledBlock } from "ui-library/Styled/Layouts/Blocks";

// import {Block} from 'atoms';

export const StyledContractCard = withTheme(styled(StyledBlock)`
    padding: ${(props) => props.$padding && props.$padding + "px"};

    && {
        position: relative;
        img {
            position: absolute;
            right: 20px;
            bottom: 0;
            z-index: 0;
            max-width: 360px;
            width: 100%;
            object-fit: unset;
            height: auto;
        }
    }

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    }
`);

export const StyledContractWrapper = withTheme(styled(Box)`
    && {
        height: 100%;
        position: relative;
        z-index: 1;
    }
`);
