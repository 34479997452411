import { Countries } from "enums";
import { fn } from "../util";
import { TFunction } from "core/i18n";
import { getLocalizedEnumMap } from "./getLocalizedEnumMap";

export const getLocalizedCountryCode = (t: TFunction, countryCode: string): string => {
    const countryMap = getLocalizedEnumMap(t, Countries);
    if (fn.isEmpty(countryCode)) {
        return "";
    }
    const localizedCountry = countryMap[countryCode];
    return fn.isEmpty(localizedCountry) ? "" : localizedCountry;
};
