import PropTypes from "prop-types";
import IPropTypes from "react-immutable-proptypes";
import moment from "moment";

import { DatePicker as DatePickerComponent } from "ui-library/atoms";
import { getErrorsAsString } from "../util";

const MINIMUM_DATE = "1986-07-05";

const DatePicker = ({ label, meta, input, disabled, id, minDate, maxDate, size, views, inputFormat, mask }) => {
    const errorContent = getErrorsAsString(meta);
    return (
        <DatePickerComponent
            label={label}
            id={id || "datePicker"}
            value={input.value || null}
            onChange={(newValue) => {
                // TODO: Temporary hack to make work react mui and our forms.
                const momentValue = moment(newValue);
                if (momentValue.isValid() && momentValue.isAfter(moment(MINIMUM_DATE))) {
                    input.onChange(momentValue.format("YYYY-MM-DD"));
                }
            }}
            onBlur={input.onBlur}
            error={meta.touched && meta.error && !meta.error.isEmpty()}
            errorContent={errorContent}
            disabled={disabled}
            minDate={minDate ? minDate : undefined}
            maxDate={maxDate ? maxDate : undefined}
            size={size}
            views={views}
            inputFormat={inputFormat}
            mask={mask}
        />
    );
};

DatePicker.propTypes = {
    label: PropTypes.string,
    meta: PropTypes.shape({
        error: IPropTypes.list,
        dirty: PropTypes.bool,
        touched: PropTypes.bool.isRequired,
    }).isRequired,
    input: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
        onChange: PropTypes.func.isRequired,
        onBlur: PropTypes.func.isRequired,
        onFocus: PropTypes.func.isRequired,
    }).isRequired,
    disabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    minDate: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(Date)]),
    maxDate: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(Date)]),
    size: PropTypes.string,
    views: PropTypes.array,
    inputFormat: PropTypes.string,
    mask: PropTypes.string,
};

DatePicker.defaultProps = {
    label: null,
    disabled: false,
    minDate: null,
    maxDate: null,
    size: "medium",
    views: null,
    inputFormat: "dd.MM.yyyy",
    mask: "__.__.____",
};

export default DatePicker;
