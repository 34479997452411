import React from "react";
import PropTypes from "prop-types";

// import css from "./QrCodeBase64.module.scss";

// TODO what about style? taken from ui-components
export const QrCodeBase64 = ({ base64Img }) => (
    <img
        src={base64Img}
        alt="Payment QR code"
        style={{ width: "148px" }}
        // className={css.qrCodeBase64}
    />
);

QrCodeBase64.propTypes = {
    /** Accepts full base64 string, e.g. "data:image/gif;base64,R0lGODdh..." */
    base64Img: PropTypes.string.isRequired,
};
