import PropTypes from "prop-types";
import * as React from "react";

import { StyledProductPageLayout } from "ui-library/Styled";

export const ProductPageLayout = ({ children, ...props }) => {
    return <StyledProductPageLayout {...props}>{children}</StyledProductPageLayout>;
};

ProductPageLayout.propTypes = {
    children: PropTypes.node,
};
