import React from "react";
import PropTypes from "prop-types";
import qrcode from "qrcode-generator";

export const QrCode = ({ dataString }) => {
    const qr = qrcode(6, "L");
    qr.addData(dataString);
    qr.make();
    const imgSrc = qr.createDataURL(3);

    return <img style={{ width: "148px" }} src={imgSrc} alt={dataString} />;
};

QrCode.propTypes = {
    /** String that defines the information in the QR code */
    dataString: PropTypes.string.isRequired,
};
