import { NAME } from "./constants";
import saga from "./saga";
import innerRoutes from "./innerRoutes";
import { AnnouncementsTab, RequestsTab } from "./tabs";

export { default as MessagesPage } from "./Container";

export const MessagesTabs = {
    AnnouncementsTab,
    RequestsTab,
};

export default {
    NAME,
    saga,
    innerRoutes,
};
