import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import { Locales } from "enums";

const resources = {
    [Locales.en_US.languageCode]: { translation: {} },
    [Locales.cs_CZ.languageCode]: { translation: {} },
    [Locales.sk_SK.languageCode]: { translation: {} },
};

i18next.use(initReactI18next).init({
    resources,
    fallbackLng: "en", // use sk if detected lng is not available
    supportedLngs: [Locales.cs_CZ.languageCode, Locales.sk_SK.languageCode, Locales.en_US.languageCode],
    parseMissingKeyHandler: (key) => `(${key})`,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
        escapeValue: false, // react already safes from xss
    },
});

export default i18next;
