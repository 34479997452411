import { Record } from "immutable";

class DdsSavingStrategyFund extends Record({ id: null, msg: null, fundId: null }) {}

export default Object.freeze({
    CONTRIBUTORY: new DdsSavingStrategyFund({
        id: "CONTRIBUTORY",
        msg: "saving.strategy.dds.contributory",
        fundId: 1,
    }),
    GLOBAL: new DdsSavingStrategyFund({
        id: "GLOBAL",
        msg: "saving.strategy.dds.global",
        fundId: 2,
    }),
    INDEX: new DdsSavingStrategyFund({
        id: "INDEX",
        msg: "saving.strategy.dds.index",
        fundId: 4,
    }),
});
