import React, { FC } from "react";
import { useSelector } from "react-redux";

import { InfoPanel, LabelTextItem } from "ui-library/modules";
import { ContentBox, GridItem } from "ui-library/layouts";

import i18n from "core/i18n";
import { formatYesNo } from "core/formatters";

import { getPensionTypes } from "../selectors";

type PensionTypesGridItemProps = {
    mdSize?: number;
};

export const PensionTypesGridItem: FC<PensionTypesGridItemProps> = ({ mdSize = 6 }) => {
    const { t } = i18n.useTranslation();
    const pensionTypes = useSelector(getPensionTypes);

    return (
        <GridItem display="flex" xs={12} md={mdSize}>
            <InfoPanel icon="document" title={t("pension.holderPensionTypes")}>
                <ContentBox>
                    <LabelTextItem label={t("pension.pensionPlan")} text={pensionTypes.pensionPlan} />
                    <LabelTextItem label={t("pension.retirement")} text={formatYesNo(t, pensionTypes.retirementPension)} />
                    <LabelTextItem label={t("pension.disability")} text={formatYesNo(t, pensionTypes.disabilityPension)} />
                    <LabelTextItem label={t("pension.service")} text={formatYesNo(t, pensionTypes.servicePension)} />
                    <LabelTextItem label={t("pension.survivors")} text={formatYesNo(t, pensionTypes.survivorsPension)} />
                </ContentBox>
            </InfoPanel>
        </GridItem>
    );
};
