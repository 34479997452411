import { combineReducers } from "redux-immutable";

import { app } from "core/util";
import { Life } from "model/life";

import { CLEAR, SET_DETAIL_SELECTED_INSURED, SET_LIFE_CONTRACT_DETAIL, SET_STATEMENT_TAB } from "./actions";
import { FinancialOperationsTableFilter } from "./modules";

export default combineReducers({
    data: combineReducers({
        lifeContractDetail: app.createDataReducer(SET_LIFE_CONTRACT_DETAIL, Life.fromServer(), CLEAR),
    }),
    appState: combineReducers({
        detailSelectedInsured: app.createDataReducer(SET_DETAIL_SELECTED_INSURED, "", CLEAR),
        statementTab: app.createDataReducer(SET_STATEMENT_TAB, 0, CLEAR),
        financialOperationsFilter: FinancialOperationsTableFilter.reducer,
    }),
});
