import React, { FC, ReactNode } from "react";
import { useSelector } from "react-redux";

import router from "core/router";
import { Loading } from "ui-library/atoms";

import { DefaultPageError } from "./error/DefaultPageError";

type PageBoundaryProps = {
    children: ReactNode;
    ErrorComponent?: React.ComponentType;
};

export const PageBoundary: FC<PageBoundaryProps> = ({ children, ErrorComponent }) => {
    const activePageStatus = useSelector(router.getActivePageStatus);

    if (activePageStatus.isError) {
        if (ErrorComponent) {
            return <ErrorComponent />;
        } else {
            return <DefaultPageError errorMsgKey={activePageStatus.msgKey} errorIdentifier={activePageStatus.errorIdentifier} />;
        }
    }

    return <Loading loading={activePageStatus.isLoading}>{children}</Loading>;
};
