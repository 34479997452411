import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { change, initialize } from "redux-form/lib/immutable";

import coreEnum from "core/enum";
import { FORM_STEPS_BACK } from "app/constants";
import { ChangePropositionStatus, ProductGroupLvl2, MutualFundsDirectAmountType, MutualFundsPeriodicity } from "enums";
import { fieldChangeMatcher, formWrapper, scrollToFirstError } from "core/form";
import { changePropositionsApi, mutualFundsApi, mutualFundsDirectApi, mutualFundsRegularApi } from "serverApi";
import userInfo from "core/userInfo";
import { OTP_CODE_FIELD_NAME, OTP_ID_FIELD_NAME } from "modules/otp";
import { fetch, fn, sentry } from "core/util";
import errorHandling, { OTP_WRONG_MOBILE_ERROR_CODE, OTP_WRONG_MOBILE_NUMBER_FIN_OPERATIONS } from "core/errorHandling";
import { addOtpFormSuccess, resetOtpFormSuccess } from "core/form/actions";

import { CANCEL_FIELD_ID, MEANS_FIELD, PRODUCT_LINE_FIELD, TYPE_FIELD } from "./constants";

export default (
    formName,
    setResent,
    setPhoneNumber,
    productLineId,
    getChangeProposition,
    getMutualFundsFinancialRequest,
    loadChangePropositions,
) =>
    formWrapper(formName, {
        *initialize(idObject, transactionId, actionType) {
            yield call(userInfo.checkUserVerified, FORM_STEPS_BACK);
            yield put(errorHandling.removeServiceErrors(formName));
            yield put(resetOtpFormSuccess([formName]));
            yield put(coreEnum.loadIfNeeded([coreEnum.BANK_LIST]));
            if (transactionId) {
                return yield call(
                    initializeData,
                    actionType,
                    transactionId,
                    formName,
                    productLineId,
                    getChangeProposition,
                    getMutualFundsFinancialRequest,
                );
            }
            return {};
        },
        *persistentEffects() {
            if (productLineId === ProductGroupLvl2.MUTUAL_FUNDS) {
                yield takeEvery(fieldChangeMatcher(formName, MEANS_FIELD), changeTypeSectionValue, formName);
            }
        },
        *save(values, idObject) {
            if (values.get(CANCEL_FIELD_ID)) {
                try {
                    const response = yield call(mutualFundsApi.cancelInstruction, idObject, values.get(CANCEL_FIELD_ID), {
                        [OTP_ID_FIELD_NAME]: values.get(OTP_ID_FIELD_NAME),
                        [OTP_CODE_FIELD_NAME]: values.get(OTP_CODE_FIELD_NAME),
                    });
                    return response ? { [OTP_ID_FIELD_NAME]: response.otp_id, phone: response.phone_number } : {};
                } catch (e) {
                    if (e.status === fetch.BAD_REQUEST && e.response.otpErrorCode === OTP_WRONG_MOBILE_ERROR_CODE) {
                        yield put(errorHandling.addServiceError(OTP_WRONG_MOBILE_NUMBER_FIN_OPERATIONS, e.identifier));
                        // throw empty precognition failed error to stop form submission
                        throw new fetch.EmptyPreconditionFailedError();
                    }
                    throw e;
                }
            } else {
                try {
                    const transaction = values.merge({
                        [PRODUCT_LINE_FIELD]: productLineId,
                        idObject,
                    });

                    const response = yield call(
                        productLineId === ProductGroupLvl2.MUTUAL_FUNDS
                            ? mutualFundsDirectApi.sellOrSwitchFund
                            : mutualFundsRegularApi.sellOrSwitchFund,
                        transaction,
                    );
                    return response ? { [OTP_ID_FIELD_NAME]: response.otp_id, phone: response.phone_number } : {};
                } catch (e) {
                    if (e.status === fetch.BAD_REQUEST && e.response.otpErrorCode === OTP_WRONG_MOBILE_ERROR_CODE) {
                        yield put(errorHandling.addServiceError(OTP_WRONG_MOBILE_NUMBER_FIN_OPERATIONS, e.identifier));
                        // throw empty precognition failed error to stop form submission
                        throw new fetch.EmptyPreconditionFailedError();
                    }
                    throw e;
                }
            }
        },
        *success({ otpId, phone }, idObject, transactionId, actionType) {
            if (otpId) {
                yield put(change(formName, OTP_ID_FIELD_NAME, otpId));
                yield put(setPhoneNumber(phone));
            } else {
                if (transactionId && fn.isEmpty(actionType)) {
                    try {
                        yield call(
                            changePropositionsApi.updateChangePropositions,
                            idObject,
                            transactionId,
                            ChangePropositionStatus.ACCEPTED.id,
                        );
                    } catch (e) {
                        sentry.captureException(e);
                    }
                    yield call(loadChangePropositions, idObject);
                }
                yield put(addOtpFormSuccess(formName));
            }
        },
        *onSubmitFail() {
            yield call(scrollToFirstError);
        },
    });

function* initializeData(actionType, transactionId, formName, productLineId, getChangeProposition, getMutualFundsFinancialRequest) {
    const transactionData = actionType
        ? yield select(getMutualFundsFinancialRequest(transactionId))
        : yield select(getChangeProposition(transactionId));
    if (transactionData) {
        const transaction = actionType ? transactionData : transactionData.changeProposition.mutualFundsTransaction;
        if (!fn.isEmpty(transaction)) {
            yield put(
                initialize(formName, {
                    // must be set during initialize. If changed later, validations would be incorrectly set
                    // (Field.js - validations are disabled for disabled fields.)
                    [CANCEL_FIELD_ID]: actionType === "cancel" ? transaction.id : undefined,
                }),
            );

            yield all([
                put(change(formName, "sourceFund", transaction.transactionFundFrom.code)),
                put(change(formName, MEANS_FIELD, transaction.transactionValueType.code)),
                put(change(formName, "amount", transaction.transactionValue.value)),
                put(change(formName, "account.iban", transaction.transactionTo.iban)),
                put(change(formName, "account.prefix", transaction.transactionTo.accountNumberPrefix)),
                put(change(formName, "account.number", transaction.transactionTo.accountNumber)),
                put(change(formName, "account.bankCode", transaction.transactionTo.bankNumber.code)),
                put(change(formName, "account.variableSymbol", transaction.transactionTo.variableSymbol)),
                put(change(formName, "account.specificSymbol", transaction.transactionTo.specificSymbol)),
                put(change(formName, "note", transaction.transactionTo.transactionName)),
            ]);

            // Regular investment - sell scope.
            if (productLineId === ProductGroupLvl2.REGULAR_INVESTMENTS) {
                yield put(change(formName, "sellScope", transaction.sellScope));
            }

            // Fields relevant only for direct mutual funds.
            if (productLineId === ProductGroupLvl2.MUTUAL_FUNDS) {
                yield all([
                    put(change(formName, "quantity", transaction.numberOfSharesInTransaction)),
                    put(change(formName, TYPE_FIELD, transaction.periodicityType)),
                    put(change(formName, "startDate", new Date(transaction.firstTransactionDate))),
                    put(change(formName, "endDate", new Date(transaction.lastTransactionDate))),
                    put(change(formName, "frequency", transaction.frequency.code)),
                    put(change(formName, "target", transaction.transactionType.code)),
                    put(change(formName, "targetFund", transaction.transactionTo.fund.code)),
                ]);
            }
            return null;
        }
    }
    return {};
}

function* changeTypeSectionValue(formName, { payload }) {
    if (payload === MutualFundsDirectAmountType.SELL_ALL.id) {
        yield put(change(formName, TYPE_FIELD, MutualFundsPeriodicity.ONETIME.id));
    }
}
