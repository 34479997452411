import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { Typography } from "ui-library/atoms";
import { Box } from "ui-library/layouts";
import { LabelTextItem } from "ui-library/modules";

import i18n from "core/i18n";
import localized from "core/localized";
import pageContext from "core/pageContext";
import { fn } from "core/util";
import { MutualFunds, FundSellSwitch, MutualFundsPeriodicity, FundSellScope } from "enums";
import { MutualFundsFinancialRequest } from "model/mutualFunds";
import {
    dateTimeToLocalFullDate,
    formatAmountOrNumberOfShares,
    formatEmptyValueWithDash,
    formatFundFrequency,
    formatFundPayment,
} from "core/formatters";

export const MutualFundsTransactionDetail = ({ mutualFundsTransaction }) => {
    const { t } = i18n.useTranslation();
    const siteId = useSelector(pageContext.getSiteId);
    const frequencyMap = useSelector(localized.getLocalizedFrequencyMap);

    const amount = formatAmountOrNumberOfShares(
        mutualFundsTransaction.transactionValueType,
        mutualFundsTransaction.transactionValue,
        mutualFundsTransaction.numberOfSharesInTransaction,
        t("investments.sellAll"),
    );

    const transactionFromValue =
        mutualFundsTransaction?.sellScope !== FundSellScope.PROGRAM.id
            ? t(fn.getEnumItemByCode(MutualFunds, mutualFundsTransaction?.transactionFundFrom?.code)?.msg)
            : t("investments.sellSwitch.scope.program");

    return (
        <>
            <Box mb={1}>
                <Typography component="p" variant="b">
                    {t("changeProposition.transactionDetail")}
                </Typography>
            </Box>
            <Box>
                <LabelTextItem fontVariant="caption" label={t("investments.transactionFundFrom")} text={transactionFromValue} />
                <LabelTextItem fontVariant="caption" label={t("investments.transactionValue")} text={amount} />
                <LabelTextItem
                    fontVariant="caption"
                    label={t("investments.transactionInterval")}
                    text={formatFundFrequency(mutualFundsTransaction.periodicityType, mutualFundsTransaction.frequency, frequencyMap)}
                />
                {getIntervalDates(t, mutualFundsTransaction)}
                {getSellSwitchDetail(t, mutualFundsTransaction, siteId)}
                <LabelTextItem
                    fontVariant="caption"
                    label={t("investments.transferName")}
                    text={formatEmptyValueWithDash(mutualFundsTransaction.transactionTo.transactionName)}
                />
            </Box>
        </>
    );
};

const getIntervalDates = (t, mutualFundsTransaction) => {
    if (mutualFundsTransaction.periodicityType === MutualFundsPeriodicity.ONETIME.id) {
        return null;
    } else {
        return (
            <>
                <LabelTextItem
                    fontVariant="caption"
                    label={t("investments.transactionFirstExchangeDate")}
                    text={formatEmptyValueWithDash(dateTimeToLocalFullDate(mutualFundsTransaction.firstTransactionDate))}
                />
                <LabelTextItem
                    fontVariant="caption"
                    label={t("investments.transactionLastExchangeDate")}
                    text={formatEmptyValueWithDash(dateTimeToLocalFullDate(mutualFundsTransaction.lastTransactionDate))}
                />
            </>
        );
    }
};

const getSellSwitchDetail = (t, mutualFundsTransaction, siteId) => {
    if (mutualFundsTransaction.transactionType.code === FundSellSwitch.SELL.id) {
        return (
            <>
                <LabelTextItem
                    fontVariant="caption"
                    label={t("common.accountNumber")}
                    text={formatFundPayment(mutualFundsTransaction.transactionTo, siteId)}
                />
                <LabelTextItem
                    fontVariant="caption"
                    label={t("payments.variableSymbol")}
                    text={formatEmptyValueWithDash(mutualFundsTransaction.transactionTo.variableSymbol)}
                />
                <LabelTextItem
                    fontVariant="caption"
                    label={t("payments.specificSymbol")}
                    text={formatEmptyValueWithDash(mutualFundsTransaction.transactionTo.specificSymbol)}
                />
            </>
        );
    } else {
        const transactionFromFund = fn.getEnumItemByCode(MutualFunds, mutualFundsTransaction?.transactionTo?.fund?.code);
        return <LabelTextItem fontVariant="caption" label={t("common.toFund")} text={t(transactionFromFund.msg)} />;
    }
};

MutualFundsTransactionDetail.propTypes = {
    mutualFundsTransaction: PropTypes.instanceOf(MutualFundsFinancialRequest).isRequired,
};
