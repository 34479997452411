import React from "react";

import { StandaloneHtmlLink } from "ui-library/atoms";

import { fn, model } from "core/util";
import { TFunction } from "core/i18n";
import { CodeValue } from "types";

export const formatCodeValueFundLink = (t: TFunction, languageCode: string, enumObject: any, fund: CodeValue) => {
    const enumItem = fund?.code ? fn.getEnumItemById(enumObject, fund.code) : null;
    const fundHref = enumItem ? t(enumItem.fundLink) : null;
    const fundName = model.getLocaleCodeName(fund, languageCode);

    if (!fundHref) {
        return fundName;
    }

    return (
        <StandaloneHtmlLink color="blue" href={fundHref} target="_blank">
            {fundName}
        </StandaloneHtmlLink>
    );
};
