import { model } from "core/util";

import { AmountLegacy } from "model/common";
import { MutualFundsActualValueOfInvestmentsBasedOnFund } from "../MutualFundsActualValueOfInvestmentsBasedOnFund";
import { MutualFundsInvestmentsProfit } from "../MutualFundsInvestmentsProfit";

export const MutualFundsDirectFinancialStatement = model.createModelLegacy("MutualFundsDirectFinancialStatement", (json) => ({
    statementDate: json.StatementDate,
    investedAmountSum: AmountLegacy.fromServer(json.InvestedAmountSum),
    actualValueOfInvestmentsSum: AmountLegacy.fromServer(json.ActualValueOfInvestmentsSum),
    profitSum: json.ProfitSum,
    actualValueOfInvestmentsBasedOnFund: MutualFundsActualValueOfInvestmentsBasedOnFund.fromServerList(
        json.ActualValueOfInvestmentsBasedOnFund,
    ),
    investmentsProfits: MutualFundsInvestmentsProfit.fromServerList(json.InvestmentsProfits),
}));
