import { NAME } from "./constants";

export const SET_INCIDENT_DETAIL = `${NAME}/SET_INCIDENT_DETAIL`;
export const SET_SELECTED_CLAIM_ID = `${NAME}/SET_SELECTED_CLAIM_ID`;
export const SET_DOCUMENT_TO_UPLOAD_REACT_KEY = `${NAME}/SET_DOCUMENT_TO_UPLOAD_REACT_KEY`;
export const CLEAR = `${NAME}/CLEAR`;

export const setIncidentDetail = (incidentDetail) => ({
    type: SET_INCIDENT_DETAIL,
    payload: incidentDetail,
});

export const setSelectedClaimId = (selectedClaimId) => ({
    type: SET_SELECTED_CLAIM_ID,
    payload: selectedClaimId,
});

export const setDocumentToUploadReactKey = (reactKey) => ({
    type: SET_DOCUMENT_TO_UPLOAD_REACT_KEY,
    payload: reactKey,
});

export const clear = () => ({
    type: CLEAR,
});
