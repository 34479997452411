import React from "react";
import { useSelector } from "react-redux";

import entity from "core/entity";
import fetching from "core/fetching";
import i18n from "core/i18n";
import { dateTimeToLocalFullDate, formatFinancialStatementAmount } from "core/formatters";
import { PensionPpCategoryEnum as CategoryEnum } from "enums";
import { PensionDssDdsFinancialStatement } from "model/pension";
import { Typography } from "ui-library/atoms";
import { DataTable } from "ui-library/modules";
import { GridItem } from "ui-library/layouts";

import { ENTITY_FINANCIAL_STATEMENT } from "../constants";
import { getPensionDssContractBalanceSheet } from "../selectors";

const columns = (t) => [
    {
        field: "name",
        headerName: t("common.actionType"),
        flex: 2,
        minWidth: 250,
        sortable: false,
    },
    {
        field: "amount",
        headerName: t("common.amount"),
        flex: 1,
        minWidth: 120,
        sortable: false,
        align: "right",
        headerAlign: "right",
        renderCell: (params) => formatFinancialStatementAmount(t, params.value, true),
    },
];

const prepareAmountRow = (name, category, amount) => ({
    name,
    category,
    amount,
});

const getData = (t, contractBalanceSheet) => [
    prepareAmountRow(t("pension.holderPaymentDss"), CategoryEnum.RECEIVED, contractBalanceSheet.get("compulsoryPaymentsTotal")),
    prepareAmountRow(t("pension.voluntaryHolderDss"), CategoryEnum.RECEIVED, contractBalanceSheet.get("voluntaryPaymentsTotal")),
    prepareAmountRow(
        t("pension.amountTransfersCompulsoryContribution"),
        CategoryEnum.TRANSFERS,
        contractBalanceSheet.get("compulsoryAmountTransfersTotal"),
    ),
    prepareAmountRow(
        t("pension.amountTransfersVoluntaryContribution"),
        CategoryEnum.TRANSFERS,
        contractBalanceSheet.get("voluntaryAmountTransfersTotal"),
    ),
];

export const ActualAccountBalanceGridItem = () => {
    const { t } = i18n.useTranslation();
    const isStatementLoading = useSelector(fetching.isFetching(ENTITY_FINANCIAL_STATEMENT, true));
    const isStatementError = useSelector(entity.isError(ENTITY_FINANCIAL_STATEMENT));

    const statementData = useSelector(entity.getData(ENTITY_FINANCIAL_STATEMENT, PensionDssDdsFinancialStatement.fromServer()));
    const contractBalanceSheet = useSelector(getPensionDssContractBalanceSheet);
    const statementDate = dateTimeToLocalFullDate(statementData.date);

    return (
        <GridItem xs={12}>
            <DataTable
                icon="document-dollar"
                title={t("pension.statementOverviewDss")}
                actions={<Typography color="gray">{t("statements.dateTime", { statementDate })}</Typography>}
                disableColumnReorder
                hideFooter={true}
                getRowId={(row) => row.name}
                columns={columns(t)}
                rows={getData(t, contractBalanceSheet)}
                errorMsg={t("error.sectionUnavailable")}
                isLoading={isStatementLoading}
                isError={isStatementError}
            />
        </GridItem>
    );
};
