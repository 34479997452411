import { createAction } from "@reduxjs/toolkit";

import { app } from "core/util";
import { PensionDpsContract } from "types";

import { NAME } from "./constants";

export const setPensionDpsContractDetail = app.createActionWithPayload<PensionDpsContract>(`${NAME}/SET_PENSION_DPS_CONTRACT_DETAIL`);

export const clear = createAction(`${NAME}/CLEAR`);
