import { Box } from "@mui/material";
import { withTheme } from "@mui/styles";
import { Block } from "ui-library/atoms/Blocks/Block";
import styled from "styled-components";

export const StyledBlogCardContent = withTheme(styled(Box)`
    padding: 24px;

    h2 {
        padding-bottom: 8px;
    }
`);
export const StyledBlogCardImage = withTheme(styled(Box)`
    width: 100%;
    height: 169px;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`);

export const StyledBlogCardWrapper = styled(Block)`
    &:hover {
        cursor: pointer;
        img {
            transition: all 0.2s ease-in-out;
            transform: scale(1.075);
        }
    }
`;
