import { model } from "core/util";

import { AmountLegacy, CodeValueLegacy } from "model/common";

export const MutualFundsDirectFinancialOperation = model.createModelLegacy("MutualFundsDirectFinancialOperation", (json) => ({
    id: json.Id,
    valueDate: json.ValueDate,
    type: CodeValueLegacy.fromServer(json.Type),
    fund: CodeValueLegacy.fromServer(json.Fund),
    investedAmount: AmountLegacy.fromServer(json.InvestedAmount),
    payment: AmountLegacy.fromServer(json.Payment),
    nettoInvestedAmount: AmountLegacy.fromServer(json.NettoInvestedAmount),
    feePayment: AmountLegacy.fromServer(json.FeePayment),
    roundingFee: AmountLegacy.fromServer(json.RoundingFee),
    pricePerShareAtOperationDateTime: AmountLegacy.fromServer(json.PricePerShareAtOperationDateTime),
    numberOfShares: json.NumberOfShares,
}));
