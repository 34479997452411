import React, { FC } from "react";
import { useSelector } from "react-redux";

import { GridLayout } from "ui-library/layouts";

import { ActiveOperationsGridItem, ContractParametersGridItem, FinancialRequestsGridItem, NewSellSwitchGridItem } from "../containers";
import { ModalActiveOperationsPhoneNumberForm } from "../form";
import { getIsDipContract } from "../selectors";

export const FinancialOperationsTab: FC = () => {
    const isDipContract = useSelector(getIsDipContract);
    return (
        <GridLayout defaultSpacing>
            <ContractParametersGridItem />
            {!isDipContract && <NewSellSwitchGridItem />}
            {!isDipContract && <ActiveOperationsGridItem />}
            <FinancialRequestsGridItem />

            {/* Modals */}
            <ModalActiveOperationsPhoneNumberForm />
        </GridLayout>
    );
};
