import { withTheme } from "@mui/styles";
import styled from "styled-components";
import { Masonry } from "@mui/lab";

export const StyledMasonry = withTheme(styled(Masonry)`
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        margin: 0;
        & > * {
            margin-left: 0px;
            margin-right: 0px;
        }
    }
`);
