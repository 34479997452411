import React, { FC, PropsWithChildren } from "react";
import { DialogProps } from "@mui/material/Dialog/Dialog";

import { Icon, Typography } from "ui-library/atoms";
import { Box, Flexbox } from "ui-library/layouts";
import { StyledModal, StyledModalBody, StyledModalFooter, StyledModalHeader } from "ui-library/Styled";
import { BoxProps } from "ui-library/layouts/Box/Box";

export interface ModalProps extends PropsWithChildren, DialogProps {
    size: string;
    open: boolean;
    onClose: any;
}

export const Modal: FC<ModalProps> = ({ size = "small", open, onClose, children, ...props }) => {
    const sizeParser = (sizeDef: string): string => {
        switch (sizeDef) {
            case "medium":
                return "md";
            case "small":
                return "sm";
            case "large":
                return "lg";
            default:
                return "md";
        }
    };

    return (
        <StyledModal maxWidth={sizeParser(size)} fullWidth={true} open={open} onClose={onClose} {...props}>
            {children}
        </StyledModal>
    );
};

export interface ModalHeaderProps extends BoxProps {
    closeAction: any;
    icon: any;
    title: string;
}

export const ModalHeader: FC<ModalHeaderProps> = ({ closeAction, icon, title, ...props }) => {
    return (
        <StyledModalHeader {...props}>
            <Flexbox alignItems={"center"} justifyContent={"space-between"}>
                <Flexbox alignItems={"center"}>
                    <Box mr={2}>
                        <Icon size={24} color="blue" icon={icon} />
                    </Box>

                    <Typography sx={{ display: "inline" }} variant="h3">
                        {title}
                    </Typography>
                </Flexbox>
                <Box>
                    <Icon onClick={closeAction} className="close-btn" size={15} icon={"error"} color="blue" />
                </Box>
            </Flexbox>
        </StyledModalHeader>
    );
};

export const ModalBody: FC<PropsWithChildren<BoxProps>> = ({ children, ...props }) => {
    return <StyledModalBody {...props}> {children} </StyledModalBody>;
};

export const ModalFooter: FC<PropsWithChildren<BoxProps>> = ({ children, ...props }) => {
    return <StyledModalFooter {...props}>{children}</StyledModalFooter>;
};
