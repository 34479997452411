import createModel from "../createModel";
import { MutualFundsFinancialRequest } from "../mutualFunds/MutualFundsFinancialRequest";
import { MutualFundsProfileChange } from "./MutualFundsProfileChange";
import { PensionInvestmentStrategiesChange } from "./PensionInvestmentStrategiesChange";
import { PensionFundsChange } from "./PensionFundsChange";

export const ChangeProposition = createModel("ChangeProposition", (json) => ({
    type: json.Type,
    mutualFundsTransaction: MutualFundsFinancialRequest.fromServer(json.MutualFundsTransaction),
    mutualFundsProfileChange: MutualFundsProfileChange.fromServer(json.MutualFundsProfileChange),
    pensionFundsChange: PensionFundsChange.fromServer(json.PensionFundsChange),
    pensionInvestmentStrategiesChange: PensionInvestmentStrategiesChange.fromServer(json.PensionInvestmentStrategiesChange),
}));
