import { createAction } from "@reduxjs/toolkit";

import { app } from "core/util";
import { ChangeResponseType, PensionDssContract } from "types";

import { NAME } from "./constants";

export const setPensionDssContractDetail = app.createActionWithPayload<PensionDssContract>(`${NAME}/SET_PENSION_DSS_CONTRACT_DETAIL`);
export const setFundsChangeFormChangeType = app.createActionWithPayload<String>(`${NAME}/SET_FUNDS_CHANGE_FORM_CHANGE_TYPE`);
export const setPisFormChangeResult = app.createActionWithPayload<ChangeResponseType>(`${NAME}/SET_PIS_FORM_CHANGE_RESULT`);

export const clear = createAction(`${NAME}/CLEAR`);
