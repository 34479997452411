import { NAME } from "./constants";
import { PayloadAction } from "@reduxjs/toolkit";

export const SELECT_SUGGESTION = `${NAME}/SELECT_SUGGESTION`;

export const makeSelectSuggestion =
    (name: string) =>
    (suggestion: string): PayloadAction<string, string, { name: string }> => ({
        type: SELECT_SUGGESTION,
        meta: { name },
        payload: suggestion,
    });
