import { extendedOneLineNameWithTitles, formatYesNo } from "core/formatters";
import { model, nbsp } from "core/util";
import { AddressTypeCodeEnum, Payments } from "types";

import { TFunction } from "../i18n";
import { getLocalizedOneLineAddressFull } from "./getLocalizedOneLineAddressFull";

export const getLocalizedThirdPartyPayments = (t: TFunction, thirdPartyPayments: Payments) => {
    const thirdPartyPaymentsEnabled = thirdPartyPayments.enabled;
    const thirdPartyName = extendedOneLineNameWithTitles(thirdPartyPayments.payer);
    const thirdPartyAddress = getLocalizedOneLineAddressFull(
        t,
        model.getAddressByTypeCode(thirdPartyPayments.payer, AddressTypeCodeEnum.PER),
    );

    const thirdPartyNameString = thirdPartyPaymentsEnabled && thirdPartyName ? `,${nbsp}${thirdPartyName}` : "";
    const thirdPartyAddressString = thirdPartyPaymentsEnabled && thirdPartyAddress ? `,${nbsp}${thirdPartyAddress}` : "";

    return `${formatYesNo(t, thirdPartyPaymentsEnabled)}${thirdPartyNameString}${thirdPartyAddressString}`;
};
