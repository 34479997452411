import { withTheme } from "@mui/styles";
import styled from "styled-components";

import { Flexbox } from "ui-library/layouts/Flexbox/Flexbox";

export const StyledLegend = withTheme(styled(Flexbox)`
    border-bottom: 1px solid ${(props) => props.theme.palette.gray.line};
    padding: 8px;
    &:last-child {
        border-bottom: none;
    }
`);
