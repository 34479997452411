import { v4 as uuid } from "uuid";

import { model } from "core/util";
import { RiskType } from "enums";

import { AddressLegacy, AmountLegacy, LegalEntityLegacy, SimpleCodeValue } from "../common";

import { Validity } from "./Validity";
import { Coverage } from "./Coverage";

export const InsuredRealEstateProperty = model.createModelLegacy("InsuredRealEstateProperty", (json) => ({
    reactKey: uuid(),
    riskType: RiskType.PROPERTY,
    identifier: json.Identifier,
    description: json.Description,
    category: SimpleCodeValue.fromServer(json.Category),
    riskCode: json.RiskCode,
    insuranceCost: AmountLegacy.fromServer(json.InsuranceCost),
    validity: Validity.fromServer(json.Validity),
    coverages: Coverage.fromServerList(json.Coverages),
    address: AddressLegacy.fromServer(json.Address),
    vinculations: LegalEntityLegacy.fromServerList(json.Vinculations),
}));
