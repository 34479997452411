import { NAME } from "./constants";

export const SET_PAYMENT_SUCCESSFUL = `${NAME}/SET_PAYMENT_STATE`;
export const SET_ERROR = `${NAME}/SET_ERROR`;

export const setPaymentSuccessful = (isSuccessful) => ({
    type: SET_PAYMENT_SUCCESSFUL,
    payload: isSuccessful,
});

export const setError = () => ({
    type: SET_ERROR,
});
