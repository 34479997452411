import { List } from "immutable";
import createModel from "../createModel";
import { KeyValue } from "../common";
import { UniqaClaimAddress } from "./UniqaClaimAddress";

export const UniqaIncident = createModel("UniqaIncident", (json) => ({
    incidentId: json.incidentId,
    incidentNumber: json.incidentNumber,
    policyNumber: json.policyNumber,
    lineOfBusinesses: List(json.lineOfBusinesses),
    incidentState: json.incidentState,
    incidentDate: json.incidentDate,
    reportDate: json.reportDate,
    incidentCause: KeyValue.fromServer(json.incidentCause),
    insuranceRisk: KeyValue.fromServer(json.insuranceRisk),
    bonusMalus: json.bonusMalus,
    address: UniqaClaimAddress.fromServer(json.address),
    canWriteNote: json.canWriteNote,
    isAssistanceInsuranceRisk: json.isAssistanceInsuranceRisk,
}));
