import { call, put, select } from "redux-saga/effects";
import { formWrapper } from "core/form";
import errorHandling from "core/errorHandling";
import { sentry } from "core/util";
import { contractDataChangeApi } from "serverApi";

export function* createSaga(formName, idObject, getStatementPassword) {
    try {
        yield call(formSaga(formName, getStatementPassword), idObject);
    } catch (e) {
        sentry.captureException(e);
        yield put(errorHandling.addServiceError(formName, e.identifier));
    }
}

const formSaga = (formName, getStatementPassword) =>
    formWrapper(formName, {
        *initialize(idObject) {
            yield put(errorHandling.removeServiceErrors(formName));
            const formInitData = {
                idObject,
                password: null,
            };
            const statementsPassword = yield select(getStatementPassword);
            if (statementsPassword) {
                formInitData.password = statementsPassword;
            }
            return formInitData;
        },
        *save(values) {
            yield call(contractDataChangeApi.updateStatementsPassword, values);
            return values;
        },
    });
