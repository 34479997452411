import { call, put, select } from "redux-saga/effects";
import { Selector } from "@reduxjs/toolkit";

import errorHandling from "core/errorHandling";
import { formWrapper, scrollToFirstError } from "core/form";
import userInfo from "core/userInfo";
import { sentry } from "core/util";
import { State } from "types";

import { FIELD_INVESTMENT_STRATEGY } from "./constants";
import { pensionDssApi } from "serverApi";

export function* createSaga(
    formName: string,
    idObject: number,
    getIsInvestmentStrategyCombinedSelector: Selector<State, boolean>,
    refreshDetailDataCallback,
) {
    try {
        yield call(formSaga(formName, idObject, getIsInvestmentStrategyCombinedSelector, refreshDetailDataCallback));
    } catch (e) {
        sentry.captureException(e);
        yield put(errorHandling.addServiceError(formName, e.identifier));
    }
}

const formSaga = (
    formName: string,
    idObject: number,
    getIsInvestmentStrategyCombinedSelector: Selector<State, boolean>,
    refreshDetailDataCallback,
) =>
    formWrapper(formName, {
        *initialize() {
            yield call(userInfo.checkUserVerified);
            yield put(errorHandling.removeServiceErrors(formName));
            const isInvestmentStrategyCombined: boolean = yield select(getIsInvestmentStrategyCombinedSelector);

            return {
                [FIELD_INVESTMENT_STRATEGY]: isInvestmentStrategyCombined,
                idObject,
            };
        },
        // @ts-ignore we won't type sagas
        *onSubmitFail() {
            yield call(scrollToFirstError);
        },
        *save(values) {
            yield call(pensionDssApi.updatePensionInvestmentStrategy, values);
            return values;
        },
        *success(values) {
            yield call(refreshDetailDataCallback, values.get("idObject"));
        },
    });
