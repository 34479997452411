import React, { FC } from "react";
import { useParams } from "react-router-dom";

import { ContractAgentTab } from "containers/contract";

export const AgentTab: FC = () => {
    const { idObject } = useParams();

    return <ContractAgentTab idObject={Number(idObject)} />;
};
