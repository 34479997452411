import { Record } from "immutable";

class PensionInvestmentStrategy extends Record({ id: null, msg: "" }) {}

export default Object.freeze({
    COMBINED: new PensionInvestmentStrategy({
        id: "COMBINED",
        msg: "pension.dss.investmentStrategy.combined",
    }),
    DIVIDED: new PensionInvestmentStrategy({
        id: "DIVIDED",
        msg: "pension.dss.investmentStrategy.divided",
    }),
});
