import { model } from "core/util";
import { IdDto, IdentityCard as IdentityCardType } from "types";

export const IdentityCard = model.createModel<IdDto, IdentityCardType>((json) => ({
    idTypeCode: json.IdTypeCode,
    number: json.Number,
    releaseDate: json.ValidFrom,
    dateOfExpiry: json.ValidTo,
    authority: json.Autority,
}));
