import { Record } from "immutable";

class DssSavingStrategyFund extends Record({ id: null, msg: null, code: null, fundId: null }) {}

export default Object.freeze({
    // NOT guaranteed fund
    STOCK: new DssSavingStrategyFund({
        id: "STOCK",
        code: "Fund1",
        msg: "saving.strategy.stock",
        fundId: "1",
    }),
    // ???
    MIXED: new DssSavingStrategyFund({
        id: "MIXED",
        code: "Fund2",
        msg: "saving.strategy.mixed",
        fundId: "2",
    }),
    // guaranteed fund
    BOND: new DssSavingStrategyFund({
        id: "BOND",
        code: "Fund3",
        msg: "saving.strategy.bond",
        fundId: "3",
    }),
    // NOT guaranteed fund
    INDEXED: new DssSavingStrategyFund({
        id: "INDEXED",
        code: "Fund4",
        msg: "saving.strategy.indexed",
        fundId: "4",
    }),
});
