import { fromJS } from "immutable";

import { TableFilterOperator } from "enums";

import { CATEGORY_NAME_COLUMN, GROSS_AMOUNT_COLUMN, VALUE_DATE_COLUMN } from "../constants";

export const financialOperationsDefaultOperators = fromJS({
    categoryName: { column: CATEGORY_NAME_COLUMN, operator: TableFilterOperator.CONTAINS },
    dateFrom: { column: VALUE_DATE_COLUMN, operator: TableFilterOperator.AFTER },
    dateTo: { column: VALUE_DATE_COLUMN, operator: TableFilterOperator.BEFORE },
    grossAmount: { column: GROSS_AMOUNT_COLUMN, operator: "" },
});
