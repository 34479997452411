import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Button, Typography } from "ui-library/atoms";
import { Box, ContentBox, GridItem, Section } from "ui-library/layouts";
import { DataTable, InfoPanel } from "ui-library/modules";
import useBreakpoints from "ui-library/theme/breakpoints";

import entity from "core/entity";
import fetching from "core/fetching";
import i18n, { TFunction } from "core/i18n";
import router from "core/router";
import {
    dateTimeToLocalFullDate,
    formatCodeValueFundLink,
    localAmountInvestment,
    localAmountInvestmentWithCurrencyEurLegacy,
    localAmountWithCurrencyLegacy,
} from "core/formatters";
import { useHasEditContractPermission } from "core/hooks";
import userContracts from "core/userContracts";
import { DdsFunds } from "enums";
import { PensionDssDdsFinancialStatement } from "model/pension";
import { Pages, Tabs } from "routeConstants";

import { ENTITY_FINANCIAL_STATEMENT } from "../constants";

import { PieChartWithFundsTable } from "./PieChartWithFundsTable";

const columns = (t: TFunction, languageCode: string) => [
    {
        field: "investmentFund",
        headerName: t("common.fund"),
        flex: 1.5,
        sortable: false,
        renderCell: (params) => formatCodeValueFundLink(t, languageCode, DdsFunds, params.value),
    },
    {
        field: "numberOfShares",
        headerName: t("pension.dds.numberOfShares"),
        flex: 1,
        valueGetter: (params) => localAmountInvestment(params.value),
        sortable: false,
        align: "right",
        headerAlign: "right",
    },
    {
        field: "pricePerShare",
        headerName: t("pension.actualPricePerShare"),
        flex: 1,
        valueGetter: (params) => localAmountInvestmentWithCurrencyEurLegacy(params.value),
        sortable: false,
        align: "right",
        headerAlign: "right",
    },
    {
        field: "actualInvestmentsValue",
        headerName: t("investments.actualInvestmentsValue"),
        valueGetter: (params) => localAmountWithCurrencyLegacy(params.value),
        flex: 1,
        sortable: false,
        align: "right",
        headerAlign: "right",
    },
];

export const ActualInvestmentValue: FC = () => {
    const { t } = i18n.useTranslation();
    const languageCode = i18n.getLanguageCode();
    const navigate = useNavigate();
    const { idObject } = useParams();
    const formLink = router.getStaticUrl(Pages.CONTRACT_DDS, Tabs.PENSION_DDS_FUNDS_CHANGE_FORM, { idObject });
    const hasEditPermission = useHasEditContractPermission();
    const { canEdit: isContractEditable } = userContracts.useGetContractById(idObject);

    const isStatementLoading = useSelector(fetching.isFetching(ENTITY_FINANCIAL_STATEMENT, true));
    const isStatementError = useSelector(entity.isError(ENTITY_FINANCIAL_STATEMENT));
    const statementData = useSelector(entity.getData(ENTITY_FINANCIAL_STATEMENT, PensionDssDdsFinancialStatement.fromServer()));
    const fundsData = statementData.pensionInvestmentsBasedOnFund?.toArray();
    const statementDate = dateTimeToLocalFullDate(statementData.date);

    if (statementData?.actualInvestmentsValue?.value === "0") {
        return null;
    }

    const isMd = useBreakpoints("md");

    return (
        <GridItem xs={12}>
            <InfoPanel
                icon={"chart-line"}
                title={t("insurance.investmentStateBasedOnFunds")}
                headerAction={<Typography color="gray">{t("statements.dateTime", { statementDate })}</Typography>}
            >
                {/* Funds table data */}
                <ContentBox>
                    <DataTable
                        headerHeight={isMd ? 60 : 70}
                        getRowId={(row) => row.reactKey}
                        columns={columns(t, languageCode)}
                        rows={fundsData}
                        summaryRow={{
                            desc: t("common.overall"),
                            total: localAmountWithCurrencyLegacy(statementData.actualInvestmentsValue),
                        }}
                        withWrapper={false}
                        disableColumnReorder
                        isLoading={isStatementLoading}
                        isError={isStatementError}
                    />
                </ContentBox>

                {/* Change button */}
                {hasEditPermission && isContractEditable && (
                    <Section>
                        <Button color="green" variant="contained" onClick={() => navigate(formLink)}>
                            {t("common.changeInvestments")}
                        </Button>
                    </Section>
                )}

                {/* Chart and sum data */}
                {!isStatementLoading && !isStatementError && (
                    <Box>
                        <Box mb={1}>
                            <Typography variant="h4">{t("insurance.investmentStateBasedOnFunds")}</Typography>
                        </Box>
                        <PieChartWithFundsTable />
                    </Box>
                )}
            </InfoPanel>
        </GridItem>
    );
};
