import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { GridItem } from "ui-library/layouts";
import { Button, Typography } from "ui-library/atoms";
import { ContractCard, InfoText } from "ui-library/modules";
import { ADAPTIVE } from "ui-library/modules/enums";

import i18n from "core/i18n";
import pageContext from "core/pageContext";
import { AmountLegacy } from "model/common";
import { dateTimeToLocalFullDate, formatTwoValuesToOneText, localAmountWithCurrencyLegacy } from "core/formatters";
import { claims, fn } from "core/util";
import userContracts from "core/userContracts";

import { ProductGroup } from "enums";

export const CardAxaIncident = ({ incident, isActive, lifeDetailLink, nonLifeDetailLink, shouldDisplayContractNumber }) => {
    const { t } = i18n.useTranslation();
    const country = useSelector(pageContext.getSite);
    const policy = userContracts.useGetContractByContractNumber(incident.policyNumber);
    const isLife = policy?.idEnuProductGroup === ProductGroup.LIFE;

    const isNonLifeOrIsClaimantOwner = (item) => !isLife || Number(item.isClaimantOwnerContract) === 1;

    return (
        <GridItem xs={12}>
            <ContractCard
                variant={ADAPTIVE}
                title={incident.claimSubType}
                subTitleSection={isActive && <InfoText icon={"arrow-right"} color="orange" text={incident.claimStatus} />}
                imageUrl={claims.getImageCategoryByProductGroupLvl2(policy?.idEnuProductGroupLevelTwo)}
                action={
                    <Button variant={"contained"} color={"blue"} externalLink={isLife ? lifeDetailLink : nonLifeDetailLink} newTab>
                        {t("common.moreInformation")}
                    </Button>
                }
            >
                {/* Insured person - only for axa life contracts */}
                {isLife && (
                    <Typography component="p">{`${t("person.insured")} ${formatTwoValuesToOneText(
                        incident.insuredFirstName,
                        incident.insuredLastName,
                    )}`}</Typography>
                )}

                {/* Contract Number - used on general list. */}
                {shouldDisplayContractNumber && (
                    <Typography component="p">{`${t("common.contractNumber")} ${incident.policyNumber}`}</Typography>
                )}

                {/* Claim/Incident number - only for non life or life where claimant is contract owner */}
                {isNonLifeOrIsClaimantOwner(incident) && (
                    <Typography component="p">{`${t("claim.incidentNumber")} ${incident.claimNumber}`}</Typography>
                )}

                {isLife && incident.externalNo && (
                    <Typography component="p">{`${t("common.incidentNumber")} ${incident.externalNo}`}</Typography>
                )}

                {/* Incident date */}
                <Typography component="p">{`${t("common.creationDate")} ${dateTimeToLocalFullDate(incident.eventDate)}`}</Typography>

                {/* Date of closure or last state change */}
                {isActive ? (
                    <Typography component="p">{`${t("common.eventLastChange")} ${dateTimeToLocalFullDate(
                        incident.lastStateChange,
                    )}`}</Typography>
                ) : (
                    <Typography component="p">{`${t("common.eventClosed")} ${dateTimeToLocalFullDate(
                        incident.lastStateChange,
                    )}`}</Typography>
                )}

                {/* Paid amount - only for ended non life contracts */}
                {!isLife && !isActive && !fn.isEmpty(incident.paid) && (
                    <Typography component="p">{`${t("common.paidAmount")} ${localAmountWithCurrencyLegacy(
                        new AmountLegacy({ value: incident.paid, currencyCode: country.currencyCode }),
                    )}`}</Typography>
                )}
            </ContractCard>
        </GridItem>
    );
};

CardAxaIncident.propTypes = {
    incident: PropTypes.object.isRequired,
    isActive: PropTypes.bool,
    lifeDetailLink: PropTypes.string.isRequired,
    nonLifeDetailLink: PropTypes.string.isRequired,
    shouldDisplayContractNumber: PropTypes.bool,
};

CardAxaIncident.defaultProps = {
    isActive: false,
    shouldDisplayContractNumber: false,
};
