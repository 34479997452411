import React from "react";
import { useParams } from "react-router-dom";
import { Pages } from "routeConstants";

import { VehicleCoveragesWithSelect } from "../containers";

import { setDetailSelectedCoverage } from "../actions";
import { getDetailSelectedCoverage, getInsurancePackagesOptions, getSelectedProductPackage } from "../selectors";

export const InsuranceCoverageGridItem = () => {
    const { idObject } = useParams();

    return (
        <VehicleCoveragesWithSelect
            idObject={idObject}
            topRoute={Pages.CONTRACT_VEHICLE}
            getDetailSelectedCoverage={getDetailSelectedCoverage}
            getInsurancePackagesOptions={getInsurancePackagesOptions}
            getSelectedProductPackage={getSelectedProductPackage}
            setDetailSelectedCoverage={setDetailSelectedCoverage}
        />
    );
};
