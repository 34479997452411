import React, { FC } from "react";
import { useSelector } from "react-redux";

import { CampaignsDataStatusEnum } from "types";

import { DataLoadedContent, ErrorContent } from "../containers/electronicCommunication";
import { getElectronicCommunicationCampaignData } from "../selectors";

export const ElectronicCommunicationTab: FC = () => {
    const campaignData = useSelector(getElectronicCommunicationCampaignData);
    return (
        <>
            {campaignData.result === CampaignsDataStatusEnum.OK && <DataLoadedContent />}
            {campaignData.result !== CampaignsDataStatusEnum.OK && <ErrorContent />}
        </>
    );
};
