import { fn, nbsp } from "../util";
import { localAmount, localAmountNoDecimal } from "./localAmountFormatter";
import { decimalNumberDotOrCommaRegex } from "core/form";

export const addSpacesToNumber = (value: number | string) => {
    value = value.toString();
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, nbsp);
};

/*
Output needs to be string
if we need add x zeros to end to have all numbers same size use toFixed(decimals) - (123, 2) round to 123,00
in other case use toString() which end rounding without zeros - (123,2) rounds to 123
*/
export const roundTo = (value: number, decimals = 0): string =>
    // @ts-ignore TODO: martin.rodin - fix the argument of Math.round, there might be a bug
    Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`).toFixed(decimals);

export const formatPercentage = (value: number): string => {
    if (fn.isEmpty(value)) {
        return "";
    }
    return `${localAmount(value)}${nbsp}%`;
};

export const formatPercentageNoDecimal = (value: string): string => {
    if (!value) {
        return "";
    }
    return `${localAmountNoDecimal(value)}${nbsp}%`;
};

export const formatPercentageNonLifeCoverages = (value: string): string => {
    if (fn.isEmpty(value)) {
        return "";
    } else if (decimalNumberDotOrCommaRegex.test(value)) {
        return `${localAmount(fn.decimalNumberWithDot(value))}${nbsp}%`;
        // in case of "compound value" as "2 % min. 4 000" or "10% /30%", we return plain value received from core system
    } else {
        return value;
    }
};
