import React, { FC } from "react";

import { Image } from "ui-library/atoms";
import { Box, Flexbox } from "ui-library/layouts";

import laptopImage from "assets/campaign/1440x490_Laptop.png";

import { CampaignElectronicCommunicationInPageForm } from "../../form";

export const DataLoadedContent: FC = () => (
    <>
        <Flexbox direction={{ lg: "row" }} justifyContent={"center"} mb={5}>
            <Box sx={{ width: 960 }}>
                <Image borderRadius={0} alt={"table"} src={laptopImage} shadow={false} />
            </Box>
        </Flexbox>

        <CampaignElectronicCommunicationInPageForm />
    </>
);
