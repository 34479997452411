import { Map } from "immutable";
import { combineReducers } from "redux-immutable";

export const createReducer = (actions) => {
    const filter = (state = Map(), { type, payload }) => {
        switch (type) {
            case actions.RESET_FILTER:
                return Map();
            case actions.SET_FILTER:
                return payload;
            default:
                return state;
        }
    };

    return combineReducers({ filter });
};
