import slice from "./slice";
import * as selectors from "./selectors";
import saga, { withFetching } from "./saga";
import { FETCHING_AVAILABLE_PROFILES, FETCHING_CONTRACTS_FINANCIAL_OPERATIONS, FETCHING_PROFILE_CHANGE_FEE } from "./constants";

const fetching = {
    NAME: slice.name,
    reducer: slice.reducer,
    ...slice.actions,
    ...selectors,

    saga,
    withFetching,

    FETCHING_AVAILABLE_PROFILES,
    FETCHING_PROFILE_CHANGE_FEE,
    FETCHING_CONTRACTS_FINANCIAL_OPERATIONS,

    fetchingString: "...",

    start: (entity: string) => slice.actions.setFetching(entity, true, false),
    startImmediate: (entity: string) => slice.actions.setFetching(entity, true, true),
    stop: (entity: string) => slice.actions.setFetching(entity, false, false),
};

export default fetching;
