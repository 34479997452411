import createModel from "../../createModel";
import { CodeValueLegacy, AmountLegacy } from "../../common";

import { MutualFundsAccountPaymentDetailLegacy } from "../MutualFundsAccountPaymentDetailLegacy";
import { MutualFundsRegularFinancialOperationFund } from "./MutualFundsRegularFinancialOperationFund";

export const MutualFundsRegularFinancialOperationDetail = createModel("MutualFundsRegularFinancialOperationDetail", (json) => ({
    id: json.Id,
    operationType: CodeValueLegacy.fromServer(json.OperationType),
    valueDate: json.ValueDate,
    payment: AmountLegacy.fromServer(json.Payment),
    feePayment: AmountLegacy.fromServer(json.FeePayment),
    transactionFundFrom: CodeValueLegacy.fromServer(json.TransactionFundFrom),
    transactionTo: MutualFundsAccountPaymentDetailLegacy.fromServer(json.TransactionTo),
    transactionDate: json.TransactionDate,
    numberOfSharesInTransaction: json.NumberOfSharesInTransaction,
    transactionStatus: CodeValueLegacy.fromServer(json.TransactionStatus),
    roundingFee: AmountLegacy.fromServer(json.RoundingFee),
    investedAmount: AmountLegacy.fromServer(json.InvestedAmount),
    nettoInvestedAmount: AmountLegacy.fromServer(json.NettoInvestedAmount),
    investmentProfile: CodeValueLegacy.fromServer(json.InvestmentProfile),
    funds: MutualFundsRegularFinancialOperationFund.fromServerList(json.Funds),
}));
