import React from "react";
import PropTypes from "prop-types";
import { Map } from "immutable";

import { Box, ContentBox } from "ui-library/layouts";

import i18n from "core/i18n";
import { Field, required, SingleSelect } from "core/form";

import { REDEEM_TYPE_FIELD } from "../constants";

export const SelectRedeemTypeSection = ({ allowedRedeemTypes }) => {
    if (allowedRedeemTypes.size === 1) {
        return null;
    }

    const { t } = i18n.useTranslation();
    const options = allowedRedeemTypes.map((redeemType) => Map().set("label", t(redeemType.msg)).set("value", redeemType.id));

    return (
        <Box>
            <ContentBox>
                <Field
                    name={REDEEM_TYPE_FIELD}
                    label={t("form.pensionRedeemSavings.redeemType")}
                    component={SingleSelect}
                    validateStatic={[required]}
                    options={options}
                />
            </ContentBox>
        </Box>
    );
};

SelectRedeemTypeSection.propTypes = {
    allowedRedeemTypes: PropTypes.object.isRequired,
};
