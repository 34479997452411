import React from "react";

import { GridLayout } from "ui-library/layouts";

import { IncidentsSection } from "containers/incident";

import { ENTITY_CLIENT_INCIDENTS, ENTITY_CLIENT_INCIDENTS_OPEN_STATE } from "../constants";

export const ClientClaimsTab = () => (
    <GridLayout defaultSpacing>
        <IncidentsSection
            incidentsEntity={ENTITY_CLIENT_INCIDENTS}
            displayedIncidentStateEntity={ENTITY_CLIENT_INCIDENTS_OPEN_STATE}
            shouldDisplayContractNumber
        />
    </GridLayout>
);
