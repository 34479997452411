import { withTheme } from "@mui/styles";
import styled from "styled-components";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box } from "@mui/material";

export const StyledTabs = withTheme(styled(Tabs)`
    overflow-x: auto;
    @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
        position: relative;
        &:after {
            content: "";
            width: 20px;
            height: 100%;
            top: 0;
            bottom: 0;
            right: 0;
            position: absolute;
            background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
        }
    }
`);

export const StyledTab = withTheme(styled(Tab)`
    & {
        color: ${(props) => props.theme.palette.blue.main};
        &:hover,
        &:focus,
        &:active {
            color: ${(props) => props.theme.palette.blue.dark};
        }
    }

    &:after {
        content: "";
        background: ${(props) => props.theme.palette.gray.line};
        width: 1px;
        height: calc(100% - 7px);
        position: absolute;
        top: 0;
        right: 0;
    }
    &:last-child {
        &:after {
            display: none;
        }
    }
    &.Mui-selected {
        span {
            font-weight: 700;
        }
    }
`);

export const StyledTabPanel = withTheme(styled(Box)``);
