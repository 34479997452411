import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { nanoid, Selector } from "@reduxjs/toolkit";
import { arrayPush } from "redux-form/immutable";
import { List, Map } from "immutable";

import i18n from "core/i18n";
import router from "core/router";
import { FullPageFormLayout } from "containers/fullPageForm";
import { form, getFormFieldValue } from "core/form";
import { Pages, Tabs } from "routeConstants";
import { ContentBox, Flexbox, Section } from "ui-library/layouts";
import { Accordion, AccordionTitle, Button, Container, Loading, Typography } from "ui-library/atoms";
import { FormBlock } from "ui-library/modules";
import { SubjectType } from "enums";
import { PensionBeneficiary, State } from "types";

import { deleteBeneficiary } from "./actions";
import { BackToPolicyDetailButton } from "./components";
import { BENEFICIARIES_FIELD, MAX_BENEFICIARIES } from "./constants";
import { BeneficiaryDdsDss } from "./containers";

const createEmptyBeneficiary = () =>
    Map({
        reactKey: nanoid(),
        firstName: "",
        lastName: "",
        birthNumber: "",
        share: "",
        address: Map({ state: "SK" }),
        subjectType: SubjectType.INDIVIDUAL.id,
    });

type ContainerDdsDssType = {
    formName: string;
    beneficiariesSelector: Selector<State, PensionBeneficiary[]>;
    idObject: number;
    isDds: boolean;
    canSubmit: boolean;
    handleSubmit: () => void;
    submitting: boolean;
};

const ContainerDdsDss: FC<ContainerDdsDssType> = ({
    formName,
    beneficiariesSelector,
    idObject,
    isDds,
    canSubmit,
    handleSubmit,
    submitting,
}) => {
    const { t } = i18n.useTranslation();

    const beneficiaries = useSelector(beneficiariesSelector);
    const originalBeneficiariesCount = beneficiaries ? beneficiaries.length : 0;
    const formBeneficiaries = useSelector(getFormFieldValue(formName, BENEFICIARIES_FIELD)) || List();

    const maxBeneficiaryCount = originalBeneficiariesCount > MAX_BENEFICIARIES ? originalBeneficiariesCount : MAX_BENEFICIARIES;
    const formBeneficiaryCount = formBeneficiaries ? formBeneficiaries.size : 0;
    const showRemove = originalBeneficiariesCount > 0 || formBeneficiaryCount > 1;

    const data = formBeneficiaries
        .map((beneficiary, index) => {
            const isCompany = beneficiary.get("subjectType") === SubjectType.COMPANY.id;
            let beneficiaryName;
            if (isCompany) {
                beneficiaryName = beneficiary.get("companyName") || "";
            } else {
                beneficiaryName = beneficiary.get("lastName") ? `${beneficiary.get("firstName")} ${beneficiary.get("lastName")}` : "";
            }
            const placeHolder = isCompany ? t("holder.companyName") : `${t("holder.firstName")} ${t("holder.lastName")}`;

            return {
                title: (
                    <AccordionTitle
                        text={beneficiaryName.trim() ? beneficiaryName : placeHolder}
                        shareNumber={beneficiary.get("share") ? `${beneficiary.get("share")} %` : "0 %"}
                    />
                ),
                children: <BeneficiaryDdsDss formName={formName} index={index} />,
            };
        })
        .toArray();

    const dispatch = useDispatch();
    const addNewBeneficiary = () => dispatch(arrayPush(formName, BENEFICIARIES_FIELD, createEmptyBeneficiary()));
    const removeBeneficiary = (index) => dispatch(deleteBeneficiary(index));

    const agentTabLink = router.getStaticUrl(Pages.CONTRACT_DPS, Tabs.AGENT, { idObject });
    const foreignerBeneficiaryText = i18n.translateDirectly("pension.ddsDss.foreignerBeneficiaryText", { agentTabLink });

    return (
        <Container maxWidth="md">
            <Loading fullPage loading={submitting} />

            <FormBlock>
                <ContentBox>
                    <Typography markDown={isDds ? t("pension.dds.editBeneficiaryText") : t("pension.dss.editBeneficiaryText")} />
                </ContentBox>

                <ContentBox mb={4}>
                    {/* @ts-ignore TODO: martin.rodin fix types in i18n later */}
                    <Typography markDown={foreignerBeneficiaryText} />
                </ContentBox>

                <Section>
                    <Accordion
                        defaultLastExpanded
                        compact
                        deleteAction={showRemove ? removeBeneficiary : false}
                        data={data}
                        id="baseAccordion"
                    />
                </Section>

                {formBeneficiaryCount < maxBeneficiaryCount && (
                    <Section>
                        <Button variant="outlined" startIcon="plus" color="blue" onClick={addNewBeneficiary}>
                            {t("pension.addBeneficiary")}
                        </Button>
                    </Section>
                )}

                <ContentBox mb={4}>
                    <Typography variant="caption">
                        {formBeneficiaryCount === 0
                            ? isDds
                                ? t("pension.dds.noBeneficiaries")
                                : t("pension.dss.noBeneficiaries")
                            : t("pension.ddsDss.editBeneficiaryAcceptanceText")}
                    </Typography>
                </ContentBox>

                <Flexbox gap={2} justifyContent="space-between">
                    <BackToPolicyDetailButton idObject={idObject} />

                    <Button onClick={handleSubmit} variant="contained" color="blue" disabled={!canSubmit}>
                        {t("common.send")}
                    </Button>
                </Flexbox>
            </FormBlock>
        </Container>
    );
};

export const createContainerDdsDss = (
    formName: string,
    beneficiariesSelector: Selector<State, PensionBeneficiary[]>,
    idObjectSelector: Selector<State, number>,
    isDds: boolean,
) =>
    form(formName)((props) => {
        const { t } = i18n.useTranslation();
        const idObject = useSelector(idObjectSelector);

        return (
            <FullPageFormLayout
                formName={formName}
                formTitle={t("form.editPensionBeneficiaries")}
                formLead={isDds ? t("form.editPensionBeneficiaries.dds.lead") : t("form.editPensionBeneficiaries.dss.lead")}
                BackButton={<BackToPolicyDetailButton idObject={idObject} />}
            >
                <ContainerDdsDss
                    {...props}
                    formName={formName}
                    beneficiariesSelector={beneficiariesSelector}
                    idObject={idObject}
                    isDds={isDds}
                />
            </FullPageFormLayout>
        );
    });
