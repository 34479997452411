export const getPersonIdLegacy = (ids, t) => {
    const IDENTITY_CARD_ENUM = "OP";
    const PASSPORT_ENUM = "CP";

    let label = t("holder.idPassNumber");
    let numberValue = "";
    if (ids) {
        const idCard = ids.filter((item) => item.idTypeCode === IDENTITY_CARD_ENUM).first();
        const passport = ids.filter((item) => item.idTypeCode === PASSPORT_ENUM).first();
        if (idCard) {
            label = t("holder.idNumber");
            numberValue = idCard.number;
        } else if (passport) {
            label = t("holder.passNumber");
            numberValue = passport.number;
        }
    }
    return { label, number: numberValue };
};
