import { withTheme } from "@mui/styles";
import styled from "styled-components";
import { Badge } from "@mui/material";

const positioning = (props) => {
    if (props.$childPadding === 0) {
        return ` right :  9px;`;
    } else {
        return `right :  ${props.$childPadding * 8 + 9}px;
                top :  ${props.$childPadding * 8}px;
        
        `;
    }
};

export const StyledBadge = withTheme(styled(Badge)`
    .MuiBadge-badge {
        color: ${(props) => props.theme.palette.gray.main};
        background: ${(props) => props.theme.palette.blue.cloud};
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        min-height: 24px;
        min-width: 24px;
        position: relative;
        border-radius: 20px;
        right: 9px;

        ${(props) => positioning(props)};
    }
`);
