import { StyledTypography } from "ui-library/Styled";
import React, { FC, ReactNode } from "react";
import Markdown from "markdown-to-jsx";

import { HtmlLink, Link } from "ui-library/atoms/Link/Link";
import { UnorderedList } from "ui-library/atoms/UnorderedList/UnorderedList";
import { OrderedList } from "ui-library/atoms/OrderedList/OrderedList";

type TypographyProps = {
    children?: ReactNode;
    markDown?: string | ReactNode;
    variant?: string;
    color?: string;
    fontSize?: number;
    fontWeight?: number;
    lineHeight?: number;
    listVariant?: string;
    internalAppLink?: boolean;
    // TODO: martin.rodin this generic type should be removed later
    [prop: string]: any;
};

export const Typography: FC<TypographyProps> = ({
    children,
    markDown,
    variant,
    color,
    fontSize,
    fontWeight,
    lineHeight,
    listVariant,
    internalAppLink = false,
    ...props
}) => {
    const options = {
        forceBlock: true,
        overrides: {
            h1: {
                component: Typography,
                props: {
                    variant: variant,
                    color: color,
                },
            },
            h2: { component: Typography, props: { variant: variant, color: color } },
            h3: { component: Typography, props: { variant: variant, color: color } },
            h4: {
                component: Typography,
                props: { variant: variant, color: color },
            },
            span: {
                component: Typography,
                props: {
                    variant: variant,
                    color: color,
                    fontSize: fontSize,
                    fontWeight: fontWeight,
                    lineHeight: lineHeight,
                    component: "p",
                },
            },
            p: {
                component: Typography,
                props: {
                    variant: variant,
                    color: color,
                    fontSize: fontSize,
                    fontWeight: fontWeight,
                    lineHeight: lineHeight,
                    component: "p",
                },
            },
            i: {
                component: Typography,
                props: {
                    variant: variant,
                    color: color,
                    fontSize: fontSize,
                    fontWeight: fontWeight,
                    lineHeight: lineHeight,
                    component: "i",
                },
            },
            em: {
                component: Typography,
                props: {
                    variant: variant,
                    color: color,
                    fontSize: fontSize,
                    fontWeight: fontWeight,
                    lineHeight: lineHeight,
                    component: "em",
                },
            },
            b: {
                component: Typography,
                props: {
                    variant: variant,
                    color: color,
                    fontSize: fontSize,
                    fontWeight: fontWeight,
                    lineHeight: lineHeight,
                    component: "b",
                },
            },
            strong: {
                component: Typography,
                props: {
                    variant: variant,
                    color: color,
                    fontSize: fontSize,
                    fontWeight: fontWeight,
                    lineHeight: lineHeight,
                    component: "strong",
                },
            },
            a: {
                component: ({ classes, ...props }) => {
                    if (internalAppLink) {
                        return <Link to={props.href}>{props.children}</Link>;
                    } else {
                        return (
                            <HtmlLink href={props.href} target="_blank">
                                {props.children}
                            </HtmlLink>
                        );
                    }
                },
            },
            ul: {
                component: ({ classes, ...props }) => {
                    return <UnorderedList itemsVariant={listVariant}>{props.children}</UnorderedList>;
                },
            },
            li: {
                component: ({ classes, ...props }) => {
                    return <Typography>{props.children}</Typography>;
                },
            },
            ol: {
                component: ({ classes, ...props }) => {
                    return (
                        <OrderedList>
                            <Typography>{props.children}</Typography>
                        </OrderedList>
                    );
                },
            },
        },
    };

    return (
        <>
            {!markDown ? (
                <StyledTypography
                    variant={variant}
                    color={color}
                    fontSize={fontSize}
                    fontWeight={fontWeight}
                    lineHeight={lineHeight}
                    {...props}
                >
                    {children}
                </StyledTypography>
            ) : (
                <>{markDown && <Markdown options={options}>{markDown}</Markdown>}</>
            )}
        </>
    );
};
