import { Record } from "immutable";
import MutualFundsTransactionStatus from "./MutualFundsTransactionStatus";

class MutualFundsTransactionStatusDropdown extends Record({ id: null, statusCodes: null, msg: null }) {}

// you always need edit both enums MutualFundsTransactionStatus / MutualFundsTransactionStatusDropdown
export default Object.freeze({
    READY: new MutualFundsTransactionStatusDropdown({
        id: "READY",
        statusCodes: [MutualFundsTransactionStatus.NE.id, MutualFundsTransactionStatus.RE.id, MutualFundsTransactionStatus.CI.id],
        msg: "investments.transactionStatusReady",
    }),
    PROCESSED: new MutualFundsTransactionStatusDropdown({
        id: "PROCESSED",
        statusCodes: [MutualFundsTransactionStatus.PR.id, MutualFundsTransactionStatus.EX.id],
        msg: "investments.transactionStatusProcessed",
    }),
    COMPLETED: new MutualFundsTransactionStatusDropdown({
        id: "COMPLETED",
        statusCodes: [MutualFundsTransactionStatus.SE.id, MutualFundsTransactionStatus.CL.id],
        msg: "investments.transactionStatusCompleted",
    }),
    CANCELED: new MutualFundsTransactionStatusDropdown({
        id: "CANCELED",
        statusCodes: [
            MutualFundsTransactionStatus.RG.id,
            MutualFundsTransactionStatus.CA.id,
            MutualFundsTransactionStatus.RD.id,
            MutualFundsTransactionStatus.CM.id,
        ],
        msg: "investments.transactionStatusCanceled",
    }),
    ENDED: new MutualFundsTransactionStatusDropdown({
        id: "ENDED",
        statusCodes: [
            MutualFundsTransactionStatus.C.id,
            MutualFundsTransactionStatus.S.id,
            MutualFundsTransactionStatus.T.id,
            MutualFundsTransactionStatus.U.id,
            MutualFundsTransactionStatus.D.id,
        ],
        msg: "investments.transactionStatusEnded",
    }),
    ACTIVE: new MutualFundsTransactionStatusDropdown({
        id: "ACTIVE",
        statusCodes: [MutualFundsTransactionStatus.A.id, MutualFundsTransactionStatus.N.id, MutualFundsTransactionStatus.P.id],
        msg: "investments.transactionStatusActive",
    }),
});
