import { FC, PropsWithChildren, useState } from "react";
import { Chip, Icon, Typography } from "ui-library/atoms";
import { Box, Flexbox, InlineBox } from "ui-library/layouts";
import { StyledDroppableContractCard } from "ui-library/Styled";

export interface DroppableContractCardProps extends PropsWithChildren {
    title: string;
    contractNumber: string;
    chipLabel: any;
    description: any;
    action: any;
}

export const DroppableContractCard: FC<DroppableContractCardProps> = ({ title, contractNumber, chipLabel, description, children, action }) => {
    const [more, setMore] = useState(false);

    const handleClick = () => {
        setMore(!more);
    };

    return (
        <StyledDroppableContractCard $more={more}>
            <Box pb={1.5} className="wrapper">
                <Flexbox className="title" gap={3} onClick={handleClick}>
                    <Box mt={0.25}>
                        <Icon size={16} icon={more ? "chevron-up" : "chevron-down"} />
                    </Box>

                    <Flexbox gap={0.5} alignItems={"flex-start"} direction="column">
                        <Typography variant="caption">{title}</Typography>
                        <Typography color="gray" variant="caption">
                            {contractNumber}
                        </Typography>
                    </Flexbox>
                </Flexbox>
            </Box>
            {more && (
                <Flexbox ml={4.5} mt={2} direction="column" className="content">
                    <InlineBox mb={1}>
                        <Chip label={chipLabel} bgColor="blue" />
                    </InlineBox>
                    <Box mb={1}>
                        <Typography variant="h4">{description}</Typography>
                    </Box>

                    {children && <Box mb={1}>{children}</Box>}
                    {action && action}
                </Flexbox>
            )}
        </StyledDroppableContractCard>
    );
};
