import { Record } from "immutable";

const RedeemPensionDpsPensionTypes = Record({ id: null, msg: null, description: null });

export default Object.freeze({
    PRE_RETIREMENT: new RedeemPensionDpsPensionTypes({
        id: "PRE_RETIREMENT",
        msg: "pension.preRetirement",
        description: "pension.redeemSavings.dps.option.preRetirement",
    }),
    RETIREMENT_PENSION: new RedeemPensionDpsPensionTypes({
        id: "RETIREMENT_PENSION",
        msg: "pension.retirement",
        description: "pension.redeemSavings.dps.option.retirementPension",
    }),
    EARLY_PAYMENT: new RedeemPensionDpsPensionTypes({
        id: "EARLY_PAYMENT",
        msg: "pension.earlyPayment",
        description: "pension.redeemSavings.dps.option.earlyPayment",
    }),
});
