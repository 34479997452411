import { ProductLine, ProductPolicy } from "types";
import { ProductPolicies } from "./ProductPolicies";
import { czProductLines, skProductLines } from "./ProductLines";

export const getProductDefinition: (idEnuProductGroupLevelTwo: number, isSlovakSite: boolean) => ProductLine = (
    idEnuProductGroupLevelTwo,
    isSlovakSite,
) => {
    const productLines = isSlovakSite ? skProductLines : czProductLines;
    return productLines[idEnuProductGroupLevelTwo];
};

export const getProductPolicyByPGL2 = (idEnuProductGroupLevelTwo: number): ProductPolicy => {
    return Object.values(ProductPolicies).find((item) => item.productGroupLevelTwo === idEnuProductGroupLevelTwo);
};
