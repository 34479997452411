import { NAME } from "./constants";
import reducer from "./reducer";
import saga from "./saga";

export { default as PaymentNotification } from "./Container";

export default {
    NAME,
    reducer,
    saga,
};
