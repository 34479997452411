import { withTheme } from "@mui/styles";
import styled from "styled-components";
import { Chip } from "@mui/material";

const chipVariant = (props) => {
    switch (props.$bgColor) {
        case "blue":
            return `
        background :  ${props.theme.palette.blue.cloud};
        color : ${props.theme.palette.gray.main};
    `;

        default:
            return ``;
    }
};

export const StyledChip = withTheme(styled(Chip)`
    font-weight: 600;
    padding: 4px 8px;
    border-radius: 4px;
    height: 24px;
    ${(props) => chipVariant(props)};
    .MuiChip-label {
        padding: 0;
    }
`);
