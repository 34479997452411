import React from "react";
import PropTypes from "prop-types";

import { formatBirthNumber, localFullDate, oneLineNameWithTitles } from "core/formatters";
import i18n from "core/i18n";
import { getLocalizedOneLineAddressFull } from "core/localized";
import { model } from "core/util";
import { AddressType } from "enums";
import { Box } from "ui-library/layouts";
import { LabelTextItem } from "ui-library/modules";

const birthAndInsuranceNumberTitle = (birthNumber, showInsuranceNumber, insuranceNumber) =>
    showInsuranceNumber ? birthNumber.concat(" / ").concat(insuranceNumber) : birthNumber;

export const HolderPersonSectionLegacy = ({ holder, isExtended, showInsuranceNumber }) => {
    const { t } = i18n.useTranslation();
    const personId = model.getPersonIdLegacy(holder.ids, t);
    const birthSSNumberTile = birthAndInsuranceNumberTitle(t("holder.birthNumber"), showInsuranceNumber, t("holder.insuranceNumber"));

    return (
        <Box>
            <LabelTextItem label={t("holder.fullName")} text={oneLineNameWithTitles(holder)} />
            {isExtended && <LabelTextItem label={t("holder.citizenship")} text={holder.get("citizenship")} />}
            <LabelTextItem label={t("holder.birthDate")} text={localFullDate(holder.get("birthDate"))} />
            <LabelTextItem label={birthSSNumberTile} text={formatBirthNumber(holder.get("birthNumber"))} />
            {isExtended && <LabelTextItem label={personId.label} text={personId.number} />}
            <LabelTextItem
                label={t("holder.permanentAddress")}
                text={getLocalizedOneLineAddressFull(t, model.getAddressByTypeCodeLegacy(holder, AddressType.PER))}
            />
        </Box>
    );
};

HolderPersonSectionLegacy.propTypes = {
    holder: PropTypes.object.isRequired,
    isExtended: PropTypes.bool.isRequired,
    showInsuranceNumber: PropTypes.bool.isRequired,
};
