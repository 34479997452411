import { withTheme } from "@mui/styles";

import { Box } from "ui-library/layouts/Box/Box";
import styled from "styled-components";

export const StyledScreenMessageLayout = withTheme(styled(Box)`
    padding: 50px 0 550px;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        padding: 100px 0 100px;
    }
`);
