import { combineReducers } from "redux-immutable";

import { app } from "core/util";

export default (actions) => {
    const { SET_RESENT, SET_PHONE_NUMBER, CLEAR } = actions;

    const resent = (state = false, action) => {
        switch (action.type) {
            case SET_RESENT:
                return true;
            case CLEAR:
                return false;
            default:
                return state;
        }
    };

    return combineReducers({
        resent,
        phoneNumber: app.createDataReducer(SET_PHONE_NUMBER),
    });
};
