import { model } from "core/util";

import { AmountLegacy } from "../../common";

export const PensionDpsFundInvestmentsStatement = model.createModelLegacy("PensionDpsFundInvestmentsStatement", (json) => ({
    fundCode: json.FundCode,
    fundName: json.FundName,
    fundStatusCode: json.FundStatusCode,
    fundStatusValue: json.FundStatusValue,
    investmentsSumInvested: AmountLegacy.fromServer(json.InvestmentsSumInvested),
    investmentsSumNotInvested: AmountLegacy.fromServer(json.InvestmentsSumNotInvested),
    sumShares: json.SumShares,
    pricePerShare: AmountLegacy.fromServer(json.PricePerShare),
    investmentsValue: AmountLegacy.fromServer(json.InvestmentsValue),
    valuationPercentage: json.ValuationPercentage,
    actualInvestmentsValue: AmountLegacy.fromServer(json.ActualInvestmentsValue),
}));
