export const NAME = "pensionBeneficiaries";

export const BENEFICIARIES_FIELD = "beneficiaries";

export const MAX_BENEFICIARIES = 10;

// form fields
export const ADDRESS_AUTOCOMPLETE = "autocomplete.address";

export const STREET_FORM_FIELD = "address.street";
export const DESCRIPTION_NUMBER_FORM_FIELD = "address.descriptionNumber";
export const ORIENTATION_NUMBER_FORM_FIELD = "address.orientationNumber";
export const CITY_FORM_FIELD = "address.city";
export const ZIP_FORM_FIELD = "address.zip";
export const STATE_FORM_FIELD = "address.state";
