import { Record } from "immutable";

const ClientType = Record({ id: null, msg: null });

export default Object.freeze({
    INDIVIDUAL: new ClientType({
        id: "INDIVIDUAL",
        msg: "common.individual",
    }),
    COMPANY: new ClientType({
        id: "COMPANY",
        msg: "common.legalEntity",
    }),
});
