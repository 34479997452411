import { Map } from "immutable";
import { combineReducers } from "redux-immutable";

import { app } from "core/util";

import { EPIN_PHASE_ISSUE } from "./constants";

export default (actions) => {
    const { SET_EPIN_PHASE, SET_EPIN_ISSUE_ERROR, SET_EPIN_PASSWORD_ERROR, SET_EPIN_VERIFICATION_ERROR, SET_DUPLICATE_CONTRACT_NUMBER } =
        actions;

    const initialEpinData = Map({
        contract: null,
        phone: null,
        password: null,
        axaUuid: null,
        otpId: null,
    });

    const setEpinPhase = (state = EPIN_PHASE_ISSUE, action) => {
        switch (action.type) {
            case SET_EPIN_PHASE:
                return action.meta.phase;
            default:
                return state;
        }
    };

    const epinData = (state = initialEpinData, { type, meta, payload }) => {
        switch (type) {
            case SET_EPIN_PHASE:
                return meta.phase === EPIN_PHASE_ISSUE ? initialEpinData : state.merge(payload);
            default:
                return state;
        }
    };

    const setDuplicateContractNumber = (state = null, { type, payload }) => {
        switch (type) {
            case SET_DUPLICATE_CONTRACT_NUMBER:
                return payload;
            default:
                return state;
        }
    };

    return combineReducers({
        epinPhase: setEpinPhase,
        epinData: epinData,
        duplicateContractNumber: setDuplicateContractNumber,
        epinIssueError: app.createDataReducer(SET_EPIN_ISSUE_ERROR),
        epinPasswordError: app.createDataReducer(SET_EPIN_PASSWORD_ERROR),
        epinVerificationError: app.createDataReducer(SET_EPIN_VERIFICATION_ERROR),
    });
};
