import React from "react";
import PropTypes from "prop-types";

import { fn } from "core/util";
import { FileInfo } from "ui-library/modules";

export const DocumentLink = ({ fileName, fileUrl, isReady }) => (
    <FileInfo
        disabled={!isReady}
        tableVariant
        icon={isReady ? "download" : "horizontal-dots"}
        fileName={fileName}
        onClick={isReady ? () => window.open(fileUrl) : fn.noop}
    />
);

DocumentLink.propTypes = {
    fileName: PropTypes.string.isRequired,
    fileUrl: PropTypes.string.isRequired,
    isReady: PropTypes.bool,
};

DocumentLink.defaultProps = {
    isReady: true,
};
