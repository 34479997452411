import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Modal, ModalHeader } from "ui-library/modules";

import modal from "core/modal";
import { ModalErrorBoundary } from "core/errorHandling";
import { isOtpFormSuccessfullySubmitted, isSubmitting } from "core/form";

import { ModalEditFormError } from "./ModalEditFormError";
import { ModalSuccess } from "./ModalSuccess";
import { ModalFormLayoutProps } from "./ModalFormLayout";

export const ModalOtpFormLayout: FC<ModalFormLayoutProps> = ({
    children,
    formName,
    formTitle,
    formIcon,
    SuccessComponent = ModalSuccess,
}) => {
    const dispatch = useDispatch();

    const isModalFormOpen = useSelector(modal.isModalFormOpen(formName));
    const isFormSubmitted = useSelector(isOtpFormSuccessfullySubmitted(formName));
    const isFormSubmitting = useSelector(isSubmitting(formName));

    const closeModalForm = () => {
        if (!isFormSubmitting) {
            dispatch(modal.closeModalForm(formName));
        }
    };

    return (
        <Modal size={"small"} open={isModalFormOpen} onClose={closeModalForm}>
            <ModalHeader closeAction={closeModalForm} icon={formIcon} title={formTitle} />
            <ModalErrorBoundary ErrorComponent={ModalEditFormError} closeModal={closeModalForm} service={formName}>
                {isFormSubmitted ? <SuccessComponent closeModal={closeModalForm} /> : children}
            </ModalErrorBoundary>
        </Modal>
    );
};
