import { model } from "core/util";

import { AmountLegacy } from "../common";
import { PensionDssDdsInvestmentsBasedOnFund } from "./PensionDssDdsInvestmentsBasedOnFund";
import { PensionDssDdsContractBalanceSheet } from "./PensionDssDdsContractBalanceSheet";

export const PensionDssDdsFinancialStatement = model.createModelLegacy("PensionDssDdsFinancialStatement", (json) => ({
    date: json.Date,
    sumNumberOfShares: json.SumNumberOfShares,
    actualInvestmentsValue: AmountLegacy.fromServer(json.ActualInvestmentsValue),
    pensionInvestmentsBasedOnFund: PensionDssDdsInvestmentsBasedOnFund.fromServerList(json.PensionInvestmentsBasedOnFund),
    contractBalanceSheet: PensionDssDdsContractBalanceSheet.fromServer(json.ContractBalanceSheet),
}));
