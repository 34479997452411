import React from "react";

import { Typography } from "ui-library/atoms";
import { ContentBox } from "ui-library/layouts";

import i18n from "core/i18n";

export default () => {
    const { t } = i18n.useTranslation();
    return (
        <ContentBox>
            <Typography component="p" markDown={t("pension.fundsChange.unprocessedRequestExists")} />
        </ContentBox>
    );
};
