import React, { FC } from "react";
import { useSelector } from "react-redux";
import moment from "moment/moment";

import { Box, GridItem } from "ui-library/layouts";
import { DataTable } from "ui-library/modules";
import { Icon } from "ui-library/atoms";

import { date as dateUtil, model } from "core/util";
import i18n, { TFunction } from "core/i18n";
import entity from "core/entity";
import fetching from "core/fetching";
import userContracts from "core/userContracts";
import { localFullDate } from "core/formatters";

import { DEFAULT_TABLE_PAGE_SIZE, DEFAULT_TABLE_ROWS_OPTIONS } from "app/constants";
import { documentsApi } from "serverApi";

import { DocumentLink } from "containers/DocumentLink";
import { CodeValue, ContractPolicy } from "types";
import { ContractStatus } from "enums";

const STATUS_PROCESSED = "PROCESSED";

const translatedName = (codeValue: CodeValue, locale: string) => model.getLocaleCodeName(codeValue, locale);

const isDocumentReady = (documentStatus) => documentStatus?.code === STATUS_PROCESSED;

const href = (idObject: number, idDocument: string) => documentsApi.getContractEnqueuedDocumentDownloadUrl(idObject, idDocument);

const columns = (t: TFunction, locale: string, idObject: number) => [
    {
        field: "type",
        headerName: t("documents.documentName"),
        flex: 3,
        sortComparator: (v1, v2) => v1?.code.localeCompare(v2.code),
        renderCell: (params) => (
            <DocumentLink
                fileName={translatedName(params.value, locale)}
                fileUrl={href(idObject, params.row.id)}
                isReady={isDocumentReady(params.row.status)}
            />
        ),
    },
    {
        field: "lastChange",
        headerName: t("common.type"),
        width: 150,
        flex: 0.5,
        renderCell: (params) => "PDF",
    },
    {
        field: "requested",
        headerName: t("common.date"),
        width: 110,
        flex: 1,
        renderCell: (params) => localFullDate(params.value),
    },
    {
        field: "status",
        headerName: t("common.status"),
        width: 110,
        flex: 1,
        sortComparator: (v1, v2) => v1?.code.localeCompare(v2.code),
        renderCell: (params) =>
            isDocumentReady(params.value) ? <Icon icon={"check"} color={"green"} /> : translatedName(params.value, locale),
    },
];

export interface ContractEnqueueDocumentsGridItemProps {
    entityName: string;
    idObject: number;
}

export const ContractEnqueueDocumentsGridItem: FC<ContractEnqueueDocumentsGridItemProps> = ({ entityName, idObject }) => {
    const { t } = i18n.useTranslation();
    const locale = i18n.getLanguageCode();

    const isLoading = useSelector(fetching.isFetching(entityName, true));
    const isError = useSelector(entity.isError(entityName));
    const enqueuedDocuments = useSelector(entity.getData(entityName));
    const mappedEnqueuedDocuments = enqueuedDocuments && enqueuedDocuments.toArray();

    const contract = userContracts.useGetContractById(idObject);
    const { isFutureContract, isEndedNotThisOrLastYear } = userContracts.useGetContractDerivedData(contract);

    if (isFutureContract || isEndedNotThisOrLastYear) {
        return null;
    }

    return (
        <GridItem xs={12}>
            <Box fullWidth>
                <DataTable
                    icon="document"
                    title={t("documents.contractEnqueuedDocuments")}
                    disableColumnReorder
                    pageSize={DEFAULT_TABLE_PAGE_SIZE}
                    rowsPerPageOptions={DEFAULT_TABLE_ROWS_OPTIONS}
                    getRowId={(row) => row.id}
                    rows={mappedEnqueuedDocuments}
                    columns={columns(t, locale, idObject)}
                    emptyMsg={t("documents.noDocuments")}
                    errorMsg={t("error.sectionUnavailable")}
                    isLoading={isLoading}
                    isError={isError}
                />
            </Box>
        </GridItem>
    );
};
