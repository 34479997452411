import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { withTheme } from "@mui/styles";
import styled from "styled-components";

const verticalBorder = (props) => {
    if (props.$vertical) {
        return ` border-left: 2px solid ${props.theme.palette.blue.main}`;
    }
};

export const StyledTableContainer = withTheme(styled(TableContainer)``);

export const StyledTable = withTheme(styled(Table)`
    ${(props) => verticalBorder(props)};
`);

export const StyledTableHead = withTheme(styled(TableHead)`
    border-top: 2px solid ${(props) => props.theme.palette.blue.main};

    // na urovni headu vyzadujem styling head aj ked zada ako body

    .MuiTableCell-head {
        font-family: Lato;
        font-size: 16px;
        font-weight: 700;
    }
    .MuiTableCell-body {
        font-family: Lato;
        font-size: 16px;
        font-weight: 700;
    }
`);
export const StyledTableBody = withTheme(styled(TableBody)`
    .MuiTableCell-head {
        font-family: Lato;
        font-size: 16px;
        font-weight: 700;
    }
    .MuiTableCell-body {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
            "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 16px;
    }
`);

export const StyledTableRow = withTheme(styled(TableRow)`
    &:hover {
        background: ${(props) => props.theme.palette.blue.soft};
    }
`);
export const StyledTableCell = withTheme(styled(TableCell)`
    padding: 9px;
    min-height: 42px;
`);
