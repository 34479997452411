import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import i18n from "core/i18n";
import { Field, form, InputText, numberCharacters, required } from "core/form";
import { Button, ErrorContent, Loading } from "ui-library/atoms";
import { BannerFlexbox, Box, Flexbox } from "ui-library/layouts";

export const IssueEpinFormContainer = ({ handleSubmit, canSubmit, submitting, selectors }) => {
    const { t } = i18n.useTranslation();
    const isContractNumberDuplicity = useSelector(selectors.getDuplicateContractNumber);
    const epinIssueError = useSelector(selectors.getEpinIssueError);

    return (
        <>
            <Loading fullPage loading={submitting} />

            <BannerFlexbox>
                <Box>
                    <Field
                        name="contractNumber"
                        component={InputText}
                        size="large"
                        label={t("common.contractNumber")}
                        onEnter={handleSubmit}
                        validateStatic={[required]}
                        normalize={[numberCharacters]}
                    />
                </Box>

                {isContractNumberDuplicity && (
                    <Box>
                        <Field
                            name="birthNumberOrCompanyId"
                            component={InputText}
                            size="large"
                            label={t("holder.birthNumberAndID")}
                            onEnter={handleSubmit}
                            validateStatic={[required]}
                            normalize={[numberCharacters]}
                        />
                    </Box>
                )}

                <Box mt={{ xs: 0, lg: 3.625 }}>
                    <Button color="green" variant="contained" size={"large"} onClick={handleSubmit} disabled={!canSubmit}>
                        {t("common.continue")}
                    </Button>
                </Box>
            </BannerFlexbox>

            {epinIssueError && (
                <Flexbox alignItems={"top"} gap={2} mt={2}>
                    <ErrorContent content={epinIssueError} />
                </Flexbox>
            )}
        </>
    );
};

IssueEpinFormContainer.propTypes = {
    selectors: PropTypes.object.isRequired,
    canSubmit: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
};

export const createIssueEpinForm = (formName, selectors) =>
    form(formName)((props) => <IssueEpinFormContainer selectors={selectors} {...props} />);
