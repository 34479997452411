import { Button, Container, Typography } from "ui-library/atoms";
import { ContentBox, GridItem, GridLayout, LeadOffsetLayout } from "ui-library/layouts";
import { InfoBox } from "ui-library/modules";

import i18n from "core/i18n";
import { useGetSiteValue } from "core/hooks";
import { ExternalUrl } from "enums";

export const LeadPanels = () => {
    const { t } = i18n.useTranslation();
    const getSiteValue = useGetSiteValue();

    return (
        <Container>
            <LeadOffsetLayout>
                <GridLayout defaultSpacing>
                    <GridItem display="flex" justifyContent={"center"} xs={12} md={4}>
                        <InfoBox
                            icon={"plane"}
                            title={t("common.eRegistry")}
                            actionButton={
                                <Button
                                    variant={"outlined"}
                                    color={"blue"}
                                    startIcon={"link"}
                                    newTab
                                    externalLink={getSiteValue(ExternalUrl.E_REGISTRY)}
                                >
                                    {t("common.sendDocuments")}
                                </Button>
                            }
                        >
                            <ContentBox>
                                <Typography variant="caption" color={"gray"} markDown={t("page.contact.panel.eRegistry")} />
                            </ContentBox>
                        </InfoBox>
                    </GridItem>

                    <GridItem display="flex" justifyContent={"center"} xs={12} md={4}>
                        <InfoBox
                            icon={"headset"}
                            title={t("common.assistanceServices")}
                            actionButton={
                                <Button
                                    variant={"outlined"}
                                    color={"blue"}
                                    startIcon={"link"}
                                    newTab
                                    externalLink={getSiteValue(ExternalUrl.ASSISTANCE_SERVICES)}
                                >
                                    {t("common.showContacts")}
                                </Button>
                            }
                        >
                            <ContentBox>
                                <Typography variant="caption" color={"gray"} markDown={t("page.contact.panel.assistanceServices")} />
                            </ContentBox>
                        </InfoBox>
                    </GridItem>

                    <GridItem display="flex" justifyContent={"center"} xs={12} md={4}>
                        <InfoBox
                            icon={"map"}
                            title={t("common.branchesMap")}
                            actionButton={
                                <Button
                                    variant={"outlined"}
                                    color={"blue"}
                                    startIcon={"link"}
                                    newTab
                                    externalLink={getSiteValue(ExternalUrl.BRANCHES_MAP)}
                                >
                                    {t("common.findBranch")}
                                </Button>
                            }
                        >
                            <ContentBox>
                                <Typography variant="caption" color={"gray"} markDown={t("page.contact.panel.branches")} />
                            </ContentBox>
                        </InfoBox>
                    </GridItem>
                </GridLayout>
            </LeadOffsetLayout>
        </Container>
    );
};
