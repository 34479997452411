import { withTheme } from "@mui/styles";
import { Box } from "ui-library/layouts/Box/Box";
import styled from "styled-components";
import { StyledBlock } from "./Blocks";

export const StyledLeadLayout = withTheme(styled(StyledBlock)`
    padding: 48px;
    margin-bottom: 64px;
    position: relative;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        padding: 24px;
        margin-bottom: 48px;
        border: none;
        background: transparent;
        box-shadow: none;
        padding: 0;
        margin-bottom: 16px;
    }
    img {
        position: absolute;
        right: 20px;
        bottom: 0;
        z-index: 0;
        max-width: 468px;
        width: 100%;
        object-fit: unset;
        height: auto;
        max-height: 100%;
        @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
            position: relative;
            right: 0;
            margin-left: auto;
        }
    }
`);

export const StyledLeadImageWrapper = withTheme(styled(Box)`
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 100%;
        text-align: right;
    }
    img {
        @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
            position: relative;
            right: 0;
        }
    }
`);
