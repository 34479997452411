import React from "react";
import { useSelector } from "react-redux";

import { Loading, Typography } from "ui-library/atoms";
import { ContentBox, GridLayout } from "ui-library/layouts";

import i18n from "core/i18n";

import { TAX_STATEMENT, YEARLY_STATEMENT } from "./constants";
import { MessageViewer, SubmitButton, YearSelect } from "./components";
import { STATEMENT_TEXT } from "./messages";

export default (getId, actions, selectors, documentType, fetchAvailableYears, getPolicyBeginDateYear, yearToRemoveFromList) => {
    return () => {
        const { t } = i18n.useTranslation();
        const isError = useSelector(selectors.isError);
        const showForm = useSelector(selectors.showForm);
        const submitting = useSelector(selectors.isSubmitting);

        const hasYearSelect = documentType === YEARLY_STATEMENT || documentType === TAX_STATEMENT;

        return (
            showForm && (
                <Loading loading={submitting}>
                    <ContentBox>
                        <Typography component="p">{t(STATEMENT_TEXT[documentType])}</Typography>
                    </ContentBox>

                    {isError && (
                        <ContentBox>
                            <Typography component="p">{t("error.serviceUnavailable")}</Typography>
                        </ContentBox>
                    )}

                    {!isError && hasYearSelect ? (
                        <GridLayout spacing={1} alignItems="end">
                            <YearSelect
                                getId={getId}
                                getPolicyBeginDateYear={getPolicyBeginDateYear}
                                selectChange={actions.changeData}
                                getSelectedYear={selectors.getSelectedYear}
                                getAvailableYears={selectors.getAvailableYears}
                                fetchAvailableYears={fetchAvailableYears}
                                yearToRemoveFromList={yearToRemoveFromList}
                            />
                            <SubmitButton submitAction={actions.statementsSubmit} />
                        </GridLayout>
                    ) : (
                        <SubmitButton submitAction={actions.statementsSubmit} />
                    )}

                    <MessageViewer getMessagesFromState={selectors.getMessagesFromState} internalAppLink />
                </Loading>
            )
        );
    };
};
