import React, { FC } from "react";
import { useParams } from "react-router-dom";

import { GridLayout } from "ui-library/layouts";

import { AgentDetailGridItem } from "containers/contract";

import { ChangePropositionModule } from "../modules";

export const AgentTab: FC = () => {
    const { idObject } = useParams();
    return (
        <GridLayout defaultSpacing>
            <ChangePropositionModule.Container />

            <AgentDetailGridItem idObject={Number(idObject)} />
        </GridLayout>
    );
};
