import React, { FC } from "react";
import { useSelector } from "react-redux";

import i18n from "core/i18n";
import { Box } from "ui-library/layouts";
import { Container, ImportantAnnouncements } from "ui-library/atoms";

import errorHandling from "core/errorHandling";
import { ErrorDateAndIdentifier } from "../ErrorDateAndIdentifier";

export interface FullPageEditFormErrorProps {
    service: string;
    title?: string;
    BackButton?: any;
}

export const FullPageEditFormError: FC<FullPageEditFormErrorProps> = ({ service, title, BackButton }) => {
    const { t } = i18n.useTranslation();
    const errorIdentifiers = useSelector(errorHandling.getErrorsForService(service));

    return (
        <Container maxWidth="md">
            <Box mb={5}>
                {errorIdentifiers.map((errorIdentifier, i) => (
                    <ImportantAnnouncements
                        key={i}
                        shadow
                        variant={"ERROR"}
                        data={{
                            heading: title || t("error.editFormUnavailable.title"),
                            content: (
                                <ErrorDateAndIdentifier errorMsgKey="error.editFormUnavailable.text" errorIdentifier={errorIdentifier} />
                            ),
                        }}
                    />
                ))}
            </Box>
            <Box mb={30}>{BackButton}</Box>
        </Container>
    );
};
