import { model } from "core/util";
import { PensionDdsAccountDetailDto, PensionDdsAccountDetail as PensionDdsAccountDetailType } from "types";

import { CodeValue, PaymentsScheduler } from "../../common";
import { PensionDssDdsFundInvestmentStrategy } from "../PensionDssDdsFundInvestmentStrategy";

export const PensionDdsAccountDetail = model.createModel<PensionDdsAccountDetailDto, PensionDdsAccountDetailType>((json) => ({
    paymentsScheduler: PaymentsScheduler.fromServer(json.PaymentsScheduler),
    batchPlan: CodeValue.fromServer(json.BatchPlan),
    pensionAccountEstablishmentDate: json.PensionAccountEstablishmentDate,
    isTransferredContract: json.IsTransferredContract,
    contractStatus: CodeValue.fromServer(json.ContractStatus),
    fundsInvestmentStrategies: PensionDssDdsFundInvestmentStrategy.fromServerList(json.FundsInvestmentStrategies),
    statementPassword: json.StatementPassword,
    isPassive: json.IsPassive,
}));
