import { withTheme } from "@mui/styles";
import styled from "styled-components";
import { Box, Container } from "@mui/material";

const blocksVariant = (props) => {
    switch (props.variant) {
        case "default":
            return `
        background : ${props.theme.palette.white.main};
        color : ${props.theme.palette.black.main};
    `;
        case "blue":
            return `
        background :  ${props.theme.palette.blue.main};
        color : ${props.theme.palette.white.main};
    `;
        case "muddy":
            return `
        background :  ${props.theme.palette.blue.muddy};
        color : ${props.theme.palette.white.main};
    `;
        case "sky":
            return `
        background :  ${props.theme.palette.blue.sky};
        color : ${props.theme.palette.white.main};
    `;
        case "tyrkys":
            return `
        background :  ${props.theme.palette.blue.tyrkys};
        color : ${props.theme.palette.white.main};
    `;
        case "cloud":
            return `
        background :  ${props.theme.palette.blue.cloud};
        color : ${props.theme.palette.black.main};
    `;
        case "blueGradient":
            return `
        background :  ${props.theme.palette.blue.gradient};
        color : ${props.theme.palette.black.main};
        border : 1px solid ${props.theme.palette.blue.king};
    `;

        case "grayGradient":
            return `
        background :  ${props.theme.palette.gray.gradient};
        color : ${props.theme.palette.black.main};
        border : 1px solid ${props.theme.palette.gray.softBorder};
    `;

        case "grayWhite":
            return `
        background :  ${props.theme.palette.white.main};
        color : ${props.theme.palette.black.main};
        border : 1px solid ${props.theme.palette.gray.line};
    `;

        case "greenGradient":
            return `
        background :  ${props.theme.palette.green.gradient};
        color : ${props.theme.palette.black.main};
        border : 1px solid ${props.theme.palette.green.success};
    `;

        case "redGradient":
            return `
        background : ${props.theme.palette.red.gradient};
        color : ${props.theme.palette.black.main};
        border : 1px solid ${props.theme.palette.red.main};
    `;

        case "blueSoft":
            return `
        background :  ${props.theme.palette.blue.soft};
        color : ${props.theme.palette.black.main};
    `;

        case "white":
            return `
        background :  ${props.theme.palette.white.main};
        color : ${props.theme.palette.black.main};
        border : 1px solid ${props.theme.palette.gray.line};
    `;

        case "clean":
            return `
            background :  ${props.theme.palette.white.main};
            border : none;
        `;

        case "transparent":
            return `
         background : transparent;
         `;

        default:
            return ``;
    }
};

const containerVariant = (props) => {
    switch (props.variant) {
        case "blue":
            return `
        background :  ${props.theme.palette.blue.main};
        color : ${props.theme.palette.white.main};
    `;
        case "cloud":
            return `
        background :  ${props.theme.palette.blue.cloud};
        color : ${props.theme.palette.black.main};
    `;
        case "blueSoft":
            return `
        background :  ${props.theme.palette.blue.soft};
        color : ${props.theme.palette.black.main};
    `;
        case "white":
            return `
        background :  ${props.theme.palette.white.main};
        color : ${props.theme.palette.black.main};
        border : 1px solid ${props.theme.palette.gray.line};
    `;

        default:
            return ``;
    }
};

export const StyledContainer = withTheme(styled(Container)`
    ${(props) => containerVariant(props)};

    .MuiContainer-maxWidthMd {
        max-width: 968px;
    }
`);

export const StyledBlock = withTheme(styled(Box)`
    border-radius: 4px;
    ${(props) => blocksVariant(props)};
    box-shadow: ${(props) => (props.shadow ? " 0px 20px 35px rgba(48, 49, 61, 0.1)" : "")};
    padding: ${(props) => (props.padding ? " 24px" : "")};
`);

export const StyledOffsetImageWrapper = withTheme(styled(Box)`
    width: 100%;
    position: relative;
    top: -200px;
    left: 0;
    text-align: ${(props) => (props.$contentAlign === "right" ? "right" : props.$contentAlign === "left" ? "left" : "center")};

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        position: relative;
        top: 0;
    }
    img {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
        height: auto;
        object-fit: unset;
        max-width: 456px;
        max-height: 456px;
        @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
            position: relative;
        }
    }
`);
