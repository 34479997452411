import { Record } from "immutable";

class UniqaLineOfBusiness extends Record({ id: null, msg: null, img: null }) {}

export default Object.freeze({
    SK_CASCO: new UniqaLineOfBusiness({
        id: "SK_CASCO",
        msg: "uniqa.lineOfBusiness.casco",
        img: "car",
    }),
    SK_MTPL: new UniqaLineOfBusiness({
        id: "SK_MTPL",
        msg: "uniqa.lineOfBusiness.mtpl",
        img: "car",
    }),
    SK_PROPERTY: new UniqaLineOfBusiness({
        id: "SK_PROPERTY",
        msg: "uniqa.lineOfBusiness.property",
        img: "property",
    }),
    SK_LIABILITY: new UniqaLineOfBusiness({
        id: "SK_LIABILITY",
        msg: "uniqa.lineOfBusiness.liability",
        img: "property",
    }),
    SK_DAS: new UniqaLineOfBusiness({
        id: "SK_DAS",
        msg: "uniqa.lineOfBusiness.das",
        img: "property",
    }),
    SK_HEALTH: new UniqaLineOfBusiness({
        id: "SK_HEALTH",
        msg: "uniqa.lineOfBusiness.medicalInsurance",
        img: "life",
    }),
    SK_ACCIDENT: new UniqaLineOfBusiness({
        id: "SK_ACCIDENT",
        msg: "uniqa.lineOfBusiness.accident",
        img: "life",
    }),
    SK_LIFE: new UniqaLineOfBusiness({
        id: "SK_LIFE",
        msg: "uniqa.lineOfBusiness.lifeInsurance",
        img: "life",
    }),
    SK_TRAVEL: new UniqaLineOfBusiness({
        id: "SK_TRAVEL",
        msg: "uniqa.lineOfBusiness.travel",
        img: "travel",
    }),
    SK_BANK: new UniqaLineOfBusiness({
        id: "SK_BANK",
        msg: "uniqa.lineOfBusiness.bank",
        img: "property",
    }),
    CZ_CASCO: new UniqaLineOfBusiness({
        id: "CZ_CASCO",
        msg: "uniqa.lineOfBusiness.casco",
        img: "car",
    }),
    CZ_MTPL: new UniqaLineOfBusiness({
        id: "CZ_MTPL",
        msg: "uniqa.lineOfBusiness.mtpl",
        img: "car",
    }),
    CZ_PROPERTY: new UniqaLineOfBusiness({
        id: "CZ_PROPERTY",
        msg: "uniqa.lineOfBusiness.property",
        img: "property",
    }),
    CZ_LIABILITY: new UniqaLineOfBusiness({
        id: "CZ_LIABILITY",
        msg: "uniqa.lineOfBusiness.liability",
        img: "property",
    }),
    CZ_ACCIDENT: new UniqaLineOfBusiness({
        id: "CZ_ACCIDENT",
        msg: "uniqa.lineOfBusiness.accident",
        img: "life",
    }),
    CZ_LIFE: new UniqaLineOfBusiness({
        id: "CZ_LIFE",
        msg: "uniqa.lineOfBusiness.lifeInsurance",
        img: "life",
    }),
    CZ_INJURY220: new UniqaLineOfBusiness({
        id: "CZ_INJURY220",
        msg: "uniqa.lineOfBusiness.careFamilyMember",
        img: "life",
    }),
    CZ_INJURY240: new UniqaLineOfBusiness({
        id: "CZ_INJURY240",
        msg: "uniqa.lineOfBusiness.careFamilyMember",
        img: "life",
    }),
    CZ_INJURY250: new UniqaLineOfBusiness({
        id: "CZ_INJURY250",
        msg: "uniqa.lineOfBusiness.careFamilyMember",
        img: "life",
    }),
    CZ_BUFT: new UniqaLineOfBusiness({
        id: "CZ_BUFT",
        msg: "uniqa.lineOfBusiness.operationalHalt",
        img: "life",
    }),
    CZ_HEALTH250_1: new UniqaLineOfBusiness({
        id: "CZ_HEALTH250_1",
        msg: "uniqa.lineOfBusiness.medicalInsurance",
        img: "life",
    }),
    CZ_HEALTH250_2: new UniqaLineOfBusiness({
        id: "CZ_HEALTH250_2",
        msg: "uniqa.lineOfBusiness.medicalInsurance",
        img: "life",
    }),
    CZ_HEALTH250_3: new UniqaLineOfBusiness({
        id: "CZ_HEALTH250_3",
        msg: "uniqa.lineOfBusiness.medicalInsurance",
        img: "life",
    }),
    CZ_HEALTH250_4: new UniqaLineOfBusiness({
        id: "CZ_HEALTH250_4",
        msg: "uniqa.lineOfBusiness.medicalInsurance",
        img: "life",
    }),
    CZ_HEALTH240: new UniqaLineOfBusiness({
        id: "CZ_HEALTH240",
        msg: "uniqa.lineOfBusiness.medicalInsurance",
        img: "life",
    }),
    CZ_LIFE220_1: new UniqaLineOfBusiness({
        id: "CZ_LIFE220_1",
        msg: "uniqa.lineOfBusiness.lifeInsurance",
        img: "life",
    }),
    CZ_LIFE220_2: new UniqaLineOfBusiness({
        id: "CZ_LIFE220_2",
        msg: "uniqa.lineOfBusiness.lifeInsurance",
        img: "life",
    }),
    CZ_LIFE220_3: new UniqaLineOfBusiness({
        id: "CZ_LIFE220_3",
        msg: "uniqa.lineOfBusiness.lifeInsurance",
        img: "life",
    }),
    CZ_LIFE222_1: new UniqaLineOfBusiness({
        id: "CZ_LIFE222_1",
        msg: "uniqa.lineOfBusiness.lifeInsurance",
        img: "life",
    }),
    CZ_LIFE222_2: new UniqaLineOfBusiness({
        id: "CZ_LIFE222_2",
        msg: "uniqa.lineOfBusiness.lifeInsurance",
        img: "life",
    }),
    CZ_LIFE222_3: new UniqaLineOfBusiness({
        id: "CZ_LIFE222_3",
        msg: "uniqa.lineOfBusiness.lifeInsurance",
        img: "life",
    }),
    CZ_LIFE240: new UniqaLineOfBusiness({
        id: "CZ_LIFE240",
        msg: "uniqa.lineOfBusiness.lifeInsurance",
        img: "life",
    }),
    CZ_LIFE250: new UniqaLineOfBusiness({
        id: "CZ_LIFE250",
        msg: "uniqa.lineOfBusiness.lifeInsurance",
        img: "life",
    }),
    CZ_TRAVEL: new UniqaLineOfBusiness({
        id: "CZ_TRAVEL",
        msg: "uniqa.lineOfBusiness.travel",
        img: "travel",
    }),
    CZ_PPI: new UniqaLineOfBusiness({
        id: "CZ_PPI",
        msg: "uniqa.lineOfBusiness.bank",
        img: "life",
    }),
    CZ_LIFE220_4: new UniqaLineOfBusiness({
        id: "CZ_LIFE220_4",
        msg: "uniqa.lineOfBusiness.lifeInsurance",
        img: "life",
    }),
    CZ_LIFE220_5: new UniqaLineOfBusiness({
        id: "CZ_LIFE220_5",
        msg: "uniqa.lineOfBusiness.lifeInsurance",
        img: "life",
    }),
    CZ_LIFE222_4: new UniqaLineOfBusiness({
        id: "CZ_LIFE222_4",
        msg: "uniqa.lineOfBusiness.lifeInsurance",
        img: "life",
    }),
    CZ_LIFE222_5: new UniqaLineOfBusiness({
        id: "CZ_LIFE222_5",
        msg: "uniqa.lineOfBusiness.lifeInsurance",
        img: "life",
    }),
    CZ_LIFE222_6: new UniqaLineOfBusiness({
        id: "CZ_LIFE222_6",
        msg: "uniqa.lineOfBusiness.lifeInsurance",
        img: "life",
    }),
});
