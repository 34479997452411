import { Record } from "immutable";

class DdsFunds extends Record({ id: null, fundCode: null, fundName: null, fundColor: null, fundLink: null }) {}

export default Object.freeze({
    CONSERVATIVE: new DdsFunds({
        id: "1",
        fundCode: "1",
        fundColor: "greenMain",
        fundLink: "fundLink.dds.contributory",
    }),
    BOND: new DdsFunds({
        id: "2",
        fundCode: "2",
        fundColor: "blueMain",
        fundLink: "fundLink.dds.global",
    }),
    BALANCED: new DdsFunds({
        id: "3",
        fundCode: "3",
        fundColor: "pinkMain",
        fundLink: "fundLink.dds.balanced",
    }),
    INDEXGLOBAL: new DdsFunds({
        id: "4",
        fundCode: "4",
        fundColor: "orangeMain",
        fundLink: "fundLink.dds.indexglobal",
    }),
});
