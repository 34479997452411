import slice from "./slice";
import * as selectors from "./selectors";

export * from "./constants";

export { ErrorBoundary } from "./ErrorBoundary";
export { FullPageErrorBoundary } from "./FullPageErrorBoundary";
export { ModalErrorBoundary } from "./ModalErrorBoundary";

const errorHandling = {
    NAME: slice.name,
    reducer: slice.reducer,
    ...slice.actions,
    ...selectors,
};

export default errorHandling;
