import React from "react";
import { useSelector } from "react-redux";

import { Loading, Typography } from "ui-library/atoms";
import { GridItem } from "ui-library/layouts";
import { InfoPanel } from "ui-library/modules";

import entity from "core/entity";
import fetching from "core/fetching";
import i18n from "core/i18n";
import modal from "core/modal";
import { ChangePropositionDetail } from "modules/changePropositions/containers/modalDetail";

import { ChangePropositionsTables } from "./containers";
import { MODAL_CHANGE_PROPOSITION_DETAIL } from "./constants";

export default (propositionsEntity, actions, selectors, idObjectSelector, contractConstant) => {
    const ChangePropositionsCollapseSection = () => {
        const { t } = i18n.useTranslation();
        const isLoading = useSelector(fetching.isFetching(propositionsEntity, true));
        const isError = useSelector(entity.isError(propositionsEntity));
        const isModalOpen = useSelector(modal.isModalFormOpen(MODAL_CHANGE_PROPOSITION_DETAIL));

        return (
            <GridItem display={"flex"} xs={12}>
                <InfoPanel icon={"chart-bar"} title={t("agent.offers")}>
                    <Loading loading={isLoading}>
                        {isError && <Typography>{t("error.sectionUnavailable")}</Typography>}
                        {!isError && (
                            <ChangePropositionsTables
                                idObjectSelector={idObjectSelector}
                                actions={actions}
                                changePropositions={selectors.getChangePropositions}
                                selectors={selectors}
                            />
                        )}
                    </Loading>
                </InfoPanel>

                {/* Modals */}
                {isModalOpen && (
                    <ChangePropositionDetail
                        idObjectSelector={idObjectSelector}
                        pageStateSelector={selectors.getPageState}
                        changePropositionDetailSelector={selectors.getChangePropositionDetail}
                        isCancellationSubmittedSelector={selectors.getIsCancellationSubmitted}
                        updatePropositionStateAction={actions.setCancel}
                        contractConstant={contractConstant}
                    />
                )}
            </GridItem>
        );
    };

    return ChangePropositionsCollapseSection;
};
