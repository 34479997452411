import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { Countries } from "enums";

import { ModalFormLayout } from "containers/modal";
import {
    amountCharacters,
    Field,
    form,
    greaterThanZero,
    InputText,
    max10Characters,
    numberCharacters,
    required,
    SingleSelect,
    validAmount,
} from "core/form";
import i18n from "core/i18n";
import { localCurrency } from "core/formatters";
import localized from "core/localized";
import pageContext from "core/pageContext";
import { PAYMENT_CONTRIBUTIONS_OPTIONS_EX_AXA, PAYMENT_CONTRIBUTIONS_OPTIONS_UNIQA } from "app/constants";
import { ModalBody, ModalFooter } from "ui-library/modules";
import { Box, ContentBox } from "ui-library/layouts";
import { Button, Loading, Typography } from "ui-library/atoms";

const getAmountNormalizerBySite = (siteId) => (siteId === Countries.SK.id ? amountCharacters : numberCharacters);
const getValidateStaticBySite = (siteId) =>
    siteId === Countries.SK.id ? [required, validAmount, greaterThanZero] : [greaterThanZero, required];

const ContractOwnerPaymentsForm = ({
    canSubmit,
    handleSubmit,
    submitting,
    canEditAmount,
    showFrequency,
    showPaymentType,
    isUniqaContract,
    hasMonthlyFrequency,
    amountLabel,
    paymentOptionsUniqa,
    paymentOptionsUniqaExAxa,
    displayDisclaimer,
}) => {
    const { t } = i18n.useTranslation();
    const { siteId, currencyCode } = pageContext.useGetPageLocalization();

    const amountLabelMsg = amountLabel ? amountLabel : t("payments.amount");

    const paymentOptionsMonthIncluded = useSelector(localized.getLocalizedFrequencyOptions);
    const paymentOptionsMonthExcluded = useSelector(localized.getLocalizedFrequencyNonLifeOptions);
    const frequencyOptions = hasMonthlyFrequency ? paymentOptionsMonthIncluded : paymentOptionsMonthExcluded;

    const paymentContributionOptions = useSelector(localized.getLocalizedPaymentContributionOptions);
    const paymentOptions = isUniqaContract ? paymentOptionsUniqa : paymentOptionsUniqaExAxa;

    return (
        <>
            <ModalBody>
                <Loading loading={submitting}>
                    <Box>
                        <ContentBox>
                            <Field
                                name="amount"
                                component={InputText}
                                label={amountLabelMsg}
                                sufix={localCurrency(currencyCode)}
                                validateStatic={getValidateStaticBySite(siteId)}
                                normalize={canEditAmount ? [getAmountNormalizerBySite(siteId), max10Characters] : []}
                                disabled={!canEditAmount}
                                onEnter={handleSubmit}
                            />
                        </ContentBox>

                        {showFrequency && (
                            <ContentBox>
                                <Field
                                    name="frequencyOfPayment"
                                    component={SingleSelect}
                                    label={t("payments.frequency")}
                                    options={frequencyOptions}
                                    validateStatic={[required]}
                                    placeholder={t("common.choose")}
                                />
                            </ContentBox>
                        )}

                        {showPaymentType && (
                            <ContentBox>
                                <Field
                                    name="paymentContribution"
                                    component={SingleSelect}
                                    label={t("payments.type")}
                                    options={paymentContributionOptions.filter((item) => paymentOptions.includes(item.get("value")))}
                                    validateStatic={[required]}
                                    placeholder={t("common.choose")}
                                />
                            </ContentBox>
                        )}
                    </Box>
                    {displayDisclaimer && (
                        <ContentBox>
                            <Typography variant="caption" markDown={t("insurance.text.contractOwnerPayments")} />
                        </ContentBox>
                    )}
                </Loading>
            </ModalBody>
            <ModalFooter>
                <Button color="blue" variant="contained" onClick={handleSubmit} disabled={!canSubmit}>
                    {t("common.submitChange")}
                </Button>
            </ModalFooter>
        </>
    );
};

ContractOwnerPaymentsForm.propTypes = {
    canSubmit: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    canEditAmount: PropTypes.bool,
    showFrequency: PropTypes.bool,
    showPaymentType: PropTypes.bool,
    isUniqaContract: PropTypes.bool,
    hasMonthlyFrequency: PropTypes.bool,
    amountLabel: PropTypes.string,
    paymentOptionsUniqa: PropTypes.object,
    paymentOptionsUniqaExAxa: PropTypes.object,
    displayDisclaimer: PropTypes.bool,
};

ContractOwnerPaymentsForm.defaultProps = {
    canEditAmount: true,
    showFrequency: true,
    showPaymentType: true,
    isUniqaContract: false,
    hasMonthlyFrequency: true,
    amountLabel: null,
    displayDisclaimer: false,
    paymentOptionsUniqa: PAYMENT_CONTRIBUTIONS_OPTIONS_UNIQA,
    paymentOptionsUniqaExAxa: PAYMENT_CONTRIBUTIONS_OPTIONS_EX_AXA,
};

export const createContainer = (formName, formTitleKey) =>
    form(formName)((props) => {
        const { t } = i18n.useTranslation();
        return (
            <ModalFormLayout formName={formName} formTitle={t(formTitleKey)} formIcon="circle-dollar">
                <ContractOwnerPaymentsForm {...props} />
            </ModalFormLayout>
        );
    });
