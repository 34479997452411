import { model } from "core/util";

import { AmountLegacy, SimpleCodeValue } from "../common";
import { ElectronicCommunicationLegacy } from "../electronicCommunication";
import {
    BalanceOnContract,
    ContractParameters,
    HarmonizedPaymentsScheduler,
    InsuredRealEstateProperty,
    PaymentRequest,
} from "../harmonized";
import { HolderLegacy } from "../holder";

import { UniqaLifeAccountDetail } from "./UniqaLifeAccountDetail";

export const UniqaLifeContractDetail = model.createModelLegacy("UniqaLifeContractDetail", (json) => ({
    category: SimpleCodeValue.fromServer(json.Category),
    identifier: json.Identifier,
    contractParameters: ContractParameters.fromServer(json.ContractParameters),
    insuranceCostSummary: AmountLegacy.fromServer(json.InsuranceCostSummary),
    actualPaymentsScheduler: HarmonizedPaymentsScheduler.fromServerList(json.ActualPaymentsScheduler),
    advancePaymentsScheduler: HarmonizedPaymentsScheduler.fromServerList(json.AdvancePaymentsScheduler),
    paymentsScheduler: HarmonizedPaymentsScheduler.fromServerList(json.PaymentsScheduler),
    paymentRequests: PaymentRequest.fromServerList(json.PaymentRequests),
    balanceOnContract: BalanceOnContract.fromServer(json.BalanceOnContract),
    lifeAccountDetail: UniqaLifeAccountDetail.fromServer(json.LifeAccountDetail),
    insuredRealEstateProperties: InsuredRealEstateProperty.fromServerList(json.InsuredRealEstateProperties),
    holder: HolderLegacy.fromServer(json.Holder),
    electronicCommunication: ElectronicCommunicationLegacy.fromServer(json.ElectronicCommunication),
}));
