import { v4 as uuid } from "uuid";

import { model } from "core/util";

import { AmountLegacy, SimpleCodeValue } from "../common";

export const HarmonizedPaymentsScheduler = model.createModelLegacy("HarmonizedPaymentsScheduler", (json) => ({
    reactKey: uuid(),
    periodicity: SimpleCodeValue.fromServer(json.Periodicity),
    modalAmount: AmountLegacy.fromServer(json.ModalAmount),
    paymentMode: SimpleCodeValue.fromServer(json.PaymentMode),
    paymentStartDate: json.PaymentStartDate,
    paymentEndDate: json.PaymentEndDate,
    state: json.State,
}));
