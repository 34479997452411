import { CircularProgress, Step as MuiStep, Stepper as MuiStepper } from "@mui/material";
import { Icon, Typography } from "ui-library/atoms";
import { Flexbox } from "ui-library/layouts";
import PropTypes from "prop-types";
import { StyledStepLabel, StyledStepperCircle, StyledStepperConnector, StyledStepperIcon } from "ui-library/Styled";

const StepperConntector = () => {
    return <StyledStepperConnector />;
};

const StepperIcon = ({ completed, active, className }) => {
    return (
        <StyledStepperIcon ownerState={{ completed, active }} className={className}>
            {completed ? <Icon size={14} color={"white"} icon="check" /> : ""}
        </StyledStepperIcon>
    );
};

export const Stepper = ({ activeStep, displayAsCircle, children }) => {
    return (
        <>
            {displayAsCircle ? (
                <Flexbox alignItems={"center"} gap={2}>
                    <StyledStepperCircle className="box-wrapper" sx={{ position: "relative", display: "inline-flex" }}>
                        <CircularProgress
                            size={48}
                            variant="determinate"
                            value={((activeStep + 1) / children.length) * 100}
                            className={"foreground"}
                        />
                        <CircularProgress size={48} variant="determinate" value={100} className={"background"} />
                        <Flexbox alignItems="center" justifyContent="center" className="inner">
                            <Typography variant="h3">
                                {activeStep + 1}/{children.length}
                            </Typography>
                        </Flexbox>
                    </StyledStepperCircle>
                    <Typography variant="h2">Krok {activeStep + 1}</Typography>
                </Flexbox>
            ) : (
                <MuiStepper alternativeLabel activeStep={activeStep} connector={<StepperConntector />}>
                    {children.map((Child) => (
                        <MuiStep key={Child.props.label}>
                            <StyledStepLabel StepIconComponent={StepperIcon}>{Child.props.label}</StyledStepLabel>
                        </MuiStep>
                    ))}
                </MuiStepper>
            )}
        </>
    );
};

Stepper.propTypes = {
    activeStep: PropTypes.number.isRequired,
    displayAsCircle: PropTypes.bool,
};

export const Step = () => {};

Step.propTypes = {
    label: PropTypes.any.isRequired,
};
