import { NAME } from "./constants";
import saga from "./saga";
import reducer from "./reducer";

export { default as DisclaimerPage } from "./Container";

export default {
    NAME,
    reducer,
    saga,
};
