import { call, put, select, takeEvery } from "redux-saga/effects";

import fetching from "core/fetching";
import pageContext from "core/pageContext";
import router from "core/router";
import userInfo from "core/userInfo";
import { sentry } from "core/util";
import { Pages } from "routeConstants";
import { consentApi } from "serverApi";

import { NAME } from "./constants";
import { ACCEPT, clear } from "./actions";

export default router.routerWrapper({
    *onPageEnter() {
        yield takeEvery(ACCEPT, acceptSaga);
    },
    clearDataForPage() {
        return [clear()];
    },
});

function* acceptSaga() {
    try {
        yield put(fetching.setFetching(NAME, true));
        const version = yield select(pageContext.getDisclaimerVersion);
        yield call(consentApi.setDisclaimerConsent, version);
        yield call(userInfo.loadCriticalUserInfo);
        yield put(fetching.setFetching(NAME, false));
        yield put(router.navigate(Pages.CONTRACTS));
    } catch (e) {
        sentry.captureException(e);
        // yield put(addServiceError(USER_SET_CONSENT_UNAVAILABLE));
        yield put(fetching.setFetching(NAME, false));
    }
}
