import { NAME, EntityState } from "./constants";

const getModel = (state): EntityState => state.get(NAME);

export const isError =
    (entity: string): ((state) => boolean) =>
    (state) =>
        getModel(state).errors.includes(entity);

export const getData =
    (entity: string, defaultValue = null) =>
    (state) =>
        getModel(state).data[entity] || defaultValue;

export const getDataSelector = (state, entity: string, defaultValue = null) => getModel(state).data[entity] || defaultValue;
