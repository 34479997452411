import React from "react";
import PropTypes from "prop-types";

import { Typography } from "ui-library/atoms";

import { MutualFundsFinancialRequest } from "model/mutualFunds";
import i18n from "core/i18n";
// import {ContractChangeProposition} from "model/changePropositions";
import { formatAmountOrNumberOfShares } from "./productFormatter";

export const AmountShareNumberFormatter = ({ row }) => {
    const { t } = i18n.useTranslation();
    let transaction;
    if (row instanceof MutualFundsFinancialRequest) {
        transaction = row;
    } else {
        // TODO change propositions
        // transaction = row.changeProposition.mutualFundsTransaction;
    }
    const value = formatAmountOrNumberOfShares(
        transaction.transactionValueType,
        transaction.transactionValue,
        transaction.numberOfSharesInTransaction,
        t("investments.sellAll"),
    );
    return <Typography>{value}</Typography>;
};

AmountShareNumberFormatter.propTypes = {
    row: PropTypes.oneOfType([
        PropTypes.instanceOf(MutualFundsFinancialRequest),
        // PropTypes.instanceOf(ContractChangeProposition),
    ]).isRequired,
};
