import { all, call, put, select, takeLatest } from "redux-saga/effects";

import entity from "core/entity";
import fetching from "core/fetching";
import modal from "core/modal";
import errorHandling from "core/errorHandling";
import { sentry } from "core/util";
import { ChangePropositionStatus } from "enums";
import { changePropositionsApi } from "serverApi";

import { FETCHING_CHANGE_PROPOSITION_CANCEL, MODAL_CHANGE_PROPOSITION_DETAIL, PROPOSITION_PAGE_STATE } from "./constants";

export default (actions, selectors, propositionsEntity) => {
    return function* (idObject, changePropositionId) {
        yield all([
            put(modal.closeModalForm(MODAL_CHANGE_PROPOSITION_DETAIL)),
            put(actions.setPageState(changePropositionId, PROPOSITION_PAGE_STATE.INITIAL)),
        ]);
        yield takeLatest(actions.CANCEL_SUBMIT, cancelChangePropositionSaga, idObject);
    };

    function* cancelChangePropositionSaga(idObject) {
        yield put(fetching.setFetching(FETCHING_CHANGE_PROPOSITION_CANCEL, true));
        const pageState = yield select(selectors.getPageState);
        try {
            yield call(
                changePropositionsApi.updateChangePropositions,
                idObject,
                pageState.changePropositionId,
                ChangePropositionStatus.DECLINED.id,
            );
            yield call(entity.load, propositionsEntity, () => changePropositionsApi.getChangePropositions(idObject));
            yield all([put(fetching.setFetching(FETCHING_CHANGE_PROPOSITION_CANCEL, false)), put(actions.setCancellationSubmitted(true))]);
        } catch (e) {
            sentry.captureException(e);
            yield put(errorHandling.addServiceError(MODAL_CHANGE_PROPOSITION_DETAIL, e.identifier));
            yield put(fetching.setFetching(FETCHING_CHANGE_PROPOSITION_CANCEL, false));
        }
    }
};
