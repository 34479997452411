import createModel from "../createModel";
import { ClientVerification } from "./ClientVerification";
import { LegalEntityLegacy } from "../common/LegalEntityLegacy";
import { UserConsent } from "./UserConsent";

export const AuthenticatedUser = createModel("AuthenticatedUser", (json) => ({
    client: LegalEntityLegacy.fromServer(json.Client),
    verification: ClientVerification.fromServer(json.Verification),
    consent: UserConsent.fromServer(json.Consent),
    disclaimerVersion: json.Disclaimer ? json.Disclaimer.ConsentVersionCode : null,
}));
