import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ImpersonateState, initialState, NAME } from "./constants";

const setUnsuccessfulReason: CaseReducer<ImpersonateState, PayloadAction<string>> = (state, { payload }) => {
    state.unsuccessfulReason = payload;
};

export const slice = createSlice({
    name: NAME,
    initialState,
    reducers: {
        setUnsuccessfulReason,
    },
});

export default slice;
