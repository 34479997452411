import React, { FC } from "react";

import { Typography } from "ui-library/atoms";

import { Countries } from "enums";
import { fn } from "core/util";

export interface FundPaymentFormatterProps {
    value: any; //todo MutualFundsAccountPaymentDetail
    country: string;
}

export const FundPaymentFormatter: FC<FundPaymentFormatterProps> = ({ value, country }) => {
    if (fn.isEmpty(value)) {
        return null;
    }

    if (country === Countries.SK.id) {
        const ibanValue = value.get("iban");
        if (ibanValue) {
            return <Typography>{ibanValue}</Typography>;
        }
    }

    const accountNumberValue = value.get("accountNumber");
    const bankCodeValue = value.getIn(["bankNumber", "code"]);
    if (accountNumberValue && bankCodeValue) {
        const accountPrefix = value.get("accountNumberPrefix") ? `${value.get("accountNumberPrefix")}-` : "";
        const toDisplay = `${accountPrefix}${accountNumberValue}/${bankCodeValue}`;
        return <Typography>{toDisplay}</Typography>;
    }
    return null;
};
