import { call, put } from "redux-saga/effects";

import router from "core/router";
import { epinExternalApi } from "serverApi";

import fetching from "core/fetching";
import { HASH_PLACEHOLDER } from "core/util";

import { setError, setResult } from "./actions";
import { NAME } from "./constants";
import { Tabs } from "routeConstants";

export default router.routerWrapper({
    *onInnerRouteChange(name, params) {
        yield call(routeChangedSaga, name, params);
    },
    clearDataForPage() {
        return [setResult(null, null)];
    },
});

function* routeChangedSaga(name, params) {
    switch (name) {
        case Tabs.EPIN_EXTERNAL_HASH: {
            yield* fetching.withFetching(NAME, function* () {
                try {
                    if (params.hash !== HASH_PLACEHOLDER) {
                        const result = yield call(epinExternalApi.resendEpin, params.hash);
                        yield put(setResult(result.phoneNumberShort, result.resendEpinCountExhausted, result.invalidHash));
                    }
                } catch (e) {
                    yield put(setError());
                }
            });
            break;
        }
        default:
            break;
    }
}
