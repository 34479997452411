import React from "react";
import PropTypes from "prop-types";
import { gridPageCountSelector, gridPageSelector, gridPageSizeSelector, useGridApiContext, useGridSelector } from "@mui/x-data-grid-pro";

import { TablePagination } from "../Pagination/TablePagination";
import { Flexbox } from "../../layouts";
import { Typography } from "../../atoms";

export const TableFooter = ({ summaryRow, hideFooter, rowsPerPageOptions }) => {
    if (Object.keys(summaryRow).length !== 0) {
        return <CustomSummary summaryRow={summaryRow} />;
    }
    if (!hideFooter) {
        return <CustomPagination rowsPerPageOptions={rowsPerPageOptions} />;
    }
    return null;
};

TableFooter.propTypes = {
    summaryRow: PropTypes.object.isRequired,
    hideFooter: PropTypes.bool.isRequired,
    rowsPerPageOptions: PropTypes.array.isRequired,
};

const CustomSummary = ({ summaryRow }) => {
    if (!summaryRow) {
        return null;
    }

    const summaryRowData = Array.isArray(summaryRow) ? summaryRow : [summaryRow];
    return (
        <>
            {summaryRowData.map((item) => (
                <Flexbox key={item.desc} mx={{ xs: -3, md: 0 }} p={1.25} justifyContent={"space-between"}>
                    <Typography variant={item.textType ? item.textType : "p"}>{item.desc}</Typography>
                    <Typography variant="b">{item.total}</Typography>
                </Flexbox>
            ))}
        </>
    );
};

CustomSummary.propTypes = {
    summaryRow: PropTypes.object.isRequired,
};

const CustomPagination = ({ rowsPerPageOptions }) => {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    const pageSize = useGridSelector(apiRef, gridPageSizeSelector);

    return (
        <TablePagination rowsPerPageOptions={rowsPerPageOptions} apiRef={apiRef} page={page} pageCount={pageCount} pageSize={pageSize} />
    );
};

CustomPagination.propTypes = {
    rowsPerPageOptions: PropTypes.array.isRequired,
};
