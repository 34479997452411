import { createSelector } from "reselect";
import { Map } from "immutable";

import pageContext from "core/pageContext";
import i18n from "core/i18n";
import { Countries } from "enums";
import { Country } from "types";

const sortedCountries = (countryMap: {}, site: Country) => {
    const primary = Map({ value: site.id, label: countryMap[site.id] });
    const secondary = site.secondaryCountries.map((country) => Map({ value: country, label: countryMap[country] }));
    const secondarySet = site.secondaryCountries;
    const rest = Object.entries(countryMap)
        .filter(([country]) => site.id !== country && !secondarySet.includes(country))
        .sort(([, label1]: [never, string], [, label2]: [never, string]) => label1.localeCompare(label2))
        .map(([value, label]) => Map({ value, label }));
    secondary.unshift(primary);
    return secondary.concat(rest);
};

export const getLocalizedCountryOptions = createSelector(i18n.createGetLocalizedEnumMap(Countries), pageContext.getSite, sortedCountries);
