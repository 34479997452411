import { call, put, select } from "redux-saga/effects";
import { formWrapper } from "core/form";
import errorHandling from "core/errorHandling";
import { sentry } from "core/util";
import userContracts from "core/userContracts";
import { contractDataChangeApi } from "serverApi";

export function* createSaga(formName, idObject) {
    try {
        yield call(formSaga(formName), idObject);
    } catch (e) {
        sentry.captureException(e);
        yield put(errorHandling.addServiceError(formName, e.identifier));
    }
}

const formSaga = (formName) =>
    formWrapper(formName, {
        *initialize(idObject) {
            yield put(errorHandling.removeServiceErrors(formName));
            const formInitData = {
                idObject,
                contractName: null,
            };
            const policy = yield select(userContracts.createGetContractById(Number(idObject)));
            if (policy) {
                formInitData.contractName = policy?.contractName;
            }
            return formInitData;
        },
        *save(values) {
            yield call(contractDataChangeApi.updateContractName, values);
            yield call(userContracts.loadUserContracts); // contract name changed.
            return values;
        },
    });
