import React, { FC } from "react";

import { Container, Typography } from "ui-library/atoms";
import { Section } from "ui-library/layouts";

export interface FullPageFormHeaderProps {
    title: string;
    lead?: string;
}

export const FullPageFormHeader: FC<FullPageFormHeaderProps> = ({ title, lead }) => (
    <Container maxWidth="md">
        <Section>
            <Typography variant="h1">{title}</Typography>
            {lead && <Typography variant="lead">{lead}</Typography>}
        </Section>
    </Container>
);
