import PropTypes from "prop-types";

import React from "react";

import { Icon, Typography } from "ui-library/atoms";

import { Box, Flexbox } from "ui-library/layouts";

export const InsuranceDedactibleInfo = ({ cause, children }) => {
    return (
        <Box mb={3}>
            <Flexbox mb={1}>
                <Flexbox flexDirection={{ xs: "row" }} alignItems={"center"} gap={1}>
                    <Icon size={14} icon={"circle-check"} color="green" />
                    <Typography component="h4" variant="h4">
                        {cause}
                    </Typography>
                </Flexbox>
            </Flexbox>
            {children}
        </Box>
    );
};

InsuranceDedactibleInfo.propTypes = {
    cause: PropTypes.string,
};

InsuranceDedactibleInfo.defaultProps = {};
