import { NAME } from "./constants";
import reducer from "./reducer";
import saga from "./saga";
import innerRoutes from "./innerRoutes";
import { ClientClaimsTab, ReportClaimTab, ReportClaimTabOld } from "./tabs";

export { default as ClaimsPage } from "./Container";

export const ClaimsTabs = {
    ReportClaimTab,
    ReportClaimTabOld,
    ClientClaimsTab,
};

export default {
    NAME,
    reducer,
    saga,
    innerRoutes,
};
