import React, { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button, Typography } from "ui-library/atoms";
import { InfoPanel } from "ui-library/modules";
import { ButtonsLayout, ContentBox, GridItem } from "ui-library/layouts";

import i18n from "core/i18n";
import router from "core/router";
import userContracts from "core/userContracts";
import { useHasEditContractPermission } from "core/hooks";

import { Pages, Tabs } from "routeConstants";

export const NewSellSwitchGridItem: FC = () => {
    const { t } = i18n.useTranslation();
    const { idObject } = useParams();
    const hasEditPermission = useHasEditContractPermission();
    const contract = userContracts.useGetContractById(idObject);
    const { isInFutureOrTerminated } = userContracts.useGetContractDerivedData(contract);

    const navigate = useNavigate();
    const sellSwitchFormUrl = router.getStaticUrl(Pages.CONTRACT_REGULAR, Tabs.SELL_SWITCH_FORM, { idObject });

    if (isInFutureOrTerminated) {
        return null;
    }

    return (
        <GridItem display={"flex"} xs={12} md={6}>
            <InfoPanel
                actionStretch={true}
                bgVariant="white"
                icon="file-circle-plus"
                title={t("investments.newSellSwitchAction")}
                actionButton={
                    hasEditPermission &&
                    contract.canEdit && (
                        <ButtonsLayout mb={1}>
                            <Button color="orange" variant="contained" onClick={() => navigate(sellSwitchFormUrl)}>
                                {t("investments.newSellRequest")}
                            </Button>
                        </ButtonsLayout>
                    )
                }
            >
                <ContentBox>
                    <Typography variant="p">{t("investments.sellSwitch.description")}</Typography>
                </ContentBox>
            </InfoPanel>
        </GridItem>
    );
};
