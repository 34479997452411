import { Record } from "immutable";

import carImg from "ui-library/assets/images/car-crash.svg";
import propertyImg from "ui-library/assets/images/property-event.svg";
import travelImg from "ui-library/assets/images/travel-event.svg";
import lifeImg from "ui-library/assets/images/life-event.svg";

import { formatClaimVehicle, localAmount } from "core/formatters";
import { ClaimState, RequiredDocumentParty, RequiredDocumentState, UniqaLineOfBusiness } from "enums";
import { fn } from "core/util";

const ARCHIVE_STATE = "ARCHIVE";

const getLineOfBusiness = (item) => item.lineOfBusinesses?.get(0);

export const getLocalizedLineOfBusiness = (item, uniqaLineOfBusinessMapObj) => {
    const lineOfBusiness = getLineOfBusiness(item);
    if (!lineOfBusiness) {
        return "";
    }
    return `${uniqaLineOfBusinessMapObj[lineOfBusiness]}: `;
};

export const getLocalizedInsuranceRisk = (item, uniqaInsuranceRiskMapObj) => {
    const foundRisk = uniqaInsuranceRiskMapObj[item?.insuranceRisk?.key];
    if (!foundRisk) {
        return item?.insuranceRisk?.value;
    }
    return foundRisk;
};

export const getClaimStateObj = (stateId) => {
    const claimsState = Object.values(ClaimState).find((item) => item.id === stateId);
    return claimsState || new Record({}, ClaimState);
};

export const getCounterPartyObj = (partyType) => {
    const counterParty = Object.values(RequiredDocumentParty).find((item) => item.id === partyType);
    return counterParty || new Record({}, RequiredDocumentParty);
};

export const getDocumentStateObj = (stateId) => {
    const documentState = Object.values(RequiredDocumentState).find((item) => item.id === stateId);
    return documentState || new Record({}, RequiredDocumentState);
};

export const getImageCategory = (item) => {
    const lineOfBusiness = getLineOfBusiness(item);
    const filtered = Object.values(UniqaLineOfBusiness).find((item) => item.id === lineOfBusiness);
    return getBgImg(filtered?.img);
};

export const formatClaimName = (t, claim) => {
    if (!claim) {
        return "";
    }

    const claimNumber = `${t("claim.claim")} ${claim.claimNumber}`;

    if (claim.claimCategory === "CAR") {
        const subject = claim.subject;
        const subjectData = formatClaimVehicle(subject) + (subject?.licensePlate ? subject.licensePlate + " " : "");
        return claimNumber + " " + subjectData;
    }

    return claimNumber + " " + claim.damageScope?.value;
};

export const isWithPayment = (closureType) => {
    return closureType === "CLAIM_WITH_PAYMENT" || closureType === "INCIDENT_WITH_PAYMENT";
};

export const formatClaimStatusWithPaymentInfo = (t, claimData) => {
    const lossSum = claimData.lossSum ? claimData.lossSum : 0;

    if (!isWithPayment(claimData.closureType)) {
        return t("claim.withoutPayment");
    }

    if (claimData.shouldDisplaySum && !fn.isEmptyOrZero(lossSum)) {
        return `${t("claim.withPayment")} (${localAmount(lossSum)})`;
    }
    return t("claim.withPayment");
};

export const formatAdvancedPayment = (t, claimData) => {
    const lossSum = claimData.lossSum ? claimData.lossSum : 0;

    if (claimData.claimState === ARCHIVE_STATE || !isWithPayment(claimData.closureType)) {
        return null;
    }

    if (claimData.shouldDisplaySum) {
        return localAmount(lossSum);
    }
    return t("common.yes");
};

const getBgImg = (productType) => {
    switch (productType) {
        case "car":
            return carImg;
        case "property":
            return propertyImg;
        case "travel":
            return travelImg;
        case "life":
            return lifeImg;
        default:
            return lifeImg;
    }
};
