import React from "react";
import PropTypes from "prop-types";

import { Flexbox } from "ui-library/layouts";
import { Button } from "ui-library/atoms";

import i18n from "core/i18n";

import { BackToRedeemSavingsButton } from "./BackToRedeemSavingsButton";

export const StepOneButtonsBox = ({ idObject, canSubmit, handleSubmit }) => {
    const { t } = i18n.useTranslation();

    return (
        <Flexbox mt={6} gap={2} justifyContent="space-between">
            <BackToRedeemSavingsButton idObject={idObject} />
            <Button disabled={!canSubmit} onClick={handleSubmit} variant="contained" color="blue">
                {t("common.continue")}
            </Button>
        </Flexbox>
    );
};

StepOneButtonsBox.propTypes = {
    idObject: PropTypes.number.isRequired,
    canSubmit: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};
