import { NAME } from "./constants";

import createSingle from "./createSingle";
import { SELECT_SUGGESTION } from "./actions";
import { selectMatcher } from "./actionMatchers";

/**
 * Named module - saga and reducer already connected
 */
export default {
    NAME,
    SELECT_SUGGESTION,
    createSingle,
    selectMatcher,
};
