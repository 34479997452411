import React from "react";
import { useSelector } from "react-redux";

import { Typography } from "ui-library/atoms";
import { ContentBox, GridItem, GridLayout } from "ui-library/layouts";

import {
    Field,
    InputText,
    lettersNumbersBasicInterpunctionCharacters,
    lettersNumbersDash,
    max20Characters,
    max50Characters,
    max9Characters,
    SingleSelect,
} from "core/form";
import i18n from "core/i18n";
import { getLocalizedCountryOptions } from "core/localized";

import {
    BENEFICIARIES_FIELD,
    CITY_FORM_FIELD,
    DESCRIPTION_NUMBER_FORM_FIELD,
    ORIENTATION_NUMBER_FORM_FIELD,
    STATE_FORM_FIELD,
    STREET_FORM_FIELD,
    ZIP_FORM_FIELD,
} from "../constants";

export const BeneficiaryAddress = ({ index }) => {
    const { t } = i18n.useTranslation();
    const countryOptions = useSelector(getLocalizedCountryOptions);

    return (
        <>
            <ContentBox>
                <Typography variant="h3">{t("holder.permanentAddress")}</Typography>
            </ContentBox>

            <ContentBox>
                <Field
                    name={`${BENEFICIARIES_FIELD}[${index}].${STREET_FORM_FIELD}`}
                    component={InputText}
                    label={t("holder.street")}
                    disableSuccessTick // validated on BE, dont show success prematurely to user
                    normalize={[lettersNumbersBasicInterpunctionCharacters, max50Characters]}
                />
            </ContentBox>
            <ContentBox>
                <GridLayout spacing={2}>
                    <GridItem xs={12} md={6}>
                        <Field
                            name={`${BENEFICIARIES_FIELD}[${index}].${DESCRIPTION_NUMBER_FORM_FIELD}`}
                            component={InputText}
                            label={t("holder.descriptionNumber")}
                            disableSuccessTick // validated on BE, dont show success prematurely to user
                            normalize={[lettersNumbersBasicInterpunctionCharacters, max20Characters]}
                        />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <Field
                            name={`${BENEFICIARIES_FIELD}[${index}].${ORIENTATION_NUMBER_FORM_FIELD}`}
                            component={InputText}
                            label={t("holder.orientationNumber")}
                            disableSuccessTick // validated on BE, dont show success prematurely to user
                            normalize={[lettersNumbersBasicInterpunctionCharacters, max20Characters]}
                        />
                    </GridItem>
                </GridLayout>
            </ContentBox>
            <ContentBox>
                <GridLayout spacing={2}>
                    <GridItem xs={12} md={9}>
                        <Field
                            name={`${BENEFICIARIES_FIELD}[${index}].${CITY_FORM_FIELD}`}
                            component={InputText}
                            label={t("holder.city")}
                            disableSuccessTick // validated on BE, dont show success prematurely to user
                            normalize={[lettersNumbersBasicInterpunctionCharacters, max50Characters]}
                        />
                    </GridItem>
                    <GridItem xs={12} md={3}>
                        <Field
                            name={`${BENEFICIARIES_FIELD}[${index}].${ZIP_FORM_FIELD}`}
                            component={InputText}
                            label={t("holder.postalCode")}
                            disableSuccessTick // validated on BE, dont show success prematurely to user
                            normalize={[lettersNumbersDash, max9Characters]}
                        />
                    </GridItem>
                </GridLayout>
            </ContentBox>
            <ContentBox>
                <Field
                    name={`${BENEFICIARIES_FIELD}[${index}].${STATE_FORM_FIELD}`}
                    component={SingleSelect}
                    label={t("holder.countryCode")}
                    disableSuccessTick // validated on BE, dont show success prematurely to user
                    options={countryOptions}
                />
            </ContentBox>
        </>
    );
};
