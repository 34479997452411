import { fromJS } from "immutable";

import { TableFilterOperator } from "enums";

import { GROSS_AMOUNT_COLUMN, INVESTMENT_FUND_NAME_COLUMN, TYPE_COLUMN, VALUE_DATE_COLUMN } from "../constants";

export const financialOperationsDefaultOperators = fromJS({
    investmentFundName: { column: INVESTMENT_FUND_NAME_COLUMN, operator: TableFilterOperator.CONTAINS },
    dateFrom: { column: VALUE_DATE_COLUMN, operator: TableFilterOperator.AFTER },
    dateTo: { column: VALUE_DATE_COLUMN, operator: TableFilterOperator.BEFORE },
    type: { column: TYPE_COLUMN, operator: TableFilterOperator.CONTAINS },
    grossAmount: { column: GROSS_AMOUNT_COLUMN, operator: "" },
});
