import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { GridItem, GridLayout } from "ui-library/layouts";
import { InfoPanel } from "ui-library/modules";

import i18n from "core/i18n";
import router from "core/router";
import { RedeemSavingsNotPossibleText, RedeemSavingsWelcomeText } from "containers/contract";
import { Pages, Tabs } from "routeConstants";

import { RedeemSavingsPensionDpsNoOptionsText } from "../containers";
import { REDEEM_SAVINGS_STEP } from "../constants";
import { getIsAnotherRedeemInProcess, getIsRedeemPossible, getRedeemAllowedPensionTypes } from "../selectors";

export const RedeemSavingsTab: FC = () => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();
    const [redeemStep, setRedeemStep] = useState(REDEEM_SAVINGS_STEP.FIRST_STEP_INTRODUCTION);

    const { idObject } = useParams();
    const allowedRedeemPensionTypes = useSelector(getRedeemAllowedPensionTypes);
    const isAnotherRedeemInProcess = useSelector(getIsAnotherRedeemInProcess);
    const isRedeemPossible = useSelector(getIsRedeemPossible);

    const getNextButtonAction = () =>
        isRedeemPossible
            ? dispatch(router.navigate(Pages.CONTRACT_DPS, Tabs.PENSION_REDEEM_SAVINGS_FORM, { idObject }))
            : setRedeemStep(REDEEM_SAVINGS_STEP.SECOND_STEP_REDEEM_NOT_POSSIBLE);

    return (
        <GridLayout defaultSpacing>
            <GridItem display="flex" xs={12} md={12}>
                <InfoPanel icon="circle-dollar" title={t("pension.redeemSavings")}>
                    {/* Step One: Welcome text */}
                    {redeemStep === REDEEM_SAVINGS_STEP.FIRST_STEP_INTRODUCTION && (
                        <RedeemSavingsWelcomeText welcomeText={t("pension.redeemSavings.dps.text")} buttonAction={getNextButtonAction} />
                    )}

                    {/* Step Two: Not possible / No redeem to be claimed */}
                    {redeemStep === REDEEM_SAVINGS_STEP.SECOND_STEP_REDEEM_NOT_POSSIBLE && allowedRedeemPensionTypes.isEmpty() && (
                        <RedeemSavingsPensionDpsNoOptionsText
                            backButtonAction={() => setRedeemStep(REDEEM_SAVINGS_STEP.FIRST_STEP_INTRODUCTION)}
                        />
                    )}

                    {/* Step Two: Not possible / Another redeem in process */}
                    {redeemStep === REDEEM_SAVINGS_STEP.SECOND_STEP_REDEEM_NOT_POSSIBLE && isAnotherRedeemInProcess && (
                        <RedeemSavingsNotPossibleText
                            descriptionText={t("pension.redeemSavings.pp.anotherRedeemInProcess")}
                            backButtonAction={() => setRedeemStep(REDEEM_SAVINGS_STEP.FIRST_STEP_INTRODUCTION)}
                        />
                    )}
                </InfoPanel>
            </GridItem>
        </GridLayout>
    );
};
