import { NAME } from "./constants";
import innerRoutes from "./innerRoutes";
import saga from "./saga";
import reducer from "./reducer";

import { ElectronicCommunicationSuccessTab, ElectronicCommunicationTab } from "./tabs";

export { default as CampaignPage } from "app/pages/campaign/Container";

export const CampaignTabs = {
    ElectronicCommunicationTab,
    ElectronicCommunicationSuccessTab,
};

export default {
    NAME,
    innerRoutes,
    reducer,
    saga,
};
