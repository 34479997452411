import React, { FC } from "react";
import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";

import bannerImg from "ui-library/assets/images/banner-prihlasenie.svg";
import logo from "ui-library/assets/images/logo.svg";
import { Container, Image } from "ui-library/atoms";
import { Flexbox, StaticPageLayout } from "ui-library/layouts";
import { SectionBanner } from "ui-library/modules";
import { LogoWrapper, StyledHeader } from "ui-library/Styled/Modules/Header";
import { theme } from "ui-library/theme/theme";
import { ErrorDateAndIdentifier } from "containers";

export interface ErrorLayoutProps {
    msgKey: string;
    errorIdentifier: string;
}

export const ErrorLayout: FC<ErrorLayoutProps> = ({ msgKey, errorIdentifier }) => {
    const handleLogoClick = () => {
        // We are using location.href here because it forces the browser to refresh.
        window.location.href = "/";
    };

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline>
                    <StyledHeader>
                        <Container>
                            <Flexbox
                                className={"header"}
                                flexDirection={{ xs: "row", md: "row" }}
                                alignItems={"center"}
                                justifyContent={{ xs: "unset", md: "space-between" }}
                            >
                                <LogoWrapper>
                                    <Image shadow={false} src={logo} onClick={handleLogoClick} alt={"Logo"} />
                                </LogoWrapper>
                            </Flexbox>
                        </Container>
                    </StyledHeader>

                    <StaticPageLayout>
                        <SectionBanner title="Nastala technická chyba" imageUrl={bannerImg}>
                            <ErrorDateAndIdentifier errorMsgKey={msgKey} errorIdentifier={errorIdentifier} />
                        </SectionBanner>
                    </StaticPageLayout>
                </CssBaseline>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};
