import { fetch } from "core/util";
import { API_PREFIX } from "app/constants";
import { ContractChangeProposition } from "model/changePropositions";

const URL_PREFIX = `${API_PREFIX}/product`;

const getChangePropositions = (idObject) =>
    fetch.doGet(`${URL_PREFIX}/${idObject}/change-propositions`, null, ContractChangeProposition.fromServerList);
const updateChangePropositions = (idObject, id, status) =>
    fetch.doPut(`${URL_PREFIX}/change-propositions`, {
        idObject,
        changePropositions: {
            id,
            status,
        },
    });

export default {
    getChangePropositions,
    updateChangePropositions,
};
