import { Record } from "immutable";

class ClaimState extends Record({ id: null, msg: null, step: null, isClaimClosed: false }) {}

export default Object.freeze({
    NO_ASSIGNEE: new ClaimState({
        id: "NO_ASSIGNEE",
        msg: "claim.claimState.check",
        step: 1,
        isClaimClosed: false,
    }),
    CHECK: new ClaimState({
        id: "CHECK",
        msg: "claim.claimState.check",
        step: 1,
        isClaimClosed: false,
    }),
    ASSEMBLY: new ClaimState({
        id: "ASSEMBLY",
        msg: "claim.claimState.documentationProcessing",
        step: 2,
        isClaimClosed: false,
    }),
    ASSESSMENT: new ClaimState({
        id: "ASSESSMENT",
        msg: "claim.claimState.liquidation",
        step: 3,
        isClaimClosed: false,
    }),
    REVIEW: new ClaimState({
        id: "REVIEW",
        msg: "claim.claimState.liquidation",
        step: 3,
        isClaimClosed: false,
    }),
    ARCHIVE: new ClaimState({
        id: "ARCHIVE",
        msg: "claim.claimState.closed",
        step: 4,
        isClaimClosed: true,
    }),
    AUTOCOMPLETITION: new ClaimState({
        id: "AUTOCOMPLETITION",
        msg: "claim.claimState.closed",
        step: 4,
        isClaimClosed: true,
    }),
    PERMANENT_INJURY: new ClaimState({
        id: "PERMANENT_INJURY",
        msg: "claim.claimState.persistentEffects",
        step: 4,
        isClaimClosed: true,
    }),
    RENT: new ClaimState({
        id: "RENT",
        msg: "claim.claimState.rent",
        step: 4,
        isClaimClosed: true,
    }),
});
