import { fetch } from "core/util";
import { API_PREFIX } from "app/constants";
import { UniqaClaimInspectionOrder, UniqaIncidentDetail, UniqaOutgoingDocument, UniqaRequiredDocument } from "model/uniqaClaims";
import { ClaimsEvents } from "model/claim";

const prefix = `${API_PREFIX}/claim`;

const getClientIncidents = () => fetch.doGet(`${prefix}/client`, null, ClaimsEvents.fromServer);
const getIncidentDetail = (incidentId) => fetch.doGet(`${prefix}/incident/${incidentId}`, null, UniqaIncidentDetail.fromServer);
const getClaimInspectionOrders = (incidentId, claimId) =>
    fetch.doGet(`${prefix}/incident/${incidentId}/claim/${claimId}/inspection-orders`, null, UniqaClaimInspectionOrder.fromServerList);
const getClaimRequiredDocuments = (incidentId, claimId) =>
    fetch.doGet(`${prefix}/incident/${incidentId}/claim/${claimId}/required-documents`, null, UniqaRequiredDocument.fromServerList);
const getClaimOutgoingDocuments = (incidentId, claimId) =>
    fetch.doGet(`${prefix}/incident/${incidentId}/claim/${claimId}/outgoing-documents`, null, UniqaOutgoingDocument.fromServerList);
const sendClaimNote = (incidentId, claimId, values) => fetch.doPost(`${prefix}/incident/${incidentId}/claim/${claimId}/claim-note`, values);
const uploadRequiredDocuments = (incidentId, claimId, values) =>
    fetch.doPost(`${prefix}/incident/${incidentId}/claim/${claimId}/upload-required-documents`, values);

export default {
    getClientIncidents,
    getIncidentDetail,
    getClaimInspectionOrders,
    getClaimRequiredDocuments,
    getClaimOutgoingDocuments,
    sendClaimNote,
    uploadRequiredDocuments,
};
