import React from "react";
import { useSelector } from "react-redux";

import { Typography } from "ui-library/atoms";
import { DataTable } from "ui-library/modules";
import { ContentBox } from "ui-library/layouts";

import { formatPercentage } from "core/formatters";
import i18n from "core/i18n";

import { getInvestmentStrategyDataUniqa } from "../../selectors";

const getColumns = (t) => [
    {
        field: "fundName",
        headerName: t("investments.fundName"),
        sortable: false,
        flex: 2,
    },
    {
        field: "percentage",
        headerName: t("common.percent"),
        sortable: false,
        flex: 0.5,
        renderCell: (params) => formatPercentage(params.value),
    },
];

export const UniqaInvestmentsStrategyTable = () => {
    const { t } = i18n.useTranslation();
    const investmentTariffsData = useSelector(getInvestmentStrategyDataUniqa);

    return (
        <>
            {investmentTariffsData.map((tariff) => (
                <ContentBox mt={5} key={tariff.tariff.code}>
                    <Typography variant="h4">{`${t("common.tariff")}: ${tariff.tariff.value || tariff.tariff.code}`}</Typography>

                    {tariff.tariffPrograms.map((program) => (
                        <ContentBox mt={3} key={program.investmentProgram.code}>
                            <Typography variant="h4">{program.investmentProgram.value}</Typography>

                            <DataTable
                                disableColumnReorder
                                hideFooter
                                withWrapper={false}
                                headerHeight={62}
                                getRowId={(row) => row.fundCode}
                                rows={program.programFunds && program.programFunds.toArray()}
                                columns={getColumns(t)}
                            />
                        </ContentBox>
                    ))}
                </ContentBox>
            ))}
        </>
    );
};
