import React from "react";
import { useSelector } from "react-redux";
import { Map } from "immutable";

import { Accordion, Icon, Loading, StandaloneHtmlLink, Typography } from "ui-library/atoms";
import { ContentBox, Flexbox } from "ui-library/layouts";

import i18n from "core/i18n";
import userInfo from "core/userInfo";

const StatusIcon = ({ key, isActive }) => (
    <Icon key={key} size={16} icon={isActive ? "circle-check" : "circle-error"} color={isActive ? "green" : "red"} />
);

const getConsentsData = (consents) =>
    consents
        .map((consentData, consentEntity) => ({
            title: consentEntity,
            children: (
                <>
                    {consentData &&
                        consentData.map((consent) => (
                            <Flexbox key={consent.get("reactKey")} alignItems={"center"} gap={1} mb={1}>
                                <StatusIcon key={`icon-${consent.get("reactKey")}`} isActive={consent.get("isActive")} />
                                <Typography key={`text-${consent.get("reactKey")}`} variant="caption">
                                    {consent.get("consentReason")}
                                </Typography>
                            </Flexbox>
                        ))}
                </>
            ),
        }))
        .toList()
        .toJS();

export const GdprAccepted = () => {
    const { t } = i18n.useTranslation();
    const date = useSelector(userInfo.getGdprConsentDate);
    const getConsentDate = i18n.createGetFormattedDate(date);
    const consentDate = useSelector(getConsentDate);
    const conditionsUrl = useSelector(userInfo.getGdprUrl);

    const consents = useSelector(userInfo.getConsents) || Map();

    return (
        <>
            {/* Text */}
            <ContentBox>
                <Typography variant={"p"} component={"p"}>
                    {t("userSettings.gdprSubheader")}
                </Typography>
            </ContentBox>
            <ContentBox>
                <Typography markDown={t("userSettings.gdpr")} />
            </ContentBox>
            <ContentBox>
                <Typography variant={"p"} component={"p"} color={"green"}>
                    <Icon icon={"check"} color={"green"} /> {`${t("userSettings.gdpr.consentWasAgreed")} ${consentDate}`}
                </Typography>
            </ContentBox>

            {/* Conditions link */}
            {conditionsUrl && (
                <StandaloneHtmlLink href={conditionsUrl} target="_blank" color={"blue"}>
                    {t("common.detailAgreement")}
                </StandaloneHtmlLink>
            )}

            {/* Consents Accordion */}
            <Loading loading={consents.isEmpty()}>
                <ContentBox mt={3}>
                    <Accordion data={getConsentsData(consents)} id="baseAccordion" />
                </ContentBox>
            </Loading>
        </>
    );
};
