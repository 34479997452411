import { Record } from "immutable";

const RequestStatus = Record({ id: null, color: null, msg: null });

export default Object.freeze({
    NEW: new RequestStatus({
        id: "NEW",
        msg: "requests.status.new",
    }),
    CONFIRMED: new RequestStatus({
        id: "CONFIRMED",
        msg: "requests.status.confirmed",
    }),
    DENIED: new RequestStatus({
        id: "DENIED",
        msg: "requests.status.denied",
    }),
});
