import { combineReducers as immutableCombineReducers } from "redux-immutable";
import { combineReducers, createReducer } from "@reduxjs/toolkit";

import { PensionPp } from "model/pension/pp";
import { PensionPpContract } from "types";

import { clear, setPensionPpContractDetail } from "./actions";
import { ActualStatements, ExtraStatements, TaxStatements, YearStatements, FinancialOperationsTableFilter } from "./modules";

const pensionPpContractDetail = createReducer<PensionPpContract>(PensionPp.fromServer(), (builder) => {
    builder.addCase(setPensionPpContractDetail, (_, { payload }) => payload);
    builder.addCase(clear, () => PensionPp.fromServer());
});

export default immutableCombineReducers({
    data: combineReducers({
        pensionPpContractDetail,
    }),
    appState: immutableCombineReducers({
        yearStatements: YearStatements.reducer,
        extraStatements: ExtraStatements.reducer,
        taxStatements: TaxStatements.reducer,
        actualStatements: ActualStatements.reducer,
        financialOperationsFilter: FinancialOperationsTableFilter.reducer,
    }),
});
