import { Set } from "immutable";

import fundsChangeDssForm from "form/fullPage/pensionFundsChange";

import { ENTITY_FINANCIAL_STATEMENT, ENTITY_FUNDS_CHANGE_PERMITTED, FORM_EDIT_FUNDS_CHANGE } from "../constants";
import { OtpModule } from "../modules";
import { getActualFundsStrategiesByType, getFundsChangeFormChangeType, getIdObject, getPensionAccountDetail } from "../selectors";

export const FullPageFundsChangeForm = fundsChangeDssForm.createContainerDss(
    FORM_EDIT_FUNDS_CHANGE,
    getIdObject,
    getFundsChangeFormChangeType,
    getPensionAccountDetail,
    getActualFundsStrategiesByType,
    OtpModule,
    Set([ENTITY_FINANCIAL_STATEMENT]),
    ENTITY_FUNDS_CHANGE_PERMITTED,
);
