import React, { FC } from "react";
import { useDispatch } from "react-redux";

import { StandaloneLink } from "ui-library/atoms";

import i18n from "core/i18n";
import modal from "core/modal";
import { ChangePropositionStatus } from "enums";
import errorHandling from "core/errorHandling";
import { MODAL_CHANGE_PROPOSITION_DETAIL } from "modules/changePropositions/constants";

export interface DetailLinkActionProps {
    actions: any;
    request: any; //ContractChangeProposition
}

export const DetailLinkAction: FC<DetailLinkActionProps> = ({ request, actions }) => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();

    const showChangePropositionDetail = () => {
        dispatch(errorHandling.removeServiceErrors(MODAL_CHANGE_PROPOSITION_DETAIL));
        dispatch(actions.setCancellationSubmitted(false));
        dispatch(actions.setPageState(request.id, request.changeProposition.type));
        dispatch(modal.openModalForm(MODAL_CHANGE_PROPOSITION_DETAIL));
    };

    return request.status === ChangePropositionStatus.AWAITING_ACCEPTATION.id && request.isActiveAdvisor ? (
        <StandaloneLink color="blue" onClick={showChangePropositionDetail}>
            {t("common.detail")}
        </StandaloneLink>
    ) : null;
};
