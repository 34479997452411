import React, { FC } from "react";
import { useSelector } from "react-redux";

import { Block, StandaloneLink, Typography } from "ui-library/atoms";
import { Box, ContentBox, GridItem, GridLayout } from "ui-library/layouts";
import { DataTable, InfoPanel } from "ui-library/modules";

import entity from "core/entity";
import fetching from "core/fetching";
import i18n from "core/i18n";
import pageContext from "core/pageContext";
import { FinancialOperationStatusFormatter, localAmountWithCurrencyLegacy, localFullDate } from "core/formatters";

import { FeatureFlag, GridItemVariant } from "types";
import { DEFAULT_TABLE_OVERVIEW_PAGE_SIZE } from "app/constants";
import { InsuranceProductPaymentGridItem } from "containers/contract";

const columns = (t) => [
    {
        field: "valueDate",
        headerName: t("common.date"),
        flex: 1.5,
        sortable: false,
        renderCell: (params) => localFullDate(params.value),
    },
    {
        field: "type",
        headerName: t("common.categoryName"),
        flex: 2,
        sortable: false,
    },
    {
        field: "status",
        headerName: t("common.status"),
        flex: 0.5,
        minWidth: 50,
        sortable: false,
        renderCell: (params) => (
            <FinancialOperationStatusFormatter status={params.value} statusPendingText={t("payments.status.pending")} />
        ),
    },
    {
        field: "amount",
        headerName: t("common.amount"),
        flex: 2,
        align: "right",
        headerAlign: "right",
        sortable: false,
        minWidth: 170,
        renderCell: (params) => localAmountWithCurrencyLegacy(params.value),
    },
];

export interface LifeFinancialOperationsOverviewGridItemProps {
    idObject: number;
    financialOperationsEntityName: string;
    selector: any;
    paymentDataEntityName: string;
    detailLink: string;
    hasQuickPayment: boolean;
}

export const LifeFinancialOperationsOverviewGridItem: FC<LifeFinancialOperationsOverviewGridItemProps> = ({
    idObject,
    financialOperationsEntityName,
    selector,
    paymentDataEntityName,
    detailLink,
    hasQuickPayment,
}) => {
    const { t } = i18n.useTranslation();
    const isFinOperationsLoading = useSelector(fetching.isFetching(financialOperationsEntityName, true));
    const isFinOperationsError = useSelector(entity.isError(financialOperationsEntityName));
    const financialOperations = useSelector(selector);

    // @ts-ignore
    const financialOperationsTableData = financialOperations.slice(0, DEFAULT_TABLE_OVERVIEW_PAGE_SIZE);

    const isOnlinePaymentsEnabled = pageContext.useIsFeatureFlagEnabled(FeatureFlag.PAYMENT_CENTER_ONLINE_PAYMENTS);
    const paymentsTitle = isOnlinePaymentsEnabled ? t("payments.pendingPayments.info") : t("common.financialOperationsNotice");

    return (
        <GridItem xs={12}>
            <Block variant="white">
                <GridLayout spacing={8}>
                    <GridItem md={hasQuickPayment ? 7 : 12} xs={12}>
                        <InfoPanel
                            icon={"chart-bar"}
                            title={t("payments.overview")}
                            subTitleSection={
                                <ContentBox mt={3}>
                                    <Typography fontSize={14}>{paymentsTitle}</Typography>
                                </ContentBox>
                            }
                            bgVariant={"clean"}
                            padding={false}
                            headerAction={
                                <StandaloneLink to={detailLink} color="blue">
                                    {t("common.all")}
                                </StandaloneLink>
                            }
                        >
                            <Box>
                                <DataTable
                                    disableColumnReorder
                                    pageSize={DEFAULT_TABLE_OVERVIEW_PAGE_SIZE}
                                    withWrapper={false}
                                    hideFooter
                                    getRowId={(row) => row.id}
                                    rows={financialOperationsTableData.toArray()}
                                    columns={columns(t)}
                                    emptyMsg={t("common.noMovementsOnContract")}
                                    errorMsg={t("error.sectionUnavailable")}
                                    isLoading={isFinOperationsLoading}
                                    isError={isFinOperationsError}
                                />
                            </Box>
                        </InfoPanel>
                    </GridItem>
                    {hasQuickPayment && (
                        <InsuranceProductPaymentGridItem
                            idObject={idObject}
                            entityName={paymentDataEntityName}
                            variant={GridItemVariant.INCLUDED}
                        />
                    )}
                </GridLayout>
            </Block>
        </GridItem>
    );
};
