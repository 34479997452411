import { model } from "core/util";
import { LegalPersonalityCode } from "enums";
import { LegalEntity as LegalEntityType, LegalEntityDto } from "types";

import { Address } from "./Address";
import { IdentityCard } from "./IdentityCard";

export const LegalEntity = model.createModel<LegalEntityDto, LegalEntityType>((json) => ({
    idSubject: json.IdSubject,
    countryCode: json.CountryCode,
    firstName: json.FirstName,
    lastName: json.LastName,
    birthLastName: json.BirthLastName,
    titleBefore: json.TitleBefore,
    titleAfter: json.TitleAfter,
    sexCode: json.SexCode,
    isPerson: !json.LegalPersonalityCode || json.LegalPersonalityCode === LegalPersonalityCode.FO,
    birthDate: json.BirthDate,
    birthPlace: json.BirthPlace,
    birthNumber: json.BirthNumber,
    companyName: json.CompanyName,
    companyID: json.CompanyID,
    citizenship: json.Citizenship,
    idList: IdentityCard.fromServerList(json.IDs),
    addresses: Address.fromServerList(json.Address),
    email: json.Email,
    phone: json.Phone,
}));
