import React, { FC, PropsWithChildren } from "react";
import { BoxProps } from "@mui/material";

import { StyledBlock } from "ui-library/Styled";

export type color =
    | "default"
    | "blue"
    | "muddy"
    | "sky"
    | "tyrkys"
    | "cloud"
    | "blueGradient"
    | "grayGradient"
    | "grayWhite"
    | "greenGradient"
    | "redGradient"
    | "blueSoft"
    | "white";

export interface BlockProps extends PropsWithChildren, Omit<BoxProps, "padding"> {
    variant: color;
    shadow?: boolean;
    padding?: boolean;
}

export const Block: FC<BlockProps> = ({ variant = "default", shadow = false, padding = true, children, ...props }) => {
    return (
        <StyledBlock variant={variant} shadow={shadow} padding={padding} {...props}>
            {children}
        </StyledBlock>
    );
};
