import { ACTUAL_STATEMENT, EXTRA_STATEMENT, TAX_STATEMENT, YEARLY_STATEMENT, YEARLY_STATEMENT_ONLY_LAST_YEAR } from "./constants";

export const STATEMENT_TEXT = {
    [YEARLY_STATEMENT]: "statements.yearlyStatement.text",
    [YEARLY_STATEMENT_ONLY_LAST_YEAR]: "statements.actualStatementOnlyLastYear.text",
    [EXTRA_STATEMENT]: "statements.extraStatement.text",
    [TAX_STATEMENT]: "statements.taxStatement.text",
    [ACTUAL_STATEMENT]: "statements.actualStatement.text",
};

export const MESSAGE_MAPPING = {
    [YEARLY_STATEMENT]: "statements.yearlyStatement.success",
    [YEARLY_STATEMENT_ONLY_LAST_YEAR]: "statements.yearlyStatementOnlyLastYear.success",
    [EXTRA_STATEMENT]: "statements.extraStatement.success",
    [TAX_STATEMENT]: "statements.taxStatement.success",
    [ACTUAL_STATEMENT]: "statements.actualStatement.success",
};
