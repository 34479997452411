import React, { FC, PropsWithChildren } from "react";

import { Chip, Image, Typography } from "ui-library/atoms";
import { Box, Flexbox, LeadLayout } from "ui-library/layouts";
import { StyledLeadImageWrapper } from "ui-library/Styled";
import { LeadLayoutProps } from "ui-library/layouts/LeadLayout/LeadLayout";

export interface PageLeadProps extends PropsWithChildren, LeadLayoutProps {
    title: string;
    lead?: string;
    chip?: string;
    leadImg?: string;
    leadImgDesatured?: boolean;
    subTitleSection?: any;
}

export const PageLead: FC<PageLeadProps> = ({ title, subTitleSection, lead, chip, leadImg, leadImgDesatured, children, ...props }) => {
    return (
        <LeadLayout {...props}>
            <Flexbox justifyContent={"space-between"} flexDirection={{ xs: "column", md: "row" }}>
                <Box order={{ xs: 1, md: 0 }}>
                    <Typography variant="h1">{title}</Typography>
                    {lead && (
                        <Typography color={"gray"} variant="lead">
                            {lead}
                        </Typography>
                    )}
                </Box>
                {chip && (
                    <Box order={{ xs: 0, md: 1 }} pb={{ xs: 2, md: 0 }}>
                        <Chip label={chip} />
                    </Box>
                )}
            </Flexbox>
            <>
                {subTitleSection && <Box mt={{ xs: 1, md: 0 }}>{subTitleSection}</Box>}
                {children && <Box mt={3}>{children}</Box>}
                {leadImg && (
                    <StyledLeadImageWrapper>
                        <Image objectFit={"fill"} shadow={false} src={leadImg} alt={title} desatured={leadImgDesatured} />
                    </StyledLeadImageWrapper>
                )}
            </>
        </LeadLayout>
    );
};
