import React, { FC, ReactNode } from "react";
import { AccordionProps as MUIAccordionProps } from "@mui/material";

import { Icon } from "ui-library/atoms/Icons/Icon";
import { Typography } from "ui-library/atoms/Typography/Typography";
import { Button } from "ui-library/atoms/Button/Button";
import { StyledAccordion, StyledAccordionSummary, StyledAccordionDetails } from "ui-library/Styled";

export interface AccordionProps extends Omit<MUIAccordionProps, "children"> {
    id: string;
    data: {
        title: string | ReactNode;
        children: ReactNode;
    }[];
    deleteAction?: any;
    compact?: boolean;
    defaultLastExpanded?: boolean;
}

export const Accordion: FC<AccordionProps> = ({ id, deleteAction, compact, data, defaultLastExpanded, ...props }) => {
    const [expanded, setExpanded] = React.useState(`${id}-1`);
    const [latestDataSize, setLatestDataSize] = React.useState(0);

    if (defaultLastExpanded && latestDataSize !== data?.length) {
        setLatestDataSize(data.length);
        setExpanded(id + (data?.length - 1));
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <>
            {data
                ? data.map(({ title, children }, i) => (
                      <StyledAccordion {...props} expanded={expanded === id + i} onChange={handleChange(id + i)} key={i}>
                          <StyledAccordionSummary
                              key={i}
                              expandIcon={
                                  expanded === id + i ? (
                                      <Icon size={12} icon="minus" color="gray" />
                                  ) : (
                                      <Icon size={12} icon="plus" color="gray" />
                                  )
                              }
                              aria-controls={id + i}
                              id={id + i}
                              className={compact && "header-compact"}
                          >
                              {React.isValidElement(title) ? <>{title}</> : <Typography variant="p">{title}</Typography>}
                              {deleteAction && (
                                  <Button
                                      onClick={() => deleteAction(i)}
                                      endIcon={"trash-can"}
                                      variant="text"
                                      rounded
                                      color="gray"
                                      size="small"
                                  />
                              )}
                          </StyledAccordionSummary>
                          <StyledAccordionDetails>
                              <Typography variant="p">{children}</Typography>
                          </StyledAccordionDetails>
                      </StyledAccordion>
                  ))
                : ""}
        </>
    );
};
