import { NAME } from "./constants";
import innerRoutes from "./innerRoutes";

import { InnerContainer } from "./containers";

export { default as ImpersonatePage } from "./Container";

export const ImpersonateTabs = {
    InnerContainer,
};

export default {
    NAME,
    innerRoutes,
};
