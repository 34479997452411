import { model } from "core/util";

import { AmountLegacy, SimpleCodeValue } from "../common";
import { ElectronicCommunicationLegacy } from "../electronicCommunication";
import { HolderLegacy } from "../holder";

import { BalanceOnContract } from "./BalanceOnContract";
import { ContractParameters } from "./ContractParameters";
import { HarmonizedPaymentsScheduler } from "./HarmonizedPaymentsScheduler";
import { InsuredRealEstateProperty } from "./InsuredRealEstateProperty";
import { InsuredVehicle } from "./InsuredVehicle";
import { PaymentRequest } from "./PaymentRequest";
import { Vehicle } from "./Vehicle";
import { UniqaLifeAccountDetail } from "../uniqaLife/UniqaLifeAccountDetail";

export const HarmonizedContractDetail = model.createModelLegacy("HarmonizedContractDetail", (json) => ({
    category: SimpleCodeValue.fromServer(json.Category),
    identifier: json.Identifier,
    contractParameters: ContractParameters.fromServer(json.ContractParameters),
    insuranceCostSummary: AmountLegacy.fromServer(json.InsuranceCostSummary),
    actualPaymentsScheduler: HarmonizedPaymentsScheduler.fromServerList(json.ActualPaymentsScheduler),
    advancePaymentsScheduler: HarmonizedPaymentsScheduler.fromServerList(json.AdvancePaymentsScheduler),
    paymentsScheduler: HarmonizedPaymentsScheduler.fromServerList(json.PaymentsScheduler),
    paymentRequests: PaymentRequest.fromServerList(json.PaymentRequests),
    balanceOnContract: BalanceOnContract.fromServer(json.BalanceOnContract),
    insuredRealEstateProperties: InsuredRealEstateProperty.fromServerList(json.InsuredRealEstateProperties),
    insuredVehicles: InsuredVehicle.fromServerList(json.InsuredVehicles),
    vehicles: Vehicle.fromServerList(json.Vehicles),
    holder: HolderLegacy.fromServer(json.Holder),
    electronicCommunication: ElectronicCommunicationLegacy.fromServer(json.ElectronicCommunication),
    lifeAccountDetail: UniqaLifeAccountDetail.fromServer(json.LifeAccountDetail),
}));
