import investmentProfile from "form/fullPage/investmentProfile";
import { ENTITY_FINANCIAL_STATEMENT, FORM_INVESTMENT_PROFILE } from "../constants";
import {
    getAvailableFundsProfileChangeOptions,
    getContractNumber,
    getFilteredAvailableProfileOptions,
    getIdObject,
    getInvestmentProfileFromData,
    isActualInvestmentProfileIndividual,
    isEntryFeePaid,
    isProductTempoCz,
} from "../selectors";
import { OtpModule } from "../modules";

export const InvestmentProfileForm = investmentProfile.createContainer(
    FORM_INVESTMENT_PROFILE,
    getContractNumber,
    getInvestmentProfileFromData,
    getFilteredAvailableProfileOptions,
    getAvailableFundsProfileChangeOptions,
    ENTITY_FINANCIAL_STATEMENT,
    isEntryFeePaid,
    isProductTempoCz,
    isActualInvestmentProfileIndividual,
    getIdObject,
    OtpModule,
);
