import { createAction } from "@reduxjs/toolkit";

import { app } from "core/util";
import { PensionPpContract } from "types";

import { NAME } from "./constants";

export const setPensionPpContractDetail = app.createActionWithPayload<PensionPpContract>(`${NAME}/SET_PENSION_PP_CONTRACT_DETAIL`);

export const clear = createAction(`${NAME}/CLEAR`);
