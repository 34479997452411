import { NAME } from "./constants";

export const CLEAR = `${NAME}/CLEAR`;

export const SET_INCIDENTS = `${NAME}/SET_INCIDENTS`;
export const SET_DISPLAYED_INCIDENT_STATE_TYPE = `${NAME}/SET_DISPLAYED_INCIDENT_STATE_TYPE`;
export const SET_SELECTED_CONTRACT_ID = `${NAME}/SET_SELECTED_CONTRACT_ID`;

export const setIncidents = (incidents) => ({
    type: SET_INCIDENTS,
    payload: incidents,
});

export const setDisplayedIncidentState = (incidentState) => ({
    type: SET_DISPLAYED_INCIDENT_STATE_TYPE,
    payload: incidentState,
});

export const setStoredContractId = (contractId) => ({
    type: SET_SELECTED_CONTRACT_ID,
    payload: contractId,
});

export const clear = () => ({
    type: CLEAR,
});
