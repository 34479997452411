import React from "react";

import i18n from "core/i18n";
import { ContentBox } from "ui-library/layouts";
import { Typography } from "ui-library/atoms";

import { localAmountWithCurrency } from "core/formatters";

export const FeeNotPaidComponent = ({ requiredFee, paidFee }) => {
    const { t } = i18n.useTranslation();
    return (
        <ContentBox>
            <ContentBox>
                <Typography markDown={t("investments.profileChangeDisabledText")} />
            </ContentBox>
            <ContentBox>
                <Typography markDown={t("investments.investmentProfileNotification")} />
            </ContentBox>
            <Typography markDown={t("investments.requiredFee", { requiredFee: localAmountWithCurrency(requiredFee) })} />
            <Typography markDown={t("investments.paidFee", { paidFee: localAmountWithCurrency(paidFee) })} />
        </ContentBox>
    );
};
