import * as React from "react";
import PropTypes from "prop-types";
import { ErrorContent, Label, PopoverTooltip, Typography } from "ui-library/atoms";
import { Box } from "ui-library/layouts";
import { StyledSwitch, StyledSwitchFormControlLabel, StyledSwitchFormGroup } from "ui-library/Styled";

export const Switch = ({ id, isChecked, disabled, onChange, label, labelOptional, tooltip, footNote, errors }) => {
    return (
        <>
            <StyledSwitchFormGroup>
                <StyledSwitchFormControlLabel
                    control={<StyledSwitch checked={isChecked} onChange={onChange} id={id} disabled={disabled} />}
                    label={
                        <Box ml={1}>
                            <Label label={label} id={id} labelOptional={labelOptional} />
                        </Box>
                    }
                />
                {tooltip && <PopoverTooltip className="checkbox-tooltip" title={tooltip} />}
            </StyledSwitchFormGroup>

            {/* Footnote */}
            {footNote && (
                <Box mt={1}>
                    <Typography component="p" fontSize={14} markDown={footNote} />
                </Box>
            )}

            {/* Error message - From redux store */}
            {errors?.size > 0 && (
                <Box mt={1} mb={1} ml={1}>
                    {errors.map((error) => (
                        <ErrorContent key={error} content={error} />
                    ))}
                </Box>
            )}
        </>
    );
};

Switch.defaultProps = {};

Switch.propTypes = {
    id: PropTypes.string,
    isChecked: PropTypes.bool,
    onChange: PropTypes.func,
    label: PropTypes.any,
    labelOptional: PropTypes.string,
    tooltip: PropTypes.string,
    footNote: PropTypes.string,
    errors: PropTypes.any,
};
