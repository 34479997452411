import moment from "moment/moment";
import { date as dateUtil, fn } from "core/util";

export const dateObjToLocalFullDate = (date: Date): string => {
    if (date) {
        return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
    } else {
        return "";
    }
};

export const timestampToDateTime = (timestamp: number): string => {
    if (!timestamp) {
        return "";
    }
    return moment(timestamp).format("DD.MM.YYYY, HH:mm");
};

export const dateObjToLocalMonthPeriod = (date: Date): string => {
    if (date) {
        return `${date.getMonth() + 1}/${date.getFullYear()}`;
    } else {
        return "";
    }
};

export const localFullDate = (value?: string): string => {
    if (value) {
        return value.replace(/(\d{4})-(\d{2})-(\d{2})/g, "$3.$2.$1").slice(0, 10);
    } else {
        return "";
    }
};

export const dateTimeToLocalFullDate = (value: string): string => {
    if (value) {
        return localFullDate(value.split("T")[0]);
    } else {
        return "";
    }
};

export const dateTimeToLocalMonthPeriod = (value: string): string => {
    if (value) {
        const dateString = value.split("T")[0];
        if (dateString) {
            return value.replace(/(\d{4})-(\d{2})-(\d{2})/g, "$2/$1").slice(0, 10);
        }
    }
    return "";
};

/**
 * transforms date in form YYYY-MM-DD into MM/YYYY form
 * e.g. 2018-05-20 -> 05/2018
 */
export const localPeriod = (value: string): string => {
    if (value) {
        return value.replace(/(\d{4})-(\d{2})-(\d{2})/g, "$2/$1");
    } else {
        return "";
    }
};

export const formatUnlimitedDate = (dateValue: string, unlimitedText: string) =>
    fn.isEmpty(dateValue) || dateUtil.unlimitedDateFormat.includes(dateValue) ? unlimitedText : localFullDate(dateValue);
