import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ModalState, initialState, NAME } from "./constants";

const openModalForm: CaseReducer<ModalState, PayloadAction<string>> = (state, { payload }) => {
    state.forms.push(payload);
};
const closeModalForm: CaseReducer<ModalState, PayloadAction<string>> = (state, { payload }) => {
    state.forms = state.forms.filter((f) => f !== payload);
};
const closeAllModalForms: CaseReducer<ModalState> = (state) => {
    state.forms = [];
};

export const slice = createSlice({
    name: NAME,
    initialState,
    reducers: {
        openModalForm,
        closeModalForm,
        closeAllModalForms,
    },
});

export default slice;
