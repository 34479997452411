import createModel from "../createModel";

import { UniqaClaimRisk } from "./UniqaClaimRisk";
import { UniqaClaimSubject } from "./UniqaClaimSubject";

export const UniqaClaim = createModel("UniqaClaim", (json) => ({
    incidentId: json.incidentId,
    claimId: json.claimId,
    claimNumber: json.claimNumber,
    lossSum: json.lossSum,
    shouldDisplaySum: json.shouldDisplaySum,
    reportDate: json.reportDate,
    closureDate: json.closureDate,
    closureType: json.closureType,
    plateNumber: json.plateNumber,
    damageScope: json.damageScope,
    claimState: json.claimState,
    claimCategory: json.claimCategory,
    risk: UniqaClaimRisk.fromServer(json.risk),
    subject: UniqaClaimSubject.fromServer(json.subject),
}));
