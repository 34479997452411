import { model } from "core/util";

import { AmountLegacy, CodeValueLegacy } from "model/common";

export const MutualFundsRegularFinancialOperation = model.createModelLegacy("MutualFundsRegularFinancialOperation", (json) => ({
    id: json.Id,
    valueDate: json.ValueDate,
    type: CodeValueLegacy.fromServer(json.Type),
    nettoInvestedAmount: AmountLegacy.fromServer(json.NettoInvestedAmount),
    feePayment: AmountLegacy.fromServer(json.FeePayment),
    roundingFee: AmountLegacy.fromServer(json.RoundingFee),
    investedAmount: AmountLegacy.fromServer(json.InvestedAmount),
    payment: AmountLegacy.fromServer(json.Payment),
    cumulativeFee: AmountLegacy.fromServer(json.CumulativeFee),
    cumulativeInvestment: AmountLegacy.fromServer(json.CumulativeInvestment),
}));
