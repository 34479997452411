import { model } from "core/util";
import { BankAccount as BankAccountType, BankAccountDto } from "types";

import { CodeValue } from "./CodeValue";

export const BankAccount = model.createModel<BankAccountDto, BankAccountType>((json) => ({
    iban: json.iban,
    prefix: json.prefix,
    number: json.number,
    bankCode: json.bankCode,
    variableSymbol: json.variableSymbol,
    specificSymbol: json.specificSymbol,
}));
