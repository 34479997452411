import { NormalizedData, ProductPolicy } from "types";
import { ProductGroupLvl2, PolicyType, PolicyUrlName, ProductGroup } from "enums";

export const ProductPolicies: NormalizedData<ProductPolicy> = {
    LIFE_RISK: {
        // Accident insurance
        id: "LIFE_RISK",
        productGroup: ProductGroup.LIFE,
        productGroupLevelTwo: ProductGroupLvl2.LIFE_RISK,
        type: PolicyType.LIFE,
        urlName: PolicyUrlName.LIFE,
    },
    LIFE_SAVINGS: {
        // Investment life insurance
        id: "LIFE_SAVINGS",
        productGroup: ProductGroup.LIFE,
        productGroupLevelTwo: ProductGroupLvl2.LIFE_SAVINGS,
        type: PolicyType.LIFE,
        urlName: PolicyUrlName.LIFE,
    },
    LIFE_ONETIME_SAVINGS: {
        // Single investment life insurance
        id: "LIFE_ONETIME_SAVINGS",
        productGroup: ProductGroup.LIFE,
        productGroupLevelTwo: ProductGroupLvl2.LIFE_ONETIME_SAVINGS,
        type: PolicyType.LIFE,
        urlName: PolicyUrlName.LIFE,
    },
    VEHICLE: {
        // Car insurance
        id: "VEHICLE",
        productGroup: ProductGroup.VEHICLE,
        productGroupLevelTwo: ProductGroupLvl2.VEHICLE,
        type: PolicyType.CAR,
        urlName: PolicyUrlName.VEHICLE,
    },
    HOME: {
        // Home insurance
        id: "HOME",
        productGroup: ProductGroup.HOME,
        productGroupLevelTwo: ProductGroupLvl2.HOME,
        type: PolicyType.HOME,
        urlName: PolicyUrlName.HOME,
    },
    BUFT: {
        // Home insurance
        id: "BUFT",
        productGroup: ProductGroup.BUSINESS,
        productGroupLevelTwo: ProductGroupLvl2.BUFT,
        type: PolicyType.HOME,
        urlName: PolicyUrlName.HOME,
    },
    TRANSPORT: {
        // Home insurance
        id: "TRANSPORT",
        productGroup: ProductGroup.BUSINESS,
        productGroupLevelTwo: ProductGroupLvl2.TRANSPORT,
        type: PolicyType.HOME,
        urlName: PolicyUrlName.HOME,
    },
    LIABILITY: {
        // Home insurance
        id: "LIABILITY",
        productGroup: ProductGroup.BUSINESS,
        productGroupLevelTwo: ProductGroupLvl2.LIABILITY,
        type: PolicyType.HOME,
        urlName: PolicyUrlName.HOME,
    },
    BUSINESS: {
        // Home insurance
        id: "BUSINESS",
        productGroup: ProductGroup.BUSINESS,
        productGroupLevelTwo: ProductGroupLvl2.BUSINESS,
        type: PolicyType.HOME,
        urlName: PolicyUrlName.HOME,
    },
    INDUSTRY: {
        // Business
        id: "INDUSTRY",
        productGroup: ProductGroup.BUSINESS,
        productGroupLevelTwo: ProductGroupLvl2.INDUSTRY,
        type: PolicyType.HOME,
        urlName: PolicyUrlName.HOME,
    },
    MUTUAL_FUNDS: {
        // Single payments
        id: "MUTUAL_FUNDS",
        productGroup: ProductGroup.INVESTMENT,
        productGroupLevelTwo: ProductGroupLvl2.MUTUAL_FUNDS,
        type: PolicyType.INVESTMENT,
        urlName: PolicyUrlName.MUTUAL_FUNDS_DIRECT,
    },
    REGULAR_INVESTMENTS: {
        // Regular payments
        id: "REGULAR_INVESTMENTS",
        productGroup: ProductGroup.INVESTMENT,
        productGroupLevelTwo: ProductGroupLvl2.REGULAR_INVESTMENTS,
        type: PolicyType.INVESTMENT,
        urlName: PolicyUrlName.MUTUAL_FUNDS_REGULAR,
    },
    PENSION_PP: {
        // I. Pillar (PP)
        id: "PENSION_PP",
        productGroup: ProductGroup.PENSION,
        productGroupLevelTwo: ProductGroupLvl2.PENSION_PP,
        type: PolicyType.RETIREMENT,
        urlName: PolicyUrlName.PENSION_PP,
    },
    PENSION_DSS: {
        // II. Pillar (DSS)
        id: "PENSION_DSS",
        productGroup: ProductGroup.PENSION,
        productGroupLevelTwo: ProductGroupLvl2.PENSION_DSS,
        type: PolicyType.RETIREMENT,
        urlName: PolicyUrlName.PENSION_DSS,
    },
    PENSION_DPS_DDS: {
        // III. Pillar (DPS,DDS)
        id: "PENSION_DPS_DDS",
        productGroup: ProductGroup.PENSION,
        productGroupLevelTwo: ProductGroupLvl2.PENSION_DPS_DDS,
        type: PolicyType.RETIREMENT,
        urlName: PolicyUrlName.PENSION_DPS,
    },
    TRAVEL: {
        id: "TRAVEL",
        productGroup: ProductGroup.TRAVEL,
        productGroupLevelTwo: ProductGroupLvl2.TRAVEL,
        type: PolicyType.TRAVEL,
        urlName: PolicyUrlName.TRAVEL,
    },
    LIFE_RISK_14: {
        // Accident insurance
        id: "LIFE_RISK_14",
        productGroup: ProductGroup.LIFE,
        productGroupLevelTwo: ProductGroupLvl2.LIFE_INSURANCE_OTHER,
        type: PolicyType.LIFE,
        urlName: PolicyUrlName.LIFE,
    },
    LIFE_RISK_15: {
        // Accident insurance
        id: "LIFE_RISK_15",
        productGroup: ProductGroup.LIFE,
        productGroupLevelTwo: ProductGroupLvl2.ACCIDENT_INSURANCE,
        type: PolicyType.LIFE,
        urlName: PolicyUrlName.LIFE,
    },
    LIFE_SAVINGS_16: {
        // Investment life insurance
        id: "LIFE_SAVINGS_16",
        productGroup: ProductGroup.LIFE,
        productGroupLevelTwo: ProductGroupLvl2.LIFE_INVESTMENT_ACCOUNT,
        type: PolicyType.LIFE,
        urlName: PolicyUrlName.LIFE,
    },
    LIFE_SAVINGS_18: {
        // Investment life insurance
        id: "LIFE_SAVINGS_18",
        productGroup: ProductGroup.LIFE,
        productGroupLevelTwo: ProductGroupLvl2.LIFE_SAVINGS_18,
        type: PolicyType.LIFE,
        urlName: PolicyUrlName.LIFE,
    },
    LIFE_SAVINGS_24: {
        // Investment life insurance
        id: "LIFE_SAVINGS_24",
        productGroup: ProductGroup.LIFE,
        productGroupLevelTwo: ProductGroupLvl2.LIFE_SAVINGS_24,
        type: PolicyType.LIFE,
        urlName: PolicyUrlName.LIFE,
    },
    LIFE_SAVINGS_25: {
        // Investment life insurance
        id: "LIFE_SAVINGS_25",
        productGroup: ProductGroup.LIFE,
        productGroupLevelTwo: ProductGroupLvl2.LIFE_SAVINGS_25,
        type: PolicyType.LIFE,
        urlName: PolicyUrlName.LIFE,
    },
    LIFE_SAVINGS_26: {
        // Investment life insurance
        id: "LIFE_SAVINGS_26",
        productGroup: ProductGroup.LIFE,
        productGroupLevelTwo: ProductGroupLvl2.LIFE_SAVINGS_26,
        type: PolicyType.LIFE,
        urlName: PolicyUrlName.LIFE,
    },
    LIFE_SAVINGS_27: {
        // Investment life insurance
        id: "LIFE_SAVINGS_27",
        productGroup: ProductGroup.LIFE,
        productGroupLevelTwo: ProductGroupLvl2.LIFE_SAVINGS_27,
        type: PolicyType.LIFE,
        urlName: PolicyUrlName.LIFE,
    },
    LIFE_SAVINGS_28: {
        // Investment life insurance
        id: "LIFE_SAVINGS_28",
        productGroup: ProductGroup.LIFE,
        productGroupLevelTwo: ProductGroupLvl2.LIFE_SAVINGS_28,
        type: PolicyType.LIFE,
        urlName: PolicyUrlName.LIFE,
    },
    LIFE_SAVINGS_29: {
        // Investment life insurance
        id: "LIFE_SAVINGS_29",
        productGroup: ProductGroup.LIFE,
        productGroupLevelTwo: ProductGroupLvl2.LIFE_SAVINGS_29,
        type: PolicyType.LIFE,
        urlName: PolicyUrlName.LIFE,
    },
};
