import React, { FC } from "react";

import { Box } from "ui-library/layouts";
import { Container, ImportantAnnouncements } from "ui-library/atoms";

import i18n from "core/i18n";
import router from "core/router";
import { Pages, Tabs } from "routeConstants";

export interface FullPageSuccessProps {
    title?: string;
    message?: string;
    BackButton?: any;
}

export const FullPageSuccess: FC<FullPageSuccessProps> = ({ title, message, BackButton }) => {
    const { t } = i18n.useTranslation();
    const link = router.getStaticUrl(Pages.MESSAGES, Tabs.REQUESTS);

    return (
        <Container maxWidth="md">
            <Box mb={5}>
                <ImportantAnnouncements
                    shadow
                    variant={"SUCCESS"}
                    data={{
                        heading: title || t("success.title.sent"),
                        content: message || t("success.text", { link }),
                    }}
                />
            </Box>
            <Box mb={30}>{BackButton}</Box>
        </Container>
    );
};
