import fn from "core/util/fn";
import { nbsp } from "app/constants";
import { ContractPolicy, PaymentsScheduler } from "types";
import { ProductGroup, ProductGroupLvl2 } from "enums";

import { dateTimeToLocalFullDate, localFullDate } from "./dateFormatter";
import {
    localAmountWithCurrencyBasedOnSite,
    localAmountWithCurrencyBasedOnSiteLegacy,
    localAmountWithCurrencyLegacy,
} from "./localAmountFormatter";
import { formatPercentage } from "./numberFormatter";
import { formatLocalizedValueMap, formatMutualFundsRegularMonthlyPayment } from "./productFormatter";
import { TFunction } from "../i18n";

const INAS_UNLIMITED_PERIOD_YEAR = 2099;

export const formatContractEndDate = (value: string, unlimitedMessage: string): string => {
    if (fn.isEmpty(value)) {
        return unlimitedMessage;
    }

    const datePart = value.split("T")[0];
    const year = datePart.slice(0, 4);

    // INAS contract for unlimited period.
    if (parseInt(year) === INAS_UNLIMITED_PERIOD_YEAR) {
        return unlimitedMessage;
    }

    return localFullDate(value.split("T")[0]);
};

export const formatContractOverview = (
    t: TFunction,
    contract: ContractPolicy,
    frequencyMap,
    paymentContributionMap,
    isSlovakSite: boolean,
): string => {
    if (!contract.overview) {
        return "...";
    }

    const contractPayments = formatContractPaymentsForContractOverview(contract, frequencyMap, paymentContributionMap, isSlovakSite);
    const contractBalance = localAmountWithCurrencyLegacy(contract.overview.investedAmount);
    const pensionBeingPaidText =
        contract.idEnuProductGroup === ProductGroup.PENSION && contract.overview.isPassive ? t("common.pensionBeingPaid") : null;

    return [contractPayments, contractBalance, pensionBeingPaidText].filter((value) => !!value).join(", ");
};

export const formatPaymentsSchedulerAmount = (paymentsScheduler: PaymentsScheduler, isSlovakSite = false) => {
    const amount =
        !fn.isEmpty(paymentsScheduler.paymentType) && paymentsScheduler.paymentType === "P"
            ? formatPercentage(paymentsScheduler.modalAmount.value)
            : localAmountWithCurrencyBasedOnSite(paymentsScheduler.modalAmount, isSlovakSite);

    if (fn.isEmpty(amount)) {
        return "";
    }
    return amount;
};

export const formatPaymentsSchedulerAmountLegacy = (paymentsScheduler, isSlovakSite = false) => {
    const amount =
        !fn.isEmpty(paymentsScheduler.paymentType) && paymentsScheduler.paymentType === "P"
            ? formatPercentage(paymentsScheduler.modalAmount.value)
            : localAmountWithCurrencyBasedOnSiteLegacy(paymentsScheduler.modalAmount, isSlovakSite);

    if (fn.isEmpty(amount)) {
        return "";
    }
    return amount;
};

export const formatPaymentsSchedulerDate = (paymentScheduler) => {
    if (paymentScheduler) {
        const startDate = dateTimeToLocalFullDate(paymentScheduler.get("paymentStartDate"));
        const endDate = dateTimeToLocalFullDate(paymentScheduler.get("paymentEndDate"));
        return startDate && endDate ? `${startDate}${nbsp}-${nbsp}${endDate}` : "";
    }

    return "";
};

export const formatPaymentsSchedulerFull = (
    paymentsScheduler: PaymentsScheduler,
    frequencyMap,
    paymentContributionMap,
    isSlovakSite = false,
) => {
    if (fn.isEmpty(paymentsScheduler)) {
        return "";
    }

    const amount = formatPaymentsSchedulerAmount(paymentsScheduler, isSlovakSite);
    const period = formatLocalizedValueMap(paymentsScheduler.periodicity.code, frequencyMap);
    const paymentMode = formatLocalizedValueMap(paymentsScheduler.paymentMode?.code, paymentContributionMap);

    if (fn.isEmpty(amount)) {
        return "";
    }

    if (fn.isEmpty(period)) {
        return amount;
    }

    const amountAndPeriod = `${amount} / ${period}`;
    if (fn.isEmpty(paymentMode)) {
        return amountAndPeriod;
    } else {
        return `${amountAndPeriod}, ${paymentMode}`;
    }
};

export const formatPaymentsSchedulerFullLegacy = (paymentsScheduler, frequencyMap, paymentContributionMap, isSlovakSite = false) => {
    if (fn.isEmpty(paymentsScheduler)) {
        return "";
    }

    const amount = formatPaymentsSchedulerAmountLegacy(paymentsScheduler, isSlovakSite);
    const period = formatLocalizedValueMap(paymentsScheduler.periodicity.code, frequencyMap);
    const paymentMode = formatLocalizedValueMap(paymentsScheduler.paymentMode.code, paymentContributionMap);

    if (fn.isEmpty(amount)) {
        return "";
    }

    if (fn.isEmpty(period)) {
        return amount;
    }

    const amountAndPeriod = `${amount} / ${period}`;
    if (fn.isEmpty(paymentMode)) {
        return amountAndPeriod;
    } else {
        return `${amountAndPeriod}, ${paymentMode}`;
    }
};

export const formatContractPaymentsForContractOverview = (
    contract: ContractPolicy,
    frequencyMap,
    paymentContributionMap,
    isSlovakSite: boolean,
): string => {
    if (contract.idEnuProductGroupLevelTwo === ProductGroupLvl2.REGULAR_INVESTMENTS) {
        return formatMutualFundsRegularMonthlyPayment(contract.overview.monthlyPayment, frequencyMap, isSlovakSite);
    }
    return formatPaymentsSchedulerFullLegacy(contract.overview.paymentScheduler, frequencyMap, paymentContributionMap, isSlovakSite);
};
