import { actionChannel, all, call, delay, fork, put, select, spawn, take } from "redux-saga/effects";
import auth from "core/auth";
import cmsContent from "core/cmsContent";
import chooseContractForChangeModal from "core/chooseContractForChangeModal";
import init from "core/init";
import i18n from "core/i18n";
import impersonate from "core/impersonate";
import modal from "core/modal";
import pageContext from "core/pageContext";
import router from "core/router";
import user from "core/user";
import userContracts from "core/userContracts";
import userInfo from "core/userInfo";
import { CallCenterContactData, ExternalUrl, Locales, PortalData, ProductGroup } from "enums";
import { czProductLines, skProductLines } from "core/products/ProductLines";
import { SetContextPayload } from "core/pageContext/constants";
import { ConfigDto } from "types/generated";
import { configApi, translationsApi, useCaseApi } from "serverApi";
import { pageRoutes } from "routeUrls";
import { Pages, Tabs } from "routeConstants";

import { PUBLIC_PAGES } from "./constants";

const productPages = [
    Pages.CONTRACT_VEHICLE,
    Pages.CONTRACT_HOME,
    Pages.CONTRACT_LIFE,
    Pages.CONTRACT_DIRECT,
    Pages.CONTRACT_REGULAR,
    Pages.CONTRACT_DDS,
    Pages.CONTRACT_DPS,
    Pages.CONTRACT_DSS,
    Pages.CONTRACT_PP,
    Pages.CONTRACT_TRAVEL,
];

export default function* () {
    yield call(loadSiteInfo);
    yield call(loadTranslations);
    yield call(setTranslationsDefaultVariables);

    // Check Sso login.
    yield call(auth.ssoLoginSaga);

    // Check impersonate access.
    yield call(impersonate.impersonateSaga);

    // Load RSS content.
    yield fork(cmsContent.loadRssContent);

    yield call(loadUserInfoAndHandleCases);

    // channel for taking router.ROUTE_ENTERED must be created prior calling router.setPageByLocationDirectly
    // if we just call take router.ROUTE_ENTERED afterwards, its apparently too late, action was already dispatched.
    // normally it would not happen because javascript is single threaded, but there is some history.replace magic, which
    // can probably somehow interrupt js event loop.
    const channel = yield actionChannel(router.routeEntered.type);
    // router.setPageByLocationDirectly will appropriately initialize and enter initial route for app according to URL.
    yield fork(router.setPageByLocationDirectly, window.location);
    // after all the magic is done (action router.ROUTE_ENTERED is dispatched)
    yield take(channel);
    channel.close(); // just good manners, we won't need this channel anymore

    yield fork(router.startRouting);

    // we can show application component, which has already all data for page needed.
    yield put(init.initialize());
}

function* loadSiteInfo() {
    const [config, localeConfig]: [ConfigDto, any] = yield all([call(configApi.getConfig), call(configApi.getLocaleConfig)]);

    yield put(auth.setInactiveInterval(config.sessionMaxInactiveInterval));

    const context: SetContextPayload = {
        clientId: config.clientId,

        configUrls: {
            authUri: config.authUri,
            claimReportingUrl: config.claimReportingUrl,
            claimReportingUrls: config.claimReportingUrls,
            logoutUri: config.logoutUri,
        },
        disclaimer: {
            text: config.disclaimerText,
            version: config.disclaimerVersion,
        },
        featureFlags: config.featureFlags,
        infoTexts: config.infoTexts,

        keys: {
            recaptcha: config.recaptchaSiteKey,
            vapid: config.vapidPublicKey,
            youtube: config.youtubePromoId,
        },
        notAllowed: {
            targetFundCodesCz: config.notAllowedTargetFundCodesCz,
            targetFundCodesSk: config.notAllowedTargetFundCodesSk,
        },
        pageLocalization: {
            locale: localeConfig.locale,
            availableLocales: localeConfig.availableLocales,
            site: localeConfig.site,
        },
    };
    yield all([put(pageContext.setContext(context))]);
}

function* loadTranslations() {
    const site = yield select(pageContext.getSite);
    const locale = yield select(pageContext.getLocale);
    const localMessages = yield call(translationsApi.getTranslations, site, locale);
    const enMessages = yield call(translationsApi.getTranslations, site, Locales.en_US);

    yield all([
        call(i18n.addTranslations, locale.languageCode, localMessages),
        call(i18n.addTranslations, Locales.en_US.languageCode, enMessages),
        call(i18n.changeLanguage, locale.languageCode),
    ]);

    // Handle initial setup of en language if lang=en is in url param. At this moment it is set in a way
    // that initially local language is set and only after en. If en is set initially i18n is not working.
    // TODO: We will need to investigate / fix this.
    const enLocale = Locales.en_US;
    const searchParams = new URLSearchParams(window.location?.search);
    if (searchParams.get(router.LANG_PARAM) === Locales.en_US.languageCode) {
        yield all([put(pageContext.setLocale(enLocale)), call(i18n.changeLanguage, enLocale.languageCode)]);
    }
}

function* setTranslationsDefaultVariables() {
    const site = yield select(pageContext.getSite);
    const { defaultVariables, setDefaultInterpolationVariables } = i18n;

    const defaultValues = {
        [defaultVariables.PORTAL_NAME]: PortalData.PORTAL_NAME[site?.id],
        [defaultVariables.CC_EMAIL]: CallCenterContactData.EMAIL[site?.id],
        [defaultVariables.CC_PHONE]: CallCenterContactData.PHONE[site?.id],
    };

    yield call(setDefaultInterpolationVariables, defaultValues);
}

function* loadUserInfoAndHandleCases() {
    try {
        yield call(userInfo.loadCriticalUserInfo);
        yield put(auth.setLogged());

        const isAuthenticated = yield select(user.getIsAuthenticated);
        const isOneTimeRole = yield select(user.getIsOneTimeRole);

        if (isAuthenticated) {
            yield call(handleAuthenticatedUser);
        } else if (isOneTimeRole) {
            yield call(handleOneTime);
        }
    } catch (e) {
        // We are touching raw windows.location because router.getCurrentRoute is set way after this saga is run (withAsyncModule - onRouteEntered)
        /* eslint-disable-next-line no-restricted-globals */
        if (PUBLIC_PAGES.some((page) => location.pathname.includes(pageRoutes[page]))) {
            // do not redirect
        } else {
            yield put(router.navigate(Pages.LOGIN));
        }
    }
}

function* handleAuthenticatedUser() {
    const { pathname, search } = window.location;
    const pageInformation = router.parseLocation(pathname, search);
    const acceptedDisclaimer = yield select(userInfo.getCurrentDisclaimerAccepted);
    if (!acceptedDisclaimer) {
        yield put(router.navigate(Pages.DISCLAIMER));
        /* eslint-disable-next-line no-restricted-globals */
    } else if (pageInformation?.name === Pages.USE_CASE && pageInformation?.innerName === Tabs.UC_CHANGE) {
        yield put(router.navigate(Pages.CONTRACTS));
        yield put(modal.openModalForm(chooseContractForChangeModal.FORM_CHOOSE_CONTRACT_FOR_CHANGE));
    } else if (location.pathname.includes(pageRoutes[Pages.LOGIN]) || location.pathname === "/") {
        // if logged and on login page, redirect to contract. When done in login saga, login page will briefly show itself.
        // This is optimization of this blink
        yield put(router.navigate(Pages.CONTRACTS));
        // some delay is needed to avoid login page blink
        // in production, app init is so fast, that transition is done after rendering the login component
        yield delay(100);
    }

    // TODO: Find better way to handle choose contract for edit modal.
    yield spawn(chooseContractForChangeModal.createSaga, chooseContractForChangeModal.FORM_CHOOSE_CONTRACT_FOR_CHANGE);

    yield spawn(userInfo.loadAgents);
    yield spawn(userInfo.loadMessages);
}

function* handleOneTime() {
    const { pathname, search } = window.location;
    const pageInformation = router.parseLocation(pathname, search);
    const isOneTimeChangeRole = yield select(user.getIsOneTimeChangeRole);
    const isOneTimeClaimRole = yield select(user.getIsOneTimeClaimRole);

    // TODO: Temporary solution to create redirect for one time changes outside of vehicles.
    // yield call(handleOtChangeNavigateToERegistry);

    // One time change.
    if (isOneTimeChangeRole) {
        // Handle non public pages that should not be visible to one time user.
        if (!PUBLIC_PAGES.includes(pageInformation?.name) && !productPages.includes(pageInformation?.name)) {
            yield call(handleOtChangeNavigateToContractEdit);
        }

        if (pageInformation?.name === Pages.USE_CASE && pageInformation?.innerName === Tabs.UC_CHANGE) {
            // Handle case for OT_CHANGE role and use-case/change page.
            yield call(handleOtChangeNavigateToContractEdit);
        }
    }

    if (isOneTimeClaimRole) {
        if (!PUBLIC_PAGES.includes(pageInformation?.name) && !productPages.includes(pageInformation?.name)) {
            yield call(handleOtClaimNavigateToIncidentDetail);
        }

        if (pageInformation?.name === Pages.USE_CASE && pageInformation?.innerName === Tabs.UC_CLAIM) {
            // Handle case for OT_CHANGE role and use-case/change page.
            yield call(handleOtClaimNavigateToIncidentDetail);
        }
    }
}

function* handleOtChangeNavigateToERegistry() {
    const contracts = yield select(userContracts.getContractList);
    const contractData = contracts[0];

    const siteId = yield select(pageContext.getSiteId);
    const eRegistryLink = ExternalUrl.E_REGISTRY_DISPATCH[siteId];

    // If not vehicle
    if (contractData?.idEnuProduct !== ProductGroup.VEHICLE) {
        yield (window.location.href = eRegistryLink);
    }
}

function* handleOtChangeNavigateToContractEdit() {
    const contracts = yield select(userContracts.getContractList);
    const contractData = contracts[0];

    const isSlovakSite = yield select(pageContext.getIsSlovakSite);
    const productLines = isSlovakSite ? skProductLines : czProductLines;
    const productDefinition = productLines[contractData?.idEnuProductGroupLevelTwo];

    yield put(
        router.navigate(productDefinition.detailRoute[0], Tabs.CHANGES, {
            idObject: contractData?.idObject,
        }),
    );
}

function* handleOtClaimNavigateToIncidentDetail() {
    const useCaseData = yield call(useCaseApi.getUseCaseClaimData);
    yield put(
        router.navigate(Pages.INCIDENT_DETAIL, null, {
            incidentId: useCaseData?.incident?.detail?.incidentId,
        }),
    );
}
