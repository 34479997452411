import { model } from "core/util";
import { PolicyDto, ContractPolicy } from "types";

export const Policy = model.createModel<PolicyDto, ContractPolicy>((json) => ({
    idObject: json.idObject,
    isUniqaContract: json.IsUniqaContract,
    countryCode: json.CountryCode,
    contractNumber: json.ContractNumber,
    contractName: json.ContractName,
    isActive: json.IsActive,
    idEnuContractStatus: json.Id_ENU_ContractStatus,
    idEnuProduct: json.idENU_Product,
    idEnuProductGroup: json.idENU_ProductGroup,
    productGroup: json.ProductGroup,
    idENUProduct: json.idENU_Product,
    idEnuProductGroupLevelTwo: json.idENU_ProductGroup_lvl2,
    productGroupLevelTwo: json.ProductGroup_lvl2,
    productCRM: json.Product_CRM,
    beginDate: json.BeginDate,
    endDate: json.EndDate,
    signatureDate: json.SignatureDate,
    spz: json.SPZ,
    brand: json.Brand,
    model: json.Model,
    homeAddress: json.HomeAddress,
    idObjectAgentContractAgent: json.IdObject_AgentContract_Agent,
    hasContractDetail: json.hasContractDetail,
    overview: undefined,
    financialOperations: undefined,
    canEdit: json.canEdit,
}));
