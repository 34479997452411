import React from "react";
import PropTypes from "prop-types";

import { StyledColorDot } from "ui-library/Styled";

export const ColorDot = ({ size, color }) => {
    return <StyledColorDot $size={size} $color={color} />;
};

ColorDot.defaultProps = {
    size: 16,
};

ColorDot.propTypes = {
    color: PropTypes.string,
    size: PropTypes.number.isRequired,
};
