import PropTypes from "prop-types";
import { StyledOffsetImageWrapper } from "ui-library/Styled";
import React from "react";

export const OffsetImageWrapper = ({ children, contentAlign, ...props }) => {
    return (
        <StyledOffsetImageWrapper $contentAlign={contentAlign} {...props}>
            {children}
        </StyledOffsetImageWrapper>
    );
};

OffsetImageWrapper.propTypes = {
    contentAlign: PropTypes.string,
};
