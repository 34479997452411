import React from "react";
import PropTypes from "prop-types";

import { HtmlLink } from "ui-library/atoms/Link/Link";
import { GridItem } from "ui-library/layouts";
import { InfoPanel } from "ui-library/modules";

import i18n from "core/i18n";

import { PieChartWithFundsTable } from "./PieChartWithFundsTable";

export const InvestmentsOverviewGridItem = ({ detailLink }) => {
    const { t } = i18n.useTranslation();

    return (
        <GridItem display={"flex"} xs={12} md={12}>
            <InfoPanel
                icon={"chart-line"}
                title={t("insurance.investmentStateBasedOnFunds")}
                headerAction={
                    <HtmlLink href={detailLink} target="_blank" color="blue">
                        {t("common.detail")}
                    </HtmlLink>
                }
            >
                <PieChartWithFundsTable />
            </InfoPanel>
        </GridItem>
    );
};

InvestmentsOverviewGridItem.propTypes = {
    detailLink: PropTypes.string.isRequired,
};
