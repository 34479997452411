import { createTheme } from "@mui/material";
import { createBreakpoints } from "@mui/system";
import LatoEot from "ui-library/assets/fonts/Lato/LatoLatin-Bold.eot";
import LatoWoff from "ui-library/assets/fonts/Lato/LatoLatin-Bold.woff";
import LatoWoff2 from "ui-library/assets/fonts/Lato/LatoLatin-Bold.woff2";

import PoppinsEot from "ui-library/assets/fonts/Poppins/poppins-v20-latin-600.eot";
import PoppinsWoff from "ui-library/assets/fonts/Poppins/poppins-v20-latin-600.woff";
import PoppinsWoff2 from "ui-library/assets/fonts/Poppins/poppins-v20-latin-600.woff2";

//blue
const blueMain = "#005CA9";
const blueMainDarker = "#004076"; //hover
const blueMainLighter = "#E6EFF7"; // hover
const blueMuddy = "#5379B4";
const blueSky = "#4C92CF";
const blueTyrkys = "#2AB1D5";
const blueKing = "#1892DC";
const blueCloud = "#E3EBF8";
const blueSoft = "#EEF2FA";
const blueGradient = "linear-gradient(180deg, #FFFFFF 0%, #FAFCFE 35.94%, #F1F8FC 100%)";

//green
const greenMain = "#03B675";
const greenSuccess = "#00813A";
const greenMainDarker = "#027A4E"; // hover
const greenMainLighter = "#E6F8F2"; // hover
const greenGradient = "linear-gradient(180deg, #FFFFFF 0%, #F7FBF9 100%)";

//grey
const grayMain = "#617384";
const grayMainDarker = "#495663";
const grayMainLighter = "#A6B7C7";
const grayWhiteDarker = "#EBEBED"; //hover
const grayWhiteLighter = "#337DBA"; //hover
const graySoftBorder = "#C1D1D8";
const grayBorder = "#CED4DA";
const grayLine = "#DEDEE1";
const grayGradient = "linear-gradient(180deg, #FFFFFF 0%, #E6E9EC 100%)";
const grayDark = "#495663";

const whiteMain = "#FFFFFF";
const blackMain = "#1B1B1B";

//red
const redMain = "#D71C4F";
const redMainDarker = "#901335"; // hover
const redMainLighter = "#FBE9EE"; // hover
const redGradient = "linear-gradient(180deg, #FFFFFF 0%, #FEF8F9 100%)";

//orange
const orangeMain = "#FFA450";
const orangeMainDarker = "#fc9332";
const orangeMainLighter = "#fcb472";

const violetMain = "#986CF2";
const pinkMain = "#DE659F";

const buttonDisabled = "#000000";

const latoHeading = {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontDisplay: "fallback",
    fontWeight: 700,
};

const textsFontSettings = {
    fontFamily: [
        '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    ],
    fontWeight: 400,
};

const breakpoints = createBreakpoints({});

export const theme = createTheme({
    "@global": {
        body: {
            padding: 0,
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
        @font-face {
          font-family: ${latoHeading.fontFamily};
          font-style: ${latoHeading.fontStyle};
          font-display: ${latoHeading.fontDisplay};
          font-weight: ${latoHeading.fontWeight};
          src: local(${latoHeading.fontFamily}), local('LatoLatin-Bold'),  url(${LatoWoff}) format('woff'), url(${LatoWoff2}) format('woff2');
        },

        @font-face {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            src: local('Poppins'), url(${PoppinsEot}), url(${PoppinsWoff}) format('woff'), url(${PoppinsWoff2}) format('woff2');
      
        },
           
       `,
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: "22rem",
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    height: "3px",
                    backgroundColor: blueMain,
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    boxShadow: "none",
                },
            },
        },
    },
    typography: {
        fontFamily: [
            '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
        ],
        h1: {
            fontFamily: latoHeading.fontFamily,
            fontWeight: latoHeading.fontWeight,
            letterSpacing: latoHeading.letterSpacing,
            [breakpoints.down("md")]: {
                fontSize: 26,
                lineHeight: "32px",
            },
            [breakpoints.up("md")]: {
                fontSize: 34,
                lineHeight: "48px",
            },
        },
        h2: {
            fontFamily: latoHeading.fontFamily,
            fontWeight: latoHeading.fontWeight,
            letterSpacing: latoHeading.letterSpacing,
            [breakpoints.down("md")]: {
                fontSize: 22,
                lineHeight: "26px",
            },
            [breakpoints.up("md")]: {
                fontSize: 28,
                lineHeight: "40px",
            },
        },
        h3: {
            fontFamily: latoHeading.fontFamily,
            fontWeight: latoHeading.fontWeight,
            letterSpacing: latoHeading.letterSpacing,
            [breakpoints.down("md")]: {
                fontSize: 18,
                lineHeight: "21px",
            },
            [breakpoints.up("md")]: {
                fontSize: 21,
                lineHeight: "32px",
            },
        },
        h4: {
            fontFamily: latoHeading.fontFamily,
            fontWeight: latoHeading.fontWeight,
            letterSpacing: latoHeading.letterSpacing,
            [breakpoints.down("md")]: {
                lineHeight: "19px",
            },
            [breakpoints.up("md")]: {
                lineHeight: "24px",
            },
            fontSize: 16,
        },
        p: {
            fontFamily: textsFontSettings.fontFamily,
            fontWeight: textsFontSettings.fontWeight,
            fontSize: 16,
            lineHeight: "24px",
        },
        b: {
            fontFamily: textsFontSettings.fontFamily,
            fontWeight: 600,
            fontSize: 16,
            lineHeight: "24px",
        },
        strong: {
            fontFamily: textsFontSettings.fontFamily,
            fontWeight: 600,
            fontSize: 16,
            lineHeight: "24px",
        },
        i: {
            fontSize: 16,
            lineHeight: "22px",
            fontStyle: "cursive",
        },
        perex: {
            fontFamily: textsFontSettings.fontFamily,
            fontWeight: textsFontSettings.fontWeight,
            fontSize: 18,
            lineHeight: "32px",
        },
        lead: {
            fontFamily: textsFontSettings.fontFamily,
            fontWeight: textsFontSettings.fontWeight,
            fontSize: 21,
            lineHeight: "32px",
        },
        caption: {
            fontSize: 14,
            lineHeight: "22px",
        },
        link: {
            fontFamily: textsFontSettings.fontFamily,
            fontWeight: textsFontSettings.fontWeight,
            fontSize: 18,
            lineHeight: "24px",
        },
        button: {
            textTransform: "none",
        },
    },
    palette: {
        chartsColor: [
            greenMain,
            orangeMain,
            blueMain,
            redMain,
            blueTyrkys,
            violetMain,
            pinkMain,
            grayMainLighter,
            redMainDarker,
            grayMainDarker,
        ],
        chartColors: {
            blueMain,
            blueMainDarker,
            blueMainLighter,
            blueMuddy,
            blueSky,
            blueTyrkys,
            blueKing,
            blueCloud,
            blueSoft,
            greenMain,
            greenSuccess,
            greenMainDarker,
            greenMainLighter,
            grayMain,
            grayMainDarker,
            grayMainLighter,
            grayWhiteDarker,
            grayWhiteLighter,
            graySoftBorder,
            grayBorder,
            grayLine,
            grayDark,
            whiteMain,
            blackMain,
            redMainLighter,
            redGradient,
            orangeMain,
            orangeMainDarker,
            orangeMainLighter,
            redMain,
            redMainDarker,
            violetMain,
            pinkMain,
        },
        colors: {
            blue: blueMain,
            blueDarker: blueMainDarker,
            blueLighter: blueMainLighter,
            muddy: blueMuddy,
            sky: blueSky,
            tyrkys: blueTyrkys,
            king: blueKing,
            cloud: blueSoft,
            green: greenMain,
            greenDarker: greenMainDarker,
            greenLighter: greenMainLighter,
            success: greenSuccess,
            gray: grayMain,
            grayDarker: grayWhiteDarker,
            grayLighter: grayWhiteLighter,
            graySoftBorder: graySoftBorder,
            grayBorder: grayBorder,
            grayLine: grayLine,
            grayGradient: grayGradient,
            red: redMain,
            redDarker: redMainDarker,
            redLighter: redMainLighter,
            orange: orangeMain,
            white: whiteMain,
            black: blackMain,
        },
        buttonDisabled: buttonDisabled,
        base: {
            main: blackMain,
            contrastText: whiteMain,
        },
        black: {
            main: blackMain,
        },
        blue: {
            main: blueMain,
            dark: blueMainDarker,
            light: blueMainLighter,
            muddy: blueMuddy,
            sky: blueSky,
            tyrkys: blueTyrkys,
            king: blueKing,
            cloud: blueCloud,
            soft: blueSoft,
            gradient: blueGradient,
            contrastText: whiteMain,
        },
        green: {
            main: greenMain,
            dark: greenMainDarker,
            light: greenMainLighter,
            gradient: greenGradient,
            success: greenSuccess,
            contrastText: whiteMain,
        },
        gray: {
            main: grayMain,
            dark: grayWhiteDarker,
            mainLighter: grayMainLighter,
            light: grayWhiteLighter,
            softBorder: graySoftBorder,
            border: grayBorder,
            line: grayLine,
            gradient: grayGradient,
            extraDark: grayDark,
        },
        red: {
            main: redMain,
            dark: redMainDarker,
            light: redMainLighter,
            gradient: redGradient,
            contrastText: whiteMain,
        },
        orange: {
            main: orangeMain,
            dark: orangeMainDarker,
            light: orangeMainLighter,
            contrastText: whiteMain,
        },
        white: {
            main: whiteMain,
            dark: grayWhiteDarker,
            light: grayWhiteLighter,
            contrastText: blueMain,
        },
    },
});
