import { Map } from "immutable";
import { createSelector } from "reselect";

import { app } from "core/util";
import { UniqaClaim, UniqaRequiredDocument } from "model/uniqaClaims";

import { ENTITY_CLAIM_REQUIRED_DOCUMENTS, NAME } from "./constants";
import { formatClaimName } from "./util";
import entity from "core/entity";
import { ENTITY_FINANCIAL_OPERATIONS } from "app/pages/detailPp/constants";
import { PensionPpFinancialOperation } from "model/pension/pp";

/** contract data */
const getModel = app.createGetModel(NAME);
const getData = (state) => getModel(state).get("data");
const getIncidentData = (state) => getData(state).get("incidentDetail");

export const getIncidentDetail = (state) => getIncidentData(state).get("detail");
export const getIsAssistanceInsuranceRisk = (state) => getIncidentDetail(state).get("isAssistanceInsuranceRisk");

export const getClaims = (state) => getIncidentData(state).get("claims");
export const getNumberOfClaims = (state) => getClaims(state).size;
export const getIsMultiClaimIncident = (state) => getClaims(state).size > 1;

export const getRequiredDocuments = (state) =>
    entity.getDataSelector(state, ENTITY_CLAIM_REQUIRED_DOCUMENTS, UniqaRequiredDocument.fromServerList());

/** appState */
const getAppState = (state) => getModel(state).get("appState");
export const getSelectedClaimId = (state) => getAppState(state).get("selectedClaimId");
export const getSelectedDocumentToUploadReactKey = (state) => getAppState(state).get("selectedDocumentToUploadReactKey");

/** Custom selectors */
export const getClaimData = createSelector(getClaims, getSelectedClaimId, (claims, selectedClaimId) => {
    const claim = claims.find((item) => item.get("claimId") === selectedClaimId);
    if (claim) {
        return claim;
    }
    return UniqaClaim.fromServer();
});

export const getClaimsForSelect = (t) =>
    createSelector(getClaims, (claims) =>
        claims.map(
            (claim) =>
                new Map({
                    value: claim.claimId,
                    label: formatClaimName(t, claim),
                }),
        ),
    );

export const getDocumentDataForSelectedRequiredDocument = createSelector(
    getRequiredDocuments,
    getSelectedDocumentToUploadReactKey,
    (requiredDocuments, selectedReactKey) =>
        requiredDocuments.filter((requiredDocument) => requiredDocument.reactKey === selectedReactKey).first(),
);
