import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Loading } from "ui-library/atoms";

import i18n from "core/i18n";
import router from "core/router";
import userContracts from "core/userContracts";
import { useGetClaimReportingUrls } from "core/hooks";
import { Pages, Params, Tabs } from "routeConstants";

export const ReportClaimButton = ({ compliantLineOfBusinessClasses, idObject, categoryParam, isContractOverviewLoading, isOutlined }) => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();
    const redirectExternal = compliantLineOfBusinessClasses && compliantLineOfBusinessClasses.size <= 1;
    const redirectSpecificUrl = useSelector(userContracts.createGetFinalRedirectUrl(idObject, compliantLineOfBusinessClasses.first()));
    const claimReportingUrls = useGetClaimReportingUrls();

    const goToClaimsPage = () =>
        dispatch(
            router.navigate(
                Pages.CLAIMS,
                Tabs.REPORT_CLAIM,
                {},
                {
                    [Params.CLAIM_REPORT_CATEGORY]: categoryParam,
                    [Params.PAYMENT_CONTRACT_ID]: idObject,
                },
                false,
            ),
        );

    return (
        <Loading loading={isContractOverviewLoading}>
            {redirectExternal ? (
                <Button
                    color="blue"
                    variant={isOutlined ? "outlined" : "contained"}
                    endIcon={"computer"}
                    externalLink={compliantLineOfBusinessClasses.size === 1 ? redirectSpecificUrl : claimReportingUrls.base}
                    newTab
                >
                    {t("claim.report")}
                </Button>
            ) : (
                <Button color="blue" variant={isOutlined ? "outlined" : "contained"} endIcon={"computer"} onClick={goToClaimsPage}>
                    {t("claim.report")}
                </Button>
            )}
        </Loading>
    );
};
