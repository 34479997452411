import React from "react";
import PropTypes from "prop-types";

import { ModalBody, ModalFooter } from "ui-library/modules";
import { Box, ContentBox } from "ui-library/layouts";
import { Button, Loading } from "ui-library/atoms";

import i18n from "core/i18n";
import localized from "core/localized";
import { ModalFormLayout, ModalSuccess } from "containers/modal";
import { Field, form, SingleSelect } from "core/form";

import { FIELD_INVESTMENT_STRATEGY } from "./constants";
import { useSelector } from "react-redux";

const PensionInvestmentStrategy = ({ canSubmit, handleSubmit, submitting }) => {
    const { t } = i18n.useTranslation();
    const options = useSelector(localized.getLocalizedPensionInvestmentStrategyOptions);
    return (
        <>
            <ModalBody>
                <Loading loading={submitting}>
                    <Box>
                        <ContentBox>
                            <Field
                                name={FIELD_INVESTMENT_STRATEGY}
                                component={SingleSelect}
                                label={t("pension.dss.investmentStrategy")}
                                options={options}
                            />
                        </ContentBox>
                    </Box>
                </Loading>
            </ModalBody>
            <ModalFooter>
                <Button color="blue" variant="contained" onClick={handleSubmit} disabled={!canSubmit}>
                    {t("common.submitChange")}
                </Button>
            </ModalFooter>
        </>
    );
};

PensionInvestmentStrategy.propTypes = {
    canSubmit: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
};

export const createContainer = (formName) =>
    form(formName)((props) => {
        const { t } = i18n.useTranslation();
        const SuccessComponent = ({ closeModal }) => <ModalSuccess closeModal={closeModal} title={t("success.requestSent.title")} noText />;

        return (
            <ModalFormLayout
                formName={formName}
                formTitle={t("form.pensionInvestmentStrategy")}
                formIcon="contract"
                SuccessComponent={SuccessComponent}
            >
                <PensionInvestmentStrategy {...props} />
            </ModalFormLayout>
        );
    });
