import React from "react";
import PropTypes from "prop-types";

import { Typography } from "ui-library/atoms";
import { ContentBox } from "ui-library/layouts";

import i18n from "core/i18n";

import { DssStrategySection, DssTransferSection, GuaranteedFundRatioReduction } from "../sections";
import { PensionDssFundsChangeType } from "enums";

const DdsFundsChangeComponent = ({
    formName,
    isOtpVisible,
    changeType,
    actualStrategies,
    isTransferFundsDataPossible,
    isChangeStrategyDataPossible,
    isGuaranteedFundRatioReductionPossible,
    isGuaranteedFundRatioReductionInitiallySet,
}) => {
    const { t } = i18n.useTranslation();

    return (
        <>
            <ContentBox mb={4}>
                <Typography component="p" color="red" markDown={t("pension.fundsChange.changeInfo")} internalAppLink />
            </ContentBox>

            <ContentBox mb={4}>
                <Typography component="p" markDown={t("pension.fundsChange.text")} />
            </ContentBox>

            <DssTransferSection
                formName={formName}
                changeType={changeType}
                actualStrategies={actualStrategies}
                isTransferFundsDataPossible={isTransferFundsDataPossible}
                disabled={isOtpVisible}
            />
            <DssStrategySection
                formName={formName}
                changeType={changeType}
                actualStrategies={actualStrategies}
                isChangeStrategyDataPossible={isChangeStrategyDataPossible}
                disabled={isOtpVisible}
            />
            {changeType !== PensionDssFundsChangeType.VOLUNTARY.id && (
                <GuaranteedFundRatioReduction
                    formName={formName}
                    isGuaranteedFundRatioReductionInitiallySet={isGuaranteedFundRatioReductionInitiallySet}
                    isGuaranteedFundRatioReductionPossible={isGuaranteedFundRatioReductionPossible}
                    disabled={isOtpVisible}
                />
            )}

            <ContentBox mb={4}>
                <Typography component="p" markDown={t("pension.fundsChange.dss.changeInfo.partTwo")} />
            </ContentBox>
        </>
    );
};

DdsFundsChangeComponent.propTypes = {
    formName: PropTypes.string.isRequired,
    isOtpVisible: PropTypes.bool.isRequired,
    changeType: PropTypes.string.isRequired,
    isTransferFundsDataPossible: PropTypes.bool.isRequired,
    isChangeStrategyDataPossible: PropTypes.bool.isRequired,
    isGuaranteedFundRatioReductionPossible: PropTypes.bool.isRequired,
    isGuaranteedFundRatioReductionInitiallySet: PropTypes.bool.isRequired,
    actualStrategies: PropTypes.object.isRequired,
};

export default DdsFundsChangeComponent;
