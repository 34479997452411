import pensionPisSettingConfirmationForm from "form/modal/pensionPisSettingConfirmation";

import { FORM_PIS_CONFIRMATION } from "../constants";
import { getDisplayEnableButton, getPisFormChangeResult } from "../selectors";

export const ModalPisConfirmationForm = pensionPisSettingConfirmationForm.createContainer(
    FORM_PIS_CONFIRMATION,
    getDisplayEnableButton,
    getPisFormChangeResult,
);
