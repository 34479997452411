import { app } from "core/util";
import { createSelector } from "reselect";

import { Roles } from "enums";
import { AccountInformation } from "model/user";

import { NAME } from "./constants";

const LOGIN_TYPE_IMPERSONATION = "IMPERSONATION";

export const getModel = app.createGetModel(NAME);

export const getUserAccount = (state) => getModel(state).get("userAccount", new AccountInformation());

export const getUserRoles = (state) => getUserAccount(state).get("roles") || [];

export const getIsAuthenticated = (state) => getUserRoles(state).includes(Roles.AUTHENTICATED);

export const getIsOneTimeRole = (state) => getUserRoles(state).includes(Roles.ONETIME);
export const getIsOneTimeChangeRole = (state) =>
    getUserRoles(state).includes(Roles.ONETIME) && getUserRoles(state).includes(Roles.OT_CONTRACT_CHANGE);
export const getIsOneTimeClaimRole = (state) => getUserRoles(state).includes(Roles.ONETIME) && getUserRoles(state).includes(Roles.OT_CLAIM);

// TODO - first verion dummy - when impersonated cannot edit contracts.
export const hasPermission = (permissionsToCheck) =>
    createSelector(getUserAccount, (userAccount) => userAccount.authorizationType !== LOGIN_TYPE_IMPERSONATION);
