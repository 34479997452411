export default (reducerPath) => {
    const getModel = (state) => state.getIn(reducerPath);

    return {
        getEpinPhase: (state) => getModel(state).get("epinPhase"),
        getEpinData: (state) => getModel(state).get("epinData"),
        getDuplicateContractNumber: (state) => getModel(state).get("duplicateContractNumber"),
        getEpinIssueError: (state) => getModel(state).get("epinIssueError"),
        getEpinPasswordError: (state) => getModel(state).get("epinPasswordError"),
        getEpinVerificationError: (state) => getModel(state).get("epinVerificationError"),
    };
};
