import { combineReducers as immutableCombineReducers } from "redux-immutable";
import { combineReducers, createReducer } from "@reduxjs/toolkit";

import { PensionDds } from "model/pension/dds";
import { PensionDdsContract } from "types";

import { clear, setPensionDdsContractDetail } from "./actions";
import {
    ActualStatements,
    ChangePropositionModule,
    ExtraStatements,
    FinancialOperationsTableFilter,
    OtpModule,
    TaxStatements,
    YearStatements,
} from "./modules";

const pensionDdsContractDetail = createReducer<PensionDdsContract>(PensionDds.fromServer(), (builder) => {
    builder.addCase(setPensionDdsContractDetail, (_, { payload }) => payload);
    builder.addCase(clear, () => PensionDds.fromServer());
});

export default immutableCombineReducers({
    data: combineReducers({
        pensionDdsContractDetail,
    }),
    appState: immutableCombineReducers({
        yearStatements: YearStatements.reducer,
        extraStatements: ExtraStatements.reducer,
        taxStatements: TaxStatements.reducer,
        actualStatements: ActualStatements.reducer,
        otp: OtpModule.reducer,
        changeProposition: ChangePropositionModule.reducer,
        financialOperationsFilter: FinancialOperationsTableFilter.reducer,
    }),
});
