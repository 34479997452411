import React, { FC } from "react";
import { Button } from "ui-library/atoms";
import { StyledContractSwitch } from "ui-library/Styled";
import { ViewType } from "ui-library/modules/enums";

export interface ContractSwitchProps {
    state: ViewType;
    setState: (state: ViewType) => {};
}

export const ContractSwitch: FC<ContractSwitchProps> = ({ state, setState }) => {
    return (
        <StyledContractSwitch>
            <Button
                onClick={() => setState(ViewType.TILES)}
                className={"left" + (state === ViewType.TILES ? " active" : "")}
                border={false}
                variant="outlined"
                startIcon={"tiles"}
            />
            <Button
                onClick={() => setState(ViewType.BASE)}
                className={"right" + (state === ViewType.BASE ? " active" : "")}
                border={false}
                variant="outlined"
                startIcon={"horizontal-lines"}
            />
        </StyledContractSwitch>
    );
};
