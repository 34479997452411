import { nanoid } from "@reduxjs/toolkit";

import { model } from "core/util";

import { AmountLegacy, CodeValueLegacy } from "../common";

export const PensionDssDdsFundInvestmentStrategyLegacy = model.createModelLegacy("PensionDssDdsFundInvestmentStrategy", (json) => ({
    reactKey: nanoid(),
    investmentFund: CodeValueLegacy.fromServer(json.InvestmentFund),
    pricePerShare: AmountLegacy.fromServer(json.PricePerShare),
    investmentPercentage: json.InvestmentPercentage,
}));
