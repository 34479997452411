import router from "core/router";
import { API_PREFIX } from "app/constants";
import { fetch } from "core/util";
import { Countries, Locales } from "enums";
import { ConfigDto } from "types/generated";

const getConfig = (): ConfigDto => {
    // @ts-ignore
    return fetch.doGet(`${API_PREFIX}/config`);
};

const getAvailableLocales = (site) => {
    const siteLocale = site === Countries.SK ? Locales.sk_SK : Locales.cs_CZ;
    return [siteLocale, Locales.en_US];
};

/* eslint-disable no-restricted-globals */
const getLocaleConfig = () => {
    const isSk = location.hostname.split(".").reverse()[0] === "sk";
    const searchParams = new URLSearchParams(window.location?.search);
    const siteDefaultLocale = isSk ? Locales.sk_SK : Locales.cs_CZ;
    const locale = searchParams.get(router.LANG_PARAM) === Locales.en_US.languageCode ? Locales.en_US : siteDefaultLocale;
    const site = isSk ? Countries.SK : Countries.CZ;
    const availableLocales = getAvailableLocales(site);
    return { site, locale, availableLocales };
};
/* eslint-enable */

export default {
    getConfig,
    getLocaleConfig,
};
