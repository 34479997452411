import React, { FC, PropsWithChildren, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import skLocale from "date-fns/locale/sk";
import csLocale from "date-fns/locale/cs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import router from "core/router";
import i18n from "core/i18n";
import pageContext from "core/pageContext";
import { Locales } from "enums";

const LocaleProvider: FC<PropsWithChildren> = ({ children }) => {
    const { t } = i18n.useTranslation();
    const locale = useSelector(pageContext.getLocale);
    const adapterLocale = locale === Locales.sk_SK ? skLocale : csLocale;

    const location = useLocation();
    const navigate = useNavigate();

    const dateLocaleText = {
        cancelButtonLabel: t("common.cancel"),
        okButtonLabel: t("common.confirm"),
    };

    // This is to make sure that on en version we have lang=en always in url.
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if (locale === Locales.en_US && searchParams.get(router.LANG_PARAM) !== Locales.en_US.languageCode) {
            searchParams.set(router.LANG_PARAM, Locales.en_US.languageCode);
            navigate({ search: searchParams.toString() });
        }
    }, [locale, location, navigate]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={adapterLocale} localeText={dateLocaleText}>
            {children}
        </LocalizationProvider>
    );
};

export default LocaleProvider;
