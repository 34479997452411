import React, { FC, ImgHTMLAttributes } from "react";
import { StyledImage } from "ui-library/Styled";

export interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
    src: string;
    alt?: string;
    srcSet?: string;
    borderRadius?: number;
    shadow?: boolean;
    desatured?: boolean;
    objectFit?: string;
}

export const Image: FC<ImageProps> = ({
    desatured = false,
    shadow = true,
    src,
    alt = "",
    srcSet,
    borderRadius = 4,
    objectFit = "cover",
    ...props
}) => {
    return (
        <StyledImage
            $desatured={desatured}
            $shadow={shadow}
            src={src}
            alt={alt}
            srcSet={srcSet}
            $borderRadius={borderRadius}
            $objectFit={objectFit}
            {...props}
        />
    );
};
