import { Typography } from "ui-library/atoms/Typography/Typography";
import { Box } from "ui-library/layouts/Box/Box";
import { Flexbox } from "ui-library/layouts/Flexbox/Flexbox";
import PropTypes from "prop-types";
import * as React from "react";

export const AccordionTitle = ({ text, count, shareNumber }) => {
    return (
        <>
            {count >= 0 && (
                <Flexbox gap={0.5} alignItems="center">
                    <Typography>{text}</Typography>
                    <Typography color="gray"> ({count})</Typography>
                </Flexbox>
            )}
            {shareNumber && (
                <Flexbox gap={0.5} alignItems="center" justifyContent={"space-between"} width="100%">
                    <Typography>{text}</Typography>
                    <Box mr={2}>
                        <Typography color="black">{shareNumber}</Typography>
                    </Box>
                </Flexbox>
            )}
        </>
    );
};

AccordionTitle.propTypes = {
    text: PropTypes.string,
    shareNumber: PropTypes.string,
    count: PropTypes.number,
};
