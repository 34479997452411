import * as React from "react";
import { FC } from "react";
import { StyledChip } from "ui-library/Styled";
import { ChipProps as MaterialChipProps } from "@mui/material";

export interface ChipProps extends MaterialChipProps {
    bgColor?: string;
    label: string;
}

export const Chip: FC<ChipProps> = ({ bgColor = "blue", label, ...props }) => {
    return <StyledChip $bgColor={bgColor} label={label} {...props} />;
};
