import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { Box, ContentBox } from "ui-library/layouts";

import i18n from "core/i18n";
import localized from "core/localized";
import { DatePicker, Field, InputText, maxLengthCharacters, maxValue, numberCharacters, required, SingleSelect } from "core/form";
import { date } from "core/util";

import { REDEEM_EXTRAORDINARY_PAYMENT_PERCENT_FIELD, REDEEM_PAYMENT_FREQUENCY_FIELD, REDEEM_START_FROM_FIELD } from "../../constants";

export const PensionPpLifetimePaymentSection = ({ selectedRedeemTypeParameters }) => {
    const { t } = i18n.useTranslation();
    const frequencyOptions = useSelector(localized.getLocalizedFrequencyOptions);
    const minDate = Date.parse(selectedRedeemTypeParameters?.claimDateFrom) || date.today;
    const maxDate = Date.parse(selectedRedeemTypeParameters?.claimDateTo);

    return (
        <Box mt={4}>
            <ContentBox>
                <Field
                    name={REDEEM_START_FROM_FIELD}
                    label={t("form.pensionRedeemSavings.redeemStartFrom")}
                    component={DatePicker}
                    validateStatic={[required]}
                    minDate={minDate}
                    maxDate={maxDate}
                    inputFormat={"MM/yyyy"}
                    mask="__/____"
                    views={date.getMonthYearViewOptions(minDate, maxDate)}
                    md={4}
                />
            </ContentBox>
            <ContentBox fullWidth>
                <Field
                    name={REDEEM_PAYMENT_FREQUENCY_FIELD}
                    component={SingleSelect}
                    label={t("form.pensionRedeemSavings.redeemPaymentFrequency")}
                    options={frequencyOptions}
                    validateStatic={[required]}
                    placeholder={t("common.choose")}
                />
            </ContentBox>
            <ContentBox>
                <Field
                    name={REDEEM_EXTRAORDINARY_PAYMENT_PERCENT_FIELD}
                    label={t("form.pensionRedeemSavings.redeemExtraordinaryPaymentPercent")}
                    component={InputText}
                    normalize={[numberCharacters, maxLengthCharacters(2)]}
                    validateStatic={[maxValue(33)]}
                    md={6}
                />
            </ContentBox>
        </Box>
    );
};

PensionPpLifetimePaymentSection.propTypes = {
    selectedRedeemTypeParameters: PropTypes.object.isRequired,
};
