import { ClaimCategory } from "enums";
import { IncidentsStatistics } from "model/claim";

export const calculateClaimsStatistics = (claims) => {
    const numberOfAllIncidents = claims.size;
    const numberOfOpenIncidents = claims.filter((incident) => incident.categoryClaim !== ClaimCategory.FIN.id).size;
    const numberOfClosedIncidents = claims.filter((incident) => incident.categoryClaim === ClaimCategory.FIN.id).size;

    return IncidentsStatistics.fromServer({
        totalIncidentsCount: numberOfAllIncidents,
        openIncidentsCount: numberOfOpenIncidents,
        closedIncidentsCount: numberOfClosedIncidents,
    });
};
