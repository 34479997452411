import PropTypes from "prop-types";
import * as React from "react";
import { Box } from "@mui/material";
import { StyledArticlesList } from "ui-library/Styled";
import { Typography } from "ui-library/atoms";
import { Flexbox } from "ui-library/layouts";

export const ArticlesList = ({ title, description, children, action, ...props }) => {
    return (
        <StyledArticlesList {...props}>
            {title && (
                <Box mb={1}>
                    <Typography variant="h2">{title}</Typography>
                </Box>
            )}
            {description && (
                <Box mb={{ xs: 3, md: 4 }} width={{ xs: "100%", md: "75%" }}>
                    <Typography variant="p" color="gray">
                        {description}
                    </Typography>
                </Box>
            )}
            {children}
            {action && (
                <Flexbox mb={3} justifyContent={"center"}>
                    {action}
                </Flexbox>
            )}
        </StyledArticlesList>
    );
};

ArticlesList.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    children: PropTypes.node,
    action: PropTypes.node,
};
