import React, { FC } from "react";

import { Pages } from "routeConstants";
import { ProfitDetailTable as ProfitDetail } from "containers";

import { getIdObject, getProfitDetail } from "../selectors";

export const ProfitDetailTable: FC = () => (
    <ProfitDetail getProfitDetail={getProfitDetail} getIdObject={getIdObject} topPage={Pages.CONTRACT_DIRECT} />
);
