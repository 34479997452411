import { fetch, fn } from "core/util";
import { API_PREFIX } from "app/constants";
import { ChangeResponse } from "model/form";
import { PensionDss } from "model/pension/dss";
import { PensionDssDdsFinancialOperation, PensionDssDdsFinancialStatement, PensionFundsChangePermittedByType } from "model/pension";

const productUriPrefix = `${API_PREFIX}/product/pension-dss`;
const getPensionDssContractDetail = (idObject) =>
    fetch.doGet(`${productUriPrefix}/${idObject}/contract-detail`, null, PensionDss.fromServer);
const getPensionDssFinancialOperations = (idObject) =>
    fetch.doGet(`${productUriPrefix}/${idObject}/financial-operations`, null, PensionDssDdsFinancialOperation.fromServerList);
const getPensionDssFinancialStatement = (idObject) =>
    fetch.doGet(`${productUriPrefix}/${idObject}/financial-statement`, null, PensionDssDdsFinancialStatement.fromServer);
const getIsPensionDssFundsChangePermittedByType = (idObject, type) =>
    fetch.doGet(`${productUriPrefix}/${idObject}/funds-change-permitted/${type}`, null, PensionFundsChangePermittedByType.fromServer);

const fundsChange = (values) => fetch.doPut(`${productUriPrefix}/funds-change`, values);
const updateBeneficiaries = (values) => fetch.doPut(`${productUriPrefix}/update-beneficiaries`, values);
const updatePis = (body) => fetch.doPut(`${productUriPrefix}/update-pis-status`, body, fn.identity, ChangeResponse.fromServer);
const updatePensionInvestmentStrategy = (body) => fetch.doPut(`${productUriPrefix}/investment-strategy`, body);

export default {
    getPensionDssContractDetail,
    getPensionDssFinancialOperations,
    getPensionDssFinancialStatement,
    getIsPensionDssFundsChangePermittedByType,
    fundsChange,
    updateBeneficiaries,
    updatePis,
    updatePensionInvestmentStrategy,
};
