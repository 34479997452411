import React, { FC } from "react";
import { useParams } from "react-router-dom";

import { GridLayout } from "ui-library/layouts";

import { ContractDocuments, ContractEnqueueDocumentsGridItem } from "containers/contract";

import { ENTITY_CONTRACT_DOCUMENTS, ENTITY_ENQUEUED_DOCUMENTS } from "../constants";

export const DocumentsTab: FC = () => {
    const { idObject } = useParams();

    return (
        <GridLayout defaultSpacing>
            <ContractDocuments idObject={Number(idObject)} entityName={ENTITY_CONTRACT_DOCUMENTS} />
            <ContractEnqueueDocumentsGridItem idObject={Number(idObject)} entityName={ENTITY_ENQUEUED_DOCUMENTS} />
        </GridLayout>
    );
};
