import { NAME } from "./constants";

export const SET_AUTHENTICATED_USER = `${NAME}/SET_AUTHENTICATED_USER`;
export const SET_MESSAGES = `${NAME}/SET_MESSAGES`;
export const EXPAND_MESSAGE = `${NAME}/EXPAND_MESSAGE`;
export const SET_AGENTS = `${NAME}/SET_AGENTS`;
export const GDPR_DATA = `${NAME}/GDPR_DATA`;

export const setAuthenticatedUser = (authenticatedUser) => ({
    type: SET_AUTHENTICATED_USER,
    payload: { authenticatedUser },
});

export const setMessages = (data) => ({
    type: SET_MESSAGES,
    payload: data,
});

export const expandMessage = (id, expand, unread) => ({
    type: EXPAND_MESSAGE,
    payload: { id, expand, unread },
});

export const setAgents = (agents) => ({
    type: SET_AGENTS,
    payload: agents,
});

export const gdprData = (consentDate, text, version, formText, formVersion, modifyDate, url, consents) => ({
    type: GDPR_DATA,
    payload: { consentDate, text, version, formText, formVersion, modifyDate, url, consents },
});

export const acceptingGdprActionGroup = {
    START: `${NAME}_ACCEPTING_START`,
    CONFIRM: `${NAME}_ACCEPTING_CONFIRM`,
    CANCEL: `${NAME}_ACCEPTING_CANCEL`,
    RESPONSE: `${NAME}_ACCEPTING_RESPONSE`,
    start: () => ({
        type: acceptingGdprActionGroup.START,
    }),
    confirm: () => ({
        type: acceptingGdprActionGroup.CONFIRM,
    }),
    cancel: () => ({
        type: acceptingGdprActionGroup.CANCEL,
    }),
    response: (timestamp, consents) => ({
        type: acceptingGdprActionGroup.RESPONSE,
        payload: { timestamp, consents },
    }),
};

export const revokingGdprActionGroup = {
    START: `${NAME}_REVOKING_START`,
    CONFIRM: `${NAME}_REVOKING_CONFIRM`,
    CANCEL: `${NAME}_REVOKING_CANCEL`,
    RESPONSE: `${NAME}_REVOKING_RESPONSE`,
    start: () => ({
        type: revokingGdprActionGroup.START,
    }),
    confirm: () => ({
        type: revokingGdprActionGroup.CONFIRM,
    }),
    cancel: () => ({
        type: revokingGdprActionGroup.CANCEL,
    }),
    response: () => ({
        type: revokingGdprActionGroup.RESPONSE,
    }),
};
