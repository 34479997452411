import statementsGetter, {
    ACTUAL_STATEMENT,
    EXTRA_STATEMENT,
    TAX_STATEMENT,
    YEARLY_STATEMENT_ONLY_LAST_YEAR,
} from "modules/statementsGetter";
import changePropositions from "modules/changePropositions";
import otp from "modules/otp";
import { tableFilter } from "modules/tableFilter";
import { Pages, Tabs } from "routeConstants";

import { ENTITY_CHANGE_PROPOSITIONS, ENTITY_ENQUEUED_DOCUMENTS, NAME } from "./constants";
import { FinancialOperationsAdvancedFilter, FinancialOperationsBasicFilter, financialOperationsDefaultOperators } from "./filters";
import { getIdObject, getPolicyBeginDateYear } from "./selectors";

export const YearStatements = statementsGetter({
    actionPrefix: `${NAME}/yearStatements`,
    reducerPath: [NAME, "appState", "yearStatements"],
    getId: getIdObject,
    documentType: YEARLY_STATEMENT_ONLY_LAST_YEAR,
    getPolicyBeginDateYear: getPolicyBeginDateYear,
    enqueuedDocumentsEntity: ENTITY_ENQUEUED_DOCUMENTS,
    documentsPath: [Pages.CONTRACT_DDS, Tabs.DOCUMENTS],
});

export const ExtraStatements = statementsGetter({
    actionPrefix: `${NAME}/extraStatements`,
    reducerPath: [NAME, "appState", "extraStatements"],
    getId: getIdObject,
    enqueuedDocumentsEntity: ENTITY_ENQUEUED_DOCUMENTS,
    documentType: EXTRA_STATEMENT,
    documentsPath: [Pages.CONTRACT_DDS, Tabs.DOCUMENTS],
});

export const TaxStatements = statementsGetter({
    actionPrefix: `${NAME}/taxStatements`,
    reducerPath: [NAME, "appState", "taxStatements"],
    getId: getIdObject,
    getPolicyBeginDateYear,
    enqueuedDocumentsEntity: ENTITY_ENQUEUED_DOCUMENTS,
    documentType: TAX_STATEMENT,
    documentsPath: [Pages.CONTRACT_DDS, Tabs.DOCUMENTS],
});

export const ActualStatements = statementsGetter({
    actionPrefix: `${NAME}/actualStatements`,
    reducerPath: [NAME, "appState", "actualStatements"],
    getId: getIdObject,
    enqueuedDocumentsEntity: ENTITY_ENQUEUED_DOCUMENTS,
    documentType: ACTUAL_STATEMENT,
    documentsPath: [Pages.CONTRACT_DDS, Tabs.DOCUMENTS],
});

export const OtpModule = otp({
    actionPrefix: `${NAME}/otp`,
    reducerPath: [NAME, "appState", "otp"],
});

export const ChangePropositionModule = changePropositions({
    actionPrefix: `${NAME}/changeProposition`,
    reducerPath: [NAME, "appState", "changeProposition"],
    idObjectSelector: getIdObject,
    contractConstant: Pages.CONTRACT_DDS,
    propositionsEntity: ENTITY_CHANGE_PROPOSITIONS,
});

export const FinancialOperationsTableFilter = tableFilter({
    actionPrefix: `${NAME}/financialOperationsFilter`,
    reducerPath: [NAME, "appState", "financialOperationsFilter"],
    AdvancedFilter: FinancialOperationsAdvancedFilter,
    BasicFilter: FinancialOperationsBasicFilter,
    defaultOperators: financialOperationsDefaultOperators,
});
