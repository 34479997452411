import { model } from "core/util";

import { HolderLegacy } from "../holder";
import { ElectronicCommunicationLegacy } from "../electronicCommunication";
import { LifeContractParameters } from "./LifeContractParameters";
import { LifeAccountDetail } from "./LifeAccountDetail";

export const LifeContractDetail = model.createModelLegacy("LifeContractDetail", (json) => ({
    contractParameters: LifeContractParameters.fromServer(json.ContractParameters),
    holder: HolderLegacy.fromServer(json.Holder),
    lifeAccountDetail: LifeAccountDetail.fromServer(json.LifeAccountDetail),
    electronicCommunication: ElectronicCommunicationLegacy.fromServer(json.ElectronicCommunication),
}));
