import { StyledTable, StyledTableBody, StyledTableCell, StyledTableContainer, StyledTableHead, StyledTableRow } from "ui-library/Styled";
import PropTypes from "prop-types";

export const TableContainer = ({ children, ...props }) => {
    return <StyledTableContainer {...props}>{children}</StyledTableContainer>;
};

export const Table = ({ children, vertical, ...props }) => {
    return (
        <StyledTable $vertical={vertical} {...props}>
            {children}
        </StyledTable>
    );
};

Table.propTypes = {
    vertical: PropTypes.bool,
};

Table.defaultProps = {
    vertical: false,
};

export const TableHead = ({ children, ...props }) => {
    return <StyledTableHead {...props}>{children}</StyledTableHead>;
};

export const TableBody = ({ children, ...props }) => {
    return <StyledTableBody {...props}>{children}</StyledTableBody>;
};

export const TableRow = ({ children, ...props }) => {
    return <StyledTableRow {...props}>{children}</StyledTableRow>;
};

export const TableCell = ({ children, variant, align, ...props }) => {
    return (
        <StyledTableCell variant={variant} align={align} {...props}>
            {children}
        </StyledTableCell>
    );
};

TableCell.propTypes = {
    variant: PropTypes.string,
    align: PropTypes.string,
};
TableCell.defaultProps = {
    variant: "body",
    align: "inherit",
};
