import { all, call, put, select } from "redux-saga/effects";
import { change, initialize } from "redux-form/lib/immutable";
import { Map } from "immutable";

import userInfo from "core/userInfo";
import { FORM_STEPS_BACK } from "app/constants";
import { formWrapper, scrollToFirstError } from "core/form";
import { fetch, fn, sentry } from "core/util";
import { ChangePropositionStatus, ChangePropositionType, DdsSavingStrategyFund } from "enums";
import { OTP_CODE_FIELD_NAME, OTP_ID_FIELD_NAME } from "modules/otp";
import errorHandling, { OTP_WRONG_MOBILE_ERROR_CODE, OTP_WRONG_MOBILE_NUMBER_FIN_OPERATIONS } from "core/errorHandling";
import { addOtpFormSuccess, resetOtpFormSuccess } from "core/form/actions";
import { changePropositionsApi, pensionDdsApi } from "serverApi";

import {
    CHANGE_STRATEGY_DATA,
    CHANGE_STRATEGY_DATA_DDS_CONTRIBUTORY,
    CHANGE_STRATEGY_DATA_DDS_GLOBAL,
    CHANGE_STRATEGY_DATA_DDS_INDEX,
    DDS_CONTRIBUTORY_FIELD_NAME,
    DDS_GLOBAL_FIELD_NAME,
    DDS_INDEX_FIELD_NAME,
    TRANSFER_FUNDS_DATA,
    TRANSFER_FUNDS_DATA_DDS_CONTRIBUTORY,
    TRANSFER_FUNDS_DATA_DDS_GLOBAL,
    TRANSFER_FUNDS_DATA_DDS_INDEX,
} from "./constants";

const defaultSectionData = {
    [DDS_GLOBAL_FIELD_NAME]: "",
    [DDS_CONTRIBUTORY_FIELD_NAME]: "",
    [DDS_INDEX_FIELD_NAME]: "",
};

export function* createSagaDds(
    formName,
    setResent,
    setPhoneNumber,
    getChangeProposition,
    loadChangePropositions,
    idObject,
    changePropositionId,
) {
    try {
        yield call(
            formSaga(formName, setResent, setPhoneNumber, getChangeProposition, loadChangePropositions),
            idObject,
            changePropositionId,
        );
    } catch (e) {
        sentry.captureException(e);
        yield put(errorHandling.addServiceError(formName, e.identifier));
    }
}

const formSaga = (formName, setResent, setPhoneNumber, getChangeProposition, loadChangePropositions) =>
    formWrapper(formName, {
        *initialize(idObject, changePropositionId) {
            yield call(userInfo.checkUserVerified, FORM_STEPS_BACK);
            yield put(errorHandling.removeServiceErrors(formName));
            yield put(resetOtpFormSuccess([formName]));
            if (changePropositionId) {
                const changePropositionData = yield select(getChangeProposition(changePropositionId));
                if (changePropositionData) {
                    const changePropositionNewFunds = changePropositionData.changeProposition.pensionFundsChange.newPensionFunds;
                    const changePropositionInvestmentStrategies =
                        changePropositionData.changeProposition.type === ChangePropositionType.PENSION_FUNDS_CHANGE.id
                            ? changePropositionData.changeProposition.pensionFundsChange.newInvestmentStrategyFunds
                            : changePropositionData.changeProposition.pensionInvestmentStrategiesChange.newInvestmentStrategyFunds;
                    yield put(initialize(formName, {}));
                    yield all([
                        put(
                            change(
                                formName,
                                TRANSFER_FUNDS_DATA_DDS_GLOBAL,
                                getFundPercentage(changePropositionNewFunds, DdsSavingStrategyFund.GLOBAL.fundId),
                            ),
                        ),
                        put(
                            change(
                                formName,
                                TRANSFER_FUNDS_DATA_DDS_CONTRIBUTORY,
                                getFundPercentage(changePropositionNewFunds, DdsSavingStrategyFund.CONTRIBUTORY.fundId),
                            ),
                        ),
                        put(
                            change(
                                formName,
                                TRANSFER_FUNDS_DATA_DDS_INDEX,
                                getFundPercentage(changePropositionNewFunds, DdsSavingStrategyFund.INDEX.fundId),
                            ),
                        ),
                        put(
                            change(
                                formName,
                                CHANGE_STRATEGY_DATA_DDS_GLOBAL,
                                getFundPercentage(changePropositionInvestmentStrategies, DdsSavingStrategyFund.GLOBAL.fundId),
                            ),
                        ),
                        put(
                            change(
                                formName,
                                CHANGE_STRATEGY_DATA_DDS_CONTRIBUTORY,
                                getFundPercentage(changePropositionInvestmentStrategies, DdsSavingStrategyFund.CONTRIBUTORY.fundId),
                            ),
                        ),
                        put(
                            change(
                                formName,
                                CHANGE_STRATEGY_DATA_DDS_INDEX,
                                getFundPercentage(changePropositionInvestmentStrategies, DdsSavingStrategyFund.INDEX.fundId),
                            ),
                        ),
                    ]);
                    return undefined;
                }
            }
            return {
                [CHANGE_STRATEGY_DATA]: defaultSectionData,
                [TRANSFER_FUNDS_DATA]: defaultSectionData,
            };
        },
        *onSubmitFail() {
            yield call(scrollToFirstError);
        },
        *save(values, idObject) {
            try {
                const data = Map()
                    .set(TRANSFER_FUNDS_DATA, makeBackendFieldData(values.get(TRANSFER_FUNDS_DATA)))
                    .set(CHANGE_STRATEGY_DATA, makeBackendFieldData(values.get(CHANGE_STRATEGY_DATA)))
                    .set("idObject", idObject)
                    .set("otpId", values.get(OTP_ID_FIELD_NAME))
                    .set("otpCode", values.get(OTP_CODE_FIELD_NAME));
                const result = yield call(pensionDdsApi.fundsChange, data);
                return result ? { otpId: result.otp_id, phone: result.phone_number } : {};
            } catch (e) {
                if (e.status === fetch.BAD_REQUEST && e.response.otpErrorCode === OTP_WRONG_MOBILE_ERROR_CODE) {
                    yield put(errorHandling.addServiceError(OTP_WRONG_MOBILE_NUMBER_FIN_OPERATIONS, e.identifier));
                    // throw empty precognition failed error to stop form submission
                    // throw new fetch.EmptyPrecognitionFailedError();
                } else {
                    throw e;
                }
            }
        },
        *success({ otpId, phone }, idObject, changePropositionId) {
            if (otpId) {
                yield put(change(formName, OTP_ID_FIELD_NAME, otpId));
                yield put(setPhoneNumber(phone));
            } else {
                if (changePropositionId) {
                    try {
                        yield call(
                            changePropositionsApi.updateChangePropositions,
                            idObject,
                            changePropositionId,
                            ChangePropositionStatus.ACCEPTED.id,
                        );
                    } catch (e) {
                        sentry.captureException(e);
                    }
                    yield call(loadChangePropositions, idObject);
                }
                yield put(addOtpFormSuccess(formName));
            }
        },
    });

const getFundPercentage = (fundList, fundCode) => {
    if (fundList.isEmpty()) {
        return "";
    }
    const fundData = fundList.find((fund) => Number(fund.fund.code) === fundCode);
    return fn.isEmpty(fundData) ? "" : fundData.percentage;
};

const makeBackendFieldData = (values) => {
    if (!values || values.every((value) => !value)) {
        return undefined;
    }
    return values
        .entrySeq()
        .toList()
        .map(([key, value]) =>
            Map({
                fundId: Number(DdsSavingStrategyFund[key].fundId),
                percents: value ? Number(value) : null,
            }),
        )
        .filter((item) => !!item.get("percents"));
};
