import { compose } from "redux";
import { calculateClaimsStatistics } from "./calculateClaimsStatistics";
import Immutable from "immutable";

const isEmpty = (value) => typeof value === "undefined" || value === null || value === "";

const calculateChartPercentage = (sumValue, items) =>
    items.map((item) => ({
        value: Math.round((Number(item.value) / Number(sumValue)) * 100),
        fullValue: item.fullValue,
        name: item.name,
        color: item.color,
    }));

export default {
    noop: () => {},
    array: (...value) => value.filter(Boolean),
    substringWithEtc: (value, size) => (value?.length < size ? value : value?.substring(0, size) + " ..."),
    identity: (item) => item,
    block: () => new Promise(() => {}),
    isEmpty,
    isEmptyOrZero: (value) => isEmpty(value) || Number(value) === 0,
    isObjectEmpty: (value) =>
        isEmpty(value) ||
        (typeof value === "object" && Object.keys(value).length === 0 && Object.getPrototypeOf(value) === Object.prototype),
    isArrayEmpty: (value) => isEmpty(value) || (Array.isArray(value) && value.length === 0),
    getFirstFromArray: (value) => (isEmpty(value) || (Array.isArray(value) && value.length === 0) ? null : value[0]),
    filterOptions: (options, allowedValues) => options.filter((option) => allowedValues.includes(option.get("value"))),
    compose,
    parseBoolean: (string) => string === "true",
    toObject: (result, [key, value]) => Object.assign({ [key]: value }, result),
    sum: (acc, number) => acc + number,
    decimalNumberWithDot: (value) => (value ? value.toString().replace(",", ".") : null),
    decimalNumberWithComma: (value) => (value ? value.toString().replace(".", ",") : null),
    decodeQueryParam: (value) => decodeURIComponent(value.replace(/\+/g, " ")),
    downloadBase64PdfFile: (base64File) => `data:application/pdf;base64,${base64File}`,
    calculateChartPercentage,
    calculateClaimsStatistics,
    getEnumItemById: (enumObject, id) => Object.values(enumObject).find((item) => item.id === id),
    getEnumItemByCode: (enumObject, code) => Object.values(enumObject).find((item) => item.code === code),
    isAmountValueZeroOrValueNull: (amount) => !amount || Number(amount.value) === 0 || !amount.value,
    isAmountValueLessEqOneOrValueNull: (amount) => !amount || Number(amount.value) <= 1 || !amount.value,
    isContractBalanceZeroOrValueNull: (financialDetail) =>
        Number(financialDetail?.balanceOnContract?.value) === 0 || !financialDetail?.balanceOnContract?.value,
    isBalanceZeroOrValueNull: (balance) => Number(balance?.value) === 0 || !balance?.value,
    isInternetExplorer:
        typeof window !== "undefined" &&
        (window.navigator.userAgent.indexOf("MSIE") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1),
    logoutRedirectUri: `${window.location.origin}/logout`,
    getItemOrFirstItemWhenIterable: (item) => (Immutable.Iterable.isIterable(item) ? item.first() : item),
    formatMarkdownText: (value) => value && value.replace(/ \n\n /g, " \n\n&nbsp;\n\n ").replace(/ \n /g, " \n\n "),
};
