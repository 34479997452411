export const NAME = "page-detail-incident";

// Form
export const FORM_EDIT_CLAIM_NOTE = `${NAME}/FORM_EDIT_CLAIM_NOTE`;
export const FORM_UPLOAD_CLAIM_DOCUMENTS = `${NAME}/FORM_UPLOAD_CLAIM_DOCUMENTS`;

// Entity
export const ENTITY_CLAIM_INSPECTION_ORDERS = `${NAME}/ENTITY_CLAIM_INSPECTION_ORDERS`;
export const ENTITY_CLAIM_REQUIRED_DOCUMENTS = `${NAME}/ENTITY_CLAIM_REQUIRED_DOCUMENTS`;
export const ENTITY_CLAIM_OUTGOING_DOCUMENTS = `${NAME}/ENTITY_CLAIM_OUTGOING_DOCUMENTS`;
