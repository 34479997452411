import React from "react";
import { useSelector } from "react-redux";

import { Field, SingleSelect } from "core/form";
import i18n from "core/i18n";

import { getFinancialOperationsFunds } from "../selectors";

export const FinancialOperationsBasicFilter = () => {
    const { t } = i18n.useTranslation();
    const fundOptions = useSelector(getFinancialOperationsFunds);

    return <Field name="fund" component={SingleSelect} options={fundOptions} placeholder={t("pension.financialOperation.selectFund")} />;
};
