import React from "react";
import { useSelector } from "react-redux";

import userInfo from "core/userInfo";
import { MessagesAccordion } from "ui-library/atoms";
import { Box } from "ui-library/layouts";

export function AnnouncementsTab() {
    const messages = useSelector(userInfo.getMessages);

    return (
        <Box>
            <MessagesAccordion messages={messages} id="messagesAccordion" />
        </Box>
    );
}
