import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EnumState, initialState, NAME, SetEnumPayload } from "./constants";

export const setEnum: CaseReducer<EnumState, PayloadAction<SetEnumPayload>> = (state, { payload }) => {
    state.content[payload.enumName] = payload.content;
};

export const loadIfNeeded: CaseReducer<EnumState, PayloadAction<string[]>> = (state, { payload }) => {
    // load in saga
};

export const slice = createSlice({
    name: NAME,
    initialState,
    reducers: {
        setEnum: {
            reducer: setEnum,
            prepare: (enumName: string, content: any) => ({
                payload: { enumName, content },
            }),
        },
        loadIfNeeded,
    },
});

export default slice;
