import { withTheme } from "@mui/styles";
import { Box } from "ui-library/layouts/Box/Box";
import { Flexbox } from "ui-library/layouts/Flexbox/Flexbox";
import styled from "styled-components";

export const StyledHeader = withTheme(styled("header")`
    transition: all ease 200ms;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    box-shadow: 0px 20px 35px 0px #30313d1a;
    background: ${(props) => props.theme.palette.white.main};
    z-index: 3;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        height: 70px;
        display: flex;
        align-items: center;
        &.opened-header-menu {
            z-index: 5;
            transition: all ease 200ms;
        }
    }
`);

export const MenuWrapper = withTheme(styled(Flexbox)`
    gap: 25px;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: none;
        gap: 0;
        position: fixed;
        top: 70px;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: ${(props) => props.theme.palette.white.main};
        flex-direction: column;
        padding: 0 20px;
        z-index: 101;
        &.opened {
            display: flex;
        }
        .MuiBadge-root {
            a {
                width: 100%;
            }
            .MuiBadge-badge {
                top: 50%;
                position: absolute;
            }
        }
    }
`);

export const LogoWrapper = withTheme(styled(Box)`
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        flex: 1;
        display: flex;
    }
    img {
        max-width: 160px;
        object-fit: unset;
        @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
            max-width: 101px;
        }
    }
`);
