import { createSaga } from "./createSaga";
import { createContainer } from "./createContainer";

/**
 * Saga and Container creator for Holder edit form
 */
const userVerificationForm = {
    createSaga,
    createContainer,
};
export default userVerificationForm;
