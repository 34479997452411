import createModel from "../createModel";
import { OpeningTimes } from "./OpeningTimes";

export const Branch = createModel("Branch", (json) => ({
    latitude: json.Latitude,
    longitude: json.Longitude,
    openingTimes: json.OpeningTimes && OpeningTimes.fromServer(json.OpeningTimes),
    addressTypeCode: json.AddressTypeCode,
    street: json.Street,
    orientationNumber: json.OrientationNumber,
    descriptionNumber: json.DescriptionNumber,
    city: json.City,
    postalCode: json.PostalCode,
    countryCode: json.CountryCode,
}));
