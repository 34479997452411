import pageContext from "core/pageContext";

import { ProductLine, ProductPolicy } from "types";

import { getProductDefinition, getProductPolicyByPGL2 } from "./helper";

export const useGetProductDefinition: (idEnuProductGroupLevelTwo: number) => ProductLine = (idEnuProductGroupLevelTwo) => {
    const { isSlovakSite } = pageContext.useGetPageLocalization();
    return getProductDefinition(idEnuProductGroupLevelTwo, isSlovakSite);
};

export const useGetProductPolicy: (idEnuProductGroupLevelTwo: number) => ProductPolicy = (idEnuProductGroupLevelTwo) => {
    return getProductPolicyByPGL2(idEnuProductGroupLevelTwo);
};
