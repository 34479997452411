function NotAuthenticatedError(error) {
    this.status = error.status;
    if (error.response) {
        this.response = error.response.body;
        this.identifier = error.response.headers?.identifier;
    }
}

NotAuthenticatedError.prototype = Object.create(Error.prototype);
NotAuthenticatedError.prototype.name = "NotAuthenticatedError";
NotAuthenticatedError.prototype.constructor = NotAuthenticatedError;

export default NotAuthenticatedError;
