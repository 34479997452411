import { Set } from "immutable";

import carImg from "ui-library/assets/images/car-crash.svg";
import propertyImg from "ui-library/assets/images/property-event.svg";
import travelImg from "ui-library/assets/images/travel-event.svg";
import lifeImg from "ui-library/assets/images/life-event.svg";

import { ClaimReportCategory, ClaimsLineOfBusinessClass, Countries, ProductGroupLvl2, UniqaLineOfBusiness } from "enums";

const getLineOfBusiness = (item) => item.lineOfBusinesses?.get(0);

const getLocalizedLineOfBusiness = (item, uniqaLineOfBusinessMapObj) => {
    const lineOfBusiness = getLineOfBusiness(item);
    if (!lineOfBusiness) {
        return "";
    }
    return `${uniqaLineOfBusinessMapObj[lineOfBusiness]}: `;
};

const getLocalizedInsuranceRisk = (item, uniqaInsuranceRiskMapObj) => {
    const foundRisk = uniqaInsuranceRiskMapObj[item?.insuranceRisk?.key];
    if (!foundRisk) {
        return item?.insuranceRisk?.value;
    }
    return foundRisk;
};

const getImageCategory = (item) => {
    const lineOfBusiness = getLineOfBusiness(item);
    const filtered = Object.values(UniqaLineOfBusiness).find((item) => item.id === lineOfBusiness);
    return getBgImg(filtered?.img);
};

const getImageCategoryByProductGroupLvl2 = (idEnuProductGroupLevelTwo) => {
    switch (idEnuProductGroupLevelTwo) {
        case ProductGroupLvl2.HOME:
        case ProductGroupLvl2.INDUSTRY:
        case ProductGroupLvl2.BUFT:
        case ProductGroupLvl2.LIABILITY:
        case ProductGroupLvl2.TRANSPORT:
        case ProductGroupLvl2.BUSINESS:
            return propertyImg;
        case ProductGroupLvl2.VEHICLE:
            return carImg;
        case ProductGroupLvl2.TRAVEL:
            return travelImg;
        default:
            return lifeImg;
    }
};

const getBgImg = (productType) => {
    switch (productType) {
        case "car":
            return carImg;
        case "property":
            return propertyImg;
        case "travel":
            return travelImg;
        case "life":
            return lifeImg;
        default:
            return lifeImg;
    }
};

const getClaimReportRedirectUrl = (baseRedirectUrl, idObject, lineOfBusinessClass) =>
    `${baseRedirectUrl}?idObject=${idObject}&lineOfBusiness=${lineOfBusinessClass}`;

// const getCompliantLineOfBusinesses = (lineOfBusinessClasses, policyProductGroupLvl2) => {
//     if (!policyProductGroupLvl2 || !lineOfBusinessClasses || lineOfBusinessClasses.isEmpty()) {
//         return Set();
//     } else if (
//         ClaimReportCategory.TRAVEL.productGroupLvls2.contains(policyProductGroupLvl2) ||
//         ClaimReportCategory.LIFE.productGroupLvls2.contains(policyProductGroupLvl2)
//     ) {
//         if (lineOfBusinessClasses.contains(ClaimsLineOfBusinessClass.TRAVEL.id)) {
//             return Set([ClaimsLineOfBusinessClass.TRAVEL.id]);
//         } else if (
//             Set([ClaimsLineOfBusinessClass.LIFE.id, ClaimsLineOfBusinessClass.ACCIDENT.id, ClaimsLineOfBusinessClass.HEALTH.id]).intersect(
//                 lineOfBusinessClasses,
//             )
//         ) {
//             return Set([ClaimsLineOfBusinessClass.LIFE.id]);
//         } else {
//             return Set();
//         }
//     } else if (ClaimReportCategory.VEHICLE.productGroupLvls2.contains(policyProductGroupLvl2)) {
//         const compliantVehicleClasses = ClaimReportCategory.VEHICLE.compliantLineOfBusinessClasses;
//         return compliantVehicleClasses.intersect(lineOfBusinessClasses);
//     } else if (ClaimReportCategory.HOME_AND_LIABILITY.productGroupLvls2.contains(policyProductGroupLvl2)) {
//         const compliantVehicleClasses = ClaimReportCategory.HOME_AND_LIABILITY.compliantLineOfBusinessClasses;
//         return compliantVehicleClasses.intersect(lineOfBusinessClasses);
//     } else {
//         return Set();
//     }
// };

// temporary solution
const getCompliantLineOfBusinesses = (lineOfBusinessClasses, policyProductGroupLvl2, siteId) => {
    if (!policyProductGroupLvl2 || !lineOfBusinessClasses || lineOfBusinessClasses.isEmpty()) {
        return Set();
    } else if (
        ClaimReportCategory.TRAVEL.productGroupLvls2.contains(policyProductGroupLvl2) ||
        ClaimReportCategory.LIFE.productGroupLvls2.contains(policyProductGroupLvl2)
    ) {
        if (lineOfBusinessClasses.contains(ClaimsLineOfBusinessClass.TRAVEL.id)) {
            return Set([ClaimsLineOfBusinessClass.TRAVEL.id]);
        } else if (
            Set([ClaimsLineOfBusinessClass.LIFE.id, ClaimsLineOfBusinessClass.ACCIDENT.id, ClaimsLineOfBusinessClass.HEALTH.id]).intersect(
                lineOfBusinessClasses,
            )
        ) {
            return Set([ClaimsLineOfBusinessClass.LIFE.id]);
        } else {
            return Set();
        }
    } else if (ClaimReportCategory.VEHICLE.productGroupLvls2.contains(policyProductGroupLvl2)) {
        const compliantVehicleClasses = ClaimReportCategory.VEHICLE.compliantLineOfBusinessClasses;
        if (siteId === Countries.SK.id) {
            const classes = [];
            if (lineOfBusinessClasses.contains(ClaimsLineOfBusinessClass.MTPL.id)) {
                classes.push(ClaimsLineOfBusinessClass.MTPL.id, ClaimsLineOfBusinessClass.GLASS.id);
            }
            if (lineOfBusinessClasses.contains(ClaimsLineOfBusinessClass.CASCO.id)) {
                classes.push(ClaimsLineOfBusinessClass.CASCO.id, ClaimsLineOfBusinessClass.GLASS_CASCO.id);
            }
            return Set(classes);
        } else {
            return compliantVehicleClasses.intersect(lineOfBusinessClasses);
        }
    } else if (ClaimReportCategory.HOME_AND_LIABILITY.productGroupLvls2.contains(policyProductGroupLvl2)) {
        const compliantVehicleClasses = ClaimReportCategory.HOME_AND_LIABILITY.compliantLineOfBusinessClasses;
        return compliantVehicleClasses.intersect(lineOfBusinessClasses);
    } else {
        return Set();
    }
};

export default {
    getLocalizedLineOfBusiness,
    getLocalizedInsuranceRisk,
    getImageCategory,
    getImageCategoryByProductGroupLvl2,
    getClaimReportRedirectUrl,
    getCompliantLineOfBusinesses,
};
