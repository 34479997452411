import { Button, Typography } from "ui-library/atoms";
import { SectionBanner, SectionInnerBlock } from "ui-library/modules";
import { Box, Flexbox } from "ui-library/layouts";
import useBreakpoints from "ui-library/theme/breakpoints";
import bannerImg from "ui-library/assets/images/banner-kontakt.svg";
import mobileBanner from "ui-library/assets/images/contact-mobile.svg";

import i18n from "core/i18n";
import { useGetSiteValue } from "core/hooks";
import { CallCenterContactData } from "enums";

export const Banner = () => {
    const { t } = i18n.useTranslation();
    const getSiteValue = useGetSiteValue();

    return (
        <SectionBanner
            title={t("common.contact")}
            description={t("page.contact.banner.description")}
            imageUrl={useBreakpoints("md") ? bannerImg : mobileBanner}
        >
            <SectionInnerBlock>
                <Box mb={1}>
                    <Typography variant="b">{t("page.contact.infoline.description")}</Typography>
                </Box>

                <Flexbox direction={{ xs: "column", lg: "row" }} gap={2}>
                    <Box>
                        <Button
                            color="blue"
                            variant="contained"
                            size="large"
                            externalLink={`tel:${getSiteValue(CallCenterContactData.PHONE_AS_LINK)}`}
                        >
                            {getSiteValue(CallCenterContactData.PHONE)}
                        </Button>
                    </Box>
                    <Box>
                        <Button
                            color="blue"
                            variant="outlined"
                            size="large"
                            externalLink={`mailto:${getSiteValue(CallCenterContactData.EMAIL)}`}
                        >
                            {getSiteValue(CallCenterContactData.EMAIL)}
                        </Button>
                    </Box>
                </Flexbox>
            </SectionInnerBlock>
        </SectionBanner>
    );
};
