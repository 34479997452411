import React from "react";
import { useDispatch } from "react-redux";

import i18n from "core/i18n";
import { ContentBox, Flexbox } from "ui-library/layouts";
import { Button, Typography } from "ui-library/atoms";

import { setDisclaimerStep } from "../actions";
import { STEP_DISCLAIMER_TEXT } from "../constants";

export const Declined = () => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();

    return (
        <>
            <ContentBox pb={{ xs: 2, md: 2 }}>
                <Typography component="h3" variant="h3">
                    {t("page.disclaimer.errorTitle")}
                </Typography>
            </ContentBox>

            <ContentBox pb={{ xs: 4, md: 4 }}>
                <Typography component="p" variant="p">
                    {t("page.disclaimer.errorText")}
                </Typography>
            </ContentBox>

            <Flexbox gap={2} justifyContent="space-between">
                <Button onClick={() => dispatch(setDisclaimerStep(STEP_DISCLAIMER_TEXT))} variant="outlined" color="blue">
                    {t("page.disclaimer.back")}
                </Button>
            </Flexbox>
        </>
    );
};
