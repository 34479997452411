import React from "react";

import i18n from "core/i18n";
import { ContentBox } from "ui-library/layouts";
import { Typography } from "ui-library/atoms";

export const CannotChangeProfileComponent = () => {
    const { t } = i18n.useTranslation();
    return (
        <ContentBox mb={2}>
            <ContentBox mb={2}>
                <Typography markDown={t("investments.profileChange.forbiddenIfActiveIndividualProfile")} />
            </ContentBox>
        </ContentBox>
    );
};
