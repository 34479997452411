import React from "react";
import { Outlet } from "react-router-dom";

import { Button, Container, SidemenuLink } from "ui-library/atoms";
import { Flexbox, SubPageLayout, SubPageWrapper } from "ui-library/layouts";
import { PageLead, Sidemenu } from "ui-library/modules";

import i18n from "core/i18n";
import { useGetSiteValue } from "core/hooks";
import { CallCenterContactData } from "enums";
import { innerRoutes } from "routeUrls";
import { Tabs } from "routeConstants";

import { ModalWriteMessageForm } from "./form";

export default () => {
    const { t } = i18n.useTranslation();
    const getSiteValue = useGetSiteValue();

    return (
        <SubPageLayout>
            <Container>
                <PageLead title={t("page.messages.title")} lead={t("page.messages.description")}>
                    <Flexbox direction={{ xs: "column", md: "row" }} mt={4} gap={1}>
                        <Button
                            variant={"outlined"}
                            color={"blue"}
                            externalLink={`tel:${getSiteValue(CallCenterContactData.PHONE_AS_LINK)}`}
                        >
                            {getSiteValue(CallCenterContactData.PHONE)}
                        </Button>
                    </Flexbox>
                </PageLead>
                <SubPageWrapper>
                    <Sidemenu>
                        <SidemenuLink to={innerRoutes[Tabs.ANNOUNCEMENTS_TAB]}>{t("menu.tab.announcements")}</SidemenuLink>
                        <SidemenuLink to={innerRoutes[Tabs.REQUESTS]}>{t("menu.tab.requests")}</SidemenuLink>
                    </Sidemenu>

                    <Outlet />
                </SubPageWrapper>
            </Container>

            {/* Modals */}
            <ModalWriteMessageForm />
        </SubPageLayout>
    );
};
