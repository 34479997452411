import { call, put, select, takeEvery } from "redux-saga/effects";
import { change } from "redux-form/immutable";

import autocomplete from "core/autocomplete";
import coreEnum from "core/enum";
import userContracts from "core/userContracts";
import errorHandling from "core/errorHandling";
import { fieldChangeMatcher, formWrapper, scrollToFirstError } from "core/form";
import { model, sentry } from "core/util";
import { RedeemPensionPpPensionTypes } from "enums";
import { pensionPpApi } from "serverApi";
import { AddressTypeCodeEnum } from "types";

import {
    ACTIVE_STEP_VIRTUAL_FIELD,
    ADDRESS_AUTOCOMPLETE,
    ADDRESS_SECTION,
    BANK_ACCOUNT_NUMBER_FIELD,
    BANK_ACCOUNT_PREFIX_FIELD,
    BANK_ACCOUNT_SECTION,
    BANK_CODE_FIELD,
    CITY_FORM_FIELD,
    DESCRIPTION_NUMBER_FORM_FIELD,
    EMPTY_ACCOUNT_PREFIX,
    EXTRAORDINARY_PAYMENT_AMOUNT_VIRTUAL_FIELD,
    ID_OBJECT_VIRTUAL_FIELD,
    ORIENTATION_NUMBER_FORM_FIELD,
    PAYMENT_AMOUNT_VIRTUAL_FIELD,
    PENSION_TYPE_FIELD,
    REDEEM_TYPE_FIELD,
    SPECIFIC_SYMBOL_FIELD,
    STATE_FORM_FIELD,
    STREET_FORM_FIELD,
    VARIABLE_SYMBOL_FIELD,
    ZIP_FORM_FIELD,
} from "./constants";
import { pensionTypeFieldChangeSaga, placeSelectedSaga, prepareDefaultTypeValues } from "./saga";

export function* createSagaPp(
    formName,
    idObject,
    policyHolderSelector,
    loadContractAdditionInformation,
    contractAdditionalInfoSelector,
    redeemAllowedPensionTypesSelector,
    redeemAllowedRedeemTypesSelector,
) {
    try {
        yield call(
            formSaga(
                formName,
                loadContractAdditionInformation,
                contractAdditionalInfoSelector,
                redeemAllowedPensionTypesSelector,
                redeemAllowedRedeemTypesSelector,
                policyHolderSelector,
            ),
            idObject,
        );
    } catch (e) {
        sentry.captureException(e);
        yield put(errorHandling.addServiceError(formName, e.identifier));
    }
}

const formSaga = (
    formName,
    loadContractAdditionInformation,
    contractAdditionalInfoSelector,
    redeemAllowedPensionTypesSelector,
    redeemAllowedRedeemTypesSelector,
    policyHolderSelector,
) =>
    formWrapper(formName, {
        *persistentEffects() {
            yield takeEvery(autocomplete.selectMatcher(ADDRESS_AUTOCOMPLETE), placeSelectedSaga, formName, ADDRESS_SECTION);
            yield takeEvery(
                fieldChangeMatcher(formName, PENSION_TYPE_FIELD),
                pensionTypeFieldChangeSaga,
                formName,
                RedeemPensionPpPensionTypes,
                redeemAllowedRedeemTypesSelector,
            );
        },
        *initialize(idObject) {
            yield put(errorHandling.removeServiceErrors(formName));
            yield put(coreEnum.loadIfNeeded([coreEnum.BANK_LIST]));

            // Make sure we have contract additional information loaded first.
            const data = yield call(loadContractAdditionInformation, idObject);

            const policy = yield select(userContracts.createGetContractById(Number(idObject)));
            const policyHolder = yield select(policyHolderSelector);
            const contactAddress = model.getAddressByTypeCode(policyHolder, AddressTypeCodeEnum.CON);
            const { defaultPensionType, defaultRedeemType } = yield call(
                prepareDefaultTypeValues,
                redeemAllowedPensionTypesSelector,
                redeemAllowedRedeemTypesSelector,
            );

            const additionalInformation = yield select(contractAdditionalInfoSelector);
            const account = additionalInformation?.account;

            return {
                [ID_OBJECT_VIRTUAL_FIELD]: idObject,
                [PENSION_TYPE_FIELD]: defaultPensionType,
                [REDEEM_TYPE_FIELD]: defaultRedeemType,
                [ADDRESS_SECTION]: {
                    [STREET_FORM_FIELD]: contactAddress.street,
                    [DESCRIPTION_NUMBER_FORM_FIELD]: contactAddress.descriptionNumber,
                    [ORIENTATION_NUMBER_FORM_FIELD]: contactAddress.orientationNumber,
                    [CITY_FORM_FIELD]: contactAddress.city,
                    [ZIP_FORM_FIELD]: contactAddress.postalCode,
                    [STATE_FORM_FIELD]: contactAddress.countryCode,
                },
                [BANK_ACCOUNT_SECTION]: {
                    [BANK_ACCOUNT_PREFIX_FIELD]: account?.prefix && account?.prefix !== EMPTY_ACCOUNT_PREFIX ? account.prefix : null,
                    [BANK_ACCOUNT_NUMBER_FIELD]: account?.number || null,
                    [BANK_CODE_FIELD]: account?.bankCode || null,
                    [VARIABLE_SYMBOL_FIELD]: policy.contractNumber,
                    [SPECIFIC_SYMBOL_FIELD]: policyHolder.birthNumber,
                },
                [ACTIVE_STEP_VIRTUAL_FIELD]: 0,
            };
        },
        *onSubmitFail() {
            yield call(scrollToFirstError);
        },
        *save(data) {
            const result = yield call(pensionPpApi.putRedeemSavings, data);
            return {
                activeStep: result.activeStep,
                extraordinaryPaymentAmount: result.extraordinaryPaymentAmount,
                paymentAmount: result.amount,
            };
        },
        *success({ activeStep, extraordinaryPaymentAmount, paymentAmount }) {
            yield put(change(formName, ACTIVE_STEP_VIRTUAL_FIELD, activeStep));
            yield put(change(formName, PAYMENT_AMOUNT_VIRTUAL_FIELD, paymentAmount));
            yield put(change(formName, EXTRAORDINARY_PAYMENT_AMOUNT_VIRTUAL_FIELD, extraordinaryPaymentAmount));
        },
    });
