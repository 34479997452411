import { FC, PropsWithChildren } from "react";
import { Box as MaterialBox, BoxProps as MaterialBoxProps } from "@mui/material";

export interface BoxProps extends PropsWithChildren<MaterialBoxProps> {
    fullWidth?: boolean;
}

export const Box: FC<BoxProps> = ({ children, fullWidth = false, ...props }) => (
    <MaterialBox width={fullWidth ? 1 : props.width} {...props}>
        {children}
    </MaterialBox>
);
