import createModel from "../createModel";

export const ClientDocument = createModel("ClientDocument", (json) => ({
    id: json.id,
    guid: json.guid,
    status: json.status,
    date: json.date,
    documentType: "PDF",
    typeCode: json.typeCode,
    typeLocalName: json.typeLocalName,
}));
