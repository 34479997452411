import slice from "./slice";
import * as selectors from "./selectors";

const modal = {
    NAME: slice.name,
    reducer: slice.reducer,
    ...slice.actions,
    ...selectors,
};

export default modal;
