import { model } from "core/util";
import { v4 as uuid } from "uuid";

import { AmountLegacy } from "../../common";
import { PensionDpsFundInvestmentsStatement } from "./PensionDpsFundInvestmentsStatement";
import { PensionDpsContractBalanceSheet } from "./PensionDpsContractBalanceSheet";

export const PensionDpsFinancialStatement = model.createModelLegacy("PensionDpsFinancialStatement", (json) => ({
    reactKey: uuid(),
    dateTime: json.DateTime,
    netAmountTotal: AmountLegacy.fromServer(json.NetAmountTotal),
    investmentsSumInvested: AmountLegacy.fromServer(json.InvestmentsSumInvested),
    investmentsSumNotInvested: AmountLegacy.fromServer(json.InvestmentsSumNotInvested),
    sumShares: json.SumShares,
    investmentsValue: AmountLegacy.fromServer(json.InvestmentsValue),
    valuationPercentage: json.ValuationPercentage,
    actualInvestmentsValue: AmountLegacy.fromServer(json.ActualInvestmentsValue),
    pensionInvestmentsFundsInvestmentsStatements: PensionDpsFundInvestmentsStatement.fromServerList(
        json.PensionInvestmentsFundsInvestmentsStatements,
    ),
    contractBalanceSheet: PensionDpsContractBalanceSheet.fromServer(json.ContractBalanceSheet),
}));
