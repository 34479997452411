import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { ContentBox, GridItem, GridLayout } from "ui-library/layouts";

import i18n from "core/i18n";
import {
    Field,
    getFormFieldValue,
    InputText,
    lettersNumbersBasicInterpunctionCharacters,
    max20Characters,
    max50Characters,
    max6Characters,
    numberSpaceCharacters,
    required,
    SingleSelect,
    zip,
} from "core/form";
import { getLocalizedCountryOptions, getLocalizedDomesticCountryOption } from "core/localized";

import {
    CITY_FORM_FIELD,
    DESCRIPTION_NUMBER_FORM_FIELD,
    ORIENTATION_NUMBER_FORM_FIELD,
    STATE_FORM_FIELD,
    STREET_FORM_FIELD,
    ZIP_FORM_FIELD,
} from "../constants";
import { SectionDescription } from "form/fullPage/policyHolder/components";

const isDomesticCountry = (country) => country === "CZ" || country === "SK";

const AddressFieldsContainer = ({ formName, sectionName, addressAutocomplete, disabled, isUniqaContract }) => {
    const { t } = i18n.useTranslation();
    const countryOptions = useSelector(getLocalizedCountryOptions);
    const domesticCountryOption = useSelector(getLocalizedDomesticCountryOption);
    const selectedCountry = useSelector(getFormFieldValue(formName, `${sectionName}.${STATE_FORM_FIELD}`));

    return (
        <>
            <ContentBox>
                <Field
                    name={`${sectionName}.${STREET_FORM_FIELD}`}
                    component={disabled ? InputText : addressAutocomplete}
                    label={t("holder.street")}
                    normalize={[lettersNumbersBasicInterpunctionCharacters, max50Characters]}
                    disabled={disabled}
                />
            </ContentBox>

            <ContentBox>
                <GridLayout spacing={2}>
                    <GridItem xs={12} md={6}>
                        <Field
                            name={`${sectionName}.${DESCRIPTION_NUMBER_FORM_FIELD}`}
                            component={InputText}
                            label={t("holder.descriptionNumber")}
                            normalize={[lettersNumbersBasicInterpunctionCharacters, max20Characters]}
                            validateStatic={[required]}
                            disabled={disabled}
                        />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <Field
                            name={`${sectionName}.${ORIENTATION_NUMBER_FORM_FIELD}`}
                            component={InputText}
                            label={t("holder.orientationNumber")}
                            normalize={[lettersNumbersBasicInterpunctionCharacters, max20Characters]}
                            disabled={disabled}
                        />
                    </GridItem>
                </GridLayout>
            </ContentBox>

            <ContentBox>
                <GridLayout spacing={2}>
                    <GridItem xs={12} md={9}>
                        <Field
                            name={`${sectionName}.${CITY_FORM_FIELD}`}
                            component={InputText}
                            label={t("holder.city")}
                            normalize={[lettersNumbersBasicInterpunctionCharacters, max50Characters]}
                            disabled={disabled}
                        />
                    </GridItem>
                    <GridItem xs={12} md={3}>
                        <Field
                            name={`${sectionName}.${ZIP_FORM_FIELD}`}
                            component={InputText}
                            label={t("holder.postalCode")}
                            normalize={[
                                isDomesticCountry(selectedCountry) ? numberSpaceCharacters : lettersNumbersBasicInterpunctionCharacters,
                                isDomesticCountry(selectedCountry) ? max6Characters : max20Characters,
                            ]}
                            validateStatic={[isDomesticCountry(selectedCountry) ? zip : required]}
                            disabled={disabled}
                        />
                    </GridItem>
                </GridLayout>
            </ContentBox>

            <ContentBox>
                <Field
                    name={`${sectionName}.${STATE_FORM_FIELD}`}
                    component={SingleSelect}
                    label={t("holder.countryCode")}
                    options={isUniqaContract ? domesticCountryOption : countryOptions}
                    disabled={disabled}
                />
                {isUniqaContract && <SectionDescription content={t("holder.contactAddressOnlyDomesticCountry")} />}
            </ContentBox>
        </>
    );
};

AddressFieldsContainer.propTypes = {
    formName: PropTypes.string.isRequired,
    sectionName: PropTypes.string.isRequired,
    addressAutocomplete: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    isUniqaContract: PropTypes.bool,
};

AddressFieldsContainer.defaultProps = {
    disabled: false,
    isUniqaContract: false,
};

export default AddressFieldsContainer;
