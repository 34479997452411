export const NAME = "MODULE_EPIN";

export const EPIN_PHASE_ISSUE = `${NAME}/EPIN_PHASE_ISSUE`;
export const EPIN_PHASE_PASSWORD = `${NAME}/EPIN_PHASE_PASSWORD`;
export const EPIN_PHASE_VERIFICATION = `${NAME}/EPIN_PHASE_VERIFICATION`;

export const createFormConstants = (formPrefix) => ({
    FORM_EPIN_ISSUE: `${formPrefix}/EPIN_PHASE_ISSUE`,
    FORM_EPIN_PASSWORD: `${formPrefix}/FORM_EPIN_PASSWORD`,
    FORM_EPIN_VERIFICATION: `${formPrefix}/FORM_EPIN_VERIFICATION`,
});
