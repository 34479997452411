import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { ContentBox } from "ui-library/layouts";
import { Container, Typography } from "ui-library/atoms";
import { FileInfo, InfoPanel } from "ui-library/modules";

import i18n from "core/i18n";
import { getFormFieldValue } from "core/form";
import { documentsApi } from "serverApi";

import { ID_OBJECT_VIRTUAL_FIELD } from "../../constants";
import { StepThreeButtonsBox } from "../../components";

export const StepThreeForm = ({ formName, idObject }) => {
    const { t } = i18n.useTranslation();
    const documentUrl = () => documentsApi.getPensionRedeemSavingsDocumentDownloadUrl(idObject);

    return (
        <Container maxWidth="md">
            <InfoPanel title={t("form.pensionRedeemSavings.authorization.title")}>
                <FileInfo icon="download" fileName={t("common.downloadRedeem")} onClick={() => window.open(documentUrl())} />

                <ContentBox mt={4}>
                    <Typography markDown={t("form.pensionRedeemSavings.authorization.textSection1")} />
                    <Typography markDown={t("form.pensionRedeemSavings.authorization.address")} />
                </ContentBox>

                <ContentBox mt={4}>
                    <Typography markDown={t("form.pensionRedeemSavings.authorization.textSection2")} />
                </ContentBox>

                <StepThreeButtonsBox idObject={idObject} />
            </InfoPanel>
        </Container>
    );
};

StepThreeForm.propTypes = {
    formName: PropTypes.string.isRequired,
    idObject: PropTypes.number.isRequired,
};
