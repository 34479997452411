import { model } from "core/util";

export const PolicyAgent = model.createModelLegacy("PolicyAgent", (json) => ({
    idObjectAgentContractAgent: json.idAgentContractAgent || json.idObjectAgentContractAgent,
    firstName: json.firstName,
    lastName: json.lastName,
    companyName: json.companyName,
    agentNumber: json.agentNumber,
    agentState: json.agentState,
    agentFunction: json.agentFunction,
    idEnuBranch: json.idEnuBranch,
    branch: json.branch,
}));
