/* eslint-disable */
import { fetch } from "core/util";
import { ClaimsEvents } from "model/claim";
import { HarmonizedFinancialOperation } from "model/harmonized";
import { UniqaLife, UniqaLifeFinancialStatement } from "model/uniqaLife";
import { API_PREFIX } from "app/constants";

const productUriPrefix = `${API_PREFIX}/product/life`;
const getLifeContractDetail = (idObject) => fetch.doGet(`${productUriPrefix}/${idObject}/contract-detail`, null, UniqaLife.fromServer);
const getLifeFinancialOperations = (idObject) =>
    fetch.doGet(`${productUriPrefix}/${idObject}/financial-operations`, null, HarmonizedFinancialOperation.fromServerList);
const getLifeFinancialStatement = (objectId) =>
    fetch.doGet(`${productUriPrefix}/${objectId}/financial-statement`, null, UniqaLifeFinancialStatement.fromServer);
const getLifeClaims = (idObject) => fetch.doGet(`${productUriPrefix}/${idObject}/claims`, null, ClaimsEvents.fromServer);

export default {
    getLifeContractDetail,
    getLifeFinancialOperations,
    getLifeFinancialStatement,
    getLifeClaims,
};
