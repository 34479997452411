import PropTypes from "prop-types";
import React from "react";

import { Block } from "ui-library/atoms";

export const SectionInnerBlock = ({ children, ...props }) => {
    return (
        <Block variant="default" shadow width={{ xs: "100%", sm: "fit-content" }} {...props}>
            {children}
        </Block>
    );
};

SectionInnerBlock.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    children: PropTypes.node,
    imageUrl: PropTypes.string,
};
