import { combineReducers } from "redux-immutable";

import { SET_ERROR, SET_RESULT } from "./actions";

const phoneReducer = (state = null, { type, payload }) => {
    switch (type) {
        case SET_RESULT:
            return payload.phoneNumberShort;
        case SET_ERROR:
            return null;
        default:
            return state;
    }
};

const resendEpinCountExhaustedReducer = (state = null, { type, payload }) => {
    switch (type) {
        case SET_RESULT:
            return payload.resendEpinCountExhausted;
        case SET_ERROR:
            return null;
        default:
            return state;
    }
};

const resendEpinInvalidHashReducer = (state = null, { type, payload }) => {
    switch (type) {
        case SET_RESULT:
            return payload.invalidHash;
        case SET_ERROR:
            return null;
        default:
            return state;
    }
};

const errorReducer = (state = null, { type }) => {
    switch (type) {
        case SET_ERROR:
            return true;
        case SET_RESULT:
            return null;
        default:
            return state;
    }
};

export default combineReducers({
    phoneNumberShort: phoneReducer,
    resendEpinCountExhausted: resendEpinCountExhaustedReducer,
    invalidHash: resendEpinInvalidHashReducer,
    error: errorReducer,
});
