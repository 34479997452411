import { Icon } from "ui-library/atoms/Icons/Icon";
import * as React from "react";
import { StyledHamburger } from "ui-library/Styled";
import PropTypes from "prop-types";

export const Hamburger = ({ onClick, isOpenState }) => {
    return (
        <StyledHamburger onClick={onClick} p={{ xs: 1.5, md: 0 }} mr={{ xs: -1.5, md: 0 }}>
            <Icon size={18} icon={isOpenState ? "error" : "hamburger"} color="gray" />
        </StyledHamburger>
    );
};

Hamburger.propTypes = {
    isOpenState: PropTypes.bool,
    onClick: PropTypes.func,
};
