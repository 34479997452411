import React from "react";
import { useSelector } from "react-redux";

import i18n from "core/i18n";
import { Box, GridItem, GridLayout } from "ui-library/layouts";
import { InfoPanel, LabelTextItem } from "ui-library/modules";
import { Typography } from "ui-library/atoms";

import { getIncidentDetail } from "../selectors";
import { IncidentDtoIncidentStateEnum } from "types/generated";

const formatAddressTmp = (address) => `${address.street} ${address.city}`;

export const IncidentBasicData = () => {
    const { t } = i18n.useTranslation();
    const incidentDetail = useSelector(getIncidentDetail);
    const isAddressPresent = incidentDetail.address && incidentDetail.address.street;

    return (
        <Box mb={8}>
            <InfoPanel icon={"document"} title={t("common.basicData")}>
                <GridLayout>
                    <GridItem xs={12} md={6}>
                        <LabelTextItem label={t("incident.type")} text={incidentDetail?.insuranceRisk?.value} />
                        {incidentDetail?.incidentCause?.value && (
                            <LabelTextItem label={t("incident.cause")} text={incidentDetail?.incidentCause?.value} />
                        )}
                        {isAddressPresent && (
                            <LabelTextItem label={t("incident.address")} text={formatAddressTmp(incidentDetail.address)} />
                        )}
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <LabelTextItem
                            label={t("incident.state")}
                            text={
                                <Typography color="orange">
                                    {incidentDetail.incidentState === IncidentDtoIncidentStateEnum.Opened ? t("incident.open") : t("incident.closed")}
                                </Typography>
                            }
                        />
                    </GridItem>
                </GridLayout>
            </InfoPanel>
        </Box>
    );
};
