import PropTypes from "prop-types";

import React from "react";

import { Typography, Icon } from "ui-library/atoms";
import { Flexbox, Box } from "ui-library/layouts";
import { StyledInfoBox } from "ui-library/Styled";

export const InfoBox = ({ children, actionButton, title, icon, iconText, ...props }) => {
    return (
        <StyledInfoBox padding={true} shadow={true} variant="clean">
            <Flexbox direction={"column"} sx={{ gap: "16px" }} mb={2}>
                <Box mt={0.25} mr={2}>
                    {icon ? (
                        <Icon color={"blue"} size={24} icon={icon} />
                    ) : (
                        <Typography color={"blue"} variant="h3">
                            {iconText}
                        </Typography>
                    )}
                </Box>
                <Box>
                    <Typography variant={"h4"}>{title}</Typography>
                </Box>
            </Flexbox>

            {children}

            {actionButton}
        </StyledInfoBox>
    );
};

InfoBox.propTypes = {
    actionButton: PropTypes.node,
    title: PropTypes.node,
    icon: PropTypes.string,
    iconText: PropTypes.string,
};

InfoBox.defaultProps = {};
