import React, { FC, PropsWithChildren } from "react";
import { useSelector } from "react-redux";

import { hasSubmitSucceeded } from "core/form";
import { FullPageErrorBoundary } from "core/errorHandling";

import { FullPageFormHeader } from "./FullPageFormHeader";
import { FullPageSuccess } from "./FullPageSuccess";
import { FullPageEditFormError } from "./FullPageEditFormError";

export interface FullPageFormLayoutProps extends PropsWithChildren {
    formName: string;
    formTitle: string;
    formLead?: string;
    BackButton?: any;
    SuccessComponent?: any;
}

export const FullPageFormLayout: FC<FullPageFormLayoutProps> = ({
    children,
    formName,
    formTitle,
    formLead,
    BackButton,
    SuccessComponent = FullPageSuccess,
}) => {
    const isFormSubmitted = useSelector(hasSubmitSucceeded(formName));

    return (
        <>
            <FullPageFormHeader title={formTitle} lead={formLead} />
            <FullPageErrorBoundary service={formName} ErrorComponent={FullPageEditFormError} BackButton={BackButton}>
                {isFormSubmitted ? <SuccessComponent BackButton={BackButton} /> : children}
            </FullPageErrorBoundary>
        </>
    );
};
