import { createSelector } from "reselect";

import { getFormFieldValue } from "core/form";

import { FUND_PERCENTAGE_BREAKDOWN } from "./constants";

export const getAvailableFundsOptionsForRow = (formName, availableFundsProfileChangeOptionsSelector, index) =>
    createSelector(
        availableFundsProfileChangeOptionsSelector,
        getFormFieldValue(formName, FUND_PERCENTAGE_BREAKDOWN),
        (availableFundsProfileChangeOptions, formFundsValues) => {
            const rowReactKey = formFundsValues.get(index).get("reactKey");
            const alreadySelectedFundsIsinExceptCurrent = formFundsValues
                .filter((row) => row.get("reactKey") !== rowReactKey)
                .map((values) => values.get("isin"));

            return availableFundsProfileChangeOptions.filter(
                (option) => !alreadySelectedFundsIsinExceptCurrent.includes(option.get("value")),
            );
        },
    );
