import { withTheme } from "@mui/styles";
import styled from "styled-components";

export const StyledFooter = withTheme(styled("footer")`
    width: 100%;
    margin-bottom: 60px;

    .footer-content {
        padding-top: 24px;
        border-top: 1px solid ${(props) => props.theme.palette.gray.line};

        //TODO for dinosaurs 2.0
        button {
            @media (max-width: 359px) {
                padding: 0 16px;
            }
            @media (max-width: 340px) {
                padding: 0 16px;
            }
        }
    }
`);
