import { createSaga } from "./createSaga";
import { createContainer } from "./createContainer";

/**
 * Saga and Container creator for Claim Note form
 */
const claimNoteForm = {
    createSaga,
    createContainer,
};
export default claimNoteForm;
