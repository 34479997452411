import { createSagaDss } from "./createSagaDss";
import { createSagaDds } from "./createSagaDds";
import { createContainerDss } from "./createContainerDss";
import { createContainerDds } from "./createContainerDds";

/**
 * Saga and Container creator for Contract name form
 */
const contractNameForm = {
    createSagaDss,
    createSagaDds,
    createContainerDss,
    createContainerDds,
};
export default contractNameForm;
