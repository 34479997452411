import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { Box, ContentBox } from "ui-library/layouts";

import i18n from "core/i18n";
import localized from "core/localized";
import { DatePicker, Field, InputText, maxLengthCharacters, minValue, numberCharacters, required, SingleSelect } from "core/form";
import { date, fn } from "core/util";

import { REDEEM_PAYMENT_FREQUENCY_FIELD, REDEEM_PERIOD_IN_MONTHS_FIELD, REDEEM_START_FROM_FIELD } from "../../constants";
import { getRedeemPeriodInMonthsValue } from "../../selectors";

export const PensionPpRegularPaymentSection = ({ formName, selectedRedeemTypeParameters }) => {
    const { t } = i18n.useTranslation();

    const redeemPeriodInMonths = useSelector(getRedeemPeriodInMonthsValue(formName));
    const listOfApplicableFrequencies = date.getListOfApplicableFrequenciesByModulo(redeemPeriodInMonths);

    const frequencyOptions = useSelector(localized.getLocalizedFrequencyOptions);
    const filteredFrequencyOptions = fn.filterOptions(frequencyOptions, listOfApplicableFrequencies);

    const minDate = Date.parse(selectedRedeemTypeParameters?.claimDateFrom) || date.today;
    const maxDate = Date.parse(selectedRedeemTypeParameters?.claimDateTo);

    return (
        <Box mt={4}>
            <ContentBox>
                <Field
                    name={REDEEM_START_FROM_FIELD}
                    label={t("form.pensionRedeemSavings.redeemStartFrom")}
                    component={DatePicker}
                    validateStatic={[required]}
                    minDate={minDate}
                    maxDate={maxDate}
                    inputFormat={"MM/yyyy"}
                    mask="__/____"
                    views={date.getMonthYearViewOptions(minDate, maxDate)}
                    md={4}
                />
            </ContentBox>
            <ContentBox>
                <Field
                    name={REDEEM_PERIOD_IN_MONTHS_FIELD}
                    label={t("form.pensionRedeemSavings.redeemPeriodInMonths", { months: 12 })}
                    component={InputText}
                    normalize={[numberCharacters, maxLengthCharacters(4)]}
                    validateStatic={[required, minValue(12)]}
                    md={6}
                />
            </ContentBox>
            <ContentBox fullWidth>
                <Field
                    name={REDEEM_PAYMENT_FREQUENCY_FIELD}
                    component={SingleSelect}
                    label={t("form.pensionRedeemSavings.redeemPaymentFrequency")}
                    options={filteredFrequencyOptions}
                    validateStatic={[required]}
                    placeholder={t("common.choose")}
                />
            </ContentBox>
        </Box>
    );
};

PensionPpRegularPaymentSection.propTypes = {
    formName: PropTypes.string.isRequired,
    selectedRedeemTypeParameters: PropTypes.object.isRequired,
};
