import { createSelector } from "reselect";
import { List } from "immutable";

import entity from "core/entity";
import i18n from "core/i18n";
import { fn } from "core/util";
import { Change, ChangeGroup, RequestStatus } from "enums";

import { ENTITY_REQUESTS } from "./constants";

const getStatusEnumData = (statusId, statuses) => statuses.find((item) => item.get("id") === statusId);

const getRequests = (state) => entity.getDataSelector(state, ENTITY_REQUESTS, new List());

export const getTranslatedEnumChanges = i18n.createGetLocalizedEnumMap(Change);
export const getTranslatedEnumChangeGroups = i18n.createGetLocalizedEnumMap(ChangeGroup);
export const getTranslatedEnumStatuses = i18n.createGetLocalizedEnum(RequestStatus, "msg");

export const createGetTranslatedRequests = createSelector(
    getRequests,
    getTranslatedEnumChangeGroups,
    getTranslatedEnumChanges,
    getTranslatedEnumStatuses,
    (requests, groupMessages, changeMessages, statusMessages) => {
        const translateFormatTypes = createTranslateFormatTypes(groupMessages, changeMessages);
        return requests.map((item) => {
            const statusRecord = getStatusEnumData(item.getIn(["status", "id"]), statusMessages);
            return {
                reactKey: item.reactKey,
                id: item.id,
                changeTypes: translateFormatTypes(item.changeTypes),
                contractNumber: item.contractNumber,
                status: statusRecord.msg,
                date: item.date,
                author: item.author,
            };
        });
    },
);

/**
 * formats [A, B, C] as a "G1: A,B, G2: C"
 */
const createTranslateFormatTypes =
    (groupMessages, changeMessages) =>
    (value = List()) => {
        const separator = ", ";
        return value
            .map((type) => fn.getEnumItemById(Change, type))
            .filter(Boolean) // filter undefined/unknown types
            .groupBy((type) => type.group)
            .map((types, groupId) => {
                if (groupId) {
                    return `${groupMessages[groupId]} ${types.map((type) => changeMessages[type.id]).join(separator)}`;
                }
                return types.map((type) => changeMessages[type.id]).join(separator);
            })
            .join(separator);
    };
