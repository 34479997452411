import React, { FC } from "react";

import { StandaloneLink } from "ui-library/atoms";
import { Box, GridItem } from "ui-library/layouts";
import { InfoPanel, LabelTextItem } from "ui-library/modules";

import { dateTimeToLocalFullDate } from "core/formatters";
import i18n from "core/i18n";

import { ContractPolicy } from "types";

export interface ContractOverviewGridItemProps {
    policy: ContractPolicy;
    detailLink: string;
}

export const ContractOverviewGridItem: FC<ContractOverviewGridItemProps> = ({ policy, detailLink }) => {
    const { t } = i18n.useTranslation();
    return (
        <GridItem display="flex" md={7} xs={12}>
            <InfoPanel
                icon="contract"
                title={t("common.contract")}
                headerAction={
                    <StandaloneLink to={detailLink} color="blue">
                        {t("common.detail")}
                    </StandaloneLink>
                }
            >
                <Box>
                    <LabelTextItem label={t("common.begin")} text={dateTimeToLocalFullDate(policy?.beginDate)} />
                    {policy?.endDate && <LabelTextItem label={t("common.end")} text={dateTimeToLocalFullDate(policy.endDate)} />}
                    <LabelTextItem label={t("common.contractNumber")} text={policy?.contractNumber} />
                </Box>
            </InfoPanel>
        </GridItem>
    );
};
