import { withTheme } from "@mui/styles";
import styled from "styled-components";

import { Box } from "ui-library/layouts/Box/Box";

export const StyledShowMore = withTheme(styled(Box)``);

export const StyledShowMoreContainer = withTheme(styled(Box)`
    overflow: hidden;
    transition: max-height 1s;
    .more-content {
        max-height: ${(props) => (props.$more ? "auto" : "0px")};
    }
`);
