import { Block } from "ui-library/atoms";
import useBreakpoints from "ui-library/theme/breakpoints";

export const FormBlock = ({ children, ...props }) => {
    const isMDBreak = useBreakpoints("md");

    return (
        <>
            {isMDBreak ? (
                <Block sx={{ padding: "56px 88px" }} padding={false} variant="white" shadow={false} {...props}>
                    {children}
                </Block>
            ) : (
                <Block mx={-2} sx={{ padding: "24px 16px 0 16px" }} shadow={false} padding={false}>
                    {children}
                </Block>
            )}
        </>
    );
};

FormBlock.propTypes = {};

FormBlock.defaultProps = {};
