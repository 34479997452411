import React from "react";
import { useSelector } from "react-redux";
import { List } from "immutable";

import { ContentBox } from "ui-library/layouts";
import { LabelTextItem } from "ui-library/modules";

import i18n from "core/i18n";
import { getFormFieldValue } from "core/form";
import { localAmountNoDecimal, localFullDate } from "core/formatters";
import { fn } from "core/util";
import { Frequency, RedeemPensionDpsRedeemTypes, RedeemPensionPpRedeemTypes } from "enums";

import {
    EXTRAORDINARY_PAYMENT_AMOUNT_VIRTUAL_FIELD,
    REDEEM_EXTRAORDINARY_PAYMENT_PERCENT_FIELD,
    REDEEM_PAYMENT_FREQUENCY_FIELD,
    REDEEM_PERIOD_IN_MONTHS_FIELD,
    REDEEM_START_FROM_FIELD,
} from "../../constants";

import { PaymentLabelTextItem } from "./PaymentLabelTextItem";

const MIN_WIDTH_PX = "170px";

const lifetimeRedeems = List.of(
    RedeemPensionPpRedeemTypes.PP_RETIREMENT_PENSION_LIFETIME_PENSION,
    RedeemPensionPpRedeemTypes.PP_SERVICE_PENSION_LIFETIME_PENSION,
);

export const SectionPension = ({ formName, pensionType, redeemType, paymentAmount }) => {
    const { t } = i18n.useTranslation();
    const isLifetimeRedeem = lifetimeRedeems.includes(redeemType);
    const isPreRetirement = redeemType === RedeemPensionDpsRedeemTypes.DPS_PRE_RETIREMENT;

    const extraordinaryPaymentAmountValue = useSelector(getFormFieldValue(formName, EXTRAORDINARY_PAYMENT_AMOUNT_VIRTUAL_FIELD));
    const extraordinaryPaymentAmount = `${localAmountNoDecimal(extraordinaryPaymentAmountValue)} Kč`;

    const extraordinaryPaymentPercentage =
        isLifetimeRedeem && useSelector(getFormFieldValue(formName, REDEEM_EXTRAORDINARY_PAYMENT_PERCENT_FIELD));

    const redeemStartFrom = useSelector(getFormFieldValue(formName, REDEEM_START_FROM_FIELD));

    const redeemTypePostFix = isLifetimeRedeem ? t("pension.redeemSavings.lifetime") : t("pension.redeemSavings.regular");
    const redeemTypeText = isPreRetirement ? t(pensionType?.msg) : `${t(pensionType?.msg)} ${redeemTypePostFix}`;

    const months = useSelector(getFormFieldValue(formName, REDEEM_PERIOD_IN_MONTHS_FIELD));
    const frequencyValue = useSelector(getFormFieldValue(formName, REDEEM_PAYMENT_FREQUENCY_FIELD));
    const frequencyObj = fn.getEnumItemById(Frequency, frequencyValue);
    const frequency = t(frequencyObj?.msg)?.toLowerCase();
    const frequencyText = isLifetimeRedeem ? frequency : t("form.pensionRedeemSavings.recapitulation.frequencyText", { frequency, months });

    return (
        <ContentBox mt={4}>
            {/* Pension redeem type */}
            <LabelTextItem minWidthLabel minWidthPx={MIN_WIDTH_PX} label={t("common.pensionRedeemType")} text={redeemTypeText} />

            {/* Redeem start from */}
            <LabelTextItem
                minWidthLabel
                minWidthPx={MIN_WIDTH_PX}
                label={t("common.redeemStartFrom")}
                text={localFullDate(redeemStartFrom)}
            />

            {/* Payment frequency*/}
            <LabelTextItem minWidthLabel minWidthPx={MIN_WIDTH_PX} label={t("payments.redeemFrequency")} text={frequencyText} />

            {/* Payment type */}
            <PaymentLabelTextItem minWidthPx={MIN_WIDTH_PX} formName={formName} />

            {/* Extraordinary payment */}
            {extraordinaryPaymentPercentage && (
                <LabelTextItem
                    minWidthLabel
                    minWidthPx={MIN_WIDTH_PX}
                    label={t("form.pensionRedeemSavings.recapitulation.extraordinaryPayment")}
                    text={`${extraordinaryPaymentPercentage}% (${extraordinaryPaymentAmount})`}
                />
            )}

            {/* Payment amount */}
            <LabelTextItem
                minWidthLabel
                minWidthPx={MIN_WIDTH_PX}
                label={t("form.pensionRedeemSavings.recapitulation.redeemAmount")}
                text={paymentAmount}
            />
        </ContentBox>
    );
};
