import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { ModalFormLayout } from "containers/modal";
import { Field, form, getFormFieldValue, InputText, max20Characters, phoneNumberCharacters, SegmentedControl } from "core/form";
import i18n from "core/i18n";
import { ModalBody, ModalFooter } from "ui-library/modules";
import { Box, ContentBox } from "ui-library/layouts";
import { Button, Loading } from "ui-library/atoms";

import { FIELD_ENABLED, FIELD_PHONE } from "./constants";

const TaxOptimizationsForm = ({ canSubmit, handleSubmit, submitting, taxOptimizationEnabled }) => {
    const { t } = i18n.useTranslation();
    return (
        <>
            <ModalBody>
                <Loading loading={submitting}>
                    <Box>
                        <ContentBox>
                            <Field name={FIELD_ENABLED} component={SegmentedControl} label={t("form.taxOptimization")} />
                        </ContentBox>
                    </Box>
                    {taxOptimizationEnabled && (
                        <Box>
                            <ContentBox>
                                <Field
                                    name={FIELD_PHONE}
                                    component={InputText}
                                    label={t("holder.phone")}
                                    // validated async in saga!
                                    normalize={[phoneNumberCharacters, max20Characters]}
                                    onEnter={handleSubmit}
                                />
                            </ContentBox>
                        </Box>
                    )}
                </Loading>
            </ModalBody>
            <ModalFooter>
                <Button color="blue" variant="contained" onClick={handleSubmit} disabled={!canSubmit}>
                    {t("common.submitChange")}
                </Button>
            </ModalFooter>
        </>
    );
};

TaxOptimizationsForm.propTypes = {
    canSubmit: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    taxOptimizationEnabled: PropTypes.bool,
};

TaxOptimizationsForm.defaultProps = {
    taxOptimizationEnabled: false,
};

export const createContainer = (formName) =>
    form(formName, { asyncValidationFields: [FIELD_PHONE] })((props) => {
        const { t } = i18n.useTranslation();
        const taxOptimizationEnabled = useSelector(getFormFieldValue(formName, FIELD_ENABLED));
        return (
            <ModalFormLayout formName={formName} formTitle={t("form.taxOptimization")} formIcon="circle-dollar">
                <TaxOptimizationsForm taxOptimizationEnabled={taxOptimizationEnabled} {...props} />
            </ModalFormLayout>
        );
    });
