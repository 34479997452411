import { Record } from "immutable";

class ClaimCategory extends Record({ id: null }) {}

export default Object.freeze({
    PROG: new ClaimCategory({
        id: "PROG",
    }),
    WAIT: new ClaimCategory({
        id: "WAIT",
    }),
    VER: new ClaimCategory({
        id: "VER",
    }),
    FIN: new ClaimCategory({
        id: "FIN",
    }),
});
