import React, { FC } from "react";

import { Typography } from "ui-library/atoms";
import { ContentBox, GridItem, GridLayout } from "ui-library/layouts";

import {
    Field,
    InputText,
    lettersNumbersBasicInterpunctionCharacters,
    max10Characters,
    max50Characters,
    maxValue100,
    minTwoLetters,
    numberCharacters,
    required,
} from "core/form";
import i18n from "core/i18n";

import { BENEFICIARIES_FIELD } from "../constants";

type BeneficiaryPersonProps = {
    index: number;
};

export const BeneficiaryPerson: FC<BeneficiaryPersonProps> = ({ index }) => {
    const { t } = i18n.useTranslation();

    return (
        <>
            <ContentBox>
                <Typography variant="h3">{t("common.principalData")}</Typography>
            </ContentBox>
            <ContentBox>
                <GridLayout spacing={2}>
                    <GridItem xs={12} md={6}>
                        <Field
                            name={`${BENEFICIARIES_FIELD}[${index}].firstName`}
                            component={InputText}
                            label={t("holder.firstName")}
                            validateStatic={[required, minTwoLetters]}
                            normalize={[lettersNumbersBasicInterpunctionCharacters, max50Characters]}
                        />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <Field
                            name={`${BENEFICIARIES_FIELD}[${index}].lastName`}
                            component={InputText}
                            label={t("holder.lastName")}
                            validateStatic={[required, minTwoLetters]}
                            normalize={[lettersNumbersBasicInterpunctionCharacters, max50Characters]}
                        />
                    </GridItem>
                </GridLayout>
            </ContentBox>
            <ContentBox>
                <GridLayout spacing={2}>
                    <GridItem xs={12} md={8}>
                        <Field
                            name={`${BENEFICIARIES_FIELD}[${index}].birthNumber`}
                            component={InputText}
                            label={t("holder.birthNumber")}
                            validateStatic={[required]}
                            normalize={[numberCharacters, max10Characters]}
                        />
                    </GridItem>
                    <GridItem xs={12} md={4}>
                        <Field
                            name={`${BENEFICIARIES_FIELD}[${index}].share`}
                            component={InputText}
                            label={t("pension.beneficiaryShare")}
                            validateStatic={[required]}
                            normalize={[numberCharacters, maxValue100]}
                        />
                    </GridItem>
                </GridLayout>
            </ContentBox>
        </>
    );
};
