import { Record } from "immutable";

class UserVerificationStatus extends Record({ id: null, title: null, text: null, icon: null, color: null }) {}

export default Object.freeze({
    UNVERIFIED: new UserVerificationStatus({
        id: "UNVERIFIED",
        title: "holder.unverified",
        text: "userSettings.verifyUser.unverified",
        color: "orange",
        icon: "alert",
    }),
    VERIFICATION_IN_PROGRESS: new UserVerificationStatus({
        id: "VERIFICATION_IN_PROGRESS",
        title: "holder.verificationInProgress",
        text: "userSettings.verifyUser.verificationInProgress",
        color: "orange",
        icon: "alert",
    }),
    VERIFIED: new UserVerificationStatus({
        id: "VERIFIED",
        title: "holder.verified",
        text: "userSettings.verifyUser.verified",
        color: "success",
        icon: "check",
    }),
});
