import { Container } from "ui-library/atoms";
import { GridLayout, LeadOffsetLayout } from "ui-library/layouts";

import i18n from "core/i18n";

import { LeadPanelItem } from "./LeadPanelItem";

export const LeadPanels = () => {
    const { t } = i18n.useTranslation();
    return (
        <Container>
            <LeadOffsetLayout>
                <GridLayout alignItems="center" spacing={4}>
                    <LeadPanelItem
                        icon={"document"}
                        title={t("page.login.panels.documents.title")}
                        text={t("page.login.panels.documents.content")}
                    />
                    <LeadPanelItem
                        icon={"user"}
                        title={t("page.login.panels.onlineDataUpdate.title")}
                        text={t("page.login.panels.onlineDataUpdate.content")}
                    />
                    <LeadPanelItem
                        icon={"eye"}
                        title={t("page.login.panels.investments.title")}
                        text={t("page.login.panels.investments.content")}
                    />
                    <LeadPanelItem
                        icon={"credit-card"}
                        title={t("page.login.panels.payment.title")}
                        text={t("page.login.panels.payment.content")}
                    />
                </GridLayout>
            </LeadOffsetLayout>
        </Container>
    );
};
