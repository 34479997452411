import React from "react";
import PropTypes from "prop-types";

import i18n from "core/i18n";
import { Field, InputText, lettersNumbersBasicInterpunctionCharacters, max50Characters, minTwoLetters, required } from "core/form";
import { ContentBox } from "ui-library/layouts";

import { COMPANY_NAME_FIELD } from "../constants";

const CompanyNameField = ({ sectionName, disabled }) => {
    const { t } = i18n.useTranslation();

    return (
        <>
            <ContentBox>
                <Field
                    name={sectionName ? `${sectionName}.${COMPANY_NAME_FIELD}` : COMPANY_NAME_FIELD}
                    component={InputText}
                    label={t("holder.companyName")}
                    validateStatic={[required, minTwoLetters]}
                    normalize={[lettersNumbersBasicInterpunctionCharacters, max50Characters]}
                    disabled={disabled}
                />
            </ContentBox>
        </>
    );
};

CompanyNameField.propTypes = {
    sectionName: PropTypes.string,
    disabled: PropTypes.bool,
};

CompanyNameField.defaultProps = {
    disabled: false,
    sectionName: null,
};

export default CompanyNameField;
