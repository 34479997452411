import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialState, NAME, CmsContentState } from "./constants";
import { RssItem } from "types";

export const setRssItems: CaseReducer<CmsContentState, PayloadAction<RssItem[]>> = (state, { payload }) => {
    state.rss = payload;
};

export const slice = createSlice({
    name: NAME,
    initialState,
    reducers: {
        setRssItems,
    },
});

export default slice;
