// these constants are coming from the back-end, therefore you can not change them nor create them
export const YEARLY_STATEMENT = "YEARLY_STATEMENT";
export const EXTRA_STATEMENT = "EXTRA_STATEMENT";
export const TAX_STATEMENT = "TAX_STATEMENT";
export const ACTUAL_STATEMENT = "ACTUAL_STATEMENT";

// Special case yearly statement only for last year
export const YEARLY_STATEMENT_ONLY_LAST_YEAR = "YEARLY_STATEMENT_ONLY_LAST_YEAR";

export const SUBMIT_OK = "SUBMIT_OK";
export const SUBMIT_ERROR = "SUBMIT_ERROR";
