import { model } from "core/util";
import { MutualFundsAccountPaymentDetail as MutualFundsAccountPaymentDetailType, MutualFundsAccountPaymentDetailDto } from "types";

import { Amount, CodeValue } from "../common";

export const MutualFundsAccountPaymentDetail = model.createModel<MutualFundsAccountPaymentDetailDto, MutualFundsAccountPaymentDetailType>(
    (json) => ({
        fund: CodeValue.fromServer(json.Fund),
        iban: json.Iban,
        accountNumberPrefix: json.AccountNumberPrefix,
        accountNumber: json.AccountNumber,
        bankNumber: CodeValue.fromServer(json.BankNumber),
        bicCode: CodeValue.fromServer(json.BICCode),
        variableSymbol: json.VariableSymbol,
        specificSymbol: json.SpecificSymbol,
        transactionName: json.TransactionName,
        currencyCode: json.CurrencyCode,
        actualPricePerShare: Amount.fromServer(json.ActualPricePerShare),
    }),
);
