import React, { FC, SVGAttributes } from "react";
import { IconsPath } from "./IconsPath";
import { StyledPath, StyledSVG } from "ui-library/Styled";

export interface IconProps extends SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
    icon: string;
}

export const Icon: FC<IconProps> = ({ color = "black", size = 12, icon, ...props }) => {
    const iconPath = IconsPath(icon);
    return (
        <StyledSVG {...props} viewBox={iconPath.viewBox} width={`${size}px`} height={`${size}px`} xmlns="http://www.w3.org/2000/svg">
            <StyledPath color={color} d={iconPath.d} />
        </StyledSVG>
    );
};
