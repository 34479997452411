import { Block } from "ui-library/atoms";
import { Box, GridItem, GridLayout } from "ui-library/layouts";
import PropTypes from "prop-types";
import React from "react";
import { StyledHeaderAction } from "ui-library/Styled";
import { AgentInfo } from "../AgentInfo/AgentInfo";

export const AgentInfoBlock = ({ title, avatarImg, actionButton, headerAction, children }) => {
    return (
        <Block variant={"white"} position="relative">
            <GridLayout>
                <StyledHeaderAction alignItems={"flex-end"} md={12} xs={12} item>
                    <Box className={"agent-header-action"}>{headerAction}</Box>
                </StyledHeaderAction>
                <GridItem justifyContent="center" md={5} xs={12}>
                    <AgentInfo title={title} image={avatarImg} actionButton={actionButton}></AgentInfo>
                </GridItem>
                <GridItem md={7} xs={12} mt={6}>
                    {children}
                </GridItem>
            </GridLayout>
        </Block>
    );
};

AgentInfo.propTypes = {
    title: PropTypes.string.isRequired,
    avatarImg: PropTypes.string,
    actionButton: PropTypes.node,
    headerAction: PropTypes.node,
    children: PropTypes.node,
};
