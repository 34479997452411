import React from "react";

import { ContentBox } from "ui-library/layouts";

import { Field, InputText, lettersNumbersBasicInterpunctionCharacters, max50Characters, maxLengthCharacters } from "core/form";
import i18n from "core/i18n";

export default () => {
    const { t } = i18n.useTranslation();
    return (
        <>
            <ContentBox fullWidth>
                <Field
                    name="account.variableSymbol"
                    label={t("payments.variableSymbol")}
                    component={InputText}
                    normalize={maxLengthCharacters(10)}
                    md={6}
                />
            </ContentBox>
            <ContentBox fullWidth>
                <Field
                    name="account.specificSymbol"
                    label={t("payments.specificSymbol")}
                    component={InputText}
                    normalize={maxLengthCharacters(10)}
                    md={6}
                />
            </ContentBox>
            <ContentBox fullWidth>
                <Field
                    name="note"
                    label={t("investments.transferName")}
                    component={InputText}
                    normalize={[lettersNumbersBasicInterpunctionCharacters, max50Characters]}
                    md={6}
                />
            </ContentBox>
        </>
    );
};
