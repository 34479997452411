import { fetch } from "core/util";
import { API_PREFIX } from "app/constants";
import { UseCaseChangeDataResponse } from "model/useCase";

const getUseCaseChangeData = () => fetch.doGet(`${API_PREFIX}/use-case/change`, null, UseCaseChangeDataResponse.fromServer);
const getUseCaseClaimData = () => fetch.doGet(`${API_PREFIX}/use-case/claim`, null, UseCaseChangeDataResponse.fromServer);

export default {
    getUseCaseChangeData,
    getUseCaseClaimData,
};
