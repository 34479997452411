import { model } from "core/util";
import { AmountLegacy } from "model/common";

import { UniqaLifeInvestmentFund } from "./UniqaLifeInvestmentFund";

export const UniqaLifeFinancialStatement = model.createModelLegacy("UniqaLifeFinancialStatement", (json) => ({
    statementDate: json.StatementDate,
    investmentFundUnitSum: json.InvestmentFundUnitSum,
    investmentFundValueSum: AmountLegacy.fromServer(json.InvestmentFundValueSum),
    investmentFunds: UniqaLifeInvestmentFund.fromServerList(json.InvestmentFunds),
    investmentTypes: UniqaLifeInvestmentFund.fromServerList(json.InvestmentTypes),
}));
