import { Record, Map } from "immutable";
import { Countries } from "./pageContext";

class MutualFundProducts extends Record({ id: null, codes: Map(), link: null }) {}

export default Object.freeze({
    TEMPO: new MutualFundProducts({
        id: "TEMPO",
        codes: Map({
            [Countries.CZ.id]: "TEMPO_CZ",
            [Countries.SK.id]: "TEMPO_SK",
        }),
        link: "link.mutualFund.TEMPO",
    }),
    GENX: new MutualFundProducts({
        id: "GENX",
        codes: Map({
            [Countries.CZ.id]: "GEN_X_CZ",
            [Countries.SK.id]: "GEN_X_SK",
        }),
        link: "link.mutualFund.GENX",
    }),
    PROGRESS: new MutualFundProducts({
        id: "PROGRESS",
        codes: Map({
            [Countries.CZ.id]: "",
            [Countries.SK.id]: "PROGRESS_SK",
        }),
        link: "link.mutualFund.PROGRESS",
    }),
    GEN_NEXT: new MutualFundProducts({
        id: "GEN_NEXT",
        codes: Map({
            [Countries.CZ.id]: "GEN_NEXT_CZ",
            [Countries.SK.id]: "",
        }),
        link: "link.mutualFund.GEN_NEXT",
    }),
    LIFE_CYCLE: new MutualFundProducts({
        id: "LIFE_CYCLE",
        codes: Map({
            [Countries.CZ.id]: "LIFE_CYCLE_CZ",
            [Countries.SK.id]: "",
        }),
        link: "link.mutualFund.LIFE_CYCLE",
    }),
    DIP: new MutualFundProducts({
        id: "DIP",
        codes: Map({
            [Countries.CZ.id]: "DIP_CZ",
            [Countries.SK.id]: "",
        }),
        link: "link.mutualFund.DIP",
    }),
});
