import React from "react";
import { Container, StandaloneLink, Typography, Button } from "ui-library/atoms";
import { Box, Flexbox } from "ui-library/layouts";
import { StyledFooter } from "ui-library/Styled";

export const Footer = ({ ...props }) => {
    return (
        <StyledFooter {...props}>
            <Container>
                <Flexbox
                    className={"footer-content"}
                    flexDirection={{ xs: "column", md: "row" }}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <Flexbox mb={{ xs: 3, md: 0 }} justifyContent={"space-between"} width={{ xs: "100%", md: "auto" }}>
                        <Box display={"inline"} mr={{ xs: 0, md: 4 }}>
                            <Typography variant="p">© UNIQA 2022</Typography>
                        </Box>

                        <StandaloneLink color="gray" to="/podmienky">
                            Podmienky používania
                        </StandaloneLink>
                    </Flexbox>
                    <Box>
                        <Flexbox sx={{ gap: "8px" }}>
                            <Button color="blue" variant="outlined" startIcon="social-facebook" />
                            <Button color="blue" variant="outlined" startIcon="social-twitter" />
                            <Button color="blue" variant="outlined" startIcon="social-youtube" />
                        </Flexbox>
                    </Box>
                </Flexbox>
            </Container>
        </StyledFooter>
    );
};

Footer.propTypes = {};

Footer.defaultProps = {};
