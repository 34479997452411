import { createActions } from "./createActions";
import { createContainer } from "./createContainer";
import { createReducer } from "./createReducer";
import { createSaga } from "./createSaga";
import { createSelectors } from "./createSelectors";

export { amountOperators, amountWithOperationTypeOperators, codeValueOperators } from "./operators";

export const tableFilter = ({ actionPrefix, reducerPath, AdvancedFilter, BasicFilter, defaultOperators }) => {
    const config = {
        name: `${actionPrefix}/filter-form`,
        AdvancedFilter,
        BasicFilter,
        defaultOperators,
    };

    const actions = createActions(actionPrefix);
    const Container = createContainer(actions, config);
    const reducer = createReducer(actions);
    const saga = createSaga(actions, config);
    const selectors = createSelectors(reducerPath, config);

    return {
        Container,
        reducer,
        saga: saga.tableFilterSaga,
        getFilterModel: selectors.getFilterModel,
    };
};
