import { model } from "core/util";

import { AmountLegacy, CodeValueLegacy } from "../common";

export const MutualFundsInvestmentsProfit = model.createModelLegacy("MutualFundsInvestmentsProfit", (json) => ({
    date: json.Date,
    fund: CodeValueLegacy.fromServer(json.Fund),
    numberOfShares: json.NumberOfShares,
    purchasePrice: AmountLegacy.fromServer(json.PurchasePrice),
    actualPrice: AmountLegacy.fromServer(json.ActualPrice),
    profit: json.Profit,
    nettoProfit: json.NettoProfit,
    nettoPurchasePrice: AmountLegacy.fromServer(json.NettoPurchasePrice),
    purchasePricePerShare: AmountLegacy.fromServer(json.PurchasePricePerShare),
    actualPricePerShare: AmountLegacy.fromServer(json.ActualPricePerShare),
}));
