import React from "react";
import { useSelector } from "react-redux";

import fetching from "core/fetching";
import i18n from "core/i18n";
import { Box, StaticPageLayout } from "ui-library/layouts";
import { Container, Loading, Typography } from "ui-library/atoms";

import { NAME, STEP_DISCLAIMER_TEXT } from "./constants";
import { Consent, Declined } from "./containers";
import { getDisclaimerStep } from "./selectors";

const DisclaimerPage = () => {
    const { t } = i18n.useTranslation();
    const disclaimerStep = useSelector(getDisclaimerStep);
    const isLoading = useSelector(fetching.isFetching(NAME));

    return (
        <StaticPageLayout>
            <Loading fullPage loading={isLoading} />
            <Container maxWidth="md">
                <Box pb={{ xs: 4, md: 8 }}>
                    <Typography component="h1" variant="h1">
                        {t("page.disclaimer.header")}
                    </Typography>
                </Box>

                {disclaimerStep === STEP_DISCLAIMER_TEXT ? <Consent /> : <Declined />}
            </Container>
        </StaticPageLayout>
    );
};

export default DisclaimerPage;
