import React, { FC } from "react";

import { Box, ContentBox } from "ui-library/layouts";

import { BeneficiaryPerson } from "./BeneficiaryPerson";

type BeneficiaryPpProps = {
    index: number;
};

export const BeneficiaryPp: FC<BeneficiaryPpProps> = ({ index }) => (
    <Box>
        <ContentBox>
            <BeneficiaryPerson index={index} />
        </ContentBox>
    </Box>
);
