import React from "react";
import { useSelector } from "react-redux";

import { PopoverTooltip } from "ui-library/atoms";
import { DataTable, InfoPanel } from "ui-library/modules";
import { Flexbox, GridItem } from "ui-library/layouts";

import i18n from "core/i18n";
import { formatLifeBeneficiaryName, formatPercentage, oneLineNameWithTitles } from "core/formatters";

import { getBeneficiariesData } from "../selectors";

const columns = (t) => [
    {
        field: "insuredName",
        headerName: t("person.insured"),
        flex: 2,
        renderCell: (params) => oneLineNameWithTitles(params.value),
    },
    {
        field: "beneficiaryName",
        headerName: t("person.beneficiary"),
        flex: 2,
        renderCell: (params) => formatLifeBeneficiaryName(params.value),
    },
    {
        field: "percentage",
        headerName: t("common.percent"),
        flex: 0.5,
        align: "right",
        headerAlign: "right",
        renderCell: (params) => formatPercentage(params.value),
    },
];

export const BeneficiariesGridItem = () => {
    const { t } = i18n.useTranslation();
    const beneficiaries = useSelector(getBeneficiariesData).toArray();

    return (
        <GridItem display={"flex"} xs={12}>
            <InfoPanel
                icon={"users"}
                title={
                    <Flexbox gap={1}>
                        {t("person.authorizedPersons")}
                        <PopoverTooltip title={t("common.note")} content={t("life.beneficiaries.note")} />
                    </Flexbox>
                }
            >
                <DataTable
                    withWrapper={false}
                    getRowId={(row) => row.reactKey}
                    hideFooter={true}
                    rows={beneficiaries}
                    columns={columns(t)}
                />
            </InfoPanel>
        </GridItem>
    );
};
