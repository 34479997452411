import React from "react";
import PropTypes from "prop-types";

import { Link, Typography } from "ui-library/atoms";
import { fn, model } from "core/util";

import { formatPercentage } from "./numberFormatter";

/**
 *  Create hyperlink used in table formatters
 *  profit - contains profit.profit text value to show on portal, fundIsin value for onClick function and boolean value profit.sumRow to inform if is sum row
 *  onProfitDetail - contains onclick function
 */
export const profitHyperLinkFormatter = (profit, getProfitUrl) => {
    if (!profit || fn.isEmpty(profit.get("profit"))) {
        return "";
    }
    if (profit.get("sumRow")) {
        return <Typography>{formatPercentage(profit.get("profit"))}</Typography>;
    } else if (!profit.get("fundIsin")) {
        return <Link to={getProfitUrl(model.OPTION_ALL)}>{formatPercentage(profit.get("profit"))}</Link>;
    } else {
        return <Link to={getProfitUrl(profit.get("fundIsin"))}>{formatPercentage(profit.get("profit"))}</Link>;
    }
};

profitHyperLinkFormatter.propTypes = {
    profit: PropTypes.shape({
        text: PropTypes.string,
        fundIsin: PropTypes.string,
        sumRow: PropTypes.bool,
    }).isRequired,
    onProfitDetail: PropTypes.func.isRequired,
};
