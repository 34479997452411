import React from "react";
import { useDispatch, useSelector } from "react-redux";

import i18n from "core/i18n";
import { Select, Typography } from "ui-library/atoms";
import { Box, ContentBox, GridItem } from "ui-library/layouts";
import { InfoPanel } from "ui-library/modules";

import { getClaimsForSelect, getSelectedClaimId } from "../selectors";
import { setSelectedClaimId } from "app/pages/detailIncident/actions";

export const ClaimSelect = () => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();
    const selectOptions = useSelector(getClaimsForSelect(t));
    const selectedClaim = useSelector(getSelectedClaimId);

    return (
        <GridItem xs={12}>
            <ContentBox bigger>
                <Typography variant="h2">{t("claim.claims")}</Typography>
            </ContentBox>

            <InfoPanel icon="contract" title="Nárok">
                <Box>
                    <Select
                        id="claimID"
                        value={selectedClaim}
                        onChange={(event) => dispatch(setSelectedClaimId(event.target.value))}
                        placeholder="Vyberte nárok"
                        options={selectOptions}
                        fullWidth
                    />
                </Box>
            </InfoPanel>
        </GridItem>
    );
};
