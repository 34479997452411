import { model } from "core/util";

import { Holder } from "model/holder";
import { ElectronicCommunication } from "model/electronicCommunication";
import { MutualFundsRegularContractDetail as MutualFundsRegularContractDetailType, MutualFundsRegularContractDetailDto } from "types";

import { MutualFundsContractParameters } from "../MutualFundsContractParameters";
import { MutualFundsRegularAccountDetail } from "./MutualFundsRegularAccountDetail";
import { MutualFundsClientStatementSettings } from "../MutualFundsClientStatementSettings";

export const MutualFundsRegularContractDetail = model.createModel<
    MutualFundsRegularContractDetailDto,
    MutualFundsRegularContractDetailType
>((json) => ({
    contractParameters: MutualFundsContractParameters.fromServer(json.ContractParameters),
    holder: Holder.fromServer(json.Holder),
    accountDetail: MutualFundsRegularAccountDetail.fromServer(json.MutualFundsAccountDetail),
    electronicCommunication: ElectronicCommunication.fromServer(json.ElectronicCommunication),
    clientStatementSettings: MutualFundsClientStatementSettings.fromServer(json.ClientStatementSettings),
    shouldCheckIbanCountry: json.ShouldCheckIbanCountry,
}));
