import fundsSellSwitchForm from "form/mutualFundsSellSwitch";

import {getFundOptions, getIdObject, getSellScopeOptions, getShouldCheckIbanCountry, isProductGenerationNext} from "../selectors";
import { OtpModule } from "../modules";
import { FORM_SELL_SWITCH } from "../constants";
import { getLocalizedFundSellMeansRegular } from "core/localized";

export const FundsSellSwitchForm = fundsSellSwitchForm.createContainerRegular(
    FORM_SELL_SWITCH,
    OtpModule,
    getFundOptions,
    getLocalizedFundSellMeansRegular,
    isProductGenerationNext,
    getSellScopeOptions,
    getIdObject,
    getShouldCheckIbanCountry,
    true,
);
