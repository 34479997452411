import { model } from "core/util";

export const AddressLegacy = model.createModelLegacy("Address", (json) => ({
    addressTypeCode: json.AddressTypeCode,
    street: json.Street,
    orientationNumber: json.OrientationNumber,
    descriptionNumber: json.DescriptionNumber,
    city: json.City,
    postalCode: json.PostalCode,
    countryCode: json.CountryCode,
}));
