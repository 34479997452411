import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { Option, PaymentAmountTypeOption, Select } from "ui-library/atoms";

import router from "core/router";
import { createGetFundsOptionsForInvestment } from "core/util/selector";
import { Pages, Params, Tabs } from "routeConstants";
import i18n from "core/i18n";

import { getContractId, getFundId, getFunds } from "../selectors";

export const PaymentFundSelect = () => {
    const { t } = i18n.useTranslation();
    const selectedContractId = useSelector(getContractId);

    const funds = useSelector(getFunds);
    const filteredFunds = useSelector(createGetFundsOptionsForInvestment(funds, "code"));
    const filteredFundOptions = useSelector(i18n.createGetLocalizedCodeOptions(filteredFunds));

    const fundId = useSelector(getFundId);
    const dispatch = useDispatch();

    // TODO fix args order after rebase
    const doTransition = (contractId, fundId) =>
        router.navigate(
            Pages.ONLINE_PAYMENT,
            Tabs.PAYMENTS_PAY,
            {},
            {
                [Params.PAYMENT_CONTRACT_ID]: contractId,
                [Params.PAYMENT_FUND_ID]: fundId,
            },
            true,
        );

    const fundIdChange = (fundId) => doTransition(selectedContractId, fundId);

    return (
        filteredFundOptions &&
        !filteredFundOptions.isEmpty() && (
            <Select
                value={fundId || ""}
                onChange={(event) => dispatch(fundIdChange(event.target.value))}
                placeholder={t("payments.fundPlaceholder")}
                label=""
                id="selectFund"
                fullWidth
                sx={{ marginTop: { xs: "16px" } }}
            >
                {filteredFundOptions.map((option) => (
                    <Option key={option.get("value")} value={option.get("value")}>
                        <PaymentAmountTypeOption label={option.get("label")} />
                    </Option>
                ))}
            </Select>
        )
    );
};
