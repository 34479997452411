import React from "react";
import PropTypes from "prop-types";

import { Typography } from "ui-library/atoms";
import { ContentBox, Section } from "ui-library/layouts";

import i18n from "core/i18n";

import { PercentageSelectDds } from "../components";
import { CHANGE_STRATEGY_DATA } from "../constants";

const DdsStrategySection = ({ formName }) => {
    const { t } = i18n.useTranslation();

    return (
        <Section>
            <ContentBox>
                <Typography variant="h3">{`2. ${t("pension.fundsChange.changeInvestmentStrategy.title")}`}</Typography>
            </ContentBox>

            <ContentBox>
                <Typography component="p" markDown={t("pension.fundsChange.changeInvestmentStrategy.text")} />
            </ContentBox>

            <ContentBox>
                <Typography fontWeight={600} component="p">
                    {t("pension.fundsChange.changeInvestmentStrategy.choose")}
                </Typography>
            </ContentBox>

            <PercentageSelectDds formName={formName} sectionName={CHANGE_STRATEGY_DATA} />
        </Section>
    );
};

DdsStrategySection.propTypes = {
    formName: PropTypes.string.isRequired,
};

export default DdsStrategySection;
