import slice from "./slice";
import * as selectors from "./selectors";
import { impersonateSaga } from "./saga";

export default {
    NAME: slice.name,
    reducer: slice.reducer,
    ...selectors,

    impersonateSaga,
};
