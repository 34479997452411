import { FC } from "react";

import { Box } from "ui-library/layouts";
import { Typography } from "ui-library/atoms";

import { dateTimeToLocalFullDate } from "core/formatters";
import i18n from "core/i18n";
import userContracts from "core/userContracts";

import { ContractPolicy } from "types";
import ContractOverview from "./ContractOverview";

export interface DesktopTabletContractCardProps {
    contract: ContractPolicy;
}

export const DesktopTabletContractCard: FC<DesktopTabletContractCardProps> = ({ contract }) => {
    const { t } = i18n.useTranslation();
    const { showEndDate } = userContracts.useGetContractDerivedData(contract);
    const contractNumberWithDatesInfo = t("contractList.text.contractWithNumberSignedOn", {
        contractNumber: contract.contractNumber,
        beginDate: dateTimeToLocalFullDate(contract.beginDate),
    });
    const endDate = `${t("common.endDate")}: ${dateTimeToLocalFullDate(contract.endDate)}`;

    return (
        <>
            <Box>
                <Typography variant="p">{contractNumberWithDatesInfo}</Typography>
            </Box>
            {showEndDate ? (
                <Box>
                    <Typography variant="p">{endDate}</Typography>
                </Box>
            ) : (
                <Box>
                    <Typography variant="p">
                        <ContractOverview contract={contract} />
                    </Typography>
                </Box>
            )}
        </>
    );
};
