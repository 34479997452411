import { fromJS } from "immutable";

import { TableFilterOperator } from "enums";

import { DATE_COLUMN, PAYMENT_COLUMN, TYPE_COLUMN } from "../constants";

export const financialOperationsDefaultOperators = fromJS({
    type: { column: TYPE_COLUMN, operator: TableFilterOperator.CONTAINS },
    dateFrom: { column: DATE_COLUMN, operator: TableFilterOperator.AFTER },
    dateTo: { column: DATE_COLUMN, operator: TableFilterOperator.BEFORE },
    payment: { column: PAYMENT_COLUMN, operator: "" },
});
