import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import i18n from "core/i18n";
import localized from "core/localized";
import { Field, getFormFieldValue, required, SingleSelect } from "core/form";
import { SubjectType } from "enums";
import { Box, ContentBox } from "ui-library/layouts";

import { BeneficiaryPerson } from "./BeneficiaryPerson";
import { BeneficiaryAddress } from "./BeneficiaryAddress";
import { BeneficiaryCompany } from "./BeneficiaryCompany";

import { BENEFICIARIES_FIELD } from "../constants";

export const BeneficiaryDdsDss = ({ formName, index }) => {
    const { t } = i18n.useTranslation();
    const subjectOptions = useSelector(localized.getLocalizedSubjectType);
    const subjectType = useSelector(getFormFieldValue(formName, `${BENEFICIARIES_FIELD}[${index}].subjectType`));

    return (
        <Box>
            <ContentBox>
                <Field
                    name={`${BENEFICIARIES_FIELD}[${index}].subjectType`}
                    component={SingleSelect}
                    options={subjectOptions}
                    label={t("common.subjectType")}
                    validateStatic={[required]}
                />
            </ContentBox>
            <ContentBox>
                {subjectType === SubjectType.INDIVIDUAL.id ? <BeneficiaryPerson index={index} /> : <BeneficiaryCompany index={index} />}
            </ContentBox>
            <ContentBox>
                <BeneficiaryAddress index={index} />
            </ContentBox>
        </Box>
    );
};

BeneficiaryDdsDss.propTypes = {
    formName: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
};
