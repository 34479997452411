import { List, Map } from "immutable";
import { createSelector } from "reselect";

export const createSelectors = (reducerPath, config) => {
    const { defaultOperators } = config;

    const getModel = (state) => state.getIn(reducerPath);
    const getFilter = (state) => getModel(state).get("filter");

    const getFilterModel = createSelector(getFilter, (filter) => {
        const model = filter.reduce(
            (acc, value, key) =>
                acc.push(
                    Map()
                        .set("id", key)
                        .set("columnField", defaultOperators.getIn([key, "column"]))
                        .set("operatorValue", filter.getIn([key, "operator"], defaultOperators.getIn([key, "operator"])))
                        .set("value", filter.getIn([key, "value"], Map.isMap(value) ? "" : value)),
                ),
            List(),
        );

        return model.toJS();
    });

    return {
        getFilterModel,
    };
};
