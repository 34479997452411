import React from "react";
import PropTypes from "prop-types";

import i18n from "core/i18n";
import {
    Field,
    InputText,
    lettersDotSpaceCharacters,
    lettersNumbersBasicInterpunctionCharacters,
    max20Characters,
    max50Characters,
    minTwoLetters,
    required,
} from "core/form";
import { ContentBox } from "ui-library/layouts";

import { PERSON_SECTION } from "../constants";

const IndividualPersonFields = ({ sectionName, disabled }) => {
    const { t } = i18n.useTranslation();

    return (
        <>
            <ContentBox>
                <Field
                    name={sectionName ? `${sectionName}.${PERSON_SECTION}.titleBefore` : `${PERSON_SECTION}.titleBefore`}
                    component={InputText}
                    label={t("holder.titleBefore")}
                    validateStatic={[minTwoLetters]}
                    normalize={[lettersDotSpaceCharacters, max20Characters]}
                    disabled={disabled}
                />
            </ContentBox>
            <ContentBox>
                <Field
                    name={sectionName ? `${sectionName}.${PERSON_SECTION}.firstName` : `${PERSON_SECTION}.firstName`}
                    component={InputText}
                    label={t("holder.firstName")}
                    validateStatic={[required, minTwoLetters]}
                    normalize={[lettersNumbersBasicInterpunctionCharacters, max50Characters]}
                    disabled={disabled}
                />
            </ContentBox>
            <ContentBox>
                <Field
                    name={sectionName ? `${sectionName}.${PERSON_SECTION}.lastName` : `${PERSON_SECTION}.lastName`}
                    component={InputText}
                    label={t("holder.lastName")}
                    validateStatic={[required, minTwoLetters]}
                    normalize={[lettersNumbersBasicInterpunctionCharacters, max50Characters]}
                    disabled={disabled}
                    tooltip={t("holder.lastNameTooltip")}
                />
            </ContentBox>
            <ContentBox>
                <Field
                    name={sectionName ? `${sectionName}.${PERSON_SECTION}.titleAfter` : `${PERSON_SECTION}.titleAfter`}
                    component={InputText}
                    label={t("holder.titleAfter")}
                    validateStatic={[minTwoLetters]}
                    normalize={[lettersDotSpaceCharacters, max20Characters]}
                    disabled={disabled}
                />
            </ContentBox>
        </>
    );
};

IndividualPersonFields.propTypes = {
    sectionName: PropTypes.string,
    disabled: PropTypes.bool,
};

IndividualPersonFields.defaultProps = {
    sectionName: null,
    disabled: false,
};

export default IndividualPersonFields;
