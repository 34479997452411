import React from "react";
import PropTypes from "prop-types";
import { List } from "immutable";

import { ContentBox } from "ui-library/layouts";
import { Typography } from "ui-library/atoms";

import i18n from "core/i18n";
import { RedeemPensionDpsPensionTypes, RedeemPensionPpPensionTypes } from "enums";

const excludedRedeemTypes = List.of(
    RedeemPensionPpPensionTypes.EARLY_PAYMENT,
    RedeemPensionDpsPensionTypes.EARLY_PAYMENT,
    RedeemPensionDpsPensionTypes.PRE_RETIREMENT,
);

const retirementPensions = List.of(RedeemPensionPpPensionTypes.RETIREMENT_PENSION, RedeemPensionDpsPensionTypes.RETIREMENT_PENSION);

const getPensionTypeLead = (t, selectedPensionType) => {
    if (retirementPensions.includes(selectedPensionType)) {
        return t("pension.redeemSavings.redeemOptions.retirementPension");
    }
    if (selectedPensionType === RedeemPensionPpPensionTypes.SERVICE_PENSION) {
        return t("pension.redeemSavings.redeemOptions.servicePension");
    }
    return t("pension.redeemSavings.redeemOptions", { pensionTypeName: t(selectedPensionType?.msg) });
};

export const AllowedRedeemTypesSection = ({ selectedPensionType, allowedRedeemTypes }) => {
    if (excludedRedeemTypes.includes(selectedPensionType)) {
        return null;
    }

    const { t } = i18n.useTranslation();
    return (
        <>
            <ContentBox>
                <Typography markDown={getPensionTypeLead(t, selectedPensionType)} />
            </ContentBox>

            <ContentBox>
                {allowedRedeemTypes.map((redeemType) => (
                    <Typography key={redeemType.id} markDown={t(redeemType.description)} />
                ))}
            </ContentBox>
        </>
    );
};

AllowedRedeemTypesSection.propTypes = {
    allowedRedeemTypes: PropTypes.object.isRequired,
};
