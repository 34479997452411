import { model } from "core/util";

import { AmountLegacy } from "../common";
import { LifeInvestmentFund } from "./LifeInvestmentFund";
import { LifeInvestmentType } from "./LifeInvestmentType";

export const LifeFinancialStatement = model.createModelLegacy("LifeFinancialStatement", (json) => ({
    dateTime: json.DateTime,
    investmentFundUnitSum: json.InvestmentFundUnitSum,
    investmentFundValueSum: AmountLegacy.fromServer(json.InvestmentFundValueSum),
    investmentFunds: LifeInvestmentFund.fromServerList(json.InvestmentFunds),
    investmentTypes: LifeInvestmentType.fromServerList(json.InvestmentTypes),
}));
