import { FetchingState, NAME } from "./constants";

const getModel = (state): FetchingState => state.get(NAME);

export const isFetching =
    (entity, defaultValue = false) =>
    (state) => {
        const fetching = getModel(state)[entity];
        return fetching === undefined ? defaultValue : fetching;
    };

export const isAnyInMapFetching = (mapEntity: string) => (state) =>
    Object.entries(getModel(state)).filter(([key, value]) => value === true && key.substring(0, key.indexOf("::")) === mapEntity).length >
    0;

export const isFetchingAny = (entities: string[]) => (state) => entities.some((entity) => getModel(state)[entity] === true);
