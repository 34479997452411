import { useSelector } from "react-redux";

import { Countries } from "enums";
import { getAvailableLocales, getFeatureFlagEnabled, getLocale, getSite } from "./selectors";
import { FeatureFlag, Locale } from "types";

type PageLocalizationDerivedData = {
    isSlovakSite: boolean;
    isCzechSite: boolean;
    siteId: string;
    locale: Locale;
    availableLocales: Locale[];
    currencyCode: string;
};

export const useGetPageLocalization: () => PageLocalizationDerivedData = () => {
    const site = useSelector(getSite);
    const siteId = site?.id;
    const currencyCode = site?.currencyCode;
    const locale = useSelector(getLocale);
    const availableLocales = useSelector(getAvailableLocales);

    return {
        isSlovakSite: siteId === Countries.SK.id,
        isCzechSite: siteId === Countries.CZ.id,
        siteId,
        locale,
        availableLocales,
        currencyCode,
    };
};

export const useIsFeatureFlagEnabled: (featureFlag: FeatureFlag) => boolean = (featureFlag) => {
    return useSelector(getFeatureFlagEnabled(featureFlag));
};
