import { model } from "core/util";

import { AmountLegacy } from "../../common";

export const PensionDpsContractBalanceSheet = model.createModelLegacy("PensionDpsContractBalanceSheet", (json) => ({
    holderPaymentsTotal: AmountLegacy.fromServer(json.HolderPaymentsTotal),
    governmentPaymentsTotal: AmountLegacy.fromServer(json.GovernmentPaymentsTotal),
    employerPaymentsTotal: AmountLegacy.fromServer(json.EmployerPaymentsTotal),
    otherPensionContractsPaymentsTotal: AmountLegacy.fromServer(json.OtherPensionContractsPaymentsTotal),
    otherPaymentsTotal: AmountLegacy.fromServer(json.OtherPaymentsTotal),
    axaClubLoyaltyCardTotal: AmountLegacy.fromServer(json.AxaClubLoyaltyCardTotal),
    withdrawalAmountTotal: AmountLegacy.fromServer(json.WithdrawalAmountTotal),
    taxesAndRoundingAmountTotal: AmountLegacy.fromServer(json.TaxesAndRoundingAmountTotal),
    earningsAmountTotal: AmountLegacy.fromServer(json.EarningsAmountTotal),
    paymentsAmountTotal: AmountLegacy.fromServer(json.PaymentsAmountTotal),
    netAmountTotal: AmountLegacy.fromServer(json.NetAmountTotal),
}));
