import { model } from "core/util";
import { HolderLegacy } from "../holder";
import { ElectronicCommunicationLegacy } from "../electronicCommunication";
import { LifeContractParameters } from "../life";
import { AmountLegacy, SimpleCodeValue } from "../common";
import { BalanceOnContract, HarmonizedPaymentsScheduler, InsuredRealEstateProperty, PaymentRequest } from "../harmonized";
import { UniqaLifeAccountDetail } from "../uniqaLife/UniqaLifeAccountDetail";

export const TravelContractDetail = model.createModelLegacy("TravelContractDetail", (json) => ({
    category: SimpleCodeValue.fromServer(json.Category),
    identifier: json.Identifier,
    holder: HolderLegacy.fromServer(json.Holder),
    electronicCommunication: ElectronicCommunicationLegacy.fromServer(json.ElectronicCommunication),
    contractParameters: LifeContractParameters.fromServer(json.ContractParameters),
    insuranceCostSummary: AmountLegacy.fromServer(json.InsuranceCostSummary),
    actualPaymentsScheduler: HarmonizedPaymentsScheduler.fromServerList(json.ActualPaymentsScheduler),
    advancePaymentsScheduler: HarmonizedPaymentsScheduler.fromServerList(json.AdvancePaymentsScheduler),
    paymentsScheduler: HarmonizedPaymentsScheduler.fromServerList(json.PaymentsScheduler),
    paymentRequests: PaymentRequest.fromServerList(json.PaymentRequests),
    balanceOnContract: BalanceOnContract.fromServer(json.BalanceOnContract),
    lifeAccountDetail: UniqaLifeAccountDetail.fromServer(json.LifeAccountDetail),
    insuredRealEstateProperties: InsuredRealEstateProperty.fromServerList(json.InsuredRealEstateProperties),
}));
