import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { StandaloneLink, Typography } from "ui-library/atoms";
import { ContentBox, GridItem } from "ui-library/layouts";
import { InfoPanel, LabelTextItem } from "ui-library/modules";

import i18n from "core/i18n";
import router from "core/router";
import { useHasEditContractPermission } from "core/hooks";
import { EditLinksDropdown } from "containers/EditLinksDropdown";
import { formatValueOrEmptyString } from "core/formatters";
import { Pages, Tabs } from "routeConstants";

import { FORM_EDIT_ACTIVE_OPERATIONS_PHONE_NUMBER } from "../constants";
import { getActiveOperationsPhoneNumber, isActiveOperationsEnabled } from "../selectors";

const editLinks = [
    {
        formConst: FORM_EDIT_ACTIVE_OPERATIONS_PHONE_NUMBER,
        name: "form.activeOperationsPhoneNumber",
    },
];

export const ActiveOperationsGridItem: FC = () => {
    const { t } = i18n.useTranslation();
    const { idObject } = useParams();
    const activeOperationsRequestEnabled = useSelector(isActiveOperationsEnabled);
    const phoneNumber = useSelector(getActiveOperationsPhoneNumber);
    const hasEditPermission = useHasEditContractPermission();

    return (
        <GridItem xs={12} md={6} display="flex">
            <InfoPanel
                bgVariant="white"
                icon="file-circle-info"
                title={t("investments.rulesOverviewSellSwitch")}
                headerAction={hasEditPermission && activeOperationsRequestEnabled && <EditLinksDropdown links={editLinks} />}
            >
                {activeOperationsRequestEnabled ? (
                    <>
                        <ContentBox>
                            <Typography variant="p">
                                {`${t("investments.activeOperationsDocumentsLabel")} `}
                                <StandaloneLink to={router.getStaticUrl(Pages.CONTRACT_REGULAR, Tabs.DOCUMENTS, { idObject })} color="blue">
                                    {t("common.documents")}
                                </StandaloneLink>
                                .
                            </Typography>
                        </ContentBox>
                        <LabelTextItem label={t("investments.activeOperationsPhoneNumber")} text={formatValueOrEmptyString(phoneNumber)} />
                    </>
                ) : (
                    <Typography variant="caption" markDown={t("investments.mutualFundsRegular.activeOperationsText")} />
                )}
            </InfoPanel>
        </GridItem>
    );
};
