import React from "react";
import { useSelector } from "react-redux";

import { Field, SingleSelect } from "core/form";
import i18n from "core/i18n";

import { getFinancialOperationsFundNames } from "../selectors";

export const FinancialOperationsBasicFilter = () => {
    const { t } = i18n.useTranslation();
    const fundNameOptions = useSelector(getFinancialOperationsFundNames);

    return (
        <Field
            name="investmentFundName"
            component={SingleSelect}
            options={fundNameOptions}
            placeholder={t("pension.financialOperation.selectFund")}
        />
    );
};
