import React, { FC, ReactNode } from "react";
import { TooltipProps as MaterialTooltipProps } from "@mui/material";
import i18n from "core/i18n";
import { StyledTooltip } from "ui-library/Styled";
import { Icon, Popover } from "ui-library/atoms";

export interface TooltipProps extends MaterialTooltipProps {
    variant?: string;
}

export const Tooltip: FC<TooltipProps> = ({ title, placement = "top", variant = "info", ...props }) => {
    return (
        <StyledTooltip placement={placement} title={title} {...props}>
            <span>
                <Icon className="tooltip" size={18} icon={variant === "info" ? "circle-info" : "circle-question"} color="sky" />
            </span>
        </StyledTooltip>
    );
};

export interface PopoverTooltipProps extends Omit<MaterialTooltipProps, "children"> {
    size?: number;
    title: string;
    variant?: string;
    content?: ReactNode;
}

export const PopoverTooltip: FC<PopoverTooltipProps> = ({ title = "", content, variant = "info", size = 18, ...props }) => {
    const [popover, setPopover] = React.useState(null);
    const { t } = i18n.useTranslation();

    return (
        <>
            <StyledTooltip onClick={(event) => setPopover(event.currentTarget)} disableHoverListener={true} title={title} {...props}>
                <span>
                    <Icon className="tooltip" size={size} icon={variant === "info" ? "circle-info" : "circle-question"} color="sky" />
                </span>
            </StyledTooltip>
            <Popover
                id={"popover-" + 1}
                anchorEl={popover}
                onClose={() => setPopover(null)}
                open={Boolean(popover)}
                position="right"
                title={title}
                content={content}
                buttonLabel={t("common.close")}
            />
        </>
    );
};
