export const NAME = "user-settings";

// Panel and page names.
export const USER_SETTINGS = "userSettings";
export const USER_PERSONAL_DATA = "personalData";
export const USER_CONSENT = "userConsent";

// Entities.
export const ENTITY_CONTRACTS_GDPR_DATA = `${NAME}/ENTITY_CONTRACTS_GDPR_DATA`;

// Forms.
export const USERNAME_FORM = `${NAME}/USERNAME_FORM`;
export const USER_PASSWORD_FORM = `${NAME}/USER_PASSWORD_FORM`;
export const USER_VERIFICATION_FORM = `${NAME}/USER_VERIFICATION_FORM`;
export const USER_GDPR_CONSENT_FORM = `${NAME}/USER_GDPR_CONSENT_FORM`;

export const PAGE_STATE = {
    INITIAL: "INITIAL",
    ACCEPTING: "ACCEPTING",
    REVOKING: "REVOKING",
};
