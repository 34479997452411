import { reducer } from "redux-form/immutable";
import { combineReducers } from "redux-immutable";
import { Set } from "immutable";

import { ADD_OTP_FORM_SUCCESS, RESET_TOP_FORM_SUCCESS } from "./actions";

const otpFormSuccess = (state = Set(), { type, payload }) => {
    switch (type) {
        case ADD_OTP_FORM_SUCCESS:
            return state.add(payload.formName);
        case RESET_TOP_FORM_SUCCESS:
            return state.subtract(payload.formName);
        default:
            return state;
    }
};

export default combineReducers({
    form: reducer,
    otpFormSuccess,
});
