import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { arrayRemove, unregisterField } from "redux-form/immutable";
import { fromJS, List, Map } from "immutable";
import { nanoid, Selector } from "@reduxjs/toolkit";

import userInfo from "core/userInfo";
import { FORM_STEPS_BACK } from "app/constants";
import { formWrapper, getFormFieldValue, scrollToFirstError } from "core/form";
import { sentry } from "core/util";
import { pensionPpApi } from "serverApi";
import errorHandling from "core/errorHandling";
import { PensionBeneficiary, State } from "types";

import { REMOVE_BENEFICIARY } from "./actions";
import { BENEFICIARIES_FIELD } from "./constants";
import { pensionBeneficiaryShareFormatter } from "./util";

const parseBeneficiary = (beneficiary) =>
    Map({
        reactKey: nanoid(),
        firstName: beneficiary.getIn(["beneficiaryEntity", "firstName"]),
        lastName: beneficiary.getIn(["beneficiaryEntity", "lastName"]),
        birthNumber: beneficiary.getIn(["beneficiaryEntity", "birthNumber"]),
        share: pensionBeneficiaryShareFormatter(beneficiary.get("beneficiaryShare")),
    });

export function* createSagaPp(formName: string, getBeneficiaries: Selector<State, PensionBeneficiary[]>, idObject: number) {
    try {
        yield call(formSaga(formName, getBeneficiaries), idObject);
    } catch (e) {
        sentry.captureException(e);
        yield put(errorHandling.addServiceError(formName, e.identifier));
    }
}

const formSaga = (formName: string, getBeneficiaries: Selector<State, PensionBeneficiary[]>) =>
    formWrapper(formName, {
        *persistentEffects() {
            // @ts-ignore we won't type sagas
            yield takeEvery(REMOVE_BENEFICIARY, removeBeneficiarySaga, formName);
        },
        *initialize(idObject) {
            yield call(userInfo.checkUserVerified, FORM_STEPS_BACK);
            yield put(errorHandling.removeServiceErrors(formName));

            const beneficiaries: PensionBeneficiary[] = yield select(getBeneficiaries);
            if (beneficiaries) {
                const beneficiariesForInit = beneficiaries.length === 0 ? List().push(Map()) : fromJS(beneficiaries);
                return {
                    idObject,
                    beneficiaries: beneficiariesForInit.map(parseBeneficiary),
                };
            }
            return {};
        },
        *save(values) {
            const data = values.update("beneficiaries", (list) => list.map((beneficiary) => beneficiary.delete("reactKey")));
            yield call(pensionPpApi.updateBeneficiaries, data);
        },
        // @ts-ignore we won't type sagas
        *onSubmitFail() {
            yield call(scrollToFirstError);
        },
    });

function* removeBeneficiarySaga(formName, { meta }) {
    const beneficiaries = yield select(getFormFieldValue(formName, BENEFICIARIES_FIELD));
    const lastIndex = beneficiaries.count() - 1;

    yield put(arrayRemove(formName, BENEFICIARIES_FIELD, meta.index));
    yield all([
        yield put(unregisterField(formName, `${BENEFICIARIES_FIELD}[${lastIndex}].firstName`)),
        yield put(unregisterField(formName, `${BENEFICIARIES_FIELD}[${lastIndex}].lastName`)),
        yield put(unregisterField(formName, `${BENEFICIARIES_FIELD}[${lastIndex}].birthNumber`)),
        yield put(unregisterField(formName, `${BENEFICIARIES_FIELD}[${lastIndex}].share`)),
    ]);
}
