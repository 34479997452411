import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import i18n from "core/i18n";
import { Button } from "ui-library/atoms";
import { GridItem } from "ui-library/layouts";

export const SubmitButton = ({ submitAction }) => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();

    return (
        <GridItem lg={3} md={6} xs={12}>
            <Button variant="contained" color="blue" onClick={() => dispatch(submitAction())}>
                {t("common.statementRequest")}
            </Button>
        </GridItem>
    );
};

SubmitButton.propTypes = {
    submitAction: PropTypes.func.isRequired,
};
