import React, { FC } from "react";
import { useSelector } from "react-redux";

import { Typography } from "ui-library/atoms";
import { ContentBox, GridItem } from "ui-library/layouts";
import { DataTable, InfoPanel } from "ui-library/modules";

import i18n from "core/i18n";
import { formatCodeValueFundLink, formatPercentageNoDecimal, localAmountInvestmentWithCurrencyEur } from "core/formatters";
import { DssFunds, PensionInvestmentStrategy } from "enums";

import { getFundsInvestmentStrategies, getFundsInvestmentVoluntaryStrategies, getPensionAccountDetail } from "../selectors";

const columns = (t, languageCode) => [
    {
        field: "investmentFund",
        headerName: t("common.fund"),
        flex: 1.5,
        sortable: false,
        renderCell: (params) => formatCodeValueFundLink(t, languageCode, DssFunds, params.value),
    },
    {
        field: "pricePerShare",
        headerName: t("pension.actualPricePerShare"),
        flex: 2,
        sortable: false,
        align: "right",
        headerAlign: "right",
        valueGetter: (params) => localAmountInvestmentWithCurrencyEur(params.value),
    },
    {
        field: "investmentPercentage",
        headerName: t("pension.investmentStrategies.percentage"),
        flex: 1,
        sortable: false,
        align: "right",
        headerAlign: "right",
        valueGetter: (params) => formatPercentageNoDecimal(params.value),
    },
];

export const PensionInvestmentStrategiesGridItem: FC = () => {
    const { t } = i18n.useTranslation();
    const languageCode = i18n.getLanguageCode();

    const pensionAccountDetail = useSelector(getPensionAccountDetail);
    const isCombinedStrategy = pensionAccountDetail?.investmentStrategy === PensionInvestmentStrategy.COMBINED.id;

    const fundsInvestmentStrategies = useSelector(getFundsInvestmentStrategies);
    const fundsInvestmentVoluntaryStrategies = useSelector(getFundsInvestmentVoluntaryStrategies);

    return (
        <GridItem xs={12}>
            <InfoPanel icon="chart-column" title={t("pension.investmentStrategies.title")}>
                {isCombinedStrategy ? (
                    <ContentBox bigger>
                        <DataTable
                            disableColumnReorder
                            withWrapper={false}
                            hideFooter={true}
                            getRowId={(row) => row.reactKey}
                            columns={columns(t, languageCode)}
                            rows={fundsInvestmentStrategies}
                        />
                    </ContentBox>
                ) : (
                    <>
                        <ContentBox mt={4}>
                            <Typography variant="h4">{t("pension.compulsory")}</Typography>
                        </ContentBox>

                        <ContentBox bigger>
                            <DataTable
                                disableColumnReorder
                                withWrapper={false}
                                hideFooter={true}
                                getRowId={(row) => row.reactKey}
                                columns={columns(t, languageCode)}
                                rows={fundsInvestmentStrategies}
                            />
                        </ContentBox>

                        <ContentBox mt={4}>
                            <Typography variant="h4">{t("pension.voluntary")}</Typography>
                        </ContentBox>

                        <ContentBox bigger>
                            <DataTable
                                disableColumnReorder
                                withWrapper={false}
                                hideFooter={true}
                                getRowId={(row) => row.reactKey}
                                columns={columns(t, languageCode)}
                                rows={fundsInvestmentVoluntaryStrategies}
                            />
                        </ContentBox>
                    </>
                )}
            </InfoPanel>
        </GridItem>
    );
};
