import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import i18n from "core/i18n";
import modal from "core/modal";
import { Dropdown, DropdownItem } from "ui-library/modules";
import { StandaloneLink } from "ui-library/atoms";

export interface EditLinksDropdownLink {
    name: string;
    formConst?: string;
    link?: string;
}

export interface EditLinksDropdownProps {
    links: EditLinksDropdownLink[];
}

export const EditLinksDropdown: FC<EditLinksDropdownProps> = ({ links }) => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const isDropdownOpen = Boolean(anchorEl);

    const openDropdown = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const closeDropdown = () => setAnchorEl(null);

    const openModalForm = (formName: string) => {
        closeDropdown();
        dispatch(modal.openModalForm(formName));
    };

    return (
        <>
            <StandaloneLink color="blue" onClick={openDropdown}>
                {t("common.edit")}
            </StandaloneLink>

            <Dropdown position={"left"} anchorEl={anchorEl} open={isDropdownOpen} onClose={closeDropdown}>
                {links.map((editLink) =>
                    editLink.formConst ? (
                        <DropdownItem key={editLink.name} onClick={() => openModalForm(editLink.formConst)}>
                            {t(editLink.name)}
                        </DropdownItem>
                    ) : (
                        <DropdownItem key={editLink.name} onClick={() => navigate(editLink.link)}>
                            {t(editLink.name)}
                        </DropdownItem>
                    ),
                )}
            </Dropdown>
        </>
    );
};
