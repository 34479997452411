import { Flexbox } from "ui-library/layouts/Flexbox/Flexbox";
import React, { useEffect } from "react";
import { useSwipeable } from "react-swipeable";
import { StyledContractsScrollLayout } from "ui-library/Styled";

export const ContractScrollLayout = ({ children, ...props }) => {
    const [order, setOrder] = React.useState([]);
    const [sliding, setSliding] = React.useState(false);
    const [direction, setDirection] = React.useState("RIGHT");

    useEffect(() => {
        if (order.length === 0) {
            children.props.children.forEach((item, index) => {
                order.push(index + 1);
            });
        }
    });

    const handlers = useSwipeable({
        onSwipedLeft: () => slide("LEFT"),
        onSwipedRight: () => slide("RIGHT"),
        delta: 30,

        preventScrollOnSwipe: true,
        trackMouse: false,
        trackTouch: true,
    });

    const slide = (direction) => {
        setSliding(true);
        setDirection(direction);

        if (direction === "RIGHT") {
            const newOrder = arrayRotate([...order]);
            setOrder(newOrder);
        } else {
            const newOrder = arrayRotate([...order], true);
            setOrder(newOrder);
        }

        setTimeout(() => {
            setSliding(false);
        }, 200);
    };

    function arrayRotate(arr, reverse) {
        if (reverse) arr.unshift(arr.pop());
        else arr.push(arr.shift());
        return arr;
    }
    return (
        <StyledContractsScrollLayout $direction={direction} $sliding={sliding}>
            <div {...handlers} className="scroll">
                {children.props.children.map((item, index) => (
                    <Flexbox order={order[index] ? order[index] : index + 1} className="item" key={index} flex={1} mr={2}>
                        {item}
                    </Flexbox>
                ))}
            </div>
        </StyledContractsScrollLayout>
    );
};
