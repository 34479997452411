import { nanoid } from "@reduxjs/toolkit";

import { model } from "core/util";

import { LegalEntityLegacy } from "../common";
import { PensionBeneficiaryShareLegacy } from "./PensionBeneficiaryShareLegacy";

export const PensionBeneficiaryLegacy = model.createModelLegacy("PensionBeneficiary", (json) => ({
    reactKey: nanoid(),
    beneficiaryShare: PensionBeneficiaryShareLegacy.fromServer(json.BeneficiaryShare),
    beneficiaryEntity: LegalEntityLegacy.fromServer(json.BeneficiaryEntity),
}));
