import React from "react";
import PropTypes from "prop-types";

import { ModalFormLayout } from "containers/modal";
import { acceptedFileSizeValidator, acceptedFileTypesValidator, Field, form, FileUpload } from "core/form";
import i18n from "core/i18n";
import { ModalBody, ModalFooter } from "ui-library/modules";
import { Box } from "ui-library/layouts";
import { Button, Loading } from "ui-library/atoms";

const UploadClaimDocumentsForm = ({ canSubmit, handleSubmit, submitting }) => {
    const { t } = i18n.useTranslation();
    return (
        <>
            <ModalBody>
                <Loading loading={submitting}>
                    <Box>
                        <Field
                            name="files"
                            component={FileUpload}
                            validateStatic={[acceptedFileTypesValidator, acceptedFileSizeValidator]}
                        />
                    </Box>
                </Loading>
            </ModalBody>
            <ModalFooter>
                <Button color="blue" variant="contained" onClick={handleSubmit} disabled={!canSubmit}>
                    {t("common.send")}
                </Button>
            </ModalFooter>
        </>
    );
};

UploadClaimDocumentsForm.propTypes = {
    canSubmit: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
};

export const createContainer = (formName) =>
    form(formName)((props) => {
        const { t } = i18n.useTranslation();
        return (
            <ModalFormLayout formName={formName} formTitle={t("form.uploadClaimDocuments")} formIcon="document">
                <UploadClaimDocumentsForm {...props} />
            </ModalFormLayout>
        );
    });
