import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import i18n from "core/i18n";
import { createGetChangedFieldsList } from "core/form";
import { Button } from "ui-library/atoms";

import { VISIBLE_CHANGED_FIELDS } from "./constants";

export const SubmitButton = ({ formName, canSubmit, handleSubmit }) => {
    const { t } = i18n.useTranslation();

    const getFormChangedFieldsList = createGetChangedFieldsList(VISIBLE_CHANGED_FIELDS, formName);
    const isAnyFieldChanged = useSelector(getFormChangedFieldsList).length > 0;
    const submitDisabled = isAnyFieldChanged ? !canSubmit : true;

    return (
        <Button disabled={submitDisabled} onClick={handleSubmit} variant="contained" color="blue">
            {t("common.send")}
        </Button>
    );
};

SubmitButton.propTypes = {
    formName: PropTypes.string.isRequired,
    canSubmit: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};
