import createModel from "../../createModel";
import { CodeValueLegacy, AmountLegacy } from "../../common";

export const MutualFundsRegularFinancialOperationFund = createModel("MutualFundsRegularFinancialOperationFund", (json) => ({
    shareOfInvestmentInTheFund: json.ShareOfInvestmentInTheFund,
    fund: CodeValueLegacy.fromServer(json.Fund),
    amount: AmountLegacy.fromServer(json.Amount),
    nettoAmount: AmountLegacy.fromServer(json.NettoAmount),
    roundingFee: AmountLegacy.fromServer(json.RoundingFee),
    dateOfInstructionGeneration: json.DateOfInstructionGeneration,
    dateOfInstructionRealization: json.DateOfInstructionRealization,
    numberOfPurchasedShares: json.NumberOfPurchasedShares,
    priceOfOneShare: AmountLegacy.fromServer(json.PriceOfOneShare),
}));
