import { v4 as uuid } from "uuid";
import { model } from "core/util";

import { AmountLegacy, CodeValueLegacy } from "../common";

export const LifeTariff = model.createModelLegacy("LifeTariff", (json) => ({
    reactKey: uuid(),
    isPrimary: json.IsPrimary,
    isOneTimeTariff: json.IsOneTimeTariff,
    insuranceTariff: CodeValueLegacy.fromServer(json.InsuranceTariff),
    insuranceIndexation: json.InsuranceIndexation,
    insuranceAmount: AmountLegacy.fromServer(json.InsuranceAmount),
    insuranceCost: AmountLegacy.fromServer(json.InsuranceCost),
}));
