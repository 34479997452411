import { NAME } from "./constants";
import innerRoutes from "./innerRoutes";
import reducer from "./reducer";
import saga from "./saga";

import { InnerContainer } from "./containers";

export { default as EpinExternalPage } from "./Container";

export const EpinExternalTabs = {
    InnerContainer,
};

export default {
    NAME,
    innerRoutes,
    reducer,
    saga,
};
