import React, { FC } from "react";
import { useParams } from "react-router-dom";

import { ContractDocuments, ContractEnqueueDocumentsGridItem } from "containers/contract";
import { GridLayout } from "ui-library/layouts";

import { ENTITY_CONTRACT_DOCUMENTS, ENTITY_ENQUEUED_DOCUMENTS } from "../constants";

export const DocumentsTab: FC = () => {
    const { idObject } = useParams();
    return (
        <GridLayout defaultSpacing>
            <ContractDocuments idObject={idObject} entityName={ENTITY_CONTRACT_DOCUMENTS} />

            <ContractEnqueueDocumentsGridItem idObject={idObject} entityName={ENTITY_ENQUEUED_DOCUMENTS} />
        </GridLayout>
    );
};
