import PropTypes from "prop-types";
import React, { useRef } from "react";
import { Image, Typography } from "ui-library/atoms";
import { Box } from "ui-library/layouts";
import { StyledRadio, StyledImageLabelRadioGroup, StyledImageLabelRadio } from "ui-library/Styled";

export const ImageLabelRadio = ({ labelTitle, labelSubtitle, labelImgSrc, small, disabled, id, error, ...props }) => {
    const radioRef = useRef("");

    return (
        <StyledImageLabelRadio
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            className={radioRef.current && radioRef.current.checked && !error ? "checked" : "unchecked"}
            control={<StyledRadio disabled={disabled} $error={error} inputRef={radioRef} id={id} {...props} />}
            label={
                <Box
                    id={id}
                    display="flex"
                    flexDirection={{ xs: "row", md: "column" }}
                    justifyContent="space-between"
                    height={"100%"}
                    alignItems="center"
                >
                    <Typography component="span" variant="h4">
                        {labelTitle}
                    </Typography>

                    {labelSubtitle && <Typography component="span" variant="caption" markDown={labelSubtitle} />}

                    <Image className={small && "small"} alt={labelTitle} src={labelImgSrc} shadow={false} $desatured={disabled} />
                </Box>
            }
        />
    );
};

ImageLabelRadio.propTypes = {
    id: PropTypes.string.isRequired,
    labelTitle: PropTypes.string,
    labelSubtitle: PropTypes.string,
    labelImgSrc: PropTypes.string,
    error: PropTypes.bool,
    small: PropTypes.bool,
    disabled: PropTypes.bool,
};

export const ImageLabelRadioGroup = ({ children, ...props }) => (
    <>
        <StyledImageLabelRadioGroup {...props}>{children}</StyledImageLabelRadioGroup>
    </>
);

ImageLabelRadioGroup.defaultProps = {
    labelSubtitle: null,
    small: false,
    disabled: false,
};
