import { all, call, fork, put, select } from "redux-saga/effects";

import fetching from "core/fetching";
import router from "core/router";
import userContracts from "core/userContracts";
import { documentsApi } from "serverApi";

import { ContractListContentType, FETCHING_CLIENT_DOCUMENTS } from "./constants";
import { clearDisplayedContentType, setClientDocuments, setDisplayedContentType } from "./actions";

export default router.routerWrapper({
    *onPageEnter() {
        yield fork(loadClientDocuments);
        yield fork(userContracts.loadContractOverviews);
        yield fork(userContracts.loadFinancialOperationsOverview);
        const activeContracts = yield select(userContracts.getActiveContractIds);
        if (activeContracts && activeContracts.size === 0) {
            yield put(setDisplayedContentType(ContractListContentType.INACTIVE_CONTRACTS));
        }
    },
    clearDataForPage() {
        return all([put(clearDisplayedContentType()), put(setClientDocuments())]);
    },
});

function* loadClientDocuments() {
    yield* fetching.withFetching(FETCHING_CLIENT_DOCUMENTS, function* () {
        try {
            const clientDocuments = yield call(documentsApi.getClientDocuments);
            yield put(setClientDocuments(clientDocuments));
        } catch (e) {
            // TODO error
        }
    });
}
