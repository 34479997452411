import { call, put, select } from "redux-saga/effects";
import { formWrapper } from "core/form";
import errorHandling from "core/errorHandling";
import { sentry } from "core/util";
import userInfo from "core/userInfo";
import { pensionDssApi } from "serverApi";

export function* createSaga(formName, idObject, getDisplayEnableButton, refreshDetailDataCallback, setPisFormChangeResultAction) {
    try {
        yield call(formSaga(formName, getDisplayEnableButton, refreshDetailDataCallback, setPisFormChangeResultAction), idObject);
    } catch (e) {
        sentry.captureException(e);
        yield put(errorHandling.addServiceError(formName, e.identifier));
    }
}

const formSaga = (formName, getDisplayEnableButton, refreshDetailDataCallback, setPisFormChangeResultAction) =>
    formWrapper(formName, {
        *initialize(idObject) {
            yield put(errorHandling.removeServiceErrors(formName));
            yield call(userInfo.checkUserVerified);
            const canEnable = yield select(getDisplayEnableButton);
            return {
                idObject,
                activate: canEnable,
            };
        },
        *save(values) {
            const result = yield call(pensionDssApi.updatePis, values);
            return result;
        },
        *success(result, idObject) {
            yield put(setPisFormChangeResultAction(result));
            yield call(refreshDetailDataCallback, idObject);
        },
    });
