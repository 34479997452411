import { Record } from "immutable";

import FrequencyNonLife from "./FrequencyNonLife";

class Frequency extends Record({ id: null, msg: null }) {}

// Frequency
const MONTHLY = "MONTHLY";
const QUARTERLY = "QUARTERLY";
const HALFYEARLY = "HALFYEARLY";
const YEARLY = "YEARLY";

const map = {
    MONTHLY,
    QUARTERLY,
    // TODO: find out who is sending BIANNUALLY
    BIANNUALLY: HALFYEARLY,
    HALFYEARLY,
    YEARLY,
};

export default Object.freeze({
    MONTHLY: new Frequency({
        id: MONTHLY,
        msg: "common.monthly",
    }),
    ...FrequencyNonLife,
});

export const convertFromPeriodicity = (periodicity = {}) => ({
    Code: map[periodicity.Code],
    Names: periodicity.Names,
});
