import { Set } from "immutable";

import createModel from "../createModel";
import { PaymentsSchedulerLegacy } from "./PaymentsSchedulerLegacy";
import { AmountLegacy } from "./AmountLegacy";

export const ContractOverview = createModel("ContractOverview", (json) => ({
    paymentScheduler: PaymentsSchedulerLegacy.fromServer(json.paymentScheduler),
    investedAmount: AmountLegacy.fromServer(json.investedAmount),
    monthlyPayment: AmountLegacy.fromServer(json.monthlyPayment),
    isElectronicCommunicationEnabled: json.isElectronicCommunicationEnabled,
    lineOfBusinessClasses: Set(json.lineOfBusinessClasses),
    pensionInvestmentStrategy: json.pensionInvestmentStrategy,
    isPassive: json.isPassive,
}));
