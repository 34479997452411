export const PensionPpCategoryEnum = Object.freeze({
    RECEIVED: {
        key: "common.received",
        color: "green",
    },
    PROFIT: {
        key: "common.profit",
        color: "blue",
    },
    TRANSFERS: {
        key: "common.transfers",
        color: "gray",
    },
    OTHER: {
        key: "common.other",
        color: "orange",
    },
    PAID: {
        key: "common.paid",
        color: "red",
    },
});
