import { useSelector } from "react-redux";

import pageContext from "core/pageContext";

export const useGetSiteValue: () => (resource: object) => string = () => (resource) => {
    const site = useSelector(pageContext.getSite);
    const locale = useSelector(pageContext.getLocale);

    return typeof resource[site?.id] === "string" || resource[site?.id] instanceof String
        ? resource[site?.id]
        : resource[site?.id] && resource[site?.id][locale?.id];
};
