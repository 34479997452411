import React, { FC } from "react";

import { Button, ImportantAnnouncements } from "ui-library/atoms";
import { Box } from "ui-library/layouts";
import { ModalBody, ModalFooter } from "ui-library/modules";

import i18n from "core/i18n";
import router from "core/router";
import { Pages, Tabs } from "routeConstants";

export interface ModalSuccessProps {
    closeModal: any;
    title?: string;
    message?: string;
    noText?: boolean;
}

export const ModalSuccess: FC<ModalSuccessProps> = ({ closeModal, title, message, noText }) => {
    const { t } = i18n.useTranslation();
    const link = router.getStaticUrl(Pages.MESSAGES, Tabs.REQUESTS);

    return (
        <>
            <ModalBody>
                <Box>
                    <ImportantAnnouncements
                        variant={"SUCCESS"}
                        data={{
                            heading: title || t("success.title.sent"),
                            content: message ? message : noText ? null : t("success.text", { link: link }),
                        }}
                    />
                </Box>
            </ModalBody>
            <ModalFooter>
                <Button onClick={closeModal} color="blue" variant="contained">
                    {t("common.close")}
                </Button>
            </ModalFooter>
        </>
    );
};
