import { combineReducers as immutableCombineReducers } from "redux-immutable";
import { combineReducers, createReducer } from "@reduxjs/toolkit";

import { MutualFundsDirect } from "model/mutualFunds/direct";
import { MutualFundsDirectContract } from "types";

import { clear, setMutualFundsDirectContractDetail } from "./actions";
import {
    ActualStatements,
    ChangePropositionModule,
    ExtraStatements,
    FinancialOperationsTableFilter,
    OtpModule,
    YearStatements,
} from "./modules";

const mutualFundsDirectContractDetail = createReducer<MutualFundsDirectContract>(MutualFundsDirect.fromServer(), (builder) => {
    builder.addCase(setMutualFundsDirectContractDetail, (_, { payload }) => payload);
    builder.addCase(clear, () => MutualFundsDirect.fromServer());
});

export default immutableCombineReducers({
    data: combineReducers({
        mutualFundsDirectContractDetail,
    }),
    appState: immutableCombineReducers({
        yearStatements: YearStatements.reducer,
        extraStatements: ExtraStatements.reducer,
        actualStatements: ActualStatements.reducer,
        otp: OtpModule.reducer,
        changeProposition: ChangePropositionModule.reducer,
        financialOperationsFilter: FinancialOperationsTableFilter.reducer,
    }),
});
