import { withTheme } from "@mui/styles";
import styled from "styled-components";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Flexbox } from "ui-library/layouts/Flexbox/Flexbox";
import { Box } from "ui-library/layouts/Box/Box";

export const StyledDataTableWrapper = withTheme(styled(Box)`
    padding: ${(props) => (props.$withWrapper ? "24px" : "0px")};
    border: ${(props) => (props.$withWrapper ? "1px solid" : "none")};
    border-color: ${(props) => props.theme.palette.gray.border};
    border-radius: 4px;
    background: ${(props) => props.theme.palette.white.main};
    width: 100%;
`);

const addScrollGradient = (props) => {
    if (props.$hasScrollBar) {
        return `
        content: '';
        width: 30px;
        height: 100%;
        top: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
        `;
    }
};

export const StyledDataTable = withTheme(styled(DataGridPro)`
    & {
        border-radius: 0px;
        border: none;
        background: ${(props) => props.theme.palette.white.main};

        .MuiDataGrid-main {
            @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
                margin-left: -24px;
                width: calc(100% + 48px);
                position: relative;

                &:after {
                    ${(props) => addScrollGradient(props)}
                }
            }

            .MuiDataGrid-columnHeaders {
                border-radius: 0px;

                font-size: 14px;
                line-height: 22px;
                color: ${(props) => props.theme.palette.gray.main};
                .MuiDataGrid-columnHeader {
                    &:focus-within {
                        outline: none;
                    }
                    .MuiDataGrid-columnHeaderTitle {
                        font-weight: 400;
                        white-space: normal;
                        line-height: 18px;
                        overflow: hidden;
                    }
                }
            }

            .MuiDataGrid-row {
                font-size: 16px;
                &:hover {
                    color: ${(props) => props.theme.palette.black.main};
                    background: ${(props) => props.theme.palette.blue.soft};
                }
                .Mui-selected {
                    background: ${(props) => props.theme.palette.blue.soft};
                    &:hover {
                        background: ${(props) => props.theme.palette.blue.soft};
                    }
                }
                .MuiDataGrid-cell {
                    white-space: break-spaces;
                    border-bottom: none;
                    padding: 10px;
                    border-top: 1px solid ${(props) => props.theme.palette.gray.line};
                    &:focus-within {
                        outline: none;
                    }
                    .MuiDataGrid-cellContent {
                        white-space: normal;
                        @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
                            white-space: nowrap;
                        }
                    }
                }
                &--lastVisible {
                    .MuiDataGrid-cell {
                        border-bottom: 1px solid ${(props) => props.theme.palette.gray.line};
                        border-bottom-color: ${(props) => props.theme.palette.gray.line} !important;
                    }
                }
                &:nth-child(1) {
                    .MuiDataGrid-cell {
                        border-top: none;
                    }
                }
            }
        }

        .MuiDataGrid-columnSeparator {
            display: none;
        }

        .MuiDataGrid-footerContainer {
            border-top: none;

            @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
                justify-content: ${(props) => !props.$summaryRow && "center"};
            }
        }

        .MuiDataGrid-detailPanel {
            border-top: 3px solid ${(props) => props.theme.palette.blue.main};
        }
    }

    .error {
        color: ${(props) => props.theme.palette.red.main};
        background: ${(props) => props.theme.palette.red.light};
    }
`);

export const StyledPaging = withTheme(styled(Flexbox)`
    .page-icon {
        width: 36px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }
    .disabled {
        svg {
            path {
                fill: ${(props) => props.theme.palette.gray.line};
            }
        }
        &:hover {
            cursor: unset;
        }
    }
    .able {
        &:hover {
            cursor: pointer;
            background: ${(props) => props.theme.palette.gray.line};
        }
    }
`);
