import { Record } from "immutable";

class MutualFundsStatementFrequency extends Record({ id: null, msg: null }) {}

export default Object.freeze({
    WEEKLY: new MutualFundsStatementFrequency({
        id: "WEEKLY",
        msg: "common.weekly",
    }),
    MONTHLY: new MutualFundsStatementFrequency({
        id: "MONTHLY",
        msg: "common.monthly",
    }),
    QUARTERLY: new MutualFundsStatementFrequency({
        id: "QUARTERLY",
        msg: "common.quarterly",
    }),
    BIANNUALLY: new MutualFundsStatementFrequency({
        id: "BIANNUALLY",
        msg: "common.halfYearly",
    }),
});
