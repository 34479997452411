import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { GridItem } from "ui-library/layouts";
import { DataTable } from "ui-library/modules";

import entity from "core/entity";
import fetching from "core/fetching";
import i18n from "core/i18n";
import router from "core/router";
import { DEFAULT_TABLE_PAGE_SIZE, DEFAULT_TABLE_ROWS_OPTIONS } from "app/constants";
import { localAmountWithCurrencyLegacy, localFullDate, negativeFormatter } from "core/formatters";
import { amountWithOperationTypeOperators } from "modules/tableFilter";
import { Pages, Tabs } from "routeConstants";

import { DATE_COLUMN, ENTITY_FINANCIAL_OPERATIONS, PAYMENT_COLUMN, TYPE_COLUMN } from "../constants";
import { FinancialOperationDetailLinkFormatter } from "../formatters";
import { FinancialOperationsTableFilter } from "../modules";
import { getFinancialOperations } from "../selectors";

import { FinancialOperationPrintButton } from "./FinancialOperationPrintButton";

const columns = (t, idObject, getOperationBuyDetailUrl, getOperationSellDetailUrl) => [
    {
        field: DATE_COLUMN,
        type: "date",
        headerName: t("investments.investmentDate"),
        minWidth: 120,
        renderCell: (params) => localFullDate(params.value),
    },
    {
        field: TYPE_COLUMN,
        headerName: t("common.actionType"),
        minWidth: 50,
        sortable: false,
    },
    {
        field: PAYMENT_COLUMN,
        filterOperators: amountWithOperationTypeOperators,
        headerName: t("common.payment"),
        renderCell: (params) => negativeFormatter(params.value),
        minWidth: 130,
        sortable: false,
        align: "right",
        headerAlign: "right",
    },
    {
        headerName: t("investments.feePayment"),
        field: "feePayment",
        renderCell: (params) => localAmountWithCurrencyLegacy(params.value),
        minWidth: 150,
        sortable: false,
        align: "right",
        headerAlign: "right",
    },
    {
        headerName: t("investments.forInvestmentOrSell"),
        field: "investedAmount",
        renderCell: (params) => negativeFormatter(params.value),
        minWidth: 170,
        sortable: false,
        align: "right",
        headerAlign: "right",
    },
    {
        headerName: t("common.action"),
        field: "detail",
        renderCell: (params) => (
            <FinancialOperationDetailLinkFormatter
                operation={params.value}
                getOperationSellDetailUrl={getOperationSellDetailUrl}
                getOperationBuyDetailUrl={getOperationBuyDetailUrl}
            />
        ),
        minWidth: 50,
        sortable: false,
        align: "right",
        headerAlign: "right",
    },
    {
        headerName: t("common.print"),
        field: "id",
        sortable: false,
        renderCell: (params) => <FinancialOperationPrintButton idObject={idObject} idOperation={params.value} />,
        align: "center",
        headerAlign: "center",
    },
];

export const MovementsOverviewGridItem = () => {
    const { t } = i18n.useTranslation();
    const financialOperations = useSelector(getFinancialOperations);
    const isFinOperationsLoading = useSelector(fetching.isFetching(ENTITY_FINANCIAL_OPERATIONS, true));
    const isFinOperationsError = useSelector(entity.isError(ENTITY_FINANCIAL_OPERATIONS));
    const { idObject } = useParams();
    const filterModel = useSelector(FinancialOperationsTableFilter.getFilterModel);

    const getOperationBuyDetailUrl = (operationId) =>
        router.getStaticUrl(Pages.CONTRACT_REGULAR, Tabs.REGULAR_FINANCIAL_OPERATION_DETAIL_BUY, { idObject, operationId });
    const getOperationSellDetailUrl = (operationId) =>
        router.getStaticUrl(Pages.CONTRACT_REGULAR, Tabs.REGULAR_FINANCIAL_OPERATION_DETAIL_SELL, { idObject, operationId });

    return (
        <GridItem xs={12}>
            <DataTable
                icon="chart-bar"
                title={t("general.statements.operationOverview")}
                headerHeight={62}
                actions={<FinancialOperationsTableFilter.Container />}
                disableColumnReorder
                pageSize={DEFAULT_TABLE_PAGE_SIZE}
                rowsPerPageOptions={DEFAULT_TABLE_ROWS_OPTIONS}
                getRowId={(row) => row.id}
                rows={financialOperations.toArray()}
                columns={columns(t, idObject, getOperationBuyDetailUrl, getOperationSellDetailUrl)}
                emptyMsg={t("common.noData")}
                errorMsg={t("error.sectionUnavailable")}
                isLoading={isFinOperationsLoading}
                isError={isFinOperationsError}
                filterModel={filterModel}
            />
        </GridItem>
    );
};
