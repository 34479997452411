import { withTheme } from "@mui/styles";
import styled from "styled-components";
import { Box } from "ui-library/layouts/Box/Box";

export const StyledYoutubeEmbed = withTheme(styled(Box)`
    max-width: ${(props) => (props.$maxWidth ? props.$maxWidth + "px" : "unset")};
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
    /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        box-shadow: ${(props) => (props.$shadow ? "0px 20px 35px 0px #30313d1a" : "")};
    }
`);
