import React from "react";
import { fromJS } from "immutable";
import { useSelector } from "react-redux";

import { Typography } from "ui-library/atoms";
import { ContentBox, Section } from "ui-library/layouts";

import i18n from "core/i18n";
import {
    Field,
    FormWideDisabledProvider,
    SingleSelect,
    STATE_CHANGE_DISABLE,
    STATE_CHANGE_ENABLE,
    STATE_CHANGE_NO_CHANGE,
} from "core/form";

import { GUARANTEED_FUND_RATIO_REDUCTION } from "../constants";
import { getGuaranteedFundRatioReductionValue } from "../selectors";
import { GuaranteedFundRatioReductionDisable, GuaranteedFundRatioReductionEnable } from "../components/textBlock";

const prepareOptionsBasedOnInitialValue = (t, isInitiallyEnabled) => {
    const options = [];
    options.push({ label: t("form.stateChange.noChange"), value: STATE_CHANGE_NO_CHANGE });
    if (isInitiallyEnabled) {
        options.push({ label: t("form.stateChange.disable"), value: STATE_CHANGE_DISABLE });
    } else {
        options.push({ label: t("form.stateChange.enable"), value: STATE_CHANGE_ENABLE });
    }
    return fromJS(options);
};

const GuaranteedFundRatioReduction = ({
    formName,
    isGuaranteedFundRatioReductionInitiallySet,
    isGuaranteedFundRatioReductionPossible,
    disabled = false,
}) => {
    const { t } = i18n.useTranslation();
    const guaranteedFundRatioReductionValue = useSelector(getGuaranteedFundRatioReductionValue(formName));
    const isDisabled = !isGuaranteedFundRatioReductionPossible || disabled;

    return (
        <Section>
            <FormWideDisabledProvider value={isDisabled}>
                {/* Section title */}
                <ContentBox>
                    <Typography variant="h3">{`3. ${t("pension.guaranteedFundRatioReductionSet")}`}</Typography>
                </ContentBox>

                {/* Text based on initial value */}
                <ContentBox>
                    <Typography
                        markDown={
                            isGuaranteedFundRatioReductionInitiallySet
                                ? t("pension.fundsChange.dss.guaranteedFundRatioReductionInitiallySet")
                                : t("pension.fundsChange.dss.guaranteedFundRatioReductionInitiallyUnset")
                        }
                    />
                </ContentBox>

                {/* Form or not allowed at the moment text. */}
                {isGuaranteedFundRatioReductionPossible ? (
                    <>
                        {/* Select component */}
                        <Field
                            name={GUARANTEED_FUND_RATIO_REDUCTION}
                            component={SingleSelect}
                            options={prepareOptionsBasedOnInitialValue(t, isGuaranteedFundRatioReductionInitiallySet)}
                        />

                        {/* New value disclaimer */}
                        {guaranteedFundRatioReductionValue !== STATE_CHANGE_NO_CHANGE && (
                            <ContentBox mt={2}>
                                {guaranteedFundRatioReductionValue === STATE_CHANGE_ENABLE && <GuaranteedFundRatioReductionEnable />}
                                {guaranteedFundRatioReductionValue === STATE_CHANGE_DISABLE && <GuaranteedFundRatioReductionDisable />}
                            </ContentBox>
                        )}
                    </>
                ) : (
                    <ContentBox>
                        <Typography color="red" component="p">
                            {t("pension.fundsChange.unprocessedGuaranteedFundRatioReductionSet")}
                        </Typography>
                    </ContentBox>
                )}
            </FormWideDisabledProvider>
        </Section>
    );
};
export default GuaranteedFundRatioReduction;
