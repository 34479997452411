import React from "react";
import PropTypes from "prop-types";

import { StyledDropdownOption } from "ui-library/Styled";

import { Box } from "ui-library/layouts/Box/Box";
import { Typography } from "ui-library/atoms/Typography/Typography";

export const PaymentAmountTypeOption = ({ label, ...props }) => (
    <StyledDropdownOption complexOption={false} py={{ xs: 0.5, md: 0 }} direction={{ xs: "column", md: "row" }} {...props}>
        <Box pr={2}>
            <Typography variant="p">{label}</Typography>
        </Box>
        {/*<Box sx={{ marginLeft: { xs: '0px', md: 'auto' } }}>*/}
        {/*<Typography variant="c" color="gray">*/}
        {/*ABC*/}
        {/*</Typography>*/}
        {/*</Box>*/}
    </StyledDropdownOption>
);

PaymentAmountTypeOption.propTypes = {
    label: PropTypes.string.isRequired,
};
