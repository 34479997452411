import { reduxForm, SubmissionError } from "redux-form/immutable";

import { startSubmit, asyncValidate as reduxAsyncValidate, onSubmitFail } from "./actions";
import { getForm } from "./selectors";

export default (props) =>
    reduxForm({
        ...props,
        // enableReinitialize: true,
        updateUnregisteredFields: true,
        touchOnChange: true,
        asyncChangeFields: props.asyncValidationFields,
        shouldAsyncValidate: props.asyncValidationFields ? shouldAsyncValidate : undefined,
        getFormState: getForm,
        validate: () => {},
        /**
         * Customized submit function, which calls async validations beforehand
         * Default redux-form behavior was strange here, so we handle it ourselves.
         */
        onSubmit: (data, dispatch, formProps) =>
            new Promise((resolve, reject) => {
                // strangely, asyncValidate's should reject plain error object, but
                // submit's reject expect instance of SubmissionError
                const rejectWithSubmissionError = (error) => {
                    reject(new SubmissionError(error));
                };
                const startSubmitCallback = () => {
                    dispatch(startSubmit(props.form, data, resolve, reject));
                };
                if (formProps.shouldAsyncValidate) {
                    dispatch(reduxAsyncValidate(props.form, null, data, startSubmitCallback, rejectWithSubmissionError));
                } else {
                    startSubmitCallback();
                }
            }),
        onSubmitFail: (errors, dispatch) => {
            dispatch(onSubmitFail(props.form));
        },
        asyncValidate: props.asyncValidationFields
            ? (data, dispatch, formProps, field) =>
                  new Promise((resolve, reject) => {
                      dispatch(reduxAsyncValidate(props.form, field, data, resolve, reject));
                  })
            : undefined,
    });

/**
 * Mostly original function aside from changed SUBMIT and BLUR behavior. We call async validation ourselves. see onSubmit
 * As for blur validation, it worked bad with submitting
 */
function shouldAsyncValidate({ syncValidationPasses, trigger }) {
    if (!syncValidationPasses) {
        return false;
    }
    switch (trigger) {
        case "change":
            return true;
        case "submit":
        case "blur":
            return false;
        default:
            return false;
    }
}
