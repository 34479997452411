import { Frequency } from "enums";
import { PaymentsSchedulerLegacy } from "model/common";

export const getPaymentSchedulerOrEmptyScheduler = (paymentsScheduler) => {
    if (!paymentsScheduler) {
        return PaymentsSchedulerLegacy.fromServer();
    }
    return paymentsScheduler;
};

export const prepareValidFrequencyOptions = (paymentsScheduler) => {
    const validFrequencyOptions = Object.keys(Frequency);
    return validFrequencyOptions.includes(paymentsScheduler.getIn(["periodicity", "code"]))
        ? paymentsScheduler
        : paymentsScheduler.setIn(["periodicity", "code"], undefined);
};
