import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthState, initialState, LogoutAction, NAME, UnsuccessfulReason } from "./constants";

const setInactiveInterval: CaseReducer<AuthState, PayloadAction<number>> = (state, { payload }) => {
    state.inactiveInterval = payload;
};

const logIn: CaseReducer<AuthState, PayloadAction<number>> = (state, { payload }) => {
    state.logged = true;
};

const setLogged: CaseReducer<AuthState, PayloadAction<number>> = (state, { payload }) => {
    state.logged = true;
};

const logOut: CaseReducer<AuthState, PayloadAction<LogoutAction>> = (state, { payload }) => {
    state.logged = false;
    state.logoutAction = payload;
};

const setLoginUnsuccessfulReason: CaseReducer<AuthState, PayloadAction<UnsuccessfulReason>> = (state, { payload }) => {
    state.loginUnsuccessfulReason = payload;
};

export const slice = createSlice({
    name: NAME,
    initialState,
    reducers: {
        setInactiveInterval,
        logIn,
        setLogged,
        logOut,
        setLoginUnsuccessfulReason,
    },
});

export default slice;
