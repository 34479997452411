import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { change } from "redux-form/immutable";

import { Flexbox } from "ui-library/layouts";
import { Button } from "ui-library/atoms";

import i18n from "core/i18n";

import { ACTIVE_STEP_VIRTUAL_FIELD } from "../constants";

export const StepTwoButtonsBox = ({ formName, canSubmit, handleSubmit }) => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();

    return (
        <Flexbox mt={6} gap={2} justifyContent="space-between">
            <Button onClick={() => dispatch(change(formName, ACTIVE_STEP_VIRTUAL_FIELD, 0))} variant="outlined" color="blue">
                {t("common.back")}
            </Button>
            <Button disabled={!canSubmit} onClick={handleSubmit} variant="contained" color="blue">
                {t("common.confirmRequest")}
            </Button>
        </Flexbox>
    );
};

StepTwoButtonsBox.propTypes = {
    formName: PropTypes.string.isRequired,
    canSubmit: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};
