export const NAME = "page-claims";

// Entity
export const ENTITY_CLIENT_INCIDENTS = `${NAME}/ENTITY_CLIENT_INCIDENTS`;

// Entity state
export const ENTITY_CLIENT_INCIDENTS_OPEN_STATE = `${NAME}/ENTITY_CLIENT_INCIDENTS_OPEN_STATE`;
export const ENTITY_CONTRACT_OVERVIEW = `${NAME}/ENTITY_CONTRACT_OVERVIEW`;

// Selector class
export const SECTION_TO_SCROLL_VIEW_CLASS = "section_to_scroll_view_class";
