import { List } from "immutable";
import { createSelector } from "reselect";

import entity from "core/entity";
import { app } from "core/util";
import { ChangePropositionStatus, ChangePropositionType } from "enums";

export default (reducerPath, propositionsEntity) => {
    const getModel = (state) => state.getIn(reducerPath);
    const getChangePropositions = (state) => entity.getDataSelector(state, propositionsEntity, new List());

    const isError = (state) => getChangePropositions(state) === app.ERROR_DATA_VALUE;
    const getPageState = (state) => getModel(state).get("pageState");

    const getChangeProposition = (changePropositionId) =>
        createSelector(getChangePropositions, (changePropositions) =>
            changePropositions.filter((changeProposition) => changeProposition.id === changePropositionId).first(),
        );

    const getChangePropositionDetail = createSelector(getChangePropositions, getPageState, (changePropositions, pageState) =>
        changePropositions.filter((changeProposition) => changeProposition.id === pageState.changePropositionId).first(),
    );

    const getChangePropositionsData = createSelector(getChangePropositions, (changePropositions) =>
        changePropositions.filter(
            (changeProposition) => changeProposition.changeProposition.type !== ChangePropositionType.MUTUAL_FUNDS_TRANSACTION.id,
        ),
    );

    const getChangePropositionsTransactionData = createSelector(getChangePropositions, (changePropositions) =>
        changePropositions.filter(
            (changeProposition) => changeProposition.changeProposition.type === ChangePropositionType.MUTUAL_FUNDS_TRANSACTION.id,
        ),
    );

    const isChangePropositionDisabled = createSelector(
        getChangePropositionDetail,
        (propositionDetail) => propositionDetail.status !== ChangePropositionStatus.AWAITING_ACCEPTATION.id,
    );

    const getIsCancellationSubmitted = (state) => getModel(state).get("isCancellationSubmitted");

    return {
        getChangePropositionDetail,
        getChangePropositions,
        getChangeProposition,
        getChangePropositionsData,
        getChangePropositionsTransactionData,
        getPageState,
        isError,
        isChangePropositionDisabled,
        getIsCancellationSubmitted,
    };
};
