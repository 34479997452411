import { NAME, PageContextState } from "./constants";
import { Countries } from "enums";
import { Country, Locale } from "types";

const getModel = (state): PageContextState => state.get(NAME);

export const getLocale = (state): Locale => getModel(state).pageLocalization.locale;
export const getAvailableLocales = (state): Locale[] => getModel(state).pageLocalization.availableLocales;

export const getSite = (state): Country => getModel(state).pageLocalization.site;
export const getSiteId = (state): string => getModel(state).pageLocalization.site.id;
export const getIsSlovakSite = (state) => getSite(state).id === Countries.SK.id;

const getFeatureFlags = (state) => getModel(state).featureFlags;
export const getFeatureFlagEnabled = (featureName) => (state) => getFeatureFlags(state).includes(featureName);

export const getNotAllowedTargetFundCodesCz = (state) => getModel(state).notAllowed.targetFundCodesCz;
export const getNotAllowedTargetFundCodesSk = (state) => getModel(state).notAllowed.targetFundCodesSk;

export const getDisclaimerText = (state) => getModel(state).disclaimer.text;
export const getDisclaimerVersion = (state) => getModel(state).disclaimer.version;

export const getCaptchaSiteKey = (state) => getModel(state).keys.recaptcha;
export const getVapidPublicKey = (state) => getModel(state).keys.vapid;
export const getYoutubePromoId = (state) => getModel(state).keys.youtube;

export const getInfoTexts = (state) => getModel(state).infoTexts;

export const getClientId = (state) => getModel(state).clientId;

export const getAuthUri = (state) => getModel(state).configUrls.authUri;
export const getLogoutUri = (state) => getModel(state).configUrls.logoutUri;
export const getClaimReportingUrl = (state) => getModel(state).configUrls.claimReportingUrl;
export const getClaimReportingUrls = (state) => getModel(state).configUrls.claimReportingUrls;
