import { Record } from "immutable";

class DpsFunds extends Record({ fundCode: null, fundName: null, fundColor: null, fundLink: null }) {}

export default Object.freeze({
    CONSERVATIVE: new DpsFunds({
        fundCode: "3",
        fundColor: "greenMain",
        fundLink: "fundLink.pensions.CONSERVATIVE",
    }),
    BOND: new DpsFunds({
        fundCode: "2",
        fundColor: "blueMain",
        fundLink: "fundLink.pensions.BOND",
    }),
    BALANCED: new DpsFunds({
        fundCode: "4",
        fundColor: "pinkMain",
        fundLink: "fundLink.pensions.BALANCED",
    }),
    EQUITY: new DpsFunds({
        fundCode: "5",
        fundColor: "orangeMain",
        fundLink: "fundLink.pensions.EQUITY",
    }),
});
