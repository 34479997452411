import { combineReducers } from "redux-immutable";
import { List, Map } from "immutable";

import { AuthenticatedUser } from "model/user";

import { acceptingGdprActionGroup, EXPAND_MESSAGE, GDPR_DATA, SET_AGENTS, SET_AUTHENTICATED_USER, SET_MESSAGES } from "./actions";

const authenticatedUser = (state = new AuthenticatedUser(), { type, payload }) => {
    switch (type) {
        case SET_AUTHENTICATED_USER:
            return payload.authenticatedUser;
        default:
            return state;
    }
};

const messagesList = (state = List(), action) => {
    switch (action.type) {
        case SET_MESSAGES:
            return action.payload.map((item) => item.id);
        default:
            return state;
    }
};

const messagesMap = (state = Map(), action) => {
    switch (action.type) {
        case SET_MESSAGES:
            return Map(action.payload.map((item) => [item.id, item]));
        case EXPAND_MESSAGE:
            if (action.payload.expand) {
                return state.setIn([action.payload.id, "unread"], false);
            }
            return state;
        default:
            return state;
    }
};

const agents = (state = Map(), action) => {
    switch (action.type) {
        case SET_AGENTS:
            return Map(action.payload.map((agent) => [agent.idAgentContractAgent, agent]));
        default:
            return state;
    }
};

const gdprData = (
    state = Map({
        text: null,
        version: null,
        formText: null,
        formVersion: null,
        modifyDate: null,
        url: null,
        rows: null,
        cols: null,
        matrix: null,
    }),
    { type, payload },
) => {
    switch (type) {
        case GDPR_DATA:
            return Map({
                text: payload.text,
                version: payload.version,
                formText: payload.formText,
                formVersion: payload.formVersion,
                modifyDate: payload.modifyDate,
                url: payload.url,
                consents: payload.consents,
            });
        case acceptingGdprActionGroup.RESPONSE:
            return state.merge({
                consents: payload.consents,
            });
        default:
            return state;
    }
};

export default combineReducers({
    authenticatedUser,
    gdprData,
    messagesList,
    messagesMap,
    agents,
});
