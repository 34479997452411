import PropTypes from "prop-types";
import * as React from "react";
import { Flexbox } from "ui-library/layouts/Flexbox/Flexbox";

export const SubPageWrapper = ({ children, ...props }) => {
    return (
        <Flexbox direction={{ xs: "column", md: "row" }} {...props}>
            {children}
        </Flexbox>
    );
};

SubPageWrapper.propTypes = {
    children: PropTypes.node,
};
