import React from "react";
import { useSelector } from "react-redux";

import { Box, ScreenMessageLayout } from "ui-library/layouts";
import { Container, ImportantAnnouncements } from "ui-library/atoms";
import bannerImg from "ui-library/assets/images/banner-prihlasenie.svg";

import i18n from "core/i18n";

import { getIsError, getIsPaymentSuccessful } from "./selectors";

export default () => {
    const { t } = i18n.useTranslation();
    const isPaymentSuccessful = useSelector(getIsPaymentSuccessful);
    const isError = useSelector(getIsError);
    const text = getText(isPaymentSuccessful, isError);
    const variant = isPaymentSuccessful ? "SUCCESS" : "ERROR";

    return (
        <Container>
            <Box className="banner-img" sx={{ backgroundImage: "url(" + bannerImg + ")" }}>
                <ScreenMessageLayout>
                    <ImportantAnnouncements
                        variant={variant}
                        data={{
                            heading: t(text),
                            content: "",
                        }}
                    />
                </ScreenMessageLayout>
            </Box>
        </Container>
    );
};

const getText = (isPaymentSuccessful, isError) => {
    if (isError) {
        return "onlinePayment.state.error";
    } else if (isPaymentSuccessful === true) {
        return "onlinePayment.state.success";
    } else if (isPaymentSuccessful === false) {
        return "onlinePayment.state.failed";
    } else {
        return null;
    }
};
