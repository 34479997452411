import React, { FC } from "react";
import { useSelector } from "react-redux";

import { HtmlLink, PopoverTooltip, Typography } from "ui-library/atoms";
import { Box, ContentBox, Flexbox, GridItem } from "ui-library/layouts";
import { DataTable, InfoPanel } from "ui-library/modules";

import entity from "core/entity";
import fetching from "core/fetching";
import i18n, { TFunction } from "core/i18n";
import {
    dateTimeToLocalFullDate,
    localAmountInvestment,
    localAmountInvestmentWithCurrency,
    localAmountWithCurrencyLegacy,
} from "core/formatters";

import { ENTITY_FINANCIAL_STATEMENT } from "../constants";
import { getPensionDpsFinancialStatementEntity, getPensionDpsFundsStatementsData } from "../selectors";

import { PieChartWithFundsTable } from "./PieChartWithFundsTable";

const columns = (t: TFunction) => [
    {
        field: "fundName",
        headerName: t("common.fund"),
        flex: 1,
        renderCell: (params) => (
            <HtmlLink href={params.value.href} target="_blank">
                {params.row.fundName.text}
            </HtmlLink>
        ),
        sortable: false,
    },
    {
        field: "sumShares",
        headerName: t("pension.numberOfShares"),
        flex: 1,
        valueGetter: (params) => localAmountInvestment(params.row.sumShares),
        sortable: false,
        align: "right",
        headerAlign: "right",
    },
    {
        field: "pricePerShare",
        headerName: t("pension.actualPricePerShare"),
        flex: 1,
        valueGetter: (params) => localAmountInvestmentWithCurrency(params.row.pricePerShare),
        sortable: false,
        align: "right",
        headerAlign: "right",
    },
    {
        field: "actualInvestmentsValue",
        headerName: t("investments.actualInvestmentsValue"),
        valueGetter: (params) => localAmountWithCurrencyLegacy(params.row.actualInvestmentsValue),
        flex: 1,
        sortable: false,
        align: "right",
        headerAlign: "right",
    },
];

export const ActualInvestmentValue: FC = () => {
    const { t } = i18n.useTranslation();
    const data = useSelector(getPensionDpsFundsStatementsData);
    const isStatementLoading = useSelector(fetching.isFetching(ENTITY_FINANCIAL_STATEMENT, true));
    const isStatementError = useSelector(entity.isError(ENTITY_FINANCIAL_STATEMENT));

    const statement = useSelector(getPensionDpsFinancialStatementEntity);
    const statementDate = statement?.dateTime;

    if (statement?.actualInvestmentsValue?.value === "0") {
        return null;
    }

    return (
        <GridItem xs={12}>
            <InfoPanel
                icon={"chart-line"}
                title={
                    <Flexbox gap={1}>
                        {t("insurance.investmentStateBasedOnFunds")}
                        <PopoverTooltip title="" content={t("pension.statements.dateTimeFootnote")} />
                    </Flexbox>
                }
                headerAction={<Typography color="gray">{dateTimeToLocalFullDate(statementDate)}</Typography>}
            >
                {/* Funds table data */}
                <ContentBox>
                    <DataTable
                        headerHeight={60}
                        getRowId={(row) => row.reactKey}
                        columns={columns(t)}
                        rows={data.toArray()}
                        summaryRow={{ desc: t("common.overall"), total: localAmountWithCurrencyLegacy(statement.actualInvestmentsValue) }}
                        withWrapper={false}
                        disableColumnReorder
                        isLoading={isStatementLoading}
                        isError={isStatementError}
                    />
                </ContentBox>

                {/* Chart and sum data */}
                {!isStatementLoading && !isStatementError && (
                    <Box>
                        <Box mb={1}>
                            <Typography variant="h4">{t("insurance.investmentStateBasedOnFunds")}</Typography>
                        </Box>
                        <PieChartWithFundsTable />
                    </Box>
                )}
            </InfoPanel>
        </GridItem>
    );
};
