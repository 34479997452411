import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { DataTable } from "ui-library/modules";
import { Accordion, StandaloneLink, Typography } from "ui-library/atoms";
import { ContentBox, GridItem } from "ui-library/layouts";

import i18n, { TFunction } from "core/i18n";
import router from "core/router";
import { ExternalLinkFormatter, localAmountInvestmentWithCurrencyEur, localCurrency } from "core/formatters";
import userContracts from "core/userContracts";
import { Pages, Tabs } from "routeConstants";

import { getContractListOfFundsForInvestment, getFundsForInvestment } from "../selectors";

const getColumns = (t: TFunction, idObject: number, isInFutureOrTerminated: boolean) => [
    {
        headerName: t("common.fund"),
        field: "fundName",
        minWidth: "220",
        renderCell: (params) => <ExternalLinkFormatter href={params.value.href} text={params.value.text} />,
    },
    {
        headerName: t("investments.investmentCurrency"),
        field: "currencyCode",
        renderCell: (params) => localCurrency(params.value),
        minWidth: "70",
    },
    {
        headerName: t("common.fundType"),
        field: "fundType",
        minWidth: "140",
    },
    {
        headerName: t("investments.actualShareValue"),
        field: "actualPricePerShare",
        renderCell: (params) => localAmountInvestmentWithCurrencyEur(params.value),
        minWidth: "130",
    },
    {
        headerName: t("investments.investmentHorizon"),
        field: "fundInvestmentHorizon",
        minWidth: "150",
    },
    {
        headerName: t("common.payment"),
        field: "payment",
        renderCell: (params) =>
            !isInFutureOrTerminated && (
                <StandaloneLink
                    color="blue"
                    to={router.getStaticUrl(Pages.ONLINE_PAYMENT, Tabs.PAYMENTS_PAY, {
                        contractId: idObject,
                        fundId: params.row.id,
                    })}
                >
                    {t("common.invest")}
                </StandaloneLink>
            ),
        minWidth: "120",
    },
];

export const InvestmentsNewBuyActionGridItem: FC = () => {
    const { t } = i18n.useTranslation();
    const funds = useSelector(getContractListOfFundsForInvestment);
    const data = useSelector(getFundsForInvestment(funds));
    const { idObject } = useParams();
    const contract = userContracts.useGetContractById(idObject);
    const { isInFutureOrTerminated } = userContracts.useGetContractDerivedData(contract);

    return (
        <GridItem xs={12}>
            <DataTable
                icon={"coins"}
                title={t("investments.newBuyActionCollapsible")}
                headerHeight={60}
                rowHeight={50}
                disableColumnReorder
                pageSize={20}
                hideFooter
                getRowId={(row) => row.id}
                rows={data ?? []}
                columns={getColumns(t, Number(idObject), isInFutureOrTerminated)}
                preContent={
                    <>
                        <ContentBox>
                            <Accordion
                                data={[
                                    {
                                        title: t("investments.buyNote"),
                                        children: <Typography markDown={t("investments.newInvestmentText")} />,
                                    },
                                ]}
                                id="mutualFundsWarning"
                            />
                        </ContentBox>
                    </>
                }
            />
        </GridItem>
    );
};
