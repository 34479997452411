export default (actionPrefix) => {
    const SET_PAGE_STATE = `${actionPrefix}/SET_PAGE_STATE`;
    const SET_CANCELLATION_SUBMITTED = `${actionPrefix}/SET_CANCELLATION_SUBMITTED`;
    const CANCEL_SUBMIT = `${actionPrefix}/CANCEL_SUBMIT`;

    return {
        SET_PAGE_STATE,
        setPageState: (changePropositionId, changePropositionPageState) => ({
            type: SET_PAGE_STATE,
            payload: { changePropositionId, changePropositionPageState },
        }),

        SET_CANCELLATION_SUBMITTED,
        setCancellationSubmitted: (isCancellationSubmitted) => ({
            type: SET_CANCELLATION_SUBMITTED,
            payload: isCancellationSubmitted,
        }),

        CANCEL_SUBMIT,
        setCancel: () => ({
            type: CANCEL_SUBMIT,
        }),
    };
};
