import { call, put } from "redux-saga/effects";
import { List } from "immutable";

import { formWrapper } from "core/form";
import errorHandling from "core/errorHandling";
import { sentry } from "core/util";
import { incidentsApi } from "serverApi";

export function* createSaga(formName, incidentId, claimId, requiredDocument) {
    try {
        yield call(formSaga(formName), incidentId, claimId, requiredDocument);
    } catch (e) {
        sentry.captureException(e);
        yield put(errorHandling.addServiceError(formName, e.identifier));
    }
}

const formSaga = (formName) =>
    formWrapper(formName, {
        *initialize() {
            yield put(errorHandling.removeServiceErrors(formName));
            return {
                files: List(),
            };
        },
        *save(values, incidentId, claimId, requiredDocument) {
            const requestData = {
                files: values.get("files"),
                requiredDocument,
            };

            yield call(incidentsApi.uploadRequiredDocuments, incidentId, claimId, requestData);
            return values;
        },
    });
