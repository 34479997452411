import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "ui-library/atoms";
import { Flexbox } from "ui-library/layouts";
import { SectionBanner, SectionInnerBlock } from "ui-library/modules";
import bannerImg from "ui-library/assets/images/banner-prihlasenie.svg";

import i18n from "core/i18n";
import pageContext from "core/pageContext";
import { LoginPageError } from "containers/error";
import { PageBoundary } from "containers";

import { setLoginPageStep } from "../actions";
import { AlertBox } from "../components";
import { LOGIN_PAGE_STEP } from "../constants";
import { ResetPassword } from "../modules";
import { getLoginPageState } from "../selectors";

import { LoginForm } from "./LoginForm";

export const LoginResetPasswordSection = () => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();
    const loginPageStep = useSelector(getLoginPageState);
    const infoTexts = useSelector(pageContext.getInfoTexts);

    return (
        <SectionBanner title={t("page.login.welcomeTitle")} description={t("page.login.welcomeText")} imageUrl={bannerImg}>
            {/* Info box with alert text */}
            {infoTexts?.enableLoginAlert && <AlertBox mb={2} text={infoTexts.loginText} />}

            {/* Section with Login Form and Reset password form */}
            <PageBoundary ErrorComponent={LoginPageError}>
                <SectionInnerBlock>
                    {loginPageStep === LOGIN_PAGE_STEP.LOGIN_FORM && <LoginForm />}
                    {loginPageStep === LOGIN_PAGE_STEP.RESET_PASSWORD && <ResetPassword.Container />}
                </SectionInnerBlock>

                <Flexbox mt={1} alignItems={{ xs: "flex-start", md: "flex-end" }}>
                    {loginPageStep !== LOGIN_PAGE_STEP.LOGIN_FORM && (
                        <Button onClick={() => dispatch(setLoginPageStep(LOGIN_PAGE_STEP.LOGIN_FORM))} variant="text" color="blue">
                            {t("common.backToBeginning")}
                        </Button>
                    )}
                    {loginPageStep !== LOGIN_PAGE_STEP.RESET_PASSWORD && (
                        <Button onClick={() => dispatch(setLoginPageStep(LOGIN_PAGE_STEP.RESET_PASSWORD))} variant="text" color="blue">
                            {t("page.login.forgotPassword")}
                        </Button>
                    )}
                </Flexbox>
            </PageBoundary>
        </SectionBanner>
    );
};
