import { createSelector } from "reselect";

import i18n from "core/i18n";
import pageContext from "core/pageContext";
import user from "core/user";

import { AUTHORIZATION_TYPE_IMPERSONATION } from "app/constants";
import { app } from "core/util";
import { createGetContractById } from "core/userContracts/selectors";
import { AccountInformation, AuthenticatedUser } from "model/user";
import { LegalEntityLegacy } from "model/common";
import { Agent } from "model/agent";

import { NAME } from "./constants";

export const getModel = app.createGetModel(NAME);

export const getAuthenticatedUser = (state) => getModel(state).get("authenticatedUser", new AuthenticatedUser());
export const getIsVerifiedUser = (state) => getAuthenticatedUser(state).getIn(["verification", "isVerified"]);
export const getVerificationDenyReason = (state) => getAuthenticatedUser(state).getIn(["verification", "denyReason"]);
export const getVerificationDenyReasonComment = (state) => getAuthenticatedUser(state).getIn(["verification", "denyReasonComment"]);
export const getVerificationRequestDate = (state) => getAuthenticatedUser(state).getIn(["verification", "requestDate"]);
export const getVerificationConfirmDate = (state) => getAuthenticatedUser(state).getIn(["verification", "confirmDate"]);

export const getClient = (state) => getModel(state).getIn(["authenticatedUser", "client"], new LegalEntityLegacy());
export const isClientPerson = (state) => getClient(state).get("isPerson");
export const getPhone = (state) => getClient(state).get("phone");

export const getConsent = (state) => getAuthenticatedUser(state).get("consent");
export const hasGdprConsent = (state) => !!getConsent(state).get("date");
export const getGdprConsentDate = (state) => getConsent(state).get("date");
export const getGdprText = (state) => getModel(state).getIn(["gdprData", "text"]);
export const getGdprFormText = (state) => getModel(state).getIn(["gdprData", "formText"]);
export const getGdprVersion = (state) => getModel(state).getIn(["gdprData", "version"]);
export const getGdprModifyDate = (state) => {
    const date = getModel(state).getIn(["gdprData", "modifyDate"]);
    if (!date) {
        return null;
    }
    const getFormattedDate = i18n.createGetFormattedDate(date);
    return getFormattedDate(state);
};
export const getGdprUrl = (state) => getModel(state).getIn(["gdprData", "url"]);
export const getConsents = (state) => getModel(state).getIn(["gdprData", "consents"]);

const getMessageIds = (state) => getModel(state).getIn(["messagesList"]);
export const getUnreadMessageCount = (state) =>
    getMessageIds(state)
        .filter((msgId) => getMessage(state, msgId).unread)
        .count();
export const getMessage = (state, id) => getModel(state).getIn(["messagesMap", id]);
export const getMessages = (state) => getModel(state).get("messagesMap").toList();

// Disclaimer
export const getCurrentDisclaimerAccepted = (state) => {
    // We do not want to display disclaimer for impersonate.
    const accountInformation = user.getUserAccount(state);
    const isImpersonation = accountInformation?.authorizationType === AUTHORIZATION_TYPE_IMPERSONATION;
    if (isImpersonation) {
        return true;
    }

    const currentVersion = pageContext.getDisclaimerVersion(state);
    const acceptedVersion = getAuthenticatedUser(state).disclaimerVersion;
    return currentVersion === acceptedVersion;
};

// Agents
export const getAgents = (state) => getModel(state).get("agents");
export const createGetAgentByObjectId = (idObject) =>
    createSelector(createGetContractById(idObject), getAgents, (contract, agents) =>
        contract && agents && agents.get(contract.idObjectAgentContractAgent)
            ? agents.get(contract.idObjectAgentContractAgent)
            : new Agent(),
    );
