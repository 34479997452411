import { v4 as uuid } from "uuid";
import { model } from "core/util";

import { HolderLegacy } from "../holder";

export const LifeBeneficiaries = model.createModelLegacy("LifeBeneficiaries", (json) => ({
    reactKey: uuid(),
    percentage: json.Percentage,
    insuredPerson: HolderLegacy.fromServer(json.InsuredPerson),
    beneficiary: HolderLegacy.fromServer(json.Beneficiary),
    description: json.Description,
}));
