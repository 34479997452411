import { createActionWithPayload } from "./createActionWithPayload";
import { createDataReducer, createDataReducerWithError, ERROR_DATA_VALUE } from "./createDataReducer";
import createGetModel from "./createGetModel";
import createNormalizedReducers from "./createNormalizedReducers";
import httpOpenInNewWindow from "./httpOpenInNewWindow";

export default {
    ERROR_DATA_VALUE,
    createActionWithPayload,
    createDataReducer,
    createGetModel,
    createDataReducerWithError,
    httpOpenInNewWindow,
    createNormalizedReducers,
};
