import { captureException as captureExceptionFn, init as initFn } from "@sentry/browser";

import RequestError from "../fetch/RequestError";
import fn from "../fn";

const captureException = (error, showDevLog = true) => {
    if (process.env.NODE_ENV === "production") {
        if (error instanceof RequestError && !fn.isEmpty(error) && !fn.isEmpty(error.response)) {
            captureExceptionFn(error, {
                extra: {
                    error: error.response.error,
                    message: error.response.message,
                    path: error.response.path,
                    timestamp: error.response.timestamp,
                },
            });
        } else {
            captureExceptionFn(error);
        }
    } else if (showDevLog) {
        // for dev purposes only
        console.error(error);
    }
};

const init = () => {
    if (process.env.NODE_ENV === "production") {
        const port = window.location.port ? `:${window.location.port}` : "";
        // project_id need to be number - I choose random number 15 and it needs to be same in BE (https://develop.sentry.dev/sdk/store/)
        initFn({ dsn: `${window.location.protocol}//token@${window.location.hostname}${port}/15` });
    }
};

export default {
    captureException,
    init,
};
