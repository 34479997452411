import React from "react";

import { ContentBox, Section } from "ui-library/layouts";
import { Typography } from "ui-library/atoms";

import { Field, required, SingleSelect } from "core/form";
import i18n from "core/i18n";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

export const SourceSectionDirect = ({ getSourceFundCodes, createGetSourceFundOptions }) => {
    const { t } = i18n.useTranslation();

    const sourceFundsCodes = useSelector(getSourceFundCodes);
    const getSourceFundOptions = createGetSourceFundOptions("targetFund", sourceFundsCodes);
    const sourceFundOptions = useSelector(getSourceFundOptions);

    return (
        <Section>
            <ContentBox>
                <Typography variant="h3">{t("common.fund")}</Typography>
            </ContentBox>
            <ContentBox fullWidth>
                <Field
                    name="sourceFund"
                    component={SingleSelect}
                    label={t("investments.sellSwitch.sourceLabel")}
                    options={sourceFundOptions}
                    validateStatic={[required]}
                    placeholder={t("investments.sellSwitch.sourcePlaceholder")}
                />
            </ContentBox>
        </Section>
    );
};

SourceSectionDirect.propTypes = {
    createGetSourceFundOptions: PropTypes.func.isRequired,
    getSourceFundCodes: PropTypes.func.isRequired,
};
