import React from "react";
import PropTypes from "prop-types";

import { Box, ContentBox } from "ui-library/layouts";
import { Button, Typography } from "ui-library/atoms";

import i18n from "core/i18n";

export const RedeemSavingsWelcomeText = ({ welcomeText, buttonAction }) => {
    const { t } = i18n.useTranslation();

    return (
        <>
            <ContentBox>
                <Typography markDown={welcomeText} />
            </ContentBox>
            <Box mt={4}>
                <Button color="green" onClick={buttonAction}>
                    {t("common.validateRedeem")}
                </Button>
            </Box>
        </>
    );
};

RedeemSavingsWelcomeText.propTypes = {
    welcomeText: PropTypes.string.isRequired,
    buttonAction: PropTypes.func.isRequired,
};
