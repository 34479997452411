import { Record } from "immutable";

class IndividualPersonType extends Record({ id: null, msg: null }) {}

export default Object.freeze({
    CONTRACT_HOLDER: new IndividualPersonType({ id: "CONTRACT_HOLDER", msg: "person.contractHolder" }),
    PARTICIPANT: new IndividualPersonType({ id: "PARTICIPANT", msg: "person.participant" }),
    SHAREHOLDER: new IndividualPersonType({ id: "SHAREHOLDER", msg: "person.shareholder" }),
    SAVER: new IndividualPersonType({ id: "SAVER", msg: "person.saver" }),
});
