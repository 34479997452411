import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { GridItem } from "ui-library/layouts";
import { Button, Typography } from "ui-library/atoms";
import { ContractCard } from "ui-library/modules";

import i18n from "core/i18n";
import localized from "core/localized";
import router from "core/router";

import { dateTimeToLocalFullDate } from "core/formatters";
import { claims } from "core/util";
import { Pages } from "routeConstants";
import { ADAPTIVE } from "ui-library/modules/enums";

export const CardUniqaIncident = ({ incident, shouldDisplayContractNumber }) => {
    const { t } = i18n.useTranslation();
    const navigate = useNavigate();

    const localizedLineOfBusiness = useSelector(localized.getLocalizedUniqaLineOfBusinessMap);
    const localizedInsuranceRisk = useSelector(localized.getLocalizedUniqaInsuranceRiskMap);
    const reportedOnlineNotProcessed = incident.get("categoryClaim") === "WEBCLAIM";

    return (
        <GridItem xs={12} mb={1}>
            <ContractCard
                variant={ADAPTIVE}
                title={`${claims.getLocalizedLineOfBusiness(incident, localizedLineOfBusiness)}${claims.getLocalizedInsuranceRisk(
                    incident,
                    localizedInsuranceRisk,
                )}`}
                imageUrl={claims.getImageCategory(incident)}
                action={
                    reportedOnlineNotProcessed ? (
                        <Typography>{t("incident.reportedOnlineInfo")}</Typography>
                    ) : (
                        <Button
                            variant={"contained"}
                            color={"blue"}
                            onClick={() =>
                                navigate(router.getStaticUrl(Pages.INCIDENT_DETAIL, undefined, { incidentId: incident.incidentId }))
                            }
                        >
                            {t("common.detail")}
                        </Button>
                    )
                }
            >
                <Typography component="p">{`${t("claim.incidentNumber")} ${incident.claimNumber}`}</Typography>
                {shouldDisplayContractNumber && (
                    <Typography component="p">{`${t("common.contractNumber")} ${incident.policyNumber}`}</Typography>
                )}
                <Typography component="p">{`${t("common.creationDate")} ${dateTimeToLocalFullDate(incident.eventDate)}`}</Typography>
            </ContractCard>
        </GridItem>
    );
};

CardUniqaIncident.propTypes = {
    incident: PropTypes.object.isRequired,
    shouldDisplayContractNumber: PropTypes.bool,
};

CardUniqaIncident.defaultProps = {
    shouldDisplayContractNumber: false,
};
