import createModel from "../createModel";

export const UniqaClaimSubject = createModel("UniqaClaimSubject", (json) => ({
    type: json.type,
    model: json.model,
    brand: json.brand,
    vin: json.vin,
    licensePlate: json.licensePlate,
    damageDescription: json.damageDescription,
}));
