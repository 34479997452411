import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ContractOption, Option, Select } from "ui-library/atoms";
import i18n from "core/i18n";
import { ContractPolicy } from "types";

export interface ContractSelectProps {
    contracts: ContractPolicy[];
    contractIdForPaymentSelector: any;
    setContractIdForPaymentAction: any;
}

export const ContractSelect: FC<ContractSelectProps> = ({ contracts, contractIdForPaymentSelector, setContractIdForPaymentAction }) => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();
    const contractId: string = useSelector(contractIdForPaymentSelector);

    return (
        <Select
            value={contractId || ""}
            onChange={(event) => dispatch(setContractIdForPaymentAction(event.target.value))}
            placeholder={t("payments.contractPlaceholder")}
            label={t("common.contract")}
            id="selectOther"
            fullWidth
        >
            {contracts &&
                contracts.map((contract) => (
                    <Option value={contract.idObject} key={contract.idObject}>
                        <ContractOption contractName={contract.contractName} contractNumber={`${contract.contractNumber}`} />
                    </Option>
                ))}
        </Select>
    );
};
