import React from "react";

import { Icon, Typography } from "ui-library/atoms";

import { FinancialOperationStatus } from "enums";

export const FinancialOperationStatusFormatter = ({ status, statusPendingText: pendingStatusText }) => {
    if (status === FinancialOperationStatus.PENDING) {
        return <Typography>{pendingStatusText}</Typography>;
    } else {
        return <Icon icon="check" color={"success"} />;
    }
};
