export const EMPTY_ACCOUNT_PREFIX = "000000";

// Fields
export const PENSION_TYPE_FIELD = "pensionType";
export const REDEEM_TYPE_FIELD = "redeemType";
export const REDEEM_START_FROM_FIELD = "redeemStartFrom";
export const REDEEM_PAYMENT_FREQUENCY_FIELD = "redeemPaymentFrequency";
export const REDEEM_PERIOD_IN_MONTHS_FIELD = "redeemPeriodInMonths";
export const REDEEM_EXTRAORDINARY_PAYMENT_PERCENT_FIELD = "redeemExtraordinaryPaymentPercent";
export const CONTRACT_TERMINATION_DATE_FIELD = "contractTerminationDate";
export const PAYMENT_TYPE_FIELD = "paymentType";
export const INSURANCE_COMPANY_FIELD = "insuranceCompany";
export const ACTIVE_STEP_VIRTUAL_FIELD = "activeStep";
export const ID_OBJECT_VIRTUAL_FIELD = "idObject";
export const PAYMENT_AMOUNT_VIRTUAL_FIELD = "paymentAmount";
export const EXTRAORDINARY_PAYMENT_AMOUNT_VIRTUAL_FIELD = "extraordinaryPaymentAmount";

// Address fields
export const ADDRESS_SECTION = "address";
export const ADDRESS_AUTOCOMPLETE = "autocomplete.address";
export const STREET_FORM_FIELD = "street";
export const DESCRIPTION_NUMBER_FORM_FIELD = "descriptionNumber";
export const ORIENTATION_NUMBER_FORM_FIELD = "orientationNumber";
export const CITY_FORM_FIELD = "city";
export const ZIP_FORM_FIELD = "zip";
export const STATE_FORM_FIELD = "state";

// Bank account fields
export const BANK_ACCOUNT_SECTION = "account";
export const BANK_ACCOUNT_PREFIX_FIELD = "prefix";
export const BANK_ACCOUNT_NUMBER_FIELD = "number";
export const BANK_CODE_FIELD = "bankCode";
export const VARIABLE_SYMBOL_FIELD = "variableSymbol";
export const SPECIFIC_SYMBOL_FIELD = "specificSymbol";

// Payment type
export const PAYMENT_TYPE_ADDRESS = "PAYMENT_TYPE_ADDRESS";
export const PAYMENT_TYPE_ACCOUNT = "PAYMENT_TYPE_ACCOUNT";
