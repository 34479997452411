import React, { FC, PropsWithChildren } from "react";
import { MenuItemProps } from "@mui/material";
import { StyledOption } from "ui-library/Styled";

export interface OptionProps extends PropsWithChildren, MenuItemProps {
    value: any;
}

export const Option: FC<OptionProps> = ({ value, children, ...props }) => {
    return (
        <StyledOption
            className={React.isValidElement(children) && children.props.complexOption ? "complex-option" : ""}
            value={value}
            {...props}
        >
            {children}
        </StyledOption>
    );
};
