import createModel from "../createModel";

export const OpeningTimes = createModel("OpeningTimes", (json) => ({
    monday: json.Monday,
    tuesday: json.Tuesday,
    wednesday: json.Wednesday,
    thursday: json.Thursday,
    friday: json.Friday,
    saturday: json.Saturday,
    sunday: json.Sunday,
}));
