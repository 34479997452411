import { Reducer } from "redux";

import auth from "core/auth";
import autocomplete from "core/autocomplete";
import cmsContent from "core/cmsContent";
import entity from "core/entity";
import enums from "core/enum";
import errorHandling from "core/errorHandling";
import fetching from "core/fetching";
import form from "core/form";
import i18n from "core/i18n";
import impersonate from "core/impersonate";
import init from "core/init";
import modal from "core/modal";
import pageContext from "core/pageContext";
import router from "core/router";
import user from "core/user";
import userContracts from "core/userContracts";
import userInfo from "core/userInfo";

export type CorePackage = {
    NAME: string;
    reducer?: Reducer;
} & any;

const corePackages: CorePackage[] = [
    auth,
    autocomplete,
    cmsContent,
    entity,
    enums,
    errorHandling,
    fetching,
    form,
    i18n,
    impersonate,
    init,
    modal,
    pageContext,
    router,
    user,
    userContracts,
    userInfo,
];

export default corePackages;
