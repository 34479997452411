import React, { FC } from "react";

import { InfoPanel, LabelTextItem } from "ui-library/modules";
import { Box, ContentBox, Flexbox, GridItem } from "ui-library/layouts";
import { PopoverTooltip, Typography } from "ui-library/atoms";

import i18n from "core/i18n";
import { formatBalanceOverdue, localAmountWithCurrencyLegacy } from "core/formatters";
import { fn } from "core/util";

export interface TotalInsuranceWithBalanceOverdueGridItemProps {
    contractBalance: any;
}

export const TotalInsuranceWithBalanceOverdueGridItem: FC<TotalInsuranceWithBalanceOverdueGridItemProps> = ({ contractBalance }) => {
    const { t } = i18n.useTranslation();
    const formattedOverdue = formatBalanceOverdue(t, contractBalance?.balanceOnContract);

    return (
        <GridItem xs={12} md={12}>
            <InfoPanel
                icon={"money-funnel"}
                title={
                    <Flexbox alignItems="center" gap={1}>
                        {t("insurance.balanceOverdue")}
                        <Box>
                            <PopoverTooltip title="" content={t("insurance.nonlifeBalanceFootnote")} />
                        </Box>
                    </Flexbox>
                }
                subTitleSection={
                    <ContentBox mt={1}>
                        <Typography fontSize={14} markDown={t("insurance.oneTimePayments.text")} />
                    </ContentBox>
                }
            >
                <LabelTextItem
                    label={t("insurance.insuranceCostSummary")}
                    text={<Typography fontWeight={500}>{localAmountWithCurrencyLegacy(contractBalance?.insuranceCostSummary)}</Typography>}
                />
                <LabelTextItem
                    label={t("insurance.receivedPayments")}
                    text={<Typography fontWeight={500}>{localAmountWithCurrencyLegacy(contractBalance?.receivedPayments)}</Typography>}
                />

                {!fn.isBalanceZeroOrValueNull(contractBalance?.balanceOnContract) && (
                    <LabelTextItem
                        label={formattedOverdue.title}
                        text={<Typography fontWeight={500}>{formattedOverdue.value}</Typography>}
                    />
                )}
            </InfoPanel>
        </GridItem>
    );
};
