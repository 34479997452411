import bannerImg from "ui-library/assets/images/banner-registracia.svg";
import { LandingPageLayout } from "ui-library/layouts";
import { SectionBanner, SectionInnerBlock } from "ui-library/modules";

import i18n from "core/i18n";

import { ActivateAccount } from "./modules";

export default () => {
    const { t } = i18n.useTranslation();

    return (
        <LandingPageLayout>
            <SectionBanner title={t("page.resetPassword.title")} description={t("page.registration.content")} imageUrl={bannerImg}>
                <SectionInnerBlock>
                    <ActivateAccount.Container />
                </SectionInnerBlock>
            </SectionBanner>
        </LandingPageLayout>
    );
};
