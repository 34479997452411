import slice from "./slice";
import * as selectors from "./selectors";
import * as saga from "./saga";

const entity = {
    NAME: slice.name,
    reducer: slice.reducer,
    ...slice.actions,
    ...selectors,
    ...saga,
};

export default entity;
