import { LandingPageLayout } from "ui-library/layouts";
import { useSelector } from "react-redux";
import auth from "core/auth";
import { FaqSection, LeadPanels, PromoBlocks } from "./components";
import { LoginResetPasswordSection, SsoLoginSection } from "./containers";

export default () => {
    const isSsoLoginEnabled = useSelector(auth.getIsSsoLoginEnabled);

    return (
        <LandingPageLayout>
            {isSsoLoginEnabled && <SsoLoginSection />}
            {!isSsoLoginEnabled && <LoginResetPasswordSection />}
            <LeadPanels />
            <PromoBlocks />
            <FaqSection />
        </LandingPageLayout>
    );
};
