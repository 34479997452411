import React, { FC } from "react";

import i18n from "core/i18n";
import { ImportantAnnouncements } from "ui-library/atoms";

export interface PageErrorProps {
    title: string;
    text: string;
}

export const PageError: FC<PageErrorProps> = ({ title, text }) => {
    const { t } = i18n.useTranslation();
    return (
        <ImportantAnnouncements
            variant={"ERROR"}
            data={{
                heading: t(title),
                content: t(text),
            }}
        />
    );
};
