import React from "react";
import { StyledOrderedList } from "ui-library/Styled";
import { ListItem } from "./ListItem";
import PropTypes from "prop-types";

export const OrderedList = ({ children }) => (
    <StyledOrderedList>
        {children
            ? React.Children.map(children, (item, index) => (
                  <ListItem key={index}> {item.type === "li" ? item.props.children : item}</ListItem>
              ))
            : ""}
    </StyledOrderedList>
);

OrderedList.propTypes = {
    children: PropTypes.array,
};

OrderedList.defaultProps = {};
