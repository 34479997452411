import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { DataTable } from "ui-library/modules";
import { Button, PopoverTooltip, StandaloneHtmlLink } from "ui-library/atoms";
import { ButtonsLayout, GridItem } from "ui-library/layouts";

import {
    localAmountInvestmentWithCurrencyEurLegacy,
    localAmountNoDecimal,
    localAmountWithCurrencyLegacy,
    nettoProfitHyperLinkFormatter,
} from "core/formatters";
import i18n, { TFunction } from "core/i18n";
import router from "core/router";
import { useHasEditContractPermission } from "core/hooks";
import userContracts from "core/userContracts";

import { Pages, Tabs } from "routeConstants";
import { getInvestmentDetailData, getIsDipContract } from "../selectors";

const createColumns = (t: TFunction, getProfitDetailUrl: (fund: string) => string) => [
    {
        headerName: t("common.fund"),
        field: "fund",
        minWidth: 260,
        sortable: false,
        renderCell: (params) => (
            <StandaloneHtmlLink color="blue" href={params.value.get("href")} target={"_blank"}>
                {params.value.get("text")}
            </StandaloneHtmlLink>
        ),
    },
    {
        headerName: (
            <>
                {t("investments.investedAmount")}{" "}
                <PopoverTooltip size={14} variant="info" title={t("investments.nettoInvestedAmount.mutualFundsRegular.footnote")} />
            </>
        ),
        field: "investedAmount",
        align: "right",
        headerAlign: "right",
        minWidth: 140,
        renderCell: (params) => localAmountWithCurrencyLegacy(params.value),
    },
    {
        headerName: t("investments.numberOfShares"),
        field: "numberOfShares",
        align: "right",
        headerAlign: "right",
        minWidth: 140,
        renderCell: (params) => localAmountNoDecimal(params.value),
    },
    {
        headerName: t("investments.actualShareValue"),
        field: "actualShareValue",
        align: "right",
        headerAlign: "right",
        minWidth: 140,
        renderCell: (params) => localAmountInvestmentWithCurrencyEurLegacy(params.value),
    },
    {
        headerName: t("investments.actualInvestmentsValue"),
        field: "actualValueOfInvestment",
        align: "right",
        headerAlign: "right",
        minWidth: 140,
        renderCell: (params) => localAmountWithCurrencyLegacy(params.value),
    },
    {
        headerName: t("common.profit"),
        field: "profit",
        align: "right",
        minWidth: 120,
        headerAlign: "right",
        renderCell: (params) => nettoProfitHyperLinkFormatter(params.value, getProfitDetailUrl),
    },
];

export const InvestmentDetailTable: FC = () => {
    const { t } = i18n.useTranslation();
    const navigate = useNavigate();
    const data = useSelector(getInvestmentDetailData);
    const hasEditPermission = useHasEditContractPermission();
    const { idObject } = useParams();
    const { canEdit } = userContracts.useGetContractById(idObject);
    const isContractEditable = !useSelector(getIsDipContract) && canEdit;

    const getProfitDetailUrl = (fund: string) =>
        router.getStaticUrl(Pages.CONTRACT_REGULAR, Tabs.REGULAR_PROFIT_DETAIL, { idObject, fund });

    const onSellRequest = () => navigate(router.getStaticUrl(Pages.CONTRACT_REGULAR, Tabs.SELL_SWITCH_FORM, { idObject }));

    return (
        <GridItem xs={12}>
            <DataTable
                icon={"coins"}
                title={t("investments.investmentDetail")}
                headerHeight={60}
                hideFooter
                disableColumnReorder
                pageSize={20}
                getRowId={(row) => row.id}
                rows={data ? data.toArray() : []}
                columns={createColumns(t, getProfitDetailUrl)}
                preContent={
                    hasEditPermission &&
                    isContractEditable && (
                        <ButtonsLayout mb={4}>
                            <Button color="orange" variant="contained" onClick={onSellRequest}>
                                {t("investments.newSellRequest")}
                            </Button>
                        </ButtonsLayout>
                    )
                }
            />
        </GridItem>
    );
};
