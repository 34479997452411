import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { Typography } from "ui-library/atoms";
import { ContentBox, GridItem } from "ui-library/layouts";
import { DataTable } from "ui-library/modules";

import entity from "core/entity";
import fetching from "core/fetching";
import i18n from "core/i18n";
import { DEFAULT_TABLE_PAGE_SIZE, DEFAULT_TABLE_ROWS_OPTIONS } from "app/constants";
import { model } from "core/util";
import {
    localAmountInvestment,
    localAmountInvestmentWithCurrencyEurLegacy,
    localAmountWithCurrencyLegacy,
    localFullDate,
    localPeriod,
} from "core/formatters";
import { amountOperators, codeValueOperators } from "modules/tableFilter";

import { GROSS_AMOUNT_COLUMN, INVESTMENT_FUND_COLUMN, PAYMENT_DATE_COLUMN, TYPE_COLUMN } from "../constants";
import { FinancialOperationsTableFilter } from "../modules";

const columns = (t, languageCode) => [
    {
        field: INVESTMENT_FUND_COLUMN,
        filterOperators: codeValueOperators,
        headerName: t("common.fund"),
        sortable: false,
        flex: 4,
        minWidth: 190,
        renderCell: (params) => model.getLocaleCodeName(params.value, languageCode),
    },
    {
        field: PAYMENT_DATE_COLUMN,
        type: "date",
        headerName: t("investments.paymentDate"),
        flex: 2,
        minWidth: 120,
        renderCell: (params) => localFullDate(params.value),
    },
    {
        field: "investmentPeriod",
        headerName: t("investments.investmentPeriod"),
        flex: 2,
        renderCell: (params) => localPeriod(params.value),
    },
    {
        field: GROSS_AMOUNT_COLUMN,
        filterOperators: amountOperators,
        headerName: t("investments.investmentBuyoutAmount"),
        sortable: false,
        flex: 2,
        minWidth: 120,
        align: "right",
        headerAlign: "right",
        renderCell: (params) => localAmountWithCurrencyLegacy(params.value),
    },
    {
        field: "investmentDate",
        headerName: t("investments.investmentDate"),
        flex: 2,
        renderCell: (params) => localFullDate(params.value),
    },
    {
        field: "pricePerShareAtOperationDateTime",
        headerName: t("investments.sellSwitch.dds.buyoutSharePrice"),
        sortable: false,
        flex: 2,
        minWidth: 140,
        align: "right",
        headerAlign: "right",
        renderCell: (params) => localAmountInvestmentWithCurrencyEurLegacy(params.value),
    },
    {
        field: "numberOfShares",
        headerName: t("pension.dds.numberOfShares"),
        sortable: false,
        flex: 2,
        minWidth: 120,
        align: "right",
        headerAlign: "right",
        renderCell: (params) => localAmountInvestment(params.value),
    },
    {
        field: TYPE_COLUMN,
        filterOperators: codeValueOperators,
        headerName: t("common.actionType"),
        sortable: false,
        flex: 2,
        minWidth: 200,
        renderCell: (params) => model.getLocaleCodeName(params.value, languageCode),
    },
];

export const FinancialOperationsGridItem = ({ entityName }) => {
    const { t } = i18n.useTranslation();
    const languageCode = i18n.getLanguageCode();
    const isFinOperationsLoading = useSelector(fetching.isFetching(entityName, true));
    const isFinOperationsError = useSelector(entity.isError(entityName));
    const financialOperations = useSelector(entity.getData(entityName));
    const filterModel = useSelector(FinancialOperationsTableFilter.getFilterModel);

    const financialOperationsTableData = financialOperations && financialOperations.toArray();

    return (
        <GridItem xs={12}>
            <DataTable
                icon={"chart-bar"}
                title={t("payments.overview")}
                preContent={
                    <ContentBox>
                        <Typography fontSize={14}>{t("common.financialOperationsNotice")}</Typography>
                    </ContentBox>
                }
                headerHeight={96}
                actions={<FinancialOperationsTableFilter.Container />}
                rowHeight={48}
                disableColumnReorder
                pageSize={DEFAULT_TABLE_PAGE_SIZE}
                rowsPerPageOptions={DEFAULT_TABLE_ROWS_OPTIONS}
                getRowId={(row) => row.id}
                rows={financialOperationsTableData}
                columns={columns(t, languageCode)}
                emptyMsg={t("common.noData")}
                errorMsg={t("error.sectionUnavailable")}
                isLoading={isFinOperationsLoading}
                isError={isFinOperationsError}
                filterModel={filterModel}
            />
        </GridItem>
    );
};

FinancialOperationsGridItem.propTypes = {
    entityName: PropTypes.string.isRequired,
};
