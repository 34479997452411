import { ErrorHandlingState, NAME } from "./constants";

const getModel = (state): ErrorHandlingState => state.get(NAME);

export const getServiceErrors = (state) => getModel(state).serviceErrors;

export const getErrorsForService =
    (serviceIdentifier: string): ((state) => string[]) =>
    (state) =>
        getServiceErrors(state)[serviceIdentifier];

export const isServiceInErrorState = (serviceIdentifier: string) => (state) => {
    const errorsForService: string[] = getErrorsForService(serviceIdentifier)(state);
    return errorsForService?.length > 0;
};
