import lifeImage from "ui-library/assets/images/life-pozadie.svg";
import homeImage from "ui-library/assets/images/domcek-pozadie.svg";
import vehicleImage from "ui-library/assets/images/auto-pozadie.svg";
import businessImage from "ui-library/assets/images/business.svg";
import travelImage from "ui-library/assets/images/travel-pozadie.svg";
import investmentsImage from "ui-library/assets/images/invest-pozadie.svg";
import pensionImage from "ui-library/assets/images/dochodok-pozadie.svg";

import { PensionDssFundsChangeType } from "enums";

import { Pages, Tabs } from "routeConstants";
import {
    homeApi,
    lifeApi,
    mutualFundsDirectApi,
    mutualFundsRegularApi,
    pensionDdsApi,
    pensionDpsApi,
    pensionDssApi,
    pensionPpApi,
    travelApi,
    vehicleApi,
} from "serverApi";

import { ProductLine, ProductLineLink } from "types";
import { ProductGroupLvl2 } from "enums";

const lifeSavingsLinks: ProductLineLink[] = [
    {
        msg: "quicklink.holder",
        route: [Pages.CONTRACT_LIFE, Tabs.HOLDER],
    },
    {
        msg: "quicklink.coverages",
        route: [Pages.CONTRACT_LIFE, Tabs.DETAIL],
    },
    {
        msg: "quicklink.actualInvestmentsValue",
        route: [Pages.CONTRACT_LIFE, Tabs.DETAIL],
    },
    {
        msg: "quicklink.payInsurance",
        route: [Pages.ONLINE_PAYMENT, Tabs.PAYMENTS_PAY],
        getQuery: (idObject) => ({
            contractId: idObject,
        }),
    },
    {
        msg: "quicklink.claims",
        route: [Pages.CONTRACT_LIFE, Tabs.CLAIMS],
    },
    {
        msg: "quicklink.agent",
        route: [Pages.CONTRACT_LIFE, Tabs.AGENT],
    },
];

const life: ProductLine = {
    // Accident insurance (LifeRisk)
    type: "lifeInsurance",
    msg: "life.lifeInsurance",
    primaryButton: "insuredEvent",
    topLevelRoute: Pages.CONTRACT_LIFE,
    detailRoute: [Pages.CONTRACT_LIFE, Tabs.OVERVIEW],
    elcomFormRoute: [Pages.CONTRACT_LIFE, Tabs.EDIT_ELECTRONIC_COMMUNICATION_FORM],
    image: lifeImage,
    serverApi: lifeApi.getLifeContractDetail,
    links: [
        {
            msg: "quicklink.holder",
            route: [Pages.CONTRACT_LIFE, Tabs.HOLDER],
        },
        {
            msg: "quicklink.coverages",
            route: [Pages.CONTRACT_LIFE, Tabs.DETAIL],
        },
        {
            msg: "quicklink.payInsurance",
            route: [Pages.ONLINE_PAYMENT, Tabs.PAYMENTS_PAY],
            getQuery: (idObject) => ({
                contractId: idObject,
            }),
        },
        {
            msg: "quicklink.claims",
            route: [Pages.CONTRACT_LIFE, Tabs.CLAIMS],
        },
        {
            msg: "quicklink.agent",
            route: [Pages.CONTRACT_LIFE, Tabs.AGENT],
        },
    ],
};

const home: ProductLine = {
    // Home insurance
    type: "homeInsurance",
    msg: "home.homeInsurance",
    primaryButton: "insuredEvent",
    topLevelRoute: Pages.CONTRACT_HOME,
    detailRoute: [Pages.CONTRACT_HOME, Tabs.OVERVIEW],
    elcomFormRoute: [Pages.CONTRACT_HOME, Tabs.EDIT_ELECTRONIC_COMMUNICATION_FORM],
    image: homeImage,
    serverApi: homeApi.getHomeContractDetail,
    links: [
        {
            msg: "quicklink.holder",
            route: [Pages.CONTRACT_HOME, Tabs.HOLDER],
        },
        {
            msg: "quicklink.coverages",
            route: [Pages.CONTRACT_HOME, Tabs.DETAIL],
        },
        {
            msg: "quicklink.payInsurance",
            route: [Pages.ONLINE_PAYMENT, Tabs.PAYMENTS_PAY],
            getQuery: (idObject) => ({
                contractId: idObject,
            }),
        },
        {
            msg: "quicklink.claims",
            route: [Pages.CONTRACT_HOME, Tabs.CLAIMS],
        },
        {
            msg: "quicklink.agent",
            route: [Pages.CONTRACT_HOME, Tabs.AGENT],
        },
    ],
};

const commonProductLines: { [productGroupLevelTwo: number]: ProductLine } = {
    [ProductGroupLvl2.LIFE_RISK]: life, // Risk life insurance
    [ProductGroupLvl2.LIFE_ONETIME_SAVINGS]: {
        // Single investment life insurance
        ...life,
        links: lifeSavingsLinks,
    },
    [ProductGroupLvl2.LIFE_SAVINGS]: {
        // Investment life insurance
        ...life,
        links: lifeSavingsLinks,
    },
    [ProductGroupLvl2.VEHICLE]: {
        // Vehicle insurance
        type: "vehicleInsurance",
        msg: "vehicle.vehicleInsurance",
        primaryButton: "insuredEvent",
        topLevelRoute: Pages.CONTRACT_VEHICLE,
        detailRoute: [Pages.CONTRACT_VEHICLE, Tabs.OVERVIEW],
        elcomFormRoute: [Pages.CONTRACT_VEHICLE, Tabs.EDIT_ELECTRONIC_COMMUNICATION_FORM],
        image: vehicleImage,
        serverApi: vehicleApi.getVehicleContractDetail,
        links: [
            {
                msg: "quicklink.holder",
                route: [Pages.CONTRACT_VEHICLE, Tabs.HOLDER],
            },
            {
                msg: "quicklink.greenCard",
                route: [Pages.CONTRACT_VEHICLE, Tabs.DOCUMENTS],
            },
            {
                msg: "quicklink.coverages",
                route: [Pages.CONTRACT_VEHICLE, Tabs.DETAIL],
            },
            {
                msg: "quicklink.payInsurance",
                route: [Pages.ONLINE_PAYMENT, Tabs.PAYMENTS_PAY],
                getQuery: (idObject) => ({
                    contractId: idObject,
                }),
            },
            {
                msg: "quicklink.claims",
                route: [Pages.CONTRACT_VEHICLE, Tabs.CLAIMS],
            },
            {
                msg: "quicklink.agent",
                route: [Pages.CONTRACT_VEHICLE, Tabs.AGENT],
            },
        ],
    },
    [ProductGroupLvl2.HOME]: {
        // Home insurance
        ...home,
    },
    [ProductGroupLvl2.BUFT]: {
        // Same as home insurance
        ...home,
        msg: "buft.buftInsurance",
        image: businessImage,
    },
    [ProductGroupLvl2.TRANSPORT]: {
        // Same as home insurance
        ...home,
        msg: "transport.transportInsurance",
        image: businessImage,
    },
    [ProductGroupLvl2.LIABILITY]: {
        // Same as home insurance
        ...home,
        msg: "liability.liabilityInsurance",
        image: businessImage,
    },
    [ProductGroupLvl2.BUSINESS]: {
        // Same as home insurance
        ...home,
        msg: "business.businessInsurance",
        image: businessImage,
    },
    [ProductGroupLvl2.INDUSTRY]: {
        // Same as home insurance
        ...home,
        msg: "business.industryInsurance",
        image: businessImage,
    },
    [ProductGroupLvl2.TRAVEL]: {
        // Travel insurance
        type: "travelInsurance",
        msg: "travel.travelInsurance",
        primaryButton: "insuredEvent",
        topLevelRoute: Pages.CONTRACT_TRAVEL,
        detailRoute: [Pages.CONTRACT_TRAVEL, Tabs.OVERVIEW],
        elcomFormRoute: [Pages.CONTRACT_TRAVEL, Tabs.EDIT_ELECTRONIC_COMMUNICATION_FORM],
        image: travelImage,
        serverApi: travelApi.getTravelContractDetail,
        links: [
            {
                msg: "quicklink.holder",
                route: [Pages.CONTRACT_TRAVEL, Tabs.HOLDER],
            },
            {
                msg: "quicklink.coverages",
                route: [Pages.CONTRACT_TRAVEL, Tabs.DETAIL],
            },
            {
                msg: "quicklink.payInsurance",
                route: [Pages.ONLINE_PAYMENT, Tabs.PAYMENTS_PAY],
                getQuery: (idObject) => ({
                    contractId: idObject,
                }),
            },
            {
                msg: "quicklink.claims",
                route: [Pages.CONTRACT_TRAVEL, Tabs.CLAIMS],
            },
            {
                msg: "quicklink.agent",
                route: [Pages.CONTRACT_TRAVEL, Tabs.AGENT],
            },
        ],
    },
    [ProductGroupLvl2.MUTUAL_FUNDS]: {
        // Mutual Funds Direct Plan
        type: "investment",
        msg: "investments.investment",
        primaryButton: "investmentState",
        topLevelRoute: Pages.CONTRACT_DIRECT,
        detailRoute: [Pages.CONTRACT_DIRECT, Tabs.OVERVIEW],
        elcomFormRoute: [Pages.CONTRACT_DIRECT, Tabs.EDIT_ELECTRONIC_COMMUNICATION_FORM],
        image: investmentsImage,
        serverApi: mutualFundsDirectApi.getMutualFundsDirectContractDetail,
        links: [
            {
                msg: "quicklink.holder",
                route: [Pages.CONTRACT_DIRECT, Tabs.HOLDER],
            },
            {
                msg: "quicklink.actualInvestmentsValue",
                route: [Pages.CONTRACT_DIRECT, Tabs.PORTFOLIO],
            },
            {
                msg: "quicklink.newInvestment",
                route: [Pages.ONLINE_PAYMENT, Tabs.PAYMENTS_PAY],
                getQuery: (idObject) => ({
                    contractId: idObject,
                }),
            },
            {
                msg: "quicklink.sellSwitchRequest",
                route: [Pages.CONTRACT_DIRECT, Tabs.SELL_SWITCH_FORM],
            },
            {
                msg: "quicklink.statement",
                route: [Pages.CONTRACT_DIRECT, Tabs.FINANCIAL_OPERATIONS],
            },
            {
                msg: "quicklink.agent",
                route: [Pages.CONTRACT_DIRECT, Tabs.AGENT],
            },
        ],
    },
    [ProductGroupLvl2.REGULAR_INVESTMENTS]: {
        // Mutual Funds Regular Plan
        type: "investment",
        msg: "investments.investment",
        primaryButton: "investmentState",
        topLevelRoute: Pages.CONTRACT_REGULAR,
        detailRoute: [Pages.CONTRACT_REGULAR, Tabs.OVERVIEW],
        elcomFormRoute: [Pages.CONTRACT_REGULAR, Tabs.EDIT_ELECTRONIC_COMMUNICATION_FORM],
        image: investmentsImage,
        serverApi: mutualFundsRegularApi.getMutualFundsRegularContractDetail,
        links: [
            {
                msg: "quicklink.holder",
                route: [Pages.CONTRACT_REGULAR, Tabs.HOLDER],
            },
            {
                msg: "quicklink.actualInvestmentsValue",
                route: [Pages.CONTRACT_REGULAR, Tabs.PORTFOLIO],
            },
            {
                msg: "quicklink.newInvestment",
                route: [Pages.ONLINE_PAYMENT, Tabs.PAYMENTS_PAY],
                getQuery: (idObject) => ({
                    contractId: idObject,
                }),
            },
            {
                msg: "quicklink.sellSwitchRequest",
                route: [Pages.CONTRACT_REGULAR, Tabs.SELL_SWITCH_FORM],
            },
            {
                msg: "quicklink.statement",
                route: [Pages.CONTRACT_REGULAR, Tabs.FINANCIAL_STATEMENT],
            },
            {
                msg: "quicklink.agent",
                route: [Pages.CONTRACT_REGULAR, Tabs.AGENT],
            },
        ],
    },
    [ProductGroupLvl2.PENSION_PP]: {
        // I. Pillar (PP)
        type: "retirementSavings",
        msg: "pension.retirementSavings",
        primaryButton: "accountBalance",
        topLevelRoute: Pages.CONTRACT_PP,
        detailRoute: [Pages.CONTRACT_PP, Tabs.OVERVIEW],
        elcomFormRoute: [Pages.CONTRACT_PP, Tabs.EDIT_ELECTRONIC_COMMUNICATION_FORM],
        image: pensionImage,
        serverApi: pensionPpApi.getPensionPpContractDetail,
        links: [
            {
                msg: "quicklink.holder",
                route: [Pages.CONTRACT_PP, Tabs.HOLDER],
            },
            {
                msg: "quicklink.actualInvestmentsValue",
                route: [Pages.CONTRACT_PP, Tabs.FINANCIAL_STATEMENT],
            },
            {
                msg: "quicklink.payContribution",
                route: [Pages.ONLINE_PAYMENT, Tabs.PAYMENTS_PAY],
                getQuery: (idObject) => ({
                    contractId: idObject,
                }),
            },
            {
                msg: "quicklink.statement",
                route: [Pages.CONTRACT_PP, Tabs.FINANCIAL_OPERATIONS],
            },
            {
                msg: "quicklink.agent",
                route: [Pages.CONTRACT_PP, Tabs.AGENT],
            },
        ],
    },
    [ProductGroupLvl2.PENSION_DSS]: {
        // II. Pillar (DSS)
        type: "retirementSavings",
        msg: "pension.retirementSavings",
        primaryButton: "accountBalance",
        topLevelRoute: Pages.CONTRACT_DSS,
        detailRoute: [Pages.CONTRACT_DSS, Tabs.OVERVIEW],
        elcomFormRoute: [Pages.CONTRACT_DSS, Tabs.EDIT_ELECTRONIC_COMMUNICATION_FORM],
        image: pensionImage,
        serverApi: pensionDssApi.getPensionDssContractDetail,
        links: [
            {
                msg: "quicklink.holder",
                route: [Pages.CONTRACT_DSS, Tabs.HOLDER],
            },
            {
                msg: "quicklink.actualInvestmentsValue",
                route: [Pages.CONTRACT_DSS, Tabs.FINANCIAL_STATEMENT],
            },
            {
                msg: "quicklink.payVoluntaryContribution",
                route: [Pages.ONLINE_PAYMENT, Tabs.PAYMENTS_PAY],
                getQuery: (idObject) => ({
                    contractId: idObject,
                }),
            },
            {
                msg: "quicklink.statement",
                route: [Pages.CONTRACT_DSS, Tabs.FINANCIAL_OPERATIONS],
            },
            {
                displayCondition: "COMBINED",
                msg: "quicklink.changeInvestments",
                route: [Pages.CONTRACT_DSS, Tabs.PENSION_DSS_FUNDS_CHANGE_FORM],
                params: { changeType: PensionDssFundsChangeType.COMBINED.id },
            },
            {
                displayCondition: "DIVIDED",
                msg: "quicklink.changeInvestments.voluntary",
                route: [Pages.CONTRACT_DSS, Tabs.PENSION_DSS_FUNDS_CHANGE_FORM],
                params: { changeType: PensionDssFundsChangeType.VOLUNTARY.id },
            },
            {
                displayCondition: "DIVIDED",
                msg: "quicklink.changeInvestments.compulsory",
                route: [Pages.CONTRACT_DSS, Tabs.PENSION_DSS_FUNDS_CHANGE_FORM],
                params: { changeType: PensionDssFundsChangeType.COMPULSORY.id },
            },
            {
                msg: "quicklink.agent",
                route: [Pages.CONTRACT_DSS, Tabs.AGENT],
            },
        ],
    },
    [ProductGroupLvl2.LIFE_INSURANCE_OTHER]: life, // Risk life insurance
    [ProductGroupLvl2.ACCIDENT_INSURANCE]: life, // Risk life insurance
    [ProductGroupLvl2.LIFE_INVESTMENT_ACCOUNT]: {
        // Investment life insurance
        ...life,
        links: lifeSavingsLinks,
    },
    [ProductGroupLvl2.LIFE_SAVINGS_18]: {
        // Investment life insurance
        ...life,
        links: lifeSavingsLinks,
    },
    [ProductGroupLvl2.LIFE_SAVINGS_24]: {
        // Investment life insurance
        ...life,
        links: lifeSavingsLinks,
    },
    [ProductGroupLvl2.LIFE_SAVINGS_25]: {
        // Investment life insurance
        ...life,
        links: lifeSavingsLinks,
    },
    [ProductGroupLvl2.LIFE_SAVINGS_26]: {
        // Investment life insurance
        ...life,
        links: lifeSavingsLinks,
    },
    [ProductGroupLvl2.LIFE_SAVINGS_27]: {
        // Investment life insurance
        ...life,
        links: lifeSavingsLinks,
    },
    [ProductGroupLvl2.LIFE_SAVINGS_28]: {
        // Investment life insurance
        ...life,
        links: lifeSavingsLinks,
    },
    [ProductGroupLvl2.LIFE_SAVINGS_29]: {
        // Investment life insurance
        ...life,
        links: lifeSavingsLinks,
    },
};

export const czProductLines: { [productGroupLevelTwo: number]: ProductLine } = {
    ...commonProductLines,
    [ProductGroupLvl2.PENSION_DPS_DDS]: {
        // III. Pillar (DPS)
        type: "retirementSavings",
        msg: "pension.retirementSavings",
        primaryButton: "accountBalance",
        topLevelRoute: Pages.CONTRACT_DPS,
        detailRoute: [Pages.CONTRACT_DPS, Tabs.OVERVIEW],
        elcomFormRoute: [Pages.CONTRACT_DPS, Tabs.EDIT_ELECTRONIC_COMMUNICATION_FORM],
        image: pensionImage,
        serverApi: pensionDpsApi.getPensionDpsContractDetail,
        links: [
            {
                msg: "quicklink.holder",
                route: [Pages.CONTRACT_DPS, Tabs.HOLDER],
            },
            {
                msg: "quicklink.actualInvestmentsValue",
                route: [Pages.CONTRACT_DPS, Tabs.FINANCIAL_STATEMENT],
            },
            {
                msg: "quicklink.payContribution",
                route: [Pages.ONLINE_PAYMENT, Tabs.PAYMENTS_PAY],
                getQuery: (idObject) => ({
                    contractId: idObject,
                }),
            },
            {
                msg: "quicklink.statement",
                route: [Pages.CONTRACT_DPS, Tabs.FINANCIAL_OPERATIONS],
            },
            {
                msg: "quicklink.agent",
                route: [Pages.CONTRACT_DPS, Tabs.AGENT],
            },
        ],
    },
};

export const skProductLines: { [productGroupLevelTwo: number]: ProductLine } = {
    ...commonProductLines,
    [ProductGroupLvl2.PENSION_DPS_DDS]: {
        // III. Pillar (DDS)
        type: "retirementSavings",
        msg: "pension.retirementSavings",
        primaryButton: "accountBalance",
        topLevelRoute: Pages.CONTRACT_DDS,
        detailRoute: [Pages.CONTRACT_DDS, Tabs.OVERVIEW],
        elcomFormRoute: [Pages.CONTRACT_DDS, Tabs.EDIT_ELECTRONIC_COMMUNICATION_FORM],
        image: pensionImage,
        serverApi: pensionDdsApi.getPensionDdsContractDetail,
        links: [
            {
                msg: "quicklink.holder",
                route: [Pages.CONTRACT_DDS, Tabs.HOLDER],
            },
            {
                msg: "quicklink.actualInvestmentsValue",
                route: [Pages.CONTRACT_DDS, Tabs.FINANCIAL_STATEMENT],
            },
            {
                msg: "quicklink.payContribution",
                route: [Pages.ONLINE_PAYMENT, Tabs.PAYMENTS_PAY],
                getQuery: (idObject) => ({
                    contractId: idObject,
                }),
            },
            {
                msg: "quicklink.statement",
                route: [Pages.CONTRACT_DDS, Tabs.FINANCIAL_OPERATIONS],
            },
            {
                msg: "quicklink.changeInvestments",
                route: [Pages.CONTRACT_DDS, Tabs.PENSION_DDS_FUNDS_CHANGE_FORM],
            },
            {
                msg: "quicklink.agent",
                route: [Pages.CONTRACT_DDS, Tabs.AGENT],
            },
        ],
    },
};
