import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { ModalBody, ModalFooter } from "ui-library/modules";
import { Box, ContentBox } from "ui-library/layouts";
import { Button, Loading } from "ui-library/atoms";

import { ModalFormLayout } from "containers/modal";
import {
    Field,
    form,
    getFormFieldValue,
    InputText,
    lettersNumbersBasicInterpunctionCharacters,
    max10Characters,
    max50Characters,
    numberCharacters,
    required,
    SegmentedControl,
    SingleSelect,
} from "core/form";
import i18n from "core/i18n";
import { getLocalizedStatementFrequencyOptions } from "core/localized";

import { RECEIVE_STATEMENT_FIELD, STATEMENT_PERIODICITY_FIELD } from "./constants";

const PensionStatementSettingsForm = ({ canSubmit, handleSubmit, submitting, receiveStatements }) => {
    const { t } = i18n.useTranslation();
    const frequencyOptions = useSelector(getLocalizedStatementFrequencyOptions);
    return (
        <>
            <ModalBody>
                <Loading loading={submitting}>
                    <Box>
                        <ContentBox>
                            <Field name={RECEIVE_STATEMENT_FIELD} component={SegmentedControl} label={t("form.receiveStatement")} />
                        </ContentBox>
                    </Box>

                    {receiveStatements && (
                        <Box>
                            <ContentBox>
                                <Field
                                    name={STATEMENT_PERIODICITY_FIELD}
                                    component={SingleSelect}
                                    label={t("form.statementPeriodicity")}
                                    options={frequencyOptions}
                                    validateStatic={[required]}
                                    placeholder={t("common.choose")}
                                />
                            </ContentBox>
                        </Box>
                    )}
                </Loading>
            </ModalBody>
            <ModalFooter>
                <Button color="blue" variant="contained" onClick={handleSubmit} disabled={!canSubmit}>
                    {t("common.submitChange")}
                </Button>
            </ModalFooter>
        </>
    );
};

PensionStatementSettingsForm.propTypes = {
    canSubmit: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    receiveStatements: PropTypes.bool,
};

PensionStatementSettingsForm.defaultProps = {
    receiveStatements: false,
};

export const createContainer = (formName) =>
    form(formName)((props) => {
        const { t } = i18n.useTranslation();
        const receiveStatements = useSelector(getFormFieldValue(formName, RECEIVE_STATEMENT_FIELD));

        return (
            <ModalFormLayout formName={formName} formTitle={t("form.pensionStatementSettings")} formIcon="contract">
                <PensionStatementSettingsForm receiveStatements={receiveStatements} {...props} />
            </ModalFormLayout>
        );
    });
