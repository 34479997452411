import { app } from "core/util";
import { ACTUAL_STATEMENT, EXTRA_STATEMENT } from "./constants";
import { policyActiveYearOptions } from "./util";

export default (reducerPath, docType, getPolicyBeginDateYear, yearToRemoveFromList, fetchAvailableYears) => {
    const getModel = (state) => state.getIn(reducerPath);

    const getCalculatedAvailableYears = (state) => policyActiveYearOptions(getPolicyBeginDateYear(state), yearToRemoveFromList);
    const getAvailableYears = (state) => getModel(state).get("availableYears");
    const isError = (state) => getAvailableYears(state) === app.ERROR_DATA_VALUE;
    const isAvailableYearListPopulated = (state) =>
        fetchAvailableYears ? !getAvailableYears(state).isEmpty() : !getCalculatedAvailableYears(state).isEmpty();
    const showForm = (state) => docType === EXTRA_STATEMENT || docType === ACTUAL_STATEMENT || isAvailableYearListPopulated(state) || false;
    return {
        getSelectedYear: (state) => getModel(state).get("selectedYear"),
        getAvailableYears,
        isError,
        showForm,
        getMessagesFromState: (state) => getModel(state).get("statementsGenerateMsg"),
        isSubmitting: (state) => getModel(state).get("submitting"),
    };
};
