import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import i18n from "core/i18n";
import {
    DatePicker,
    Field,
    InputText,
    lettersNumbersBasicInterpunctionCharacters,
    max20Characters,
    max50Characters,
    required,
    SingleSelect,
} from "core/form";
import { ContentBox, GridItem, GridLayout, Section } from "ui-library/layouts";

import { SectionDescription, SectionTitle } from "../components";
import { getTranslatedIdTypeOptions } from "../selectors";

const IdentityCardTypeFormBox = ({ idCardDirty }) => {
    const { t } = i18n.useTranslation();
    const idTypeOptions = useSelector(getTranslatedIdTypeOptions);

    return (
        <Section>
            <SectionTitle title={t("holder.identityCard")} />
            <SectionDescription content={t("form.editPolicyHolder.identificationDocuments")} />

            <ContentBox>
                <Field
                    name="identificationCard.identification"
                    component={SingleSelect}
                    label={t("holder.identityCard.type")}
                    options={idTypeOptions}
                />
            </ContentBox>

            <ContentBox>
                <Field
                    name="identificationCard.cardId"
                    component={InputText}
                    label={t("holder.identityCard.number")}
                    // validated async in saga!
                    normalize={[max20Characters]}
                />
            </ContentBox>

            <ContentBox>
                <GridLayout spacing={2}>
                    <GridItem xs={12} md={6}>
                        <Field
                            name="identificationCard.releaseDate"
                            component={DatePicker}
                            label={t("common.dateFrom")}
                            validateStatic={idCardDirty ? [required] : []}
                            disabled={!idCardDirty}
                        />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <Field
                            name="identificationCard.dateOfExpiry"
                            component={DatePicker}
                            label={t("common.dateTo")}
                            validateStatic={idCardDirty ? [required] : []}
                            disabled={!idCardDirty}
                        />
                    </GridItem>
                </GridLayout>
            </ContentBox>

            <ContentBox>
                <Field
                    name="identificationCard.authority"
                    component={InputText}
                    label={t("holder.identityCard.authority")}
                    validateStatic={idCardDirty ? [required] : []}
                    normalize={[lettersNumbersBasicInterpunctionCharacters, max50Characters]}
                    disabled={!idCardDirty}
                />
            </ContentBox>
        </Section>
    );
};

IdentityCardTypeFormBox.propTypes = {
    idCardDirty: PropTypes.bool.isRequired,
};

export default IdentityCardTypeFormBox;
