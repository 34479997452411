import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { GridItem } from "ui-library/layouts";
import { DataTable } from "ui-library/modules";

import {
    AmountShareNumberFormatter,
    CodeNameFormatter,
    CopyCancelActionsFormatter,
    formatLocalizedValueMap,
    FormatMutualFundsAccountPayment,
    localFullDate,
} from "core/formatters";
import i18n, { TFunction } from "core/i18n";
import { useHasEditContractPermission } from "core/hooks";
import { getLocalizedMutualFundsTransactionStatusMap } from "core/localized";
import userContracts from "core/userContracts";

import { Pages } from "routeConstants";
import { MutualFundsTransactionStatusDropdown } from "enums";

import { getFinancialRequestsEntity } from "../selectors";

const createColumns = (t: TFunction, hasEditPermission: boolean, transactionStatusMap, idObject: number) => {
    let columns = [
        {
            headerName: t("investments.creationDate"),
            field: "creationDate",
            minWidth: 110,
            renderCell: (params) => localFullDate(params.value),
        },
        {
            headerName: t("investments.transactionType"),
            field: "transactionType",
            renderCell: (params) => <CodeNameFormatter value={params.value} />,
            minWidth: 60,
        },
        {
            headerName: t("investments.transactionFundFrom"),
            field: "transactionFundFrom",
            minWidth: 170,
            renderCell: (params) => <CodeNameFormatter value={params.value} />,
        },
        {
            headerName: t("investments.transactionFundTo"),
            field: "transactionTo",
            minWidth: 240,
            renderCell: (params) => FormatMutualFundsAccountPayment({ value: params.value }),
        },
        {
            headerName: t("investments.transactionValue"),
            field: "transactionValue",
            renderCell: (params) => <AmountShareNumberFormatter row={params.row} />,
            minWidth: 140,
            align: "right",
            headerAlign: "right",
        },
        {
            headerName: t("investments.transactionStatus"),
            field: "transactionStatus",
            renderCell: (params) => formatLocalizedValueMap(params.value.code, transactionStatusMap),
            minWidth: 120,
        },
        {
            headerName: t("investments.transferName"),
            field: "abc",
            renderCell: (params) => params.row.transactionTo?.transactionName,
            minWidth: 150,
        },
    ];

    if (hasEditPermission) {
        columns.push({
            headerName: t("common.action"),
            field: "action",
            renderCell: (params) => {
                const isCancelEnabled = MutualFundsTransactionStatusDropdown.ACTIVE.statusCodes.includes(params.row.transactionStatus.code);
                return (
                    <CopyCancelActionsFormatter
                        idObject={idObject}
                        requestId={params.row.id}
                        mainPageConst={Pages.CONTRACT_REGULAR}
                        isCancelEnabled={isCancelEnabled}
                    />
                );
            },
            minWidth: 60,
        });
    }

    return columns;
};

export const FinancialRequestsGridItem: FC = () => {
    const { t } = i18n.useTranslation();
    const data = useSelector(getFinancialRequestsEntity);
    const transactionStatusMap = useSelector(getLocalizedMutualFundsTransactionStatusMap);
    const { idObject } = useParams();
    const hasEditPermission = useHasEditContractPermission();
    const contract = userContracts.useGetContractById(idObject);
    const { isInFutureOrTerminated } = userContracts.useGetContractDerivedData(contract);

    return (
        <GridItem xs={12}>
            <DataTable
                icon={"coins"}
                title={t("investments.overviewExistingSellSwitch")}
                headerHeight={60}
                rowHeight={60}
                disableColumnReorder
                pageSize={10}
                rowsPerPageOptions={[5, 10, 15, 20]}
                getRowId={(row) => row.id}
                rows={data ? data.toArray() : []}
                columns={createColumns(t, hasEditPermission && !isInFutureOrTerminated, transactionStatusMap, Number(idObject))}
            />
        </GridItem>
    );
};
