import React from "react";
import { useSelector } from "react-redux";

import { EPIN_PHASE_ISSUE, EPIN_PHASE_PASSWORD, EPIN_PHASE_VERIFICATION } from "./constants";
import { createIssueEpinForm, createPasswordEpinForm, createVerificationEpinForm } from "./containers";

export default (formConstants, selectors, actions) => {
    return () => {
        const epinPhase = useSelector(selectors.getEpinPhase);
        const IssueEpinForm = createIssueEpinForm(formConstants.FORM_EPIN_ISSUE, selectors);
        const PasswordEpinForm = createPasswordEpinForm(formConstants.FORM_EPIN_PASSWORD, selectors);
        const VerificationEpinForm = createVerificationEpinForm(formConstants.FORM_EPIN_VERIFICATION, selectors, actions);

        switch (epinPhase) {
            case EPIN_PHASE_ISSUE:
                return <IssueEpinForm />;
            case EPIN_PHASE_PASSWORD:
                return <PasswordEpinForm />;
            case EPIN_PHASE_VERIFICATION:
                return <VerificationEpinForm />;
            default:
                return <div />;
        }
    };
};
