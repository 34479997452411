import React from "react";
import { useSelector } from "react-redux";

import { SingleSelect } from "core/form";
import localized from "core/localized";

export const AmountFilterOperator = (props) => {
    const amountFilterOptions = useSelector(localized.getLocalizedAmountFilterOperatorOptions);

    return <SingleSelect {...props} options={amountFilterOptions} />;
};
