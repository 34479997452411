import { withTheme } from "@mui/styles";

import { Box } from "ui-library/layouts/Box/Box";
import styled from "styled-components";

export const StyledSubPageLayout = withTheme(styled(Box)`
    background: linear-gradient(180deg, #eef2fa 0%, #ffffff 34.5%);
    padding: 96px 0 64px;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        padding: 48px 0 32px;
    }
`);
