export const createActions = (actionPrefix) => {
    const RESET_FILTER = `${actionPrefix}/RESET_FILTER`;
    const SET_FILTER = `${actionPrefix}/SET_FILTER`;
    const SUBMIT_TABLE_FILTER = `${actionPrefix}/SUBMIT_TABLE_FILTER`;

    return {
        RESET_FILTER,
        SET_FILTER,
        SUBMIT_TABLE_FILTER,

        resetFilter: () => ({
            type: RESET_FILTER,
        }),
        setFilter: (filter) => ({
            type: SET_FILTER,
            payload: filter,
        }),
        submitTableFilter: () => ({
            type: SUBMIT_TABLE_FILTER,
        }),
    };
};
