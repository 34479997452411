import PropTypes from "prop-types";
import * as React from "react";
import { StyledLandingPageLayout } from "ui-library/Styled";

export const LandingPageLayout = ({ children, ...props }) => {
    return <StyledLandingPageLayout {...props}>{children}</StyledLandingPageLayout>;
};

LandingPageLayout.propTypes = {
    children: PropTypes.node,
};
