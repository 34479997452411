import { API_PREFIX } from "app/constants";
import { fetch } from "core/util";
import { LoginResponse } from "model/auth";

const authenticate = (values) => fetch.doPost(`${API_PREFIX}/auth/login`, values, undefined, LoginResponse.fromServer);
const ssoLogin = (values) => fetch.doPost(`${API_PREFIX}/auth/sso-login`, values, undefined, LoginResponse.fromServer);

const resetPasswordStepOneGetOneTimeCode = (values) => fetch.doPost(`${API_PREFIX}/user/reset-password/step-one`, values);
const resetPasswordStepTwoSetNewPassword = (values) => fetch.doPut(`${API_PREFIX}/user/reset-password/step-two`, values);
const resetPasswordStepThreeSubmit = (values) => fetch.doPut(`${API_PREFIX}/user/reset-password/step-three`, values);
const resetPasswordStepThreeNewPin = (values) => fetch.doPost(`${API_PREFIX}/user/reset-password/new-pin`, values);

const logout = () => fetch.doPost(`${API_PREFIX}/auth/logout`);

export default {
    authenticate,
    ssoLogin,
    logout,
    resetPasswordStepOneGetOneTimeCode,
    resetPasswordStepTwoSetNewPassword,
    resetPasswordStepThreeSubmit,
    resetPasswordStepThreeNewPin,
};
