import { withTheme } from "@mui/styles";
import styled from "styled-components";
import { Button } from "@mui/material";
import { Box } from "ui-library/layouts/Box/Box";

const iconColor = (props) => {
    if (props.disabled) {
        return props.theme.palette.buttonDisabled;
    } else {
        if (props.color === "white") {
            return props.variant === "contained" ? props.theme.palette[props.color].contrastText : props.theme.palette.white.main;
        } else if (props.color === "base") {
            return props.variant === "contained" ? props.theme.palette.white.main : props.theme.palette.black.main;
        } else {
            return props.variant === "contained" ? props.theme.palette.white.main : props.theme.palette[props.color].main;
        }
    }
};

const hoverBGColor = (props) => {
    return props.variant === "contained" ? props.theme.palette[props.color].dark : props.theme.palette[props.color].light;
};

const hoverColor = (props) => {
    return props.variant === "outlined" || props.variant === "text" ? props.theme.palette[props.color].dark : "";
};

const borderColor = (props) => {
    if (props.color !== "base") {
        return props.theme.palette[props.color].main;
    } else {
        return props.theme.palette.gray.border;
    }
};

export const StyledButton = withTheme(styled(Button)`
    font-size: 16px;
    line-height: 24px;
    border-radius: ${(props) => props.$borderRadius}px;
    padding: 0 16px;
    height: 42px;
    font-weight: 400;
    border: ${(props) => (!props.$border ? "none" : "")};
    border-color: ${(props) => borderColor(props)};
    box-shadow: none;
    min-width: unset;
    max-width: ${(props) => (props.children === undefined ? "42px" : "unset")};
    background-color: ${(props) => (props.color === "base" ? props.theme.palette.white.main : "")};
    white-space: nowrap;

    //TODO for dinosaurs 2.0
    @media (max-width: 359px) {
        padding: 0 6px;
    }
    @media (max-width: 340px) {
        padding: 0 4px;
    }
    &.MuiButton-sizeLarge {
        padding: 17px 24px;
        height: 54px;
        font-size: 21px;
    }
    &.MuiButton-sizeSmall {
        padding: 0;
    }

    &:hover,
    &:active,
    &:focus {
        box-shadow: none;
        background: ${(props) => hoverBGColor(props)};
        border: ${(props) => (!props.$border ? "none" : "")};
        color: ${(props) => hoverColor(props)};
        svg path {
            fill: ${(props) => hoverColor(props)};
        }
    }

    .MuiButton-startIcon {
        margin: ${(props) => (props.children === undefined ? "0" : "0 8px 0 0")};
    }
    .MuiButton-endIcon {
        margin: ${(props) => (props.children === undefined ? "0" : "0 0 0 8px")};
    }
    svg path {
        fill: ${(props) => iconColor(props)};
        opacity: ${(props) => (props.disabled ? 0.26 : 1)};
    }
    &.Mui-disabled {
        color: ${(props) => props.theme.palette.gray.extraDark};
    }

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 100%;
        line-height: 18px;
        padding: 0 12px;
    }
`);

export const StyledBankIDLabel = withTheme(styled(Box)`
    margin-left: 48px;
    position: relative;
    font-family: "Poppins";
    letter-spacing: 0.01rem;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 100%;
        margin-left: 24px;
    }

    &:before {
        content: "";
        position: absolute;
        left: -24px;
        top: 0;
        bottom: 0;
        height: 100%;
        background: ${(props) => props.theme.palette.white.main};
        width: 1px;
        @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
            left: -12px;
        }
    }
`);
