import { v4 as uuid } from "uuid";

import { model } from "core/util";
import { AmountLegacy } from "../common";

export const HarmonizedFinancialOperation = model.createModelLegacy("HarmonizedFinancialOperation", (json) => ({
    reactKey: uuid(),
    id: json.Id,
    type: json.Type,
    amount: AmountLegacy.fromServer(json.Amount),
    movementDate: json.MovementDate,
    dateOfIdentification: json.DateOfIdentification,
    status: json.status,
}));
