import React from "react";
import PropTypes from "prop-types";

import { ContentBox, Flexbox, Section } from "ui-library/layouts";
import { Button, Loading, Typography } from "ui-library/atoms";
import { FormBlock } from "ui-library/modules";

import coreEnum from "core/enum";
import i18n from "core/i18n";
import { FormWideDisabledProvider } from "core/form";
import pageContext from "core/pageContext";
import { FeatureFlag } from "types";

import AccountSection from "./AccountSection";
import AccountOwnerIdentification from "./AccountOwnerIdentification";
import { SourceSectionRegular } from "./SourceSectionRegular";
import { MeansSectionRegular } from "./MeansSectionRegular";

export const InnerContainerRegular = ({
    handleSubmit,
    submitting,
    isOtpVisible,
    otpWrongNumberErrorExists,
    onOtpBack,
    isCancelling,
    OtpModule,
    formName,
    canSubmit,
    onBack,
    isGenNextSelector,
    sellScope,
    getSellScopeOptions,
    getSourceFundOptions,
    getLocalizedFundSellMeansRegular,
    getShouldCheckIbanCountry,
}) => {
    const { t } = i18n.useTranslation();
    const isOwnerVerifyFeatureEnabled = pageContext.useIsFeatureFlagEnabled(FeatureFlag.MUTUAL_FUNDS_ACCOUNT_OWNER_VERIFY);

    return (
        <FormBlock>
            <Loading loading={submitting}>
                {!isOtpVisible && (
                    <>
                        <FormWideDisabledProvider value={isCancelling}>
                            <SourceSectionRegular
                                formName={formName}
                                getSellScopeOptions={getSellScopeOptions}
                                getSourceFundOptions={getSourceFundOptions}
                            />

                            <MeansSectionRegular
                                formName={formName}
                                getLocalizedFundSellMeansRegular={getLocalizedFundSellMeansRegular}
                                isGenNextSelector={isGenNextSelector}
                                sellScope={sellScope}
                            />

                            <ContentBox>
                                <Typography variant="h3">{t("investments.sellSwitch.target")}</Typography>
                            </ContentBox>
                            <ContentBox>
                                <Typography>{t("investments.sellSwitch.accountText")}</Typography>
                            </ContentBox>
                            <AccountSection bankListType={coreEnum.BANK_LIST} getShouldCheckIbanCountry={getShouldCheckIbanCountry} />
                            {isOwnerVerifyFeatureEnabled && <AccountOwnerIdentification formName={formName} />}
                        </FormWideDisabledProvider>

                        <Section>
                            <ContentBox>
                                <Typography Typography fontSize={12} markDown={t("investments.sellSwitch.mutualFundsRegular.footnote")} />
                            </ContentBox>
                        </Section>
                    </>
                )}

                {isOtpVisible && (
                    <OtpModule.Container
                        formName={formName}
                        // ErrorComponent={OtpWrongNumberDirect}
                    />
                )}
            </Loading>

            <Flexbox gap={2} justifyContent="space-between">
                <Button onClick={isOtpVisible && !otpWrongNumberErrorExists ? onOtpBack : onBack} variant="outlined" color="blue">
                    {isOtpVisible && !otpWrongNumberErrorExists ? t("form.otp.backToFirstStep") : t("common.back")}
                </Button>
                <Button onClick={handleSubmit} variant="contained" color="blue" disabled={!canSubmit || otpWrongNumberErrorExists}>
                    {isCancelling ? t("investments.sellSwitch.cancel") : t("common.send")}
                </Button>
            </Flexbox>
        </FormBlock>
    );
};

InnerContainerRegular.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    OtpModule: PropTypes.object.isRequired,
    formName: PropTypes.string.isRequired,
    canSubmit: PropTypes.bool.isRequired,
    isOtpVisible: PropTypes.bool.isRequired,
    otpWrongNumberErrorExists: PropTypes.bool.isRequired,
    getSourceFundOptions: PropTypes.func.isRequired,
    getFundSellTypeOptions: PropTypes.func.isRequired,
    getLocalizedFundSellMeansRegular: PropTypes.func.isRequired,
    getShouldCheckIbanCountry: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
    onOtpBack: PropTypes.func.isRequired,
};
