import { call, put, select } from "redux-saga/effects";
import { change } from "redux-form/immutable";

import { formWrapper, asyncValidateFields, asyncFieldValidator, required } from "core/form";
import { fetch, sentry } from "core/util";
import errorHandling, { OTP_WRONG_MOBILE_NUMBER_FIN_OPERATIONS, OTP_WRONG_MOBILE_ERROR_CODE } from "core/errorHandling";
import { OTP_CODE_FIELD_NAME, OTP_ID_FIELD_NAME } from "modules/otp";
import { contractDataChangeApi, validationApi } from "serverApi";
import userInfo from "core/userInfo";
import { addOtpFormSuccess, resetOtpFormSuccess } from "core/form/actions";

export function* createSaga(formName, idObject, activeOperationsPhoneNumberSelector, setResent, setPhoneNumber) {
    try {
        yield call(formSaga(formName, activeOperationsPhoneNumberSelector, setResent, setPhoneNumber), idObject);
    } catch (e) {
        sentry.captureException(e);
        yield put(errorHandling.addServiceError(formName, e.identifier));
    }
}

const formSaga = (formName, activeOperationsPhoneNumberSelector, setResent, setPhoneNumber) =>
    formWrapper(formName, {
        *initialize() {
            yield call(userInfo.checkUserVerified);
            yield put(errorHandling.removeServiceErrors(formName));
            yield put(resetOtpFormSuccess([formName]));

            const newActiveOperationsPhoneNumber = yield select(activeOperationsPhoneNumberSelector);
            return {
                newActiveOperationsPhoneNumber: newActiveOperationsPhoneNumber || null,
            };
        },
        *save(values, idObject) {
            try {
                const result = yield call(contractDataChangeApi.updateActiveOperationsPhoneNumber, {
                    idObject,
                    newActiveOperationsPhoneNumber: values.get("newActiveOperationsPhoneNumber"),
                    otpId: values.get(OTP_ID_FIELD_NAME),
                    otpCode: values.get(OTP_CODE_FIELD_NAME),
                });
                return result ? { otpId: result.otp_id, phone: result.phone_number } : {};
            } catch (e) {
                if (e.status === fetch.BAD_REQUEST && e.response.otpErrorCode === OTP_WRONG_MOBILE_ERROR_CODE) {
                    yield put(errorHandling.addServiceError(OTP_WRONG_MOBILE_NUMBER_FIN_OPERATIONS, e.identifier));
                    // throw empty precognition failed error to stop form submission
                    throw new fetch.EmptyPreconditionFailedError();
                }
                throw e;
            }
        },
        *success({ otpId, phone }) {
            if (otpId) {
                yield put(change(formName, OTP_ID_FIELD_NAME, otpId));
                yield put(setPhoneNumber(phone));
            } else {
                yield put(addOtpFormSuccess(formName));
            }
        },
        *asyncValidation(values, field) {
            return yield call(
                asyncValidateFields(asyncFieldValidator(["newActiveOperationsPhoneNumber"], [required, validationApi.validatePhone])),
                field,
                values,
            );
        },
    });
