import React from "react";
import { useSelector } from "react-redux";

import { formatPercentage } from "core/formatters";
import i18n from "core/i18n";
import { DataTable } from "ui-library/modules";
import { ContentBox } from "ui-library/layouts";

import { getInvestmentStrategyDataAxa } from "../../selectors";

const getColumns = (t) => [
    {
        field: "tariff",
        headerName: t("common.tariff"),
        sortable: false,
        flex: 2,
    },
    {
        field: "fundName",
        headerName: t("investments.fundName"),
        sortable: false,
        flex: 2,
    },
    {
        field: "percentage",
        headerName: t("common.percent"),
        sortable: false,
        flex: 0.5,
        renderCell: (params) => formatPercentage(params.value),
    },
];

export const AxaInvestmentsStrategyTable = () => {
    const { t } = i18n.useTranslation();

    const investmentsStrategy = useSelector(getInvestmentStrategyDataAxa);
    const mappedInvestmentsStrategy = investmentsStrategy && investmentsStrategy.toArray();

    return (
        <ContentBox bigger>
            <DataTable
                disableColumnReorder
                hideFooter
                withWrapper={false}
                headerHeight={62}
                getRowId={(row) => row.fundCode}
                rows={mappedInvestmentsStrategy}
                columns={getColumns(t)}
            />
        </ContentBox>
    );
};
