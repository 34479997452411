import { createSelector } from "reselect";
import { List, Map, Set } from "immutable";

import { localCurrency } from "core/formatters";

import { app } from "core/util";
import { ProductPaymentAmountName, PaymentAmountType } from "enums";
import i18n from "core/i18n";
import userContracts from "core/userContracts";

import { NAME } from "./constants";

const COMBINED_AMOUNT_DROPDOWN_MSG = "payments.amountCombined.dropdown";
const COMBINED_AMOUNT_TEXT_MSG = "payments.amountCombined.text";
const getModel = app.createGetModel(NAME);

export const getContractId = (state) => getModel(state).get("contractId");
export const getProductGroupNameUrlParam = (state) => getModel(state).get("productGroup");
export const getFundId = (state) => getModel(state).get("fundId");
// export const getContracts = (state) => getModel(state).get('contracts');
export const getFunds = (state) => getModel(state).get("funds");
export const getPaymentInfo = (state) => getModel(state).get("paymentInfo");
export const getPaymentMethod = (state) => getModel(state).get("paymentMethod");
export const hasData = (state) => !!getPaymentInfo(state);
export const getIsUniqaContract = (state) => getPaymentInfo(state)?.get("isUniqaContract");
const getCanPayOnline = (state) => hasData(state) && getPaymentInfo(state).get("canPayOnline");
export const getDebtAmount = (state) => getPaymentInfo(state)?.get("debtAmount");
export const getDebtAmountValue = (state) => getDebtAmount(state)?.value;
const getAdvancedSchedulerAmount = (state) => getPaymentInfo(state)?.get("advancedSchedulerAmount");
const getAdvancedSchedulerAmountValue = (state) => getAdvancedSchedulerAmount(state)?.value;
const getCombinedAmount = (state) => getPaymentInfo(state)?.get("combinedAmount");
const getCombinedAmountValue = (state) => getCombinedAmount(state)?.value;
export const getIsAmountMultipleChoice = (state) => !!getCombinedAmountValue(state);

const getCurrencyCode = createSelector(
    getDebtAmount,
    getAdvancedSchedulerAmount,
    getCombinedAmount,
    (debtAmount, advancedSchedulerAmount, combinedAmount) => {
        const currencyCodeSet = Set([debtAmount, advancedSchedulerAmount, combinedAmount]);
        return currencyCodeSet
            .filter((item) => item != null)
            .filter((item) => item.currencyCode != null)
            .map((item) => item.currencyCode)
            .first();
    },
);

export const getAmountType = (state) => {
    const amountType = getModel(state).get("amountType");
    if (amountType) {
        return amountType;
    } else if (getIsAmountMultipleChoice(state)) {
        return null;
    } else if (getDebtAmountValue(state)) {
        return PaymentAmountType.DEBT;
    } else if (getAdvancedSchedulerAmountValue(state)) {
        return PaymentAmountType.ADVANCED;
    }
    return null;
};

const getAmountValueObj = createSelector(
    getDebtAmount,
    getAdvancedSchedulerAmount,
    getCombinedAmount,
    (debtAmount, advancedSchedulerAmount, combinedAmount) => ({
        [PaymentAmountType.DEBT]: debtAmount?.value,
        [PaymentAmountType.ADVANCED]: advancedSchedulerAmount?.value,
        [PaymentAmountType.COMBINED]: combinedAmount?.value,
    }),
);

const getTranslatedLabels = i18n.createGetLocalizedEnum(ProductPaymentAmountName, "amountMsg", "debtAmountMsg");

const getProductGroupName = (state) => getPaymentInfo(state)?.get("productGroupName");

const createGetAmountLabelObj = (combinedAmountLabelMsg) =>
    createSelector(
        getProductGroupName,
        getTranslatedLabels,
        (state) => i18n.translateDirectly(combinedAmountLabelMsg),
        (productGroupName, translatedLabelEnum, combinedAmountLabelTranslated) => {
            const result = translatedLabelEnum.filter((item) => item.get("id") === productGroupName).first();

            return {
                [PaymentAmountType.DEBT]: result.debtAmountMsg,
                [PaymentAmountType.ADVANCED]: result.amountMsg,
                [PaymentAmountType.COMBINED]: combinedAmountLabelTranslated,
            };
        },
    );

const getAmountDropdownLabelObj = createGetAmountLabelObj(COMBINED_AMOUNT_DROPDOWN_MSG);
const getAmountTextLabelObj = createGetAmountLabelObj(COMBINED_AMOUNT_TEXT_MSG);

export const getAmountTextLabel = createSelector(getAmountType, getAmountTextLabelObj, (amountType, amountLabelMsgObj) =>
    amountType ? amountLabelMsgObj[amountType] : null,
);

export const getAmountValue = createSelector(getAmountType, getAmountValueObj, (amountType, amountValueObj) =>
    amountType ? amountValueObj[amountType] : null,
);

export const getAmountObj = createSelector(getAmountValue, getCurrencyCode, (amountValue, currencyCode) =>
    amountValue
        ? Map({
              value: amountValue,
              currencyCode,
          })
        : null,
);

const formatDropdownAmountLabel = (amountMsg, amountValue, currency) => `${amountMsg}: ${amountValue} ${localCurrency(currency)}`;

export const getAmountOptions = createSelector(
    getCurrencyCode,
    getAmountValueObj,
    getAmountDropdownLabelObj,
    (currencyCode, amountValueObj, amountLabelMsgObj) =>
        List(
            Object.keys(amountLabelMsgObj).map((key) =>
                Map({
                    value: key,
                    label: formatDropdownAmountLabel(amountLabelMsgObj[key], amountValueObj[key], currencyCode),
                }),
            ),
        ),
);

export const getAccountNumber = (state) => {
    const fundId = getFundId(state);
    if (fundId) {
        return findFund(state).accountNumber;
    } else {
        return getPaymentInfo(state).accountNumber;
    }
};
export const getSpecificSymbol = (state) => getPaymentInfo(state).specificSymbol;
export const getVariableSymbol = (state) => getPaymentInfo(state).variableSymbol;

const findFund = createSelector(
    (state) => getPaymentInfo(state).fundsToInvest,
    getFundId,
    (funds, fundId) => funds.find((item) => item.fund.code === fundId),
);

export const getSkQrCode = createSelector(getFundId, findFund, getAmountType, getPaymentInfo, (fundId, fund, amountType, paymentInfo) => {
    if (fundId) {
        return fund.skQrCode;
    } else if (!amountType) {
        return paymentInfo.get("advancedSchedulerSkQrCode");
    } else if (amountType === PaymentAmountType.ADVANCED) {
        return paymentInfo.get("advancedSchedulerSkQrCode");
    } else if (amountType === PaymentAmountType.DEBT) {
        return paymentInfo.get("debtSkQrCode");
    } else {
        return paymentInfo.get("combinedAmountSkQrCode");
    }
});

export const hasDataAndCanPayOnline = (state) =>
    !!(getCanPayOnline(state) && (getDebtAmountValue(state) || getAdvancedSchedulerAmountValue(state)));
