import { List } from "immutable";
import { combineReducers } from "redux-immutable";

import { app } from "core/util";
import { ContractListContentType } from "./constants";

import { SET_CLIENT_DOCUMENTS, SET_CONTRACT_ID_FOR_PAYMENT, SET_DISPLAYED_CONTENT_TYPE } from "./actions";

export default combineReducers({
    data: combineReducers({
        clientDocuments: app.createDataReducerWithError(SET_CLIENT_DOCUMENTS, List()),
    }),
    appState: combineReducers({
        contractIdForPayment: app.createDataReducer(SET_CONTRACT_ID_FOR_PAYMENT, null),
        displayedContentType: app.createDataReducer(SET_DISPLAYED_CONTENT_TYPE, ContractListContentType.ACTIVE_CONTRACTS),
    }),
});
