import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { Option, Select, Typography } from "ui-library/atoms";
import { Box, ContentBox, Flexbox, GridItem } from "ui-library/layouts";
import { InfoPanel } from "ui-library/modules";

import i18n from "core/i18n";
import router from "core/router";
import { Tabs } from "routeConstants";
import { NonLifeInsuranceCoverage } from "./NonLifeInsuranceCoverage";

export const NonLifeCoveragesWithSelect = ({
    topRoute,
    idObject,
    getDetailSelectedCoverage,
    getInsurancePackagesOptions,
    getSelectedProductPackage,
    setDetailSelectedCoverage,
}) => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();
    const insurancePackagesOptions = useSelector(getInsurancePackagesOptions);
    const actualChooseSelection = useSelector(getDetailSelectedCoverage);
    const selectedProductPackage = useSelector(getSelectedProductPackage);
    const link = router.getStaticUrl(topRoute, Tabs.DOCUMENTS, { idObject });

    return (
        <GridItem xs={12}>
            <InfoPanel
                icon={"chart-bar"}
                title={
                    <Flexbox alignItems="center" gap={1}>
                        {t("insurance.coverages")}
                    </Flexbox>
                }
            >
                <Box>
                    {insurancePackagesOptions?.size > 1 && (
                        <ContentBox>
                            <Select
                                value={actualChooseSelection}
                                onChange={(event) => dispatch(setDetailSelectedCoverage(event.target.value))}
                                placeholder={t("common.chooseInsurance")}
                                label={t("insurance.insurance")}
                                id="selectOther"
                                fullWidth
                            >
                                {actualChooseSelection && (
                                    <Option key="" value="">
                                        {t("common.noneSelected")}
                                    </Option>
                                )}
                                {insurancePackagesOptions &&
                                    insurancePackagesOptions.map((insuranceOption) => (
                                        <Option key={insuranceOption.value} value={insuranceOption.value}>
                                            {insuranceOption.label}
                                        </Option>
                                    ))}
                            </Select>
                        </ContentBox>
                    )}

                    {actualChooseSelection !== "" && (
                        <>
                            <NonLifeInsuranceCoverage productPackage={selectedProductPackage} />
                            <ContentBox mt={3}>
                                <Typography markDown={t("insurance.coveragesNote1")} />
                            </ContentBox>
                            <ContentBox>
                                <Typography markDown={t("insurance.coveragesNote2", { link })} internalAppLink />
                            </ContentBox>
                        </>
                    )}
                </Box>
            </InfoPanel>
        </GridItem>
    );
};

NonLifeCoveragesWithSelect.propTypes = {
    idObject: PropTypes.number.isRequired,
    topRoute: PropTypes.string.isRequired,
    getDetailSelectedCoverage: PropTypes.func.isRequired,
    getInsurancePackagesOptions: PropTypes.func.isRequired,
    getSelectedProductPackage: PropTypes.func.isRequired,
    setDetailSelectedCoverage: PropTypes.func.isRequired,
};
