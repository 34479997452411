import React from "react";

import { Link } from "ui-library/atoms";

import { FundSellTarget } from "enums";
import { fn } from "core/util";
import i18n from "core/i18n";

export const FinancialOperationDetailLinkFormatter = ({ operation, getOperationBuyDetailUrl, getOperationSellDetailUrl }) => {
    const { t } = i18n.useTranslation();
    if (!operation || fn.isEmpty(operation.get("operationId"))) {
        return "";
    }
    if (operation.get("operationType") === FundSellTarget.SELL.id) {
        return <Link to={getOperationSellDetailUrl(operation.get("operationId"))}>{t("common.detail")}</Link>;
    } else if (operation.get("operationType") === FundSellTarget.SWITCH.id) {
        return "";
    } else {
        return <Link to={getOperationBuyDetailUrl(operation.get("operationId"))}>{t("common.detail")}</Link>;
    }
};
