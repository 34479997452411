import React from "react";
import { useSelector } from "react-redux";

import { Button, Container, Loading, Typography, UnorderedList } from "ui-library/atoms";
import { ContentBox, Flexbox } from "ui-library/layouts";
import { FormBlock } from "ui-library/modules";

import i18n from "core/i18n";
import {
    acceptedFileSizeValidator,
    acceptedFileTypesValidator,
    Field,
    FileUpload,
    form,
    getFormFieldValue,
    InputText,
    max20Characters,
    personalDataConsentRequired,
    phoneNumberCharacters,
    SegmentedControl,
} from "core/form";
import userInfo from "core/userInfo";
import { FullPageFormLayout } from "containers/fullPageForm";
import { BackToVerificationGridItemButton } from "./components";

const UserVerificationForm = ({ canSubmit, handleSubmit, submitting, formName }) => {
    const { t } = i18n.useTranslation();

    const isClientPerson = useSelector(userInfo.isClientPerson);
    const getText = () => (isClientPerson ? t("verifyUser.intro") : t("verifyUser.introPO"));

    const files = useSelector(getFormFieldValue(formName, "files"));
    const fileUploaded = typeof files !== "undefined" && files.size > 0;

    return (
        <>
            <Container maxWidth="md">
                <Loading fullPage loading={submitting} />
                <FormBlock>
                    <>
                        <ContentBox>
                            <Typography variant="h3">{t("verifyUser.subtitle.uploadDocument")}</Typography>
                        </ContentBox>
                        <ContentBox>
                            <Typography variant="caption" markDown={getText()} />
                        </ContentBox>
                        <ContentBox>
                            <Typography color="gray" variant="caption">
                                {t("common.attachmentSpecification")}
                            </Typography>
                        </ContentBox>
                        <ContentBox>
                            <UnorderedList compact={true}>
                                <Typography variant="caption">{t("userSettings.verifyUser.maxFourFiles")}</Typography>
                                <Typography variant="caption">{t("userSettings.verifyUser.maxFileSize")}</Typography>
                                <Typography variant="caption">{t("userSettings.verifyUser.acceptedFileTypes")}</Typography>
                            </UnorderedList>
                        </ContentBox>
                        <ContentBox>
                            <Field
                                name="files"
                                component={FileUpload}
                                validateStatic={[acceptedFileTypesValidator, acceptedFileSizeValidator]}
                                maxUploadedFiles={4}
                            />
                        </ContentBox>
                        {fileUploaded && (
                            <ContentBox>
                                <Field
                                    checkboxLabel={t("common.personalDataConsentRequired")}
                                    name="personalDataConsent"
                                    component={SegmentedControl}
                                    validateStatic={[personalDataConsentRequired]}
                                />
                                <Typography variant="caption" markDown={t("common.personalDataConsent")} />
                            </ContentBox>
                        )}
                        <ContentBox mt={5}>
                            <Typography variant="h3">{t("verifyUser.subtitle.verifyMobilePhone")}</Typography>
                        </ContentBox>
                        <ContentBox>
                            <Typography variant="caption" markDown={t("verifyUser.stepTwo")} />
                        </ContentBox>
                        <ContentBox>
                            <Field
                                name="phone"
                                component={InputText}
                                label={t("holder.mobile")}
                                // validated async in saga!
                                normalize={[phoneNumberCharacters, max20Characters]}
                                preventDefaultNormalization
                            />
                        </ContentBox>
                        <ContentBox mb={4}>
                            <Typography variant="caption" color="gray">
                                {t("verifyUser.stepTwo.additionalInfo")}
                            </Typography>
                        </ContentBox>
                    </>
                    <Flexbox gap={2} justifyContent="space-between">
                        <BackToVerificationGridItemButton />
                        <Button onClick={handleSubmit} variant="contained" color="blue" disabled={!canSubmit || submitting}>
                            {t("common.send")}
                        </Button>
                    </Flexbox>
                </FormBlock>
            </Container>
        </>
    );
};

export const createContainer = (formName) =>
    form(formName, { asyncValidationFields: ["phone"] })((props) => {
        const { t } = i18n.useTranslation();
        return (
            <FullPageFormLayout formName={formName} formTitle={t("holder.verification")} BackButton={<BackToVerificationGridItemButton />}>
                <UserVerificationForm formName={formName} {...props} />
            </FullPageFormLayout>
        );
    });
