import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { List } from "immutable";
import { arrayPush } from "redux-form/immutable";

import { Box, ContentBox, GridItem, GridLayout, Section } from "ui-library/layouts";
import { Button, HorizontalLine, Typography } from "ui-library/atoms";
import useBreakpoints from "ui-library/theme/breakpoints";

import i18n from "core/i18n";
import { equalsHundred, Field, FormErrorBox, getFormFieldValue, InputText } from "core/form";

import { FUND_PERCENTAGE_BREAKDOWN, MAX_FUND_COUNT, SUM_PERCENTAGE_FIELD } from "../constants";
import { createEmptyFundPercentageRow } from "../util";

import { FundWithPercentage } from "./FundWithPercentage";

export const IndividualProfileComponent = ({ formName, availableFundsProfileChangeOptionsSelector }) => {
    const { t } = i18n.useTranslation();
    const fundPercentageBreakdown = useSelector(getFormFieldValue(formName, FUND_PERCENTAGE_BREAKDOWN)) || List();
    const fundPercentageRowCount = fundPercentageBreakdown ? fundPercentageBreakdown.size : 0;

    const dispatch = useDispatch();
    const addNewFundPercentageRow = () => dispatch(arrayPush(formName, FUND_PERCENTAGE_BREAKDOWN, createEmptyFundPercentageRow()));

    const isSMBreak = useBreakpoints("sm");

    return (
        <>
            <ContentBox mb={1}>
                <Typography>{t("investments.profileChange.individualProfileText")}</Typography>
                <Typography color="red">{t("investments.profileChange.individualProfileNotice")}</Typography>
            </ContentBox>
            <FormErrorBox form={formName} />
            {fundPercentageBreakdown.map((fundPercentage, index) => (
                <FundWithPercentage
                    key={index}
                    index={index}
                    formName={formName}
                    availableFundsProfileChangeOptionsSelector={availableFundsProfileChangeOptionsSelector}
                />
            ))}
            <HorizontalLine />
            <GridLayout spacing={0.5}>
                <GridItem xs={12} sm={4} md={4}>
                    {fundPercentageRowCount < MAX_FUND_COUNT && (
                        <Section>
                            <Button variant="outlined" startIcon="plus" color="blue" onClick={addNewFundPercentageRow}></Button>
                        </Section>
                    )}
                </GridItem>
                <GridItem xs={12} sm={2} md={2}>
                    <ContentBox mt={2}>
                        <Box display={"flex"} flexDirection={"column"} alignItems={isSMBreak ? "end" : "start"}>
                            <Typography fontSize={16}>{t("common.overall")}</Typography>
                        </Box>
                    </ContentBox>
                </GridItem>
                <GridItem xs={4} sm={2} md={2}>
                    <Field
                        name={SUM_PERCENTAGE_FIELD}
                        component={InputText}
                        disableSuccessTick // validated on BE, don't show success prematurely to user
                        validateStatic={[equalsHundred]}
                        validateDisabled
                    />
                </GridItem>
                <GridItem xs={1} sm={1} md={1}>
                    <ContentBox mt={2}>
                        <Typography fontSize={16}>%</Typography>
                    </ContentBox>
                </GridItem>
                <GridItem xs={7} sm={3} md={3}>
                    <ContentBox mt={0.75}>
                        <Typography fontSize={14}>{t("common.sumShouldEqualHundredPercent")}</Typography>
                    </ContentBox>
                </GridItem>
            </GridLayout>
        </>
    );
};
