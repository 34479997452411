import React from "react";
import { useSelector } from "react-redux";

import { Container, Typography } from "ui-library/atoms";
import { ContentBox, Section, StaticPageLayout } from "ui-library/layouts";

import i18n from "core/i18n";
import impersonate from "core/impersonate";

export const InnerContainer = () => {
    const { t } = i18n.useTranslation();

    const unsuccessfulReason = useSelector(impersonate.getUnsuccessfulReason);
    const unsuccessfulReasonTranslation = t(`page.impersonate.reason.${unsuccessfulReason}`);
    const unsuccessfulReasonText = unsuccessfulReasonTranslation
        ? unsuccessfulReasonTranslation
        : t("page.impersonate.reason.BAD_CREDENTIALS");

    return (
        <StaticPageLayout>
            <>
                <Container maxWidth="md">
                    <Section>
                        <Typography variant="h1">{t("page.impersonate.error.title")}</Typography>
                    </Section>
                </Container>
                <Container maxWidth="md">
                    <ContentBox mb={10}>
                        <Typography component="p" markDown={unsuccessfulReasonText} />
                    </ContentBox>
                </Container>
                <ContentBox mb={60} />
            </>
        </StaticPageLayout>
    );
};
