import React from "react";

import { ContentBox } from "ui-library/layouts";
import { Icon, Typography } from "ui-library/atoms";

import i18n from "core/i18n";

export const GdprNotAccepted = () => {
    const { t } = i18n.useTranslation();
    return (
        <>
            <ContentBox>
                <Typography variant={"p"} component={"p"}>
                    {t("userSettings.gdprSubheader")}
                </Typography>
            </ContentBox>
            <ContentBox>
                <Typography markDown={t("userSettings.gdpr")} />
            </ContentBox>
            <ContentBox>
                <Typography variant={"p"} component={"p"} color={"orange"}>
                    <Icon icon={"alert"} color={"orange"} /> {t("userSettings.gdpr.consentNotAgreed")}
                </Typography>
            </ContentBox>
        </>
    );
};
