import { Record } from "immutable";

class DdsDssFundType extends Record({ id: null, msg: null }) {}

export default Object.freeze({
    COMPULSORY: new DdsDssFundType({
        id: "COMPULSORY",
        msg: "pension.compulsory",
    }),
    VOLUNTARY: new DdsDssFundType({
        id: "VOLUNTARY",
        msg: "pension.voluntary",
    }),
});
