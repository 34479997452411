import { nanoid } from "@reduxjs/toolkit";

import { model } from "core/util";
import { PensionBeneficiary as PensionBeneficiaryType, PensionBeneficiaryDto } from "types";

import { LegalEntity } from "../common";
import { PensionBeneficiaryShare } from "./PensionBeneficiaryShare";

export const PensionBeneficiary = model.createModel<PensionBeneficiaryDto, PensionBeneficiaryType>((json) => ({
    reactKey: nanoid(),
    beneficiaryShare: PensionBeneficiaryShare.fromServer(json.BeneficiaryShare),
    beneficiaryEntity: LegalEntity.fromServer(json.BeneficiaryEntity),
}));
