import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Button } from "ui-library/atoms";
import { DataTable } from "ui-library/modules";
import { Box, GridItem, GridLayout } from "ui-library/layouts";

import { localAmountWithCurrencyLegacy, localFullDate } from "core/formatters";
import pageContext from "core/pageContext";
import i18n, { TFunction } from "core/i18n";
import router from "core/router";
import { Pages, Tabs } from "routeConstants";
import fetching from "core/fetching";

import { ENTITY_FINANCIAL_OPERATION_DETAIL } from "../constants";
import { getFinancialOperationDetailSell, getIdObject } from "../selectors";
import { FundPaymentFormatter } from "../formatters";

const getColumns = (t: TFunction, siteId: string) => [
    {
        headerName: t("investments.transactionFundFrom"),
        field: "transactionFundFrom",
        minWidth: 250,
    },
    {
        headerName: t("common.account"),
        field: "transactionTo",
        renderCell: (params) => <FundPaymentFormatter value={params.value} country={siteId} />,
        minWidth: 160,
    },
    {
        headerName: t("payments.variableSymbol"),
        field: "variableSymbol",
        minWidth: 120,
        // align: "right",
    },
    {
        headerName: t("payments.specificSymbol"),
        field: "specificSymbol",
        align: "right",
        minWidth: 90,
    },
    {
        headerName: t("investments.sellSwitch.date"),
        field: "transactionDate",
        renderCell: (params) => localFullDate(params.value),
        minWidth: 110,
    },
    {
        headerName: t("account.amountTotal"),
        field: "payment",
        renderCell: (params) => localAmountWithCurrencyLegacy(params.value),
        align: "right",
        headerAlign: "right",
        minWidth: 110,
    },
];

export const FinancialOperationDetailSell: FC = () => {
    const { t } = i18n.useTranslation();
    const isDetailLoading = useSelector(fetching.isFetching(ENTITY_FINANCIAL_OPERATION_DETAIL, true));
    const sellData = useSelector(getFinancialOperationDetailSell);
    const { idObject } = useParams();
    const siteId = useSelector(pageContext.getSiteId);
    const navigate = useNavigate();
    return (
        <GridLayout defaultSpacing>
            <GridItem xs={12}>
                <DataTable
                    icon={"coins"}
                    title={t("investments.investmentDetail")}
                    getRowId={(row) => row.id}
                    pageSize={10}
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    // @ts-ignore
                    rows={sellData ? sellData.toArray() : []}
                    columns={getColumns(t, siteId)}
                    isLoading={isDetailLoading}
                    headerHeight={60}
                    hideFooter
                    rowHeight={60}
                />
                <Box mt={3}>
                    <Button
                        onClick={() => navigate(router.getStaticUrl(Pages.CONTRACT_REGULAR, Tabs.FINANCIAL_STATEMENT, { idObject }))}
                        color="green"
                        variant="contained"
                        size="large"
                    >
                        {t("common.backToStatements")}
                    </Button>
                </Box>
            </GridItem>
        </GridLayout>
    );
};
