import React from "react";
import { useSelector } from "react-redux";

import { Container } from "ui-library/atoms";
import { SubPageLayout } from "ui-library/layouts";

import { PageBoundary } from "containers/PageBoundary";

import { ClaimDetail, IncidentBasicData, IncidentHeader, IncidentHeaderOneTimeUser } from "./containers";
import user from "core/user";

export default () => {
    const isOneTimeClaimRole = useSelector(user.getIsOneTimeClaimRole);

    return (
        <SubPageLayout>
            <PageBoundary>
                {isOneTimeClaimRole ? <IncidentHeaderOneTimeUser /> : <IncidentHeader />}

                <Container maxWidth={"md"}>
                    <IncidentBasicData />
                    <ClaimDetail />
                </Container>
            </PageBoundary>
        </SubPageLayout>
    );
};
