import React, { FC, PropsWithChildren } from "react";
import { useSelector } from "react-redux";

import { isServiceInErrorState } from "./selectors";

export interface FullPageErrorBoundaryProps extends PropsWithChildren {
    service: string;
    BackButton: React.ComponentType;
    ErrorComponent: React.ComponentType<{ BackButton: React.ComponentType; service: string }>;
}

export const FullPageErrorBoundary: FC<FullPageErrorBoundaryProps> = ({ children, service, BackButton, ErrorComponent, ...props }) => {
    const isServiceInError = useSelector(isServiceInErrorState(service));

    if (isServiceInError) {
        return <ErrorComponent BackButton={BackButton} {...props} service={service} />;
    } else {
        return <>{children}</>;
    }
};
