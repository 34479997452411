import { withTheme } from "@mui/styles";
import { Box } from "ui-library/layouts/Box/Box";
import styled from "styled-components";

export const StyledHamburger = withTheme(styled(Box)`
    line-height: 0;
    &:hover {
        cursor: pointer;
    }
`);
