import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { MultilineContent } from "containers/MultilineContent";
import { oneLineNameWithTitles } from "core/formatters";
import i18n from "core/i18n";
import { getLocalizedAddressAsList } from "core/localized";
import userInfo from "core/userInfo";
import { Block, Button, StandaloneLink } from "ui-library/atoms";
import { Box, ButtonsLayout, ContentBox, GridItem, GridLayout } from "ui-library/layouts";
import { InfoPanel, LabelTextItem, Map } from "ui-library/modules";

const getActionLinks = (phone, email) => (
    <ButtonsLayout>
        {phone && (
            <Box>
                <Button externalLink={`tel:${phone}`} startIcon={"phone"} color="blue" variant="outlined">
                    {phone}
                </Button>
            </Box>
        )}
        {email && (
            <Box>
                <Button externalLink={`mailto:${email}`} startIcon={"mail"} variant={"outlined"} color={"blue"}>
                    {email}
                </Button>
            </Box>
        )}
    </ButtonsLayout>
);

export const AgentOverviewGridItem = ({ idObject, detailLink }) => {
    const { t } = i18n.useTranslation();

    const agent = useSelector(userInfo.createGetAgentByObjectId(idObject));

    const phone = agent.getIn(["legalEntity", "phone"]);
    const email = agent.getIn(["legalEntity", "email"]);
    const agentAddress = getLocalizedAddressAsList(t, agent.branch);

    return (
        <GridItem xs={12}>
            <Block padding={false} variant="white">
                <GridLayout defaultSpacing>
                    {/* Left side content - agent info */}
                    <GridItem xs={12} md={7}>
                        <InfoPanel
                            icon={"headset"}
                            title={t("agent.agent")}
                            bgVariant={"clean"}
                            actionButton={(phone || email) && getActionLinks(phone, email)}
                            headerAction={
                                <StandaloneLink to={detailLink} color="blue">
                                    {t("common.detail")}
                                </StandaloneLink>
                            }
                        >
                            <ContentBox>
                                <LabelTextItem label={t("holder.firstName")} text={oneLineNameWithTitles(agent.get("legalEntity"))} />
                                {agent.getIn(["branch", "city"]) && (
                                    <LabelTextItem
                                        label={t("agent.branch")}
                                        text={
                                            <Box>
                                                <Box mb={{ xs: 2, md: 1 }}>
                                                    <MultilineContent value={agentAddress} />
                                                </Box>
                                            </Box>
                                        }
                                    />
                                )}
                            </ContentBox>
                        </InfoPanel>
                    </GridItem>

                    {/* Right side content - map */}
                    {agent.branch.latitude && agent.branch.longitude && (
                        <GridItem md={5} xs={12}>
                            <Map zoom={13} height={300} width={1024} lat={agent.branch.latitude} lng={agent.branch.longitude} />
                        </GridItem>
                    )}
                </GridLayout>
            </Block>
        </GridItem>
    );
};

AgentOverviewGridItem.propTypes = {
    idObject: PropTypes.number.isRequired,
    detailLink: PropTypes.string.isRequired,
};
