import { withTheme } from "@mui/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import styled from "styled-components";

export const StyledBubbleAccordion = withTheme(styled(Accordion)`
    && {
        background: transparent;
        margin-bottom: 0;
        border-radius: 0;
        &:before {
            display: none;
        }
        &.Mui-expanded {
            margin: 0;
        }
    }
`);

export const StyledBubbleAccordionSummary = withTheme(styled(AccordionSummary)`
    color: ${(props) => props.theme.palette.blue.main};
    background: ${(props) => props.theme.palette.blue.soft};
    padding: 16px 0 16px 16px;
    transition: all ease 200ms;
    width: fit-content;
    max-width: 80%;
    border-radius: 16px;
    position: relative;
    margin-bottom: 37px;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        max-width: unset;
        width: 100%;
    }
    &:before {
        content: "";
        position: absolute;
        left: 30px;
        bottom: -14px;
        width: 0;
        height: 0;
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
        border-left: 12px solid #eef2fa;
        transform: rotate(-125deg);
    }
    .MuiAccordionSummary-content {
        margin: 0;
    }
    &.Mui-expanded {
        transition: all ease 200ms;
        min-height: unset;
        p {
            font-weight: 700;
            color: ${(props) => props.theme.palette.black.main};
        }
    }
    svg {
        margin: 0 18px;
    }
    p {
        color: ${(props) => props.theme.palette.gray.main};
    }
`);

export const StyledBubbleAccordionDetails = withTheme(styled(AccordionDetails)`
    max-width: 500px;
    border-radius: 16px;
    margin-left: auto;
    padding: 16px;
    background: ${(props) => props.theme.palette.blue.main};
    margin-bottom: 37px;
    position: relative;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        max-width: unset;
    }
    p,
    ul,
    li,
    a {
        color: ${(props) => props.theme.palette.white.main};
    }
    &:after {
        content: "";
        position: absolute;
        right: 30px;
        bottom: -14px;
        width: 0;
        height: 0;
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
        border-left: 12px solid #005ca9;
        transform: rotate(-45deg);
    }
`);
