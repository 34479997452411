import { withTheme } from "@mui/styles";
import styled from "styled-components";
import { FormControlLabel, FormGroup, InputLabel, ListSubheader, MenuItem, Radio, TextField, RadioGroup, Select } from "@mui/material";

import { CheckboxItem } from "ui-library/atoms/Inputs/CheckboxItem";
import { Flexbox } from "ui-library/layouts/Flexbox/Flexbox";

export const StyledTextField = withTheme(styled(TextField)`
    width: 100%;
    .MuiOutlinedInput-root {
        background: ${(props) => props.theme.palette.white.main};
        padding: 0;
        color: ${(props) => props.theme.palette.gray.border};
        &:hover > fieldset {
            border-color: ${(props) => props.theme.palette.gray.main} !important;
        }
        &.Mui-error > fieldset {
            border-color: ${(props) => props.theme.palette.red.main};
        }
        &.Mui-focused {
            border-color: ${(props) => props.theme.palette.blue.king};
            & .MuiOutlinedInput-notchedOutline {
                border: 1px solid ${(props) => props.theme.palette.blue.king};
                box-shadow: 0 0 2px #a7b7c7;
            }
        }
        & > input,
        & > textarea {
            min-height: 26px;
            padding: ${(props) => (props.$size === "medium" ? "8px 16px" : "16px")};
            border-radius: 4px;
            color: ${(props) => props.theme.palette.black.main};
            &::placeholder {
                color: ${(props) => props.theme.palette.gray.border};
                opacity: 1;
                letter-spacing: 0;
            }
        }
        & > fieldset {
            border-color: ${(props) => props.theme.palette.gray.border};
            box-shadow: inset 1px 2px 9px -6px ${(props) => props.theme.palette.gray.line};
        }
    }

    .MuiInputBase-adornedEnd {
        & > input {
            padding-right: 10px;
        }
    }
    .MuiInputBase-adornedStart {
        & > input {
            padding-left: 10px;
        }
    }
    .MuiInputAdornment-root {
        margin: 0;
        .MuiTypography-root {
            color: ${(props) => props.theme.palette.black.main};
        }
    }
    .MuiInputAdornment-positionStart {
        padding-left: 16px;
        .MuiTypography-root {
            padding-right: 10px;
        }
        &:after {
            content: "";
            width: 1px;
            height: 25px;
            background: ${(props) => props.theme.palette.gray.border};
        }
    }
    .MuiInputAdornment-positionEnd {
        padding-right: 16px;
        .MuiTypography-root {
            padding-left: 10px;
        }
        &:before {
            content: ${(props) => (props.$password ? null : "''")};
            width: 1px;
            height: 25px;
            background: ${(props) => props.theme.palette.gray.border};
        }

        .icon {
            &:hover {
                cursor: pointer;
            }
        }
    }
`);

export const StyledDatePicker = withTheme(styled(StyledTextField)`
    & {
        .MuiInputAdornment-positionEnd {
            &:before {
                content: none;
            }
        }
    }
`);

export const StyledSelect = withTheme(styled(Select)`
    color: ${(props) =>
        (props.value === "" || !props.value || props.value.length === 0) && props.placeholder
            ? props.theme.palette.gray.border
            : props.theme.palette.black.main};

    background: ${(props) => props.theme.palette.white.main};
    width: ${(props) => (props.$fullWidth ? "100%" : "auto")};
    white-space: normal;
    & {
        padding: 0;
        &:hover > fieldset {
            border-color: ${(props) => props.theme.palette.gray.main} !important;
        }
        &.Mui-error > fieldset {
            border-color: ${(props) => props.theme.palette.red.main};
        }
        &.Mui-focused {
            border-color: ${(props) => props.theme.palette.blue.king};
            & .MuiOutlinedInput-notchedOutline {
                border: 1px solid ${(props) => props.theme.palette.blue.king};
                box-shadow: 0 0 2px #a7b7c7;
            }
        }
        & > input,
        & > textarea {
            padding: 8px 16px;
            border-radius: 4px;
            color: ${(props) => props.theme.palette.black.main};
            &::placeholder {
                color: ${(props) => props.theme.palette.gray.border};
                opacity: 1;
            }
        }
        & > fieldset {
            border-color: ${(props) => props.theme.palette.gray.border};
            box-shadow: inset 1px 2px 9px -6px ${(props) => props.theme.palette.gray.line};
        }
    }

    font-size: 16px;
    .MuiSelect-select {
        padding: 9px 45px 9px 11px !important;
        p {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
                white-space: normal;
            }
        }
    }

    svg {
        position: absolute;
        right: 17px;
        pointer-events: none;
        flex-shrink: 0;
    }
`);

export const StyledDropdownOption = withTheme(styled(Flexbox)``);

const checkboxTicks = (props) => {
    if (!props.error) {
        return `content : url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='currentColor'%3E%3Cpath d='M11.2524 1.00232L4.87737 7.37731C4.78831 7.50856 4.64534 7.56247 4.50003 7.56247C4.35472 7.56247 4.21222 7.50753 4.1023 7.39768L0.727295 4.02268C0.507568 3.80296 0.507568 3.44706 0.727295 3.22722C0.947022 3.00737 1.30292 3.00749 1.52276 3.22722L4.50003 6.20544L10.4766 0.228882C10.6963 0.00915533 11.0522 0.00915533 11.2721 0.228882C11.4919 0.448608 11.4914 0.8031 11.2524 1.00232Z' fill='%23005CA9'/%3E%3C/svg%3E");`;
    } else {
        return `content : url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='currentColor'%3E%3Cpath d='M11.2524 1.00232L4.87737 7.37731C4.78831 7.50856 4.64534 7.56247 4.50003 7.56247C4.35472 7.56247 4.21222 7.50753 4.1023 7.39768L0.727295 4.02268C0.507568 3.80296 0.507568 3.44706 0.727295 3.22722C0.947022 3.00737 1.30292 3.00749 1.52276 3.22722L4.50003 6.20544L10.4766 0.228882C10.6963 0.00915533 11.0522 0.00915533 11.2721 0.228882C11.4919 0.448608 11.4914 0.8031 11.2524 1.00232Z' fill='%23D71C4F'/%3E%3C/svg%3E");`;
    }
};

const checkBoxGroupStyleChecked = (props) => {
    if (!props.error) {
        return `
        border: 1px solid ${props.theme.palette.blue.main};
        background-color: ${props.theme.palette.blue.soft};
    `;
    }
};

const checkBoxGroupStyleUnchecked = (props) => {
    return `
        border: 1px solid ${props.theme.palette.gray.border};
        background-color: ${props.theme.palette.white.main};
    `;
};

export const StyledCheckbox = withTheme(styled(CheckboxItem)`
    & {
        display: none;

        + span {
            box-sizing: border-box;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
                "Segoe UI Emoji", "Segoe UI Symbol";
            box-shadow: 1px 2px 9px -6px #dedee1 inset;
            display: inline-block;
            position: relative;
            min-width: 16px;
            min-height: 16px;
            border: 1px solid ${(props) => (props.error ? props.theme.palette.red.main : props.theme.palette.gray.border)};
            border-radius: 4px;
            margin-right: 8px;
            background: ${(props) => props.theme.palette.white.main};

            &:before {
                content: "";
            }
        }
    }
    &:checked {
        + span {
            border: 1px solid ${(props) => (props.error ? props.theme.palette.red.main : props.theme.palette.blue.main)};
            &:before {
                ${(props) => checkboxTicks(props)};
                position: absolute;
                top: -3px;
                left: 1px;
            }
        }
    }
`);

const labelPadding = (props) => {
    if (props.$hasTooltip === undefined) {
        return `padding : 0;`;
    } else if (props.$hasTooltip === false) {
        return `padding: 9px 16px 9px 16px;`;
    } else if (props.$hasTooltip === true) {
        return `padding: 9px 40px 9px 16px;`;
    }
};

const checkboxHover = (props) => {
    if (props.$hasGroup) {
        return `
            border-color :  ${props.theme.palette.gray.main} !important
        `;
    } else {
        return `
        span {
            color :  ${props.theme.palette.gray.main}
        }
        `;
    }
};

export const StyledCheckboxLabel = withTheme(styled(FormControlLabel)`
    &:hover {
        ${(props) => checkboxHover(props)};
    }

    ${(props) => labelPadding(props)};
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    label {
        margin-bottom: 0;
        font-weight: 400;
        white-space: pre-wrap;
        &:hover {
            cursor: pointer;
        }
    }
`);

const checkboxGroupDirection = (props) => {
    return props.direction === "row" ? "row" : "column";
};

export const StyledCheckboxGroup = withTheme(styled(FormGroup)`
    margin-top: 10px;

    & {
        display: flex;
        flex-direction: ${(props) => checkboxGroupDirection(props)};
        gap: 8px;

        .MuiFormControlLabel-root {
            display: flex;
            align-items: center;
            border-radius: 4px;
        }

        .checked {
            ${(props) => checkBoxGroupStyleChecked(props)};
        }
        .unchecked {
            ${(props) => checkBoxGroupStyleUnchecked(props)};
        }
    }

    .checkbox-wrapper {
        position: relative;

        .checkbox-tooltip {
            position: absolute;
            right: 14px;
        }
    }
`);

const radioColor = (props) => {
    if (props.$error) {
        return props.theme.palette.red.main + "!important";
    } else {
        return props.theme.palette.gray.border;
    }
};

export const StyledRadio = withTheme(styled(Radio)`
    box-shadow: 1px 2px 9px -6px #dedee1 inset;
    flex-grow: 0;
    color: ${(props) => radioColor(props)};
    padding: 0;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    background: ${(props) => props.theme.palette.white.main};

    svg {
        width: 20px;
        height: 20px;
    }
`);

export const StyledRadioGroup = withTheme(styled(RadioGroup)`
    & {
        display: flex;
        flex-direction: ${(props) => checkboxGroupDirection(props)};
        gap: 8px;
        .MuiFormControlLabel-root {
            max-height: 42px;
            display: flex;
            flex-grow: 1;
            align-items: center;
            border-radius: 4px;
            border: 1px solid ${(props) => props.theme.palette.gray.border};
            background-color: ${(props) => props.theme.palette.white.main};
        }
        .checked {
            ${(props) => checkBoxGroupStyleChecked(props)};
        }
        .unchecked {
            ${(props) => checkBoxGroupStyleUnchecked(props)};
        }
    }
    .radio-wrapper {
        position: relative;
        flex: 1;
        .radio-tooltip {
            position: absolute;
            right: 14px;
        }
    }
`);

const imageCheckBoxGroupStyleChecked = (props) => {
    if (!props.error) {
        return `
        border: 1px solid ${props.theme.palette.gray.border};
        background: linear-gradient(180deg, #FFFFFF 0%, #FAFCFE 35.94%, #F1F8FC 100%);

    `;
    }
};

const imageCheckBoxGroupStyleUnchecked = (props) => {
    return `
        border: 1px solid ${props.theme.palette.gray.border};
        background-color: ${props.theme.palette.white.main};
    `;
};

export const StyledImageLabelRadioGroup = withTheme(styled(RadioGroup)`
    & {
        .checked {
            ${(props) => imageCheckBoxGroupStyleChecked(props)};
        }
        .unchecked {
            ${(props) => imageCheckBoxGroupStyleUnchecked(props)};
        }
    }
`);

export const StyledImageLabelRadio = withTheme(styled(FormControlLabel)`
    ${(props) => labelPadding(props)};
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    display: flex;
    flex-direction: column;
    padding: 16px;
    height: 100%;
    border-radius: 4px;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        flex-direction: row;
        padding: 8px 16px;
        height: 71px;
    }
    > span {
        &:first-child {
            order: 1;
            @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
                order: 0;
            }
        }
        &:last-child {
            order: 0;
            text-align: center;
            height: 100%;
            margin-bottom: 16px;
            @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
                order: 1;
                margin-bottom: 0;
                width: 100%;
            }
            span {
                padding-bottom: 5px;
                @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
                    padding-bottom: 0;
                    width: 100%;
                    text-align: left;
                }
            }
            img {
                object-fit: unset;
                height: auto;
                max-height: 120px;
                @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
                    max-width: 45px;
                }
            }
            img.small {
                max-height: 70px;
            }
        }
    }
    label {
        margin-bottom: 0;
        font-weight: 400;
        &:hover {
            cursor: pointer;
        }
    }
`);
export const StyledRadioLabel = withTheme(styled(FormControlLabel)`
    &:hover {
        ${(props) => checkboxHover(props)};
    }

    ${(props) => labelPadding(props)};
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    label {
        margin-bottom: 0;
        font-weight: 400;
        &:hover {
            cursor: pointer;
        }
    }
`);

export const StyledLabel = withTheme(styled(InputLabel)`
    white-space: inherit;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 2px;
    color: ${(props) => props.theme.palette.black.main};

    .optional {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: ${(props) => props.theme.palette.gray.main};
        margin-left: 4px;
    }

    .tooltip {
        margin-left: 7px;
        display: block;
    }
`);

export const StyledError = withTheme(styled("div")`
    display: flex;
    align-items: center;
    margin-top: 5px;
    .MuiTypography-caption {
        margin-left: 8px;
    }
`);

export const StyledOption = withTheme(styled(MenuItem)`
    display: block;
    &.Mui-selected {
        background-color: ${(props) => props.theme.palette.blue.cloud};
        &:hover {
            background-color: ${(props) => props.theme.palette.blue.cloud};
        }
    }
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        &.complex-option {
            position: relative;
            &:before {
                position: absolute;
                content: "";
                top: 0;
                left: 5%;
                width: 90%;
                height: 2px;
                background-color: ${(props) => props.theme.palette.gray.border};
                border-radius: 4px;
            }

            &:first-child {
                &:before {
                    content: none;
                }
            }
        }
    }
    @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
        min-height: unset;
    }
`);

export const StyledOptionGroup = withTheme(styled(ListSubheader)`
    font-size: 14px;
    line-height: 22px;
    color: ${(props) => props.theme.palette.gray.main};
    font-weight: 400;
    margin-bottom: 8px;

    & + .complex-option {
        &:before {
            content: none;
        }
    }
`);
