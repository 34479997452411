import React from "react";
import { useSelector } from "react-redux";

import { DocumentLink } from "containers/DocumentLink";
import i18n from "core/i18n";
import entity from "core/entity";
import fetching from "core/fetching";
import { localFullDate } from "core/formatters";
import { documentsApi } from "serverApi";
import { Box, GridItem } from "ui-library/layouts";
import { DataTable } from "ui-library/modules";

const href = (idObject, documentGuid) => documentsApi.getContractDocumentDownloadUrl(idObject, documentGuid);

const columns = (t, idObject) => [
    {
        field: "typeLocalName",
        headerName: t("documents.documentName"),
        flex: 3,
        renderCell: (params) => {
            const row = params.row;
            return <DocumentLink fileName={params.value} fileUrl={href(idObject, row.guid)} />;
        },
    },
    {
        field: "documentType",
        headerName: t("common.type"),
        flex: 1,
    },
    {
        field: "date",
        headerName: t("common.date"),
        flex: 1,
        width: 110,
        renderCell: (params) => localFullDate(params.value),
    },
];

export const ContractDocuments = ({ entityName, idObject }) => {
    const { t } = i18n.useTranslation();

    const isDocumentsLoading = useSelector(fetching.isFetching(entityName, true));
    const isDocumentsError = useSelector(entity.isError(entityName));
    const contractDocuments = useSelector(entity.getData(entityName));
    const mappedContractDocuments = contractDocuments && contractDocuments.toArray();

    return (
        <GridItem xs={12}>
            <Box fullWidth>
                <DataTable
                    disableColumnReorder
                    getRowId={(row) => row.guid}
                    pageSize={10}
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    rows={mappedContractDocuments}
                    columns={columns(t, idObject)}
                    icon="document"
                    title={t("documents.contractDocuments")}
                    emptyMsg={t("documents.noDocuments")}
                    errorMsg={t("error.sectionUnavailable")}
                    isLoading={isDocumentsLoading}
                    isError={isDocumentsError}
                />
            </Box>
        </GridItem>
    );
};
