import { LandingPageLayout } from "ui-library/layouts";

import { Banner, LeadPanels, PromoBlocks } from "./components";
export default () => (
    <>
        <LandingPageLayout>
            <Banner />
            <LeadPanels />
            <PromoBlocks />
        </LandingPageLayout>
    </>
);
