import React from "react";
import { useSelector } from "react-redux";
import { List } from "immutable";

import { ContentBox } from "ui-library/layouts";
import { LabelTextItem } from "ui-library/modules";

import i18n from "core/i18n";
import { getFormFieldValue } from "core/form";
import { dateObjToLocalFullDate, dateObjToLocalMonthPeriod } from "core/formatters";
import { RedeemPensionDpsRedeemTypes, RedeemPensionPpRedeemTypes } from "enums";

import { CONTRACT_TERMINATION_DATE_FIELD, REDEEM_START_FROM_FIELD } from "../../constants";

import { PaymentLabelTextItem } from "./PaymentLabelTextItem";

const MIN_WIDTH_PX = "170px";

const earlyPaymentRedeems = List.of(
    RedeemPensionDpsRedeemTypes.DPS_EARLY_PAYMENT_ONE_TIME_SETTLEMENT,
    RedeemPensionPpRedeemTypes.PP_EARLY_PAYMENT_ONE_TIME_SETTLEMENT,
);

const ppOneTimePaymentRedeems = List.of(
    RedeemPensionPpRedeemTypes.PP_SERVICE_PENSION_ONE_TIME_SETTLEMENT,
    RedeemPensionPpRedeemTypes.PP_RETIREMENT_PENSION_ONE_TIME_SETTLEMENT,
);

const getTerminationDate = (formName, redeemType) => {
    if (redeemType === RedeemPensionDpsRedeemTypes.DPS_RETIREMENT_PENSION_ONE_TIME_SETTLEMENT) {
        const redeemDateValue = useSelector(getFormFieldValue(formName, REDEEM_START_FROM_FIELD));
        const redeemDate = new Date(redeemDateValue);
        return new Date(redeemDate.getFullYear(), redeemDate.getMonth(), 0);
    } else {
        const terminationDateValue = useSelector(getFormFieldValue(formName, CONTRACT_TERMINATION_DATE_FIELD));
        return new Date(terminationDateValue);
    }
};

export const SectionEarlyPaymentOrOneTimeSettlement = ({ formName, redeemType, paymentAmount }) => {
    const { t } = i18n.useTranslation();

    const terminationDate = getTerminationDate(formName, redeemType);
    const lastDayOfMonth = new Date(terminationDate.getFullYear(), terminationDate.getMonth() + 1, 0);
    const firstDayNextMonth = new Date(terminationDate.getFullYear(), terminationDate.getMonth() + 1, 1);

    const terminationTypeText = earlyPaymentRedeems.includes(redeemType)
        ? t("pension.redeemSavings.pay.earlyPayment")
        : t("pension.redeemSavings.pay.oneTimeSettlement");

    const isOneTimePpPayment = ppOneTimePaymentRedeems.includes(redeemType);

    const endDateTitle = isOneTimePpPayment
        ? t(`form.pensionRedeemSavings.recapitulation.endDate.${redeemType.id}`)
        : t("common.contractEndDate");

    return (
        <ContentBox mt={4}>
            {/* Contract end date */}
            <LabelTextItem minWidthLabel minWidthPx={MIN_WIDTH_PX} label={endDateTitle} text={dateObjToLocalFullDate(lastDayOfMonth)} />

            {/* Contract end type */}
            <LabelTextItem
                minWidthLabel
                minWidthPx={MIN_WIDTH_PX}
                label={isOneTimePpPayment ? t("common.pensionRedeemType") : t("common.contractEndType")}
                text={terminationTypeText}
            />

            {/* Payment period */}
            <LabelTextItem
                minWidthLabel
                minWidthPx={MIN_WIDTH_PX}
                label={t("common.paymentPeriod")}
                text={dateObjToLocalMonthPeriod(firstDayNextMonth)}
            />

            {/* Payment type */}
            <PaymentLabelTextItem minWidthPx={MIN_WIDTH_PX} formName={formName} />

            {/* Payment amount */}
            <LabelTextItem
                minWidthLabel
                minWidthPx={MIN_WIDTH_PX}
                label={t("form.pensionRedeemSavings.recapitulation.paymentAmount")}
                text={paymentAmount}
            />
        </ContentBox>
    );
};
