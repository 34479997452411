import React, { FC, ReactNode } from "react";

import { StyledUnorderedList } from "ui-library/Styled";

import { ListItem } from "./ListItem";

type UnorderedListProps = {
    children: ReactNode;
    itemsVariant?: string;
    compact?: boolean;
};

export const UnorderedList: FC<UnorderedListProps> = ({ children, itemsVariant = "circle", compact = false, ...props }) => {
    return (
        <StyledUnorderedList $variant={itemsVariant}>
            {children
                ? React.Children.map(children, (item, index) => (
                      <ListItem compact={compact} variant={itemsVariant} key={index}>
                          {/* @ts-ignore TODO: martin.rodin fix types later */}
                          {item.type === "li" ? item.props.children : item}
                      </ListItem>
                  ))
                : ""}
        </StyledUnorderedList>
    );
};
