import { Selector } from "@reduxjs/toolkit";
import { Map } from "immutable";
import { createSelector } from "reselect";
import { v4 as uuid } from "uuid";

import { DEFAULT_EMPTY_PARAM } from "app/constants";
import { getFormFieldValue } from "core/form";
import { FundSellSwitch, FundSellTarget, MutualFunds, MutualFundsDirectAmountType, MutualFundsPeriodicity } from "enums";
import i18n from "core/i18n";
import { app, date, DEFAULT_CHART_COLOR, model } from "core/util";
import { localAmountWithCurrencyLegacy } from "core/formatters";
import userContracts from "core/userContracts";
import { MutualFundsFinancialRequest } from "model/mutualFunds";
import { MutualFundsDirectFinancialOperation, MutualFundsDirectFinancialStatement } from "model/mutualFunds/direct";
import entity from "core/entity";
import {
    ElectronicCommunication,
    Holder,
    MutualFundsAccountPaymentDetail,
    MutualFundsClientStatementSettings,
    MutualFundsContractParameters,
    MutualFundsDirectContract,
    State,
} from "types";

import { ENTITY_FINANCIAL_OPERATIONS, ENTITY_FINANCIAL_REQUESTS, ENTITY_FINANCIAL_STATEMENT, FORM_SELL_SWITCH, NAME } from "./constants";
import { createGetFundsForInvestment } from "core/util/selector";

/** contract data */
const getModel = app.createGetModel(NAME);
const getData = (state) => getModel(state).get("data");

const getMutualFundsDirectContractDetail: Selector<State, MutualFundsDirectContract> = (state) =>
    getData(state).mutualFundsDirectContractDetail;

export const getIdObject: Selector<State, number> = (state) => getMutualFundsDirectContractDetail(state).idObject || DEFAULT_EMPTY_PARAM;
export const getPolicyHolder: Selector<State, Holder> = (state) => getMutualFundsDirectContractDetail(state).contractDetail.holder;
export const getMutualFundsDirectContractParameters: Selector<State, MutualFundsContractParameters> = (state) =>
    getMutualFundsDirectContractDetail(state).contractDetail.contractParameters;
export const getContractListOfFundsForInvestment: Selector<State, MutualFundsAccountPaymentDetail[]> = (state) =>
    getMutualFundsDirectContractDetail(state).contractDetail.mutualFundPayments;
export const getElectronicCommunication: Selector<State, ElectronicCommunication> = (state) =>
    getMutualFundsDirectContractDetail(state).contractDetail.electronicCommunication;
export const isElectronicCommunicationSet: Selector<State, boolean> = (state) =>
    getMutualFundsDirectContractDetail(state).contractDetail.electronicCommunication.electronicCommunicationSet || false;
export const getClientStatementSettings: Selector<State, MutualFundsClientStatementSettings> = (state) =>
    getMutualFundsDirectContractDetail(state).contractDetail.clientStatementSettings;

export const getShouldCheckIbanCountry: Selector<State, boolean> = (state) =>
    getMutualFundsDirectContractDetail(state).contractDetail.shouldCheckIbanCountry;

export const isActiveOperationsEnabled: Selector<State, boolean> = (state) =>
    getMutualFundsDirectContractParameters(state).isActiveOperationEnabled || false;
export const getActiveOperationsPhoneNumber: Selector<State, string> = (state) =>
    getMutualFundsDirectContractParameters(state).activeOperationPhone;

export const getFinancialOperationsEntity = (state) =>
    // @ts-ignore
    entity.getDataSelector(state, ENTITY_FINANCIAL_OPERATIONS, MutualFundsDirectFinancialOperation.fromServerList());
export const getFinancialStatementEntity = (state) =>
    // @ts-ignore
    entity.getDataSelector(state, ENTITY_FINANCIAL_STATEMENT, MutualFundsDirectFinancialStatement.fromServer());
export const getMutualFundsDirectFinancialRequestsEntity = (state) =>
    // @ts-ignore
    entity.getDataSelector(state, ENTITY_FINANCIAL_REQUESTS, MutualFundsFinancialRequest.fromServerList());

export const getFinancialOperations = createSelector(getFinancialOperationsEntity, i18n.getLanguageCode, (financialOperations, locale) =>
    financialOperations.map((financialOperation) => ({
        id: financialOperation.id,
        valueDate: financialOperation.valueDate,
        fund: model.getLocaleCodeName(financialOperation.fund, locale),
        type: model.getLocaleCodeName(financialOperation.type, locale),
        numberOfShares: financialOperation.numberOfShares,
        pricePerShareAtOperationDateTime: financialOperation.pricePerShareAtOperationDateTime,
        fee: financialOperation.feePayment,
        investedAmount: Map({
            amount:
                financialOperation.type.code === FundSellSwitch.SELL.id
                    ? financialOperation.nettoInvestedAmount
                    : financialOperation.payment,
            operationType: financialOperation.type.code,
        }),
        nettoInvestedAmount: Map({
            amount:
                financialOperation.type.code === FundSellSwitch.SELL.id
                    ? financialOperation.investedAmount
                    : financialOperation.nettoInvestedAmount,
            operationType: financialOperation.type.code,
        }),
    })),
);
export const getFinancialOperationsFunds = createSelector(getFinancialOperations, (financialOperations) =>
    financialOperations.map((operation) => Map().set("label", operation.fund).set("value", operation.fund)).toSet(),
);
export const getFinancialOperationsTypes = createSelector(getFinancialOperations, (financialOperations) =>
    financialOperations.map((operation) => Map().set("label", operation.type).set("value", operation.type)).toSet(),
);

export const getActualValueOfInvestmentsBasedOnFund = (state) =>
    getFinancialStatementEntity(state).getIn(["actualValueOfInvestmentsBasedOnFund"]);
export const getInvestmentsProfits = (state) => getFinancialStatementEntity(state).getIn(["investmentsProfits"]);

export const getEnumFunds = i18n.createGetLocalizedEnum(MutualFunds, "link", "type", "horizont");

export const getMutualFundsFinancialRequest = (financialRequestId) =>
    createSelector(getMutualFundsDirectFinancialRequestsEntity, (financialRequests) =>
        financialRequests.filter((financialRequest) => financialRequest.id === financialRequestId).first(),
    );

export const getInvestmentDetailData = createSelector(
    getActualValueOfInvestmentsBasedOnFund,
    getEnumFunds,
    i18n.getLanguageCode,
    (investmentFunds, funds, languageCode) =>
        investmentFunds &&
        investmentFunds.map((investmentFund) => ({
            id: uuid(),
            fundName: Map({
                text: model.getLocaleCodeName(investmentFund.fund, languageCode),
                href: model.getHyperLinkForCodeList(funds, investmentFund.fund.code),
            }),
            investedAmount: investmentFund.investedAmount,
            entryFee: investmentFund.entryFee,
            outputFee: investmentFund.outputFee,
            numberOfShares: investmentFund.numberOfShares,
            actualShareValue: investmentFund.actualShareValue,
            actualValueOfInvestments: investmentFund.actualValueOfInvestments,
            profit: Map({
                profit: investmentFund.profit,
                fundIsin: investmentFund.fund.code,
            }),
            payment: investmentFund.fund.code,
        })),
);

const getFundEnumData = (isin, funds) => funds.find((fundData) => fundData.code === isin);

export const getFinancialStatementChartItems = createSelector(
    getActualValueOfInvestmentsBasedOnFund,
    getEnumFunds,
    i18n.getLanguageCode,
    (investmentFunds, enumFunds, languageCode) =>
        investmentFunds
            .map((item) => {
                const fundEnumData = getFundEnumData(item.getIn(["fund", "code"]), enumFunds);
                return {
                    name: model.getLocaleCodeName(item.get("fund"), languageCode),
                    color: fundEnumData ? fundEnumData.get("color") : DEFAULT_CHART_COLOR,
                    value: item.actualValueOfInvestments.value,
                    fullValue: localAmountWithCurrencyLegacy(item.actualValueOfInvestments),
                    currency: item.actualValueOfInvestments.currencyCode,
                };
            })
            .toArray(),
);

export const getFinancialStatementChartData = createSelector(
    getFinancialStatementEntity,
    getFinancialStatementChartItems,
    (financialStatement, financialStatementChartItems) =>
        Map({
            sumValue: financialStatement.actualValueOfInvestmentsSum.value,
            sumCurrency: financialStatement.actualValueOfInvestmentsSum.currencyCode,
            sumAmount: `${localAmountWithCurrencyLegacy(financialStatement.actualValueOfInvestmentsSum)}`,
            items: financialStatementChartItems,
        }),
);

export const getFundsForInvestment = (funds) =>
    createSelector(createGetFundsForInvestment(funds), getEnumFunds, i18n.getLanguageCode, (fundsForInvestment, enumFunds, languageCode) =>
        fundsForInvestment.map((investableFund) => {
            const fundIsin = investableFund.fund.code;
            const fundEnumData = getFundEnumData(fundIsin, enumFunds);
            return {
                id: fundIsin,
                fundName: {
                    text: model.getLocaleCodeName(investableFund.fund, languageCode),
                    href: fundEnumData && fundEnumData.get("link"),
                },
                currencyCode: investableFund.currencyCode,
                fundType: fundEnumData && fundEnumData.get("type"),
                actualPricePerShare: investableFund.actualPricePerShare,
                fundInvestmentHorizon: fundEnumData && fundEnumData.get("horizont"),
                fundRisk: fundEnumData && `${fundEnumData.get("risk")} / 7`,
                payment: investableFund.fund.code,
            };
        }),
    );

export const getProfitDetail = createSelector(
    getInvestmentsProfits,
    (state, filter) => filter,
    i18n.getLanguageCode,
    (data, filter, locale) => {
        return data.map((financialOperation) => ({
            id: uuid(),
            date: financialOperation.date,
            fund: model.getLocaleCodeName(financialOperation.get("fund"), locale),
            numberOfShares: financialOperation.numberOfShares,
            purchasePrice: Map({
                price: financialOperation.purchasePrice,
                perShare: financialOperation.purchasePricePerShare,
            }),
            actualPrice: Map({
                price: financialOperation.actualPrice,
                perShare: financialOperation.actualPricePerShare,
            }),
            profit: financialOperation.profit,
        }));
    },
);

// selectors for sellSwitchForm
export const getTargetFundCodes = createSelector(getContractListOfFundsForInvestment, (fundPayments) =>
    fundPayments.map((payment) => payment.fund),
);

export const getSourceFundCodes = createSelector(getActualValueOfInvestmentsBasedOnFund, (fundInvestments) =>
    fundInvestments.map((investment) => investment.get("fund")),
);

export const createGetSourceFundOptions = (targetFundFieldName, sourceFundCodes) => {
    const fundOptionsSelector = i18n.createGetLocalizedCodeOptions(sourceFundCodes);
    const targetFieldValueSelector = getFormFieldValue(FORM_SELL_SWITCH, targetFundFieldName);
    return createSelector(fundOptionsSelector, targetFieldValueSelector, (fundOptions, otherFundFieldValue) =>
        fundOptions.filter((option) => option.get("value") !== otherFundFieldValue),
    );
};

export const createGetTargetFundOptions = (sourceFundFieldName, targetFundCodes) => {
    const fundOptionsSelector = i18n.createGetLocalizedCodeOptions(targetFundCodes);
    const sourceFieldValueSelector = getFormFieldValue(FORM_SELL_SWITCH, sourceFundFieldName);
    return createSelector(fundOptionsSelector, sourceFieldValueSelector, (fundOptions, otherFundFieldValue) =>
        fundOptions.filter((option) => option.get("value") !== otherFundFieldValue),
    );
};

const getFundSellOneTimeOption = i18n.createGetLocalizedEnumOptions(
    Object.assign({}, { MutualFundsPeriodicity: MutualFundsPeriodicity.ONETIME }),
);
export const getFundSellTypeOptions = (sellMeans) =>
    sellMeans !== MutualFundsDirectAmountType.SELL_ALL.id
        ? i18n.createGetLocalizedEnumOptions(MutualFundsPeriodicity)
        : getFundSellOneTimeOption;
export const getFundSellTargetOptions = i18n.createGetLocalizedEnumOptions(FundSellTarget);

/** user data */
export const getPolicy = createSelector(userContracts.getContractNormalizedData, getIdObject, (policies, idObject) =>
    idObject ? policies[idObject] : null,
);
export const getPolicyBeginDateYear = (state) => date.getYearFromStringDateTime(getPolicy(state)?.beginDate);
