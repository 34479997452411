import React, { FC, ReactNode, useState } from "react";
import { Typography, Icon } from "ui-library/atoms";
import { Flexbox, Box } from "ui-library/layouts";
import { StyledInfoPanel } from "ui-library/Styled";
import useBreakpoints from "ui-library/theme/breakpoints";

type InfoPanelProps = {
    children: ReactNode;
    padding?: boolean;
    actionButton?: ReactNode;
    bgVariant?: string;
    droppable?: boolean;
    title?: ReactNode;
    subTitleSection?: ReactNode;
    icon?: string;
    headerAction?: ReactNode;
    shadow?: boolean;
    actionStretch?: boolean;
    dateInfo?: ReactNode;
};

export const InfoPanel: FC<InfoPanelProps> = ({
    padding = true,
    children,
    actionButton = null,
    bgVariant = "white",
    droppable = false,
    title = null,
    subTitleSection = null,
    icon = "",
    headerAction = null,
    shadow = false,
    actionStretch = false,
    dateInfo,
    ...props
}) => {
    const [more, setMore] = useState(false);

    const handleClick = () => {
        setMore(!more);
    };

    const isBreakMD = useBreakpoints("md");

    //most based used content wrapper

    return (
        <StyledInfoPanel
            $more={more}
            $droppable={droppable}
            display={actionStretch && "flex"}
            flexDirection={actionStretch && "column"}
            shadow={shadow}
            padding={padding}
            variant={bgVariant}
            {...props}
        >
            {/* @ts-ignore TODO: martin.rodin check if onClick is passed anywhere in the app */}
            <Flexbox className={droppable && "chevron"} onClick={droppable ? handleClick : props.onClick} alignItems={"center"} gap={2.25}>
                {icon && <Icon color={getFromVariant(bgVariant)} size={24} icon={icon} />}

                <Typography variant={"h3"}>{title}</Typography>
                {droppable && (
                    <Box ml={"auto"}>
                        <Icon size={16} color={getFromVariant(bgVariant)} icon={more ? "chevron-up" : "chevron-down"} />
                    </Box>
                )}
                <>
                    {dateInfo && !headerAction ? (
                        <Flexbox flex={1} justifyContent={"flex-end"} gap={2} alignItems={"center"} my={1}>
                            {React.isValidElement(dateInfo) ? <>{dateInfo}</> : <Typography color="gray">{dateInfo}</Typography>}
                        </Flexbox>
                    ) : (
                        <></>
                    )}
                    {headerAction && <Box ml={"auto"}>{headerAction}</Box>}
                </>
            </Flexbox>

            {dateInfo && !isBreakMD && headerAction ? (
                <Flexbox gap={2} alignItems={"center"} my={1}>
                    {React.isValidElement(dateInfo) ? <>{dateInfo}</> : <Typography color="gray">{dateInfo}</Typography>}
                </Flexbox>
            ) : (
                <></>
            )}

            {/* @ts-ignore TODO: martin.rodin check this flexDirection prop, seems like it doesn't belong here */}
            <Box flexDirection={actionStretch && "column"} flex={actionStretch && 1} display={actionStretch && "flex"} className="content">
                {subTitleSection && <Box>{subTitleSection}</Box>}

                <Box mt={2}>{children}</Box>
                <Box flex={actionStretch && 1} display={actionStretch && "flex"} alignItems={actionStretch && "end"}>
                    {actionButton}
                </Box>
            </Box>
        </StyledInfoPanel>
    );
};

const getFromVariant = (bgVariant: string) => {
    switch (bgVariant) {
        case "redGradient":
            return "red";

        case "white":
            return "blue";

        case "blue":
            return "white";

        case "greenGradient":
            return "green";

        default:
            return "blue";
    }
};
