import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { GridLayout } from "ui-library/layouts";

import { AgentDetailGridItem } from "containers/contract";

import { ChangePropositionModule } from "../modules";
import { getIsDipContract } from "../selectors";

export const AgentTab: FC = () => {
    const { idObject } = useParams();
    const isDipContract = useSelector(getIsDipContract);

    return (
        <GridLayout defaultSpacing>
            {!isDipContract && <ChangePropositionModule.Container />}
            <AgentDetailGridItem idObject={Number(idObject)} />
        </GridLayout>
    );
};
