import React, { FC } from "react";
import { useDispatch } from "react-redux";

import { Icon, StandaloneLink } from "ui-library/atoms";

import { sendEnqueueRequest } from "../actions";

type FinancialOperationPrintButtonProps = {
    idObject: number;
    idOperation: string;
};

export const FinancialOperationPrintButton: FC<FinancialOperationPrintButtonProps> = ({ idObject, idOperation }) => {
    const dispatch = useDispatch();

    return (
        <StandaloneLink color="blue" onClick={() => dispatch(sendEnqueueRequest(idObject, idOperation))}>
            <Icon size={16} color="blue" icon="print" />
        </StandaloneLink>
    );
};
