import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import React from "react";

import { Typography } from "ui-library/atoms";

import { MutualFundsPeriodicity } from "enums";
import { MutualFundsFinancialRequest } from "model/mutualFunds";
import i18n from "core/i18n";

export const FrequencyFormatter = ({ row }) => {
    const { t } = i18n.useTranslation();
    const getLocalizedName = i18n.createGetLocalizedNameWithCode(row.frequency);
    const res = useSelector(getLocalizedName);
    const value = row.periodicityType === MutualFundsPeriodicity.ONETIME.id ? t("common.oneTime") : res;

    return <Typography>{value}</Typography>;
};

FrequencyFormatter.propTypes = {
    row: PropTypes.instanceOf(MutualFundsFinancialRequest).isRequired,
};
