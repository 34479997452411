import { model } from "core/util";

import { CodeValue } from "../common";
import { MutualFundsContractParameters as MutualFundsContractParametersType, MutualFundsContractParametersDto } from "../../types";

export const MutualFundsContractParameters = model.createModel<MutualFundsContractParametersDto, MutualFundsContractParametersType>(
    (json) => ({
        productName: CodeValue.fromServer(json.ProductName),
        contractNumber: json.ContractNumber,
        status: CodeValue.fromServer(json.Status),
        signDate: json.SignDate,
        acceptanceDate: json.AcceptanceDate,
        endDate: json.EndDate,
        isActiveOperationEnabled: json.IsActiveOperationEnabled,
        activeOperationDate: json.ActiveOperationDate,
        activeOperationPhone: json.ActiveOperationPhone,
    }),
);
