import { all, delay, call, put, select, takeLatest } from "redux-saga/effects";
import { change } from "redux-form/lib/immutable";
import { arrayRemove, unregisterField } from "redux-form/immutable";
import { List } from "immutable";

import pageContext from "core/pageContext";
import { FORM_STEPS_BACK } from "app/constants";
import { contractDataChangeApi, mutualFundsRegularApi } from "serverApi";
import { fetch, model, sentry } from "core/util";
import { ERROR_COMPONENT_CLASS, fieldChangeMatcher, formWrapper, getFormFieldValue } from "core/form";
import { OTP_CODE_FIELD_NAME, OTP_ID_FIELD_NAME } from "modules/otp";
import userInfo from "core/userInfo";
import errorHandling, { OTP_WRONG_MOBILE_ERROR_CODE, OTP_WRONG_MOBILE_NUMBER_FIN_OPERATIONS } from "core/errorHandling";

import {
    FUND_FIELD,
    FUND_PERCENTAGE_BREAKDOWN,
    INDIVIDUAL_PROFILE_CODE,
    MAX_FUND_COUNT,
    PERCENTAGE_FIELD,
    PROFILE_CHANGE_FEE,
    PROFILE_SELECT,
    REALLOCATE_INVESTMENTS_FIELD,
    SUM_PERCENTAGE_FIELD,
} from "./constants";
import fetching from "core/fetching";
import { addOtpFormSuccess, resetOtpFormSuccess } from "core/form/actions";

import { REMOVE_FUND_WITH_PERCENTAGE_ROW } from "./actions";
import { createEmptyFundPercentageRow } from "./util";

export function* createSaga(formName, setResent, setPhoneNumber, isProfileAvailable, isProductGenerationXSkSelector, idObject) {
    try {
        yield call(formSaga(formName, setResent, setPhoneNumber, isProfileAvailable, isProductGenerationXSkSelector), idObject);
    } catch (e) {
        sentry.captureException(e);
        yield put(errorHandling.addServiceError(formName, e.identifier));
    }
}

const formSaga = (formName, setResent, setPhoneNumber, isProfileAvailable, isProductGenerationXSkSelector) =>
    formWrapper(formName, {
        *persistentEffects(idObject) {
            yield takeLatest(REMOVE_FUND_WITH_PERCENTAGE_ROW, removeFundPercentageRowSaga, formName);
            const percentageActionsMatchers = [];
            for (let i = 0; i < MAX_FUND_COUNT; i++) {
                percentageActionsMatchers.push(fieldChangeMatcher(formName, `${FUND_PERCENTAGE_BREAKDOWN}[${i}].${PERCENTAGE_FIELD}`));
            }
            yield takeLatest(percentageActionsMatchers, setPercentageSum, formName);
            yield takeLatest(
                fieldChangeMatcher(formName, PROFILE_SELECT),
                setChangeFeeAmount,
                formName,
                idObject,
                isProductGenerationXSkSelector,
            );
        },
        *initialize() {
            yield call(userInfo.checkUserVerified, FORM_STEPS_BACK);
            yield put(errorHandling.removeServiceErrors(formName));
            yield put(resetOtpFormSuccess([formName]));
            return {
                [FUND_PERCENTAGE_BREAKDOWN]: [createEmptyFundPercentageRow()],
            };
        },
        *save(values, idObject) {
            const changeProfileResult = yield call(
                mutualFundsRegularApi.getMutualFundsRegularChangeProfile,
                idObject,
                values.get(PROFILE_SELECT),
            );
            if (changeProfileResult.isChangeAvailable) {
                try {
                    const profileSelected = yield select(getFormFieldValue(formName, PROFILE_SELECT));
                    const fundPercentageBreakdown = values.get(FUND_PERCENTAGE_BREAKDOWN);
                    const fundPercentageBreakdownFinal = fundPercentageBreakdown.map((item) => item.delete("reactKey"));

                    const result = yield call(contractDataChangeApi.updateInvestmentProfile, {
                        idObject,
                        newProfileCode: values.get(PROFILE_SELECT),
                        programStructure: profileSelected === INDIVIDUAL_PROFILE_CODE ? fundPercentageBreakdownFinal : null,
                        reallocateInvestments: values.get(REALLOCATE_INVESTMENTS_FIELD),
                        otpId: values.get(OTP_ID_FIELD_NAME),
                        otpCode: values.get(OTP_CODE_FIELD_NAME),
                    });
                    return result ? { otpId: result.otp_id, phone: result.phone_number } : {};
                } catch (e) {
                    if (e.status === fetch.BAD_REQUEST && e.response.otpErrorCode === OTP_WRONG_MOBILE_ERROR_CODE) {
                        yield put(errorHandling.addServiceError(OTP_WRONG_MOBILE_NUMBER_FIN_OPERATIONS, e.identifier));
                        // throw empty precondition failed error to stop form submission
                        throw new fetch.EmptyPreconditionFailedError();
                    }
                    throw e;
                }
            } else {
                // fake error 412
                /* eslint-disable-next-line no-throw-literal */
                throw { disapprovalReason: changeProfileResult.disapprovalReason, status: fetch.PRECONDITION_FAILED };
            }
        },

        // Not sure how to handle this error
        /* eslint-disable-next-line consistent-return */
        *error(error) {
            if (error.disapprovalReason) {
                const locale = yield select(pageContext.getLocale);
                const reason = model.getLocaleCodeName(error.disapprovalReason, locale.languageCode);
                return { [PROFILE_SELECT]: [reason] };
            }
        },
        *onSubmitFail() {
            yield call(scrollToFirstError);
        },
        *success({ otpId, phone }) {
            if (otpId) {
                yield put(change(formName, OTP_ID_FIELD_NAME, otpId));
                yield put(setPhoneNumber(phone));
            } else {
                yield put(addOtpFormSuccess(formName));
            }
        },
    });

export function* scrollToFirstError() {
    yield delay(0);
    const errors = document.querySelectorAll(`.${ERROR_COMPONENT_CLASS}`);
    if (errors.length) {
        errors[0].parentElement.parentElement.parentElement.parentElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    }
}

function* setChangeFeeAmount(formName, idObject, isProductGenerationXSkSelector, action) {
    const isGenXSk = yield select(isProductGenerationXSkSelector);

    if (!isGenXSk) {
        yield put(fetching.setFetching(fetching.FETCHING_PROFILE_CHANGE_FEE, true));
        const changeProfileResult = yield call(mutualFundsRegularApi.getMutualFundsRegularChangeProfile, idObject, action.payload);
        yield put(change(formName, PROFILE_CHANGE_FEE, changeProfileResult.changeFee));
        yield put(fetching.setFetching(fetching.FETCHING_PROFILE_CHANGE_FEE, false));
    }
}

function* setPercentageSum(formName) {
    let percentageSum = 0;
    const fundPercentageBreakdown = yield select(getFormFieldValue(formName, FUND_PERCENTAGE_BREAKDOWN)) || List();
    fundPercentageBreakdown.forEach((row) => (percentageSum += row.get(PERCENTAGE_FIELD) ? Number(row.get(PERCENTAGE_FIELD)) : 0));
    yield put(change(formName, SUM_PERCENTAGE_FIELD, percentageSum));
}

function* removeFundPercentageRowSaga(formName, { meta }) {
    const fundPercentageBreakdown = yield select(getFormFieldValue(formName, FUND_PERCENTAGE_BREAKDOWN));
    const lastIndex = fundPercentageBreakdown.count() - 1;

    yield put(arrayRemove(formName, FUND_PERCENTAGE_BREAKDOWN, meta.index));
    yield all([
        yield put(unregisterField(formName, `${FUND_PERCENTAGE_BREAKDOWN}[${lastIndex}].${FUND_FIELD}`)),
        yield put(unregisterField(formName, `${FUND_PERCENTAGE_BREAKDOWN}[${lastIndex}].${PERCENTAGE_FIELD}`)),
    ]);
}
