export const NAME = "core/fetching";

export const FETCHING_AVAILABLE_PROFILES = `${NAME}/FETCHING_AVAILABLE_PROFILES`;
export const FETCHING_PROFILE_CHANGE_FEE = `${NAME}/FETCHING_PROFILE_CHANGE_FEE`;
export const FETCHING_CONTRACTS_FINANCIAL_OPERATIONS = `${NAME}/FETCHING_CONTRACTS_FINANCIAL_OPERATIONS`;

export interface FetchingState {
    [entity: string]: boolean;
}

export const initialState: FetchingState = {};

export interface SetFetchingInternalPayload {
    entity: string;
    isFetching: boolean;
}

export interface SetFetchingPayload extends SetFetchingInternalPayload {
    immediate?: boolean;
}
