import createModel from "../../createModel";
import { CodeValueLegacy, AmountLegacy } from "../../common";

import { MutualFundsInvestmentsProfit } from "../MutualFundsInvestmentsProfit";
import { MutualFundsActualValueOfInvestmentsBasedOnFund } from "../MutualFundsActualValueOfInvestmentsBasedOnFund";

export const MutualFundsRegularFinancialStatement = createModel("MutualFundsRegularFinancialStatement", (json) => ({
    statementDate: json.StatementDate,
    investmentProfile: CodeValueLegacy.fromServer(json.InvestmentProfile),
    depositedFunds: AmountLegacy.fromServer(json.DepositedFunds),
    paidEntryFee: AmountLegacy.fromServer(json.PaidEntryFee),
    isEntryFeePaid: json.IsEntryFeePaid,
    investedAmount: AmountLegacy.fromServer(json.InvestedAmount),
    nettoInvestedAmount: AmountLegacy.fromServer(json.NettoInvestedAmount),
    actualValueOfInvestments: AmountLegacy.fromServer(json.ActualValueOfInvestments),
    profit: json.Profit,
    nettoProfit: json.NettoProfit,
    actualValueOfInvestmentsBasedOnFund: MutualFundsActualValueOfInvestmentsBasedOnFund.fromServerList(
        json.ActualValueOfInvestmentsBasedOnFund,
    ),
    investmentsProfits: MutualFundsInvestmentsProfit.fromServerList(json.InvestmentsProfits),
}));
