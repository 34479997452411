import React from "react";
import { useNavigate } from "react-router-dom";

import i18n from "core/i18n";
import router from "core/router";
import { Pages, Tabs } from "routeConstants";
import { Button } from "ui-library/atoms";

export const BackToVerificationGridItemButton = () => {
    const { t } = i18n.useTranslation();
    const navigate = useNavigate();

    return (
        <Button onClick={() => navigate(router.getStaticUrl(Pages.USER, Tabs.VERIFICATION))} variant="outlined" color="blue">
            {t("common.back")}
        </Button>
    );
};
