import { fetch } from "core/util";
import { API_PREFIX } from "app/constants";
import { LoginResponse } from "model/auth";

const checkAccess = () => fetch.doGet(`${API_PREFIX}/impersonate/check-access`);
const loginByToken = (values) => fetch.doPost(`${API_PREFIX}/impersonate/auth-by-token`, values, undefined, LoginResponse.fromServer);
const loginByCode = (values) => fetch.doPost(`${API_PREFIX}/impersonate/auth-by-code`, values, undefined, LoginResponse.fromServer);

export default {
    checkAccess,
    loginByToken,
    loginByCode,
};
