import React, { FC } from "react";
import { useSelector } from "react-redux";

import { PieChart } from "ui-library/modules";
import { Loading, Typography } from "ui-library/atoms";

import entity from "core/entity";
import fetching from "core/fetching";
import i18n from "core/i18n";
import { fn } from "core/util";

import { ENTITY_FINANCIAL_STATEMENT } from "../constants";
import { getFinancialStatementChartData } from "../selectors";

export const PieChartWithFundsTable: FC = () => {
    const { t } = i18n.useTranslation();

    const isStatementLoading = useSelector(fetching.isFetching(ENTITY_FINANCIAL_STATEMENT, true));
    const isStatementError = useSelector(entity.isError(ENTITY_FINANCIAL_STATEMENT));

    const rawChartData = useSelector(getFinancialStatementChartData);
    const chartData = fn.calculateChartPercentage(rawChartData.get("sumValue"), rawChartData.get("items"));

    return (
        <Loading loading={isStatementLoading}>
            {isStatementError && <Typography>{t("error.sectionUnavailable")}</Typography>}
            {!isStatementError && (
                <PieChart data={chartData} legendTitle={t("insurance.funds")} legendValue={rawChartData.get("sumAmount")} />
            )}
        </Loading>
    );
};
