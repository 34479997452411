import { fn, nbsp } from "core/util";
import { TFunction } from "core/i18n";
import { Amount } from "types";
import { Countries, FundSellTarget } from "enums";

import { addSpacesToNumber, roundTo } from "./numberFormatter";
import { oneLineNameWithTitles } from "./userInfoFormatter";

export const localAmount = (value: number): string => {
    if (!fn.isEmpty(value)) {
        const round = roundTo(value, 2);
        const parts = round.split(".");

        parts[0] = addSpacesToNumber(parts[0]);
        const result = parts.join(",");

        return result.replace(/,+$/, "");
    } else {
        return "";
    }
};

export const localAmountInvestment = (value: number): string => {
    if (!fn.isEmpty(value)) {
        const round = roundTo(value, 4);
        const parts = round.split(".");

        parts[0] = addSpacesToNumber(parts[0]);

        const result = parts.join(",");

        return result.replace(/,+$/, "");
    } else {
        return "";
    }
};

export const localAmountInvestmentEur = (value: number): string => {
    if (!fn.isEmpty(value)) {
        const round = roundTo(value, 6);
        const parts = round.split(".");
        parts[0] = addSpacesToNumber(parts[0]);
        const result = parts.join(",");
        return result.replace(/,+$/, "");
    } else {
        return "";
    }
};

export const localAmountInvestmentWithCurrency = (value): string => {
    if (value && value.get("value") && value.get("currencyCode")) {
        const amount = value.get("value");
        const currency = value.get("currencyCode");
        return `${localAmountInvestment(amount)}${nbsp}${localCurrency(currency)}`;
    } else {
        return "";
    }
};

export const localAmountInvestmentWithCurrencyEur = (rawAmount: Amount): string => {
    if (rawAmount && rawAmount.value && rawAmount.currencyCode) {
        const currencyCode = rawAmount.currencyCode;
        const amount =
            currencyCode === Countries.SK.currencyCode ? localAmountInvestmentEur(rawAmount.value) : localAmountInvestment(rawAmount.value);
        return `${amount}${nbsp}${localCurrency(currencyCode)}`;
    } else {
        return "";
    }
};

export const localAmountInvestmentWithCurrencyEurLegacy = (value): string => {
    if (value && value.get("value") && value.get("currencyCode")) {
        const currencyCode = value.get("currencyCode");
        const amount =
            currencyCode === Countries.SK.currencyCode
                ? localAmountInvestmentEur(value.get("value"))
                : localAmountInvestment(value.get("value"));
        return `${amount}${nbsp}${localCurrency(currencyCode)}`;
    } else {
        return "";
    }
};

export const localAmountNoDecimal = (value): string => {
    if (!fn.isEmpty(value)) {
        return addSpacesToNumber(value.split(".")[0]);
    } else {
        return "";
    }
};

export const localAmountNoDecimalWithCurrency = (value: Amount): string => {
    if (value && value.value && value.currencyCode) {
        const amount = value.value;
        const currency = value.currencyCode;
        return `${localAmountNoDecimal(amount)}${nbsp}${localCurrency(currency)}`;
    } else if (value && value.value) {
        return localAmountNoDecimal(value.value);
    } else {
        return "";
    }
};

export const localAmountNoDecimalWithCurrencyLegacy = (value): string => {
    if (value && value.get("value") && value.get("currencyCode")) {
        const amount = value.get("value");
        const currency = value.get("currencyCode");
        return `${localAmountNoDecimal(amount)}${nbsp}${localCurrency(currency)}`;
    } else if (value && value.get("value")) {
        return localAmountNoDecimal(value.get("value"));
    } else {
        return "";
    }
};

export const localAmountWithCurrency = (value: Amount): string => {
    if (value && !fn.isEmpty(value.value) && value.currencyCode) {
        const amount = value.value;
        const currency = value.currencyCode;
        return `${localAmount(amount)}${nbsp}${localCurrency(currency)}`;
    } else if (value && !fn.isEmpty(value.value)) {
        return localAmount(value.value);
    } else {
        return "";
    }
};

export const localAmountWithCurrencyBasedOnSite = (amount: Amount, isSlovakSite: boolean): string => {
    if (fn.isEmpty(amount)) {
        return "";
    }
    return isSlovakSite ? localAmountWithCurrency(amount) : localAmountNoDecimalWithCurrency(amount);
};

export const localAmountWithCurrencyBasedOnSiteLegacy = (amount, isSlovakSite: boolean): string => {
    if (fn.isEmpty(amount)) {
        return "";
    }
    return isSlovakSite ? localAmountWithCurrencyLegacy(amount) : localAmountNoDecimalWithCurrencyLegacy(amount);
};

export const localAmountWithCurrencyLegacy = (value): string => {
    if (value && value.get("value") && value.get("currencyCode")) {
        const amount = value.get("value");
        const currency = value.get("currencyCode");
        return `${localAmount(amount)}${nbsp}${localCurrency(currency)}`;
    } else if (value && value.get("value")) {
        return localAmount(value.get("value"));
    } else {
        return "";
    }
};
export const localCurrency = (value: string): string => {
    let text;

    switch (value) {
        case "CZK":
        case "KČ ":
        case "KČ":
        case "KÅ":
            text = "Kč";
            break;
        case "EUR":
            text = "EUR";
            break;
        default:
            text = "";
    }

    return text;
};

export const negativeFormatter = (value, amountWithCurrencyFormatter = localAmountWithCurrencyLegacy): string => {
    if (!value || !value.get("amount") || !value.getIn(["amount", "value"])) {
        return "";
    }
    const amount = value.get("amount");
    const isDebitOperation = (operationType) => operationType === FundSellTarget.SELL.id || operationType === FundSellTarget.SELL.type;
    const absAmount = amount.set("value", Math.abs(Number(amount.value)).toString());
    return isDebitOperation(value.get("operationType"))
        ? `-${amountWithCurrencyFormatter(absAmount)}`
        : amountWithCurrencyFormatter(absAmount);
};

/**
 * The parameter contractBalance may be a positive or negative number.
 * If negative, it is interpreted as overPayment (Preplatok) and the negative sign is removed for display.
 * If positive, it is interpreted as underPayment (Dlužné pojistné).
 */
export const formatBalanceOverdue = (t: TFunction, contractBalance): { title: string; value: string } => {
    if (fn.isEmpty(contractBalance?.value)) {
        return {
            title: "",
            value: "",
        };
    }

    const balanceOnContractValue = contractBalance.value;
    const title = balanceOnContractValue < 0 ? t("insurance.dueInsurancePremium") : t("common.overPayment");
    const value = localAmountWithCurrencyLegacy(contractBalance.set("value", Math.abs(contractBalance.value).toString()));

    return {
        title,
        value,
    };
};

export const formatFinancialStatementAmount = (t: TFunction, value, withDecimals = false): string => {
    const amountFormat = (amount) =>
        withDecimals ? localAmountWithCurrencyLegacy(amount) : localAmountNoDecimalWithCurrencyLegacy(amount);

    if (value === undefined || value === null) {
        return "";
    } else if (typeof value === "object") {
        return amountFormat(value);
    }
    return value ? t("common.yes") : t("common.no");
};

export const formatLifeBeneficiaryName = (value) => oneLineNameWithTitles(value.get("beneficiary")) || value.get("description") || "";
