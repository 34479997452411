import React from "react";
import { useSelector } from "react-redux";

import { SingleSelect } from "core/form";
import localized from "core/localized";

export const AmountWithOperationTypeFilterOperator = (props) => {
    const amountWithOperationTypeFilterOptions = useSelector(localized.getLocalizedAmountWithOperationTypeFilterOperatorOptions);

    return <SingleSelect {...props} options={amountWithOperationTypeFilterOptions} />;
};
