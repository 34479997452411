import React, { FC } from "react";
import { format } from "date-fns";

import { Icon, Typography } from "ui-library/atoms";
import { Box } from "ui-library/layouts";

import { date } from "core/util";
import i18n from "core/i18n";

const isNotTranslated = (msgKey: string, value: string): boolean => value === `(${msgKey})`;

const copyToClipboard = (value: string) => navigator.clipboard.writeText(value);

const errorServerFallbackText =
    "Vážený klient, zobrazenie je aktuálne nedostupné. Na oprave pracujeme. Ospravedlňujeme sa za vzniknuté komplikácie.";
const errorIdentifierFallbackText = "Identifikátor: ";
const errorDateTimeFallbackText = "Dátum a čas: ";

export interface ErrorDateAndIdentifierProps {
    errorMsgKey: string;
    errorIdentifier?: string;
}

export const ErrorDateAndIdentifier: FC<ErrorDateAndIdentifierProps> = ({ errorMsgKey, errorIdentifier }) => {
    const { t } = i18n.useTranslation();
    const dateTime = format(new Date(), date.dateFormatWithTimeAndSecs);

    const translateOrGetFallbackText = (msgKey: string, fallbackText: string) =>
        isNotTranslated(msgKey, t(msgKey)) ? fallbackText : t(msgKey);

    return (
        <>
            <Typography markDown={translateOrGetFallbackText(errorMsgKey, errorServerFallbackText)} />
            <Box mt={2}>
                {errorIdentifier && (
                    <>
                        <Typography component="span">
                            {translateOrGetFallbackText("error.identifier", errorIdentifierFallbackText)}
                        </Typography>
                        <Typography component="span">{errorIdentifier}</Typography>
                        <Typography component="span"> </Typography>
                        <Icon icon="copy" size={16} color="blue" onClick={() => copyToClipboard(errorIdentifier)} />
                        <Typography></Typography>
                    </>
                )}
                <Typography component="span">{translateOrGetFallbackText("error.dateTime", errorDateTimeFallbackText)}</Typography>
                <Typography component="span">{dateTime}</Typography>
                <Typography component="span"> </Typography>
                <Icon icon="copy" size={16} color="blue" onClick={() => copyToClipboard(dateTime)} />
            </Box>
        </>
    );
};
