import { List } from "immutable";
import createModel from "../createModel";
import { LegalEntityLegacy } from "../common/LegalEntityLegacy";
import { Branch } from "./Branch";
import { SocialNetwork } from "./SocialNetwork";

export const Agent = createModel("Agent", (json) => ({
    idAgentContractAgent: json.IdObject_AgentContract_Agent,
    legalEntity: LegalEntityLegacy.fromServer(json.Agent),
    branch: Branch.fromServer(json.Branch),
    socialNetwork: SocialNetwork.fromServer(json.SocialNetwork),
    productList: List(json.Products),
}));
