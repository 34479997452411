import React, { FC } from "react";
import { useSelector } from "react-redux";

import { Typography } from "ui-library/atoms";
import { Box, ContentBox, GridItem } from "ui-library/layouts";
import { DataTable, InfoPanel } from "ui-library/modules";
import useBreakpoints from "ui-library/theme/breakpoints";

import entity from "core/entity";
import fetching from "core/fetching";
import i18n from "core/i18n";
import localized from "core/localized";
import { useHasEditContractPermission } from "core/hooks";
import {
    dateTimeToLocalFullDate,
    formatCodeValueFundLink,
    formatLocalizedValueMap,
    localAmountInvestment,
    localAmountInvestmentWithCurrencyEurLegacy,
    localAmountWithCurrencyLegacy,
} from "core/formatters";
import { DssFunds } from "enums";
import { PensionDssDdsFinancialStatement } from "model/pension";

import { ENTITY_FINANCIAL_STATEMENT } from "../constants";
import { ContractInvestmentParamsContentBox, InvestmentsChangeButtonsSection } from "../containers";

import { PieChartWithFundsTable } from "./PieChartWithFundsTable";

const columns = (t, languageCode, fundTypeDdsDssMap) => [
    {
        field: "investmentFund",
        headerName: t("common.fund"),
        flex: 1,
        sortable: false,
        renderCell: (params) => formatCodeValueFundLink(t, languageCode, DssFunds, params.value),
    },
    {
        field: "type",
        headerName: t("pension.investmentType"),
        flex: 1,
        sortable: false,
        renderCell: (params) => formatLocalizedValueMap(params.value, fundTypeDdsDssMap),
    },
    {
        field: "numberOfShares",
        headerName: t("pension.numberOfShares"),
        flex: 1,
        valueGetter: (params) => localAmountInvestment(params.value),
        sortable: false,
        align: "right",
        headerAlign: "right",
    },
    {
        field: "pricePerShare",
        headerName: t("pension.actualPricePerShare"),
        flex: 1,
        valueGetter: (params) => localAmountInvestmentWithCurrencyEurLegacy(params.value),
        sortable: false,
        align: "right",
        headerAlign: "right",
    },
    {
        field: "actualInvestmentsValue",
        headerName: t("investments.actualInvestmentsValue"),
        valueGetter: (params) => localAmountWithCurrencyLegacy(params.value),
        flex: 1,
        sortable: false,
        align: "right",
        headerAlign: "right",
    },
];

export const ActualInvestmentValue: FC = () => {
    const { t } = i18n.useTranslation();
    const languageCode = i18n.getLanguageCode();

    const hasEditPermission = useHasEditContractPermission();
    const isStatementLoading = useSelector(fetching.isFetching(ENTITY_FINANCIAL_STATEMENT, true));
    const isStatementError = useSelector(entity.isError(ENTITY_FINANCIAL_STATEMENT));
    const statementData = useSelector(entity.getData(ENTITY_FINANCIAL_STATEMENT, PensionDssDdsFinancialStatement.fromServer()));
    const fundsData = statementData.pensionInvestmentsBasedOnFund?.toArray();
    const statementDate = dateTimeToLocalFullDate(statementData.date);
    const fundTypeDdsDssMap = useSelector(localized.getLocalizedFundTypeDdsDssMap);

    if (statementData?.actualInvestmentsValue?.value === "0") {
        return null;
    }

    const isMd = useBreakpoints("md");

    return (
        <GridItem xs={12}>
            <InfoPanel
                icon={"chart-line"}
                title={t("insurance.investmentStateBasedOnFunds")}
                headerAction={<Typography color="gray">{t("statements.dateTime", { statementDate })}</Typography>}
            >
                {/* Funds table data */}
                <ContentBox>
                    <DataTable
                        headerHeight={isMd ? 60 : 70}
                        getRowId={(row) => row.reactKey}
                        columns={columns(t, languageCode, fundTypeDdsDssMap)}
                        rows={fundsData}
                        summaryRow={{
                            desc: t("common.overall"),
                            total: localAmountWithCurrencyLegacy(statementData.actualInvestmentsValue),
                        }}
                        withWrapper={false}
                        disableColumnReorder
                        isLoading={isStatementLoading}
                        isError={isStatementError}
                    />
                </ContentBox>

                {/* Investement strategy and isGuaranteedFundRatioReductionSet */}
                <ContractInvestmentParamsContentBox />

                {/* Change button */}
                {hasEditPermission && <InvestmentsChangeButtonsSection />}

                {/* Chart and sum data */}
                {!isStatementLoading && !isStatementError && (
                    <Box mt={2}>
                        <Box mb={1}>
                            <Typography variant="h4">{t("insurance.investmentStateBasedOnFunds")}</Typography>
                        </Box>
                        <PieChartWithFundsTable />
                    </Box>
                )}
            </InfoPanel>
        </GridItem>
    );
};
