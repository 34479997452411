import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ContentBox, GridItem } from "ui-library/layouts";
import { AcknowledgeBlock, InfoPanel } from "ui-library/modules";
import { Button, Loading, Option, Select, Typography } from "ui-library/atoms";
import manImg from "ui-library/assets/images/manNDog.svg";

import entity from "core/entity";
import fetching from "core/fetching";
import i18n from "core/i18n";
import router from "core/router";
import { getContractPaymentOptions } from "core/util/selector";
import userContracts from "core/userContracts";

import { Pages, Params, Tabs } from "routeConstants";
import { GridItemVariant } from "types";
import { ContractStatus, ProductGroup } from "enums";

const HIDDEN_FOR_TERMINATED_PRODUCT_GROUP = [ProductGroup.PENSION, ProductGroup.INVESTMENT];

const doTransition = (idObject, amountType) =>
    router.navigate(
        Pages.ONLINE_PAYMENT,
        Tabs.PAYMENTS_PAY,
        {},
        {
            [Params.PAYMENT_CONTRACT_ID]: idObject,
            [Params.PAYMENT_AMOUNT_TYPE]: amountType,
        },
        true,
    );

export interface InsuranceProductPaymentGridItemProps {
    entityName: string;
    idObject: number;
    variant: GridItemVariant;
}

export const InsuranceProductPaymentGridItem: FC<InsuranceProductPaymentGridItemProps> = ({ entityName, idObject, variant }) => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();

    const [payment, setPayment] = React.useState("");
    const paymentChange = (event) => {
        setPayment(event.target.value);
    };

    const isPaymentDataLoading = useSelector(fetching.isFetching(entityName, true));
    const isPaymentDataError = useSelector(entity.isError(entityName));
    const paymentData = useSelector(entity.getData(entityName));

    const contractPaymentOptions = useSelector(getContractPaymentOptions(paymentData));
    const contract = userContracts.useGetContractById(idObject);
    const { isFutureContract } = userContracts.useGetContractDerivedData(contract);

    if (
        isFutureContract ||
        (contract.idEnuContractStatus === ContractStatus.TERMINATED &&
            HIDDEN_FOR_TERMINATED_PRODUCT_GROUP.includes(contract.idEnuProductGroup))
    ) {
        return null;
    }

    const isPaymentNeeded = !contractPaymentOptions.isEmpty();

    return (
        <GridItem display={"flex"} xs={12} md={5}>
            <InfoPanel
                bgVariant={variant === GridItemVariant.INCLUDED ? "clean" : "white"}
                padding={variant !== GridItemVariant.INCLUDED}
                icon={"credit-card"}
                title={t("payments.quickPayment")}
                actionButton={
                    contract.canEdit &&
                    !isPaymentDataLoading &&
                    !isPaymentDataError &&
                    isPaymentNeeded && (
                        <Button variant={"contained"} color={"green"} onClick={() => dispatch(doTransition(idObject, payment))}>
                            {t("payments.processToPayment")}
                        </Button>
                    )
                }
            >
                <Loading loading={isPaymentDataLoading}>
                    {isPaymentDataError && <Typography>{t("error.sectionUnavailable")}</Typography>}
                    {!isPaymentDataError && isPaymentNeeded ? (
                        <ContentBox>
                            <Select
                                value={payment}
                                onChange={paymentChange}
                                fullWidth
                                placeholder={t("payments.paymentTypePlaceholder")}
                                id="paymentType"
                            >
                                {contractPaymentOptions.map((paymentOption) => (
                                    <Option key={paymentOption.get("value")} value={paymentOption.get("value")}>
                                        {paymentOption.get("label")}
                                    </Option>
                                ))}
                            </Select>
                        </ContentBox>
                    ) : (
                        <AcknowledgeBlock title={t("payments.allDuePaymentsPaied")} imgSrc={manImg} />
                    )}
                </Loading>
            </InfoPanel>
        </GridItem>
    );
};
