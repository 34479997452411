import { Tabs } from "routeConstants";
import { innerRoutes } from "routeUrls";

export default {
    [Tabs.USER_ACCOUNT]: innerRoutes[Tabs.USER_ACCOUNT],
    [Tabs.VERIFICATION]: innerRoutes[Tabs.VERIFICATION],
    [Tabs.DATA_PROCESSING]: innerRoutes[Tabs.DATA_PROCESSING],
    [Tabs.UNVERIFIED_USER]: innerRoutes[Tabs.UNVERIFIED_USER],
    [Tabs.USER_VERIFICATION_FORM]: innerRoutes[Tabs.USER_VERIFICATION_FORM],
};
