import React, { FC, PropsWithChildren } from "react";

import { ContractCard } from "ui-library/modules/ContractCard/ContractCard";
import { Typography } from "ui-library/atoms";
import { ViewVariant } from "ui-library/modules/enums";

import i18n from "core/i18n";
import router from "core/router";
import userContracts from "core/userContracts";
import products from "core/products";
import pageContext from "core/pageContext";

import { ContractPolicy, FeatureFlag, ProductLine, ProductLineLink } from "types";

import { ContractCardActionButtons, DesktopTabletContractCard, MobileContractCard } from "../components";
import { ContractCardActionButtonsQuickLink } from "../components/ContractCardActionButtons";
import { ContractListContentType } from "../constants";

const ELCOM_LINK_MSG = "quicklink.elcom";

const keepElcomEditLink = (link: ProductLineLink, isUniqaContract: boolean) =>
    !isUniqaContract || (isUniqaContract && link.msg !== ELCOM_LINK_MSG);

// dynamically creating links for dss depending on investmentStrategy value
const keepEditLinkByCondition = (link: ProductLineLink, contract: ContractPolicy) =>
    !link.displayCondition || link.displayCondition === contract?.overview?.pensionInvestmentStrategy;

const getIdObjectParamObj = (contract: ContractPolicy) => ({ idObject: contract.idObject });

const getQuickAccessLink = (link: ProductLineLink, contract: ContractPolicy): ContractCardActionButtonsQuickLink => {
    const url = router.getStaticUrl(
        link.route[0],
        link.route[1],
        link.params ? { ...getIdObjectParamObj(contract), ...link.params } : getIdObjectParamObj(contract),
        link.getQuery ? link.getQuery(contract.idObject + "") : {},
    );
    return {
        msg: link.msg,
        url,
    };
};

const getQuickAccessLinks = (productDefinition: ProductLine, contract: ContractPolicy): ContractCardActionButtonsQuickLink[] =>
    productDefinition.links
        .filter((link) => keepElcomEditLink(link, contract.isUniqaContract))
        .filter((link) => keepEditLinkByCondition(link, contract))
        .map((link) => getQuickAccessLink(link, contract));

const getDetailLink = (productDefinition: ProductLine, contract: ContractPolicy) => {
    if (!productDefinition || !productDefinition.detailRoute || !contract) {
        return null;
    }
    return router.getStaticUrl(productDefinition.detailRoute[0], productDefinition.detailRoute[1], { idObject: contract.idObject });
};

export interface ContractBoxProps extends PropsWithChildren {
    idObject: number;
    viewType: ViewVariant;
    displayedContentType: any;
    isMobileView?: boolean;
}

export const ContractBox: FC<ContractBoxProps> = ({ idObject, viewType, displayedContentType, isMobileView }) => {
    const { t } = i18n.useTranslation();

    const contract = userContracts.useGetContractById(idObject);
    const { isInFutureOrTerminated, isFutureContract } = userContracts.useGetContractDerivedData(contract);

    const productDefinition = products.useGetProductDefinition(contract?.idEnuProductGroupLevelTwo);

    const isDisplayEndedContractsEnabled = pageContext.useIsFeatureFlagEnabled(FeatureFlag.SHOW_ENDED_CONTRACTS_DETAIL);
    const isDisplayFutureContractsEnabled = pageContext.useIsFeatureFlagEnabled(FeatureFlag.SHOW_FUTURE_CONTRACTS_DETAIL);

    // Hide all unspecified idEnuProductGroupLevelTwo to not crash app. BE should log this situation.
    if (!productDefinition) {
        return null;
    }

    const detailLink = getDetailLink(productDefinition, contract);
    const quickAccessLinks = getQuickAccessLinks(productDefinition, contract);
    const displayingActiveContracts = displayedContentType === ContractListContentType.ACTIVE_CONTRACTS;

    const getActionComponent = () => {
        if (displayingActiveContracts) {
            if (isFutureContract) {
                if (isDisplayFutureContractsEnabled) {
                    return <ContractCardActionButtons detailLink={detailLink} quickAccessLinks={quickAccessLinks} />;
                } else {
                    return <Typography sx={{ width: "350px" }}>{t("contractList.futureContractInfoText")}</Typography>;
                }
            } else {
                return <ContractCardActionButtons detailLink={detailLink} quickAccessLinks={quickAccessLinks} />;
            }
        } else if (isDisplayEndedContractsEnabled) {
            return <ContractCardActionButtons detailLink={detailLink} />;
        } else {
            return null;
        }
    };

    return (
        <ContractCard
            actionStretch
            contractNumber={contract.contractNumber}
            droppable={isMobileView}
            variant={viewType}
            title={t(productDefinition.msg)}
            description={contract.productCRM}
            chipLabel={contract.contractName || t("general.text.customContractNameInfo")}
            imageUrl={productDefinition.image}
            imageDesatured={isInFutureOrTerminated}
            action={getActionComponent()}
        >
            {isMobileView ? <MobileContractCard contract={contract} /> : <DesktopTabletContractCard contract={contract} />}
        </ContractCard>
    );
};
