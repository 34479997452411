import { withTheme } from "@mui/styles";
import styled from "styled-components";

export const StyledImportantAnnouncement = withTheme(styled("div")`
    .flex {
        display: flex;
    }

    svg {
        margin-top: 4px;
    }

    .content {
        margin-left: 16px;
    }
`);
