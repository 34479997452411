import { call, put } from "redux-saga/effects";

import errorHandling from "core/errorHandling";
import { sentry } from "core/util";
import userInfo from "core/userInfo";

export function* createSaga(formName) {
    try {
        yield call(userInfo.gdprFormSaga(formName));
    } catch (e) {
        sentry.captureException(e);
        yield put(errorHandling.addServiceError(formName, e.identifier));
    }
}
