import { takeEvery, put } from "redux-saga/effects";

import { resetSection } from "redux-form/immutable";
import { OTP_CODE_FIELD_NAME, OTP_ID_FIELD_NAME } from "./constants";

export default (actions) => {
    return function* () {
        yield takeEvery(actions.CLEAR, clearSaga);
    };

    function* clearSaga({ payload }) {
        yield put(resetSection(payload, OTP_ID_FIELD_NAME, OTP_CODE_FIELD_NAME));
    }
};
