// idENU_ProductGroup
export enum ProductGroup {
    LIFE = 1,
    VEHICLE = 2,
    HOME = 3,
    INVESTMENT = 4,
    PENSION = 5,
    TRAVEL = 8,
    BUSINESS = 10,
}

// idENU_ProductGroup_lvl2
export enum ProductGroupLvl2 {
    LIFE_RISK = 1,
    LIFE_SAVINGS = 2,
    LIFE_ONETIME_SAVINGS = 4,
    VEHICLE = 6,
    HOME = 7,
    MUTUAL_FUNDS = 8,
    REGULAR_INVESTMENTS = 9,
    PENSION_PP = 10,
    PENSION_DSS = 11,
    PENSION_DPS_DDS = 12,
    LIFE_INSURANCE_OTHER = 14,
    ACCIDENT_INSURANCE = 15,
    LIFE_INVESTMENT_ACCOUNT = 16,
    TRAVEL = 17,
    LIFE_SAVINGS_18 = 18,
    BUFT = 19,
    TRANSPORT = 20,
    LIABILITY = 21,
    BUSINESS = 22,
    INDUSTRY = 23,
    LIFE_SAVINGS_24 = 24,
    LIFE_SAVINGS_25 = 25,
    LIFE_SAVINGS_26 = 26,
    LIFE_SAVINGS_27 = 27,
    LIFE_SAVINGS_28 = 28,
    LIFE_SAVINGS_29 = 29,
}

export enum PolicyUrlName {
    LIFE = "life",
    VEHICLE = "vehicle",
    HOME = "home",
    MUTUAL_FUNDS_DIRECT = "mutual-funds-direct",
    MUTUAL_FUNDS_REGULAR = "mutual-funds-regular",
    PENSION_PP = "pension-pp",
    PENSION_DSS = "pension-dss",
    PENSION_DPS = "pension-dps",
    TRAVEL = "travel",
}

export enum PolicyType {
    LIFE = "lifeInsurance",
    CAR = "carInsurance",
    HOME = "homeInsurance",
    INVESTMENT = "investment",
    RETIREMENT = "retirementSavings",
    TRAVEL = "travelInsurance",
}
