import { call, put, select } from "redux-saga/effects";
import { Selector } from "@reduxjs/toolkit";

import { formWrapper } from "core/form";
import { fn, sentry } from "core/util";
import errorHandling from "core/errorHandling";
import userInfo from "core/userInfo";
import { contractDataChangeApi } from "serverApi";
import { PaymentsScheduler, State } from "types";

export function* createSaga(formName: string, idObject: number, getPaymentsScheduler: Selector<State, PaymentsScheduler>) {
    try {
        yield call(formSaga(formName, getPaymentsScheduler), idObject);
    } catch (e) {
        sentry.captureException(e);
        yield put(errorHandling.addServiceError(formName, e.identifier));
    }
}

const formSaga = (formName: string, getPaymentsScheduler: Selector<State, PaymentsScheduler>) =>
    // @ts-ignore we won't type sagas
    formWrapper(formName, {
        *initialize() {
            yield call(userInfo.checkUserVerified);
            yield put(errorHandling.removeServiceErrors(formName));

            const paymentsScheduler: PaymentsScheduler = yield select(getPaymentsScheduler);

            return {
                amount: fn.decimalNumberWithComma(paymentsScheduler.modalAmount.value),
                frequencyOfPayment: paymentsScheduler.periodicity.code,
                paymentContribution: paymentsScheduler.paymentMode?.code,
            };
        },
        *save(values, idObject) {
            const requestBody = {
                idObject,
                amount: values.get("amount"),
                frequencyOfPayment: values.get("frequencyOfPayment"),
                paymentContribution: values.get("paymentContribution"),
            };
            yield call(contractDataChangeApi.updateContractOwnerPayments, requestBody);
        },
    });
