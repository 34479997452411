import React from "react";
import PropTypes from "prop-types";
import IPropTypes from "react-immutable-proptypes";

import { Switch } from "ui-library/atoms";

import { Consumer } from "../FormWideOnEnterContext";

const ENTER_KEY = "Enter";

const SegmentedControl = ({
    input,
    meta,
    tabIndex,
    disabled,
    id,
    onEnter,
    label,
    labelOptional,
    fullWidth,
    tooltip,
    footNote,
    placeholder,
    autoFocus,
}) => (
    <Consumer>
        {(formWideOnEnter) => (
            <Switch
                label={label}
                labelOptional={labelOptional}
                isChecked={input.value || false}
                fullWidth={fullWidth}
                tooltip={tooltip}
                footNote={footNote}
                errors={meta.error}
                placeholder={placeholder}
                onChange={input.onChange}
                onBlur={input.onBlur}
                onFocus={input.onFocus}
                tabIndex={tabIndex}
                disabled={disabled}
                id={id}
                onKeyPress={handleKeyPress(onEnter || formWideOnEnter)}
                autoFocus={autoFocus}
            />
        )}
    </Consumer>
);

const handleKeyPress = (onEnterHandler) => (ev) => {
    if (ev.key === ENTER_KEY && onEnterHandler) {
        onEnterHandler();
    }
};

SegmentedControl.propTypes = {
    meta: PropTypes.shape({
        error: IPropTypes.list,
        touched: PropTypes.bool.isRequired,
        asyncValidating: PropTypes.bool,
        dirty: PropTypes.bool,
        valid: PropTypes.bool,
        form: PropTypes.string,
    }).isRequired,
    input: PropTypes.shape({
        value: PropTypes.any,
        onChange: PropTypes.func.isRequired,
        onBlur: PropTypes.func.isRequired,
        onFocus: PropTypes.func.isRequired,
    }).isRequired,
    placeholder: PropTypes.string,
    tabIndex: PropTypes.number,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    onEnter: PropTypes.func,
    autoFocus: PropTypes.bool,
    label: PropTypes.string,
    fullWidth: PropTypes.bool,
    tooltip: PropTypes.string,
    footNote: PropTypes.string,
    error: PropTypes.bool,
    errorContent: PropTypes.string,
    labelDesc: PropTypes.string,
};

SegmentedControl.defaultProps = {
    placeholder: null,
    footNote: null,
    tabIndex: null,
    type: null,
    disabled: false,
    onEnter: null,
    autoFocus: false,
    fullWidth: true,
    size: "medium",
};

export default SegmentedControl;
