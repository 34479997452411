import { Record } from "immutable";
import { default as Frequency } from "./Frequency";

class LifeFrequency extends Record({ id: null, msg: null }) {}

// use only for display information (not for dropdown values)
export default Object.freeze({
    ...Frequency,
    ONETIME: new LifeFrequency({
        id: "ONETIME",
        msg: "common.oneTime",
    }),
});
