import { Record } from "immutable";

class AmountWithOperationTypeFilterOperator extends Record({ id: null, msg: null }) {}

export default Object.freeze({
    LESS: new AmountWithOperationTypeFilterOperator({
        id: "amountWithOperationTypeLess",
        msg: "tableFilter.less",
    }),
    MORE: new AmountWithOperationTypeFilterOperator({
        id: "amountWithOperationTypeMore",
        msg: "tableFilter.more",
    }),
    EQUAL: new AmountWithOperationTypeFilterOperator({
        id: "amountWithOperationTypeEqual",
        msg: "tableFilter.equal",
    }),
});
