import React from "react";
import { List } from "immutable";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { ErrorContent } from "ui-library/atoms";
import { ContentBox } from "ui-library/layouts";
import { FormBlock } from "ui-library/modules";

import { ERROR_COMPONENT_CLASS } from "./constants";
import { createGetFormError } from "./selectors";

const FormErrorBox = ({ form }) => {
    const errors = useSelector(createGetFormError(form)) || List();

    return (
        errors.count() > 0 && (
            <FormBlock mb={4}>
                <ContentBox>
                    {errors.map((error) => (
                        <div key={error} className={ERROR_COMPONENT_CLASS}>
                            <ErrorContent content={error} />
                        </div>
                    ))}
                </ContentBox>
            </FormBlock>
        )
    );
};

FormErrorBox.propTypes = {
    form: PropTypes.string.isRequired,
};

export default FormErrorBox;
