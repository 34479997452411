import { call, put, select } from "redux-saga/effects";
import X2JS from "x2js";

import pageContext from "core/pageContext";
import { pageContentApi } from "serverApi";
import { RssItem } from "types";

import { slice } from "./slice";

const x2js = new X2JS();

interface RssResponse {
    rss: {
        channel: {
            item: RssItem[];
        };
    };
}

export function* loadRssContent() {
    try {
        const isSlovakSite = yield select(pageContext.getIsSlovakSite);
        const rss: string = yield call(isSlovakSite ? pageContentApi.getSkRss : pageContentApi.getCzRss);
        const rssJson = x2js.xml2js<RssResponse>(rss);
        const items = rssJson.rss.channel.item.slice(0, 4);
        yield put(slice.actions.setRssItems(items));
    } catch (e) {
        console.warn("Rss content could not be loaded. Error received:", e); // eslint-disable-line no-console
    }
}
