import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { LabelTextItem } from "ui-library/modules";

import i18n from "core/i18n";
import localized from "core/localized";
import { formatPaymentsSchedulerDate } from "core/formatters";

export const HarmonizedPaymentSchedulerDataTable = ({ schedulerData }) => {
    const { t } = i18n.useTranslation();

    const schedulerAmount = useSelector(localized.getLocalizedSchedulerPaymentAmount(schedulerData));
    const schedulerFrequency = useSelector(localized.getLocalizedSchedulerPaymentFrequency(schedulerData));
    const schedulerPaymentMode = useSelector(localized.getLocalizedSchedulerPaymentMode(schedulerData));
    const schedulerStatus = useSelector(localized.getLocalizedSchedulerPaidState(schedulerData));

    return (
        <>
            <LabelTextItem label={t("investments.investmentPeriod")} text={formatPaymentsSchedulerDate(schedulerData)} />
            <LabelTextItem label={t("insurance.insuranceCost")} text={schedulerAmount} />
            <LabelTextItem label={t("investments.frequency")} text={schedulerFrequency} />
            <LabelTextItem label={t("payments.type")} text={schedulerPaymentMode} />
            <LabelTextItem label={t("common.status")} text={schedulerStatus} />
        </>
    );
};

HarmonizedPaymentSchedulerDataTable.propTypes = {
    schedulerData: PropTypes.object.isRequired,
};
