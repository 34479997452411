// Formatter utils.
const currentDate = new Date();
const firstDayCurrentMonth = new Date(`${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-01`);
const firstDayLastMonthObj = new Date(
    firstDayCurrentMonth.getFullYear(),
    firstDayCurrentMonth.getMonth() - 1,
    firstDayCurrentMonth.getDate(),
);
const firstDayLastMonth = new Date(`${firstDayLastMonthObj.getFullYear()}-${firstDayLastMonthObj.getMonth() + 1}-01`);

export const isThisMonthMovement = (dateString: string) => {
    const movementDate = new Date(dateString);
    return movementDate >= firstDayCurrentMonth;
};

export const isLastMonthMovement = (dateString: string) => {
    const movementDate = new Date(dateString);
    return movementDate >= firstDayLastMonth && movementDate < firstDayCurrentMonth;
};
