import { List } from "immutable";
import { v4 as uuid } from "uuid";

import { RequestStatus } from "enums";

import createModel from "../createModel";

export const Request = createModel("Request", (json) => ({
    reactKey: uuid(),
    id: json.IdRequest ? `${json.IdRequest}` : null,
    date: json.RequestDateTime,
    changeTypes: List(json.RequestType),
    contractNumber: json.ContractNumber,
    status: getRequestStatusByCode(json.RequestStatusCode),
    author: json.RequestChangeAuthor,
}));

function getRequestStatusByCode(code) {
    if (code === "IN_PROGRESS") {
        return RequestStatus.CONFIRMED;
    }
    return RequestStatus[code];
}
