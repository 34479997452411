import React from "react";
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";

import { Button, StandaloneLink } from "ui-library/atoms";
import { Box, ButtonsLayout, GridItem } from "ui-library/layouts";
import { InfoPanel, LabelTextItem } from "ui-library/modules";

import i18n from "core/i18n";
import { fn } from "core/util";
import pageContext from "core/pageContext";

import { FeatureFlag } from "types";
import { Claim } from "model/claim";
import { useNavigate } from "react-router-dom";
import { ReportClaimButton, ReportClaimButtonOld } from "containers";

export const ClaimsOverviewGridItem = ({
    detailLink,
    claims,
    compliantLineOfBusinessClasses,
    idObject,
    categoryParam,
    isContractOverviewLoading,
}) => {
    const { t } = i18n.useTranslation();
    const navigate = useNavigate();
    const statistics = fn.calculateClaimsStatistics(claims);
    const isClaimReportNewEnabled = pageContext.useIsFeatureFlagEnabled(FeatureFlag.CLAIM_REPORT_NEW);

    return (
        <GridItem display={"flex"} md={5} xs={12}>
            <InfoPanel
                icon="siren"
                title={t("insurance.claims")}
                headerAction={
                    <StandaloneLink to={detailLink} color="blue">
                        {t("common.all")}
                    </StandaloneLink>
                }
                actionButton={
                    <ButtonsLayout>
                        {isClaimReportNewEnabled ? (
                            <ReportClaimButton
                                compliantLineOfBusinessClasses={compliantLineOfBusinessClasses}
                                isContractOverviewLoading={isContractOverviewLoading}
                                idObject={idObject}
                                categoryParam={categoryParam}
                                isOutlined={false}
                            />
                        ) : (
                            <ReportClaimButtonOld isOutlined={false} />
                        )}
                        <Button variant={"outlined"} color={"blue"} onClick={() => navigate(detailLink)}>
                            {t("claim.overview")}
                        </Button>
                    </ButtonsLayout>
                }
            >
                <Box>
                    <LabelTextItem label={t("incident.openIncidents")} text={statistics.get("openIncidentsCount")} />
                    <LabelTextItem label={t("incident.reportedIncidents")} text={statistics.get("totalIncidentsCount")} />
                    <LabelTextItem label={t("incident.closedIncidents")} text={statistics.get("closedIncidentsCount")} />
                </Box>
            </InfoPanel>
        </GridItem>
    );
};

ClaimsOverviewGridItem.propTypes = {
    detailLink: PropTypes.string.isRequired,
    claims: ImmutablePropTypes.listOf(Claim),
    categoryParam: PropTypes.string.isRequired,
    compliantLineOfBusinessClasses: ImmutablePropTypes.set.isRequired,
    isContractOverviewLoading: PropTypes.bool.isRequired,
};
