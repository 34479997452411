import { Map, Range, List } from "immutable";
import moment from "moment";

const lastYear = moment.utc().year() - 1;

export const getLatestAvailableYear = (yearToRemoveFromList) => (yearToRemoveFromList === lastYear ? lastYear - 1 : lastYear);

// the oldest possible year
const defaultEndYear = lastYear - 4;

export const policyActiveYearOptions = (policyActiveFirstYear, yearToRemoveFromList) => {
    if (!policyActiveFirstYear || policyActiveFirstYear > getLatestAvailableYear(yearToRemoveFromList)) {
        return List();
    }
    const endYear = policyActiveFirstYear > defaultEndYear ? policyActiveFirstYear : defaultEndYear;
    // the second argument of Range is exclusive
    return Range(lastYear, endYear - 1)
        .filter((year) => year !== yearToRemoveFromList)
        .map((item) =>
            Map({
                value: item,
                label: item.toString(),
            }),
        )
        .toList();
};
