import React, { FC, PropsWithChildren } from "react";
import { MenuItemProps } from "@mui/material";
import { Typography, Icon } from "ui-library/atoms";
import { Box, Flexbox } from "ui-library/layouts";
import { StyledDropdown, StyledDropdownItem } from "ui-library/Styled";

export interface DropdownProps extends PropsWithChildren {
    title?: string;
    position: "right" | "left",
    anchorEl: any,
    open: boolean,
    onClose: () => void;
}

export const Dropdown: FC<DropdownProps> = ({ title, position="right", anchorEl, open, onClose, children, ...props }) => {
    return (
        <StyledDropdown
            id="basic-menu"
            className={"dropdown-" + position}
            anchorOrigin={position === "right" ? { vertical: "bottom", horizontal: "right" } : { vertical: "bottom", horizontal: "left" }}
            transformOrigin={position === "right" ? { vertical: -16, horizontal: "right" } : { vertical: -16, horizontal: "left" }}
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
        >
            {title && (
                <Box mb={3}>
                    <Typography variant="h4">{title}</Typography>
                </Box>
            )}

            <Box>{children}</Box>
        </StyledDropdown>
    );
};


export interface DropdownItemProps extends PropsWithChildren, MenuItemProps  {
    icon?: any;
}

export const DropdownItem:FC<DropdownItemProps> = ({ icon, children, ...props }) => {
    return (
        <StyledDropdownItem {...props}>
            {icon && (
                <Flexbox alignItems={"center"} mr={2}>
                    <Icon color="gray" size={16} icon={icon} />
                </Flexbox>
            )}

            <Typography variant="link" color="gray">
                {children}
            </Typography>
        </StyledDropdownItem>
    );
};
