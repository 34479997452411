import { withTheme } from "@mui/styles";
import styled from "styled-components";
import { Box } from "ui-library/layouts/Box/Box";
import { Flexbox } from "ui-library/layouts/Flexbox/Flexbox";

export const StyledPaymentMethodWrapper = withTheme(styled(Box)`
    .qr-code {
        width: 120px;
    }
`);

export const StyledPaymentMethodCardsWrapper = withTheme(styled(Flexbox)`
    flex-wrap: wrap;
    img {
        width: auto;
        height: 50px;
        object-fit: unset;
        margin-right: 24px;
        margin-bottom: 8px;
    }
`);

export const StyledPaymentMethodBanksWrapper = withTheme(styled(Flexbox)`
    flex-wrap: wrap;
    img {
        width: auto;
        height: 32px;
        object-fit: unset;
        margin-right: 24px;
        margin-bottom: 8px;
    }
`);
