import React from "react";
import PropTypes from "prop-types";
import { applyMiddleware, compose, createStore } from "redux";
import { batch, Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";
import { combineReducers } from "redux-immutable";

import auth from "core/auth";
import { ErrorLayout } from "app/layout/ErrorLayout";
import { date, fetch, sentry } from "core/util";

// TODO: This component serves as ErrorBoundary.
// Check in future for componentDidCatch hook alternative https://reactjs.org/docs/hooks-faq.html#do-hooks-cover-all-use-cases-for-classes
const createProvider = (reducers, saga, history) => {
    class AppProvider extends React.Component {
        static propTypes = {
            children: PropTypes.node.isRequired,
        };

        constructor(props) {
            super(props);
            this.showError = this.showError.bind(this);
            const sagaContext = { history };
            /**
             * latency -> redux-dev-tools dropped some actions ( https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md#latency )
             */
            /* eslint-disable no-underscore-dangle */
            const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
                ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ latency: 10 })
                : compose;
            const sagaMiddleware = createSagaMiddleware({ context: sagaContext });
            const middleware = composeEnhancers(applyMiddleware(reduxBatchMiddleware, sagaMiddleware, reduxBatchMiddleware));
            /* eslint-enable */

            const catchingCombineReducers = (reducersToWrap) => {
                const reducer = combineReducers(reducersToWrap);

                return (state, action) => {
                    try {
                        return reducer(state, action);
                    } catch (e) {
                        console.error(e);
                        this.showError(e);
                        return state;
                    }
                };
            };
            this.store = createStore(catchingCombineReducers(reducers), middleware);
            let myReducers = reducers;
            const isReducerLoaded = (name) => Object.keys(myReducers).includes(name);
            sagaContext.addReducer = (name, reducer) => {
                if (!isReducerLoaded(name)) {
                    myReducers = { ...myReducers, [name]: reducer };
                    this.store.replaceReducer(catchingCombineReducers(myReducers));
                }
            };

            this.state = { error: null };
            if (saga) {
                const sagaTask = sagaMiddleware.run(saga);
                sagaTask.toPromise().catch((e) => {
                    sagaTask.cancel();
                    this.showError(e);
                });
            }
        }

        componentDidCatch(error) {
            this.showError(error);
        }

        showError(error) {
            if (error.status && error.status === 401) {
                this.store.dispatch(auth.logOut(auth.LogoutAction.AUTO_LOGOUT));
            } else {
                let stateError = null;

                sentry.captureException(error);

                let errorIdentifier = error.identifier;

                if (error instanceof fetch.RequestError) {
                    if (error.wasDataModifying) {
                        stateError = "error.server.form.text";
                    } else {
                        stateError = "error.server.text";
                    }
                } else {
                    stateError = "error.server.form.text";
                }

                // Do not use for normal app. This hack is here only as a helper to simplify patterns for error state of app,
                // since we do not care much about how app continues after error.
                if (this.updater.isMounted(this)) {
                    this.setState({ error: stateError, errorIdentifier });
                } else {
                    // eslint-disable-next-line
                    this.state = { ...this.state, error: stateError, errorIdentifier };
                }
            }
        }

        render() {
            let { children } = this.props;
            if (this.state.error) {
                children = <ErrorLayout msgKey={this.state.error} errorIdentifier={this.state.errorIdentifier} />;
            }

            return (
                <Provider {...this.props} store={this.store}>
                    {children}
                </Provider>
            );
        }
    }

    return AppProvider;
};

const reduxBatchMiddleware = () => (next) => (action) => {
    if (Array.isArray(action)) {
        batch(() => action.forEach(next));
    } else {
        next(action);
    }
};

export default createProvider;
