import type { Selector } from "@reduxjs/toolkit";
import { List, Map, Set } from "immutable";
import { createSelector } from "reselect";
import { v4 as uuid } from "uuid";

import entity from "core/entity";
import i18n from "core/i18n";
import pageContext from "core/pageContext";
import { DEFAULT_EMPTY_PARAM, TMP_INDIVIDUAL_PROFILE_CONTRACTS } from "app/constants";
import {
    Countries,
    FundSellScope,
    MutualFundProducts,
    MutualFunds,
    MutualFundsRegularAmountType,
    MutualFundsTransactionStatusDropdown,
} from "enums";
import { getLocalizedFundSellMeansRegular, getLocalizedFundSellScope } from "core/localized";
import { localAmountWithCurrencyLegacy } from "core/formatters";
import { MutualFundsFinancialRequest } from "model/mutualFunds";
import {
    MutualFundsRegularAvailableFundsProfileChange,
    MutualFundsRegularFinancialOperation,
    MutualFundsRegularFinancialOperationDetail,
    MutualFundsRegularFinancialStatement,
} from "model/mutualFunds/regular";
import userContracts from "core/userContracts";
import { app, date, DEFAULT_CHART_COLOR, fn, model } from "core/util";
import {
    Amount,
    ElectronicCommunication,
    FeatureFlag,
    Holder,
    MutualFundsClientStatementSettings,
    MutualFundsContractParameters,
    MutualFundsRegularAccountDetail,
    MutualFundsRegularContract,
    State,
} from "types";

import {
    DIP_PRODUCT_CODE,
    ENTITY_AVAILABLE_FUNDS_PROFILE_CHANGE,
    ENTITY_FINANCIAL_OPERATION_DETAIL,
    ENTITY_FINANCIAL_OPERATIONS,
    ENTITY_FINANCIAL_REQUESTS,
    ENTITY_FINANCIAL_STATEMENT,
    NAME,
} from "./constants";

const INDIVIDUAL_PROGRAM_TEMPO_CZ_CODE = "100";
const INDIVIDUAL_PROGRAM_TEMPO_SK_CODE = "101";
const LIMIT_VALUE_FOR_INDIVIDUAL_PROFILE = 1000000;

/** contract data */
const getModel = app.createGetModel(NAME);
const getData = (state) => getModel(state).get("data");
const getAppState = (state) => getModel(state).get("appState");

const getMutualFundsRegularContractDetail: Selector<State, MutualFundsRegularContract> = (state) =>
    getData(state).mutualFundsRegularContractDetail;

export const getIdObject: Selector<State, number> = (state) => getMutualFundsRegularContractDetail(state).idObject || DEFAULT_EMPTY_PARAM;
export const getPolicyHolder: Selector<State, Holder> = (state) => getMutualFundsRegularContractDetail(state).contractDetail.holder;
export const getMutualFundsRegularAccountDetail: Selector<State, MutualFundsRegularAccountDetail> = (state) =>
    getMutualFundsRegularContractDetail(state).contractDetail.accountDetail;
export const getMutualFundsRegularContractParameters: Selector<State, MutualFundsContractParameters> = (state) =>
    getMutualFundsRegularContractDetail(state).contractDetail.contractParameters;
export const getContractNumber: Selector<State, string> = (state) => getMutualFundsRegularContractParameters(state).contractNumber;
export const getMutualFundsRegularContractProductNameCode: Selector<State, string> = (state) =>
    getMutualFundsRegularContractParameters(state).productName.code;
export const isActiveOperationsEnabled: Selector<State, boolean> = (state) =>
    getMutualFundsRegularContractParameters(state).isActiveOperationEnabled || false;
export const getActiveOperationsPhoneNumber: Selector<State, string> = (state) =>
    getMutualFundsRegularContractParameters(state).activeOperationPhone;
export const getElectronicCommunication: Selector<State, ElectronicCommunication> = (state) =>
    getMutualFundsRegularContractDetail(state).contractDetail.electronicCommunication;
export const getClientStatementSettings: Selector<State, MutualFundsClientStatementSettings> = (state) =>
    getMutualFundsRegularContractDetail(state).contractDetail.clientStatementSettings;

export const getShouldCheckIbanCountry: Selector<State, boolean> = (state) =>
    getMutualFundsRegularContractDetail(state).contractDetail.shouldCheckIbanCountry;

export const isElectronicCommunicationSet: Selector<State, boolean> = (state) =>
    getMutualFundsRegularContractDetail(state).contractDetail.electronicCommunication.electronicCommunicationSet || false;
export const getRequiredFee: Selector<State, Amount> = (state) => getMutualFundsRegularAccountDetail(state).fee;

export const getFinancialOperationsEntity = (state) =>
    // @ts-ignore
    entity.getDataSelector(state, ENTITY_FINANCIAL_OPERATIONS, MutualFundsRegularFinancialOperation.fromServerList());
export const getFinancialRequestsEntity = (state) =>
    // @ts-ignore
    entity.getDataSelector(state, ENTITY_FINANCIAL_REQUESTS, MutualFundsFinancialRequest.fromServerList());

export const getFinancialOperationDetail = (state) =>
    // @ts-ignore
    entity.getDataSelector(state, ENTITY_FINANCIAL_OPERATION_DETAIL, MutualFundsRegularFinancialOperationDetail.fromServer());

export const getAvailableFundsProfileChangeEntity = (state) =>
    // @ts-ignore
    entity.getDataSelector(state, ENTITY_AVAILABLE_FUNDS_PROFILE_CHANGE, MutualFundsRegularAvailableFundsProfileChange.fromServer());

export const getFinancialOperationDetailFunds = (state) => getFinancialOperationDetail(state).getIn(["funds"]);

export const getFinancialStatementEntity = (state) =>
    // @ts-ignore
    entity.getDataSelector(state, ENTITY_FINANCIAL_STATEMENT, MutualFundsRegularFinancialStatement.fromServer());
export const getActualValueOfInvestmentsBasedOnFund = (state) =>
    getFinancialStatementEntity(state).getIn(["actualValueOfInvestmentsBasedOnFund"]);

const getActualInvestmentProfileCode = (state) => getFinancialStatementEntity(state).getIn(["investmentProfile", "code"]);

export const isActualInvestmentProfileIndividual = createSelector(
    getActualInvestmentProfileCode,
    (actualProfileCode) => actualProfileCode && Number(actualProfileCode) > LIMIT_VALUE_FOR_INDIVIDUAL_PROFILE,
);

export const getInvestmentsProfits = (state) => getFinancialStatementEntity(state).getIn(["investmentsProfits"]);

const getAvailableProfiles = (state) => getData(state).availableProfiles;

export const isEntryFeePaid = (state) => getFinancialStatementEntity(state).getIn(["isEntryFeePaid"]);
export const getPaidFee = (state) => getFinancialStatementEntity(state).get("paidEntryFee");

export const isProductTempoCz = (state) => {
    const productName = getMutualFundsRegularContractProductNameCode(state);
    return productName === MutualFundProducts.TEMPO.codes.get(Countries.CZ.id);
};

const isProfileAvalaibleForChange = (contractNumber, isTempoCz, profileCode, featureEnabled) => {
    if (profileCode !== INDIVIDUAL_PROGRAM_TEMPO_SK_CODE && profileCode !== INDIVIDUAL_PROGRAM_TEMPO_CZ_CODE) {
        return true;
    }

    if (profileCode === INDIVIDUAL_PROGRAM_TEMPO_CZ_CODE) {
        if (featureEnabled && isTempoCz) {
            return true;
        }
        return TMP_INDIVIDUAL_PROFILE_CONTRACTS.includes(contractNumber);
    }
    return false;
};

export const getFilteredAvailableProfileOptions = createSelector(
    getContractNumber,
    getAvailableProfiles,
    pageContext.getLocale,
    isProductTempoCz,
    pageContext.getFeatureFlagEnabled(FeatureFlag.INDIVIDUAL_PROFILE_ENABLED),
    (contractNumber, availableProfiles, locale, isTempoCz, featureEnabled) =>
        availableProfiles
            .filter((profile) => isProfileAvalaibleForChange(contractNumber, isTempoCz, profile.get("code"), featureEnabled))
            .map((profile) =>
                Map({
                    value: profile.get("code"),
                    label: model.getLocaleCodeName(profile, locale?.languageCode),
                }),
            ),
);

export const getAvailableFundsProfileChangeOptions = createSelector(
    getAvailableFundsProfileChangeEntity,
    (availableFundsProfileChangeEntity) =>
        availableFundsProfileChangeEntity.isinList.map((isin) => {
            const fundEnum = Object.values(MutualFunds)
                .filter((mutualFund) => mutualFund.get("code") === isin)
                .shift();

            return Map({
                value: isin,
                label: i18n.translateDirectly(fundEnum.get("msg")),
            });
        }),
);

const PROFILE_CHANGE_FUND_NAMES = Set(["GEN_X_CZ", "GEN_X_SK", "TEMPO_SK", "TEMPO_CZ"]);
export const canChangeInvestmentProfile = (state) => {
    const productNameCode = getMutualFundsRegularContractProductNameCode(state);
    return PROFILE_CHANGE_FUND_NAMES.contains(productNameCode);
};

const CREATE_NEW_SELL_FUND_NAMES = Set(["GEN_NEXT_CZ", "GEN_X_CZ", "GEN_X_SK", "TEMPO_SK", "TEMPO_CZ", "LIFE_CYCLE_CZ", "PROGRESS_SK"]);
export const canMakeNewSell = (state) => {
    const productNameCode = getMutualFundsRegularContractProductNameCode(state);
    return CREATE_NEW_SELL_FUND_NAMES.contains(productNameCode);
};

export const getEnumFunds = i18n.createGetLocalizedEnum(MutualFunds, "link", "type", "horizont");

export const getInvestmentDetailData = createSelector(
    getActualValueOfInvestmentsBasedOnFund,
    getEnumFunds,
    i18n.getLanguageCode,
    (investmentFunds, funds, languageCode) =>
        investmentFunds &&
        investmentFunds.map((investmentFund) => ({
            id: uuid(),
            fund: Map({
                text: model.getLocaleCodeName(investmentFund.fund, languageCode),
                href: model.getHyperLinkForCodeList(funds, investmentFund.fund.code),
            }),
            investedAmount: investmentFund.investedAmount,
            numberOfShares: investmentFund.numberOfShares,
            actualShareValue: investmentFund.actualShareValue,
            actualValueOfInvestment: investmentFund.actualValueOfInvestments,
            profit: Map({
                nettoProfit: investmentFund.nettoProfit,
                fundIsin: investmentFund.fund.code,
            }),
        })),
);

export const getMutualFundsFinancialRequest = (financialRequestId) =>
    createSelector(getFinancialRequestsEntity, (financialRequests) =>
        financialRequests.filter((financialRequest) => financialRequest.id === financialRequestId).first(),
    );

export const isProfileAvailable = createSelector(
    getAvailableProfiles,
    (state, profileCode) => profileCode,
    (availableProfile, profileCode) => availableProfile.map((item) => item.get("code")).includes(profileCode),
);

const getSiteId = (state) => pageContext.getSiteId(state);

const getProducts = createSelector(i18n.createGetLocalizedEnum(MutualFundProducts, "link"), getSiteId, (products, siteId) =>
    products
        .filter((product) => product.getIn(["codes", siteId]))
        .map((product) =>
            Map({
                code: product.getIn(["codes", siteId]),
                link: product.link,
            }),
        ),
);

export const getActualValueOfInvestment = createSelector(
    getFinancialStatementEntity,
    getMutualFundsRegularContractParameters,
    getProducts,
    i18n.getLanguageCode,
    (financialStatement, contractParameters, products, locale) =>
        List([
            {
                id: uuid(),
                investmentProfile: Map({
                    text: model.getLocaleCodeName(financialStatement.get("investmentProfile"), locale),
                    href: model.getHyperLinkForCodeList(products, contractParameters.productName.code),
                }),
                depositedFunds: financialStatement.get("depositedFunds"),
                paidEntryFee: financialStatement.get("paidEntryFee"),
                investedAmount: financialStatement.get("investedAmount"),
                actualValueOfInvestments: financialStatement.get("actualValueOfInvestments"),
                profit: Map({
                    nettoProfit: financialStatement.get("nettoProfit"),
                }),
            },
        ]),
);

export const getFinancialOperationDetailSell = createSelector(
    getFinancialOperationDetail,
    i18n.getLanguageCode,
    (operationDetail, locale) => {
        if (!operationDetail) {
            return {
                id: uuid(),
            };
        }
        return List([
            {
                id: uuid(),
                transactionFundFrom: model.getLocaleCodeName(operationDetail.transactionFundFrom, locale),
                transactionTo: operationDetail.transactionTo,
                variableSymbol: operationDetail.transactionTo.variableSymbol,
                specificSymbol: operationDetail.transactionTo.specificSymbol,
                transactionDate: operationDetail.transactionDate,
                payment: operationDetail.payment,
            },
        ]);
    },
);

export const getFinancialOperationDetailBuy = createSelector(
    getFinancialOperationDetail,
    i18n.getLanguageCode,
    (operationDetail, locale) => {
        if (!operationDetail) {
            return {
                id: uuid(),
            };
        }
        return List([
            {
                id: uuid(),
                valueDate: operationDetail.valueDate,
                payment: operationDetail.payment,
                feePayment: operationDetail.feePayment,
                investedAmount: operationDetail.investedAmount,
                investmentProfile: model.getLocaleCodeName(operationDetail.investmentProfile, locale),
            },
        ]);
    },
);

export const getFinancialOperationDetailBuyFunds = createSelector(
    getFinancialOperationDetailFunds,
    i18n.getLanguageCode,
    (operationDetailFunds, locale) => {
        if (!operationDetailFunds) {
            return {
                id: uuid(),
            };
        }
        return operationDetailFunds.map((fund) => ({
            id: uuid(),
            shareOfInvestmentInTheFund: fund.shareOfInvestmentInTheFund,
            fund: model.getLocaleCodeName(fund.fund, locale),
            amount: fund.amount,
            dateOfInstructionGeneration: fund.dateOfInstructionGeneration,
            dateOfInstructionRealization: fund.dateOfInstructionRealization,
            numberOfPurchasedShares: fund.numberOfPurchasedShares,
            priceOfOneShare: fund.priceOfOneShare,
        }));
    },
);

export const createGetFilteredFinancialRequests = () =>
    createSelector(
        getFinancialRequestsEntity,
        (state, filter) => filter,
        (financialRequests, filter) =>
            fn.compose(
                model.createFilterByDate((request) => request.creationDate, filter.get("dateFrom"), filter.get("dateTo")),
                model.createFilterByGroupedOption(
                    (request) => request.transactionStatus.code,
                    filter.get("transactionStatus"),
                    MutualFundsTransactionStatusDropdown,
                ),
                model.createFilterByOption((request) => request.transactionType.code, filter.get("transactionType")),
                model.createFilterByInputText((request) => request.transactionTo.transactionName, filter.get("transferName")),
                // @ts-ignore
            )(financialRequests),
    );

export const getFinancialOperations = createSelector(getFinancialOperationsEntity, i18n.getLanguageCode, (data, locale) =>
    data.map((financialOperation) => ({
        id: financialOperation.id,
        date: financialOperation.valueDate,
        type: model.getLocaleCodeName(financialOperation.type, locale),
        payment: Map({
            amount: financialOperation.payment,
            operationType: financialOperation.type.code,
        }),
        feePayment: financialOperation.feePayment,
        cumulativeFee: financialOperation.cumulativeFee,
        investedAmount: Map({
            amount: financialOperation.investedAmount,
            operationType: financialOperation.type.code,
        }),
        cumulativeInvestment: financialOperation.cumulativeInvestment,
        detail: Map({
            operationId: financialOperation.id,
            operationType: financialOperation.getIn(["type", "code"]),
        }),
    })),
);
export const getFinancialOperationsTypes = createSelector(getFinancialOperations, (financialOperations) =>
    financialOperations.map((operation) => Map().set("label", operation.type).set("value", operation.type)).toSet(),
);

export const getProfitDetail = createSelector(getInvestmentsProfits, i18n.getLanguageCode, (profits, locale) =>
    profits.map((financialOperation) => ({
        id: uuid(),
        date: financialOperation.date,
        fundId: financialOperation.get("fund")?.get("code"),
        fund: model.getLocaleCodeName(financialOperation.get("fund"), locale),
        numberOfShares: financialOperation.numberOfShares,
        purchasePrice: Map({
            price: financialOperation.purchasePrice,
            perShare: financialOperation.purchasePricePerShare,
        }),
        actualPrice: Map({
            price: financialOperation.actualPrice,
            perShare: financialOperation.actualPricePerShare,
        }),
        nettoProfit: financialOperation.nettoProfit,
    })),
);

const getSelectedFund = (state) => getAppState(state).get("selectedFund");

export const getSelectedProfitDetail = createSelector(getProfitDetail, getSelectedFund, (profits, selectedFund) => {
    if (selectedFund === model.OPTION_ALL) {
        return profits;
    }
    return profits.filter((profit) => profit.fundId === selectedFund);
});

const getFundEnumData = (isin, funds) => funds.find((fundData) => fundData.get("code") === isin);

export const getFinancialStatementChartItems = createSelector(
    getActualValueOfInvestmentsBasedOnFund,
    getEnumFunds,
    i18n.getLanguageCode,
    (investmentFunds, enumFunds, languageCode) =>
        investmentFunds
            .map((item) => {
                const fundEnumData = getFundEnumData(item.getIn(["fund", "code"]), enumFunds);
                return {
                    name: model.getLocaleCodeName(item.get("fund"), languageCode),
                    color: fundEnumData ? fundEnumData.get("color") : DEFAULT_CHART_COLOR,
                    value: item.actualValueOfInvestments.value,
                    fullValue: localAmountWithCurrencyLegacy(item.actualValueOfInvestments),
                    currency: item.actualValueOfInvestments.currencyCode,
                };
            })
            .toArray(),
);

export const getFinancialStatementChartData = createSelector(
    getFinancialStatementEntity,
    getFinancialStatementChartItems,
    (financialStatement, chartItems) =>
        Map({
            sumValue: financialStatement.actualValueOfInvestments.value,
            sumCurrency: financialStatement.actualValueOfInvestments.currencyCode,
            sumAmount: `${localAmountWithCurrencyLegacy(financialStatement.actualValueOfInvestments)}`,
            // items: getFinancialStatementChartItems(financialStatement, funds, locale),
            items: chartItems,
        }),
);

export const getInvestmentProfileFromData = createSelector(getPaidFee, getRequiredFee, (paidFee, requiredFee) =>
    Map({
        paidFee,
        requiredFee,
    }),
);

// selectors for sellSwitchForm
const getFundCodes = createSelector(getActualValueOfInvestmentsBasedOnFund, (investments) =>
    investments.map((investment) => investment.get("fund")),
);

export const getSourceFundCodes = createSelector(getActualValueOfInvestmentsBasedOnFund, (fundInvestments) =>
    fundInvestments.map((investment) => investment.get("fund")),
);

export const getFundOptions = createSelector(
    getFundCodes,
    (state) => state,
    (fundCodes, state) => i18n.createGetLocalizedCodeOptions(fundCodes)(state),
);

export const isProductGenerationNext = createSelector(
    getMutualFundsRegularContractProductNameCode,
    (productNameCode) => productNameCode === MutualFundProducts.GEN_NEXT.codes.get(Countries.CZ.id),
);

export const isProductGenerationXSk = (state) => {
    const productName = getMutualFundsRegularContractProductNameCode(state);
    return productName === MutualFundProducts.GENX.codes.get(Countries.SK.id);
};

// for Generation Next regular investments, we only allow PROGRAM sellScope
export const getSellScopeOptions = createSelector(isProductGenerationNext, getLocalizedFundSellScope, (isGenNext, sellScopes) =>
    sellScopes.filter((sellScope) => !isGenNext || sellScope.get("value") === FundSellScope.PROGRAM.id),
);

// for Generation Next regular investments, we only allow SELL_ALL fundSellMean
export const getFundsSellMeansRegular = createSelector(
    isProductGenerationNext,
    getLocalizedFundSellMeansRegular,
    (isGenNext, fundSellMeans) =>
        fundSellMeans.filter((fundSellMean) => !isGenNext || fundSellMean.get("value") === MutualFundsRegularAmountType.SELL_ALL.id),
);

/** user data */
export const getPolicy = createSelector(userContracts.getContractNormalizedData, getIdObject, (policies, idObject) =>
    idObject ? policies[idObject] : null,
);

export const getPolicyBeginDateYear = (state) => date.getYearFromStringDateTime(getPolicy(state)?.beginDate);

export const getIsDipContract = createSelector(getPolicy, (policy) => DIP_PRODUCT_CODE === policy?.idEnuProduct);
