import { model, fn } from "core/util";
import { Pis as PisType, PisDto } from "types";

export const Pis = model.createModel<PisDto, PisType>((json) => ({
    code: json.Code,
    text: fn.formatMarkdownText(json.Text),
    buttonText: json.ButtonText,
    displayEnableButton: json.DisplayEnableButton === 1,
    displayDisableButton: json.DisplayDisableButton === 1,
}));
