import React, { FC } from "react";
import { useSelector } from "react-redux";

import { Typography } from "ui-library/atoms";
import { ContentBox } from "ui-library/layouts";
import { LabelTextItem } from "ui-library/modules";

import i18n from "core/i18n";
import { getFormFieldValue } from "core/form";
import { localFullDate } from "core/formatters";
import { VehicleCancellationReason } from "types";

import {
    FIELD_CANCELLATION_DATE,
    FIELD_CANCELLATION_NOTE,
    FIELD_CANCELLATION_REASON,
    SECTION_BANK_ACCOUNT,
    SECTION_CONTACT_PERSON,
    SECTION_ADDRESS,
    VIRTUAL_FIELD_ATTACHMENTS,
    VIRTUAL_FIELD_CANCELLATION_REASONS,
    FUNDS_TRANSFER_TYPE,
} from "../../constants";
import {
    formatAddressOneLine,
    formatAttachments,
    formatBankAccount,
    formatPhoneAndEmail,
    formatSubjectData,
    getCancellationDateTitle,
} from "../../util";

type StepOneProps = {
    formName: string;
};

export const Recapitulation: FC<StepOneProps> = ({ formName }) => {
    const { t } = i18n.useTranslation();

    const allCancellationReasons = useSelector(getFormFieldValue(formName, VIRTUAL_FIELD_CANCELLATION_REASONS));
    const selectedCancellationReason = useSelector(getFormFieldValue(formName, FIELD_CANCELLATION_REASON));
    const selectedCancellationNote = useSelector(getFormFieldValue(formName, FIELD_CANCELLATION_NOTE));
    const contactPerson = useSelector(getFormFieldValue(formName, SECTION_CONTACT_PERSON));
    const cancellationDate = useSelector(getFormFieldValue(formName, FIELD_CANCELLATION_DATE));
    const fundsTransferType = useSelector(getFormFieldValue(formName, FUNDS_TRANSFER_TYPE));
    const fundsTransferBankAccount = useSelector(getFormFieldValue(formName, SECTION_BANK_ACCOUNT));
    const attachments = useSelector(getFormFieldValue(formName, VIRTUAL_FIELD_ATTACHMENTS));

    const selectedCancellationReasonData = allCancellationReasons.filter((item) => item.code === selectedCancellationReason)[0];

    return (
        <>
            <ContentBox fullWidth textAlign={"center"}>
                <Typography variant="h3">{t("vehicle.change.contractCancellation.changelist")}</Typography>
            </ContentBox>

            <ContentBox fullWidth>
                {/* Cancellation reason */}
                <LabelTextItem
                    label={t("vehicle.change.contractCancellation.cancellationReason")}
                    text={selectedCancellationReasonData?.label}
                />

                {/* Cancellation date */}
                <LabelTextItem label={getCancellationDateTitle(t, selectedCancellationReason)} text={localFullDate(cancellationDate)} />

                {/* Agreement - cancellation note */}
                {selectedCancellationReason === VehicleCancellationReason.BY_AGREEMENT && (
                    <LabelTextItem label={t("common.note")} text={selectedCancellationNote} />
                )}

                {/* Insurer death - new contract person */}
                {selectedCancellationReason === VehicleCancellationReason.INSURER_DEATH && (
                    <>
                        <LabelTextItem
                            label={t("vehicle.change.contractCancellation.recap.newContact")}
                            text={formatSubjectData(t, contactPerson)}
                        />
                        <LabelTextItem
                            label={t("vehicle.change.contractCancellation.recap.newContactEmailPhone")}
                            text={formatPhoneAndEmail(t, contactPerson)}
                        />
                        <LabelTextItem
                            label={t("vehicle.change.contractCancellation.recap.newContactAddress")}
                            text={formatAddressOneLine(t, contactPerson.get(SECTION_ADDRESS))}
                        />
                    </>
                )}

                {/* Funds transfer */}
                <LabelTextItem
                    label={t("change.fundsTransfer.account")}
                    text={formatBankAccount(t, fundsTransferType, fundsTransferBankAccount)}
                />

                {/* Attachments */}
                {attachments?.length > 0 && <LabelTextItem label={t("change.attachments")} text={formatAttachments(t, attachments)} />}
            </ContentBox>
        </>
    );
};
