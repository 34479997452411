import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import i18n from "core/i18n";
import { date } from "core/util";
import userContracts from "core/userContracts";

import { Select } from "ui-library/atoms";
import { GridItem } from "ui-library/layouts";

import { policyActiveYearOptions } from "../util";

export const YearSelect = ({
    getId,
    getPolicyBeginDateYear,
    selectChange,
    getSelectedYear,
    getAvailableYears,
    fetchAvailableYears,
    yearToRemoveFromList,
}) => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();

    const idObject = useSelector(getId);
    const contract = userContracts.useGetContractById(idObject);
    const policyBeginDateYearExt = useSelector(getPolicyBeginDateYear);
    const policyBeginDateYear = policyBeginDateYearExt || date.getYearFromStringDateTime(contract.beginDate);

    const selectedYear = useSelector(getSelectedYear);
    const availableYears = useSelector(getAvailableYears);
    const options = fetchAvailableYears ? availableYears : policyActiveYearOptions(policyBeginDateYear, yearToRemoveFromList);

    return (
        <GridItem lg={3} md={6} xs={12}>
            <Select
                value={selectedYear}
                onChange={(event) => dispatch(selectChange(event.target.value))}
                label={t("common.year")}
                id="year"
                placeholder={t("common.chooseYear")}
                fullWidth
                options={options}
            />
        </GridItem>
    );
};

YearSelect.propTypes = {
    getId: PropTypes.func.isRequired,
    selectChange: PropTypes.func.isRequired,
    getSelectedYear: PropTypes.func.isRequired,
    getAvailableYears: PropTypes.func.isRequired,
    fetchAvailableYears: PropTypes.bool,
    yearToRemoveFromList: PropTypes.number,
};

YearSelect.defaultProps = {
    fetchAvailableYears: false,
    yearToRemoveFromList: null,
};
