import { dateTimeToLocalFullDate } from "core/formatters";
import { fn } from "core/util";

export const getLocalizedIdentityCard = (t, value) => {
    const allValues = Object.values(value);
    const filledValues = allValues.filter(fn.identity);
    if (filledValues.length !== allValues.length) {
        return t("form.bulkChange.valuesNotFilled");
    }

    const releaseDate = dateTimeToLocalFullDate(value.releaseDate);
    const dateOfExpiry = dateTimeToLocalFullDate(value.dateOfExpiry);
    return `${value.identification}, ${value.cardId || ""}, ${releaseDate}, ${dateOfExpiry}, ${value.authority || ""}`;
};
