import React, { FC } from "react";
import { useSelector } from "react-redux";

import { GridLayout } from "ui-library/layouts";

import i18n from "core/i18n";
import { GenerateStatementGridItem, PensionSkStatementsSettings } from "containers/contract";

import { ENTITY_FINANCIAL_OPERATIONS, FORM_EDIT_STATEMENT_PASSWORD } from "../constants";
import { FinancialOperationsGridItem } from "../containers";
import { ActualStatements, ExtraStatements, YearStatements } from "../modules";
import { ModalStatementPasswordForm } from "../form";
import { getStatementPassword, isElectronicCommunicationSet } from "../selectors";

export const FinancialOperationsTab: FC = () => {
    const { t } = i18n.useTranslation();
    const { setStatement } = GenerateStatementGridItem;
    const isElCommunicationSet = useSelector(isElectronicCommunicationSet);
    const statementPassword = useSelector(getStatementPassword);

    return (
        <GridLayout defaultSpacing>
            <FinancialOperationsGridItem entityName={ENTITY_FINANCIAL_OPERATIONS} />

            <GenerateStatementGridItem
                statements={[
                    setStatement(t("statements.yearlyStatement"), YearStatements),
                    setStatement(t("statements.extraStatement"), ExtraStatements),
                    setStatement(t("statements.actualStatement"), ActualStatements),
                ]}
            />

            {isElCommunicationSet && (
                <PensionSkStatementsSettings
                    statementPassword={statementPassword}
                    editStatementPassFormName={FORM_EDIT_STATEMENT_PASSWORD}
                    EditStatementPassForm={ModalStatementPasswordForm}
                />
            )}
        </GridLayout>
    );
};
