import { Record } from "immutable";

class MutualFunds extends Record({
    id: null,
    msg: null,
    code: null,
    link: null,
    type: null,
    horizont: null,
    risk: null,
    color: null,
}) {}

export default Object.freeze({
    CZK_KONTO: new MutualFunds({
        id: "CZK_KONTO",
        msg: "investments.fund.CZK_KONTO",
        code: "CZ0008472602",
        link: "fundLink.mutualFund.CZK_KONTO",
        type: "general.fund.mixedConservative",
        horizont: "common.sixMonths",
        color: "greenMain",
    }),
    EUR_KONTO: new MutualFunds({
        id: "EUR_KONTO",
        msg: "investments.fund.EUR_KONTO",
        code: "CZ0008472586",
        link: "fundLink.mutualFund.EUR_KONTO",
        type: "general.fund.mixedConservative",
        horizont: "common.minOneYear",
        color: "greenMainDarker",
    }),
    EUROBOND: new MutualFunds({
        id: "EUROBOND",
        msg: "investments.fund.EUROBOND",
        code: "CZ0008473675",
        link: "fundLink.mutualFund.EUROBOND",
        type: "general.fund.bond",
        horizont: "common.threeYears",
        color: "blueMain",
    }),
    CEE_BOND: new MutualFunds({
        id: "CEE_BOND",
        msg: "investments.fund.CEE_BOND",
        code: "CZ0008472578",
        link: "fundLink.mutualFund.CEE_BOND",
        type: "general.fund.bond",
        horizont: "common.threeYears",
        color: "blueMainDarker",
    }),
    SELECTION_OPPORTUNITIES: new MutualFunds({
        id: "SELECTION_OPPORTUNITIES",
        msg: "investments.fund.SELECTION_OPPORTUNITIES",
        code: "CZ0008472917",
        link: "fundLink.mutualFund.SELECTION_OPPORTUNITIES",
        type: "general.fund.mixed",
        horizont: "common.fiveYears",
        color: "grayMain",
    }),
    SELECTION_GLOBAL_EQUITY: new MutualFunds({
        id: "SELECTION_GLOBAL_EQUITY",
        msg: "investments.fund.SELECTION_GLOBAL_EQUITY",
        code: "CZ0008472883",
        link: "fundLink.mutualFund.SELECTION_GLOBAL_EQUITY",
        type: "general.fund.share",
        horizont: "common.fiveYears",
        color: "orangeMainLighter",
    }),
    CEE_SHARE: new MutualFunds({
        id: "CEE_SHARE",
        msg: "investments.fund.CEE_SHARE",
        code: "CZ0008472594",
        link: "fundLink.mutualFund.CEE_SHARE",
        type: "general.fund.share",
        horizont: "common.fiveYears",
        color: "orangeMainDarker",
    }),
    REAL_ESTATE_FUND: new MutualFunds({
        id: "REAL_ESTATE_FUND",
        msg: "investments.fund.REAL_ESTATE_FUND",
        code: "CZ0008472776",
        link: "fundLink.mutualFund.REAL_ESTATE_FUND",
        type: "general.fund.share",
        horizont: "common.fiveYears",
        color: "orangeMain",
    }),
    SELECTION_EMERGING_EQUITY_FUND: new MutualFunds({
        id: "SELECTION_EMERGING_EQUITY_FUND",
        msg: "investments.fund.SELECTION_EMERGING_EQUITY_FUND",
        code: "CZ0008472891",
        link: "fundLink.mutualFund.SELECTION_EMERGING_EQUITY_FUND",
        type: "general.fund.share",
        horizont: "common.fiveYears",
        color: "redMainDarker",
    }),
    SMALL_CAP_PORTFOLIO: new MutualFunds({
        id: "SMALL_CAP_PORTFOLIO",
        msg: "investments.fund.SMALL_CAP_PORTFOLIO",
        code: "CZ0008474665",
        link: "fundLink.mutualFund.SMALL_CAP_PORTFOLIO",
        type: "general.fund.share",
        horizont: "common.fiveYears",
        color: "pinkMain",
    }),
    FUTURE_TRENDS: new MutualFunds({
        id: "FUTURE_TRENDS",
        msg: "investments.fund.FUTURE_TRENDS",
        code: "CZ0008476660",
        link: "fundLink.mutualFund.FUTURE_TRENDS",
        type: "general.fund.share",
        horizont: "common.fiveYears",
        color: "blueMuddy",
    }),
});
