import React, { FC } from "react";

import i18n from "core/i18n";
import { ImportantAnnouncements } from "ui-library/atoms";

export const LoginPageError: FC = () => {
    const { t } = i18n.useTranslation();
    return (
        <ImportantAnnouncements
            variant={"ERROR"}
            data={{
                heading: t("error.login.unavailable"),
            }}
        />
    );
};
