import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { ContentBox, Section } from "ui-library/layouts";
import { Typography } from "ui-library/atoms";

import { Field, getFormFieldValue, required, SingleSelect } from "core/form";
import i18n from "core/i18n";
import { FundSellScope } from "enums";

export const SourceSectionRegular = ({ formName, getSellScopeOptions, getSourceFundOptions }) => {
    const { t } = i18n.useTranslation();
    const sellScopeOptions = useSelector(getSellScopeOptions);
    const sourceFundOptions = useSelector(getSourceFundOptions);
    const sellScope = useSelector(getFormFieldValue(formName, "sellScope"));

    return (
        <Section>
            <ContentBox>
                <Typography variant="h3">{t("investments.sellSwitch.scope")}</Typography>
            </ContentBox>
            {/*<ContentBox>*/}
            {/*    <Typography>{t("investments.sellSwitch.scope")}</Typography>*/}
            {/*</ContentBox>*/}
            <ContentBox fullWidth>
                <Field
                    name="sellScope"
                    component={SingleSelect}
                    label={t("investments.sellSwitch.scopeLabel")}
                    options={sellScopeOptions}
                    validateStatic={[required]}
                    placeholder={t("common.choose")}
                />
            </ContentBox>
            {sellScope !== FundSellScope.PROGRAM.id && (
                <>
                    <ContentBox>
                        <Typography variant="h3">{t("common.fund")}</Typography>
                    </ContentBox>
                    <ContentBox>
                        <Field
                            name="sourceFund"
                            component={SingleSelect}
                            label={t("investments.sellSwitch.sourceLabel")}
                            options={sourceFundOptions}
                            validateStatic={[required]}
                            placeholder={t("investments.sellSwitch.sourcePlaceholder")}
                        />
                    </ContentBox>
                </>
            )}
        </Section>
    );
};

SourceSectionRegular.propTypes = {
    getSellScopeOptions: PropTypes.func.isRequired,
};
