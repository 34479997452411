import { call, put, takeEvery, select, all } from "redux-saga/effects";

import { enumApi } from "serverApi";
import errorHandling, { CONTRACT_FUNDS_SELL_SWITCH_UNAVAILABLE } from "core/errorHandling";

import { BANK_LIST } from "./constants";
import { createIsEnumLoaded } from "./selectors";
import slice from "./slice";

const beingLoaded = [];
const ENUMS_API_MAP = {
    [BANK_LIST]: {
        api: enumApi.getBanksList,
        errorConst: CONTRACT_FUNDS_SELL_SWITCH_UNAVAILABLE,
    },
};

export default function* () {
    // @ts-ignore
    yield takeEvery(slice.actions.loadIfNeeded.type, loadEnumsSaga);
}

function* loadEnumsSaga({ payload }) {
    const actions = payload.map((pageName) => call(loadContents, pageName));
    yield all(actions);
}

function* loadContents(enumName: string) {
    const loaded = yield select(createIsEnumLoaded(enumName));
    if (!loaded && !beingLoaded.includes(enumName)) {
        beingLoaded.push(enumName);
        try {
            const content = yield call(ENUMS_API_MAP[enumName]["api"], enumName);
            yield put(slice.actions.setEnum(enumName, content));
        } catch (e) {
            yield put(errorHandling.addServiceError(ENUMS_API_MAP[enumName]["errorConst"], e.identifier));
        } finally {
            beingLoaded.splice(beingLoaded.indexOf(enumName), 1);
        }
    }
}
