import { withTheme } from "@mui/styles";
import styled from "styled-components";

import { StyledBlock } from "ui-library/Styled/Layouts/Blocks";

export const StyledPromoBlock = withTheme(styled(StyledBlock)`
    && {
        padding: ${(props) => (props.$hasOffsets ? "175px 0 105px 0" : "96px 0")};

        @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
            padding: ${(props) => (props.$hasOffsets ? "48px 0 48px 0" : "48px 0")};
        }
    }
`);
