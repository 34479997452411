import React from "react";
import { useDispatch } from "react-redux";

import { Button, ErrorContent } from "ui-library/atoms";
import { GridItem, GridLayout } from "ui-library/layouts";

import auth from "core/auth";
import i18n from "core/i18n";

export const WrongRoleError = () => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();

    return (
        <GridLayout spacing={1}>
            <GridItem xs={12} mb={2}>
                <ErrorContent content={t("error.page.login.wrongRole")} />
            </GridItem>
            <GridItem xs={12}>
                <Button onClick={() => dispatch(auth.logOut(auth.LogoutAction.USER_LOGOUT))} color="blue" variant="contained" size="large">
                    {t("common.logout")}
                </Button>
            </GridItem>
        </GridLayout>
    );
};
