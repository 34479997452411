import React, { FC } from "react";

import { Button, ImportantAnnouncements } from "ui-library/atoms";
import { Box } from "ui-library/layouts";
import { ModalBody, ModalFooter } from "ui-library/modules";

import i18n from "core/i18n";

const STATUS_OK = "S";

export interface ModalChangeResultSuccessProps {
    closeModal: any;
    changeResult: any;
    title?: string;
}

export const ModalChangeResultSuccess: FC<ModalChangeResultSuccessProps> = ({ closeModal, changeResult, title }) => {
    const { t } = i18n.useTranslation();

    const processed = changeResult.status === STATUS_OK;
    const displayTitle = processed ? title : t("error.editFormProcessError");
    const displayBody = processed ? changeResult.message : changeResult.error;

    return (
        <>
            <ModalBody>
                <Box>
                    <ImportantAnnouncements
                        variant={processed ? "SUCCESS" : "ERROR"}
                        data={{
                            heading: displayTitle,
                            content: displayBody,
                        }}
                    />
                </Box>
            </ModalBody>
            <ModalFooter>
                <Button onClick={closeModal} color="blue" variant="contained">
                    {t("common.close")}
                </Button>
            </ModalFooter>
        </>
    );
};
