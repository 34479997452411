import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { resetSection } from "redux-form/immutable";
import { Selector } from "@reduxjs/toolkit";

import errorHandling from "core/errorHandling";
import { asyncFieldValidator, asyncValidateFields, fieldChangeMatcher, formWrapper, getPathFromFieldName, required } from "core/form";
import userInfo from "core/userInfo";
import { sentry } from "core/util";
import { contractDataChangeApi, validationApi } from "serverApi";
import { Holder, PensionPpAccountDetail, State } from "types";

import { FIELD_ENABLED, FIELD_PHONE } from "./constants";

export function* createSaga(
    formName: string,
    idObject: number,
    getAccountDetail: Selector<State, PensionPpAccountDetail>,
    getHolder: Selector<State, Holder>,
) {
    try {
        yield call(formSaga(formName, getAccountDetail, getHolder), idObject);
    } catch (e) {
        sentry.captureException(e);
        yield put(errorHandling.addServiceError(formName, e.identifier));
    }
}

const formSaga = (formName: string, getAccountDetail: Selector<State, PensionPpAccountDetail>, getHolder: Selector<State, Holder>) =>
    // @ts-ignore we won't type sagas
    formWrapper(formName, {
        *persistentEffects() {
            yield takeEvery(fieldChangeMatcher(formName, FIELD_ENABLED), enabledChangedSaga, formName);
        },
        *initialize() {
            yield call(userInfo.checkUserVerified);
            yield put(errorHandling.removeServiceErrors(formName));

            const [detail, holder]: [detail: PensionPpAccountDetail, holder: Holder] = yield all([
                select(getAccountDetail),
                select(getHolder),
            ]);
            return {
                [FIELD_ENABLED]: detail.isTaxOptimizationEnabled,
                [FIELD_PHONE]: holder.phone,
            };
        },
        *save(values, idObject) {
            const isEnabled = values.get(FIELD_ENABLED);
            const requestBody = {
                idObject,
                enableTaxOptimization: isEnabled,
                holderPhoneNumber: isEnabled ? values.get(FIELD_PHONE) : null,
            };
            yield call(contractDataChangeApi.updateTaxOptimizationsSettings, requestBody);
        },
        *asyncValidation(values, field) {
            const asyncFieldValidators = [];
            if (values.getIn(getPathFromFieldName(FIELD_ENABLED))) {
                asyncFieldValidators.push(asyncFieldValidator(getPathFromFieldName(FIELD_PHONE), [required, validationApi.validatePhone]));
            }
            return yield call(asyncValidateFields(...asyncFieldValidators), field, values);
        },
    });

function* enabledChangedSaga(formName) {
    yield put(resetSection(formName, FIELD_PHONE));
}
