import { nbsp } from "app/constants";
import i18n from "core/i18n";
import { fn } from "core/util";

import { Countries, Frequency, MutualFundsDirectAmountType, MutualFundsPeriodicity } from "enums";
import { PensionBeneficiaryShare } from "types";

import { localAmountNoDecimal, localAmountWithCurrencyBasedOnSiteLegacy, localAmountWithCurrencyLegacy } from "./localAmountFormatter";
import { formatPercentageNoDecimal } from "./numberFormatter";
import { dateTimeToLocalFullDate } from "./dateFormatter";

export const formatLocalizedValueMap = (value, valueMap) => (!fn.isEmpty(value) && valueMap[value] ? valueMap[value] : "");

export const formatClaimVehicle = (subject): string => {
    if (!subject) {
        return "";
    }
    return (subject.brand ? subject.brand + " " : "") + (subject.model ? subject.model + " " : "");
};

export const formatAmountOrNumberOfShares = (transactionValueType, transactionAmount, numberOfSharesInTransaction, sellAll) => {
    switch (transactionValueType.code) {
        case MutualFundsDirectAmountType.SELL_ALL.id:
            return sellAll;
        case MutualFundsDirectAmountType.AMOUNT.id:
            return localAmountWithCurrencyLegacy(transactionAmount);
        case MutualFundsDirectAmountType.QUANTITY.id:
            return `${localAmountNoDecimal(numberOfSharesInTransaction)}${nbsp}PL`;
        default:
            return "";
    }
};

export const formatFundFrequency = (periodicityType, frequency, frequencyMap) => {
    const { t } = i18n.useTranslation();

    return periodicityType === MutualFundsPeriodicity.ONETIME.id
        ? t("common.oneTime")
        : formatLocalizedValueMap(frequency.code, frequencyMap);
};

export const formatFundPayment = (value, siteId: string): string => {
    if (fn.isEmpty(value)) {
        return "";
    }

    if (siteId === Countries.SK.id) {
        const ibanValue = value.get("iban");
        if (ibanValue) {
            return ibanValue;
        }
    }

    const accountNumberValue = value.get("accountNumber");
    const bankCodeValue = value.getIn(["bankNumber", "code"]);
    if (accountNumberValue && bankCodeValue) {
        const accountPrefix = value.get("accountNumberPrefix") ? `${value.get("accountNumberPrefix")}-` : "";
        return `${accountPrefix}${accountNumberValue}/${bankCodeValue}`;
    }
    return "";
};

export const formatMutualFundsRegularMonthlyPayment = (monthlyPayment, frequencyMap, isSlovakSite: boolean): string => {
    const amount = localAmountWithCurrencyBasedOnSiteLegacy(monthlyPayment, isSlovakSite);
    const period = formatLocalizedValueMap(Frequency.MONTHLY.id, frequencyMap);

    if (fn.isEmpty(amount) || fn.isEmpty(period)) {
        return "";
    }

    return `${amount} / ${period}`;
};

export const pensionBeneficiaryShare = (share: PensionBeneficiaryShare): string => {
    const { numerator } = share;

    if (fn.isEmpty(numerator)) {
        return "";
    }

    if (share.shareType === "P") {
        return formatPercentageNoDecimal(numerator);
    } else {
        const { denominator } = share;
        if (fn.isEmpty(denominator)) {
            return "";
        }

        return `${localAmountNoDecimal(numerator)}${nbsp}/${localAmountNoDecimal(denominator)}`;
    }
};

export const pensionBeneficiaryShareLegacy = (share): string => {
    const numerator = share.numerator;

    if (fn.isEmpty(numerator)) {
        return "";
    }

    if (share.shareType === "P") {
        return formatPercentageNoDecimal(numerator);
    } else {
        const denominator = share.denominator;
        if (fn.isEmpty(denominator)) {
            return "";
        }

        return `${localAmountNoDecimal(numerator)}${nbsp}/${localAmountNoDecimal(denominator)}`;
    }
};

export const formatSurrenderAmount = (
    isUniqaContract: boolean,
    isSlovakSite: boolean,
    statementDate,
    surrenderAmount,
    calculationDate,
): string => {
    const { t } = i18n.useTranslation();

    if (!isUniqaContract || !calculationDate || Number(surrenderAmount?.value) <= 0) {
        return null;
    }

    const formattedAmount = localAmountWithCurrencyBasedOnSiteLegacy(surrenderAmount, isSlovakSite);
    if (statementDate === calculationDate) {
        return formattedAmount;
    }
    return `${formattedAmount} (${t("statements.dateTime", { statementDate: dateTimeToLocalFullDate(calculationDate) })})`;
};
