import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "ui-library/atoms";
import { Box, GridItem } from "ui-library/layouts";
import { InfoPanel } from "ui-library/modules";

import i18n from "core/i18n";
import modal from "core/modal";
import { useHasEditContractPermission } from "core/hooks";
import userInfo from "core/userInfo";

import { getGdprPageState } from "../selectors";
import { USER_GDPR_CONSENT_FORM } from "../constants";
import { GdprAccepted } from "./GdprAccepted";
import { GdprNotAccepted } from "./GdprNotAccepted";

const getContent = (pageState, hasConsent) => (hasConsent ? <GdprAccepted /> : <GdprNotAccepted />);

export const GdprGridItem = () => {
    const { t } = i18n.useTranslation();
    const hasConsent = useSelector(userInfo.hasGdprConsent);
    const pageState = useSelector(getGdprPageState);
    const dispatch = useDispatch();
    const hasEditPermission = useHasEditContractPermission();

    const openModalForm = () => dispatch(modal.openModalForm(USER_GDPR_CONSENT_FORM));

    return (
        <GridItem xs={12}>
            <InfoPanel icon="user-settings" title={t("userSettings.gdprTitle")}>
                {getContent(pageState, hasConsent)}

                {/* Buttons */}
                {hasEditPermission && (
                    <Box mt={3}>
                        <Button color="blue" variant={hasConsent ? "outlined" : "contained"} onClick={openModalForm}>
                            {hasConsent ? t("common.cancelAgreement") : t("common.getAgreement")}
                        </Button>
                    </Box>
                )}
            </InfoPanel>
        </GridItem>
    );
};
