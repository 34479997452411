import { model } from "core/util";
import { PensionRedeemInvestmentsOption as PensionRedeemInvestmentsOptionType, PensionRedeemInvestmentsOptionDto } from "types";

export const PensionRedeemInvestmentsOption = model.createModel<PensionRedeemInvestmentsOptionDto, PensionRedeemInvestmentsOptionType>(
    (json) => ({
        typeCode: json.typeCode,
        description: json.description,
        claimDateFrom: json.claimDateFrom,
        claimDateTo: json.claimDateTo,
        terminationDateFrom: json.terminationDateFrom,
        terminationDateTo: json.terminationDateTo,
        beingProcessedCount: json.beingProcessedCount,
        isApplicable: json.isApplicable,
        notApplicableReason: json.notApplicableReason,
    }),
);
