import React, { FC } from "react";
import { useSelector } from "react-redux";

import i18n from "core/i18n";
import localized from "core/localized";
import pageContext from "core/pageContext";
import { dateTimeToLocalFullDate } from "core/formatters";
import { StandaloneLink } from "ui-library/atoms";
import { Box, GridItem } from "ui-library/layouts";
import { InfoPanel, LabelTextItem } from "ui-library/modules";
import { ContractPolicy, PensionDdsAccountDetail } from "types";

import { getPaymentsScheduler } from "../selectors";
import { getBatchPlan } from "../util";

type PensionContractOverviewGridItemProps = {
    contract: ContractPolicy;
    pensionAccountDetail: PensionDdsAccountDetail;
    detailLink: string;
};

export const PensionContractOverviewGridItem: FC<PensionContractOverviewGridItemProps> = ({
    contract,
    pensionAccountDetail,
    detailLink,
}) => {
    const { t } = i18n.useTranslation();
    const locale = useSelector(pageContext.getLocale);

    const paymentsScheduler = useSelector(getPaymentsScheduler);
    const holderPayments = useSelector(localized.getLocalizedSchedulerPaymentFull(paymentsScheduler, true));

    return (
        <GridItem display="flex" md={7} xs={12}>
            <InfoPanel
                icon="contract"
                title={t("common.contract")}
                headerAction={
                    <StandaloneLink to={detailLink} color="blue">
                        {t("common.detail")}
                    </StandaloneLink>
                }
            >
                <Box>
                    <LabelTextItem label={t("common.begin")} text={dateTimeToLocalFullDate(contract?.beginDate)} />
                    {contract?.endDate && <LabelTextItem label={t("common.end")} text={dateTimeToLocalFullDate(contract.endDate)} />}
                    <LabelTextItem
                        label={t("pension.batchPlan")}
                        text={getBatchPlan(pensionAccountDetail?.batchPlan, locale.languageCode, t("pension.byLaw"))}
                    />
                    <LabelTextItem label={t("account.holderPayments")} text={holderPayments} />
                </Box>
            </InfoPanel>
        </GridItem>
    );
};
