import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { ContentBox, GridItem } from "ui-library/layouts";
import { InfoPanel } from "ui-library/modules";
import { Button, Typography } from "ui-library/atoms";

import i18n from "core/i18n";
import modal from "core/modal";
import { useHasEditContractPermission } from "core/hooks";

import { getPis } from "../selectors";
import { FORM_PIS_CONFIRMATION } from "../constants";
import { ModalPisConfirmationForm } from "../form/ModalPisConfirmationForm";

export const PisGridItems = () => {
    const { t } = i18n.useTranslation();

    const pis = useSelector(getPis);
    const hasEditPermission = useHasEditContractPermission();

    const displayButton = pis.displayEnableButton === true || pis.displayDisableButton === true;
    const buttonText = pis.displayEnableButton === true ? "common.activate" : "common.deactivate";

    const dispatch = useDispatch();
    const openModalForm = (formName) => dispatch(modal.openModalForm(formName));

    return (
        <>
            <GridItem xs={12}>
                <InfoPanel icon={"chart-line"} title={t("pension.pis.title")}>
                    <ContentBox bigger>
                        <Typography markDown={t("pension.pis.text")} />
                    </ContentBox>
                </InfoPanel>
            </GridItem>
            <GridItem xs={12}>
                <InfoPanel>
                    <ContentBox bigger>
                        <Typography markDown={pis.text} />
                    </ContentBox>
                    {hasEditPermission && displayButton && (
                        <>
                            <ContentBox mt={2}>
                                <Typography markDown={pis.buttonText} />
                            </ContentBox>

                            <Button color="green" variant="contained" onClick={() => openModalForm(FORM_PIS_CONFIRMATION)}>
                                {t(buttonText)}
                            </Button>
                        </>
                    )}
                </InfoPanel>
            </GridItem>
            <ModalPisConfirmationForm />
        </>
    );
};
